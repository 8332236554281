<template>
  <div class="avatar-box">
    <el-dropdown v-if="userguid" trigger="click" @visible-change="onOpen">
      <div class="avatar-content pointer">
        <el-avatar :size="41" :src="avatarSrc" />
        <el-icon
          style="width: 18px; margin-left: 4px; color: black"
          :class="[isOpen ? 'openRotateAnimation' : 'closeRotateAnimation']"
        >
          <arrow-down />
        </el-icon>
      </div>
      <template #dropdown>
        <div class="dropdown-first-item">
          <div class="my-header-username">
            <span class="my-header-username-value">
              {{ username ? username : phone }}
            </span>
            <div v-if="viplevel > 0" class="my-header-username-label">
              <span class="my-header-username-label-item vip">
                {{ vipLevelName[viplevel - 1] }}
              </span>
              <span class="my-header-username-label-item">VIP</span>
            </div>
            <div v-else class="my-header-username-label">
              <span class="my-header-username-label-item novip">非会员</span>
              <span class="my-header-username-label-item">VIP</span>
            </div>
          </div>
          <div class="dropdown-first-item-tip">
            ID: {{ userguid.slice(-6) }}
          </div>
        </div>
        <el-dropdown-menu>
          <el-dropdown-item
            @mouseover="onHoverItem('vip')"
            @mouseout="onUnHoverItem('vip')"
            @click="onPath('vip-page')"
          >
            <img class="dropdown-icon" :src="vipIcon" alt="vip_icon" />
            <span>会员中心</span>
          </el-dropdown-item>
          <el-dropdown-item
            @mouseover="onHoverItem"
            @mouseout="onUnHoverItem"
            @click="onLogout"
          >
            <img class="dropdown-icon" :src="logoutIcon" alt="logout_icon" />
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <div v-else class="avatar-label">
      <span class="label-item pointer" @click="onPath('login')">登录</span>
      |
      <span class="label-item pointer" @click="onPath('login')">注册</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import store from '@/store'
import { navigationTo, logout, phoneOmitFn } from '@/utils'
import { vipLevelName } from '@/static'

const avatarSrc = require('@/assets/images/avatar.png')
const userguid = computed(() => store.getters.userguid)
const username = computed(() => store.getters.username)
const phone = computed(() => phoneOmitFn(store.getters.phone))
const viplevel = computed(() => store.getters.viplevel)
const isOpen = ref(false)
const vipIconUnHover = require('@/assets/images/vip_icon.png')
const logoutIconUnHover = require('@/assets/images/logout_icon.png')
const vipIconHover = require('@/assets/images/vip_icon2.png')
const logoutIconHover = require('@/assets/images/logout_icon2.png')
const vipIcon = ref(vipIconUnHover)
const logoutIcon = ref(logoutIconUnHover)

// 打开下拉菜单
const onOpen = () => {
  isOpen.value = !isOpen.value
}
// 跳转
const onPath = (path) => {
  navigationTo({ path })
}
// 退出登录
const onLogout = async () => {
  await logout()
}
// 移到指定项
const onHoverItem = (item) => {
  switch (item) {
    case 'vip':
      vipIcon.value = vipIconHover
      break
    default:
      logoutIcon.value = logoutIconHover
  }
}
// 移开指定项
const onUnHoverItem = (item) => {
  switch (item) {
    case 'vip':
      vipIcon.value = vipIconUnHover
      break
    default:
      logoutIcon.value = logoutIconUnHover
  }
}
</script>

<style scoped lang="scss">
.avatar-box {
  margin-right: 30px;
  text-align: right;
  .avatar-content {
    display: flex;
    align-items: center;
  }

  .avatar-label {
    display: inline-flex;
    align-items: center;
    width: 120px;
    white-space: nowrap;
    .label-item {
      margin: 0 10px;
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
    }
  }
}

.dropdown-first-item {
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 17px;
  .my-header-username {
    line-height: 10px;
    white-space: nowrap;
    .my-header-username-value {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
    }
    .my-header-username-label {
      display: inline-block;
      font-size: 0;
      transform: translateX(-7px) scale(0.6);
      .my-header-username-label-item {
        padding: 3px 5px;
        font-size: 12px;
        color: #f1cb8c;
        background-color: black;
      }
      .vip {
        color: black;
        background-color: #f1cb8c;
      }
      .novip {
        color: black;
        background-color: #c1c1c1;
      }
    }
  }
  .dropdown-first-item-tip {
    color: rgba(157, 157, 157, 100);
    font-size: 12px;
  }
}

.dropdown-icon {
  width: 23px;
  margin-right: 8px;
}

:deep(.el-dropdown-menu__item) {
  display: flex;
  align-items: center;
  color: rgba(89, 89, 89, 100);
  font-size: 12px !important;
  &:hover {
    color: rgba(178, 149, 93, 100) !important;
    background-color: transparent !important;
  }
}

.closeRotateAnimation {
  transform: rotate(0);
  animation: closeRotateAnimation 0.4s;
}

.openRotateAnimation {
  transform: rotate(180deg);
  animation: openRotateAnimation 0.4s;
}

@keyframes closeRotateAnimation {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes openRotateAnimation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
</style>
