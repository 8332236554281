export const AllCitys = [
  {
    text: '未知地区',
    children: [
      {
        text: '北京',
        children: [{ text: '--', gisGcj02Lat: 39.0, gisGcj02Lng: 120.0 }],
      },
    ],
  },
  {
    text: '北京',
    children: [
      {
        text: '北京',
        children: [
          {
            id: '110101',
            text: '东城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.935,
            gisBd09Lng: 116.422,
            gisGcj02Lat: 39.9288,
            gisGcj02Lng: 116.416,
            stubGroupCnt: 1,
            pinYin: 'Dongcheng',
          },
          {
            id: '110102',
            text: '西城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9183,
            gisBd09Lng: 116.373,
            gisGcj02Lat: 39.9123,
            gisGcj02Lng: 116.367,
            stubGroupCnt: 2,
            pinYin: 'Xicheng',
          },
          {
            id: '110103',
            text: '崇文区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9013,
            gisBd09Lng: 116.426,
            gisGcj02Lat: 39.8951,
            gisGcj02Lng: 116.42,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '110104',
            text: '宣武区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9028,
            gisBd09Lng: 116.395,
            gisGcj02Lat: 39.8965,
            gisGcj02Lng: 116.389,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '110105',
            text: '朝阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9275,
            gisBd09Lng: 116.45,
            gisGcj02Lat: 39.9217,
            gisGcj02Lng: 116.443,
            stubGroupCnt: 8,
            pinYin: 'Chaoyang',
          },
          {
            id: '110106',
            text: '丰台区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8645,
            gisBd09Lng: 116.293,
            gisGcj02Lat: 39.8581,
            gisGcj02Lng: 116.287,
            stubGroupCnt: 3,
            pinYin: 'Fengtai',
          },
          {
            id: '110107',
            text: '石景山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9114,
            gisBd09Lng: 116.23,
            gisGcj02Lat: 39.9058,
            gisGcj02Lng: 116.223,
            stubGroupCnt: 2,
            pinYin: 'Shijingshan',
          },
          {
            id: '110108',
            text: '海淀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9654,
            gisBd09Lng: 116.306,
            gisGcj02Lat: 39.9592,
            gisGcj02Lng: 116.299,
            stubGroupCnt: 4,
            pinYin: 'Haidian',
          },
          {
            id: '110109',
            text: '门头沟区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9466,
            gisBd09Lng: 116.109,
            gisGcj02Lat: 39.941,
            gisGcj02Lng: 116.102,
            stubGroupCnt: null,
            pinYin: 'Mentougou',
          },
          {
            id: '110111',
            text: '房山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7555,
            gisBd09Lng: 116.151,
            gisGcj02Lat: 39.7493,
            gisGcj02Lng: 116.145,
            stubGroupCnt: 2,
            pinYin: 'Fangshan',
          },
          {
            id: '110112',
            text: '通州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9124,
            gisBd09Lng: 116.671,
            gisGcj02Lat: 39.9063,
            gisGcj02Lng: 116.664,
            stubGroupCnt: 2,
            pinYin: 'Tongzhou',
          },
          {
            id: '110113',
            text: '顺义区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1358,
            gisBd09Lng: 116.661,
            gisGcj02Lat: 40.1295,
            gisGcj02Lng: 116.655,
            stubGroupCnt: null,
            pinYin: 'Shunyi',
          },
          {
            id: '110114',
            text: '昌平区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2259,
            gisBd09Lng: 116.238,
            gisGcj02Lat: 40.2202,
            gisGcj02Lng: 116.232,
            stubGroupCnt: 2,
            pinYin: 'Changping',
          },
          {
            id: '110115',
            text: '大兴区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7333,
            gisBd09Lng: 116.35,
            gisGcj02Lat: 39.7276,
            gisGcj02Lng: 116.343,
            stubGroupCnt: 1,
            pinYin: 'Daxing',
          },
          {
            id: '110116',
            text: '怀柔区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3225,
            gisBd09Lng: 116.639,
            gisGcj02Lat: 40.3162,
            gisGcj02Lng: 116.633,
            stubGroupCnt: null,
            pinYin: 'Huairou',
          },
          {
            id: '110117',
            text: '平谷区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 117.129,
            gisGcj02Lat: 40.1407,
            gisGcj02Lng: 117.123,
            stubGroupCnt: null,
            pinYin: 'Pinggu',
          },
          {
            id: '110228',
            text: '密云区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 40.3766,
            gisGcj02Lng: 116.415,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '110229',
            text: '区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 40.3766,
            gisGcj02Lng: 116.415,
            stubGroupCnt: null,
            pinYin: 'Yanqing',
          },
        ],
      },
    ],
  },
  {
    text: '上海',
    children: [
      {
        text: '上海',
        id: '310100',
        children: [
          {
            id: '310101',
            text: '黄浦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2384,
            gisBd09Lng: 121.492,
            gisGcj02Lat: 31.2326,
            gisGcj02Lng: 121.486,
            stubGroupCnt: 10,
            pinYin: 'Huangpu',
          },
          {
            id: '310103',
            text: '卢湾区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2133,
            gisBd09Lng: 121.482,
            gisGcj02Lat: 31.2074,
            gisGcj02Lng: 121.475,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '310104',
            text: '徐汇区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1917,
            gisBd09Lng: 121.443,
            gisGcj02Lat: 31.1853,
            gisGcj02Lng: 121.437,
            stubGroupCnt: 4,
            pinYin: 'Xuhui',
          },
          {
            id: '310105',
            text: '长宁区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2275,
            gisBd09Lng: 121.429,
            gisGcj02Lat: 31.2212,
            gisGcj02Lng: 121.423,
            stubGroupCnt: 7,
            pinYin: 'Changning',
          },
          {
            id: '310106',
            text: '静安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2327,
            gisBd09Lng: 121.462,
            gisGcj02Lat: 31.2265,
            gisGcj02Lng: 121.456,
            stubGroupCnt: 1,
            pinYin: "Jing'an",
          },
          {
            id: '310107',
            text: '普陀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2544,
            gisBd09Lng: 121.403,
            gisGcj02Lat: 31.2487,
            gisGcj02Lng: 121.397,
            stubGroupCnt: 4,
            pinYin: 'Putuo',
          },
          {
            id: '310108',
            text: '闸北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2533,
            gisBd09Lng: 121.466,
            gisGcj02Lat: 31.2472,
            gisGcj02Lng: 121.46,
            stubGroupCnt: 6,
            pinYin: 'Zhabei',
          },
          {
            id: '310109',
            text: '虹口区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2701,
            gisBd09Lng: 121.512,
            gisGcj02Lat: 31.2645,
            gisGcj02Lng: 121.505,
            stubGroupCnt: 9,
            pinYin: 'Hongkou',
          },
          {
            id: '310110',
            text: '杨浦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.266,
            gisBd09Lng: 121.533,
            gisGcj02Lat: 31.2601,
            gisGcj02Lng: 121.526,
            stubGroupCnt: 7,
            pinYin: 'Yangpu',
          },
          {
            id: '310112',
            text: '闵行区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1191,
            gisBd09Lng: 121.389,
            gisGcj02Lat: 31.1134,
            gisGcj02Lng: 121.382,
            stubGroupCnt: 42,
            pinYin: 'Minhang',
          },
          {
            id: '310113',
            text: '宝山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4109,
            gisBd09Lng: 121.496,
            gisGcj02Lat: 31.4052,
            gisGcj02Lng: 121.489,
            stubGroupCnt: 16,
            pinYin: 'Baoshan',
          },
          {
            id: '310114',
            text: '嘉定区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3802,
            gisBd09Lng: 121.273,
            gisGcj02Lat: 31.3746,
            gisGcj02Lng: 121.266,
            stubGroupCnt: 18,
            pinYin: 'Jiading',
          },
          {
            id: '310115',
            text: '浦东新区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.227,
            gisBd09Lng: 121.551,
            gisGcj02Lat: 31.2208,
            gisGcj02Lng: 121.544,
            stubGroupCnt: 83,
            pinYin: 'Pudong',
          },
          {
            id: '310116',
            text: '金山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7479,
            gisBd09Lng: 121.349,
            gisGcj02Lat: 30.7418,
            gisGcj02Lng: 121.343,
            stubGroupCnt: 18,
            pinYin: 'Jinshan',
          },
          {
            id: '310117',
            text: '松江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0385,
            gisBd09Lng: 121.233,
            gisGcj02Lat: 31.0325,
            gisGcj02Lng: 121.227,
            stubGroupCnt: 16,
            pinYin: 'Songjiang',
          },
          {
            id: '310118',
            text: '青浦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1556,
            gisBd09Lng: 121.131,
            gisGcj02Lat: 31.1499,
            gisGcj02Lng: 121.124,
            stubGroupCnt: 2,
            pinYin: 'Qingpu',
          },
          {
            id: '310119',
            text: '南汇区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0651,
            gisBd09Lng: 121.742,
            gisGcj02Lat: 31.0594,
            gisGcj02Lng: 121.736,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '310120',
            text: '奉贤区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9248,
            gisBd09Lng: 121.481,
            gisGcj02Lat: 30.9189,
            gisGcj02Lng: 121.475,
            stubGroupCnt: 29,
            pinYin: 'Fengxian',
          },
          {
            id: '310230',
            text: '崇明区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 30.9189,
            gisGcj02Lng: 121.475,
            stubGroupCnt: null,
            pinYin: 'Chongming',
          },
        ],
        gisBd09Lng: 121.48,
        gisBd09Lat: 31.2393,
        gisGcj02Lat: 31.2333,
        gisGcj02Lng: 121.474,
      },
    ],
  },
  {
    text: '天津',
    children: [
      {
        text: '天津',
        pinYin: 'Tianjin',
        id: '120100',
        gisGcj02Lng: 117.199,
        children: [
          {
            id: '120101',
            text: '和平区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1226,
            gisBd09Lng: 117.223,
            gisGcj02Lat: 39.1165,
            gisGcj02Lng: 117.217,
            stubGroupCnt: null,
            pinYin: 'Heping',
          },
          {
            id: '120102',
            text: '河东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1338,
            gisBd09Lng: 117.26,
            gisGcj02Lat: 39.1276,
            gisGcj02Lng: 117.254,
            stubGroupCnt: null,
            pinYin: 'Hedong',
          },
          {
            id: '120103',
            text: '河西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1137,
            gisBd09Lng: 117.232,
            gisGcj02Lat: 39.1074,
            gisGcj02Lng: 117.225,
            stubGroupCnt: null,
            pinYin: 'Hexi',
          },
          {
            id: '120104',
            text: '南开区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1431,
            gisBd09Lng: 117.158,
            gisGcj02Lat: 39.1371,
            gisGcj02Lng: 117.151,
            stubGroupCnt: null,
            pinYin: 'Nankai',
          },
          {
            id: '120105',
            text: '河北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1536,
            gisBd09Lng: 117.203,
            gisGcj02Lat: 39.1479,
            gisGcj02Lng: 117.197,
            stubGroupCnt: null,
            pinYin: 'Hebei',
          },
          {
            id: '120106',
            text: '红桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1742,
            gisBd09Lng: 117.159,
            gisGcj02Lat: 39.1683,
            gisGcj02Lng: 117.152,
            stubGroupCnt: null,
            pinYin: 'Hongqiao',
          },
          {
            id: '120107',
            text: '塘沽区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0425,
            gisBd09Lng: 117.679,
            gisGcj02Lat: 39.0368,
            gisGcj02Lng: 117.672,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '120108',
            text: '汉沽区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2557,
            gisBd09Lng: 117.814,
            gisGcj02Lat: 39.2497,
            gisGcj02Lng: 117.807,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '120109',
            text: '大港区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8529,
            gisBd09Lng: 117.473,
            gisGcj02Lat: 38.8466,
            gisGcj02Lng: 117.467,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '120110',
            text: '东丽区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.088,
            gisBd09Lng: 117.326,
            gisGcj02Lat: 39.0822,
            gisGcj02Lng: 117.319,
            stubGroupCnt: null,
            pinYin: 'Dongli',
          },
          {
            id: '120111',
            text: '西青区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1454,
            gisBd09Lng: 117.017,
            gisGcj02Lat: 39.1391,
            gisGcj02Lng: 117.01,
            stubGroupCnt: null,
            pinYin: 'Xiqing',
          },
          {
            id: '120112',
            text: '津南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9409,
            gisBd09Lng: 117.367,
            gisGcj02Lat: 38.9346,
            gisGcj02Lng: 117.361,
            stubGroupCnt: null,
            pinYin: 'Jinnan',
          },
          {
            id: '120113',
            text: '北辰区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2286,
            gisBd09Lng: 117.147,
            gisGcj02Lat: 39.2224,
            gisGcj02Lng: 117.14,
            stubGroupCnt: null,
            pinYin: 'Beichen',
          },
          {
            id: '120114',
            text: '武清区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3867,
            gisBd09Lng: 117.056,
            gisGcj02Lat: 39.381,
            gisGcj02Lng: 117.049,
            stubGroupCnt: null,
            pinYin: 'Wuqing',
          },
          {
            id: '120115',
            text: '宝坻区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7268,
            gisBd09Lng: 117.315,
            gisGcj02Lat: 39.7211,
            gisGcj02Lng: 117.309,
            stubGroupCnt: null,
            pinYin: 'Baodi',
          },
          {
            id: '120117',
            text: '宁河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.08.27 15:12:19',
            gisBd09Lat: 39.3379,
            gisBd09Lng: 117.83,
            gisGcj02Lat: 39.3317,
            gisGcj02Lng: 117.824,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '120118',
            text: '静海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 39.3317,
            gisGcj02Lng: 117.824,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '120221',
            text: '宁河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 39.099,
            gisGcj02Lng: 117.204,
            stubGroupCnt: null,
            pinYin: 'Ninghe',
          },
          {
            id: '120223',
            text: '静海区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 39.099,
            gisGcj02Lng: 117.204,
            stubGroupCnt: null,
            pinYin: 'Jinghai',
          },
          {
            id: '120225',
            text: '蓟州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 39.099,
            gisGcj02Lng: 117.204,
            stubGroupCnt: null,
            pinYin: 'Jixian',
          },
        ],
        gisBd09Lng: 117.206,
        gisBd09Lat: 39.0927,
        gisGcj02Lat: 39.0869,
      },
    ],
  },
  {
    text: '重庆',
    children: [
      {
        pinYin: 'Chongqing',
        id: '500100',
        gisGcj02Lng: 106.577,
        children: [
          {
            id: '500101',
            text: '万州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8141,
            gisBd09Lng: 108.417,
            gisGcj02Lat: 30.8084,
            gisGcj02Lng: 108.41,
            stubGroupCnt: null,
            pinYin: 'Wanzhou',
          },
          {
            id: '500102',
            text: '涪陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.71,
            gisBd09Lng: 107.395,
            gisGcj02Lat: 29.7037,
            gisGcj02Lng: 107.389,
            stubGroupCnt: null,
            pinYin: 'Fuling',
          },
          {
            id: '500103',
            text: '渝中区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5592,
            gisBd09Lng: 106.576,
            gisGcj02Lat: 29.5529,
            gisGcj02Lng: 106.569,
            stubGroupCnt: null,
            pinYin: 'Yuzhong',
          },
          {
            id: '500104',
            text: '大渡口区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4899,
            gisBd09Lng: 106.489,
            gisGcj02Lat: 29.4841,
            gisGcj02Lng: 106.483,
            stubGroupCnt: null,
            pinYin: 'Dadukou',
          },
          {
            id: '500105',
            text: '江北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6127,
            gisBd09Lng: 106.58,
            gisGcj02Lat: 29.6065,
            gisGcj02Lng: 106.574,
            stubGroupCnt: null,
            pinYin: 'Jiangbei',
          },
          {
            id: '500106',
            text: '沙坪坝区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5468,
            gisBd09Lng: 106.466,
            gisGcj02Lat: 29.5406,
            gisGcj02Lng: 106.46,
            stubGroupCnt: null,
            pinYin: 'Shapingba',
          },
          {
            id: '500107',
            text: '九龙坡区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5076,
            gisBd09Lng: 106.518,
            gisGcj02Lat: 29.5019,
            gisGcj02Lng: 106.512,
            stubGroupCnt: null,
            pinYin: 'Jiulongpo',
          },
          {
            id: '500108',
            text: '南岸区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5279,
            gisBd09Lng: 106.57,
            gisGcj02Lat: 29.5216,
            gisGcj02Lng: 106.563,
            stubGroupCnt: null,
            pinYin: "Nan'an",
          },
          {
            id: '500109',
            text: '北碚区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8116,
            gisBd09Lng: 106.405,
            gisGcj02Lat: 29.8058,
            gisGcj02Lng: 106.398,
            stubGroupCnt: null,
            pinYin: 'Beibei',
          },
          {
            id: '500110',
            text: '万盛区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9161,
            gisBd09Lng: 106.924,
            gisGcj02Lat: 28.9098,
            gisGcj02Lng: 106.918,
            stubGroupCnt: null,
            pinYin: 'Qijiang',
          },
          {
            id: '500111',
            text: '双桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5028,
            gisBd09Lng: 105.794,
            gisGcj02Lat: 29.4971,
            gisGcj02Lng: 105.787,
            stubGroupCnt: null,
            pinYin: 'Dazu',
          },
          {
            id: '500112',
            text: '渝北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7297,
            gisBd09Lng: 106.632,
            gisGcj02Lat: 29.724,
            gisGcj02Lng: 106.626,
            stubGroupCnt: null,
            pinYin: 'Yubei',
          },
          {
            id: '500113',
            text: '巴南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4097,
            gisBd09Lng: 106.549,
            gisGcj02Lat: 29.4035,
            gisGcj02Lng: 106.542,
            stubGroupCnt: null,
            pinYin: 'Banan',
          },
          {
            id: '500114',
            text: '黔江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5392,
            gisBd09Lng: 108.778,
            gisGcj02Lat: 29.5335,
            gisGcj02Lng: 108.772,
            stubGroupCnt: null,
            pinYin: 'Qianjiang',
          },
          {
            id: '500115',
            text: '长寿区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8639,
            gisBd09Lng: 107.088,
            gisGcj02Lat: 29.8581,
            gisGcj02Lng: 107.082,
            stubGroupCnt: null,
            pinYin: 'Changshou',
          },
        ],
        gisBd09Lng: 106.583,
        text: '重庆',
        gisBd09Lat: 29.5637,
        gisGcj02Lat: 29.5575,
      },
      {
        pinYin: null,
        id: '500200',
        gisGcj02Lng: 108.164,
        children: [
          {
            id: '500222',
            text: '綦江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9685,
            gisBd09Lng: 106.934,
            gisGcj02Lat: 28.9622,
            gisGcj02Lng: 106.928,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500223',
            text: '潼南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1966,
            gisBd09Lng: 105.847,
            gisGcj02Lat: 30.1903,
            gisGcj02Lng: 105.841,
            stubGroupCnt: null,
            pinYin: 'Tongnan',
          },
          {
            id: '500224',
            text: '铜梁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8508,
            gisBd09Lng: 106.065,
            gisGcj02Lat: 29.8446,
            gisGcj02Lng: 106.059,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500225',
            text: '大足县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4889,
            gisBd09Lng: 105.779,
            gisGcj02Lat: 29.4832,
            gisGcj02Lng: 105.773,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500226',
            text: '荣昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4112,
            gisBd09Lng: 105.604,
            gisGcj02Lat: 29.4048,
            gisGcj02Lng: 105.597,
            stubGroupCnt: null,
            pinYin: 'Rongchang',
          },
          {
            id: '500227',
            text: '璧山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5983,
            gisBd09Lng: 106.235,
            gisGcj02Lat: 29.5923,
            gisGcj02Lng: 106.229,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500228',
            text: '梁平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6804,
            gisBd09Lng: 107.812,
            gisGcj02Lat: 30.6746,
            gisGcj02Lng: 107.805,
            stubGroupCnt: null,
            pinYin: 'Liangping',
          },
          {
            id: '500229',
            text: '城口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9537,
            gisBd09Lng: 108.673,
            gisGcj02Lat: 31.948,
            gisGcj02Lng: 108.666,
            stubGroupCnt: null,
            pinYin: 'Chengkou',
          },
          {
            id: '500230',
            text: '丰都县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8689,
            gisBd09Lng: 107.739,
            gisGcj02Lat: 29.8629,
            gisGcj02Lng: 107.733,
            stubGroupCnt: null,
            pinYin: 'Fengdu',
          },
          {
            id: '500231',
            text: '垫江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3322,
            gisBd09Lng: 107.341,
            gisGcj02Lat: 30.3265,
            gisGcj02Lng: 107.335,
            stubGroupCnt: null,
            pinYin: 'Dianjiang',
          },
          {
            id: '500232',
            text: '武隆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3334,
            gisBd09Lng: 107.766,
            gisGcj02Lat: 29.3271,
            gisGcj02Lng: 107.76,
            stubGroupCnt: null,
            pinYin: 'Wulong',
          },
          {
            id: '500233',
            text: '忠　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3061,
            gisBd09Lng: 108.042,
            gisGcj02Lat: 30.3002,
            gisGcj02Lng: 108.036,
            stubGroupCnt: null,
            pinYin: 'Zhongxian',
          },
          {
            id: '500234',
            text: '开　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1672,
            gisBd09Lng: 108.401,
            gisGcj02Lat: 31.1613,
            gisGcj02Lng: 108.395,
            stubGroupCnt: null,
            pinYin: 'Kaixian',
          },
          {
            id: '500235',
            text: '云阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9369,
            gisBd09Lng: 108.705,
            gisGcj02Lat: 30.9307,
            gisGcj02Lng: 108.699,
            stubGroupCnt: null,
            pinYin: 'Yunyang',
          },
          {
            id: '500236',
            text: '奉节县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0244,
            gisBd09Lng: 109.472,
            gisGcj02Lat: 31.0184,
            gisGcj02Lng: 109.465,
            stubGroupCnt: null,
            pinYin: 'Fengjie',
          },
          {
            id: '500237',
            text: '巫山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0807,
            gisBd09Lng: 109.887,
            gisGcj02Lat: 31.0748,
            gisGcj02Lng: 109.881,
            stubGroupCnt: null,
            pinYin: 'Wushan',
          },
          {
            id: '500238',
            text: '巫溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.405,
            gisBd09Lng: 109.577,
            gisGcj02Lat: 31.3987,
            gisGcj02Lng: 109.571,
            stubGroupCnt: null,
            pinYin: 'Wuxi',
          },
          {
            id: '500240',
            text: '石柱土家族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0049,
            gisBd09Lng: 108.122,
            gisGcj02Lat: 29.9986,
            gisGcj02Lng: 108.115,
            stubGroupCnt: null,
            pinYin: 'Shizhu',
          },
          {
            id: '500241',
            text: '秀山土家族苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4534,
            gisBd09Lng: 109.014,
            gisGcj02Lat: 28.4476,
            gisGcj02Lng: 109.007,
            stubGroupCnt: null,
            pinYin: 'Xiushan',
          },
          {
            id: '500242',
            text: '酉阳土家族苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.847,
            gisBd09Lng: 108.775,
            gisGcj02Lat: 28.8412,
            gisGcj02Lng: 108.768,
            stubGroupCnt: null,
            pinYin: 'Youyang',
          },
          {
            id: '500243',
            text: '彭水苗族土家族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2998,
            gisBd09Lng: 108.171,
            gisGcj02Lat: 29.2941,
            gisGcj02Lng: 108.164,
            stubGroupCnt: null,
            pinYin: 'Pengshui',
          },
        ],
        gisBd09Lng: null,
        text: '县',
        gisBd09Lat: null,
        gisGcj02Lat: 29.2941,
      },
      {
        pinYin: 'Liangjiangxinqu',
        id: '500300',
        gisGcj02Lng: 106.262,
        children: [
          {
            id: '500381',
            text: '江津区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2959,
            gisBd09Lng: 106.268,
            gisGcj02Lat: 29.2903,
            gisGcj02Lng: 106.262,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500382',
            text: '合川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9783,
            gisBd09Lng: 106.284,
            gisGcj02Lat: 29.9725,
            gisGcj02Lng: 106.278,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500383',
            text: '永川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3621,
            gisBd09Lng: 105.935,
            gisGcj02Lat: 29.3561,
            gisGcj02Lng: 105.929,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '500384',
            text: '南川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1634,
            gisBd09Lng: 107.108,
            gisGcj02Lat: 29.1578,
            gisGcj02Lng: 107.101,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 106.268,
        text: '两江新区',
        gisBd09Lat: 29.2959,
        gisGcj02Lat: 29.2903,
      },
    ],
  },
  {
    text: '香港',
    children: [
      {
        pinYin: '',
        id: '999077',
        gisGcj02Lng: 0,
        children: [
          {
            id: '999077',
            text: '油尖旺区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999077',
            text: '观塘区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Pinggu',
          },
          {
            id: '999077',
            text: '九龙城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Pinggu',
          },
          {
            id: '999077',
            text: '黄大仙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Pinggu',
          },
          {
            id: '999077',
            text: '深水埗区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Pinggu',
          },
        ],
        gisBd09Lng: 0,
        text: '九龙半岛',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
      {
        pinYin: null,
        id: '999077',
        gisGcj02Lng: 116.843,
        children: [
          {
            id: '999077',
            text: '湾仔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '中西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '香港岛',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
      {
        pinYin: null,
        id: '999077',
        gisGcj02Lng: 0,
        children: [
          {
            id: '999077',
            text: '北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '大埔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '荃湾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '屯门区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '元朗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '葵青区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '离岛区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '沙田区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: 'Miyun',
          },
          {
            id: '999077',
            text: '西贡区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.39,
            gisGcj02Lng: 114.18,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '新界',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
    ],
  },
  {
    text: '澳门',
    children: [
      {
        pinYin: '',
        id: '999078',
        gisGcj02Lng: 0,
        children: [
          {
            id: '999078',
            text: '圣方济各堂区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.147,
            gisBd09Lng: 0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '路环',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
      {
        pinYin: null,
        id: '999078',
        gisGcj02Lng: 116.843,
        children: [
          {
            id: '999078',
            text: '嘉模堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '氹仔',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
      {
        pinYin: null,
        id: '999078',
        gisGcj02Lng: 0,
        children: [
          {
            id: '999078',
            text: '风顺堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999078',
            text: '望德堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999078',
            text: '大堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999078',
            text: '圣安多尼堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999078',
            text: '花地玛堂区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.149,
            gisGcj02Lng: 113.568,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '澳门半岛',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
    ],
  },
  {
    text: '台湾省',
    children: [
      {
        pinYin: null,
        id: '999079',
        gisGcj02Lng: 121.478,
        children: [
          {
            id: '999079',
            text: '板桥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '汐止区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新店区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '永和区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '中和区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '土城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '树林区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '三重区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新庄区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '芦洲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '瑞芳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '三峡区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '莺歌区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '淡水区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '万里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '金山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '深坑区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '石碇区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '平溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '双溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '贡寮区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '坪林区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '乌来区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '泰山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '林口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '五股区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '八里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '三芝区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '石门区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 25.019,
            gisGcj02Lng: 121.478,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '新北市',
        gisBd09Lat: 0,
        gisGcj02Lat: 25.019,
      },
      {
        pinYin: null,
        id: '999079',
        gisGcj02Lng: 121.308,
        children: [
          {
            id: '999079',
            text: '桃园区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '中坜区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '平镇区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '八德区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '杨梅区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '芦竹区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '龙潭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '龟山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大园区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '观音区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新屋区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '复兴区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.998,
            gisGcj02Lng: 121.308,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '桃园市',
        gisBd09Lat: 0,
        gisGcj02Lat: 24.998,
      },
      {
        pinYin: null,
        id: '999079',
        gisGcj02Lng: 0,
        children: [
          {
            id: '999079',
            text: '中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '北屯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '西屯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '南屯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '太平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '雾峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '乌日区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '丰原区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '后里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '潭子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大雅区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '神冈区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '石冈区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '东势区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新社区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '和平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大肚区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '沙鹿区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '龙井区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '梧栖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '清水区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大甲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '外埔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 24.166,
            gisGcj02Lng: 120.655,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 120.67,
        text: '台中市',
        gisBd09Lat: 24.15,
        gisGcj02Lat: 24.15,
      },
      {
        pinYin: 'taizhong',
        id: '999079',
        gisGcj02Lng: 120.67,
        children: [
          {
            id: '999079',
            text: '中西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '安平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '安南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '永康区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '归仁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新化区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '左镇区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '玉井区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '楠西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '南化区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '仁德区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '关庙区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '龙崎区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '官田区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '麻豆区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '佳里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '西港区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '七股区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '将军区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '学甲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '北门区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '后壁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '白河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '东山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '六甲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '下营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '柳营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '盐水区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '善化区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '大内区区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '山上区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '新市区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '安定区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.004,
            gisGcj02Lng: 120.2,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 0,
        text: '台南市',
        gisBd09Lat: 0,
        gisGcj02Lat: 0,
      },
      {
        pinYin: 'gaoxiong',
        id: '999079',
        gisGcj02Lng: 120.28,
        children: [
          {
            id: '999079',
            text: '旧高雄区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.62,
            gisBd09Lng: 120.28,
            gisGcj02Lat: 22.62,
            gisGcj02Lng: 120.28,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '凤山地区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.62,
            gisGcj02Lng: 120.28,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '冈山地区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.62,
            gisGcj02Lng: 120.28,
            stubGroupCnt: null,
            pinYin: '',
          },
          {
            id: '999079',
            text: '旗山地区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.62,
            gisGcj02Lng: 120.28,
            stubGroupCnt: null,
            pinYin: '',
          },
        ],
        gisBd09Lng: 120.28,
        text: '高雄市',
        gisBd09Lat: 22.62,
        gisGcj02Lat: 22.62,
      },
    ],
  },
  {
    text: '安徽省',
    children: [
      {
        pinYin: 'Hefei',
        id: '340100',
        gisGcj02Lng: 117.23,
        children: [
          {
            id: '340101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8266,
            gisBd09Lng: 117.236,
            gisGcj02Lat: 31.8203,
            gisGcj02Lng: 117.23,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340102',
            text: '瑶海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8639,
            gisBd09Lng: 117.317,
            gisGcj02Lat: 31.8582,
            gisGcj02Lng: 117.31,
            stubGroupCnt: 0,
            pinYin: 'Yaohai',
          },
          {
            id: '340103',
            text: '庐阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8842,
            gisBd09Lng: 117.272,
            gisGcj02Lat: 31.8781,
            gisGcj02Lng: 117.265,
            stubGroupCnt: 0,
            pinYin: 'Luyang',
          },
          {
            id: '340104',
            text: '蜀山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.858,
            gisBd09Lng: 117.269,
            gisGcj02Lat: 31.8518,
            gisGcj02Lng: 117.262,
            stubGroupCnt: 0,
            pinYin: 'Shushan',
          },
          {
            id: '340111',
            text: '包河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7997,
            gisBd09Lng: 117.318,
            gisGcj02Lat: 31.794,
            gisGcj02Lng: 117.311,
            stubGroupCnt: 0,
            pinYin: 'Baohe',
          },
          {
            id: '340121',
            text: '长丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4822,
            gisBd09Lng: 117.176,
            gisGcj02Lat: 32.4765,
            gisGcj02Lng: 117.169,
            stubGroupCnt: null,
            pinYin: 'Changfeng',
          },
          {
            id: '340122',
            text: '肥东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8946,
            gisBd09Lng: 117.477,
            gisGcj02Lat: 31.8883,
            gisGcj02Lng: 117.471,
            stubGroupCnt: null,
            pinYin: 'Feidong',
          },
          {
            id: '340123',
            text: '肥西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7124,
            gisBd09Lng: 117.165,
            gisGcj02Lat: 31.7065,
            gisGcj02Lng: 117.159,
            stubGroupCnt: null,
            pinYin: 'Feixi',
          },
        ],
        gisBd09Lng: 117.236,
        text: '合肥',
        gisBd09Lat: 31.8266,
        gisGcj02Lat: 31.8203,
      },
      {
        pinYin: 'Wuhu',
        id: '340200',
        gisGcj02Lng: 118.433,
        children: [
          {
            id: '340201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3589,
            gisBd09Lng: 118.44,
            gisGcj02Lat: 31.3526,
            gisGcj02Lng: 118.434,
            stubGroupCnt: 14,
            pinYin: null,
          },
          {
            id: '340202',
            text: '镜湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3464,
            gisBd09Lng: 118.392,
            gisGcj02Lat: 31.3407,
            gisGcj02Lng: 118.385,
            stubGroupCnt: 14,
            pinYin: 'Jinghu',
          },
          {
            id: '340203',
            text: '弋江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3176,
            gisBd09Lng: 118.38,
            gisGcj02Lat: 31.3119,
            gisGcj02Lng: 118.373,
            stubGroupCnt: null,
            pinYin: 'Yijiang',
          },
          {
            id: '340204',
            text: '新芜区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3554,
            gisBd09Lng: 118.372,
            gisGcj02Lat: 31.3497,
            gisGcj02Lng: 118.365,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '340207',
            text: '鸠江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3754,
            gisBd09Lng: 118.4,
            gisGcj02Lat: 31.3697,
            gisGcj02Lng: 118.393,
            stubGroupCnt: 10,
            pinYin: 'Jiujiang',
          },
          {
            id: '340221',
            text: '芜湖县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1403,
            gisBd09Lng: 118.582,
            gisGcj02Lat: 31.1341,
            gisGcj02Lng: 118.576,
            stubGroupCnt: 4,
            pinYin: 'Wuhu',
          },
          {
            id: '340222',
            text: '繁昌县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0895,
            gisBd09Lng: 118.205,
            gisGcj02Lat: 31.0831,
            gisGcj02Lng: 118.199,
            stubGroupCnt: 0,
            pinYin: 'Fanchang',
          },
          {
            id: '340223',
            text: '南陵县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9211,
            gisBd09Lng: 118.341,
            gisGcj02Lat: 30.9148,
            gisGcj02Lng: 118.335,
            stubGroupCnt: 1,
            pinYin: 'Nanling',
          },
          {
            id: '340225',
            text: '无为县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9013,
            gisBd09Lng: 116.426,
            gisGcj02Lat: 39.8951,
            gisGcj02Lng: 116.42,
            stubGroupCnt: null,
            pinYin: 'Wuwei',
          },
        ],
        gisBd09Lng: 118.44,
        text: '芜湖',
        gisBd09Lat: 31.3589,
        gisGcj02Lat: 31.3525,
      },
      {
        pinYin: 'Bengbu',
        id: '340300',
        gisGcj02Lng: 117.389,
        children: [
          {
            id: '340301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9209,
            gisBd09Lng: 117.396,
            gisGcj02Lat: 32.9149,
            gisGcj02Lng: 117.389,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340302',
            text: '龙子湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9481,
            gisBd09Lng: 117.401,
            gisGcj02Lat: 32.9422,
            gisGcj02Lng: 117.395,
            stubGroupCnt: null,
            pinYin: 'Longzihu',
          },
          {
            id: '340303',
            text: '蚌山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9505,
            gisBd09Lng: 117.374,
            gisGcj02Lat: 32.9442,
            gisGcj02Lng: 117.368,
            stubGroupCnt: null,
            pinYin: 'Bengshan',
          },
          {
            id: '340304',
            text: '禹会区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9355,
            gisBd09Lng: 117.348,
            gisGcj02Lat: 32.9293,
            gisGcj02Lng: 117.342,
            stubGroupCnt: null,
            pinYin: 'Yuhui',
          },
          {
            id: '340311',
            text: '淮上区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9706,
            gisBd09Lng: 117.366,
            gisGcj02Lat: 32.9643,
            gisGcj02Lng: 117.36,
            stubGroupCnt: null,
            pinYin: 'Huaishang',
          },
          {
            id: '340321',
            text: '怀远县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9762,
            gisBd09Lng: 117.213,
            gisGcj02Lat: 32.9703,
            gisGcj02Lng: 117.206,
            stubGroupCnt: null,
            pinYin: 'Huaiyuan',
          },
          {
            id: '340322',
            text: '五河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1508,
            gisBd09Lng: 117.892,
            gisGcj02Lat: 33.1451,
            gisGcj02Lng: 117.885,
            stubGroupCnt: null,
            pinYin: 'Wuhe',
          },
          {
            id: '340323',
            text: '固镇县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.323,
            gisBd09Lng: 117.323,
            gisGcj02Lat: 33.3172,
            gisGcj02Lng: 117.316,
            stubGroupCnt: null,
            pinYin: 'Guzhen',
          },
        ],
        gisBd09Lng: 117.396,
        text: '蚌埠',
        gisBd09Lat: 32.9209,
        gisGcj02Lat: 32.9149,
      },
      {
        pinYin: 'Huainan',
        id: '340400',
        gisGcj02Lng: 117.0,
        children: [
          {
            id: '340401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6318,
            gisBd09Lng: 117.006,
            gisGcj02Lat: 32.6254,
            gisGcj02Lng: 117.0,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340402',
            text: '大通区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6362,
            gisBd09Lng: 117.059,
            gisGcj02Lat: 32.6305,
            gisGcj02Lng: 117.053,
            stubGroupCnt: null,
            pinYin: 'Datong',
          },
          {
            id: '340403',
            text: '田家庵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.653,
            gisBd09Lng: 117.024,
            gisGcj02Lat: 32.6468,
            gisGcj02Lng: 117.018,
            stubGroupCnt: null,
            pinYin: 'Tianjiaan',
          },
          {
            id: '340404',
            text: '谢家集区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6051,
            gisBd09Lng: 116.865,
            gisGcj02Lat: 32.599,
            gisGcj02Lng: 116.859,
            stubGroupCnt: null,
            pinYin: 'Xiejiaji',
          },
          {
            id: '340405',
            text: '八公山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6352,
            gisBd09Lng: 116.84,
            gisGcj02Lat: 32.6295,
            gisGcj02Lng: 116.834,
            stubGroupCnt: null,
            pinYin: 'Bagongshan',
          },
          {
            id: '340406',
            text: '潘集区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7777,
            gisBd09Lng: 116.843,
            gisGcj02Lat: 32.7719,
            gisGcj02Lng: 116.836,
            stubGroupCnt: null,
            pinYin: 'Panji',
          },
          {
            id: '340421',
            text: '凤台县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7147,
            gisBd09Lng: 116.717,
            gisGcj02Lat: 32.709,
            gisGcj02Lng: 116.71,
            stubGroupCnt: null,
            pinYin: 'Fengtai',
          },
        ],
        gisBd09Lng: 117.006,
        text: '淮南',
        gisBd09Lat: 32.6318,
        gisGcj02Lat: 32.6254,
      },
      {
        pinYin: "Ma'anshan",
        id: '340500',
        gisGcj02Lng: 118.505,
        children: [
          {
            id: '340501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6752,
            gisBd09Lng: 118.512,
            gisGcj02Lat: 31.6695,
            gisGcj02Lng: 118.505,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340502',
            text: '金家庄区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7445,
            gisBd09Lng: 118.526,
            gisGcj02Lat: 31.7386,
            gisGcj02Lng: 118.519,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340503',
            text: '花山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7052,
            gisBd09Lng: 118.517,
            gisGcj02Lat: 31.6995,
            gisGcj02Lng: 118.511,
            stubGroupCnt: null,
            pinYin: 'Huashan',
          },
          {
            id: '340504',
            text: '雨山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6863,
            gisBd09Lng: 118.504,
            gisGcj02Lat: 31.6807,
            gisGcj02Lng: 118.498,
            stubGroupCnt: 0,
            pinYin: 'Yushan',
          },
          {
            id: '340521',
            text: '当涂县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5756,
            gisBd09Lng: 118.505,
            gisGcj02Lat: 31.5699,
            gisGcj02Lng: 118.498,
            stubGroupCnt: null,
            pinYin: 'Dangtu',
          },
        ],
        gisBd09Lng: 118.512,
        text: '马鞍山',
        gisBd09Lat: 31.6752,
        gisGcj02Lat: 31.6695,
      },
      {
        pinYin: 'Huaibei',
        id: '340600',
        gisGcj02Lng: 116.792,
        children: [
          {
            id: '340601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9634,
            gisBd09Lng: 116.799,
            gisGcj02Lat: 33.9574,
            gisGcj02Lng: 116.793,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340602',
            text: '杜集区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9961,
            gisBd09Lng: 116.835,
            gisGcj02Lat: 33.9904,
            gisGcj02Lng: 116.828,
            stubGroupCnt: null,
            pinYin: 'Duji',
          },
          {
            id: '340603',
            text: '相山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9651,
            gisBd09Lng: 116.801,
            gisGcj02Lat: 33.9592,
            gisGcj02Lng: 116.795,
            stubGroupCnt: null,
            pinYin: 'Xiangshan',
          },
          {
            id: '340604',
            text: '烈山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9004,
            gisBd09Lng: 116.82,
            gisGcj02Lat: 33.8948,
            gisGcj02Lng: 116.813,
            stubGroupCnt: null,
            pinYin: 'Lieshan',
          },
          {
            id: '340621',
            text: '濉溪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9214,
            gisBd09Lng: 116.772,
            gisGcj02Lat: 33.9151,
            gisGcj02Lng: 116.766,
            stubGroupCnt: null,
            pinYin: 'Suixi',
          },
        ],
        gisBd09Lng: 116.799,
        text: '淮北',
        gisBd09Lat: 33.9634,
        gisGcj02Lat: 33.9574,
      },
      {
        pinYin: 'Tongling',
        id: '340700',
        gisGcj02Lng: 117.812,
        children: [
          {
            id: '340701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.951,
            gisBd09Lng: 117.819,
            gisGcj02Lat: 30.945,
            gisGcj02Lng: 117.812,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340702',
            text: '铜官山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.94,
            gisBd09Lng: 117.822,
            gisGcj02Lat: 30.9339,
            gisGcj02Lng: 117.815,
            stubGroupCnt: null,
            pinYin: 'Tongguanshan',
          },
          {
            id: '340703',
            text: '狮子山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9614,
            gisBd09Lng: 117.881,
            gisGcj02Lat: 30.9555,
            gisGcj02Lng: 117.874,
            stubGroupCnt: null,
            pinYin: 'Shizishan',
          },
          {
            id: '340711',
            text: '郊　区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9103,
            gisBd09Lng: 117.808,
            gisGcj02Lat: 30.9045,
            gisGcj02Lng: 117.801,
            stubGroupCnt: null,
            pinYin: 'Jiaoqu',
          },
          {
            id: '340721',
            text: '铜陵县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9591,
            gisBd09Lng: 117.798,
            gisGcj02Lat: 30.9534,
            gisGcj02Lng: 117.791,
            stubGroupCnt: null,
            pinYin: 'Tongling',
          },
        ],
        gisBd09Lng: 117.819,
        text: '铜陵',
        gisBd09Lat: 30.951,
        gisGcj02Lat: 30.945,
      },
      {
        pinYin: 'Anqing',
        id: '340800',
        gisGcj02Lng: 117.065,
        children: [
          {
            id: '340801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 30.511,
            gisGcj02Lng: 117.091,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '340802',
            text: '迎江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.517,
            gisBd09Lng: 117.097,
            gisGcj02Lat: 30.511,
            gisGcj02Lng: 117.091,
            stubGroupCnt: null,
            pinYin: 'Yingjiang',
          },
          {
            id: '340803',
            text: '大观区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5182,
            gisBd09Lng: 117.041,
            gisGcj02Lat: 30.5123,
            gisGcj02Lng: 117.034,
            stubGroupCnt: null,
            pinYin: 'Daguan',
          },
          {
            id: '340811',
            text: '郊　区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9104,
            gisBd09Lng: 117.807,
            gisGcj02Lat: 30.9045,
            gisGcj02Lng: 117.801,
            stubGroupCnt: null,
            pinYin: 'Yixiu',
          },
          {
            id: '340822',
            text: '怀宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7383,
            gisBd09Lng: 116.836,
            gisGcj02Lat: 30.7326,
            gisGcj02Lng: 116.829,
            stubGroupCnt: null,
            pinYin: 'Huaining',
          },
          {
            id: '340823',
            text: '枞阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7058,
            gisBd09Lng: 117.227,
            gisGcj02Lat: 30.6996,
            gisGcj02Lng: 117.22,
            stubGroupCnt: null,
            pinYin: 'Zongyang',
          },
          {
            id: '340824',
            text: '潜山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6352,
            gisBd09Lng: 116.59,
            gisGcj02Lat: 30.6296,
            gisGcj02Lng: 116.583,
            stubGroupCnt: null,
            pinYin: 'Qianshan',
          },
          {
            id: '340825',
            text: '太湖县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4601,
            gisBd09Lng: 116.315,
            gisGcj02Lat: 30.454,
            gisGcj02Lng: 116.309,
            stubGroupCnt: null,
            pinYin: 'Taihu',
          },
          {
            id: '340826',
            text: '宿松县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1572,
            gisBd09Lng: 116.136,
            gisGcj02Lat: 30.1513,
            gisGcj02Lng: 116.129,
            stubGroupCnt: null,
            pinYin: 'Susong',
          },
          {
            id: '340827',
            text: '望江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1295,
            gisBd09Lng: 116.701,
            gisGcj02Lat: 30.1238,
            gisGcj02Lng: 116.694,
            stubGroupCnt: null,
            pinYin: 'Wangjiang',
          },
          {
            id: '340828',
            text: '岳西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.854,
            gisBd09Lng: 116.368,
            gisGcj02Lat: 30.8481,
            gisGcj02Lng: 116.361,
            stubGroupCnt: null,
            pinYin: 'Yuexi',
          },
          {
            id: '340881',
            text: '桐城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0413,
            gisBd09Lng: 116.98,
            gisGcj02Lat: 31.0352,
            gisGcj02Lng: 116.974,
            stubGroupCnt: null,
            pinYin: 'Tongcheng',
          },
        ],
        gisBd09Lng: 117.071,
        text: '安庆',
        gisBd09Lat: 30.5482,
        gisGcj02Lat: 30.5425,
      },
      {
        pinYin: 'Huangshan',
        id: '341000',
        gisGcj02Lng: 118.168,
        children: [
          {
            id: '341001',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1344,
            gisBd09Lng: 118.174,
            gisGcj02Lat: 30.1284,
            gisGcj02Lng: 118.168,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341002',
            text: '屯溪区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7028,
            gisBd09Lng: 118.321,
            gisGcj02Lat: 29.6964,
            gisGcj02Lng: 118.315,
            stubGroupCnt: 1,
            pinYin: 'Tunxi',
          },
          {
            id: '341003',
            text: '黄山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2786,
            gisBd09Lng: 118.148,
            gisGcj02Lat: 30.273,
            gisGcj02Lng: 118.141,
            stubGroupCnt: 0,
            pinYin: 'Huangshan',
          },
          {
            id: '341004',
            text: '徽州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8342,
            gisBd09Lng: 118.343,
            gisGcj02Lat: 29.828,
            gisGcj02Lng: 118.337,
            stubGroupCnt: null,
            pinYin: 'Huizhou',
          },
          {
            id: '341021',
            text: '歙　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8667,
            gisBd09Lng: 118.42,
            gisGcj02Lat: 29.8606,
            gisGcj02Lng: 118.414,
            stubGroupCnt: null,
            pinYin: 'Shexian',
          },
          {
            id: '341022',
            text: '休宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7922,
            gisBd09Lng: 118.189,
            gisGcj02Lat: 29.786,
            gisGcj02Lng: 118.183,
            stubGroupCnt: null,
            pinYin: 'Xiuning',
          },
          {
            id: '341023',
            text: '黟　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9302,
            gisBd09Lng: 117.945,
            gisGcj02Lat: 29.924,
            gisGcj02Lng: 117.939,
            stubGroupCnt: null,
            pinYin: 'Yixian',
          },
          {
            id: '341024',
            text: '祁门县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8607,
            gisBd09Lng: 117.723,
            gisGcj02Lat: 29.8544,
            gisGcj02Lng: 117.717,
            stubGroupCnt: null,
            pinYin: 'Qimen',
          },
        ],
        gisBd09Lng: 118.174,
        text: '黄山',
        gisBd09Lat: 30.1344,
        gisGcj02Lat: 30.1284,
      },
      {
        pinYin: 'Chuzhou',
        id: '341100',
        gisGcj02Lng: 118.317,
        children: [
          {
            id: '341101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3081,
            gisBd09Lng: 118.324,
            gisGcj02Lat: 32.3017,
            gisGcj02Lng: 118.317,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341102',
            text: '琅琊区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3,
            gisBd09Lng: 118.312,
            gisGcj02Lat: 32.2937,
            gisGcj02Lng: 118.306,
            stubGroupCnt: null,
            pinYin: 'Langya',
          },
          {
            id: '341103',
            text: '南谯区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3589,
            gisBd09Lng: 118.178,
            gisGcj02Lat: 32.3529,
            gisGcj02Lng: 118.171,
            stubGroupCnt: null,
            pinYin: 'Nanqiao',
          },
          {
            id: '341122',
            text: '来安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4576,
            gisBd09Lng: 118.443,
            gisGcj02Lat: 32.4513,
            gisGcj02Lng: 118.437,
            stubGroupCnt: null,
            pinYin: "Lai'an",
          },
          {
            id: '341124',
            text: '全椒县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0899,
            gisBd09Lng: 118.28,
            gisGcj02Lat: 32.0842,
            gisGcj02Lng: 118.273,
            stubGroupCnt: null,
            pinYin: 'Quanjiao',
          },
          {
            id: '341125',
            text: '定远县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5357,
            gisBd09Lng: 117.706,
            gisGcj02Lat: 32.5295,
            gisGcj02Lng: 117.699,
            stubGroupCnt: null,
            pinYin: 'Dingyuan',
          },
          {
            id: '341126',
            text: '凤阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8722,
            gisBd09Lng: 117.569,
            gisGcj02Lat: 32.8663,
            gisGcj02Lng: 117.562,
            stubGroupCnt: null,
            pinYin: 'Fengyang',
          },
          {
            id: '341181',
            text: '天长',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6952,
            gisBd09Lng: 119.01,
            gisGcj02Lat: 32.6893,
            gisGcj02Lng: 119.004,
            stubGroupCnt: null,
            pinYin: 'Tianchang',
          },
          {
            id: '341182',
            text: '明光',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7836,
            gisBd09Lng: 117.997,
            gisGcj02Lat: 32.7776,
            gisGcj02Lng: 117.991,
            stubGroupCnt: null,
            pinYin: 'Mingguang',
          },
        ],
        gisBd09Lng: 118.324,
        text: '滁州',
        gisBd09Lat: 32.3081,
        gisGcj02Lat: 32.3017,
      },
      {
        pinYin: 'Fuyang',
        id: '341200',
        gisGcj02Lng: 115.815,
        children: [
          {
            id: '341201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8959,
            gisBd09Lng: 115.821,
            gisGcj02Lat: 32.8896,
            gisGcj02Lng: 115.815,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341202',
            text: '颍州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8887,
            gisBd09Lng: 115.815,
            gisGcj02Lat: 32.8824,
            gisGcj02Lng: 115.809,
            stubGroupCnt: null,
            pinYin: 'Yingzhou',
          },
          {
            id: '341203',
            text: '颍东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9178,
            gisBd09Lng: 115.863,
            gisGcj02Lat: 32.9121,
            gisGcj02Lng: 115.857,
            stubGroupCnt: null,
            pinYin: 'Yingdong',
          },
          {
            id: '341204',
            text: '颍泉区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9289,
            gisBd09Lng: 115.816,
            gisGcj02Lat: 32.9226,
            gisGcj02Lng: 115.809,
            stubGroupCnt: null,
            pinYin: 'Yingquan',
          },
          {
            id: '341221',
            text: '临泉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0786,
            gisBd09Lng: 115.271,
            gisGcj02Lat: 33.0729,
            gisGcj02Lng: 115.265,
            stubGroupCnt: null,
            pinYin: 'Linquan',
          },
          {
            id: '341222',
            text: '太和县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1657,
            gisBd09Lng: 115.63,
            gisGcj02Lat: 33.16,
            gisGcj02Lng: 115.623,
            stubGroupCnt: null,
            pinYin: 'Taihe',
          },
          {
            id: '341225',
            text: '阜南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6632,
            gisBd09Lng: 115.601,
            gisGcj02Lat: 32.6573,
            gisGcj02Lng: 115.595,
            stubGroupCnt: null,
            pinYin: 'Funan',
          },
          {
            id: '341226',
            text: '颍上县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6349,
            gisBd09Lng: 116.271,
            gisGcj02Lat: 32.6286,
            gisGcj02Lng: 116.264,
            stubGroupCnt: null,
            pinYin: 'Yingshang',
          },
          {
            id: '341282',
            text: '界首',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2616,
            gisBd09Lng: 115.382,
            gisGcj02Lat: 33.256,
            gisGcj02Lng: 115.375,
            stubGroupCnt: null,
            pinYin: 'Jieshou',
          },
        ],
        gisBd09Lng: 115.821,
        text: '阜阳',
        gisBd09Lat: 32.8959,
        gisGcj02Lat: 32.8896,
      },
      {
        pinYin: 'Suzhou',
        id: '341300',
        gisGcj02Lng: 116.965,
        children: [
          {
            id: '341301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6512,
            gisBd09Lng: 116.971,
            gisGcj02Lat: 33.6453,
            gisGcj02Lng: 116.965,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341302',
            text: '墉桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6469,
            gisBd09Lng: 116.982,
            gisGcj02Lat: 33.6408,
            gisGcj02Lng: 116.976,
            stubGroupCnt: null,
            pinYin: 'Yongqiao',
          },
          {
            id: '341321',
            text: '砀山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4465,
            gisBd09Lng: 116.373,
            gisGcj02Lat: 34.4406,
            gisGcj02Lng: 116.367,
            stubGroupCnt: null,
            pinYin: 'Dangshan',
          },
          {
            id: '341322',
            text: '萧　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4165,
            gisBd09Lng: 116.495,
            gisGcj02Lat: 34.4106,
            gisGcj02Lng: 116.488,
            stubGroupCnt: null,
            pinYin: 'Xiaoxian',
          },
          {
            id: '341323',
            text: '灵璧县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5477,
            gisBd09Lng: 117.566,
            gisGcj02Lat: 33.5419,
            gisGcj02Lng: 117.559,
            stubGroupCnt: null,
            pinYin: 'Lingbi',
          },
          {
            id: '341324',
            text: '泗　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5736,
            gisBd09Lng: 117.866,
            gisGcj02Lat: 33.5674,
            gisGcj02Lng: 117.859,
            stubGroupCnt: null,
            pinYin: 'Sixian',
          },
        ],
        gisBd09Lng: 116.971,
        text: '宿州',
        gisBd09Lat: 33.6512,
        gisGcj02Lat: 33.6453,
      },
      {
        pinYin: null,
        id: '341400',
        gisGcj02Lng: 117.874,
        children: [
          {
            id: '341401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6088,
            gisBd09Lng: 117.88,
            gisGcj02Lat: 31.6028,
            gisGcj02Lng: 117.874,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341402',
            text: '居巢区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6504,
            gisBd09Lng: 117.728,
            gisGcj02Lat: 31.6441,
            gisGcj02Lng: 117.722,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341421',
            text: '庐江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2732,
            gisBd09Lng: 117.332,
            gisGcj02Lat: 31.2673,
            gisGcj02Lng: 117.326,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341422',
            text: '无为县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2569,
            gisBd09Lng: 117.865,
            gisGcj02Lat: 31.2507,
            gisGcj02Lng: 117.858,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '341423',
            text: '含山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.662,
            gisBd09Lng: 118.052,
            gisGcj02Lat: 31.6561,
            gisGcj02Lng: 118.046,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341424',
            text: '和　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.716,
            gisBd09Lng: 118.294,
            gisGcj02Lat: 31.71,
            gisGcj02Lng: 118.287,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 117.88,
        text: '巢湖',
        gisBd09Lat: 31.6088,
        gisGcj02Lat: 31.6028,
      },
      {
        pinYin: "Lu'an",
        id: '341500',
        gisGcj02Lng: 116.521,
        children: [
          {
            id: '341501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7401,
            gisBd09Lng: 116.528,
            gisGcj02Lat: 31.7338,
            gisGcj02Lng: 116.521,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341502',
            text: '金安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7553,
            gisBd09Lng: 116.546,
            gisGcj02Lat: 31.7492,
            gisGcj02Lng: 116.54,
            stubGroupCnt: 0,
            pinYin: "Jin'an",
          },
          {
            id: '341503',
            text: '裕安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7434,
            gisBd09Lng: 116.486,
            gisGcj02Lat: 31.7376,
            gisGcj02Lng: 116.479,
            stubGroupCnt: null,
            pinYin: "Yu'an",
          },
          {
            id: '341521',
            text: '寿　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5779,
            gisBd09Lng: 116.789,
            gisGcj02Lat: 32.5718,
            gisGcj02Lng: 116.783,
            stubGroupCnt: null,
            pinYin: 'Shouxian',
          },
          {
            id: '341522',
            text: '霍邱县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3588,
            gisBd09Lng: 116.285,
            gisGcj02Lat: 32.3525,
            gisGcj02Lng: 116.279,
            stubGroupCnt: null,
            pinYin: 'Huoqiu',
          },
          {
            id: '341523',
            text: '舒城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4675,
            gisBd09Lng: 116.956,
            gisGcj02Lat: 31.4618,
            gisGcj02Lng: 116.949,
            stubGroupCnt: null,
            pinYin: 'Shucheng',
          },
          {
            id: '341524',
            text: '金寨县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7321,
            gisBd09Lng: 115.94,
            gisGcj02Lat: 31.7258,
            gisGcj02Lng: 115.934,
            stubGroupCnt: null,
            pinYin: 'Jinzhai',
          },
          {
            id: '341525',
            text: '霍山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3985,
            gisBd09Lng: 116.34,
            gisGcj02Lat: 31.3929,
            gisGcj02Lng: 116.334,
            stubGroupCnt: null,
            pinYin: 'Huoshan',
          },
        ],
        gisBd09Lng: 116.528,
        text: '六安',
        gisBd09Lat: 31.7401,
        gisGcj02Lat: 31.7338,
      },
      {
        pinYin: 'Bozhou',
        id: '341600',
        gisGcj02Lng: 115.779,
        children: [
          {
            id: '341601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8502,
            gisBd09Lng: 115.785,
            gisGcj02Lat: 33.844,
            gisGcj02Lng: 115.778,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341602',
            text: '谯城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.882,
            gisBd09Lng: 115.787,
            gisGcj02Lat: 33.8758,
            gisGcj02Lng: 115.781,
            stubGroupCnt: null,
            pinYin: 'Qiaocheng',
          },
          {
            id: '341621',
            text: '涡阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.498,
            gisBd09Lng: 116.222,
            gisGcj02Lat: 33.4924,
            gisGcj02Lng: 116.216,
            stubGroupCnt: null,
            pinYin: 'Guoyang',
          },
          {
            id: '341622',
            text: '蒙城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2709,
            gisBd09Lng: 116.571,
            gisGcj02Lat: 33.2651,
            gisGcj02Lng: 116.564,
            stubGroupCnt: null,
            pinYin: 'Mengcheng',
          },
          {
            id: '341623',
            text: '利辛县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1501,
            gisBd09Lng: 116.215,
            gisGcj02Lat: 33.1444,
            gisGcj02Lng: 116.209,
            stubGroupCnt: null,
            pinYin: 'Lixin',
          },
        ],
        gisBd09Lng: 115.785,
        text: '亳州',
        gisBd09Lat: 33.8502,
        gisGcj02Lat: 33.844,
      },
      {
        pinYin: 'Chizhou',
        id: '341700',
        gisGcj02Lng: 117.491,
        children: [
          {
            id: '341701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6707,
            gisBd09Lng: 117.498,
            gisGcj02Lat: 30.6644,
            gisGcj02Lng: 117.491,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341702',
            text: '贵池区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6926,
            gisBd09Lng: 117.574,
            gisGcj02Lat: 30.6866,
            gisGcj02Lng: 117.567,
            stubGroupCnt: null,
            pinYin: 'Guichi',
          },
          {
            id: '341721',
            text: '东至县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1026,
            gisBd09Lng: 117.034,
            gisGcj02Lat: 30.0966,
            gisGcj02Lng: 117.027,
            stubGroupCnt: null,
            pinYin: 'Dongzhi',
          },
          {
            id: '341722',
            text: '石台县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2164,
            gisBd09Lng: 117.492,
            gisGcj02Lat: 30.21,
            gisGcj02Lng: 117.486,
            stubGroupCnt: null,
            pinYin: 'Shitai',
          },
          {
            id: '341723',
            text: '青阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6455,
            gisBd09Lng: 117.854,
            gisGcj02Lat: 30.6391,
            gisGcj02Lng: 117.847,
            stubGroupCnt: null,
            pinYin: 'Qingyang',
          },
        ],
        gisBd09Lng: 117.498,
        text: '池州',
        gisBd09Lat: 30.6707,
        gisGcj02Lat: 30.6644,
      },
      {
        pinYin: 'Xuancheng',
        id: '341800',
        gisGcj02Lng: 118.759,
        children: [
          {
            id: '341801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.946,
            gisBd09Lng: 118.765,
            gisGcj02Lat: 30.9402,
            gisGcj02Lng: 118.759,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '341802',
            text: '宣州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.951,
            gisBd09Lng: 118.763,
            gisGcj02Lat: 30.9452,
            gisGcj02Lng: 118.756,
            stubGroupCnt: null,
            pinYin: 'Xuanzhou',
          },
          {
            id: '341821',
            text: '郎溪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1323,
            gisBd09Lng: 119.186,
            gisGcj02Lat: 31.1261,
            gisGcj02Lng: 119.179,
            stubGroupCnt: null,
            pinYin: 'Langxi',
          },
          {
            id: '341822',
            text: '广德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8987,
            gisBd09Lng: 119.424,
            gisGcj02Lat: 30.8924,
            gisGcj02Lng: 119.418,
            stubGroupCnt: null,
            pinYin: 'Guangde',
          },
          {
            id: '341823',
            text: '泾　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6942,
            gisBd09Lng: 118.427,
            gisGcj02Lat: 30.688,
            gisGcj02Lng: 118.42,
            stubGroupCnt: null,
            pinYin: 'Jingxian',
          },
          {
            id: '341824',
            text: '绩溪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0733,
            gisBd09Lng: 118.585,
            gisGcj02Lat: 30.0671,
            gisGcj02Lng: 118.578,
            stubGroupCnt: null,
            pinYin: 'Jixi',
          },
          {
            id: '341825',
            text: '旌德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2916,
            gisBd09Lng: 118.547,
            gisGcj02Lat: 30.2854,
            gisGcj02Lng: 118.541,
            stubGroupCnt: null,
            pinYin: 'Jingde',
          },
          {
            id: '341881',
            text: '宁国',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6399,
            gisBd09Lng: 118.989,
            gisGcj02Lat: 30.6343,
            gisGcj02Lng: 118.982,
            stubGroupCnt: null,
            pinYin: 'Ningguo',
          },
        ],
        gisBd09Lng: 118.765,
        text: '宣城',
        gisBd09Lat: 30.946,
        gisGcj02Lat: 30.9402,
      },
    ],
  },
  {
    text: '福建省',
    children: [
      {
        pinYin: 'Fuzhou',
        id: '350100',
        gisGcj02Lng: 119.297,
        children: [
          {
            id: '350101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0791,
            gisBd09Lng: 119.304,
            gisGcj02Lat: 26.0729,
            gisGcj02Lng: 119.297,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350102',
            text: '鼓楼区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0884,
            gisBd09Lng: 119.311,
            gisGcj02Lat: 26.0823,
            gisGcj02Lng: 119.305,
            stubGroupCnt: null,
            pinYin: 'Gulou',
          },
          {
            id: '350103',
            text: '台江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0582,
            gisBd09Lng: 119.32,
            gisGcj02Lat: 26.0523,
            gisGcj02Lng: 119.314,
            stubGroupCnt: null,
            pinYin: 'Taijiang',
          },
          {
            id: '350104',
            text: '仓山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0533,
            gisBd09Lng: 119.279,
            gisGcj02Lat: 26.0469,
            gisGcj02Lng: 119.273,
            stubGroupCnt: null,
            pinYin: 'Cangshan',
          },
          {
            id: '350105',
            text: '马尾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9944,
            gisBd09Lng: 119.463,
            gisGcj02Lat: 25.9887,
            gisGcj02Lng: 119.456,
            stubGroupCnt: null,
            pinYin: 'Mawei',
          },
          {
            id: '350111',
            text: '晋安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0856,
            gisBd09Lng: 119.335,
            gisGcj02Lat: 26.0799,
            gisGcj02Lng: 119.328,
            stubGroupCnt: null,
            pinYin: "Jin'an",
          },
          {
            id: '350121',
            text: '闽侯县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1544,
            gisBd09Lng: 119.137,
            gisGcj02Lat: 26.1484,
            gisGcj02Lng: 119.131,
            stubGroupCnt: null,
            pinYin: 'Minhou',
          },
          {
            id: '350122',
            text: '连江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2015,
            gisBd09Lng: 119.547,
            gisGcj02Lat: 26.1953,
            gisGcj02Lng: 119.54,
            stubGroupCnt: null,
            pinYin: 'Lianjiang',
          },
          {
            id: '350123',
            text: '罗源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4936,
            gisBd09Lng: 119.557,
            gisGcj02Lat: 26.4876,
            gisGcj02Lng: 119.55,
            stubGroupCnt: null,
            pinYin: 'Luoyuan',
          },
          {
            id: '350124',
            text: '闽清县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2254,
            gisBd09Lng: 118.87,
            gisGcj02Lat: 26.2197,
            gisGcj02Lng: 118.864,
            stubGroupCnt: null,
            pinYin: 'Minqing',
          },
          {
            id: '350125',
            text: '永泰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8719,
            gisBd09Lng: 118.94,
            gisGcj02Lat: 25.8656,
            gisGcj02Lng: 118.933,
            stubGroupCnt: null,
            pinYin: 'Yongtai',
          },
          {
            id: '350128',
            text: '平潭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5036,
            gisBd09Lng: 119.799,
            gisGcj02Lat: 25.4976,
            gisGcj02Lng: 119.792,
            stubGroupCnt: null,
            pinYin: 'Pingtan',
          },
          {
            id: '350181',
            text: '福清',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7252,
            gisBd09Lng: 119.39,
            gisGcj02Lat: 25.7189,
            gisGcj02Lng: 119.384,
            stubGroupCnt: null,
            pinYin: 'Fuqing',
          },
          {
            id: '350182',
            text: '长乐',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9676,
            gisBd09Lng: 119.53,
            gisGcj02Lat: 25.9612,
            gisGcj02Lng: 119.524,
            stubGroupCnt: null,
            pinYin: 'Changle',
          },
        ],
        gisBd09Lng: 119.304,
        text: '福州',
        gisBd09Lat: 26.0791,
        gisGcj02Lat: 26.0729,
      },
      {
        pinYin: 'Xiamen',
        id: '350200',
        gisGcj02Lng: 118.09,
        children: [
          {
            id: '350201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:24',
            gisBd09Lat: 24.4844,
            gisBd09Lng: 118.096,
            gisGcj02Lat: 24.4781,
            gisGcj02Lng: 118.09,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350203',
            text: '思明区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:21',
            gisBd09Lat: 24.4523,
            gisBd09Lng: 118.091,
            gisGcj02Lat: 24.4459,
            gisGcj02Lng: 118.084,
            stubGroupCnt: null,
            pinYin: 'Siming',
          },
          {
            id: '350205',
            text: '海沧区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:19',
            gisBd09Lat: 24.4898,
            gisBd09Lng: 118.04,
            gisGcj02Lat: 24.484,
            gisGcj02Lng: 118.034,
            stubGroupCnt: null,
            pinYin: 'Haicang',
          },
          {
            id: '350206',
            text: '湖里区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:16',
            gisBd09Lat: 24.5176,
            gisBd09Lng: 118.153,
            gisGcj02Lat: 24.5119,
            gisGcj02Lng: 118.147,
            stubGroupCnt: null,
            pinYin: 'Huli',
          },
          {
            id: '350211',
            text: '集美区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:14',
            gisBd09Lat: 24.5818,
            gisBd09Lng: 118.104,
            gisGcj02Lat: 24.5756,
            gisGcj02Lng: 118.098,
            stubGroupCnt: null,
            pinYin: 'Jimei',
          },
          {
            id: '350212',
            text: '同安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:12',
            gisBd09Lat: 24.7267,
            gisBd09Lng: 118.161,
            gisGcj02Lat: 24.721,
            gisGcj02Lng: 118.155,
            stubGroupCnt: null,
            pinYin: "Tong'an",
          },
          {
            id: '350213',
            text: '翔安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.12.21 16:53:09',
            gisBd09Lat: 24.6224,
            gisBd09Lng: 118.254,
            gisGcj02Lat: 24.6167,
            gisGcj02Lng: 118.247,
            stubGroupCnt: null,
            pinYin: "Xiang'an",
          },
        ],
        gisBd09Lng: 118.096,
        text: '厦门',
        gisBd09Lat: 24.4844,
        gisGcj02Lat: 24.4781,
      },
      {
        pinYin: 'Putian',
        id: '350300',
        gisGcj02Lng: 119.008,
        children: [
          {
            id: '350301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4604,
            gisBd09Lng: 119.015,
            gisGcj02Lat: 25.4544,
            gisGcj02Lng: 119.008,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350302',
            text: '城厢区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.424,
            gisBd09Lng: 119.001,
            gisGcj02Lat: 25.4183,
            gisGcj02Lng: 118.995,
            stubGroupCnt: null,
            pinYin: 'Chengxiang',
          },
          {
            id: '350303',
            text: '涵江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4639,
            gisBd09Lng: 119.121,
            gisGcj02Lat: 25.4582,
            gisGcj02Lng: 119.115,
            stubGroupCnt: null,
            pinYin: 'Hanjiang',
          },
          {
            id: '350304',
            text: '荔城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4357,
            gisBd09Lng: 119.022,
            gisGcj02Lat: 25.4296,
            gisGcj02Lng: 119.015,
            stubGroupCnt: null,
            pinYin: 'Licheng',
          },
          {
            id: '350305',
            text: '秀屿区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4388,
            gisBd09Lng: 119.024,
            gisGcj02Lat: 25.4326,
            gisGcj02Lng: 119.017,
            stubGroupCnt: null,
            pinYin: 'Xiuyu',
          },
          {
            id: '350322',
            text: '仙游县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3658,
            gisBd09Lng: 118.699,
            gisGcj02Lat: 25.3595,
            gisGcj02Lng: 118.692,
            stubGroupCnt: null,
            pinYin: 'Xianyou',
          },
        ],
        gisBd09Lng: 119.015,
        text: '莆田',
        gisBd09Lat: 25.4604,
        gisGcj02Lat: 25.4544,
      },
      {
        pinYin: 'Sanming',
        id: '350400',
        gisGcj02Lng: 117.638,
        children: [
          {
            id: '350401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2696,
            gisBd09Lng: 117.644,
            gisGcj02Lat: 26.2638,
            gisGcj02Lng: 117.638,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350402',
            text: '梅列区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2777,
            gisBd09Lng: 117.651,
            gisGcj02Lat: 26.2719,
            gisGcj02Lng: 117.645,
            stubGroupCnt: null,
            pinYin: 'Meilie',
          },
          {
            id: '350403',
            text: '三元区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2397,
            gisBd09Lng: 117.614,
            gisGcj02Lat: 26.2334,
            gisGcj02Lng: 117.608,
            stubGroupCnt: null,
            pinYin: 'Sanyuan',
          },
          {
            id: '350421',
            text: '明溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3618,
            gisBd09Lng: 117.208,
            gisGcj02Lat: 26.356,
            gisGcj02Lng: 117.202,
            stubGroupCnt: null,
            pinYin: 'Mingxi',
          },
          {
            id: '350423',
            text: '清流县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1831,
            gisBd09Lng: 116.823,
            gisGcj02Lat: 26.1775,
            gisGcj02Lng: 116.817,
            stubGroupCnt: null,
            pinYin: 'Qingliu',
          },
          {
            id: '350424',
            text: '宁化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2679,
            gisBd09Lng: 116.66,
            gisGcj02Lat: 26.2617,
            gisGcj02Lng: 116.654,
            stubGroupCnt: null,
            pinYin: 'Ninghua',
          },
          {
            id: '350425',
            text: '大田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6985,
            gisBd09Lng: 117.854,
            gisGcj02Lat: 25.6922,
            gisGcj02Lng: 117.847,
            stubGroupCnt: null,
            pinYin: 'Datian',
          },
          {
            id: '350426',
            text: '尤溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.176,
            gisBd09Lng: 118.197,
            gisGcj02Lat: 26.1697,
            gisGcj02Lng: 118.19,
            stubGroupCnt: null,
            pinYin: 'Youxi',
          },
          {
            id: '350427',
            text: '沙　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4016,
            gisBd09Lng: 117.799,
            gisGcj02Lat: 26.3959,
            gisGcj02Lng: 117.793,
            stubGroupCnt: null,
            pinYin: 'Shaxian',
          },
          {
            id: '350428',
            text: '将乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7347,
            gisBd09Lng: 117.478,
            gisGcj02Lat: 26.7284,
            gisGcj02Lng: 117.471,
            stubGroupCnt: null,
            pinYin: 'Jiangle',
          },
          {
            id: '350429',
            text: '泰宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9049,
            gisBd09Lng: 117.183,
            gisGcj02Lat: 26.8993,
            gisGcj02Lng: 117.176,
            stubGroupCnt: null,
            pinYin: 'Taining',
          },
          {
            id: '350430',
            text: '建宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8367,
            gisBd09Lng: 116.853,
            gisGcj02Lat: 26.8308,
            gisGcj02Lng: 116.847,
            stubGroupCnt: null,
            pinYin: 'Jianning',
          },
          {
            id: '350481',
            text: '永安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9473,
            gisBd09Lng: 117.372,
            gisGcj02Lat: 25.9409,
            gisGcj02Lng: 117.366,
            stubGroupCnt: null,
            pinYin: "Yong'an",
          },
        ],
        gisBd09Lng: 117.644,
        text: '三明',
        gisBd09Lat: 26.2696,
        gisGcj02Lat: 26.2638,
      },
      {
        pinYin: 'Quanzhou',
        id: '350500',
        gisGcj02Lng: 118.678,
        children: [
          {
            id: '350501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8798,
            gisBd09Lng: 118.684,
            gisGcj02Lat: 24.8735,
            gisGcj02Lng: 118.678,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350502',
            text: '鲤城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9137,
            gisBd09Lng: 118.596,
            gisGcj02Lat: 24.9077,
            gisGcj02Lng: 118.59,
            stubGroupCnt: null,
            pinYin: 'Licheng',
          },
          {
            id: '350503',
            text: '丰泽区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8933,
            gisBd09Lng: 118.62,
            gisGcj02Lat: 24.8876,
            gisGcj02Lng: 118.613,
            stubGroupCnt: null,
            pinYin: 'Fengze',
          },
          {
            id: '350504',
            text: '洛江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9456,
            gisBd09Lng: 118.678,
            gisGcj02Lat: 24.9392,
            gisGcj02Lng: 118.671,
            stubGroupCnt: null,
            pinYin: 'Luojiang',
          },
          {
            id: '350505',
            text: '泉港区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1266,
            gisBd09Lng: 118.924,
            gisGcj02Lat: 25.1202,
            gisGcj02Lng: 118.917,
            stubGroupCnt: null,
            pinYin: 'Quangang',
          },
          {
            id: '350521',
            text: '惠安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0356,
            gisBd09Lng: 118.803,
            gisGcj02Lat: 25.0292,
            gisGcj02Lng: 118.797,
            stubGroupCnt: null,
            pinYin: "Hui'an",
          },
          {
            id: '350524',
            text: '安溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0609,
            gisBd09Lng: 118.193,
            gisGcj02Lat: 25.0547,
            gisGcj02Lng: 118.187,
            stubGroupCnt: null,
            pinYin: 'Anxi',
          },
          {
            id: '350525',
            text: '永春县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3259,
            gisBd09Lng: 118.299,
            gisGcj02Lat: 25.3198,
            gisGcj02Lng: 118.293,
            stubGroupCnt: null,
            pinYin: 'Yongchun',
          },
          {
            id: '350526',
            text: '德化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4957,
            gisBd09Lng: 118.249,
            gisGcj02Lat: 25.4899,
            gisGcj02Lng: 118.243,
            stubGroupCnt: null,
            pinYin: 'Dehua',
          },
          {
            id: '350527',
            text: '金门县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4233,
            gisBd09Lng: 118.234,
            gisGcj02Lat: 24.4172,
            gisGcj02Lng: 118.227,
            stubGroupCnt: null,
            pinYin: 'Jinmen',
          },
          {
            id: '350581',
            text: '石狮',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7372,
            gisBd09Lng: 118.655,
            gisGcj02Lat: 24.7313,
            gisGcj02Lng: 118.648,
            stubGroupCnt: null,
            pinYin: 'Shishi',
          },
          {
            id: '350582',
            text: '晋江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7857,
            gisBd09Lng: 118.557,
            gisGcj02Lat: 24.7794,
            gisGcj02Lng: 118.551,
            stubGroupCnt: null,
            pinYin: 'Jinjiang',
          },
          {
            id: '350583',
            text: '南安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9656,
            gisBd09Lng: 118.393,
            gisGcj02Lat: 24.9599,
            gisGcj02Lng: 118.386,
            stubGroupCnt: null,
            pinYin: "Nan'an",
          },
        ],
        gisBd09Lng: 118.684,
        text: '泉州',
        gisBd09Lat: 24.8798,
        gisGcj02Lat: 24.8735,
      },
      {
        pinYin: 'Zhangzhou',
        id: '350600',
        gisGcj02Lng: 117.647,
        children: [
          {
            id: '350601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5177,
            gisBd09Lng: 117.654,
            gisGcj02Lat: 24.512,
            gisGcj02Lng: 117.647,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350602',
            text: '芗城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5159,
            gisBd09Lng: 117.661,
            gisGcj02Lat: 24.5102,
            gisGcj02Lng: 117.654,
            stubGroupCnt: null,
            pinYin: 'Xiangcheng',
          },
          {
            id: '350603',
            text: '龙文区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.508,
            gisBd09Lng: 117.718,
            gisGcj02Lat: 24.5017,
            gisGcj02Lng: 117.712,
            stubGroupCnt: null,
            pinYin: 'Longwen',
          },
          {
            id: '350622',
            text: '云霄县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9617,
            gisBd09Lng: 117.345,
            gisGcj02Lat: 23.9556,
            gisGcj02Lng: 117.339,
            stubGroupCnt: null,
            pinYin: 'Yunxiao',
          },
          {
            id: '350623',
            text: '漳浦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1227,
            gisBd09Lng: 117.622,
            gisGcj02Lat: 24.1165,
            gisGcj02Lng: 117.615,
            stubGroupCnt: null,
            pinYin: 'Zhangpu',
          },
          {
            id: '350624',
            text: '诏安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7153,
            gisBd09Lng: 117.18,
            gisGcj02Lat: 23.7097,
            gisGcj02Lng: 117.173,
            stubGroupCnt: null,
            pinYin: "Zhao'an",
          },
          {
            id: '350625',
            text: '长泰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6308,
            gisBd09Lng: 117.767,
            gisGcj02Lat: 24.625,
            gisGcj02Lng: 117.76,
            stubGroupCnt: null,
            pinYin: 'Changtai',
          },
          {
            id: '350626',
            text: '东山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7062,
            gisBd09Lng: 117.437,
            gisGcj02Lat: 23.7005,
            gisGcj02Lng: 117.431,
            stubGroupCnt: null,
            pinYin: 'Dongshan',
          },
          {
            id: '350627',
            text: '南靖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5205,
            gisBd09Lng: 117.365,
            gisGcj02Lat: 24.5141,
            gisGcj02Lng: 117.358,
            stubGroupCnt: null,
            pinYin: 'Nanjing',
          },
          {
            id: '350628',
            text: '平和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3686,
            gisBd09Lng: 117.323,
            gisGcj02Lat: 24.3629,
            gisGcj02Lng: 117.316,
            stubGroupCnt: null,
            pinYin: 'Pinghe',
          },
          {
            id: '350629',
            text: '华安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0093,
            gisBd09Lng: 117.543,
            gisGcj02Lat: 25.0037,
            gisGcj02Lng: 117.537,
            stubGroupCnt: null,
            pinYin: "Hua'an",
          },
          {
            id: '350681',
            text: '龙海',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4527,
            gisBd09Lng: 117.825,
            gisGcj02Lat: 24.4466,
            gisGcj02Lng: 117.819,
            stubGroupCnt: null,
            pinYin: 'Longhai',
          },
        ],
        gisBd09Lng: 117.654,
        text: '漳州',
        gisBd09Lat: 24.5177,
        gisGcj02Lat: 24.512,
      },
      {
        pinYin: 'Nanping',
        id: '350700',
        gisGcj02Lng: 118.178,
        children: [
          {
            id: '350701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6475,
            gisBd09Lng: 118.184,
            gisGcj02Lat: 26.6414,
            gisGcj02Lng: 118.178,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350702',
            text: '延平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6441,
            gisBd09Lng: 118.188,
            gisGcj02Lat: 26.6379,
            gisGcj02Lng: 118.182,
            stubGroupCnt: null,
            pinYin: 'Yanping',
          },
          {
            id: '350721',
            text: '顺昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7989,
            gisBd09Lng: 117.817,
            gisGcj02Lat: 26.7929,
            gisGcj02Lng: 117.811,
            stubGroupCnt: null,
            pinYin: 'Shunchang',
          },
          {
            id: '350722',
            text: '浦城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9229,
            gisBd09Lng: 118.548,
            gisGcj02Lat: 27.9167,
            gisGcj02Lng: 118.542,
            stubGroupCnt: null,
            pinYin: 'Pucheng',
          },
          {
            id: '350723',
            text: '光泽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5471,
            gisBd09Lng: 117.341,
            gisGcj02Lat: 27.541,
            gisGcj02Lng: 117.335,
            stubGroupCnt: null,
            pinYin: 'Guangze',
          },
          {
            id: '350724',
            text: '松溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5314,
            gisBd09Lng: 118.792,
            gisGcj02Lat: 27.5251,
            gisGcj02Lng: 118.786,
            stubGroupCnt: null,
            pinYin: 'Songxi',
          },
          {
            id: '350725',
            text: '政和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3714,
            gisBd09Lng: 118.864,
            gisGcj02Lat: 27.3657,
            gisGcj02Lng: 118.858,
            stubGroupCnt: null,
            pinYin: 'Zhenghe',
          },
          {
            id: '350781',
            text: '邵武',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3467,
            gisBd09Lng: 117.498,
            gisGcj02Lat: 27.3404,
            gisGcj02Lng: 117.492,
            stubGroupCnt: null,
            pinYin: 'Shaowu',
          },
          {
            id: '350782',
            text: '武夷山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7624,
            gisBd09Lng: 118.042,
            gisGcj02Lat: 27.7566,
            gisGcj02Lng: 118.036,
            stubGroupCnt: null,
            pinYin: 'Wuyishan',
          },
          {
            id: '350783',
            text: '建瓯',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.029,
            gisBd09Lng: 118.312,
            gisGcj02Lat: 27.0227,
            gisGcj02Lng: 118.306,
            stubGroupCnt: null,
            pinYin: 'Jianou',
          },
          {
            id: '350784',
            text: '建阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.337,
            gisBd09Lng: 118.128,
            gisGcj02Lat: 27.3312,
            gisGcj02Lng: 118.122,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 118.184,
        text: '南平',
        gisBd09Lat: 26.6475,
        gisGcj02Lat: 26.6414,
      },
      {
        pinYin: 'Longyan',
        id: '350800',
        gisGcj02Lng: 117.019,
        children: [
          {
            id: '350801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0813,
            gisBd09Lng: 117.026,
            gisGcj02Lat: 25.0751,
            gisGcj02Lng: 117.019,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350802',
            text: '新罗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1045,
            gisBd09Lng: 117.045,
            gisGcj02Lat: 25.0986,
            gisGcj02Lng: 117.038,
            stubGroupCnt: null,
            pinYin: 'Xinluo',
          },
          {
            id: '350821',
            text: '长汀县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.839,
            gisBd09Lng: 116.365,
            gisGcj02Lat: 25.8332,
            gisGcj02Lng: 116.359,
            stubGroupCnt: null,
            pinYin: 'Changting',
          },
          {
            id: '350822',
            text: '永定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7619,
            gisBd09Lng: 116.826,
            gisGcj02Lat: 24.7562,
            gisGcj02Lng: 116.82,
            stubGroupCnt: null,
            pinYin: 'Yongding',
          },
          {
            id: '350823',
            text: '上杭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0551,
            gisBd09Lng: 116.427,
            gisGcj02Lat: 25.0489,
            gisGcj02Lng: 116.42,
            stubGroupCnt: null,
            pinYin: 'Shanghang',
          },
          {
            id: '350824',
            text: '武平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1,
            gisBd09Lng: 116.107,
            gisGcj02Lat: 25.0944,
            gisGcj02Lng: 116.101,
            stubGroupCnt: null,
            pinYin: 'Wuping',
          },
          {
            id: '350825',
            text: '连城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7164,
            gisBd09Lng: 116.762,
            gisGcj02Lat: 25.71,
            gisGcj02Lng: 116.756,
            stubGroupCnt: null,
            pinYin: 'Liancheng',
          },
          {
            id: '350881',
            text: '漳平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2945,
            gisBd09Lng: 117.426,
            gisGcj02Lat: 25.2888,
            gisGcj02Lng: 117.419,
            stubGroupCnt: null,
            pinYin: 'Zhangping',
          },
        ],
        gisBd09Lng: 117.026,
        text: '龙岩',
        gisBd09Lat: 25.0813,
        gisGcj02Lat: 25.0751,
      },
      {
        pinYin: 'Ningde',
        id: '350900',
        gisGcj02Lng: 119.548,
        children: [
          {
            id: '350901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6714,
            gisBd09Lng: 119.555,
            gisGcj02Lat: 26.6653,
            gisGcj02Lng: 119.548,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '350902',
            text: '蕉城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6665,
            gisBd09Lng: 119.532,
            gisGcj02Lat: 26.6601,
            gisGcj02Lng: 119.526,
            stubGroupCnt: null,
            pinYin: 'Jiaocheng',
          },
          {
            id: '350921',
            text: '霞浦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8913,
            gisBd09Lng: 120.012,
            gisGcj02Lat: 26.8849,
            gisGcj02Lng: 120.005,
            stubGroupCnt: null,
            pinYin: 'Xiapu',
          },
          {
            id: '350922',
            text: '古田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5831,
            gisBd09Lng: 118.753,
            gisGcj02Lat: 26.5774,
            gisGcj02Lng: 118.747,
            stubGroupCnt: null,
            pinYin: 'Gutian',
          },
          {
            id: '350923',
            text: '屏南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.914,
            gisBd09Lng: 118.993,
            gisGcj02Lat: 26.9084,
            gisGcj02Lng: 118.986,
            stubGroupCnt: null,
            pinYin: 'Pingnan',
          },
          {
            id: '350924',
            text: '寿宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4667,
            gisBd09Lng: 119.512,
            gisGcj02Lat: 27.4605,
            gisGcj02Lng: 119.505,
            stubGroupCnt: null,
            pinYin: 'Shouning',
          },
          {
            id: '350925',
            text: '周宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1102,
            gisBd09Lng: 119.345,
            gisGcj02Lat: 27.1046,
            gisGcj02Lng: 119.338,
            stubGroupCnt: null,
            pinYin: 'Zhouning',
          },
          {
            id: '350926',
            text: '柘荣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.24,
            gisBd09Lng: 119.909,
            gisGcj02Lat: 27.2339,
            gisGcj02Lng: 119.902,
            stubGroupCnt: null,
            pinYin: 'Zherong',
          },
          {
            id: '350981',
            text: '福安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0937,
            gisBd09Lng: 119.654,
            gisGcj02Lat: 27.0874,
            gisGcj02Lng: 119.648,
            stubGroupCnt: null,
            pinYin: "Fu'an",
          },
          {
            id: '350982',
            text: '福鼎',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3301,
            gisBd09Lng: 120.223,
            gisGcj02Lat: 27.324,
            gisGcj02Lng: 120.217,
            stubGroupCnt: null,
            pinYin: 'Fuding',
          },
        ],
        gisBd09Lng: 119.555,
        text: '宁德',
        gisBd09Lat: 26.6714,
        gisGcj02Lat: 26.6653,
      },
    ],
  },

  {
    text: '甘肃省',
    children: [
      {
        pinYin: 'Lanzhou',
        id: '620100',
        gisGcj02Lng: 103.833,
        children: [
          {
            id: '620101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0633,
            gisBd09Lng: 103.845,
            gisGcj02Lat: 36.0574,
            gisGcj02Lng: 103.838,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620102',
            text: '城关区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0632,
            gisBd09Lng: 103.832,
            gisGcj02Lat: 36.0571,
            gisGcj02Lng: 103.826,
            stubGroupCnt: null,
            pinYin: 'Chengguan',
          },
          {
            id: '620103',
            text: '七里河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0725,
            gisBd09Lng: 103.793,
            gisGcj02Lat: 36.0662,
            gisGcj02Lng: 103.786,
            stubGroupCnt: null,
            pinYin: 'Qilihe',
          },
          {
            id: '620104',
            text: '西固区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0944,
            gisBd09Lng: 103.635,
            gisGcj02Lat: 36.0887,
            gisGcj02Lng: 103.628,
            stubGroupCnt: null,
            pinYin: 'Xigu',
          },
          {
            id: '620105',
            text: '安宁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1102,
            gisBd09Lng: 103.726,
            gisGcj02Lat: 36.1044,
            gisGcj02Lng: 103.72,
            stubGroupCnt: null,
            pinYin: 'Anning',
          },
          {
            id: '620111',
            text: '红古区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3522,
            gisBd09Lng: 102.868,
            gisGcj02Lat: 36.346,
            gisGcj02Lng: 102.862,
            stubGroupCnt: null,
            pinYin: 'Honggu',
          },
          {
            id: '620121',
            text: '永登县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7423,
            gisBd09Lng: 103.269,
            gisGcj02Lat: 36.7366,
            gisGcj02Lng: 103.262,
            stubGroupCnt: null,
            pinYin: 'Yongdeng',
          },
          {
            id: '620122',
            text: '皋兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3381,
            gisBd09Lng: 103.955,
            gisGcj02Lat: 36.3321,
            gisGcj02Lng: 103.949,
            stubGroupCnt: null,
            pinYin: 'Gaolan',
          },
          {
            id: '620123',
            text: '榆中县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8492,
            gisBd09Lng: 104.12,
            gisGcj02Lat: 35.8434,
            gisGcj02Lng: 104.113,
            stubGroupCnt: null,
            pinYin: 'Yuzhong',
          },
        ],
        gisBd09Lng: 103.84,
        text: '兰州',
        gisBd09Lat: 36.0655,
        gisGcj02Lat: 36.0596,
      },
      {
        pinYin: 'Jiayuguan',
        id: '620200',
        gisGcj02Lng: 98.2904,
        children: [
          {
            id: '620201',
            text: '嘉峪关',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7785,
            gisBd09Lng: 98.2954,
            gisGcj02Lat: 39.7722,
            gisGcj02Lng: 98.289,
            stubGroupCnt: null,
            pinYin: 'Xiongguan',
          },
        ],
        gisBd09Lng: 98.2968,
        text: '嘉峪关',
        gisBd09Lat: 39.7785,
        gisGcj02Lat: 39.7722,
      },
      {
        pinYin: 'Jinchang',
        id: '620300',
        gisGcj02Lng: 102.189,
        children: [
          {
            id: '620301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.5263,
            gisBd09Lng: 102.196,
            gisGcj02Lat: 38.5206,
            gisGcj02Lng: 102.19,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620302',
            text: '金川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.526,
            gisBd09Lng: 102.201,
            gisGcj02Lat: 38.5202,
            gisGcj02Lng: 102.194,
            stubGroupCnt: null,
            pinYin: 'Jinchuan',
          },
          {
            id: '620321',
            text: '永昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2521,
            gisBd09Lng: 101.981,
            gisGcj02Lat: 38.2461,
            gisGcj02Lng: 101.974,
            stubGroupCnt: null,
            pinYin: 'Yongchang',
          },
        ],
        gisBd09Lng: 102.196,
        text: '金昌',
        gisBd09Lat: 38.5265,
        gisGcj02Lat: 38.5208,
      },
      {
        pinYin: 'Baiyin',
        id: '620400',
        gisGcj02Lng: 104.139,
        children: [
          {
            id: '620401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5507,
            gisBd09Lng: 104.145,
            gisGcj02Lat: 36.5446,
            gisGcj02Lng: 104.139,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620402',
            text: '白银区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.541,
            gisBd09Lng: 104.18,
            gisGcj02Lat: 36.5348,
            gisGcj02Lng: 104.173,
            stubGroupCnt: null,
            pinYin: 'Baiyin',
          },
          {
            id: '620403',
            text: '平川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.734,
            gisBd09Lng: 104.833,
            gisGcj02Lat: 36.7283,
            gisGcj02Lng: 104.826,
            stubGroupCnt: null,
            pinYin: 'Pingchuan',
          },
          {
            id: '620421',
            text: '靖远县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.571,
            gisBd09Lng: 104.694,
            gisGcj02Lat: 36.5652,
            gisGcj02Lng: 104.687,
            stubGroupCnt: null,
            pinYin: 'Jingyuan',
          },
          {
            id: '620422',
            text: '会宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6984,
            gisBd09Lng: 105.061,
            gisGcj02Lat: 35.6927,
            gisGcj02Lng: 105.054,
            stubGroupCnt: null,
            pinYin: 'Huining',
          },
          {
            id: '620423',
            text: '景泰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1878,
            gisBd09Lng: 104.072,
            gisGcj02Lat: 37.1818,
            gisGcj02Lng: 104.066,
            stubGroupCnt: null,
            pinYin: 'Jingtai',
          },
        ],
        gisBd09Lng: 104.145,
        text: '白银',
        gisBd09Lat: 36.5507,
        gisGcj02Lat: 36.5446,
      },
      {
        pinYin: 'Tianshui',
        id: '620500',
        gisGcj02Lng: 105.727,
        children: [
          {
            id: '620501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5869,
            gisBd09Lng: 105.733,
            gisGcj02Lat: 34.5806,
            gisGcj02Lng: 105.727,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620502',
            text: '秦城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5826,
            gisBd09Lng: 105.699,
            gisGcj02Lat: 34.5765,
            gisGcj02Lng: 105.692,
            stubGroupCnt: null,
            pinYin: 'Qinzhou',
          },
          {
            id: '620503',
            text: '北道区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5663,
            gisBd09Lng: 105.989,
            gisGcj02Lat: 34.5602,
            gisGcj02Lng: 105.982,
            stubGroupCnt: null,
            pinYin: 'Maiji',
          },
          {
            id: '620521',
            text: '清水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7554,
            gisBd09Lng: 106.144,
            gisGcj02Lat: 34.7497,
            gisGcj02Lng: 106.138,
            stubGroupCnt: null,
            pinYin: 'Qingshui',
          },
          {
            id: '620522',
            text: '秦安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.865,
            gisBd09Lng: 105.683,
            gisGcj02Lat: 34.8592,
            gisGcj02Lng: 105.677,
            stubGroupCnt: null,
            pinYin: "Qin'an",
          },
          {
            id: '620523',
            text: '甘谷县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.738,
            gisBd09Lng: 105.342,
            gisGcj02Lat: 34.7319,
            gisGcj02Lng: 105.336,
            stubGroupCnt: null,
            pinYin: 'Gangu',
          },
          {
            id: '620524',
            text: '武山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7276,
            gisBd09Lng: 104.896,
            gisGcj02Lat: 34.7213,
            gisGcj02Lng: 104.89,
            stubGroupCnt: null,
            pinYin: 'Wushan',
          },
          {
            id: '620525',
            text: '张家川回族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0004,
            gisBd09Lng: 106.218,
            gisGcj02Lat: 34.9942,
            gisGcj02Lng: 106.212,
            stubGroupCnt: null,
            pinYin: 'Zhangjiachuan',
          },
        ],
        gisBd09Lng: 105.733,
        text: '天水',
        gisBd09Lat: 34.5869,
        gisGcj02Lat: 34.5806,
      },
      {
        pinYin: 'Wuwei',
        id: '620600',
        gisGcj02Lng: 102.64,
        children: [
          {
            id: '620601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.939,
            gisBd09Lng: 102.648,
            gisGcj02Lat: 37.9332,
            gisGcj02Lng: 102.641,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620602',
            text: '凉州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9341,
            gisBd09Lng: 102.649,
            gisGcj02Lat: 37.9283,
            gisGcj02Lng: 102.643,
            stubGroupCnt: null,
            pinYin: 'Liangzhou',
          },
          {
            id: '620621',
            text: '民勤县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.6311,
            gisBd09Lng: 103.101,
            gisGcj02Lat: 38.6249,
            gisGcj02Lng: 103.094,
            stubGroupCnt: null,
            pinYin: 'Minqin',
          },
          {
            id: '620622',
            text: '古浪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4758,
            gisBd09Lng: 102.904,
            gisGcj02Lat: 37.4701,
            gisGcj02Lng: 102.898,
            stubGroupCnt: null,
            pinYin: 'Gulang',
          },
          {
            id: '620623',
            text: '天祝藏族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9775,
            gisBd09Lng: 103.149,
            gisGcj02Lat: 36.9719,
            gisGcj02Lng: 103.142,
            stubGroupCnt: null,
            pinYin: 'Tianzhu',
          },
        ],
        gisBd09Lng: 102.647,
        text: '武威',
        gisBd09Lat: 37.9346,
        gisGcj02Lat: 37.9288,
      },
      {
        pinYin: 'Zhangye',
        id: '620700',
        gisGcj02Lng: 100.451,
        children: [
          {
            id: '620701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9321,
            gisBd09Lng: 100.457,
            gisGcj02Lat: 38.9259,
            gisGcj02Lng: 100.451,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620702',
            text: '甘州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9356,
            gisBd09Lng: 100.485,
            gisGcj02Lat: 38.9297,
            gisGcj02Lng: 100.478,
            stubGroupCnt: null,
            pinYin: 'Ganzhou',
          },
          {
            id: '620721',
            text: '肃南裕固族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8431,
            gisBd09Lng: 99.6222,
            gisGcj02Lat: 38.8369,
            gisGcj02Lng: 99.6157,
            stubGroupCnt: null,
            pinYin: 'Sunan',
          },
          {
            id: '620722',
            text: '民乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4369,
            gisBd09Lng: 100.822,
            gisGcj02Lat: 38.4307,
            gisGcj02Lng: 100.816,
            stubGroupCnt: null,
            pinYin: 'Minle',
          },
          {
            id: '620723',
            text: '临泽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1585,
            gisBd09Lng: 100.172,
            gisGcj02Lat: 39.1526,
            gisGcj02Lng: 100.165,
            stubGroupCnt: null,
            pinYin: 'Linze',
          },
          {
            id: '620724',
            text: '高台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3847,
            gisBd09Lng: 99.827,
            gisGcj02Lat: 39.3785,
            gisGcj02Lng: 99.8206,
            stubGroupCnt: null,
            pinYin: 'Gaotai',
          },
          {
            id: '620725',
            text: '山丹县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7904,
            gisBd09Lng: 101.094,
            gisGcj02Lat: 38.7846,
            gisGcj02Lng: 101.087,
            stubGroupCnt: null,
            pinYin: 'Shandan',
          },
        ],
        gisBd09Lng: 100.457,
        text: '张掖',
        gisBd09Lat: 38.9321,
        gisGcj02Lat: 38.9259,
      },
      {
        pinYin: 'Pingliang',
        id: '620800',
        gisGcj02Lng: 106.666,
        children: [
          {
            id: '620801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5491,
            gisBd09Lng: 106.673,
            gisGcj02Lat: 35.5429,
            gisGcj02Lng: 106.666,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620802',
            text: '崆峒区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5491,
            gisBd09Lng: 106.682,
            gisGcj02Lat: 35.5428,
            gisGcj02Lng: 106.676,
            stubGroupCnt: null,
            pinYin: 'Kongtong',
          },
          {
            id: '620821',
            text: '泾川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3386,
            gisBd09Lng: 107.375,
            gisGcj02Lat: 35.3327,
            gisGcj02Lng: 107.369,
            stubGroupCnt: null,
            pinYin: 'Jingchuan',
          },
          {
            id: '620822',
            text: '灵台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0715,
            gisBd09Lng: 107.629,
            gisGcj02Lat: 35.0653,
            gisGcj02Lng: 107.622,
            stubGroupCnt: null,
            pinYin: 'Lingtai',
          },
          {
            id: '620823',
            text: '崇信县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3086,
            gisBd09Lng: 107.042,
            gisGcj02Lat: 35.3023,
            gisGcj02Lng: 107.036,
            stubGroupCnt: null,
            pinYin: 'Chongxin',
          },
          {
            id: '620824',
            text: '华亭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2239,
            gisBd09Lng: 106.661,
            gisGcj02Lat: 35.2178,
            gisGcj02Lng: 106.654,
            stubGroupCnt: null,
            pinYin: 'Huating',
          },
          {
            id: '620825',
            text: '庄浪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2082,
            gisBd09Lng: 106.043,
            gisGcj02Lat: 35.2024,
            gisGcj02Lng: 106.037,
            stubGroupCnt: null,
            pinYin: 'Zhuanglang',
          },
          {
            id: '620826',
            text: '静宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5288,
            gisBd09Lng: 105.738,
            gisGcj02Lat: 35.5225,
            gisGcj02Lng: 105.732,
            stubGroupCnt: null,
            pinYin: 'Jingning',
          },
        ],
        gisBd09Lng: 106.673,
        text: '平凉',
        gisBd09Lat: 35.5491,
        gisGcj02Lat: 35.5429,
      },
      {
        pinYin: 'Jiuquan',
        id: '620900',
        gisGcj02Lng: 98.5014,
        children: [
          {
            id: '620901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7279,
            gisBd09Lng: 98.5079,
            gisGcj02Lat: 39.7217,
            gisGcj02Lng: 98.5014,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '620902',
            text: '肃州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7516,
            gisBd09Lng: 98.5152,
            gisGcj02Lat: 39.7454,
            gisGcj02Lng: 98.5087,
            stubGroupCnt: null,
            pinYin: 'Suzhou',
          },
          {
            id: '620921',
            text: '金塔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9921,
            gisBd09Lng: 98.9093,
            gisGcj02Lat: 39.986,
            gisGcj02Lng: 98.9028,
            stubGroupCnt: null,
            pinYin: 'Jinta',
          },
          {
            id: '620922',
            text: '安西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5204,
            gisBd09Lng: 95.7863,
            gisGcj02Lat: 40.5143,
            gisGcj02Lng: 95.7799,
            stubGroupCnt: null,
            pinYin: 'Guazhou',
          },
          {
            id: '620923',
            text: '肃北蒙古族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5183,
            gisBd09Lng: 94.8838,
            gisGcj02Lat: 39.5125,
            gisGcj02Lng: 94.8772,
            stubGroupCnt: null,
            pinYin: 'Subei',
          },
          {
            id: '620924',
            text: '阿克塞哈萨克族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6401,
            gisBd09Lng: 94.3471,
            gisGcj02Lat: 39.6339,
            gisGcj02Lng: 94.3406,
            stubGroupCnt: null,
            pinYin: 'Akesai',
          },
          {
            id: '620981',
            text: '玉门',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2977,
            gisBd09Lng: 97.0525,
            gisGcj02Lat: 40.2918,
            gisGcj02Lng: 97.046,
            stubGroupCnt: null,
            pinYin: 'Yumen',
          },
          {
            id: '620982',
            text: '敦煌',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1486,
            gisBd09Lng: 94.6695,
            gisGcj02Lat: 40.1424,
            gisGcj02Lng: 94.6631,
            stubGroupCnt: null,
            pinYin: 'Dunhuang',
          },
        ],
        gisBd09Lng: 98.5079,
        text: '酒泉',
        gisBd09Lat: 39.7279,
        gisGcj02Lat: 39.7217,
      },
      {
        pinYin: 'Qingyang',
        id: '621000',
        gisGcj02Lng: 107.643,
        children: [
          {
            id: '621001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7147,
            gisBd09Lng: 107.651,
            gisGcj02Lat: 35.7084,
            gisGcj02Lng: 107.644,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '621002',
            text: '西峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7376,
            gisBd09Lng: 107.659,
            gisGcj02Lat: 35.7313,
            gisGcj02Lng: 107.653,
            stubGroupCnt: null,
            pinYin: 'Xifeng',
          },
          {
            id: '621021',
            text: '庆城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0221,
            gisBd09Lng: 107.891,
            gisGcj02Lat: 36.0157,
            gisGcj02Lng: 107.884,
            stubGroupCnt: null,
            pinYin: 'Qingcheng',
          },
          {
            id: '621022',
            text: '环　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5749,
            gisBd09Lng: 107.317,
            gisGcj02Lat: 36.5689,
            gisGcj02Lng: 107.311,
            stubGroupCnt: null,
            pinYin: 'Huanxian',
          },
          {
            id: '621023',
            text: '华池县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4678,
            gisBd09Lng: 107.998,
            gisGcj02Lat: 36.4615,
            gisGcj02Lng: 107.992,
            stubGroupCnt: null,
            pinYin: 'Huachi',
          },
          {
            id: '621024',
            text: '合水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8254,
            gisBd09Lng: 108.027,
            gisGcj02Lat: 35.8193,
            gisGcj02Lng: 108.02,
            stubGroupCnt: null,
            pinYin: 'Heshui',
          },
          {
            id: '621025',
            text: '正宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.499,
            gisBd09Lng: 108.368,
            gisGcj02Lat: 35.4926,
            gisGcj02Lng: 108.362,
            stubGroupCnt: null,
            pinYin: 'Zhengning',
          },
          {
            id: '621026',
            text: '宁　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5084,
            gisBd09Lng: 107.937,
            gisGcj02Lat: 35.5027,
            gisGcj02Lng: 107.93,
            stubGroupCnt: null,
            pinYin: 'Ningxian',
          },
          {
            id: '621027',
            text: '镇原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6818,
            gisBd09Lng: 107.206,
            gisGcj02Lat: 35.676,
            gisGcj02Lng: 107.199,
            stubGroupCnt: null,
            pinYin: 'Zhenyuan',
          },
        ],
        gisBd09Lng: 107.649,
        text: '庆阳',
        gisBd09Lat: 35.7141,
        gisGcj02Lat: 35.7078,
      },
      {
        pinYin: 'Dingxi',
        id: '621100',
        gisGcj02Lng: 104.625,
        children: [
          {
            id: '621101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5871,
            gisBd09Lng: 104.632,
            gisGcj02Lat: 35.5809,
            gisGcj02Lng: 104.625,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '621102',
            text: '安定区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5857,
            gisBd09Lng: 104.618,
            gisGcj02Lat: 35.5797,
            gisGcj02Lng: 104.612,
            stubGroupCnt: null,
            pinYin: 'Anding',
          },
          {
            id: '621121',
            text: '通渭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2173,
            gisBd09Lng: 105.249,
            gisGcj02Lat: 35.211,
            gisGcj02Lng: 105.243,
            stubGroupCnt: null,
            pinYin: 'Tongwei',
          },
          {
            id: '621122',
            text: '陇西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0103,
            gisBd09Lng: 104.64,
            gisGcj02Lat: 35.004,
            gisGcj02Lng: 104.633,
            stubGroupCnt: null,
            pinYin: 'Longxi',
          },
          {
            id: '621123',
            text: '渭源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.142,
            gisBd09Lng: 104.222,
            gisGcj02Lat: 35.1363,
            gisGcj02Lng: 104.216,
            stubGroupCnt: null,
            pinYin: 'Weiyuan',
          },
          {
            id: '621124',
            text: '临洮县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4008,
            gisBd09Lng: 103.868,
            gisGcj02Lat: 35.3951,
            gisGcj02Lng: 103.861,
            stubGroupCnt: null,
            pinYin: 'Lintao',
          },
          {
            id: '621125',
            text: '漳　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8544,
            gisBd09Lng: 104.48,
            gisGcj02Lat: 34.8486,
            gisGcj02Lng: 104.474,
            stubGroupCnt: null,
            pinYin: 'Zhangxian',
          },
          {
            id: '621126',
            text: '岷　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4437,
            gisBd09Lng: 104.045,
            gisGcj02Lat: 34.4374,
            gisGcj02Lng: 104.038,
            stubGroupCnt: null,
            pinYin: 'Minxian',
          },
        ],
        gisBd09Lng: 104.632,
        text: '定西',
        gisBd09Lat: 35.5871,
        gisGcj02Lat: 35.5809,
      },
      {
        pinYin: 'Longnan',
        id: '621200',
        gisGcj02Lng: 104.922,
        children: [
          {
            id: '621201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4069,
            gisBd09Lng: 104.928,
            gisGcj02Lat: 33.4011,
            gisGcj02Lng: 104.922,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '621202',
            text: '武都区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3977,
            gisBd09Lng: 104.933,
            gisGcj02Lat: 33.3919,
            gisGcj02Lng: 104.927,
            stubGroupCnt: null,
            pinYin: 'Wudu',
          },
          {
            id: '621221',
            text: '成　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7565,
            gisBd09Lng: 105.749,
            gisGcj02Lat: 33.7504,
            gisGcj02Lng: 105.743,
            stubGroupCnt: null,
            pinYin: 'Chengxian',
          },
          {
            id: '621222',
            text: '文　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9498,
            gisBd09Lng: 104.691,
            gisGcj02Lat: 32.944,
            gisGcj02Lng: 104.684,
            stubGroupCnt: null,
            pinYin: 'Wenxian',
          },
          {
            id: '621223',
            text: '宕昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0539,
            gisBd09Lng: 104.401,
            gisGcj02Lat: 34.0476,
            gisGcj02Lng: 104.395,
            stubGroupCnt: null,
            pinYin: 'Dangchang',
          },
          {
            id: '621224',
            text: '康　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3358,
            gisBd09Lng: 105.616,
            gisGcj02Lat: 33.3295,
            gisGcj02Lng: 105.61,
            stubGroupCnt: null,
            pinYin: 'Kangxian',
          },
          {
            id: '621225',
            text: '西和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0167,
            gisBd09Lng: 105.308,
            gisGcj02Lat: 34.011,
            gisGcj02Lng: 105.302,
            stubGroupCnt: null,
            pinYin: 'Xihe',
          },
          {
            id: '621226',
            text: '礼　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1949,
            gisBd09Lng: 105.186,
            gisGcj02Lat: 34.1892,
            gisGcj02Lng: 105.18,
            stubGroupCnt: null,
            pinYin: 'Lixian',
          },
          {
            id: '621227',
            text: '徽　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7755,
            gisBd09Lng: 106.096,
            gisGcj02Lat: 33.7692,
            gisGcj02Lng: 106.089,
            stubGroupCnt: null,
            pinYin: 'Huixian',
          },
          {
            id: '621228',
            text: '两当县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9153,
            gisBd09Lng: 106.312,
            gisGcj02Lat: 33.909,
            gisGcj02Lng: 106.306,
            stubGroupCnt: null,
            pinYin: 'Liangdang',
          },
        ],
        gisBd09Lng: 104.929,
        text: '陇南',
        gisBd09Lat: 33.4063,
        gisGcj02Lat: 33.4005,
      },
      {
        pinYin: 'Linxia',
        id: '622900',
        gisGcj02Lng: 103.211,
        children: [
          {
            id: '622901',
            text: '临夏',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4974,
            gisBd09Lng: 103.003,
            gisGcj02Lat: 35.4915,
            gisGcj02Lng: 102.996,
            stubGroupCnt: null,
            pinYin: 'Linxia',
          },
          {
            id: '622921',
            text: '临夏县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4978,
            gisBd09Lng: 103.004,
            gisGcj02Lat: 35.492,
            gisGcj02Lng: 102.998,
            stubGroupCnt: null,
            pinYin: 'Linxia',
          },
          {
            id: '622922',
            text: '康乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3759,
            gisBd09Lng: 103.716,
            gisGcj02Lat: 35.3699,
            gisGcj02Lng: 103.71,
            stubGroupCnt: null,
            pinYin: 'Kangle',
          },
          {
            id: '622923',
            text: '永靖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.948,
            gisBd09Lng: 103.322,
            gisGcj02Lat: 35.9417,
            gisGcj02Lng: 103.316,
            stubGroupCnt: null,
            pinYin: 'Yongjing',
          },
          {
            id: '622924',
            text: '广河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4871,
            gisBd09Lng: 103.576,
            gisGcj02Lat: 35.4808,
            gisGcj02Lng: 103.569,
            stubGroupCnt: null,
            pinYin: 'Guanghe',
          },
          {
            id: '622925',
            text: '和政县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4307,
            gisBd09Lng: 103.358,
            gisGcj02Lat: 35.4247,
            gisGcj02Lng: 103.352,
            stubGroupCnt: null,
            pinYin: 'Hezheng',
          },
          {
            id: '622926',
            text: '东乡族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6701,
            gisBd09Lng: 103.395,
            gisGcj02Lat: 35.6644,
            gisGcj02Lng: 103.388,
            stubGroupCnt: null,
            pinYin: 'Dongxiangzu',
          },
          {
            id: '622927',
            text: '积石山保安族东乡族撒拉族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7235,
            gisBd09Lng: 102.883,
            gisGcj02Lat: 35.7176,
            gisGcj02Lng: 102.877,
            stubGroupCnt: null,
            pinYin: 'Jishishan',
          },
        ],
        gisBd09Lng: 103.217,
        text: '临夏回族自治州',
        gisBd09Lat: 35.6075,
        gisGcj02Lat: 35.6013,
      },
      {
        pinYin: 'Gannan',
        id: '623000',
        gisGcj02Lng: 102.911,
        children: [
          {
            id: '623001',
            text: '合作',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0059,
            gisBd09Lng: 102.918,
            gisGcj02Lat: 35.0002,
            gisGcj02Lng: 102.911,
            stubGroupCnt: null,
            pinYin: 'Hezuo',
          },
          {
            id: '623021',
            text: '临潭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6988,
            gisBd09Lng: 103.361,
            gisGcj02Lat: 34.6928,
            gisGcj02Lng: 103.355,
            stubGroupCnt: null,
            pinYin: 'Lintan',
          },
          {
            id: '623022',
            text: '卓尼县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5949,
            gisBd09Lng: 103.514,
            gisGcj02Lat: 34.5892,
            gisGcj02Lng: 103.508,
            stubGroupCnt: null,
            pinYin: 'Zhuoni',
          },
          {
            id: '623023',
            text: '舟曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7908,
            gisBd09Lng: 104.379,
            gisGcj02Lat: 33.7847,
            gisGcj02Lng: 104.372,
            stubGroupCnt: null,
            pinYin: 'Zhouqu',
          },
          {
            id: '623024',
            text: '迭部县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0624,
            gisBd09Lng: 103.229,
            gisGcj02Lat: 34.0562,
            gisGcj02Lng: 103.223,
            stubGroupCnt: null,
            pinYin: 'Diebu',
          },
          {
            id: '623025',
            text: '玛曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0037,
            gisBd09Lng: 102.08,
            gisGcj02Lat: 33.9979,
            gisGcj02Lng: 102.073,
            stubGroupCnt: null,
            pinYin: 'Maqu',
          },
          {
            id: '623026',
            text: '碌曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5976,
            gisBd09Lng: 102.494,
            gisGcj02Lat: 34.5913,
            gisGcj02Lng: 102.487,
            stubGroupCnt: null,
            pinYin: 'Luqu',
          },
          {
            id: '623027',
            text: '夏河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2082,
            gisBd09Lng: 102.529,
            gisGcj02Lat: 35.2023,
            gisGcj02Lng: 102.522,
            stubGroupCnt: null,
            pinYin: 'Xiahe',
          },
        ],
        gisBd09Lng: 102.918,
        text: '甘南藏族自治州',
        gisBd09Lat: 34.9891,
        gisGcj02Lat: 34.9833,
      },
    ],
  },
  {
    text: '广东省',
    children: [
      {
        pinYin: 'Guangzhou',
        id: '440100',
        gisGcj02Lng: 113.269,
        children: [
          {
            id: '440101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1368,
            gisBd09Lng: 113.271,
            gisGcj02Lat: 23.1306,
            gisGcj02Lng: 113.265,
            stubGroupCnt: 2,
            pinYin: null,
          },
          {
            id: '440102',
            text: '东山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1395,
            gisBd09Lng: 113.278,
            gisGcj02Lat: 23.1332,
            gisGcj02Lng: 113.272,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440103',
            text: '荔湾区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1319,
            gisBd09Lng: 113.251,
            gisGcj02Lat: 23.126,
            gisGcj02Lng: 113.244,
            stubGroupCnt: null,
            pinYin: 'Liwan',
          },
          {
            id: '440104',
            text: '越秀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1351,
            gisBd09Lng: 113.273,
            gisGcj02Lat: 23.1288,
            gisGcj02Lng: 113.267,
            stubGroupCnt: null,
            pinYin: 'Yuexiu',
          },
          {
            id: '440105',
            text: '海珠区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0898,
            gisBd09Lng: 113.324,
            gisGcj02Lat: 23.0839,
            gisGcj02Lng: 113.317,
            stubGroupCnt: null,
            pinYin: 'Haizhu',
          },
          {
            id: '440106',
            text: '天河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1313,
            gisBd09Lng: 113.369,
            gisGcj02Lat: 23.1254,
            gisGcj02Lng: 113.362,
            stubGroupCnt: 4,
            pinYin: 'Tianhe',
          },
          {
            id: '440107',
            text: '芳村区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0733,
            gisBd09Lng: 113.186,
            gisGcj02Lat: 23.0671,
            gisGcj02Lng: 113.18,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440111',
            text: '白云区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1648,
            gisBd09Lng: 113.28,
            gisGcj02Lat: 23.1585,
            gisGcj02Lng: 113.274,
            stubGroupCnt: 9,
            pinYin: 'Baiyun',
          },
          {
            id: '440112',
            text: '黄埔区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1862,
            gisBd09Lng: 113.498,
            gisGcj02Lat: 23.1801,
            gisGcj02Lng: 113.491,
            stubGroupCnt: 6,
            pinYin: 'Huangpu',
          },
          {
            id: '440113',
            text: '番禺区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9411,
            gisBd09Lng: 113.393,
            gisGcj02Lat: 22.9349,
            gisGcj02Lng: 113.386,
            stubGroupCnt: 1,
            pinYin: 'Panyu',
          },
          {
            id: '440114',
            text: '花都区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4074,
            gisBd09Lng: 113.229,
            gisGcj02Lat: 23.4017,
            gisGcj02Lng: 113.222,
            stubGroupCnt: null,
            pinYin: 'Huadu',
          },
          {
            id: '440115',
            text: '南沙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 22.779675,
            gisGcj02Lng: 113.429675,
            stubGroupCnt: null,
            pinYin: 'Nansha',
          },
          {
            id: '440116',
            text: '萝岗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: 23.203669,
            gisGcj02Lng: 113.490954,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440117',
            text: '从化区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: 'e50122ea-abee-4c3d-8418-68fd32bc4c45',
            modifyTime: '2017.01.11 08:44:22',
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 23.203669,
            gisGcj02Lng: 113.490954,
            stubGroupCnt: 1,
            pinYin: 'Conghua',
          },
          {
            id: '440183',
            text: '增城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3489,
            gisBd09Lng: 113.767,
            gisGcj02Lat: 23.3426,
            gisGcj02Lng: 113.761,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 113.275,
        text: '广州',
        gisBd09Lat: 23.1211,
        gisGcj02Lat: 23.1148,
      },
      {
        pinYin: 'Shaoguan',
        id: '440200',
        gisGcj02Lng: 113.597,
        children: [
          {
            id: '440201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.816,
            gisBd09Lng: 113.604,
            gisGcj02Lat: 24.8102,
            gisGcj02Lng: 113.597,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440203',
            text: '武江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7964,
            gisBd09Lng: 113.594,
            gisGcj02Lat: 24.7907,
            gisGcj02Lng: 113.587,
            stubGroupCnt: null,
            pinYin: 'Wujiang',
          },
          {
            id: '440204',
            text: '浈江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.81,
            gisBd09Lng: 113.618,
            gisGcj02Lat: 24.804,
            gisGcj02Lng: 113.611,
            stubGroupCnt: null,
            pinYin: 'Zhenjiang',
          },
          {
            id: '440205',
            text: '曲江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6881,
            gisBd09Lng: 113.61,
            gisGcj02Lat: 24.6822,
            gisGcj02Lng: 113.604,
            stubGroupCnt: null,
            pinYin: 'Qujiang',
          },
          {
            id: '440222',
            text: '始兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9597,
            gisBd09Lng: 114.068,
            gisGcj02Lat: 24.954,
            gisGcj02Lng: 114.062,
            stubGroupCnt: null,
            pinYin: 'Shixing',
          },
          {
            id: '440224',
            text: '仁化县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0913,
            gisBd09Lng: 113.756,
            gisGcj02Lat: 25.085,
            gisGcj02Lng: 113.749,
            stubGroupCnt: null,
            pinYin: 'Renhua',
          },
          {
            id: '440229',
            text: '翁源县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3564,
            gisBd09Lng: 114.137,
            gisGcj02Lat: 24.3501,
            gisGcj02Lng: 114.13,
            stubGroupCnt: null,
            pinYin: 'Wengyuan',
          },
          {
            id: '440232',
            text: '乳源瑶族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9504,
            gisBd09Lng: 113.438,
            gisGcj02Lat: 24.9445,
            gisGcj02Lng: 113.432,
            stubGroupCnt: null,
            pinYin: 'Ruyuan',
          },
          {
            id: '440233',
            text: '新丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0649,
            gisBd09Lng: 114.213,
            gisGcj02Lat: 24.059,
            gisGcj02Lng: 114.207,
            stubGroupCnt: null,
            pinYin: 'Xinfeng',
          },
          {
            id: '440281',
            text: '乐昌',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 24.059,
            gisGcj02Lng: 114.207,
            stubGroupCnt: null,
            pinYin: 'Lechang',
          },
          {
            id: '440282',
            text: '南雄',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1252,
            gisBd09Lng: 114.318,
            gisGcj02Lat: 25.1195,
            gisGcj02Lng: 114.311,
            stubGroupCnt: null,
            pinYin: 'Nanxiong',
          },
        ],
        gisBd09Lng: 113.604,
        text: '韶关',
        gisBd09Lat: 24.816,
        gisGcj02Lat: 24.8102,
      },
      {
        pinYin: 'Shenzhen',
        id: '440300',
        gisGcj02Lng: 114.06,
        children: [
          {
            id: '440301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5481,
            gisBd09Lng: 114.066,
            gisGcj02Lat: 22.5425,
            gisGcj02Lng: 114.06,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440303',
            text: '罗湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.554,
            gisBd09Lng: 114.138,
            gisGcj02Lat: 22.5478,
            gisGcj02Lng: 114.132,
            stubGroupCnt: null,
            pinYin: 'Luohu',
          },
          {
            id: '440304',
            text: '福田区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.528,
            gisBd09Lng: 114.061,
            gisGcj02Lat: 22.5223,
            gisGcj02Lng: 114.054,
            stubGroupCnt: null,
            pinYin: 'Futian',
          },
          {
            id: '440305',
            text: '南山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.538,
            gisBd09Lng: 113.937,
            gisGcj02Lat: 22.5323,
            gisGcj02Lng: 113.931,
            stubGroupCnt: null,
            pinYin: 'Nanshan',
          },
          {
            id: '440306',
            text: '宝安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5604,
            gisBd09Lng: 113.89,
            gisGcj02Lat: 22.5541,
            gisGcj02Lng: 113.884,
            stubGroupCnt: null,
            pinYin: "Bao'an",
          },
          {
            id: '440307',
            text: '龙岗区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7248,
            gisBd09Lng: 114.255,
            gisGcj02Lat: 22.7185,
            gisGcj02Lng: 114.249,
            stubGroupCnt: null,
            pinYin: 'Longgang',
          },
          {
            id: '440308',
            text: '盐田区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5607,
            gisBd09Lng: 114.243,
            gisGcj02Lat: 22.5544,
            gisGcj02Lng: 114.236,
            stubGroupCnt: null,
            pinYin: 'Yantian',
          },
        ],
        gisBd09Lng: 114.066,
        text: '深圳',
        gisBd09Lat: 22.5481,
        gisGcj02Lat: 22.5425,
      },
      {
        pinYin: 'Zhuhai',
        id: '440400',
        gisGcj02Lng: 113.576,
        children: [
          {
            id: '440401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.276,
            gisBd09Lng: 113.583,
            gisGcj02Lat: 22.2703,
            gisGcj02Lng: 113.577,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440402',
            text: '香洲区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.2726,
            gisBd09Lng: 113.551,
            gisGcj02Lat: 22.2665,
            gisGcj02Lng: 113.544,
            stubGroupCnt: null,
            pinYin: 'Xiangzhou',
          },
          {
            id: '440403',
            text: '斗门区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.2149,
            gisBd09Lng: 113.303,
            gisGcj02Lat: 22.2087,
            gisGcj02Lng: 113.296,
            stubGroupCnt: null,
            pinYin: 'Doumen',
          },
          {
            id: '440404',
            text: '金湾区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1514,
            gisBd09Lng: 113.37,
            gisGcj02Lat: 22.1455,
            gisGcj02Lng: 113.363,
            stubGroupCnt: null,
            pinYin: 'Jinwan',
          },
        ],
        gisBd09Lng: 113.583,
        text: '珠海',
        gisBd09Lat: 22.2759,
        gisGcj02Lat: 22.2702,
      },
      {
        pinYin: 'Shantou',
        id: '440500',
        gisGcj02Lng: 116.681,
        children: [
          {
            id: '440501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3592,
            gisBd09Lng: 116.688,
            gisGcj02Lat: 23.3534,
            gisGcj02Lng: 116.681,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440507',
            text: '龙湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3778,
            gisBd09Lng: 116.723,
            gisGcj02Lat: 23.3721,
            gisGcj02Lng: 116.716,
            stubGroupCnt: null,
            pinYin: 'Longhu',
          },
          {
            id: '440511',
            text: '金平区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3703,
            gisBd09Lng: 116.71,
            gisGcj02Lat: 23.3647,
            gisGcj02Lng: 116.704,
            stubGroupCnt: null,
            pinYin: 'Jinping',
          },
          {
            id: '440512',
            text: '濠江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.2912,
            gisBd09Lng: 116.733,
            gisGcj02Lat: 23.2853,
            gisGcj02Lng: 116.726,
            stubGroupCnt: null,
            pinYin: 'Haojiang',
          },
          {
            id: '440513',
            text: '潮阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.2699,
            gisBd09Lng: 116.608,
            gisGcj02Lat: 23.2641,
            gisGcj02Lng: 116.602,
            stubGroupCnt: null,
            pinYin: 'Chaoyang',
          },
          {
            id: '440514',
            text: '潮南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.2561,
            gisBd09Lng: 116.439,
            gisGcj02Lat: 23.2501,
            gisGcj02Lng: 116.433,
            stubGroupCnt: null,
            pinYin: 'Chaonan',
          },
          {
            id: '440515',
            text: '澄海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4723,
            gisBd09Lng: 116.763,
            gisGcj02Lat: 23.466,
            gisGcj02Lng: 116.756,
            stubGroupCnt: null,
            pinYin: 'Chenghai',
          },
          {
            id: '440523',
            text: '南澳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4272,
            gisBd09Lng: 117.03,
            gisGcj02Lat: 23.4211,
            gisGcj02Lng: 117.024,
            stubGroupCnt: null,
            pinYin: 'Nanao',
          },
        ],
        gisBd09Lng: 116.688,
        text: '汕头',
        gisBd09Lat: 23.3592,
        gisGcj02Lat: 23.3534,
      },
      {
        pinYin: 'Foshan',
        id: '440600',
        gisGcj02Lng: 113.122,
        children: [
          {
            id: '440601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0268,
            gisBd09Lng: 113.128,
            gisGcj02Lat: 23.0209,
            gisGcj02Lng: 113.122,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440604',
            text: '禅城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0152,
            gisBd09Lng: 113.129,
            gisGcj02Lat: 23.0094,
            gisGcj02Lng: 113.122,
            stubGroupCnt: null,
            pinYin: 'Chancheng',
          },
          {
            id: '440605',
            text: '南海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.034,
            gisBd09Lng: 113.149,
            gisGcj02Lat: 23.0278,
            gisGcj02Lng: 113.143,
            stubGroupCnt: null,
            pinYin: 'Nanhai',
          },
          {
            id: '440606',
            text: '顺德区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8093,
            gisBd09Lng: 113.301,
            gisGcj02Lat: 22.803,
            gisGcj02Lng: 113.294,
            stubGroupCnt: null,
            pinYin: 'Shunde',
          },
          {
            id: '440607',
            text: '三水区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1617,
            gisBd09Lng: 112.904,
            gisGcj02Lat: 23.1556,
            gisGcj02Lng: 112.897,
            stubGroupCnt: null,
            pinYin: 'Sanshui',
          },
          {
            id: '440608',
            text: '高明区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9056,
            gisBd09Lng: 112.899,
            gisGcj02Lat: 22.8996,
            gisGcj02Lng: 112.892,
            stubGroupCnt: null,
            pinYin: 'Gaoming',
          },
        ],
        gisBd09Lng: 113.128,
        text: '佛山',
        gisBd09Lat: 23.0268,
        gisGcj02Lat: 23.0209,
      },
      {
        pinYin: 'Jiangmen',
        id: '440700',
        gisGcj02Lng: 113.081,
        children: [
          {
            id: '440701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5839,
            gisBd09Lng: 113.088,
            gisGcj02Lat: 22.578,
            gisGcj02Lng: 113.081,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440703',
            text: '蓬江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.6005,
            gisBd09Lng: 113.085,
            gisGcj02Lat: 22.5946,
            gisGcj02Lng: 113.078,
            stubGroupCnt: null,
            pinYin: 'Pengjiang',
          },
          {
            id: '440704',
            text: '江海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5656,
            gisBd09Lng: 113.117,
            gisGcj02Lat: 22.5599,
            gisGcj02Lng: 113.111,
            stubGroupCnt: null,
            pinYin: 'Jianghai',
          },
          {
            id: '440705',
            text: '新会区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.4643,
            gisBd09Lng: 113.039,
            gisGcj02Lat: 22.458,
            gisGcj02Lng: 113.033,
            stubGroupCnt: null,
            pinYin: 'Xinhui',
          },
          {
            id: '440781',
            text: '台山',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.2557,
            gisBd09Lng: 112.801,
            gisGcj02Lat: 22.2494,
            gisGcj02Lng: 112.794,
            stubGroupCnt: null,
            pinYin: 'Taishan',
          },
          {
            id: '440783',
            text: '开平',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.3815,
            gisBd09Lng: 112.705,
            gisGcj02Lat: 22.3753,
            gisGcj02Lng: 112.698,
            stubGroupCnt: null,
            pinYin: 'Kaiping',
          },
          {
            id: '440784',
            text: '鹤山',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7703,
            gisBd09Lng: 112.971,
            gisGcj02Lat: 22.7646,
            gisGcj02Lng: 112.965,
            stubGroupCnt: null,
            pinYin: 'Heshan',
          },
          {
            id: '440785',
            text: '恩平',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1882,
            gisBd09Lng: 112.313,
            gisGcj02Lat: 22.182,
            gisGcj02Lng: 112.306,
            stubGroupCnt: null,
            pinYin: 'Enping',
          },
        ],
        gisBd09Lng: 113.088,
        text: '江门',
        gisBd09Lat: 22.5839,
        gisGcj02Lat: 22.578,
      },
      {
        pinYin: 'Zhanjiang',
        id: '440800',
        gisGcj02Lng: 110.359,
        children: [
          {
            id: '440801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.2764,
            gisBd09Lng: 110.366,
            gisGcj02Lat: 21.2705,
            gisGcj02Lng: 110.359,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440802',
            text: '赤坎区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.2712,
            gisBd09Lng: 110.372,
            gisGcj02Lat: 21.2653,
            gisGcj02Lng: 110.366,
            stubGroupCnt: null,
            pinYin: 'Chikan',
          },
          {
            id: '440803',
            text: '霞山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.1979,
            gisBd09Lng: 110.404,
            gisGcj02Lat: 21.1916,
            gisGcj02Lng: 110.398,
            stubGroupCnt: null,
            pinYin: 'Xiashan',
          },
          {
            id: '440804',
            text: '坡头区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.25,
            gisBd09Lng: 110.462,
            gisGcj02Lat: 21.2443,
            gisGcj02Lng: 110.455,
            stubGroupCnt: null,
            pinYin: 'Potou',
          },
          {
            id: '440811',
            text: '麻章区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.2689,
            gisBd09Lng: 110.341,
            gisGcj02Lat: 21.2632,
            gisGcj02Lng: 110.334,
            stubGroupCnt: null,
            pinYin: 'Mazhang',
          },
          {
            id: '440823',
            text: '遂溪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.3833,
            gisBd09Lng: 110.257,
            gisGcj02Lat: 21.3773,
            gisGcj02Lng: 110.251,
            stubGroupCnt: null,
            pinYin: 'Suixi',
          },
          {
            id: '440825',
            text: '徐闻县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.3321,
            gisBd09Lng: 110.182,
            gisGcj02Lat: 20.3259,
            gisGcj02Lng: 110.176,
            stubGroupCnt: null,
            pinYin: 'Xuwen',
          },
          {
            id: '440881',
            text: '廉江',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6159,
            gisBd09Lng: 110.293,
            gisGcj02Lat: 21.6096,
            gisGcj02Lng: 110.287,
            stubGroupCnt: null,
            pinYin: 'Lianjiang',
          },
          {
            id: '440882',
            text: '雷州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.919,
            gisBd09Lng: 110.103,
            gisGcj02Lat: 20.9134,
            gisGcj02Lng: 110.096,
            stubGroupCnt: null,
            pinYin: 'Leizhou',
          },
          {
            id: '440883',
            text: '吴川',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.4471,
            gisBd09Lng: 110.785,
            gisGcj02Lat: 21.4409,
            gisGcj02Lng: 110.779,
            stubGroupCnt: null,
            pinYin: 'Wuchuan',
          },
        ],
        gisBd09Lng: 110.366,
        text: '湛江',
        gisBd09Lat: 21.2764,
        gisGcj02Lat: 21.2705,
      },
      {
        pinYin: 'Maoming',
        id: '440900',
        gisGcj02Lng: 110.925,
        children: [
          {
            id: '440901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6687,
            gisBd09Lng: 110.931,
            gisGcj02Lat: 21.663,
            gisGcj02Lng: 110.925,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440902',
            text: '茂南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6467,
            gisBd09Lng: 110.925,
            gisGcj02Lat: 21.6409,
            gisGcj02Lng: 110.919,
            stubGroupCnt: null,
            pinYin: 'Maonan',
          },
          {
            id: '440903',
            text: '茂港区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.5794,
            gisBd09Lng: 110.988,
            gisGcj02Lat: 21.5732,
            gisGcj02Lng: 110.981,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440923',
            text: '电白县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6849,
            gisBd09Lng: 111.241,
            gisGcj02Lat: 21.6785,
            gisGcj02Lng: 111.234,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '440981',
            text: '高州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.9236,
            gisBd09Lng: 110.86,
            gisGcj02Lat: 21.9175,
            gisGcj02Lng: 110.853,
            stubGroupCnt: null,
            pinYin: 'Gaozhou',
          },
          {
            id: '440982',
            text: '化州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6695,
            gisBd09Lng: 110.647,
            gisGcj02Lat: 21.6632,
            gisGcj02Lng: 110.64,
            stubGroupCnt: null,
            pinYin: 'Huazhou',
          },
          {
            id: '440983',
            text: '信宜',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.3598,
            gisBd09Lng: 110.954,
            gisGcj02Lat: 22.3541,
            gisGcj02Lng: 110.947,
            stubGroupCnt: null,
            pinYin: 'Xinyi',
          },
        ],
        gisBd09Lng: 110.931,
        text: '茂名',
        gisBd09Lat: 21.6687,
        gisGcj02Lat: 21.663,
      },
      {
        pinYin: 'Zhaoqing',
        id: '441200',
        gisGcj02Lng: 112.464,
        children: [
          {
            id: '441201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0524,
            gisBd09Lng: 112.472,
            gisGcj02Lat: 23.0463,
            gisGcj02Lng: 112.465,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441202',
            text: '端州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0572,
            gisBd09Lng: 112.491,
            gisGcj02Lat: 23.0514,
            gisGcj02Lng: 112.484,
            stubGroupCnt: null,
            pinYin: 'Duanzhou',
          },
          {
            id: '441203',
            text: '鼎湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1637,
            gisBd09Lng: 112.574,
            gisGcj02Lat: 23.1574,
            gisGcj02Lng: 112.567,
            stubGroupCnt: null,
            pinYin: 'Dinghu',
          },
          {
            id: '441223',
            text: '广宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6411,
            gisBd09Lng: 112.448,
            gisGcj02Lat: 23.6347,
            gisGcj02Lng: 112.442,
            stubGroupCnt: null,
            pinYin: 'Guangning',
          },
          {
            id: '441224',
            text: '怀集县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9184,
            gisBd09Lng: 112.192,
            gisGcj02Lat: 23.9122,
            gisGcj02Lng: 112.186,
            stubGroupCnt: null,
            pinYin: 'Huaiji',
          },
          {
            id: '441225',
            text: '封开县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4286,
            gisBd09Lng: 111.519,
            gisGcj02Lat: 23.4226,
            gisGcj02Lng: 111.512,
            stubGroupCnt: null,
            pinYin: 'Fengkai',
          },
          {
            id: '441226',
            text: '德庆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1486,
            gisBd09Lng: 111.793,
            gisGcj02Lat: 23.143,
            gisGcj02Lng: 111.787,
            stubGroupCnt: null,
            pinYin: 'Deqing',
          },
          {
            id: '441283',
            text: '高要',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0309,
            gisBd09Lng: 112.465,
            gisGcj02Lat: 23.0247,
            gisGcj02Lng: 112.458,
            stubGroupCnt: null,
            pinYin: 'Gaoyao',
          },
          {
            id: '441284',
            text: '四会',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3321,
            gisBd09Lng: 112.74,
            gisGcj02Lat: 23.3264,
            gisGcj02Lng: 112.733,
            stubGroupCnt: null,
            pinYin: 'Sihui',
          },
        ],
        gisBd09Lng: 112.471,
        text: '肇庆',
        gisBd09Lat: 23.0526,
        gisGcj02Lat: 23.0465,
      },
      {
        pinYin: 'Huizhou',
        id: '441300',
        gisGcj02Lng: 114.416,
        children: [
          {
            id: '441301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1151,
            gisBd09Lng: 114.423,
            gisGcj02Lat: 23.1094,
            gisGcj02Lng: 114.416,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441302',
            text: '惠城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0898,
            gisBd09Lng: 114.389,
            gisGcj02Lat: 23.0837,
            gisGcj02Lng: 114.382,
            stubGroupCnt: null,
            pinYin: 'Huicheng',
          },
          {
            id: '441303',
            text: '惠阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7943,
            gisBd09Lng: 114.463,
            gisGcj02Lat: 22.7882,
            gisGcj02Lng: 114.456,
            stubGroupCnt: null,
            pinYin: 'Huiyang',
          },
          {
            id: '441322',
            text: '博罗县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1793,
            gisBd09Lng: 114.298,
            gisGcj02Lat: 23.1736,
            gisGcj02Lng: 114.292,
            stubGroupCnt: null,
            pinYin: 'Boluo',
          },
          {
            id: '441323',
            text: '惠东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.99,
            gisBd09Lng: 114.726,
            gisGcj02Lat: 22.9837,
            gisGcj02Lng: 114.719,
            stubGroupCnt: null,
            pinYin: 'Huidong',
          },
          {
            id: '441324',
            text: '龙门县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7331,
            gisBd09Lng: 114.262,
            gisGcj02Lat: 23.7268,
            gisGcj02Lng: 114.256,
            stubGroupCnt: null,
            pinYin: 'Longmen',
          },
        ],
        gisBd09Lng: 114.423,
        text: '惠州',
        gisBd09Lat: 23.1151,
        gisGcj02Lat: 23.1094,
      },
      {
        pinYin: 'Meizhou',
        id: '441400',
        gisGcj02Lng: 116.123,
        children: [
          {
            id: '441401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.2941,
            gisBd09Lng: 116.129,
            gisGcj02Lat: 24.2883,
            gisGcj02Lng: 116.123,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441402',
            text: '梅江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3157,
            gisBd09Lng: 116.123,
            gisGcj02Lat: 24.31,
            gisGcj02Lng: 116.117,
            stubGroupCnt: null,
            pinYin: 'Meijiang',
          },
          {
            id: '441421',
            text: '梅　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3713,
            gisBd09Lng: 116.2,
            gisGcj02Lat: 24.3654,
            gisGcj02Lng: 116.193,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441422',
            text: '大埔县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3523,
            gisBd09Lng: 116.703,
            gisGcj02Lat: 24.3466,
            gisGcj02Lng: 116.696,
            stubGroupCnt: null,
            pinYin: 'Dabu',
          },
          {
            id: '441423',
            text: '丰顺县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7443,
            gisBd09Lng: 116.189,
            gisGcj02Lat: 23.7382,
            gisGcj02Lng: 116.182,
            stubGroupCnt: null,
            pinYin: 'Fengshun',
          },
          {
            id: '441424',
            text: '五华县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9368,
            gisBd09Lng: 115.783,
            gisGcj02Lat: 23.9307,
            gisGcj02Lng: 115.776,
            stubGroupCnt: null,
            pinYin: 'Wuhua',
          },
          {
            id: '441426',
            text: '平远县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5721,
            gisBd09Lng: 115.898,
            gisGcj02Lat: 24.5661,
            gisGcj02Lng: 115.892,
            stubGroupCnt: null,
            pinYin: 'Pingyuan',
          },
          {
            id: '441427',
            text: '蕉岭县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.664,
            gisBd09Lng: 116.178,
            gisGcj02Lat: 24.6578,
            gisGcj02Lng: 116.171,
            stubGroupCnt: null,
            pinYin: 'Jiaoling',
          },
          {
            id: '441481',
            text: '兴宁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.142,
            gisBd09Lng: 115.738,
            gisGcj02Lat: 24.1363,
            gisGcj02Lng: 115.732,
            stubGroupCnt: null,
            pinYin: 'Xingning',
          },
        ],
        gisBd09Lng: 116.129,
        text: '梅州',
        gisBd09Lat: 24.2941,
        gisGcj02Lat: 24.2883,
      },
      {
        pinYin: 'Shanwei',
        id: '441500',
        gisGcj02Lng: 115.376,
        children: [
          {
            id: '441501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7908,
            gisBd09Lng: 115.382,
            gisGcj02Lat: 22.7851,
            gisGcj02Lng: 115.376,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441502',
            text: '城　区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7832,
            gisBd09Lng: 115.373,
            gisGcj02Lat: 22.7775,
            gisGcj02Lng: 115.366,
            stubGroupCnt: null,
            pinYin: 'Chengqu',
          },
          {
            id: '441521',
            text: '海丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9725,
            gisBd09Lng: 115.33,
            gisGcj02Lat: 22.9662,
            gisGcj02Lng: 115.324,
            stubGroupCnt: null,
            pinYin: 'Haifeng',
          },
          {
            id: '441523',
            text: '陆河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3072,
            gisBd09Lng: 115.667,
            gisGcj02Lat: 23.301,
            gisGcj02Lng: 115.66,
            stubGroupCnt: null,
            pinYin: 'Luhe',
          },
          {
            id: '441581',
            text: '陆丰',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9509,
            gisBd09Lng: 115.651,
            gisGcj02Lat: 22.945,
            gisGcj02Lng: 115.645,
            stubGroupCnt: null,
            pinYin: 'Lufeng',
          },
        ],
        gisBd09Lng: 115.382,
        text: '汕尾',
        gisBd09Lat: 22.7908,
        gisGcj02Lat: 22.7851,
      },
      {
        pinYin: 'Heyuan',
        id: '441600',
        gisGcj02Lng: 114.7,
        children: [
          {
            id: '441601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7491,
            gisBd09Lng: 114.707,
            gisGcj02Lat: 23.7429,
            gisGcj02Lng: 114.7,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441602',
            text: '源城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7398,
            gisBd09Lng: 114.709,
            gisGcj02Lat: 23.7336,
            gisGcj02Lng: 114.703,
            stubGroupCnt: null,
            pinYin: 'Yuancheng',
          },
          {
            id: '441621',
            text: '紫金县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6408,
            gisBd09Lng: 115.19,
            gisGcj02Lat: 23.6345,
            gisGcj02Lng: 115.184,
            stubGroupCnt: null,
            pinYin: 'Zijin',
          },
          {
            id: '441622',
            text: '龙川县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1053,
            gisBd09Lng: 115.267,
            gisGcj02Lat: 24.0997,
            gisGcj02Lng: 115.26,
            stubGroupCnt: null,
            pinYin: 'Longchuan',
          },
          {
            id: '441623',
            text: '连平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3757,
            gisBd09Lng: 114.496,
            gisGcj02Lat: 24.3694,
            gisGcj02Lng: 114.489,
            stubGroupCnt: null,
            pinYin: 'Lianping',
          },
          {
            id: '441624',
            text: '和平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4472,
            gisBd09Lng: 114.946,
            gisGcj02Lat: 24.4411,
            gisGcj02Lng: 114.94,
            stubGroupCnt: null,
            pinYin: 'Heping',
          },
          {
            id: '441625',
            text: '东源县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7939,
            gisBd09Lng: 114.753,
            gisGcj02Lat: 23.7878,
            gisGcj02Lng: 114.747,
            stubGroupCnt: null,
            pinYin: 'Dongyuan',
          },
        ],
        gisBd09Lng: 114.707,
        text: '河源',
        gisBd09Lat: 23.7491,
        gisGcj02Lat: 23.7429,
      },
      {
        pinYin: 'Yangjiang',
        id: '441700',
        gisGcj02Lng: 111.983,
        children: [
          {
            id: '441701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.8641,
            gisBd09Lng: 111.99,
            gisGcj02Lat: 21.858,
            gisGcj02Lng: 111.983,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441702',
            text: '江城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.8674,
            gisBd09Lng: 111.962,
            gisGcj02Lat: 21.8611,
            gisGcj02Lng: 111.955,
            stubGroupCnt: null,
            pinYin: 'Jiangcheng',
          },
          {
            id: '441721',
            text: '阳西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.758,
            gisBd09Lng: 111.624,
            gisGcj02Lat: 21.7518,
            gisGcj02Lng: 111.618,
            stubGroupCnt: null,
            pinYin: 'Yangxi',
          },
          {
            id: '441723',
            text: '阳东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.9636,
            gisBd09Lng: 112.067,
            gisGcj02Lat: 21.9575,
            gisGcj02Lng: 112.06,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441781',
            text: '阳春',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1756,
            gisBd09Lng: 111.798,
            gisGcj02Lat: 22.1699,
            gisGcj02Lng: 111.792,
            stubGroupCnt: null,
            pinYin: 'Yangchun',
          },
        ],
        gisBd09Lng: 111.99,
        text: '阳江',
        gisBd09Lat: 21.8641,
        gisGcj02Lat: 21.858,
      },
      {
        pinYin: 'Qingyuan',
        id: '441800',
        gisGcj02Lng: 113.057,
        children: [
          {
            id: '441801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6878,
            gisBd09Lng: 113.063,
            gisGcj02Lat: 23.6816,
            gisGcj02Lng: 113.057,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441802',
            text: '清城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7032,
            gisBd09Lng: 113.07,
            gisGcj02Lat: 23.6971,
            gisGcj02Lng: 113.063,
            stubGroupCnt: null,
            pinYin: 'Qingcheng',
          },
          {
            id: '441821',
            text: '佛冈县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.884,
            gisBd09Lng: 113.539,
            gisGcj02Lat: 23.8778,
            gisGcj02Lng: 113.532,
            stubGroupCnt: null,
            pinYin: 'Fogang',
          },
          {
            id: '441823',
            text: '阳山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4702,
            gisBd09Lng: 112.648,
            gisGcj02Lat: 24.4643,
            gisGcj02Lng: 112.642,
            stubGroupCnt: null,
            pinYin: 'Yangshan',
          },
          {
            id: '441825',
            text: '连山壮族瑶族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5762,
            gisBd09Lng: 112.1,
            gisGcj02Lat: 24.57,
            gisGcj02Lng: 112.093,
            stubGroupCnt: null,
            pinYin: 'Lianshan',
          },
          {
            id: '441826',
            text: '连南瑶族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7313,
            gisBd09Lng: 112.294,
            gisGcj02Lat: 24.7254,
            gisGcj02Lng: 112.287,
            stubGroupCnt: null,
            pinYin: 'Liannan',
          },
          {
            id: '441827',
            text: '清新县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.899,
            gisBd09Lng: 112.886,
            gisGcj02Lat: 23.8932,
            gisGcj02Lng: 112.88,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '441881',
            text: '英德',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1918,
            gisBd09Lng: 113.422,
            gisGcj02Lat: 24.1856,
            gisGcj02Lng: 113.415,
            stubGroupCnt: null,
            pinYin: 'Yingde',
          },
          {
            id: '441882',
            text: '连州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7835,
            gisBd09Lng: 112.383,
            gisGcj02Lat: 24.7779,
            gisGcj02Lng: 112.377,
            stubGroupCnt: null,
            pinYin: 'Lianzhou',
          },
        ],
        gisBd09Lng: 113.063,
        text: '清远',
        gisBd09Lat: 23.6878,
        gisGcj02Lat: 23.6816,
      },
      {
        pinYin: 'Dongguan',
        id: '441900',
        gisGcj02Lng: 113.752,
        children: [
          {
            id: '441901',
            text: '东莞',
            status: 1,
            orderId: '2016.02.22 17:03:46',
            createAccount: '',
            createTime: '2016.02.22 17:03:48',
            modifyAccount: '',
            modifyTime: '2016.02.22 17:03:50',
            gisBd09Lat: 23.027,
            gisBd09Lng: 113.758,
            gisGcj02Lat: 23.0207,
            gisGcj02Lng: 113.752,
            stubGroupCnt: null,
            pinYin: 'Guancheng',
          },
        ],
        gisBd09Lng: 113.758,
        text: '东莞',
        gisBd09Lat: 23.027,
        gisGcj02Lat: 23.0207,
      },
      {
        pinYin: 'Zhongshan',
        id: '442000',
        gisGcj02Lng: 113.393,
        children: [
          {
            id: '442001',
            text: '中山',
            status: 1,
            orderId: '2016.02.22 17:04:25',
            createAccount: '',
            createTime: '2016.02.22 17:04:27',
            modifyAccount: '1',
            modifyTime: '2016.05.16 13:07:53',
            gisBd09Lat: 22.5222,
            gisBd09Lng: 113.399,
            gisGcj02Lat: 22.5159,
            gisGcj02Lng: 113.393,
            stubGroupCnt: null,
            pinYin: 'Shiqi',
          },
        ],
        gisBd09Lng: 113.399,
        text: '中山',
        gisBd09Lat: 22.5222,
        gisGcj02Lat: 22.5159,
      },
      {
        pinYin: 'Chaozhou',
        id: '445100',
        gisGcj02Lng: 116.624,
        children: [
          {
            id: '445101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6621,
            gisBd09Lng: 116.63,
            gisGcj02Lat: 23.6559,
            gisGcj02Lng: 116.624,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445102',
            text: '湘桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6801,
            gisBd09Lng: 116.636,
            gisGcj02Lat: 23.6738,
            gisGcj02Lng: 116.629,
            stubGroupCnt: null,
            pinYin: 'Xiangqiao',
          },
          {
            id: '445121',
            text: '潮安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7259,
            gisBd09Lng: 116.634,
            gisGcj02Lat: 23.7196,
            gisGcj02Lng: 116.627,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445122',
            text: '饶平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.67,
            gisBd09Lng: 117.01,
            gisGcj02Lat: 23.6637,
            gisGcj02Lng: 117.004,
            stubGroupCnt: null,
            pinYin: 'Raoping',
          },
        ],
        gisBd09Lng: 116.63,
        text: '潮州',
        gisBd09Lat: 23.6621,
        gisGcj02Lat: 23.6559,
      },
      {
        pinYin: 'Jieyang',
        id: '445200',
        gisGcj02Lng: 116.373,
        children: [
          {
            id: '445201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.5548,
            gisBd09Lng: 116.38,
            gisGcj02Lat: 23.5488,
            gisGcj02Lng: 116.373,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445202',
            text: '榕城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.53,
            gisBd09Lng: 116.374,
            gisGcj02Lat: 23.5241,
            gisGcj02Lng: 116.367,
            stubGroupCnt: null,
            pinYin: 'Rongcheng',
          },
          {
            id: '445221',
            text: '揭东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6066,
            gisBd09Lng: 116.362,
            gisGcj02Lat: 23.6009,
            gisGcj02Lng: 116.355,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445222',
            text: '揭西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4365,
            gisBd09Lng: 115.849,
            gisGcj02Lat: 23.4307,
            gisGcj02Lng: 115.843,
            stubGroupCnt: null,
            pinYin: 'Jiexi',
          },
          {
            id: '445224',
            text: '惠来县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0387,
            gisBd09Lng: 116.301,
            gisGcj02Lat: 23.0325,
            gisGcj02Lng: 116.295,
            stubGroupCnt: null,
            pinYin: 'Huilai',
          },
          {
            id: '445281',
            text: '普宁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.303,
            gisBd09Lng: 116.172,
            gisGcj02Lat: 23.2966,
            gisGcj02Lng: 116.165,
            stubGroupCnt: null,
            pinYin: 'Puning',
          },
        ],
        gisBd09Lng: 116.38,
        text: '揭阳',
        gisBd09Lat: 23.5548,
        gisGcj02Lat: 23.5488,
      },
      {
        pinYin: 'Yunfu',
        id: '445300',
        gisGcj02Lng: 112.045,
        children: [
          {
            id: '445301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9199,
            gisBd09Lng: 112.052,
            gisGcj02Lat: 22.914,
            gisGcj02Lng: 112.045,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445302',
            text: '云城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9338,
            gisBd09Lng: 112.051,
            gisGcj02Lat: 22.9279,
            gisGcj02Lng: 112.045,
            stubGroupCnt: null,
            pinYin: 'Yuncheng',
          },
          {
            id: '445321',
            text: '新兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7011,
            gisBd09Lng: 112.232,
            gisGcj02Lat: 22.695,
            gisGcj02Lng: 112.225,
            stubGroupCnt: null,
            pinYin: 'Xinxing',
          },
          {
            id: '445322',
            text: '郁南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.2395,
            gisBd09Lng: 111.544,
            gisGcj02Lat: 23.2338,
            gisGcj02Lng: 111.537,
            stubGroupCnt: null,
            pinYin: 'Yunan',
          },
          {
            id: '445323',
            text: '云安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8474,
            gisBd09Lng: 111.963,
            gisGcj02Lat: 22.8411,
            gisGcj02Lng: 111.957,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '445381',
            text: '罗定',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 22.8411,
            gisGcj02Lng: 111.957,
            stubGroupCnt: null,
            pinYin: 'Luoding',
          },
        ],
        gisBd09Lng: 112.052,
        text: '云浮',
        gisBd09Lat: 22.9199,
        gisGcj02Lat: 22.914,
      },
    ],
  },
  {
    text: '广西省',
    children: [
      {
        pinYin: 'Nanning',
        id: '450100',
        gisGcj02Lng: 108.368,
        children: [
          {
            id: '450101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8225,
            gisBd09Lng: 108.374,
            gisGcj02Lat: 22.8162,
            gisGcj02Lng: 108.368,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450102',
            text: '兴宁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8602,
            gisBd09Lng: 108.373,
            gisGcj02Lat: 22.8539,
            gisGcj02Lng: 108.367,
            stubGroupCnt: null,
            pinYin: 'Xingning',
          },
          {
            id: '450103',
            text: '青秀区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7924,
            gisBd09Lng: 108.504,
            gisGcj02Lat: 22.7862,
            gisGcj02Lng: 108.498,
            stubGroupCnt: null,
            pinYin: 'Qingxiu',
          },
          {
            id: '450105',
            text: '江南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7879,
            gisBd09Lng: 108.281,
            gisGcj02Lat: 22.782,
            gisGcj02Lng: 108.275,
            stubGroupCnt: null,
            pinYin: 'Jiangnan',
          },
          {
            id: '450107',
            text: '西乡塘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8397,
            gisBd09Lng: 108.321,
            gisGcj02Lat: 22.834,
            gisGcj02Lng: 108.314,
            stubGroupCnt: null,
            pinYin: 'Xixiangtang',
          },
          {
            id: '450108',
            text: '良庆区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7655,
            gisBd09Lng: 108.328,
            gisGcj02Lat: 22.7596,
            gisGcj02Lng: 108.322,
            stubGroupCnt: null,
            pinYin: 'Liangqing',
          },
          {
            id: '450109',
            text: '邕宁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7668,
            gisBd09Lng: 108.497,
            gisGcj02Lat: 22.7605,
            gisGcj02Lng: 108.49,
            stubGroupCnt: null,
            pinYin: 'Yongning',
          },
          {
            id: '450122',
            text: '武鸣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1654,
            gisBd09Lng: 108.284,
            gisGcj02Lat: 23.1595,
            gisGcj02Lng: 108.277,
            stubGroupCnt: null,
            pinYin: 'Wuming',
          },
          {
            id: '450123',
            text: '隆安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1718,
            gisBd09Lng: 107.705,
            gisGcj02Lat: 23.1662,
            gisGcj02Lng: 107.698,
            stubGroupCnt: null,
            pinYin: "Long'an",
          },
          {
            id: '450124',
            text: '马山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7174,
            gisBd09Lng: 108.185,
            gisGcj02Lat: 23.7117,
            gisGcj02Lng: 108.179,
            stubGroupCnt: null,
            pinYin: 'Mashan',
          },
          {
            id: '450125',
            text: '上林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4376,
            gisBd09Lng: 108.609,
            gisGcj02Lat: 23.4312,
            gisGcj02Lng: 108.602,
            stubGroupCnt: null,
            pinYin: 'Shanglin',
          },
          {
            id: '450126',
            text: '宾阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.22,
            gisBd09Lng: 108.819,
            gisGcj02Lat: 23.214,
            gisGcj02Lng: 108.813,
            stubGroupCnt: null,
            pinYin: 'Binyang',
          },
          {
            id: '450127',
            text: '横　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.6916,
            gisBd09Lng: 109.27,
            gisGcj02Lat: 22.6859,
            gisGcj02Lng: 109.263,
            stubGroupCnt: null,
            pinYin: 'Hengxian',
          },
        ],
        gisBd09Lng: 108.374,
        text: '南宁',
        gisBd09Lat: 22.8225,
        gisGcj02Lat: 22.8162,
      },
      {
        pinYin: 'Liuzhou',
        id: '450200',
        gisGcj02Lng: 109.415,
        children: [
          {
            id: '450201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3313,
            gisBd09Lng: 109.421,
            gisGcj02Lat: 24.3252,
            gisGcj02Lng: 109.415,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450202',
            text: '城中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3464,
            gisBd09Lng: 109.454,
            gisGcj02Lat: 24.3401,
            gisGcj02Lng: 109.448,
            stubGroupCnt: null,
            pinYin: 'Chengzhong',
          },
          {
            id: '450203',
            text: '鱼峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.2847,
            gisBd09Lng: 109.452,
            gisGcj02Lat: 24.2784,
            gisGcj02Lng: 109.446,
            stubGroupCnt: null,
            pinYin: 'Yufeng',
          },
          {
            id: '450204',
            text: '柳南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3405,
            gisBd09Lng: 109.392,
            gisGcj02Lat: 24.3348,
            gisGcj02Lng: 109.385,
            stubGroupCnt: null,
            pinYin: 'Liunan',
          },
          {
            id: '450205',
            text: '柳北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3683,
            gisBd09Lng: 109.409,
            gisGcj02Lat: 24.3624,
            gisGcj02Lng: 109.403,
            stubGroupCnt: null,
            pinYin: 'Liubei',
          },
          {
            id: '450221',
            text: '柳江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.261,
            gisBd09Lng: 109.334,
            gisGcj02Lat: 24.2547,
            gisGcj02Lng: 109.328,
            stubGroupCnt: null,
            pinYin: 'Liujiang',
          },
          {
            id: '450222',
            text: '柳城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6574,
            gisBd09Lng: 109.252,
            gisGcj02Lat: 24.6516,
            gisGcj02Lng: 109.246,
            stubGroupCnt: null,
            pinYin: 'Liucheng',
          },
          {
            id: '450223',
            text: '鹿寨县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4767,
            gisBd09Lng: 109.759,
            gisGcj02Lat: 24.471,
            gisGcj02Lng: 109.753,
            stubGroupCnt: null,
            pinYin: 'Luzhai',
          },
          {
            id: '450224',
            text: '融安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2296,
            gisBd09Lng: 109.406,
            gisGcj02Lat: 25.2238,
            gisGcj02Lng: 109.399,
            stubGroupCnt: null,
            pinYin: "Rong'an",
          },
          {
            id: '450225',
            text: '融水苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0757,
            gisBd09Lng: 109.26,
            gisGcj02Lat: 25.07,
            gisGcj02Lng: 109.254,
            stubGroupCnt: null,
            pinYin: 'Rongshui',
          },
          {
            id: '450226',
            text: '三江侗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.79,
            gisBd09Lng: 109.611,
            gisGcj02Lat: 25.7842,
            gisGcj02Lng: 109.605,
            stubGroupCnt: null,
            pinYin: 'Sanjiang',
          },
        ],
        gisBd09Lng: 109.421,
        text: '柳州',
        gisBd09Lat: 24.3313,
        gisGcj02Lat: 24.3252,
      },
      {
        pinYin: 'Guilin',
        id: '450300',
        gisGcj02Lng: 110.29,
        children: [
          {
            id: '450301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2834,
            gisBd09Lng: 110.302,
            gisGcj02Lat: 25.2772,
            gisGcj02Lng: 110.296,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450302',
            text: '秀峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2876,
            gisBd09Lng: 110.295,
            gisGcj02Lat: 25.2814,
            gisGcj02Lng: 110.289,
            stubGroupCnt: null,
            pinYin: 'Xiufeng',
          },
          {
            id: '450303',
            text: '叠彩区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.32,
            gisBd09Lng: 110.311,
            gisGcj02Lat: 25.3139,
            gisGcj02Lng: 110.304,
            stubGroupCnt: null,
            pinYin: 'Diecai',
          },
          {
            id: '450304',
            text: '象山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2681,
            gisBd09Lng: 110.287,
            gisGcj02Lat: 25.2618,
            gisGcj02Lng: 110.28,
            stubGroupCnt: null,
            pinYin: 'Xiangshan',
          },
          {
            id: '450305',
            text: '七星区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2584,
            gisBd09Lng: 110.325,
            gisGcj02Lat: 25.2526,
            gisGcj02Lng: 110.319,
            stubGroupCnt: null,
            pinYin: 'Qixing',
          },
          {
            id: '450311',
            text: '雁山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0665,
            gisBd09Lng: 110.316,
            gisGcj02Lat: 25.0605,
            gisGcj02Lng: 110.309,
            stubGroupCnt: null,
            pinYin: 'Yanshan',
          },
          {
            id: '450321',
            text: '阳朔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7845,
            gisBd09Lng: 110.505,
            gisGcj02Lat: 24.7784,
            gisGcj02Lng: 110.498,
            stubGroupCnt: null,
            pinYin: 'Yangshuo',
          },
          {
            id: '450322',
            text: '临桂县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.244,
            gisBd09Lng: 110.22,
            gisGcj02Lat: 25.2383,
            gisGcj02Lng: 110.214,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450323',
            text: '灵川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4158,
            gisBd09Lng: 110.331,
            gisGcj02Lat: 25.4101,
            gisGcj02Lng: 110.324,
            stubGroupCnt: null,
            pinYin: 'Lingchuan',
          },
          {
            id: '450324',
            text: '全州县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9335,
            gisBd09Lng: 111.079,
            gisGcj02Lat: 25.9278,
            gisGcj02Lng: 111.073,
            stubGroupCnt: null,
            pinYin: 'Quanzhou',
          },
          {
            id: '450325',
            text: '兴安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6166,
            gisBd09Lng: 110.678,
            gisGcj02Lat: 25.6106,
            gisGcj02Lng: 110.671,
            stubGroupCnt: null,
            pinYin: "Xing'an",
          },
          {
            id: '450326',
            text: '永福县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9854,
            gisBd09Lng: 109.991,
            gisGcj02Lat: 24.9797,
            gisGcj02Lng: 109.984,
            stubGroupCnt: null,
            pinYin: 'Yongfu',
          },
          {
            id: '450327',
            text: '灌阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4946,
            gisBd09Lng: 111.169,
            gisGcj02Lat: 25.4888,
            gisGcj02Lng: 111.162,
            stubGroupCnt: null,
            pinYin: 'Guanyang',
          },
          {
            id: '450328',
            text: '龙胜各族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8033,
            gisBd09Lng: 110.017,
            gisGcj02Lat: 25.7973,
            gisGcj02Lng: 110.01,
            stubGroupCnt: null,
            pinYin: 'Longsheng',
          },
          {
            id: '450329',
            text: '资源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0474,
            gisBd09Lng: 110.661,
            gisGcj02Lat: 26.0411,
            gisGcj02Lng: 110.654,
            stubGroupCnt: null,
            pinYin: 'Ziyuan',
          },
          {
            id: '450330',
            text: '平乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6393,
            gisBd09Lng: 110.651,
            gisGcj02Lat: 24.633,
            gisGcj02Lng: 110.644,
            stubGroupCnt: null,
            pinYin: 'Pingle',
          },
          {
            id: '450331',
            text: '荔蒲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4947,
            gisBd09Lng: 110.401,
            gisGcj02Lat: 24.4884,
            gisGcj02Lng: 110.395,
            stubGroupCnt: null,
            pinYin: 'Lipu',
          },
          {
            id: '450332',
            text: '恭城瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8369,
            gisBd09Lng: 110.836,
            gisGcj02Lat: 24.8312,
            gisGcj02Lng: 110.83,
            stubGroupCnt: null,
            pinYin: 'Gongcheng',
          },
        ],
        gisBd09Lng: 110.296,
        text: '桂林',
        gisBd09Lat: 25.2796,
        gisGcj02Lat: 25.2733,
      },
      {
        pinYin: 'Wuzhou',
        id: '450400',
        gisGcj02Lng: 111.278,
        children: [
          {
            id: '450401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4822,
            gisBd09Lng: 111.285,
            gisGcj02Lat: 23.4764,
            gisGcj02Lng: 111.278,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450403',
            text: '万秀区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4784,
            gisBd09Lng: 111.327,
            gisGcj02Lat: 23.4725,
            gisGcj02Lng: 111.32,
            stubGroupCnt: null,
            pinYin: 'Wanxiu',
          },
          {
            id: '450404',
            text: '蝶山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.5456,
            gisBd09Lng: 111.311,
            gisGcj02Lat: 23.54,
            gisGcj02Lng: 111.305,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450405',
            text: '长洲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4914,
            gisBd09Lng: 111.281,
            gisGcj02Lat: 23.4854,
            gisGcj02Lng: 111.274,
            stubGroupCnt: null,
            pinYin: 'Changzhou',
          },
          {
            id: '450421',
            text: '苍梧县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4216,
            gisBd09Lng: 111.253,
            gisGcj02Lat: 23.4153,
            gisGcj02Lng: 111.246,
            stubGroupCnt: null,
            pinYin: 'Cangwu',
          },
          {
            id: '450422',
            text: '藤　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3807,
            gisBd09Lng: 110.919,
            gisGcj02Lat: 23.3748,
            gisGcj02Lng: 110.912,
            stubGroupCnt: null,
            pinYin: 'Tengxian',
          },
          {
            id: '450423',
            text: '蒙山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.2001,
            gisBd09Lng: 110.531,
            gisGcj02Lat: 24.1937,
            gisGcj02Lng: 110.524,
            stubGroupCnt: null,
            pinYin: 'Mengshan',
          },
          {
            id: '450481',
            text: '岑溪',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9245,
            gisBd09Lng: 111.002,
            gisGcj02Lat: 22.9181,
            gisGcj02Lng: 110.996,
            stubGroupCnt: null,
            pinYin: 'Cenxi',
          },
        ],
        gisBd09Lng: 111.285,
        text: '梧州',
        gisBd09Lat: 23.4822,
        gisGcj02Lat: 23.4764,
      },
      {
        pinYin: 'Beihai',
        id: '450500',
        gisGcj02Lng: 109.12,
        children: [
          {
            id: '450501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.4855,
            gisBd09Lng: 109.127,
            gisGcj02Lat: 21.4797,
            gisGcj02Lng: 109.12,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450502',
            text: '海城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.4811,
            gisBd09Lng: 109.125,
            gisGcj02Lat: 21.4752,
            gisGcj02Lng: 109.118,
            stubGroupCnt: null,
            pinYin: 'Haicheng',
          },
          {
            id: '450503',
            text: '银海区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.4549,
            gisBd09Lng: 109.147,
            gisGcj02Lat: 21.4492,
            gisGcj02Lng: 109.14,
            stubGroupCnt: null,
            pinYin: 'Yinhai',
          },
          {
            id: '450512',
            text: '铁山港区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.5351,
            gisBd09Lng: 109.429,
            gisGcj02Lat: 21.5289,
            gisGcj02Lng: 109.422,
            stubGroupCnt: null,
            pinYin: 'Tieshangang',
          },
          {
            id: '450521',
            text: '合浦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6674,
            gisBd09Lng: 109.212,
            gisGcj02Lat: 21.6611,
            gisGcj02Lng: 109.206,
            stubGroupCnt: null,
            pinYin: 'Hepu',
          },
        ],
        gisBd09Lng: 109.127,
        text: '北海',
        gisBd09Lat: 21.4855,
        gisGcj02Lat: 21.4797,
      },
      {
        pinYin: 'Fangchenggang',
        id: '450600',
        gisGcj02Lng: 108.356,
        children: [
          {
            id: '450601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6936,
            gisBd09Lng: 108.363,
            gisGcj02Lat: 21.6873,
            gisGcj02Lng: 108.356,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450602',
            text: '港口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.6497,
            gisBd09Lng: 108.387,
            gisGcj02Lat: 21.6436,
            gisGcj02Lng: 108.38,
            stubGroupCnt: null,
            pinYin: 'Gangkou',
          },
          {
            id: '450603',
            text: '防城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.7754,
            gisBd09Lng: 108.36,
            gisGcj02Lat: 21.7691,
            gisGcj02Lng: 108.354,
            stubGroupCnt: null,
            pinYin: 'Fangcheng',
          },
          {
            id: '450621',
            text: '上思县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1598,
            gisBd09Lng: 107.99,
            gisGcj02Lat: 22.1536,
            gisGcj02Lng: 107.984,
            stubGroupCnt: null,
            pinYin: 'Shangsi',
          },
          {
            id: '450681',
            text: '东兴',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.5539,
            gisBd09Lng: 107.979,
            gisGcj02Lat: 21.5478,
            gisGcj02Lng: 107.973,
            stubGroupCnt: null,
            pinYin: 'Dongxing',
          },
        ],
        gisBd09Lng: 108.363,
        text: '防城港',
        gisBd09Lat: 21.6936,
        gisGcj02Lat: 21.6873,
      },
      {
        pinYin: 'Qinzhou',
        id: '450700',
        gisGcj02Lng: 108.655,
        children: [
          {
            id: '450701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.9865,
            gisBd09Lng: 108.662,
            gisGcj02Lat: 21.9808,
            gisGcj02Lng: 108.655,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450702',
            text: '钦南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.9448,
            gisBd09Lng: 108.664,
            gisGcj02Lat: 21.9391,
            gisGcj02Lng: 108.657,
            stubGroupCnt: null,
            pinYin: 'Qinnan',
          },
          {
            id: '450703',
            text: '钦北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1801,
            gisBd09Lng: 108.584,
            gisGcj02Lat: 22.174,
            gisGcj02Lng: 108.578,
            stubGroupCnt: null,
            pinYin: 'Qinbei',
          },
          {
            id: '450721',
            text: '灵山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.4225,
            gisBd09Lng: 109.298,
            gisGcj02Lat: 22.4165,
            gisGcj02Lng: 109.292,
            stubGroupCnt: null,
            pinYin: 'Lingshan',
          },
          {
            id: '450722',
            text: '浦北县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.2776,
            gisBd09Lng: 109.564,
            gisGcj02Lat: 22.2712,
            gisGcj02Lng: 109.557,
            stubGroupCnt: null,
            pinYin: 'Pubei',
          },
        ],
        gisBd09Lng: 108.662,
        text: '钦州',
        gisBd09Lat: 21.9865,
        gisGcj02Lat: 21.9808,
      },
      {
        pinYin: 'Guigang',
        id: '450800',
        gisGcj02Lng: 109.598,
        children: [
          {
            id: '450801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1174,
            gisBd09Lng: 109.605,
            gisGcj02Lat: 23.1115,
            gisGcj02Lng: 109.599,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450802',
            text: '港北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1172,
            gisBd09Lng: 109.579,
            gisGcj02Lat: 23.111,
            gisGcj02Lng: 109.573,
            stubGroupCnt: null,
            pinYin: 'Gangbei',
          },
          {
            id: '450803',
            text: '港南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0793,
            gisBd09Lng: 109.606,
            gisGcj02Lat: 23.0735,
            gisGcj02Lng: 109.599,
            stubGroupCnt: null,
            pinYin: 'Gangnan',
          },
          {
            id: '450804',
            text: '覃塘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1334,
            gisBd09Lng: 109.461,
            gisGcj02Lat: 23.1272,
            gisGcj02Lng: 109.454,
            stubGroupCnt: null,
            pinYin: 'Qintang',
          },
          {
            id: '450821',
            text: '平南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.5462,
            gisBd09Lng: 110.399,
            gisGcj02Lat: 23.5399,
            gisGcj02Lng: 110.393,
            stubGroupCnt: null,
            pinYin: 'Pingnan',
          },
          {
            id: '450881',
            text: '桂平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3999,
            gisBd09Lng: 110.082,
            gisGcj02Lat: 23.394,
            gisGcj02Lng: 110.076,
            stubGroupCnt: null,
            pinYin: 'Guiping',
          },
        ],
        gisBd09Lng: 109.604,
        text: '贵港',
        gisBd09Lat: 23.1188,
        gisGcj02Lat: 23.1129,
      },
      {
        pinYin: 'Yulin',
        id: '450900',
        gisGcj02Lng: 110.182,
        children: [
          {
            id: '450901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.6604,
            gisBd09Lng: 110.188,
            gisGcj02Lat: 22.6542,
            gisGcj02Lng: 110.182,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '450902',
            text: '玉州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.6347,
            gisBd09Lng: 110.158,
            gisGcj02Lat: 22.6284,
            gisGcj02Lng: 110.152,
            stubGroupCnt: null,
            pinYin: 'Yuzhou',
          },
          {
            id: '450921',
            text: '容　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8627,
            gisBd09Lng: 110.561,
            gisGcj02Lat: 22.8568,
            gisGcj02Lng: 110.554,
            stubGroupCnt: null,
            pinYin: 'Rongxian',
          },
          {
            id: '450922',
            text: '陆川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.3269,
            gisBd09Lng: 110.271,
            gisGcj02Lat: 22.3207,
            gisGcj02Lng: 110.265,
            stubGroupCnt: null,
            pinYin: 'Luchuan',
          },
          {
            id: '450923',
            text: '博白县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.2784,
            gisBd09Lng: 109.983,
            gisGcj02Lat: 22.2727,
            gisGcj02Lng: 109.977,
            stubGroupCnt: null,
            pinYin: 'Bobai',
          },
          {
            id: '450924',
            text: '兴业县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7412,
            gisBd09Lng: 109.882,
            gisGcj02Lat: 22.7354,
            gisGcj02Lng: 109.875,
            stubGroupCnt: null,
            pinYin: 'Xingye',
          },
          {
            id: '450981',
            text: '北流',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7137,
            gisBd09Lng: 110.361,
            gisGcj02Lat: 22.7079,
            gisGcj02Lng: 110.354,
            stubGroupCnt: null,
            pinYin: 'Beiliu',
          },
        ],
        gisBd09Lng: 110.188,
        text: '玉林',
        gisBd09Lat: 22.6604,
        gisGcj02Lat: 22.6542,
      },
      {
        pinYin: 'Baise',
        id: '451000',
        gisGcj02Lng: 106.619,
        children: [
          {
            id: '451001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9071,
            gisBd09Lng: 106.626,
            gisGcj02Lat: 23.9014,
            gisGcj02Lng: 106.619,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '451002',
            text: '右江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9067,
            gisBd09Lng: 106.626,
            gisGcj02Lat: 23.901,
            gisGcj02Lng: 106.619,
            stubGroupCnt: null,
            pinYin: 'Youjiang',
          },
          {
            id: '451021',
            text: '田阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7414,
            gisBd09Lng: 106.922,
            gisGcj02Lat: 23.7351,
            gisGcj02Lng: 106.916,
            stubGroupCnt: null,
            pinYin: 'Tianyang',
          },
          {
            id: '451022',
            text: '田东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6015,
            gisBd09Lng: 107.133,
            gisGcj02Lat: 23.5956,
            gisGcj02Lng: 107.126,
            stubGroupCnt: null,
            pinYin: 'Tiandong',
          },
          {
            id: '451023',
            text: '平果县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3342,
            gisBd09Lng: 107.597,
            gisGcj02Lat: 23.3285,
            gisGcj02Lng: 107.59,
            stubGroupCnt: null,
            pinYin: 'Pingguo',
          },
          {
            id: '451024',
            text: '德保县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3286,
            gisBd09Lng: 106.621,
            gisGcj02Lat: 23.3229,
            gisGcj02Lng: 106.615,
            stubGroupCnt: null,
            pinYin: 'Debao',
          },
          {
            id: '451025',
            text: '靖西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1396,
            gisBd09Lng: 106.424,
            gisGcj02Lat: 23.1335,
            gisGcj02Lng: 106.418,
            stubGroupCnt: null,
            pinYin: 'Jingxi',
          },
          {
            id: '451026',
            text: '那坡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3932,
            gisBd09Lng: 105.841,
            gisGcj02Lat: 23.3868,
            gisGcj02Lng: 105.835,
            stubGroupCnt: null,
            pinYin: 'Napo',
          },
          {
            id: '451027',
            text: '凌云县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3533,
            gisBd09Lng: 106.568,
            gisGcj02Lat: 24.347,
            gisGcj02Lng: 106.562,
            stubGroupCnt: null,
            pinYin: 'Lingyun',
          },
          {
            id: '451028',
            text: '乐业县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7922,
            gisBd09Lng: 106.568,
            gisGcj02Lat: 24.7859,
            gisGcj02Lng: 106.561,
            stubGroupCnt: null,
            pinYin: 'Leye',
          },
          {
            id: '451029',
            text: '田林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3,
            gisBd09Lng: 106.236,
            gisGcj02Lat: 24.294,
            gisGcj02Lng: 106.229,
            stubGroupCnt: null,
            pinYin: 'Tianlin',
          },
          {
            id: '451030',
            text: '西林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4948,
            gisBd09Lng: 105.101,
            gisGcj02Lat: 24.4888,
            gisGcj02Lng: 105.094,
            stubGroupCnt: null,
            pinYin: 'Xilin',
          },
          {
            id: '451031',
            text: '隆林各族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7768,
            gisBd09Lng: 105.35,
            gisGcj02Lat: 24.7706,
            gisGcj02Lng: 105.344,
            stubGroupCnt: null,
            pinYin: 'Longlin',
          },
        ],
        gisBd09Lng: 106.626,
        text: '百色',
        gisBd09Lat: 23.9071,
        gisGcj02Lat: 23.9014,
      },
      {
        pinYin: 'Hezhou',
        id: '451100',
        gisGcj02Lng: 111.567,
        children: [
          {
            id: '451101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4092,
            gisBd09Lng: 111.573,
            gisGcj02Lat: 24.4033,
            gisGcj02Lng: 111.567,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '451102',
            text: '八步区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.417,
            gisBd09Lng: 111.559,
            gisGcj02Lat: 24.4112,
            gisGcj02Lng: 111.552,
            stubGroupCnt: null,
            pinYin: 'Babu',
          },
          {
            id: '451121',
            text: '昭平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1748,
            gisBd09Lng: 110.818,
            gisGcj02Lat: 24.1691,
            gisGcj02Lng: 110.812,
            stubGroupCnt: null,
            pinYin: 'Zhaoping',
          },
          {
            id: '451122',
            text: '钟山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5317,
            gisBd09Lng: 111.309,
            gisGcj02Lat: 24.526,
            gisGcj02Lng: 111.303,
            stubGroupCnt: null,
            pinYin: 'Zhongshan',
          },
          {
            id: '451123',
            text: '富川瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8202,
            gisBd09Lng: 111.284,
            gisGcj02Lat: 24.8143,
            gisGcj02Lng: 111.277,
            stubGroupCnt: null,
            pinYin: 'Fuchuan',
          },
        ],
        gisBd09Lng: 111.573,
        text: '贺州',
        gisBd09Lat: 24.4092,
        gisGcj02Lat: 24.4033,
      },
      {
        pinYin: 'Hechi',
        id: '451200',
        gisGcj02Lng: 108.086,
        children: [
          {
            id: '451201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6983,
            gisBd09Lng: 108.092,
            gisGcj02Lat: 24.6924,
            gisGcj02Lng: 108.086,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '451202',
            text: '金城江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6951,
            gisBd09Lng: 108.044,
            gisGcj02Lat: 24.6893,
            gisGcj02Lng: 108.037,
            stubGroupCnt: null,
            pinYin: 'Jinchengjiang',
          },
          {
            id: '451221',
            text: '南丹县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9811,
            gisBd09Lng: 107.549,
            gisGcj02Lat: 24.975,
            gisGcj02Lng: 107.543,
            stubGroupCnt: null,
            pinYin: 'Nandan',
          },
          {
            id: '451222',
            text: '天峨县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0049,
            gisBd09Lng: 107.18,
            gisGcj02Lat: 24.9987,
            gisGcj02Lng: 107.174,
            stubGroupCnt: null,
            pinYin: 'Tiane',
          },
          {
            id: '451223',
            text: '凤山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5527,
            gisBd09Lng: 107.049,
            gisGcj02Lat: 24.5464,
            gisGcj02Lng: 107.042,
            stubGroupCnt: null,
            pinYin: 'Fengshan',
          },
          {
            id: '451224',
            text: '东兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5167,
            gisBd09Lng: 107.381,
            gisGcj02Lat: 24.5107,
            gisGcj02Lng: 107.374,
            stubGroupCnt: null,
            pinYin: 'Donglan',
          },
          {
            id: '451225',
            text: '罗城仫佬族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7827,
            gisBd09Lng: 108.911,
            gisGcj02Lat: 24.777,
            gisGcj02Lng: 108.905,
            stubGroupCnt: null,
            pinYin: 'Luocheng',
          },
          {
            id: '451226',
            text: '环江毛南族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8309,
            gisBd09Lng: 108.265,
            gisGcj02Lat: 24.8248,
            gisGcj02Lng: 108.258,
            stubGroupCnt: null,
            pinYin: 'Huanjiang',
          },
          {
            id: '451227',
            text: '巴马瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1471,
            gisBd09Lng: 107.266,
            gisGcj02Lat: 24.141,
            gisGcj02Lng: 107.26,
            stubGroupCnt: null,
            pinYin: 'Bama',
          },
          {
            id: '451228',
            text: '都安瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9373,
            gisBd09Lng: 108.112,
            gisGcj02Lat: 23.931,
            gisGcj02Lng: 108.105,
            stubGroupCnt: null,
            pinYin: "Du'an",
          },
          {
            id: '451229',
            text: '大化瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7415,
            gisBd09Lng: 108.004,
            gisGcj02Lat: 23.7352,
            gisGcj02Lng: 107.997,
            stubGroupCnt: null,
            pinYin: 'Dahua',
          },
          {
            id: '451281',
            text: '宜州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4905,
            gisBd09Lng: 108.643,
            gisGcj02Lat: 24.4846,
            gisGcj02Lng: 108.637,
            stubGroupCnt: null,
            pinYin: 'Yizhou',
          },
        ],
        gisBd09Lng: 108.092,
        text: '河池',
        gisBd09Lat: 24.6983,
        gisGcj02Lat: 24.6924,
      },
      {
        pinYin: 'Laibin',
        id: '451300',
        gisGcj02Lng: 109.222,
        children: [
          {
            id: '451301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7582,
            gisBd09Lng: 109.229,
            gisGcj02Lat: 23.7521,
            gisGcj02Lng: 109.222,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '451302',
            text: '兴宾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7332,
            gisBd09Lng: 109.241,
            gisGcj02Lat: 23.7273,
            gisGcj02Lng: 109.235,
            stubGroupCnt: null,
            pinYin: 'Xingbin',
          },
          {
            id: '451321',
            text: '忻城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0717,
            gisBd09Lng: 108.672,
            gisGcj02Lat: 24.066,
            gisGcj02Lng: 108.666,
            stubGroupCnt: null,
            pinYin: 'Xincheng',
          },
          {
            id: '451322',
            text: '象州县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9795,
            gisBd09Lng: 109.711,
            gisGcj02Lat: 23.9734,
            gisGcj02Lng: 109.705,
            stubGroupCnt: null,
            pinYin: 'Xiangzhou',
          },
          {
            id: '451323',
            text: '武宣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6001,
            gisBd09Lng: 109.67,
            gisGcj02Lat: 23.5939,
            gisGcj02Lng: 109.663,
            stubGroupCnt: null,
            pinYin: 'Wuxuan',
          },
          {
            id: '451324',
            text: '金秀瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1363,
            gisBd09Lng: 110.196,
            gisGcj02Lat: 24.1303,
            gisGcj02Lng: 110.189,
            stubGroupCnt: null,
            pinYin: 'Jinxiu',
          },
          {
            id: '451381',
            text: '合山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.8117,
            gisBd09Lng: 108.893,
            gisGcj02Lat: 23.8059,
            gisGcj02Lng: 108.887,
            stubGroupCnt: null,
            pinYin: 'Heshan',
          },
        ],
        gisBd09Lng: 109.229,
        text: '来宾',
        gisBd09Lat: 23.7582,
        gisGcj02Lat: 23.7521,
      },
      {
        pinYin: 'Chongzuo',
        id: '451400',
        gisGcj02Lng: 107.365,
        children: [
          {
            id: '451401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.3849,
            gisBd09Lng: 107.371,
            gisGcj02Lat: 22.3789,
            gisGcj02Lng: 107.365,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '451402',
            text: '江洲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.4112,
            gisBd09Lng: 107.36,
            gisGcj02Lat: 22.4054,
            gisGcj02Lng: 107.354,
            stubGroupCnt: null,
            pinYin: 'Jiangzhou',
          },
          {
            id: '451421',
            text: '扶绥县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.641,
            gisBd09Lng: 107.911,
            gisGcj02Lat: 22.6349,
            gisGcj02Lng: 107.904,
            stubGroupCnt: null,
            pinYin: 'Fusui',
          },
          {
            id: '451422',
            text: '宁明县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1459,
            gisBd09Lng: 107.083,
            gisGcj02Lat: 22.14,
            gisGcj02Lng: 107.077,
            stubGroupCnt: null,
            pinYin: 'Ningming',
          },
          {
            id: '451423',
            text: '龙州县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.348,
            gisBd09Lng: 106.861,
            gisGcj02Lat: 22.3423,
            gisGcj02Lng: 106.854,
            stubGroupCnt: null,
            pinYin: 'Longzhou',
          },
          {
            id: '451424',
            text: '大新县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.8356,
            gisBd09Lng: 107.207,
            gisGcj02Lat: 22.8297,
            gisGcj02Lng: 107.201,
            stubGroupCnt: null,
            pinYin: 'Daxin',
          },
          {
            id: '451425',
            text: '天等县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0871,
            gisBd09Lng: 107.15,
            gisGcj02Lat: 23.0809,
            gisGcj02Lng: 107.144,
            stubGroupCnt: null,
            pinYin: 'Tiandeng',
          },
          {
            id: '451481',
            text: '凭祥',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.1001,
            gisBd09Lng: 106.773,
            gisGcj02Lat: 22.0941,
            gisGcj02Lng: 106.767,
            stubGroupCnt: null,
            pinYin: 'Pingxiang',
          },
        ],
        gisBd09Lng: 107.371,
        text: '崇左',
        gisBd09Lat: 22.3849,
        gisGcj02Lat: 22.3789,
      },
    ],
  },
  {
    text: '贵州省',
    children: [
      {
        pinYin: 'Guiyang',
        id: '520100',
        gisGcj02Lng: 106.622,
        children: [
          {
            id: '520101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6497,
            gisBd09Lng: 106.629,
            gisGcj02Lat: 26.644,
            gisGcj02Lng: 106.622,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '520102',
            text: '南明区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5741,
            gisBd09Lng: 106.72,
            gisGcj02Lat: 26.5682,
            gisGcj02Lng: 106.714,
            stubGroupCnt: null,
            pinYin: 'Nanming',
          },
          {
            id: '520103',
            text: '云岩区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6107,
            gisBd09Lng: 106.732,
            gisGcj02Lat: 26.6049,
            gisGcj02Lng: 106.725,
            stubGroupCnt: null,
            pinYin: 'Yunyan',
          },
          {
            id: '520111',
            text: '花溪区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4587,
            gisBd09Lng: 106.642,
            gisGcj02Lat: 26.4529,
            gisGcj02Lng: 106.635,
            stubGroupCnt: 1,
            pinYin: 'Huaxi',
          },
          {
            id: '520112',
            text: '乌当区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6355,
            gisBd09Lng: 106.758,
            gisGcj02Lat: 26.6298,
            gisGcj02Lng: 106.752,
            stubGroupCnt: null,
            pinYin: 'Wudang',
          },
          {
            id: '520113',
            text: '白云区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6837,
            gisBd09Lng: 106.63,
            gisGcj02Lat: 26.678,
            gisGcj02Lng: 106.623,
            stubGroupCnt: null,
            pinYin: 'Baiyun',
          },
          {
            id: '520114',
            text: '小河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4966,
            gisBd09Lng: 106.702,
            gisGcj02Lat: 26.4903,
            gisGcj02Lng: 106.695,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '520121',
            text: '开阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0631,
            gisBd09Lng: 106.972,
            gisGcj02Lat: 27.0573,
            gisGcj02Lng: 106.965,
            stubGroupCnt: null,
            pinYin: 'Kaiyang',
          },
          {
            id: '520122',
            text: '息烽县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0963,
            gisBd09Lng: 106.748,
            gisGcj02Lat: 27.0907,
            gisGcj02Lng: 106.741,
            stubGroupCnt: null,
            pinYin: 'Xifeng',
          },
          {
            id: '520123',
            text: '修文县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8448,
            gisBd09Lng: 106.6,
            gisGcj02Lat: 26.8389,
            gisGcj02Lng: 106.593,
            stubGroupCnt: null,
            pinYin: 'Xiuwen',
          },
          {
            id: '520181',
            text: '清镇',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5614,
            gisBd09Lng: 106.477,
            gisGcj02Lat: 26.5554,
            gisGcj02Lng: 106.47,
            stubGroupCnt: null,
            pinYin: 'Qingzhen',
          },
        ],
        gisBd09Lng: 106.629,
        text: '贵阳',
        gisBd09Lat: 26.6497,
        gisGcj02Lat: 26.644,
      },
      {
        pinYin: 'Liupanshui',
        id: '520200',
        gisGcj02Lng: 104.832,
        children: [
          {
            id: '520201',
            text: '钟山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5868,
            gisBd09Lng: 104.858,
            gisGcj02Lat: 26.5808,
            gisGcj02Lng: 104.852,
            stubGroupCnt: null,
            pinYin: 'Zhongshan',
          },
          {
            id: '520203',
            text: '六枝特区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2117,
            gisBd09Lng: 105.485,
            gisGcj02Lat: 26.2054,
            gisGcj02Lng: 105.479,
            stubGroupCnt: null,
            pinYin: 'Liuzhi',
          },
          {
            id: '520221',
            text: '水城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5517,
            gisBd09Lng: 104.963,
            gisGcj02Lat: 26.5459,
            gisGcj02Lng: 104.957,
            stubGroupCnt: null,
            pinYin: 'Shuicheng',
          },
          {
            id: '520222',
            text: '盘　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7154,
            gisBd09Lng: 104.478,
            gisGcj02Lat: 25.7097,
            gisGcj02Lng: 104.471,
            stubGroupCnt: null,
            pinYin: 'Panxian',
          },
        ],
        gisBd09Lng: 104.838,
        text: '六盘水',
        gisBd09Lat: 26.599,
        gisGcj02Lat: 26.5932,
      },
      {
        pinYin: 'Zunyi',
        id: '520300',
        gisGcj02Lng: 106.931,
        children: [
          {
            id: '520301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7262,
            gisBd09Lng: 106.938,
            gisGcj02Lat: 27.7199,
            gisGcj02Lng: 106.931,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '520302',
            text: '红花岗区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6509,
            gisBd09Lng: 106.901,
            gisGcj02Lat: 27.6449,
            gisGcj02Lng: 106.894,
            stubGroupCnt: null,
            pinYin: 'Honghuagang',
          },
          {
            id: '520303',
            text: '汇川区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7559,
            gisBd09Lng: 106.94,
            gisGcj02Lat: 27.7496,
            gisGcj02Lng: 106.934,
            stubGroupCnt: null,
            pinYin: 'Huichuan',
          },
          {
            id: '520321',
            text: '遵义县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5405,
            gisBd09Lng: 106.835,
            gisGcj02Lat: 27.5344,
            gisGcj02Lng: 106.828,
            stubGroupCnt: null,
            pinYin: 'Zunyi',
          },
          {
            id: '520322',
            text: '桐梓县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1367,
            gisBd09Lng: 106.833,
            gisGcj02Lat: 28.1307,
            gisGcj02Lng: 106.827,
            stubGroupCnt: null,
            pinYin: 'Tongzi',
          },
          {
            id: '520323',
            text: '绥阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9541,
            gisBd09Lng: 107.197,
            gisGcj02Lat: 27.9481,
            gisGcj02Lng: 107.19,
            stubGroupCnt: null,
            pinYin: 'Suiyang',
          },
          {
            id: '520324',
            text: '正安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5594,
            gisBd09Lng: 107.448,
            gisGcj02Lat: 28.5536,
            gisGcj02Lng: 107.441,
            stubGroupCnt: null,
            pinYin: "Zheng'an",
          },
          {
            id: '520325',
            text: '道真仡佬族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8786,
            gisBd09Lng: 107.621,
            gisGcj02Lat: 28.8725,
            gisGcj02Lng: 107.614,
            stubGroupCnt: null,
            pinYin: 'Daozhen',
          },
          {
            id: '520326',
            text: '务川仡佬族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5699,
            gisBd09Lng: 107.907,
            gisGcj02Lat: 28.5637,
            gisGcj02Lng: 107.9,
            stubGroupCnt: null,
            pinYin: 'Wuchuan',
          },
          {
            id: '520327',
            text: '凤冈县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.959,
            gisBd09Lng: 107.723,
            gisGcj02Lat: 27.9532,
            gisGcj02Lng: 107.717,
            stubGroupCnt: null,
            pinYin: 'Fenggang',
          },
          {
            id: '520328',
            text: '湄潭县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7537,
            gisBd09Lng: 107.472,
            gisGcj02Lat: 27.748,
            gisGcj02Lng: 107.465,
            stubGroupCnt: null,
            pinYin: 'Meitan',
          },
          {
            id: '520329',
            text: '余庆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2313,
            gisBd09Lng: 107.895,
            gisGcj02Lat: 27.225,
            gisGcj02Lng: 107.888,
            stubGroupCnt: null,
            pinYin: 'Yuqing',
          },
          {
            id: '520330',
            text: '习水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3337,
            gisBd09Lng: 106.211,
            gisGcj02Lat: 28.3273,
            gisGcj02Lng: 106.204,
            stubGroupCnt: null,
            pinYin: 'Xishui',
          },
          {
            id: '520381',
            text: '赤水',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5957,
            gisBd09Lng: 105.707,
            gisGcj02Lat: 28.5895,
            gisGcj02Lng: 105.7,
            stubGroupCnt: null,
            pinYin: 'Chishui',
          },
          {
            id: '520382',
            text: '仁怀',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7979,
            gisBd09Lng: 106.409,
            gisGcj02Lat: 27.7921,
            gisGcj02Lng: 106.402,
            stubGroupCnt: null,
            pinYin: 'Renhuai',
          },
        ],
        gisBd09Lng: 106.938,
        text: '遵义',
        gisBd09Lat: 27.7262,
        gisGcj02Lat: 27.7199,
      },
      {
        pinYin: 'Anshun',
        id: '520400',
        gisGcj02Lng: 105.949,
        children: [
          {
            id: '520401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2595,
            gisBd09Lng: 105.955,
            gisGcj02Lat: 26.2533,
            gisGcj02Lng: 105.949,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '520402',
            text: '西秀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2505,
            gisBd09Lng: 105.971,
            gisGcj02Lat: 26.2442,
            gisGcj02Lng: 105.965,
            stubGroupCnt: null,
            pinYin: 'Xixiu',
          },
          {
            id: '520421',
            text: '平坝县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4124,
            gisBd09Lng: 106.269,
            gisGcj02Lat: 26.4067,
            gisGcj02Lng: 106.263,
            stubGroupCnt: null,
            pinYin: 'Pingba',
          },
          {
            id: '520422',
            text: '普定县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3082,
            gisBd09Lng: 105.75,
            gisGcj02Lat: 26.3021,
            gisGcj02Lng: 105.744,
            stubGroupCnt: null,
            pinYin: 'Puding',
          },
          {
            id: '520423',
            text: '镇宁布依族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0632,
            gisBd09Lng: 105.779,
            gisGcj02Lat: 26.0575,
            gisGcj02Lng: 105.772,
            stubGroupCnt: null,
            pinYin: 'Zhenning',
          },
          {
            id: '520424',
            text: '关岭布依族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9501,
            gisBd09Lng: 105.626,
            gisGcj02Lat: 25.944,
            gisGcj02Lng: 105.619,
            stubGroupCnt: null,
            pinYin: 'Guanling',
          },
          {
            id: '520425',
            text: '紫云苗族布依族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7576,
            gisBd09Lng: 106.088,
            gisGcj02Lat: 25.7513,
            gisGcj02Lng: 106.082,
            stubGroupCnt: null,
            pinYin: 'Ziyun',
          },
        ],
        gisBd09Lng: 105.955,
        text: '安顺',
        gisBd09Lat: 26.2595,
        gisGcj02Lat: 26.2533,
      },
      {
        pinYin: null,
        id: '522200',
        gisGcj02Lng: 109.163,
        children: [
          {
            id: '522201',
            text: '铜仁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6975,
            gisBd09Lng: 109.187,
            gisGcj02Lat: 27.6913,
            gisGcj02Lng: 109.181,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522222',
            text: '江口县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7051,
            gisBd09Lng: 108.848,
            gisGcj02Lat: 27.6988,
            gisGcj02Lng: 108.841,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522223',
            text: '玉屏侗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2407,
            gisBd09Lng: 108.922,
            gisGcj02Lat: 27.235,
            gisGcj02Lng: 108.915,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522224',
            text: '石阡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5195,
            gisBd09Lng: 108.229,
            gisGcj02Lat: 27.5133,
            gisGcj02Lng: 108.223,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522225',
            text: '思南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9457,
            gisBd09Lng: 108.262,
            gisGcj02Lat: 27.9395,
            gisGcj02Lng: 108.255,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522226',
            text: '印江土家族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9998,
            gisBd09Lng: 108.417,
            gisGcj02Lat: 27.9941,
            gisGcj02Lng: 108.41,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522227',
            text: '德江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2696,
            gisBd09Lng: 108.13,
            gisGcj02Lat: 28.2633,
            gisGcj02Lng: 108.124,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522228',
            text: '沿河土家族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5694,
            gisBd09Lng: 108.503,
            gisGcj02Lat: 28.5632,
            gisGcj02Lng: 108.497,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522229',
            text: '松桃苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1752,
            gisBd09Lng: 109.211,
            gisGcj02Lat: 28.1689,
            gisGcj02Lng: 109.205,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522230',
            text: '万山特区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5236,
            gisBd09Lng: 109.22,
            gisGcj02Lat: 27.5173,
            gisGcj02Lng: 109.214,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 109.17,
        text: '铜仁地区',
        gisBd09Lat: 27.6743,
        gisGcj02Lat: 27.6684,
      },
      {
        pinYin: 'Qianxinan',
        id: '522300',
        gisGcj02Lng: 104.904,
        children: [
          {
            id: '522301',
            text: '兴义',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0983,
            gisBd09Lng: 104.904,
            gisGcj02Lat: 25.0921,
            gisGcj02Lng: 104.897,
            stubGroupCnt: null,
            pinYin: 'Xingyi',
          },
          {
            id: '522322',
            text: '兴仁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4409,
            gisBd09Lng: 105.195,
            gisGcj02Lat: 25.4352,
            gisGcj02Lng: 105.188,
            stubGroupCnt: null,
            pinYin: 'Xingren',
          },
          {
            id: '522323',
            text: '普安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7893,
            gisBd09Lng: 104.961,
            gisGcj02Lat: 25.7835,
            gisGcj02Lng: 104.954,
            stubGroupCnt: null,
            pinYin: "Pu'an",
          },
          {
            id: '522324',
            text: '晴隆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8391,
            gisBd09Lng: 105.225,
            gisGcj02Lat: 25.8329,
            gisGcj02Lng: 105.218,
            stubGroupCnt: null,
            pinYin: 'Qinglong',
          },
          {
            id: '522325',
            text: '贞丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3907,
            gisBd09Lng: 105.658,
            gisGcj02Lat: 25.385,
            gisGcj02Lng: 105.651,
            stubGroupCnt: null,
            pinYin: 'Zhenfeng',
          },
          {
            id: '522326',
            text: '望谟县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1714,
            gisBd09Lng: 106.099,
            gisGcj02Lat: 25.1651,
            gisGcj02Lng: 106.093,
            stubGroupCnt: null,
            pinYin: 'Wangmo',
          },
          {
            id: '522327',
            text: '册亨县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9889,
            gisBd09Lng: 105.817,
            gisGcj02Lat: 24.9829,
            gisGcj02Lng: 105.81,
            stubGroupCnt: null,
            pinYin: 'Ceheng',
          },
          {
            id: '522328',
            text: '安龙县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.105,
            gisBd09Lng: 105.452,
            gisGcj02Lat: 25.0991,
            gisGcj02Lng: 105.445,
            stubGroupCnt: null,
            pinYin: 'Anlong',
          },
        ],
        gisBd09Lng: 104.911,
        text: '黔西南布依族苗族自治州',
        gisBd09Lat: 25.096,
        gisGcj02Lat: 25.0899,
      },
      {
        pinYin: null,
        id: '522400',
        gisGcj02Lng: 105.295,
        children: [
          {
            id: '522401',
            text: '毕节',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2911,
            gisBd09Lng: 105.302,
            gisGcj02Lat: 27.2854,
            gisGcj02Lng: 105.295,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522422',
            text: '大方县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1461,
            gisBd09Lng: 105.62,
            gisGcj02Lat: 27.1398,
            gisGcj02Lng: 105.614,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522423',
            text: '黔西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0146,
            gisBd09Lng: 106.041,
            gisGcj02Lat: 27.0089,
            gisGcj02Lng: 106.034,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522424',
            text: '金沙县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4651,
            gisBd09Lng: 106.228,
            gisGcj02Lat: 27.459,
            gisGcj02Lng: 106.221,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522425',
            text: '织金县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6691,
            gisBd09Lng: 105.778,
            gisGcj02Lat: 26.6634,
            gisGcj02Lng: 105.771,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522426',
            text: '纳雍县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7835,
            gisBd09Lng: 105.392,
            gisGcj02Lat: 26.7774,
            gisGcj02Lng: 105.385,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522427',
            text: '威宁彝族回族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8627,
            gisBd09Lng: 104.292,
            gisGcj02Lat: 26.8564,
            gisGcj02Lng: 104.285,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '522428',
            text: '赫章县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.128,
            gisBd09Lng: 104.735,
            gisGcj02Lat: 27.122,
            gisGcj02Lng: 104.729,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 105.302,
        text: '毕节地区',
        gisBd09Lat: 27.2911,
        gisGcj02Lat: 27.2854,
      },
      {
        pinYin: 'Qiandongnan',
        id: '522600',
        gisGcj02Lng: 107.983,
        children: [
          {
            id: '522601',
            text: '凯里',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5735,
            gisBd09Lng: 107.99,
            gisGcj02Lat: 26.5673,
            gisGcj02Lng: 107.983,
            stubGroupCnt: null,
            pinYin: 'Kaili',
          },
          {
            id: '522622',
            text: '黄平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9093,
            gisBd09Lng: 107.922,
            gisGcj02Lat: 26.9034,
            gisGcj02Lng: 107.916,
            stubGroupCnt: null,
            pinYin: 'Huangping',
          },
          {
            id: '522623',
            text: '施秉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0406,
            gisBd09Lng: 108.133,
            gisGcj02Lat: 27.0343,
            gisGcj02Lng: 108.127,
            stubGroupCnt: null,
            pinYin: 'Shibing',
          },
          {
            id: '522624',
            text: '三穗县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.961,
            gisBd09Lng: 108.682,
            gisGcj02Lat: 26.9552,
            gisGcj02Lng: 108.675,
            stubGroupCnt: null,
            pinYin: 'Sansui',
          },
          {
            id: '522625',
            text: '镇远县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0552,
            gisBd09Lng: 108.438,
            gisGcj02Lat: 27.0495,
            gisGcj02Lng: 108.431,
            stubGroupCnt: null,
            pinYin: 'Zhenyuan',
          },
          {
            id: '522626',
            text: '岑巩县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1796,
            gisBd09Lng: 108.823,
            gisGcj02Lat: 27.1735,
            gisGcj02Lng: 108.817,
            stubGroupCnt: null,
            pinYin: 'Cengong',
          },
          {
            id: '522627',
            text: '天柱县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9159,
            gisBd09Lng: 109.216,
            gisGcj02Lat: 26.9096,
            gisGcj02Lng: 109.209,
            stubGroupCnt: null,
            pinYin: 'Tianzhu',
          },
          {
            id: '522628',
            text: '锦屏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6803,
            gisBd09Lng: 109.201,
            gisGcj02Lat: 26.674,
            gisGcj02Lng: 109.194,
            stubGroupCnt: null,
            pinYin: 'Jinping',
          },
          {
            id: '522629',
            text: '剑河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7346,
            gisBd09Lng: 108.449,
            gisGcj02Lat: 26.7288,
            gisGcj02Lng: 108.442,
            stubGroupCnt: null,
            pinYin: 'Jianhe',
          },
          {
            id: '522630',
            text: '台江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6748,
            gisBd09Lng: 108.326,
            gisGcj02Lat: 26.6689,
            gisGcj02Lng: 108.32,
            stubGroupCnt: null,
            pinYin: 'Taijiang',
          },
          {
            id: '522631',
            text: '黎平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 25.9321,
            gisGcj02Lng: 108.523,
            stubGroupCnt: null,
            pinYin: 'Liping',
          },
          {
            id: '522632',
            text: '榕江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9379,
            gisBd09Lng: 108.53,
            gisGcj02Lat: 25.9321,
            gisGcj02Lng: 108.523,
            stubGroupCnt: null,
            pinYin: 'Rongjiang',
          },
          {
            id: '522633',
            text: '从江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7617,
            gisBd09Lng: 108.907,
            gisGcj02Lat: 25.756,
            gisGcj02Lng: 108.901,
            stubGroupCnt: null,
            pinYin: 'Congjiang',
          },
          {
            id: '522634',
            text: '雷山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3852,
            gisBd09Lng: 108.087,
            gisGcj02Lat: 26.3793,
            gisGcj02Lng: 108.08,
            stubGroupCnt: null,
            pinYin: 'Leishan',
          },
          {
            id: '522635',
            text: '麻江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4969,
            gisBd09Lng: 107.596,
            gisGcj02Lat: 26.4912,
            gisGcj02Lng: 107.59,
            stubGroupCnt: null,
            pinYin: 'Majiang',
          },
          {
            id: '522636',
            text: '丹寨县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2046,
            gisBd09Lng: 107.796,
            gisGcj02Lat: 26.1986,
            gisGcj02Lng: 107.79,
            stubGroupCnt: null,
            pinYin: 'Danzhai',
          },
        ],
        gisBd09Lng: 107.99,
        text: '黔东南苗族侗族自治州',
        gisBd09Lat: 26.5903,
        gisGcj02Lat: 26.5841,
      },
      {
        pinYin: 'Qiannan',
        id: '522700',
        gisGcj02Lng: 107.523,
        children: [
          {
            id: '522701',
            text: '都匀',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2665,
            gisBd09Lng: 107.522,
            gisGcj02Lat: 26.2602,
            gisGcj02Lng: 107.516,
            stubGroupCnt: null,
            pinYin: 'Duyun',
          },
          {
            id: '522702',
            text: '福泉',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6929,
            gisBd09Lng: 107.528,
            gisGcj02Lat: 26.6865,
            gisGcj02Lng: 107.521,
            stubGroupCnt: null,
            pinYin: 'Fuquan',
          },
          {
            id: '522722',
            text: '荔波县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 26.5771,
            gisGcj02Lng: 107.238,
            stubGroupCnt: null,
            pinYin: 'Libo',
          },
          {
            id: '522723',
            text: '贵定县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5829,
            gisBd09Lng: 107.244,
            gisGcj02Lat: 26.5771,
            gisGcj02Lng: 107.238,
            stubGroupCnt: null,
            pinYin: 'Guiding',
          },
          {
            id: '522725',
            text: '瓮安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0842,
            gisBd09Lng: 107.477,
            gisGcj02Lat: 27.0785,
            gisGcj02Lng: 107.471,
            stubGroupCnt: null,
            pinYin: "Weng'an",
          },
          {
            id: '522726',
            text: '独山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8281,
            gisBd09Lng: 107.553,
            gisGcj02Lat: 25.8221,
            gisGcj02Lng: 107.546,
            stubGroupCnt: null,
            pinYin: 'Dushan',
          },
          {
            id: '522727',
            text: '平塘县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8284,
            gisBd09Lng: 107.33,
            gisGcj02Lat: 25.8226,
            gisGcj02Lng: 107.324,
            stubGroupCnt: null,
            pinYin: 'Pingtang',
          },
          {
            id: '522728',
            text: '罗甸县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4314,
            gisBd09Lng: 106.76,
            gisGcj02Lat: 25.4256,
            gisGcj02Lng: 106.753,
            stubGroupCnt: null,
            pinYin: 'Luodian',
          },
          {
            id: '522729',
            text: '长顺县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.03,
            gisBd09Lng: 106.457,
            gisGcj02Lat: 26.0237,
            gisGcj02Lng: 106.45,
            stubGroupCnt: null,
            pinYin: 'Changshun',
          },
          {
            id: '522730',
            text: '龙里县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4591,
            gisBd09Lng: 106.989,
            gisGcj02Lat: 26.4534,
            gisGcj02Lng: 106.982,
            stubGroupCnt: null,
            pinYin: 'Longli',
          },
          {
            id: '522731',
            text: '惠水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1389,
            gisBd09Lng: 106.664,
            gisGcj02Lat: 26.1328,
            gisGcj02Lng: 106.657,
            stubGroupCnt: null,
            pinYin: 'Huishui',
          },
          {
            id: '522732',
            text: '三都水族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9917,
            gisBd09Lng: 107.881,
            gisGcj02Lat: 25.9854,
            gisGcj02Lng: 107.875,
            stubGroupCnt: null,
            pinYin: 'Sandu',
          },
        ],
        gisBd09Lng: 107.529,
        text: '黔南布依族苗族自治州',
        gisBd09Lat: 26.2602,
        gisGcj02Lat: 26.2539,
      },
    ],
  },
  {
    text: '海南省',
    children: [
      {
        pinYin: 'Haikou',
        id: '460100',
        gisGcj02Lng: 110.202,
        children: [
          {
            id: '460101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0501,
            gisBd09Lng: 110.208,
            gisGcj02Lat: 20.0442,
            gisGcj02Lng: 110.202,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '460105',
            text: '秀英区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0136,
            gisBd09Lng: 110.299,
            gisGcj02Lat: 20.0073,
            gisGcj02Lng: 110.293,
            stubGroupCnt: null,
            pinYin: 'Xiuying',
          },
          {
            id: '460106',
            text: '龙华区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0364,
            gisBd09Lng: 110.337,
            gisGcj02Lat: 20.0307,
            gisGcj02Lng: 110.33,
            stubGroupCnt: null,
            pinYin: 'Longhua',
          },
          {
            id: '460107',
            text: '琼山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0091,
            gisBd09Lng: 110.359,
            gisGcj02Lat: 20.0034,
            gisGcj02Lng: 110.353,
            stubGroupCnt: null,
            pinYin: 'Qiongshan',
          },
          {
            id: '460108',
            text: '美兰区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0343,
            gisBd09Lng: 110.375,
            gisGcj02Lat: 20.0283,
            gisGcj02Lng: 110.368,
            stubGroupCnt: null,
            pinYin: 'Meilan',
          },
        ],
        gisBd09Lng: 110.208,
        text: '海口',
        gisBd09Lat: 20.0501,
        gisGcj02Lat: 20.0442,
      },
      {
        pinYin: 'Sanya',
        id: '460200',
        gisGcj02Lng: 109.512,
        children: [
          {
            id: '460201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 18.2579,
            gisBd09Lng: 109.518,
            gisGcj02Lat: 18.2522,
            gisGcj02Lng: 109.512,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '460202',
            text: '海棠区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 18.2522,
            gisGcj02Lng: 109.512,
            stubGroupCnt: null,
            pinYin: 'Haitang',
          },
          {
            id: '460203',
            text: '吉阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 18.2522,
            gisGcj02Lng: 109.512,
            stubGroupCnt: null,
            pinYin: 'Jiyang',
          },
          {
            id: '460204',
            text: '天涯区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 18.2522,
            gisGcj02Lng: 109.512,
            stubGroupCnt: null,
            pinYin: 'Tianya',
          },
          {
            id: '460205',
            text: '崖州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 18.2522,
            gisGcj02Lng: 109.512,
            stubGroupCnt: null,
            pinYin: 'Yazhou',
          },
        ],
        gisBd09Lng: 109.518,
        text: '三亚',
        gisBd09Lat: 18.2579,
        gisGcj02Lat: 18.2522,
      },
      {
        pinYin: '',
        id: '469000',
        gisGcj02Lng: 114.364,
        children: [
          {
            id: '469001',
            text: '五指山',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 18.7821,
            gisBd09Lng: 109.527,
            gisGcj02Lat: 18.7763,
            gisGcj02Lng: 109.521,
            stubGroupCnt: null,
            pinYin: 'Wuzhishan',
          },
          {
            id: '469002',
            text: '琼海',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.2148,
            gisBd09Lng: 110.414,
            gisGcj02Lat: 19.2085,
            gisGcj02Lng: 110.408,
            stubGroupCnt: null,
            pinYin: 'Qionghai',
          },
          {
            id: '469003',
            text: '儋州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.527,
            gisBd09Lng: 109.588,
            gisGcj02Lat: 19.5208,
            gisGcj02Lng: 109.581,
            stubGroupCnt: null,
            pinYin: 'Danzhou',
          },
          {
            id: '469005',
            text: '文昌',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.5503,
            gisBd09Lng: 110.804,
            gisGcj02Lat: 19.5444,
            gisGcj02Lng: 110.798,
            stubGroupCnt: null,
            pinYin: 'Wenchang',
          },
          {
            id: '469006',
            text: '万宁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 20.0153,
            gisBd09Lng: 110.346,
            gisGcj02Lat: 20.0097,
            gisGcj02Lng: 110.339,
            stubGroupCnt: null,
            pinYin: 'Wanning',
          },
          {
            id: '469007',
            text: '东方',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.1176,
            gisBd09Lng: 108.671,
            gisGcj02Lat: 19.1119,
            gisGcj02Lng: 108.665,
            stubGroupCnt: null,
            pinYin: 'Dongfang',
          },
          {
            id: '469025',
            text: '定安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.6866,
            gisBd09Lng: 110.366,
            gisGcj02Lat: 19.6808,
            gisGcj02Lng: 110.359,
            stubGroupCnt: null,
            pinYin: 'Baisha',
          },
          {
            id: '469026',
            text: '屯昌县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.3576,
            gisBd09Lng: 110.112,
            gisGcj02Lat: 19.3519,
            gisGcj02Lng: 110.106,
            stubGroupCnt: null,
            pinYin: 'Changjiang',
          },
          {
            id: '469027',
            text: '澄迈县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.7443,
            gisBd09Lng: 110.012,
            gisGcj02Lat: 19.7384,
            gisGcj02Lng: 110.005,
            stubGroupCnt: null,
            pinYin: 'Ledong',
          },
          {
            id: '469028',
            text: '临高县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.9187,
            gisBd09Lng: 109.698,
            gisGcj02Lat: 19.9124,
            gisGcj02Lng: 109.691,
            stubGroupCnt: null,
            pinYin: 'Lingshui',
          },
          {
            id: '469030',
            text: '白沙黎族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.2318,
            gisBd09Lng: 109.459,
            gisGcj02Lat: 19.2255,
            gisGcj02Lng: 109.452,
            stubGroupCnt: null,
            pinYin: 'Qiongzhong',
          },
          {
            id: '469031',
            text: '昌江黎族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.3044,
            gisBd09Lng: 109.062,
            gisGcj02Lat: 19.2983,
            gisGcj02Lng: 109.056,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469033',
            text: '乐东黎族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 18.7558,
            gisBd09Lng: 109.181,
            gisGcj02Lat: 18.7497,
            gisGcj02Lng: 109.174,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469034',
            text: '陵水黎族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 18.5104,
            gisBd09Lng: 110.046,
            gisGcj02Lat: 18.5041,
            gisGcj02Lng: 110.039,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469035',
            text: '保亭黎族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 18.6448,
            gisBd09Lng: 109.71,
            gisGcj02Lat: 18.6387,
            gisGcj02Lng: 109.703,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469036',
            text: '琼中黎族苗族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 19.0392,
            gisBd09Lng: 109.846,
            gisGcj02Lat: 19.0334,
            gisGcj02Lng: 109.839,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469037',
            text: '西沙群岛',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 16.3736,
            gisBd09Lng: 111.71,
            gisGcj02Lat: 16.3674,
            gisGcj02Lng: 111.703,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469038',
            text: '南沙群岛',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 10.6522,
            gisBd09Lng: 114.851,
            gisGcj02Lat: 10.6458,
            gisGcj02Lng: 114.844,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '469039',
            text: '中沙群岛的岛礁及其海域',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 15.8719,
            gisBd09Lng: 114.37,
            gisGcj02Lat: 15.8656,
            gisGcj02Lng: 114.364,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: null,
        text: '省直辖县级行政单位',
        gisBd09Lat: null,
        gisGcj02Lat: 15.8656,
      },
    ],
  },
  {
    text: '河北省',
    children: [
      {
        pinYin: 'Shijiazhuang',
        id: '130100',
        gisGcj02Lng: 114.517,
        children: [
          {
            id: '130101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2675,
            gisBd09Lng: 114.193,
            gisGcj02Lat: 38.2618,
            gisGcj02Lng: 114.186,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130102',
            text: '长安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1625,
            gisBd09Lng: 108.918,
            gisGcj02Lat: 34.1568,
            gisGcj02Lng: 108.911,
            stubGroupCnt: null,
            pinYin: "Chang'an",
          },
          {
            id: '130103',
            text: '桥东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0643,
            gisBd09Lng: 114.511,
            gisGcj02Lat: 38.0582,
            gisGcj02Lng: 114.505,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130104',
            text: '桥西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.008,
            gisBd09Lng: 114.467,
            gisGcj02Lat: 38.0018,
            gisGcj02Lng: 114.461,
            stubGroupCnt: null,
            pinYin: 'Qiaoxi',
          },
          {
            id: '130105',
            text: '新华区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0589,
            gisBd09Lng: 114.47,
            gisGcj02Lat: 38.0527,
            gisGcj02Lng: 114.463,
            stubGroupCnt: null,
            pinYin: 'Xinhua',
          },
          {
            id: '130107',
            text: '井陉矿区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0707,
            gisBd09Lng: 114.075,
            gisGcj02Lat: 38.065,
            gisGcj02Lng: 114.068,
            stubGroupCnt: null,
            pinYin: 'Jingxingkuangqu',
          },
          {
            id: '130108',
            text: '裕华区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0136,
            gisBd09Lng: 114.542,
            gisGcj02Lat: 38.0079,
            gisGcj02Lng: 114.536,
            stubGroupCnt: null,
            pinYin: 'Yuhua',
          },
          {
            id: '130121',
            text: '井陉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0383,
            gisBd09Lng: 114.158,
            gisGcj02Lat: 38.0324,
            gisGcj02Lng: 114.151,
            stubGroupCnt: null,
            pinYin: 'Jingxing',
          },
          {
            id: '130123',
            text: '正定县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.154,
            gisBd09Lng: 114.581,
            gisGcj02Lat: 38.1479,
            gisGcj02Lng: 114.574,
            stubGroupCnt: null,
            pinYin: 'Zhengding',
          },
          {
            id: '130124',
            text: '栾城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9062,
            gisBd09Lng: 114.64,
            gisGcj02Lat: 37.9002,
            gisGcj02Lng: 114.633,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130125',
            text: '行唐县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4469,
            gisBd09Lng: 114.561,
            gisGcj02Lat: 38.4412,
            gisGcj02Lng: 114.554,
            stubGroupCnt: null,
            pinYin: 'Xingtang',
          },
          {
            id: '130126',
            text: '灵寿县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3139,
            gisBd09Lng: 114.39,
            gisGcj02Lat: 38.3078,
            gisGcj02Lng: 114.384,
            stubGroupCnt: null,
            pinYin: 'Lingshou',
          },
          {
            id: '130127',
            text: '高邑县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6203,
            gisBd09Lng: 114.618,
            gisGcj02Lat: 37.614,
            gisGcj02Lng: 114.611,
            stubGroupCnt: null,
            pinYin: 'Gaoyi',
          },
          {
            id: '130128',
            text: '深泽县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1905,
            gisBd09Lng: 115.212,
            gisGcj02Lat: 38.1842,
            gisGcj02Lng: 115.206,
            stubGroupCnt: null,
            pinYin: 'Shenze',
          },
          {
            id: '130129',
            text: '赞皇县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6719,
            gisBd09Lng: 114.396,
            gisGcj02Lat: 37.6659,
            gisGcj02Lng: 114.389,
            stubGroupCnt: null,
            pinYin: 'Zanhuang',
          },
          {
            id: '130130',
            text: '无极县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1845,
            gisBd09Lng: 114.983,
            gisGcj02Lat: 38.1782,
            gisGcj02Lng: 114.976,
            stubGroupCnt: null,
            pinYin: 'Wuji',
          },
          {
            id: '130131',
            text: '平山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2684,
            gisBd09Lng: 114.193,
            gisGcj02Lat: 38.2627,
            gisGcj02Lng: 114.186,
            stubGroupCnt: null,
            pinYin: 'Pingshan',
          },
          {
            id: '130132',
            text: '元氏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7722,
            gisBd09Lng: 114.533,
            gisGcj02Lat: 37.7665,
            gisGcj02Lng: 114.527,
            stubGroupCnt: null,
            pinYin: 'Yuanshi',
          },
          {
            id: '130133',
            text: '赵　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7638,
            gisBd09Lng: 114.782,
            gisGcj02Lat: 37.7581,
            gisGcj02Lng: 114.775,
            stubGroupCnt: null,
            pinYin: 'Zhaoxian',
          },
          {
            id: '130181',
            text: '辛集',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.924,
            gisBd09Lng: 115.299,
            gisGcj02Lat: 37.918,
            gisGcj02Lng: 115.292,
            stubGroupCnt: null,
            pinYin: 'Xinji',
          },
          {
            id: '130182',
            text: '藁城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0249,
            gisBd09Lng: 114.857,
            gisGcj02Lat: 38.0186,
            gisGcj02Lng: 114.85,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130183',
            text: '晋州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0395,
            gisBd09Lng: 115.053,
            gisGcj02Lat: 38.0335,
            gisGcj02Lng: 115.046,
            stubGroupCnt: null,
            pinYin: 'Jinzhou',
          },
          {
            id: '130184',
            text: '新乐',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3456,
            gisBd09Lng: 114.69,
            gisGcj02Lat: 38.3398,
            gisGcj02Lng: 114.684,
            stubGroupCnt: null,
            pinYin: 'Xinle',
          },
          {
            id: '130185',
            text: '鹿泉',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.092,
            gisBd09Lng: 114.328,
            gisGcj02Lat: 38.0861,
            gisGcj02Lng: 114.321,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 114.524,
        text: '石家庄',
        gisBd09Lat: 38.0441,
        gisGcj02Lat: 38.0382,
      },
      {
        pinYin: 'Tangshan',
        id: '130200',
        gisGcj02Lng: 118.181,
        children: [
          {
            id: '130201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6345,
            gisBd09Lng: 118.189,
            gisGcj02Lat: 39.6282,
            gisGcj02Lng: 118.183,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130202',
            text: '路南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6328,
            gisBd09Lng: 118.161,
            gisGcj02Lat: 39.6271,
            gisGcj02Lng: 118.154,
            stubGroupCnt: null,
            pinYin: 'Lunan',
          },
          {
            id: '130203',
            text: '路北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6311,
            gisBd09Lng: 118.207,
            gisGcj02Lat: 39.6247,
            gisGcj02Lng: 118.201,
            stubGroupCnt: null,
            pinYin: 'Lubei',
          },
          {
            id: '130204',
            text: '古冶区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7219,
            gisBd09Lng: 118.466,
            gisGcj02Lat: 39.7157,
            gisGcj02Lng: 118.459,
            stubGroupCnt: null,
            pinYin: 'Guye',
          },
          {
            id: '130205',
            text: '开平区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6779,
            gisBd09Lng: 118.268,
            gisGcj02Lat: 39.6722,
            gisGcj02Lng: 118.261,
            stubGroupCnt: null,
            pinYin: 'Kaiping',
          },
          {
            id: '130207',
            text: '丰南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5816,
            gisBd09Lng: 118.092,
            gisGcj02Lat: 39.5753,
            gisGcj02Lng: 118.086,
            stubGroupCnt: null,
            pinYin: 'Fengnan',
          },
          {
            id: '130208',
            text: '丰润区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8385,
            gisBd09Lng: 118.169,
            gisGcj02Lat: 39.8327,
            gisGcj02Lng: 118.163,
            stubGroupCnt: null,
            pinYin: 'Fengrun',
          },
          {
            id: '130223',
            text: '滦　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3643,
            gisBd09Lng: 118.316,
            gisGcj02Lat: 40.358,
            gisGcj02Lng: 118.31,
            stubGroupCnt: null,
            pinYin: 'Luanxian',
          },
          {
            id: '130224',
            text: '滦南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5103,
            gisBd09Lng: 118.681,
            gisGcj02Lat: 39.5039,
            gisGcj02Lng: 118.674,
            stubGroupCnt: null,
            pinYin: 'Luannan',
          },
          {
            id: '130225',
            text: '乐亭县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4324,
            gisBd09Lng: 118.919,
            gisGcj02Lat: 39.4261,
            gisGcj02Lng: 118.913,
            stubGroupCnt: null,
            pinYin: 'Laoting',
          },
          {
            id: '130227',
            text: '迁西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1492,
            gisBd09Lng: 118.321,
            gisGcj02Lat: 40.1429,
            gisGcj02Lng: 118.315,
            stubGroupCnt: null,
            pinYin: 'Qianxi',
          },
          {
            id: '130229',
            text: '玉田县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9045,
            gisBd09Lng: 117.746,
            gisGcj02Lat: 39.8983,
            gisGcj02Lng: 117.739,
            stubGroupCnt: null,
            pinYin: 'Yutian',
          },
          {
            id: '130230',
            text: '唐海县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2752,
            gisBd09Lng: 118.427,
            gisGcj02Lat: 39.269,
            gisGcj02Lng: 118.42,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130281',
            text: '遵化',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2752,
            gisBd09Lng: 118.427,
            gisGcj02Lat: 39.269,
            gisGcj02Lng: 118.42,
            stubGroupCnt: null,
            pinYin: 'Zunhua',
          },
          {
            id: '130283',
            text: '迁安',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.005,
            gisBd09Lng: 118.708,
            gisGcj02Lat: 39.9988,
            gisGcj02Lng: 118.701,
            stubGroupCnt: null,
            pinYin: "Qian'an",
          },
        ],
        gisBd09Lng: 118.187,
        text: '唐山',
        gisBd09Lat: 39.6371,
        gisGcj02Lat: 39.6309,
      },
      {
        pinYin: 'Qinhuangdao',
        id: '130300',
        gisGcj02Lng: 119.598,
        children: [
          {
            id: '130301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9404,
            gisBd09Lng: 119.605,
            gisGcj02Lat: 39.9346,
            gisGcj02Lng: 119.598,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130302',
            text: '海港区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9405,
            gisBd09Lng: 119.617,
            gisGcj02Lat: 39.9345,
            gisGcj02Lng: 119.611,
            stubGroupCnt: null,
            pinYin: 'Haigang',
          },
          {
            id: '130303',
            text: '山海关区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9834,
            gisBd09Lng: 119.784,
            gisGcj02Lat: 39.9772,
            gisGcj02Lng: 119.777,
            stubGroupCnt: null,
            pinYin: 'Shanhaiguan',
          },
          {
            id: '130304',
            text: '北戴河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.841,
            gisBd09Lng: 119.496,
            gisGcj02Lat: 39.835,
            gisGcj02Lng: 119.489,
            stubGroupCnt: null,
            pinYin: 'Beidaihe',
          },
          {
            id: '130321',
            text: '青龙满族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4126,
            gisBd09Lng: 118.958,
            gisGcj02Lat: 40.4066,
            gisGcj02Lng: 118.951,
            stubGroupCnt: null,
            pinYin: 'Qinglong',
          },
          {
            id: '130322',
            text: '昌黎县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7192,
            gisBd09Lng: 119.168,
            gisGcj02Lat: 39.7128,
            gisGcj02Lng: 119.162,
            stubGroupCnt: null,
            pinYin: 'Changli',
          },
          {
            id: '130323',
            text: '抚宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.884,
            gisBd09Lng: 119.251,
            gisGcj02Lat: 39.8781,
            gisGcj02Lng: 119.244,
            stubGroupCnt: null,
            pinYin: 'Funing',
          },
          {
            id: '130324',
            text: '卢龙县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8987,
            gisBd09Lng: 118.898,
            gisGcj02Lat: 39.8927,
            gisGcj02Lng: 118.892,
            stubGroupCnt: null,
            pinYin: 'Lulong',
          },
        ],
        gisBd09Lng: 119.605,
        text: '秦皇岛',
        gisBd09Lat: 39.9404,
        gisGcj02Lat: 39.9346,
      },
      {
        pinYin: 'Handan',
        id: '130400',
        gisGcj02Lng: 114.54,
        children: [
          {
            id: '130401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6321,
            gisBd09Lng: 114.546,
            gisGcj02Lat: 36.6265,
            gisGcj02Lng: 114.54,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130402',
            text: '邯山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6073,
            gisBd09Lng: 114.491,
            gisGcj02Lat: 36.6009,
            gisGcj02Lng: 114.485,
            stubGroupCnt: null,
            pinYin: 'Hanshan',
          },
          {
            id: '130403',
            text: '丛台区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6234,
            gisBd09Lng: 114.501,
            gisGcj02Lat: 36.6172,
            gisGcj02Lng: 114.495,
            stubGroupCnt: null,
            pinYin: 'Congtai',
          },
          {
            id: '130404',
            text: '复兴区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6246,
            gisBd09Lng: 114.48,
            gisGcj02Lat: 36.6183,
            gisGcj02Lng: 114.474,
            stubGroupCnt: null,
            pinYin: 'Fuxing',
          },
          {
            id: '130406',
            text: '峰峰矿区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4262,
            gisBd09Lng: 114.218,
            gisGcj02Lat: 36.4202,
            gisGcj02Lng: 114.212,
            stubGroupCnt: null,
            pinYin: 'Fengfengkuangqu',
          },
          {
            id: '130421',
            text: '邯郸县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5991,
            gisBd09Lng: 114.538,
            gisGcj02Lat: 36.5934,
            gisGcj02Lng: 114.531,
            stubGroupCnt: null,
            pinYin: 'Handan',
          },
          {
            id: '130423',
            text: '临漳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3394,
            gisBd09Lng: 114.628,
            gisGcj02Lat: 36.3333,
            gisGcj02Lng: 114.621,
            stubGroupCnt: null,
            pinYin: 'Linzhang',
          },
          {
            id: '130424',
            text: '成安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4503,
            gisBd09Lng: 114.679,
            gisGcj02Lat: 36.4446,
            gisGcj02Lng: 114.672,
            stubGroupCnt: null,
            pinYin: "Cheng'an",
          },
          {
            id: '130425',
            text: '大名县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2927,
            gisBd09Lng: 115.154,
            gisGcj02Lat: 36.287,
            gisGcj02Lng: 115.148,
            stubGroupCnt: null,
            pinYin: 'Daming',
          },
          {
            id: '130426',
            text: '涉　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7843,
            gisBd09Lng: 114.956,
            gisGcj02Lat: 36.778,
            gisGcj02Lng: 114.95,
            stubGroupCnt: null,
            pinYin: 'Shexian',
          },
          {
            id: '130427',
            text: '磁　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3882,
            gisBd09Lng: 114.396,
            gisGcj02Lat: 36.3822,
            gisGcj02Lng: 114.389,
            stubGroupCnt: null,
            pinYin: 'Cixian',
          },
          {
            id: '130428',
            text: '肥乡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5553,
            gisBd09Lng: 114.807,
            gisGcj02Lat: 36.5495,
            gisGcj02Lng: 114.801,
            stubGroupCnt: null,
            pinYin: 'Feixiang',
          },
          {
            id: '130429',
            text: '永年县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.784,
            gisBd09Lng: 114.499,
            gisGcj02Lat: 36.7777,
            gisGcj02Lng: 114.493,
            stubGroupCnt: null,
            pinYin: 'Yongnian',
          },
          {
            id: '130430',
            text: '邱　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2967,
            gisBd09Lng: 114.596,
            gisGcj02Lat: 36.2904,
            gisGcj02Lng: 114.59,
            stubGroupCnt: null,
            pinYin: 'Qiuxian',
          },
          {
            id: '130431',
            text: '鸡泽县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9257,
            gisBd09Lng: 114.884,
            gisGcj02Lat: 36.9198,
            gisGcj02Lng: 114.878,
            stubGroupCnt: null,
            pinYin: 'Jize',
          },
          {
            id: '130432',
            text: '广平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4888,
            gisBd09Lng: 114.957,
            gisGcj02Lat: 36.4825,
            gisGcj02Lng: 114.95,
            stubGroupCnt: null,
            pinYin: 'Guangping',
          },
          {
            id: '130433',
            text: '馆陶县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5416,
            gisBd09Lng: 115.313,
            gisGcj02Lat: 36.5353,
            gisGcj02Lng: 115.307,
            stubGroupCnt: null,
            pinYin: 'Guantao',
          },
          {
            id: '130434',
            text: '魏　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3569,
            gisBd09Lng: 114.943,
            gisGcj02Lat: 36.3508,
            gisGcj02Lng: 114.937,
            stubGroupCnt: null,
            pinYin: 'Weixian',
          },
          {
            id: '130435',
            text: '曲周县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7836,
            gisBd09Lng: 114.952,
            gisGcj02Lat: 36.7773,
            gisGcj02Lng: 114.946,
            stubGroupCnt: null,
            pinYin: 'Quzhou',
          },
          {
            id: '130481',
            text: '武安',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7031,
            gisBd09Lng: 114.211,
            gisGcj02Lat: 36.6972,
            gisGcj02Lng: 114.205,
            stubGroupCnt: null,
            pinYin: "Wu'an",
          },
        ],
        gisBd09Lng: 114.546,
        text: '邯郸',
        gisBd09Lat: 36.6321,
        gisGcj02Lat: 36.6265,
      },
      {
        pinYin: 'Xingtai',
        id: '130500',
        gisGcj02Lng: 114.504,
        children: [
          {
            id: '130501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0766,
            gisBd09Lng: 114.511,
            gisGcj02Lat: 37.0706,
            gisGcj02Lng: 114.505,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130502',
            text: '桥东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0748,
            gisBd09Lng: 114.515,
            gisGcj02Lat: 37.0688,
            gisGcj02Lng: 114.508,
            stubGroupCnt: null,
            pinYin: 'Qiaodong',
          },
          {
            id: '130503',
            text: '桥西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0659,
            gisBd09Lng: 114.475,
            gisGcj02Lat: 37.0596,
            gisGcj02Lng: 114.469,
            stubGroupCnt: null,
            pinYin: 'Qiaoxi',
          },
          {
            id: '130521',
            text: '邢台县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0924,
            gisBd09Lng: 114.552,
            gisGcj02Lat: 37.0867,
            gisGcj02Lng: 114.545,
            stubGroupCnt: null,
            pinYin: 'Xingtai',
          },
          {
            id: '130522',
            text: '临城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4491,
            gisBd09Lng: 114.507,
            gisGcj02Lat: 37.4429,
            gisGcj02Lng: 114.5,
            stubGroupCnt: null,
            pinYin: 'Lincheng',
          },
          {
            id: '130523',
            text: '内丘县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.2923,
            gisBd09Lng: 114.52,
            gisGcj02Lat: 37.2864,
            gisGcj02Lng: 114.513,
            stubGroupCnt: null,
            pinYin: 'Neiqiu',
          },
          {
            id: '130524',
            text: '柏乡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4885,
            gisBd09Lng: 114.7,
            gisGcj02Lat: 37.4824,
            gisGcj02Lng: 114.694,
            stubGroupCnt: null,
            pinYin: 'Baixiang',
          },
          {
            id: '130525',
            text: '隆尧县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3569,
            gisBd09Lng: 114.778,
            gisGcj02Lat: 37.3512,
            gisGcj02Lng: 114.771,
            stubGroupCnt: null,
            pinYin: 'Longyao',
          },
          {
            id: '130526',
            text: '任　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1257,
            gisBd09Lng: 114.679,
            gisGcj02Lat: 37.12,
            gisGcj02Lng: 114.672,
            stubGroupCnt: null,
            pinYin: 'Renxian',
          },
          {
            id: '130527',
            text: '南和县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1257,
            gisBd09Lng: 114.679,
            gisGcj02Lat: 37.12,
            gisGcj02Lng: 114.672,
            stubGroupCnt: null,
            pinYin: 'Nanhe',
          },
          {
            id: '130528',
            text: '宁晋县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6261,
            gisBd09Lng: 114.927,
            gisGcj02Lat: 37.6202,
            gisGcj02Lng: 114.92,
            stubGroupCnt: null,
            pinYin: 'Ningjin',
          },
          {
            id: '130529',
            text: '巨鹿县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6261,
            gisBd09Lng: 114.927,
            gisGcj02Lat: 37.6202,
            gisGcj02Lng: 114.92,
            stubGroupCnt: null,
            pinYin: 'Julu',
          },
          {
            id: '130530',
            text: '新河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5335,
            gisBd09Lng: 115.25,
            gisGcj02Lat: 37.5278,
            gisGcj02Lng: 115.244,
            stubGroupCnt: null,
            pinYin: 'Xinhe',
          },
          {
            id: '130531',
            text: '广宗县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0803,
            gisBd09Lng: 115.149,
            gisGcj02Lat: 37.0746,
            gisGcj02Lng: 115.142,
            stubGroupCnt: null,
            pinYin: 'Guangzong',
          },
          {
            id: '130532',
            text: '平乡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0689,
            gisBd09Lng: 115.038,
            gisGcj02Lat: 37.0632,
            gisGcj02Lng: 115.031,
            stubGroupCnt: null,
            pinYin: 'Pingxiang',
          },
          {
            id: '130533',
            text: '威　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9825,
            gisBd09Lng: 115.273,
            gisGcj02Lat: 36.9768,
            gisGcj02Lng: 115.267,
            stubGroupCnt: null,
            pinYin: 'Weixian',
          },
          {
            id: '130534',
            text: '清河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0453,
            gisBd09Lng: 115.674,
            gisGcj02Lat: 37.039,
            gisGcj02Lng: 115.668,
            stubGroupCnt: null,
            pinYin: 'Qinghe',
          },
          {
            id: '130535',
            text: '临西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0453,
            gisBd09Lng: 115.674,
            gisGcj02Lat: 37.039,
            gisGcj02Lng: 115.668,
            stubGroupCnt: null,
            pinYin: 'Linxi',
          },
          {
            id: '130581',
            text: '南宫',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3644,
            gisBd09Lng: 115.417,
            gisGcj02Lat: 37.3584,
            gisGcj02Lng: 115.41,
            stubGroupCnt: null,
            pinYin: 'Nangong',
          },
          {
            id: '130582',
            text: '沙河',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8631,
            gisBd09Lng: 114.51,
            gisGcj02Lat: 36.857,
            gisGcj02Lng: 114.503,
            stubGroupCnt: null,
            pinYin: 'Shahe',
          },
        ],
        gisBd09Lng: 114.51,
        text: '邢台',
        gisBd09Lat: 37.0757,
        gisGcj02Lat: 37.0696,
      },
      {
        pinYin: 'Baoding',
        id: '130600',
        gisGcj02Lng: 115.465,
        children: [
          {
            id: '130601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8814,
            gisBd09Lng: 115.472,
            gisGcj02Lat: 38.8753,
            gisGcj02Lng: 115.465,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130602',
            text: '新市区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8851,
            gisBd09Lng: 115.465,
            gisGcj02Lat: 38.8789,
            gisGcj02Lng: 115.459,
            stubGroupCnt: null,
            pinYin: 'Xinshi',
          },
          {
            id: '130603',
            text: '北市区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8882,
            gisBd09Lng: 115.503,
            gisGcj02Lat: 38.8826,
            gisGcj02Lng: 115.496,
            stubGroupCnt: null,
            pinYin: 'Beishi',
          },
          {
            id: '130604',
            text: '南市区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.861,
            gisBd09Lng: 115.535,
            gisGcj02Lat: 38.8551,
            gisGcj02Lng: 115.528,
            stubGroupCnt: null,
            pinYin: 'Nanshi',
          },
          {
            id: '130621',
            text: '满城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9571,
            gisBd09Lng: 115.329,
            gisGcj02Lat: 38.9507,
            gisGcj02Lng: 115.322,
            stubGroupCnt: null,
            pinYin: 'Mancheng',
          },
          {
            id: '130622',
            text: '清苑县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7692,
            gisBd09Lng: 115.498,
            gisGcj02Lat: 38.7635,
            gisGcj02Lng: 115.491,
            stubGroupCnt: null,
            pinYin: 'Qingyuan',
          },
          {
            id: '130623',
            text: '涞水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4002,
            gisBd09Lng: 115.72,
            gisGcj02Lat: 39.3943,
            gisGcj02Lng: 115.713,
            stubGroupCnt: null,
            pinYin: 'Laishui',
          },
          {
            id: '130624',
            text: '阜平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8536,
            gisBd09Lng: 114.202,
            gisGcj02Lat: 38.8478,
            gisGcj02Lng: 114.196,
            stubGroupCnt: null,
            pinYin: 'Fuping',
          },
          {
            id: '130625',
            text: '徐水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0225,
            gisBd09Lng: 115.663,
            gisGcj02Lat: 39.0164,
            gisGcj02Lng: 115.656,
            stubGroupCnt: null,
            pinYin: 'Xushui',
          },
          {
            id: '130626',
            text: '定兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.269,
            gisBd09Lng: 115.816,
            gisGcj02Lat: 39.2627,
            gisGcj02Lng: 115.81,
            stubGroupCnt: null,
            pinYin: 'Dingxing',
          },
          {
            id: '130627',
            text: '唐　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7518,
            gisBd09Lng: 114.99,
            gisGcj02Lat: 38.7457,
            gisGcj02Lng: 114.984,
            stubGroupCnt: null,
            pinYin: 'Tangxian',
          },
          {
            id: '130628',
            text: '高阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7071,
            gisBd09Lng: 115.784,
            gisGcj02Lat: 38.701,
            gisGcj02Lng: 115.778,
            stubGroupCnt: null,
            pinYin: 'Gaoyang',
          },
          {
            id: '130629',
            text: '容城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0499,
            gisBd09Lng: 115.868,
            gisGcj02Lat: 39.0442,
            gisGcj02Lng: 115.861,
            stubGroupCnt: null,
            pinYin: 'Rongcheng',
          },
          {
            id: '130630',
            text: '涞源县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3663,
            gisBd09Lng: 114.702,
            gisGcj02Lat: 39.3601,
            gisGcj02Lng: 114.696,
            stubGroupCnt: null,
            pinYin: 'Laiyuan',
          },
          {
            id: '130631',
            text: '望都县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7125,
            gisBd09Lng: 115.162,
            gisGcj02Lat: 38.7067,
            gisGcj02Lng: 115.156,
            stubGroupCnt: null,
            pinYin: 'Wangdu',
          },
          {
            id: '130632',
            text: '安新县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9434,
            gisBd09Lng: 115.942,
            gisGcj02Lat: 38.9371,
            gisGcj02Lng: 115.936,
            stubGroupCnt: null,
            pinYin: 'Anxin',
          },
          {
            id: '130633',
            text: '易　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.354,
            gisBd09Lng: 115.507,
            gisGcj02Lat: 39.3484,
            gisGcj02Lng: 115.501,
            stubGroupCnt: null,
            pinYin: 'Yixian',
          },
          {
            id: '130634',
            text: '曲阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.6297,
            gisBd09Lng: 114.754,
            gisGcj02Lat: 38.6237,
            gisGcj02Lng: 114.747,
            stubGroupCnt: null,
            pinYin: 'Quyang',
          },
          {
            id: '130635',
            text: '蠡　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 38.488,
            gisGcj02Lng: 115.583,
            stubGroupCnt: null,
            pinYin: 'Lixian',
          },
          {
            id: '130636',
            text: '顺平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4942,
            gisBd09Lng: 115.589,
            gisGcj02Lat: 38.488,
            gisGcj02Lng: 115.583,
            stubGroupCnt: null,
            pinYin: 'Shunping',
          },
          {
            id: '130637',
            text: '博野县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.462,
            gisBd09Lng: 115.471,
            gisGcj02Lat: 38.4559,
            gisGcj02Lng: 115.464,
            stubGroupCnt: null,
            pinYin: 'Boye',
          },
          {
            id: '130638',
            text: '雄　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9997,
            gisBd09Lng: 116.115,
            gisGcj02Lat: 38.994,
            gisGcj02Lng: 116.109,
            stubGroupCnt: null,
            pinYin: 'Xiongxian',
          },
          {
            id: '130681',
            text: '涿州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4919,
            gisBd09Lng: 115.981,
            gisGcj02Lat: 39.4862,
            gisGcj02Lng: 115.974,
            stubGroupCnt: null,
            pinYin: 'Zhuozhou',
          },
          {
            id: '130682',
            text: '定州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4658,
            gisBd09Lng: 115.059,
            gisGcj02Lat: 38.4598,
            gisGcj02Lng: 115.052,
            stubGroupCnt: null,
            pinYin: 'Dingzhou',
          },
          {
            id: '130683',
            text: '安国',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4248,
            gisBd09Lng: 115.334,
            gisGcj02Lat: 38.4185,
            gisGcj02Lng: 115.327,
            stubGroupCnt: null,
            pinYin: 'Anguo',
          },
          {
            id: '130684',
            text: '高碑店',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9158,
            gisBd09Lng: 116.538,
            gisGcj02Lat: 39.9095,
            gisGcj02Lng: 116.532,
            stubGroupCnt: null,
            pinYin: 'Gaobeidian',
          },
        ],
        gisBd09Lng: 115.472,
        text: '保定',
        gisBd09Lat: 38.8814,
        gisGcj02Lat: 38.8753,
      },
      {
        pinYin: 'Zhangjiakou',
        id: '130700',
        gisGcj02Lng: 114.888,
        children: [
          {
            id: '130701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8301,
            gisBd09Lng: 114.894,
            gisGcj02Lat: 40.8244,
            gisGcj02Lng: 114.887,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130702',
            text: '桥东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7942,
            gisBd09Lng: 114.901,
            gisGcj02Lat: 40.7885,
            gisGcj02Lng: 114.894,
            stubGroupCnt: null,
            pinYin: 'Qiaodong',
          },
          {
            id: '130703',
            text: '桥西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8254,
            gisBd09Lng: 114.876,
            gisGcj02Lat: 40.8194,
            gisGcj02Lng: 114.869,
            stubGroupCnt: null,
            pinYin: 'Qiaoxi',
          },
          {
            id: '130705',
            text: '宣化区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6147,
            gisBd09Lng: 115.106,
            gisGcj02Lat: 40.6085,
            gisGcj02Lng: 115.099,
            stubGroupCnt: null,
            pinYin: 'Xuanhua',
          },
          {
            id: '130706',
            text: '下花园区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5083,
            gisBd09Lng: 115.294,
            gisGcj02Lat: 40.5024,
            gisGcj02Lng: 115.288,
            stubGroupCnt: null,
            pinYin: 'Xiahuayuan',
          },
          {
            id: '130721',
            text: '宣化县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5851,
            gisBd09Lng: 115.008,
            gisGcj02Lat: 40.5793,
            gisGcj02Lng: 115.001,
            stubGroupCnt: null,
            pinYin: 'Xuanhua',
          },
          {
            id: '130722',
            text: '张北县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.165,
            gisBd09Lng: 114.726,
            gisGcj02Lat: 41.1586,
            gisGcj02Lng: 114.72,
            stubGroupCnt: null,
            pinYin: 'Zhangbei',
          },
          {
            id: '130723',
            text: '康保县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8586,
            gisBd09Lng: 114.607,
            gisGcj02Lat: 41.8523,
            gisGcj02Lng: 114.6,
            stubGroupCnt: null,
            pinYin: 'Kangbao',
          },
          {
            id: '130724',
            text: '沽源县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.676,
            gisBd09Lng: 115.695,
            gisGcj02Lat: 41.6697,
            gisGcj02Lng: 115.689,
            stubGroupCnt: null,
            pinYin: 'Guyuan',
          },
          {
            id: '130725',
            text: '尚义县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0819,
            gisBd09Lng: 113.976,
            gisGcj02Lat: 41.0759,
            gisGcj02Lng: 113.969,
            stubGroupCnt: null,
            pinYin: 'Shangyi',
          },
          {
            id: '130726',
            text: '蔚　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8467,
            gisBd09Lng: 114.596,
            gisGcj02Lat: 39.8404,
            gisGcj02Lng: 114.589,
            stubGroupCnt: null,
            pinYin: 'Yuxian',
          },
          {
            id: '130727',
            text: '阳原县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1096,
            gisBd09Lng: 114.157,
            gisGcj02Lat: 40.1036,
            gisGcj02Lng: 114.151,
            stubGroupCnt: null,
            pinYin: 'Yangyuan',
          },
          {
            id: '130728',
            text: '怀安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6802,
            gisBd09Lng: 114.392,
            gisGcj02Lat: 40.6742,
            gisGcj02Lng: 114.386,
            stubGroupCnt: null,
            pinYin: "Huai'an",
          },
          {
            id: '130729',
            text: '万全县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7736,
            gisBd09Lng: 114.748,
            gisGcj02Lat: 40.7674,
            gisGcj02Lng: 114.741,
            stubGroupCnt: null,
            pinYin: 'Wanquan',
          },
          {
            id: '130730',
            text: '怀来县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4206,
            gisBd09Lng: 115.525,
            gisGcj02Lat: 40.4148,
            gisGcj02Lng: 115.519,
            stubGroupCnt: null,
            pinYin: 'Huailai',
          },
          {
            id: '130731',
            text: '涿鹿县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3857,
            gisBd09Lng: 115.226,
            gisGcj02Lat: 40.3795,
            gisGcj02Lng: 115.22,
            stubGroupCnt: null,
            pinYin: 'Zhuolu',
          },
          {
            id: '130732',
            text: '赤城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9189,
            gisBd09Lng: 115.838,
            gisGcj02Lat: 40.9129,
            gisGcj02Lng: 115.832,
            stubGroupCnt: null,
            pinYin: 'Chicheng',
          },
          {
            id: '130733',
            text: '崇礼县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9805,
            gisBd09Lng: 115.29,
            gisGcj02Lat: 40.9746,
            gisGcj02Lng: 115.283,
            stubGroupCnt: null,
            pinYin: 'Chongli',
          },
        ],
        gisBd09Lng: 114.894,
        text: '张家口',
        gisBd09Lat: 40.8301,
        gisGcj02Lat: 40.8244,
      },
      {
        pinYin: 'Chengde',
        id: '130800',
        gisGcj02Lng: 117.963,
        children: [
          {
            id: '130801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9579,
            gisBd09Lng: 117.97,
            gisGcj02Lat: 40.9515,
            gisGcj02Lng: 117.963,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130802',
            text: '双桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9809,
            gisBd09Lng: 117.95,
            gisGcj02Lat: 40.9747,
            gisGcj02Lng: 117.943,
            stubGroupCnt: null,
            pinYin: 'Shuangqiao',
          },
          {
            id: '130803',
            text: '双滦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9649,
            gisBd09Lng: 117.807,
            gisGcj02Lat: 40.959,
            gisGcj02Lng: 117.8,
            stubGroupCnt: null,
            pinYin: 'Shuangluan',
          },
          {
            id: '130804',
            text: '鹰手营子矿区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5521,
            gisBd09Lng: 117.666,
            gisGcj02Lat: 40.5464,
            gisGcj02Lng: 117.659,
            stubGroupCnt: null,
            pinYin: 'Yingshouyingzikuangqu',
          },
          {
            id: '130821',
            text: '承德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7746,
            gisBd09Lng: 118.18,
            gisGcj02Lat: 40.7685,
            gisGcj02Lng: 118.174,
            stubGroupCnt: null,
            pinYin: 'Chengde',
          },
          {
            id: '130822',
            text: '兴隆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4233,
            gisBd09Lng: 117.507,
            gisGcj02Lat: 40.4171,
            gisGcj02Lng: 117.501,
            stubGroupCnt: null,
            pinYin: 'Xinglong',
          },
          {
            id: '130823',
            text: '平泉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0245,
            gisBd09Lng: 118.708,
            gisGcj02Lat: 41.0184,
            gisGcj02Lng: 118.702,
            stubGroupCnt: null,
            pinYin: 'Pingquan',
          },
          {
            id: '130824',
            text: '滦平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0245,
            gisBd09Lng: 118.708,
            gisGcj02Lat: 41.0184,
            gisGcj02Lng: 118.702,
            stubGroupCnt: null,
            pinYin: 'Luanping',
          },
          {
            id: '130825',
            text: '隆化县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3209,
            gisBd09Lng: 117.745,
            gisGcj02Lat: 41.3147,
            gisGcj02Lng: 117.738,
            stubGroupCnt: null,
            pinYin: 'Longhua',
          },
          {
            id: '130826',
            text: '丰宁满族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2139,
            gisBd09Lng: 116.655,
            gisGcj02Lat: 41.2075,
            gisGcj02Lng: 116.648,
            stubGroupCnt: null,
            pinYin: 'Fengning',
          },
          {
            id: '130827',
            text: '宽城满族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6169,
            gisBd09Lng: 118.493,
            gisGcj02Lat: 40.6111,
            gisGcj02Lng: 118.486,
            stubGroupCnt: null,
            pinYin: 'Kuancheng',
          },
          {
            id: '130828',
            text: '围场满族蒙古族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9442,
            gisBd09Lng: 117.767,
            gisGcj02Lat: 41.9384,
            gisGcj02Lng: 117.76,
            stubGroupCnt: null,
            pinYin: 'Weichang',
          },
        ],
        gisBd09Lng: 117.97,
        text: '承德',
        gisBd09Lat: 40.9579,
        gisGcj02Lat: 40.9515,
      },
      {
        pinYin: 'Cangzhou',
        id: '130900',
        gisGcj02Lng: 116.839,
        children: [
          {
            id: '130901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3102,
            gisBd09Lng: 116.845,
            gisGcj02Lat: 38.3044,
            gisGcj02Lng: 116.839,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '130902',
            text: '新华区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3205,
            gisBd09Lng: 116.873,
            gisGcj02Lat: 38.3142,
            gisGcj02Lng: 116.866,
            stubGroupCnt: null,
            pinYin: 'Xinhua',
          },
          {
            id: '130903',
            text: '运河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3162,
            gisBd09Lng: 116.839,
            gisGcj02Lat: 38.3105,
            gisGcj02Lng: 116.832,
            stubGroupCnt: null,
            pinYin: 'Yunhe',
          },
          {
            id: '130921',
            text: '沧　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3307,
            gisBd09Lng: 116.614,
            gisGcj02Lat: 38.3247,
            gisGcj02Lng: 116.607,
            stubGroupCnt: null,
            pinYin: 'Cangxian',
          },
          {
            id: '130922',
            text: '青　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0546,
            gisBd09Lng: 116.747,
            gisGcj02Lat: 38.0484,
            gisGcj02Lng: 116.741,
            stubGroupCnt: null,
            pinYin: 'Qingxian',
          },
          {
            id: '130923',
            text: '东光县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8941,
            gisBd09Lng: 116.544,
            gisGcj02Lat: 37.8879,
            gisGcj02Lng: 116.537,
            stubGroupCnt: null,
            pinYin: 'Dongguang',
          },
          {
            id: '130924',
            text: '海兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1486,
            gisBd09Lng: 117.504,
            gisGcj02Lat: 38.1424,
            gisGcj02Lng: 117.498,
            stubGroupCnt: null,
            pinYin: 'Haixing',
          },
          {
            id: '130925',
            text: '盐山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0642,
            gisBd09Lng: 117.237,
            gisGcj02Lat: 38.0578,
            gisGcj02Lng: 117.23,
            stubGroupCnt: null,
            pinYin: 'Yanshan',
          },
          {
            id: '130926',
            text: '肃宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4293,
            gisBd09Lng: 115.839,
            gisGcj02Lat: 38.4234,
            gisGcj02Lng: 115.832,
            stubGroupCnt: null,
            pinYin: 'Suning',
          },
          {
            id: '130927',
            text: '南皮县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0429,
            gisBd09Lng: 116.715,
            gisGcj02Lat: 38.0372,
            gisGcj02Lng: 116.708,
            stubGroupCnt: null,
            pinYin: 'Nanpi',
          },
          {
            id: '130928',
            text: '吴桥县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6337,
            gisBd09Lng: 116.398,
            gisGcj02Lat: 37.6274,
            gisGcj02Lng: 116.392,
            stubGroupCnt: null,
            pinYin: 'Wuqiao',
          },
          {
            id: '130929',
            text: '献　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1955,
            gisBd09Lng: 116.13,
            gisGcj02Lat: 38.1897,
            gisGcj02Lng: 116.123,
            stubGroupCnt: null,
            pinYin: 'Xianxian',
          },
          {
            id: '130930',
            text: '孟村回族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0595,
            gisBd09Lng: 117.111,
            gisGcj02Lat: 38.0532,
            gisGcj02Lng: 117.104,
            stubGroupCnt: null,
            pinYin: 'Mengcun',
          },
          {
            id: '130981',
            text: '泊头',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0884,
            gisBd09Lng: 116.585,
            gisGcj02Lat: 38.0827,
            gisGcj02Lng: 116.579,
            stubGroupCnt: null,
            pinYin: 'Botou',
          },
          {
            id: '130982',
            text: '任丘',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.717,
            gisBd09Lng: 116.106,
            gisGcj02Lat: 38.7114,
            gisGcj02Lng: 116.099,
            stubGroupCnt: null,
            pinYin: 'Renqiu',
          },
          {
            id: '130983',
            text: '黄骅',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3771,
            gisBd09Lng: 117.337,
            gisGcj02Lat: 38.3711,
            gisGcj02Lng: 117.33,
            stubGroupCnt: null,
            pinYin: 'Huanghua',
          },
          {
            id: '130984',
            text: '河间',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4511,
            gisBd09Lng: 116.106,
            gisGcj02Lat: 38.4454,
            gisGcj02Lng: 116.099,
            stubGroupCnt: null,
            pinYin: 'Hejian',
          },
        ],
        gisBd09Lng: 116.845,
        text: '沧州',
        gisBd09Lat: 38.3102,
        gisGcj02Lat: 38.3044,
      },
      {
        pinYin: 'Langfang',
        id: '131000',
        gisGcj02Lng: 116.684,
        children: [
          {
            id: '131001',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5806,
            gisBd09Lng: 113.107,
            gisGcj02Lat: 22.5749,
            gisGcj02Lng: 113.1,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '131002',
            text: '安次区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5091,
            gisBd09Lng: 116.692,
            gisGcj02Lat: 39.5034,
            gisGcj02Lng: 116.685,
            stubGroupCnt: null,
            pinYin: 'Anci',
          },
          {
            id: '131003',
            text: '广阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0646,
            gisBd09Lng: 117.236,
            gisGcj02Lat: 38.0582,
            gisGcj02Lng: 117.23,
            stubGroupCnt: null,
            pinYin: 'Guangyang',
          },
          {
            id: '131022',
            text: '固安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4443,
            gisBd09Lng: 116.305,
            gisGcj02Lat: 39.4381,
            gisGcj02Lng: 116.298,
            stubGroupCnt: null,
            pinYin: "Gu'an",
          },
          {
            id: '131023',
            text: '永清县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3271,
            gisBd09Lng: 116.505,
            gisGcj02Lat: 39.3209,
            gisGcj02Lng: 116.499,
            stubGroupCnt: null,
            pinYin: 'Yongqing',
          },
          {
            id: '131024',
            text: '香河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7675,
            gisBd09Lng: 117.012,
            gisGcj02Lat: 39.7612,
            gisGcj02Lng: 117.006,
            stubGroupCnt: null,
            pinYin: 'Xianghe',
          },
          {
            id: '131025',
            text: '大城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.711,
            gisBd09Lng: 116.661,
            gisGcj02Lat: 38.7047,
            gisGcj02Lng: 116.654,
            stubGroupCnt: null,
            pinYin: 'Daicheng',
          },
          {
            id: '131026',
            text: '文安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8786,
            gisBd09Lng: 116.465,
            gisGcj02Lat: 38.8729,
            gisGcj02Lng: 116.459,
            stubGroupCnt: null,
            pinYin: "Wen'an",
          },
          {
            id: '131028',
            text: '大厂回族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8947,
            gisBd09Lng: 116.956,
            gisGcj02Lat: 39.889,
            gisGcj02Lng: 116.949,
            stubGroupCnt: null,
            pinYin: 'Dachang',
          },
          {
            id: '131081',
            text: '霸州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1332,
            gisBd09Lng: 116.398,
            gisGcj02Lat: 39.1269,
            gisGcj02Lng: 116.391,
            stubGroupCnt: null,
            pinYin: 'Bazhou',
          },
          {
            id: '131082',
            text: '三河',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.988,
            gisBd09Lng: 117.085,
            gisGcj02Lat: 39.9822,
            gisGcj02Lng: 117.079,
            stubGroupCnt: null,
            pinYin: 'Sanhe',
          },
        ],
        gisBd09Lng: 116.691,
        text: '廊坊',
        gisBd09Lat: 39.5434,
        gisGcj02Lat: 39.5377,
      },
      {
        pinYin: 'Hengshui',
        id: '131100',
        gisGcj02Lng: 115.671,
        children: [
          {
            id: '131101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.744,
            gisBd09Lng: 115.677,
            gisGcj02Lat: 37.7377,
            gisGcj02Lng: 115.671,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '131102',
            text: '桃城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7412,
            gisBd09Lng: 115.681,
            gisGcj02Lat: 37.7349,
            gisGcj02Lng: 115.675,
            stubGroupCnt: null,
            pinYin: 'Taocheng',
          },
          {
            id: '131121',
            text: '枣强县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5192,
            gisBd09Lng: 115.73,
            gisGcj02Lat: 37.5134,
            gisGcj02Lng: 115.723,
            stubGroupCnt: null,
            pinYin: 'Zaoqiang',
          },
          {
            id: '131122',
            text: '武邑县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8071,
            gisBd09Lng: 115.894,
            gisGcj02Lat: 37.8011,
            gisGcj02Lng: 115.888,
            stubGroupCnt: null,
            pinYin: 'Wuyi',
          },
          {
            id: '131123',
            text: '武强县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.047,
            gisBd09Lng: 115.989,
            gisGcj02Lat: 38.0414,
            gisGcj02Lng: 115.982,
            stubGroupCnt: null,
            pinYin: 'Wuqiang',
          },
          {
            id: '131124',
            text: '饶阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.241,
            gisBd09Lng: 115.732,
            gisGcj02Lat: 38.2353,
            gisGcj02Lng: 115.725,
            stubGroupCnt: null,
            pinYin: 'Raoyang',
          },
          {
            id: '131125',
            text: '安平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.24,
            gisBd09Lng: 115.526,
            gisGcj02Lat: 38.2342,
            gisGcj02Lng: 115.519,
            stubGroupCnt: null,
            pinYin: 'Anping',
          },
          {
            id: '131126',
            text: '故城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3531,
            gisBd09Lng: 115.972,
            gisGcj02Lat: 37.3474,
            gisGcj02Lng: 115.966,
            stubGroupCnt: null,
            pinYin: 'Gucheng',
          },
          {
            id: '131127',
            text: '景　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5839,
            gisBd09Lng: 115.707,
            gisGcj02Lat: 37.5777,
            gisGcj02Lng: 115.7,
            stubGroupCnt: null,
            pinYin: 'Jingxian',
          },
          {
            id: '131128',
            text: '阜城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8751,
            gisBd09Lng: 116.15,
            gisGcj02Lat: 37.8689,
            gisGcj02Lng: 116.144,
            stubGroupCnt: null,
            pinYin: 'Fucheng',
          },
          {
            id: '131181',
            text: '冀州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5569,
            gisBd09Lng: 115.585,
            gisGcj02Lat: 37.5507,
            gisGcj02Lng: 115.579,
            stubGroupCnt: null,
            pinYin: 'Jizhou',
          },
          {
            id: '131182',
            text: '深州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0065,
            gisBd09Lng: 115.566,
            gisGcj02Lat: 38.0001,
            gisGcj02Lng: 115.56,
            stubGroupCnt: null,
            pinYin: 'Shenzhou',
          },
        ],
        gisBd09Lng: 115.677,
        text: '衡水',
        gisBd09Lat: 37.744,
        gisGcj02Lat: 37.7377,
      },
    ],
  },
  {
    text: '河南省',
    children: [
      {
        pinYin: 'Zhengzhou',
        id: '410100',
        gisGcj02Lng: 113.625,
        children: [
          {
            id: '410101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7524,
            gisBd09Lng: 113.631,
            gisGcj02Lat: 34.7462,
            gisGcj02Lng: 113.625,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '410102',
            text: '中原区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7532,
            gisBd09Lng: 113.616,
            gisGcj02Lat: 34.7472,
            gisGcj02Lng: 113.61,
            stubGroupCnt: null,
            pinYin: 'Zhongyuan',
          },
          {
            id: '410103',
            text: '二七区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7305,
            gisBd09Lng: 113.647,
            gisGcj02Lat: 34.7241,
            gisGcj02Lng: 113.641,
            stubGroupCnt: null,
            pinYin: 'Erqi',
          },
          {
            id: '410104',
            text: '管城回族区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.05.09 11:04:59',
            gisBd09Lat: 34.7585,
            gisBd09Lng: 113.684,
            gisGcj02Lat: 34.7526,
            gisGcj02Lng: 113.677,
            stubGroupCnt: null,
            pinYin: 'Guancheng',
          },
          {
            id: '410105',
            text: '金水区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8046,
            gisBd09Lng: 113.668,
            gisGcj02Lat: 34.7985,
            gisGcj02Lng: 113.661,
            stubGroupCnt: null,
            pinYin: 'Jinshui',
          },
          {
            id: '410106',
            text: '上街区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8081,
            gisBd09Lng: 113.317,
            gisGcj02Lat: 34.8021,
            gisGcj02Lng: 113.311,
            stubGroupCnt: null,
            pinYin: 'Shangjie',
          },
          {
            id: '410108',
            text: '邙山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8732,
            gisBd09Lng: 113.656,
            gisGcj02Lat: 34.8669,
            gisGcj02Lng: 113.65,
            stubGroupCnt: null,
            pinYin: 'Huiji',
          },
          {
            id: '410122',
            text: '中牟县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7243,
            gisBd09Lng: 113.984,
            gisGcj02Lat: 34.7182,
            gisGcj02Lng: 113.978,
            stubGroupCnt: null,
            pinYin: 'Zhongmu',
          },
          {
            id: '410181',
            text: '巩义',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7524,
            gisBd09Lng: 113.029,
            gisGcj02Lat: 34.7462,
            gisGcj02Lng: 113.022,
            stubGroupCnt: null,
            pinYin: 'Gongyi',
          },
          {
            id: '410182',
            text: '荥阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7932,
            gisBd09Lng: 113.39,
            gisGcj02Lat: 34.787,
            gisGcj02Lng: 113.384,
            stubGroupCnt: null,
            pinYin: 'Xingyang',
          },
          {
            id: '410183',
            text: '新密',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5445,
            gisBd09Lng: 113.397,
            gisGcj02Lat: 34.5382,
            gisGcj02Lng: 113.391,
            stubGroupCnt: null,
            pinYin: 'Xinmi',
          },
          {
            id: '410184',
            text: '新郑',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4014,
            gisBd09Lng: 113.748,
            gisGcj02Lat: 34.3952,
            gisGcj02Lng: 113.742,
            stubGroupCnt: null,
            pinYin: 'Xinzheng',
          },
          {
            id: '410185',
            text: '登封',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.458,
            gisBd09Lng: 113.058,
            gisGcj02Lat: 34.4517,
            gisGcj02Lng: 113.051,
            stubGroupCnt: null,
            pinYin: 'Dengfeng',
          },
        ],
        gisBd09Lng: 113.631,
        text: '郑州',
        gisBd09Lat: 34.7524,
        gisGcj02Lat: 34.7462,
      },
      {
        pinYin: 'Kaifeng',
        id: '410200',
        gisGcj02Lng: 114.306,
        children: [
          {
            id: '410201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8025,
            gisBd09Lng: 114.313,
            gisGcj02Lat: 34.7968,
            gisGcj02Lng: 114.307,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410202',
            text: '龙亭区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8062,
            gisBd09Lng: 114.361,
            gisGcj02Lat: 34.7999,
            gisGcj02Lng: 114.355,
            stubGroupCnt: null,
            pinYin: 'Longting',
          },
          {
            id: '410203',
            text: '顺河回族区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.802,
            gisBd09Lng: 114.367,
            gisGcj02Lat: 34.7956,
            gisGcj02Lng: 114.361,
            stubGroupCnt: null,
            pinYin: 'Shunhe',
          },
          {
            id: '410204',
            text: '鼓楼区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7937,
            gisBd09Lng: 114.354,
            gisGcj02Lat: 34.7874,
            gisGcj02Lng: 114.348,
            stubGroupCnt: null,
            pinYin: 'Gulou',
          },
          {
            id: '410205',
            text: '南关区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8029,
            gisBd09Lng: 114.34,
            gisGcj02Lat: 34.7969,
            gisGcj02Lng: 114.333,
            stubGroupCnt: null,
            pinYin: 'Yuwangtai',
          },
          {
            id: '410211',
            text: '郊　区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2301,
            gisBd09Lng: 113.118,
            gisGcj02Lat: 36.2243,
            gisGcj02Lng: 113.112,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410221',
            text: '杞　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5549,
            gisBd09Lng: 114.79,
            gisGcj02Lat: 34.5493,
            gisGcj02Lng: 114.783,
            stubGroupCnt: null,
            pinYin: 'Qixian',
          },
          {
            id: '410222',
            text: '通许县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4867,
            gisBd09Lng: 114.474,
            gisGcj02Lat: 34.4804,
            gisGcj02Lng: 114.468,
            stubGroupCnt: null,
            pinYin: 'Tongxu',
          },
          {
            id: '410223',
            text: '尉氏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4166,
            gisBd09Lng: 114.201,
            gisGcj02Lat: 34.4109,
            gisGcj02Lng: 114.195,
            stubGroupCnt: null,
            pinYin: 'Weishi',
          },
          {
            id: '410224',
            text: '开封县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7243,
            gisBd09Lng: 114.452,
            gisGcj02Lat: 34.7184,
            gisGcj02Lng: 114.446,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410225',
            text: '兰考县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8275,
            gisBd09Lng: 114.83,
            gisGcj02Lat: 34.8213,
            gisGcj02Lng: 114.824,
            stubGroupCnt: null,
            pinYin: 'Lankao',
          },
        ],
        gisBd09Lng: 114.313,
        text: '开封',
        gisBd09Lat: 34.8025,
        gisGcj02Lat: 34.7968,
      },
      {
        pinYin: 'Luoyang',
        id: '410300',
        gisGcj02Lng: 112.454,
        children: [
          {
            id: '410301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6238,
            gisBd09Lng: 112.46,
            gisGcj02Lat: 34.6176,
            gisGcj02Lng: 112.453,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410302',
            text: '老城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6877,
            gisBd09Lng: 112.477,
            gisGcj02Lat: 34.6817,
            gisGcj02Lng: 112.47,
            stubGroupCnt: null,
            pinYin: 'Laocheng',
          },
          {
            id: '410303',
            text: '西工区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6651,
            gisBd09Lng: 112.435,
            gisGcj02Lat: 34.6589,
            gisGcj02Lng: 112.428,
            stubGroupCnt: null,
            pinYin: 'Xigong',
          },
          {
            id: '410304',
            text: '廛河回族区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7008,
            gisBd09Lng: 112.541,
            gisGcj02Lat: 34.6947,
            gisGcj02Lng: 112.535,
            stubGroupCnt: null,
            pinYin: 'Chanhe',
          },
          {
            id: '410305',
            text: '涧西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6623,
            gisBd09Lng: 112.403,
            gisGcj02Lat: 34.6565,
            gisGcj02Lng: 112.396,
            stubGroupCnt: null,
            pinYin: 'Jianxi',
          },
          {
            id: '410306',
            text: '吉利区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9062,
            gisBd09Lng: 112.596,
            gisGcj02Lat: 34.9002,
            gisGcj02Lng: 112.59,
            stubGroupCnt: null,
            pinYin: 'Jili',
          },
          {
            id: '410307',
            text: '洛龙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6235,
            gisBd09Lng: 112.472,
            gisGcj02Lat: 34.6174,
            gisGcj02Lng: 112.465,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410322',
            text: '孟津县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8305,
            gisBd09Lng: 112.455,
            gisGcj02Lat: 34.8242,
            gisGcj02Lng: 112.449,
            stubGroupCnt: null,
            pinYin: 'Mengjin',
          },
          {
            id: '410323',
            text: '新安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7334,
            gisBd09Lng: 112.14,
            gisGcj02Lat: 34.7277,
            gisGcj02Lng: 112.134,
            stubGroupCnt: null,
            pinYin: "Xin'an",
          },
          {
            id: '410324',
            text: '栾川县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7898,
            gisBd09Lng: 111.623,
            gisGcj02Lat: 33.7836,
            gisGcj02Lng: 111.616,
            stubGroupCnt: null,
            pinYin: 'Luanchuan',
          },
          {
            id: '410325',
            text: '嵩　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1394,
            gisBd09Lng: 112.094,
            gisGcj02Lat: 34.1331,
            gisGcj02Lng: 112.087,
            stubGroupCnt: null,
            pinYin: 'Songxian',
          },
          {
            id: '410326',
            text: '汝阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1597,
            gisBd09Lng: 112.478,
            gisGcj02Lat: 34.1537,
            gisGcj02Lng: 112.471,
            stubGroupCnt: null,
            pinYin: 'Ruyang',
          },
          {
            id: '410327',
            text: '宜阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5193,
            gisBd09Lng: 112.184,
            gisGcj02Lat: 34.5132,
            gisGcj02Lng: 112.178,
            stubGroupCnt: null,
            pinYin: 'Yiyang',
          },
          {
            id: '410328',
            text: '洛宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3922,
            gisBd09Lng: 111.66,
            gisGcj02Lat: 34.3866,
            gisGcj02Lng: 111.653,
            stubGroupCnt: null,
            pinYin: 'Luoning',
          },
          {
            id: '410329',
            text: '伊川县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4266,
            gisBd09Lng: 112.431,
            gisGcj02Lat: 34.4204,
            gisGcj02Lng: 112.424,
            stubGroupCnt: null,
            pinYin: 'Yichuan',
          },
          {
            id: '410381',
            text: '偃师',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7339,
            gisBd09Lng: 112.795,
            gisGcj02Lat: 34.7276,
            gisGcj02Lng: 112.788,
            stubGroupCnt: null,
            pinYin: 'Yanshi',
          },
        ],
        gisBd09Lng: 112.46,
        text: '洛阳',
        gisBd09Lat: 34.6238,
        gisGcj02Lat: 34.6175,
      },
      {
        pinYin: 'Pingdingshan',
        id: '410400',
        gisGcj02Lng: 113.193,
        children: [
          {
            id: '410401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7706,
            gisBd09Lng: 113.2,
            gisGcj02Lat: 33.7647,
            gisGcj02Lng: 113.194,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410402',
            text: '新华区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7429,
            gisBd09Lng: 113.301,
            gisGcj02Lat: 33.7366,
            gisGcj02Lng: 113.294,
            stubGroupCnt: null,
            pinYin: 'Xinhua',
          },
          {
            id: '410403',
            text: '卫东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7406,
            gisBd09Lng: 113.342,
            gisGcj02Lat: 33.7349,
            gisGcj02Lng: 113.336,
            stubGroupCnt: null,
            pinYin: 'Weidong',
          },
          {
            id: '410404',
            text: '石龙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9043,
            gisBd09Lng: 112.905,
            gisGcj02Lat: 33.8982,
            gisGcj02Lng: 112.898,
            stubGroupCnt: null,
            pinYin: 'Shilong',
          },
          {
            id: '410411',
            text: '湛河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7069,
            gisBd09Lng: 113.283,
            gisGcj02Lat: 33.7006,
            gisGcj02Lng: 113.277,
            stubGroupCnt: null,
            pinYin: 'Zhanhe',
          },
          {
            id: '410421',
            text: '宝丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8739,
            gisBd09Lng: 113.062,
            gisGcj02Lat: 33.8677,
            gisGcj02Lng: 113.056,
            stubGroupCnt: null,
            pinYin: 'Baofeng',
          },
          {
            id: '410422',
            text: '叶　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6322,
            gisBd09Lng: 113.363,
            gisGcj02Lat: 33.6264,
            gisGcj02Lng: 113.357,
            stubGroupCnt: null,
            pinYin: 'Yexian',
          },
          {
            id: '410423',
            text: '鲁山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7435,
            gisBd09Lng: 112.914,
            gisGcj02Lat: 33.7372,
            gisGcj02Lng: 112.908,
            stubGroupCnt: null,
            pinYin: 'Lushan',
          },
          {
            id: '410425',
            text: '郏　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.977,
            gisBd09Lng: 113.219,
            gisGcj02Lat: 33.9713,
            gisGcj02Lng: 113.212,
            stubGroupCnt: null,
            pinYin: 'Jiaxian',
          },
          {
            id: '410481',
            text: '舞钢',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2991,
            gisBd09Lng: 113.532,
            gisGcj02Lat: 33.2928,
            gisGcj02Lng: 113.526,
            stubGroupCnt: null,
            pinYin: 'Wugang',
          },
          {
            id: '410482',
            text: '汝州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1715,
            gisBd09Lng: 112.852,
            gisGcj02Lat: 34.1658,
            gisGcj02Lng: 112.846,
            stubGroupCnt: null,
            pinYin: 'Ruzhou',
          },
        ],
        gisBd09Lng: 113.2,
        text: '平顶山',
        gisBd09Lat: 33.7706,
        gisGcj02Lat: 33.7647,
      },
      {
        pinYin: 'Anyang',
        id: '410500',
        gisGcj02Lng: 114.395,
        children: [
          {
            id: '410501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1025,
            gisBd09Lng: 114.402,
            gisGcj02Lat: 36.0966,
            gisGcj02Lng: 114.395,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410502',
            text: '文峰区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0967,
            gisBd09Lng: 114.361,
            gisGcj02Lat: 36.0904,
            gisGcj02Lng: 114.355,
            stubGroupCnt: null,
            pinYin: 'Wenfeng',
          },
          {
            id: '410503',
            text: '北关区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1127,
            gisBd09Lng: 114.362,
            gisGcj02Lat: 36.1064,
            gisGcj02Lng: 114.356,
            stubGroupCnt: null,
            pinYin: 'Beiguan',
          },
          {
            id: '410505',
            text: '殷都区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1138,
            gisBd09Lng: 114.309,
            gisGcj02Lat: 36.1082,
            gisGcj02Lng: 114.303,
            stubGroupCnt: null,
            pinYin: 'Yindu',
          },
          {
            id: '410506',
            text: '龙安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0809,
            gisBd09Lng: 114.31,
            gisGcj02Lat: 36.0753,
            gisGcj02Lng: 114.303,
            stubGroupCnt: null,
            pinYin: "Long'an",
          },
          {
            id: '410522',
            text: '安阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2181,
            gisBd09Lng: 114.238,
            gisGcj02Lat: 36.2117,
            gisGcj02Lng: 114.232,
            stubGroupCnt: null,
            pinYin: 'Anyang',
          },
          {
            id: '410523',
            text: '汤阴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9277,
            gisBd09Lng: 114.364,
            gisGcj02Lat: 35.9214,
            gisGcj02Lng: 114.358,
            stubGroupCnt: null,
            pinYin: 'Tangyin',
          },
          {
            id: '410526',
            text: '滑　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5798,
            gisBd09Lng: 114.527,
            gisGcj02Lat: 35.574,
            gisGcj02Lng: 114.521,
            stubGroupCnt: null,
            pinYin: 'Huaxian',
          },
          {
            id: '410527',
            text: '内黄县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9519,
            gisBd09Lng: 114.906,
            gisGcj02Lat: 35.9462,
            gisGcj02Lng: 114.9,
            stubGroupCnt: null,
            pinYin: 'Neihuang',
          },
          {
            id: '410581',
            text: '林州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0877,
            gisBd09Lng: 113.825,
            gisGcj02Lat: 36.082,
            gisGcj02Lng: 113.819,
            stubGroupCnt: null,
            pinYin: 'Linzhou',
          },
        ],
        gisBd09Lng: 114.402,
        text: '安阳',
        gisBd09Lat: 36.1025,
        gisGcj02Lat: 36.0966,
      },
      {
        pinYin: 'Hebi',
        id: '410600',
        gisGcj02Lng: 114.297,
        children: [
          {
            id: '410601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7526,
            gisBd09Lng: 114.304,
            gisGcj02Lat: 35.7469,
            gisGcj02Lng: 114.297,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410602',
            text: '鹤山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9593,
            gisBd09Lng: 114.171,
            gisGcj02Lat: 35.9535,
            gisGcj02Lng: 114.164,
            stubGroupCnt: null,
            pinYin: 'Heshan',
          },
          {
            id: '410603',
            text: '山城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.902,
            gisBd09Lng: 114.192,
            gisGcj02Lat: 35.8964,
            gisGcj02Lng: 114.185,
            stubGroupCnt: null,
            pinYin: 'Shancheng',
          },
          {
            id: '410611',
            text: '淇滨区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7464,
            gisBd09Lng: 114.306,
            gisGcj02Lat: 35.7407,
            gisGcj02Lng: 114.299,
            stubGroupCnt: null,
            pinYin: 'Qibin',
          },
          {
            id: '410621',
            text: '浚　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6811,
            gisBd09Lng: 114.558,
            gisGcj02Lat: 35.6754,
            gisGcj02Lng: 114.551,
            stubGroupCnt: null,
            pinYin: 'Xunxian',
          },
          {
            id: '410622',
            text: '淇　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6136,
            gisBd09Lng: 114.204,
            gisGcj02Lat: 35.6078,
            gisGcj02Lng: 114.198,
            stubGroupCnt: null,
            pinYin: 'Qixian',
          },
        ],
        gisBd09Lng: 114.304,
        text: '鹤壁',
        gisBd09Lat: 35.7526,
        gisGcj02Lat: 35.7469,
      },
      {
        pinYin: 'Xinxiang',
        id: '410700',
        gisGcj02Lng: 113.926,
        children: [
          {
            id: '410701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3082,
            gisBd09Lng: 113.933,
            gisGcj02Lat: 35.3025,
            gisGcj02Lng: 113.926,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410702',
            text: '红旗区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3088,
            gisBd09Lng: 113.882,
            gisGcj02Lat: 35.3024,
            gisGcj02Lng: 113.875,
            stubGroupCnt: null,
            pinYin: 'Hongqi',
          },
          {
            id: '410703',
            text: '卫滨区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3085,
            gisBd09Lng: 113.872,
            gisGcj02Lat: 35.3022,
            gisGcj02Lng: 113.866,
            stubGroupCnt: null,
            pinYin: 'Weibin',
          },
          {
            id: '410704',
            text: '凤泉区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3775,
            gisBd09Lng: 113.912,
            gisGcj02Lat: 35.3714,
            gisGcj02Lng: 113.906,
            stubGroupCnt: null,
            pinYin: 'Fengquan',
          },
          {
            id: '410711',
            text: '牧野区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.32,
            gisBd09Lng: 113.916,
            gisGcj02Lat: 35.314,
            gisGcj02Lng: 113.91,
            stubGroupCnt: null,
            pinYin: 'Muye',
          },
          {
            id: '410721',
            text: '新乡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1963,
            gisBd09Lng: 113.812,
            gisGcj02Lat: 35.1905,
            gisGcj02Lng: 113.805,
            stubGroupCnt: null,
            pinYin: 'Xinxiang',
          },
          {
            id: '410724',
            text: '获嘉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2647,
            gisBd09Lng: 113.664,
            gisGcj02Lat: 35.2585,
            gisGcj02Lng: 113.658,
            stubGroupCnt: null,
            pinYin: 'Huojia',
          },
          {
            id: '410725',
            text: '原阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0713,
            gisBd09Lng: 113.946,
            gisGcj02Lat: 35.0656,
            gisGcj02Lng: 113.94,
            stubGroupCnt: null,
            pinYin: 'Yuanyang',
          },
          {
            id: '410726',
            text: '延津县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1467,
            gisBd09Lng: 114.211,
            gisGcj02Lat: 35.1408,
            gisGcj02Lng: 114.205,
            stubGroupCnt: null,
            pinYin: 'Yanjin',
          },
          {
            id: '410727',
            text: '封丘县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0464,
            gisBd09Lng: 114.426,
            gisGcj02Lat: 35.0407,
            gisGcj02Lng: 114.419,
            stubGroupCnt: null,
            pinYin: 'Fengqiu',
          },
          {
            id: '410728',
            text: '长垣县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2061,
            gisBd09Lng: 114.675,
            gisGcj02Lat: 35.2004,
            gisGcj02Lng: 114.668,
            stubGroupCnt: null,
            pinYin: 'Changyuan',
          },
          {
            id: '410781',
            text: '卫辉',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4031,
            gisBd09Lng: 114.071,
            gisGcj02Lat: 35.3975,
            gisGcj02Lng: 114.065,
            stubGroupCnt: null,
            pinYin: 'Weihui',
          },
          {
            id: '410782',
            text: '辉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4669,
            gisBd09Lng: 113.813,
            gisGcj02Lat: 35.4612,
            gisGcj02Lng: 113.807,
            stubGroupCnt: null,
            pinYin: 'Huixian',
          },
        ],
        gisBd09Lng: 113.933,
        text: '新乡',
        gisBd09Lat: 35.3082,
        gisGcj02Lat: 35.3025,
      },
      {
        pinYin: 'Jiaozuo',
        id: '410800',
        gisGcj02Lng: 113.243,
        children: [
          {
            id: '410801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2204,
            gisBd09Lng: 113.25,
            gisGcj02Lat: 35.2145,
            gisGcj02Lng: 113.243,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410802',
            text: '解放区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2452,
            gisBd09Lng: 113.238,
            gisGcj02Lat: 35.2395,
            gisGcj02Lng: 113.231,
            stubGroupCnt: null,
            pinYin: 'Jiefang',
          },
          {
            id: '410803',
            text: '中站区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2425,
            gisBd09Lng: 113.189,
            gisGcj02Lat: 35.2364,
            gisGcj02Lng: 113.183,
            stubGroupCnt: null,
            pinYin: 'Zhongzhan',
          },
          {
            id: '410804',
            text: '马村区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2612,
            gisBd09Lng: 113.33,
            gisGcj02Lat: 35.2554,
            gisGcj02Lng: 113.323,
            stubGroupCnt: null,
            pinYin: 'Macun',
          },
          {
            id: '410811',
            text: '山阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2198,
            gisBd09Lng: 113.261,
            gisGcj02Lat: 35.2137,
            gisGcj02Lng: 113.254,
            stubGroupCnt: null,
            pinYin: 'Shanyang',
          },
          {
            id: '410821',
            text: '修武县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2283,
            gisBd09Lng: 113.455,
            gisGcj02Lat: 35.2226,
            gisGcj02Lng: 113.448,
            stubGroupCnt: null,
            pinYin: 'Xiuwu',
          },
          {
            id: '410822',
            text: '博爱县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1768,
            gisBd09Lng: 113.071,
            gisGcj02Lat: 35.1707,
            gisGcj02Lng: 113.065,
            stubGroupCnt: null,
            pinYin: 'Boai',
          },
          {
            id: '410823',
            text: '武陟县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1049,
            gisBd09Lng: 113.409,
            gisGcj02Lat: 35.0986,
            gisGcj02Lng: 113.402,
            stubGroupCnt: null,
            pinYin: 'Wuzhi',
          },
          {
            id: '410825',
            text: '温　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9449,
            gisBd09Lng: 113.088,
            gisGcj02Lat: 34.9391,
            gisGcj02Lng: 113.082,
            stubGroupCnt: null,
            pinYin: 'Wenxian',
          },
          {
            id: '410881',
            text: '济源',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0725,
            gisBd09Lng: 112.609,
            gisGcj02Lat: 35.0667,
            gisGcj02Lng: 112.603,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410882',
            text: '沁阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0931,
            gisBd09Lng: 112.958,
            gisGcj02Lat: 35.0871,
            gisGcj02Lng: 112.951,
            stubGroupCnt: null,
            pinYin: 'Qinyang',
          },
          {
            id: '410883',
            text: '孟州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9136,
            gisBd09Lng: 112.798,
            gisGcj02Lat: 34.9073,
            gisGcj02Lng: 112.791,
            stubGroupCnt: null,
            pinYin: 'Mengzhou',
          },
        ],
        gisBd09Lng: 113.25,
        text: '焦作',
        gisBd09Lat: 35.2204,
        gisGcj02Lat: 35.2145,
      },
      {
        pinYin: 'Puyang',
        id: '410900',
        gisGcj02Lng: 115.028,
        children: [
          {
            id: '410901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7665,
            gisBd09Lng: 115.035,
            gisGcj02Lat: 35.7608,
            gisGcj02Lng: 115.029,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '410902',
            text: '华龙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7831,
            gisBd09Lng: 115.083,
            gisGcj02Lat: 35.7767,
            gisGcj02Lng: 115.076,
            stubGroupCnt: null,
            pinYin: 'Hualong',
          },
          {
            id: '410922',
            text: '清丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8904,
            gisBd09Lng: 115.111,
            gisGcj02Lat: 35.8843,
            gisGcj02Lng: 115.105,
            stubGroupCnt: null,
            pinYin: 'Qingfeng',
          },
          {
            id: '410923',
            text: '南乐县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0743,
            gisBd09Lng: 115.211,
            gisGcj02Lat: 36.0679,
            gisGcj02Lng: 115.205,
            stubGroupCnt: null,
            pinYin: 'Nanle',
          },
          {
            id: '410926',
            text: '范　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8562,
            gisBd09Lng: 115.51,
            gisGcj02Lat: 35.8505,
            gisGcj02Lng: 115.503,
            stubGroupCnt: null,
            pinYin: 'Fanxian',
          },
          {
            id: '410927',
            text: '台前县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9739,
            gisBd09Lng: 115.878,
            gisGcj02Lat: 35.9681,
            gisGcj02Lng: 115.871,
            stubGroupCnt: null,
            pinYin: 'Taiqian',
          },
          {
            id: '410928',
            text: '濮阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.716,
            gisBd09Lng: 115.034,
            gisGcj02Lat: 35.7103,
            gisGcj02Lng: 115.028,
            stubGroupCnt: null,
            pinYin: 'Puyang',
          },
        ],
        gisBd09Lng: 115.035,
        text: '濮阳',
        gisBd09Lat: 35.7665,
        gisGcj02Lat: 35.7608,
      },
      {
        pinYin: 'Xuchang',
        id: '411000',
        gisGcj02Lng: 113.853,
        children: [
          {
            id: '411001',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0414,
            gisBd09Lng: 113.859,
            gisGcj02Lat: 34.0354,
            gisGcj02Lng: 113.853,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411002',
            text: '魏都区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0508,
            gisBd09Lng: 113.837,
            gisGcj02Lat: 34.0451,
            gisGcj02Lng: 113.83,
            stubGroupCnt: null,
            pinYin: 'Weidu',
          },
          {
            id: '411023',
            text: '许昌县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1315,
            gisBd09Lng: 113.829,
            gisGcj02Lat: 34.1258,
            gisGcj02Lng: 113.822,
            stubGroupCnt: null,
            pinYin: 'Xuchang',
          },
          {
            id: '411024',
            text: '鄢陵县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1068,
            gisBd09Lng: 114.184,
            gisGcj02Lat: 34.1011,
            gisGcj02Lng: 114.177,
            stubGroupCnt: null,
            pinYin: 'Yanling',
          },
          {
            id: '411025',
            text: '襄城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8564,
            gisBd09Lng: 113.513,
            gisGcj02Lat: 33.8501,
            gisGcj02Lng: 113.506,
            stubGroupCnt: null,
            pinYin: 'Xiangcheng',
          },
          {
            id: '411081',
            text: '禹州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1466,
            gisBd09Lng: 113.493,
            gisGcj02Lat: 34.1407,
            gisGcj02Lng: 113.487,
            stubGroupCnt: null,
            pinYin: 'Yuzhou',
          },
          {
            id: '411082',
            text: '长葛',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2231,
            gisBd09Lng: 113.774,
            gisGcj02Lat: 34.2167,
            gisGcj02Lng: 113.768,
            stubGroupCnt: null,
            pinYin: 'Changge',
          },
        ],
        gisBd09Lng: 113.859,
        text: '许昌',
        gisBd09Lat: 34.0414,
        gisGcj02Lat: 34.0354,
      },
      {
        pinYin: 'Luohe',
        id: '411100',
        gisGcj02Lng: 114.017,
        children: [
          {
            id: '411101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5871,
            gisBd09Lng: 114.023,
            gisGcj02Lat: 33.5809,
            gisGcj02Lng: 114.016,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411102',
            text: '源汇区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5512,
            gisBd09Lng: 113.926,
            gisGcj02Lat: 33.5453,
            gisGcj02Lng: 113.92,
            stubGroupCnt: null,
            pinYin: 'Yuanhui',
          },
          {
            id: '411103',
            text: '郾城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5929,
            gisBd09Lng: 114.013,
            gisGcj02Lat: 33.5866,
            gisGcj02Lng: 114.007,
            stubGroupCnt: null,
            pinYin: 'Yancheng',
          },
          {
            id: '411104',
            text: '召陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5918,
            gisBd09Lng: 114.1,
            gisGcj02Lat: 33.5857,
            gisGcj02Lng: 114.094,
            stubGroupCnt: null,
            pinYin: 'Zhaoling',
          },
          {
            id: '411121',
            text: '舞阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4429,
            gisBd09Lng: 113.615,
            gisGcj02Lat: 33.4369,
            gisGcj02Lng: 113.609,
            stubGroupCnt: null,
            pinYin: 'Wuyang',
          },
          {
            id: '411122',
            text: '临颍县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8152,
            gisBd09Lng: 113.939,
            gisGcj02Lat: 33.8096,
            gisGcj02Lng: 113.933,
            stubGroupCnt: null,
            pinYin: 'Linying',
          },
        ],
        gisBd09Lng: 114.023,
        text: '漯河',
        gisBd09Lat: 33.5871,
        gisGcj02Lat: 33.5809,
      },
      {
        pinYin: 'Sanmenxia',
        id: '411200',
        gisGcj02Lng: 111.2,
        children: [
          {
            id: '411201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7781,
            gisBd09Lng: 111.207,
            gisGcj02Lat: 34.7723,
            gisGcj02Lng: 111.2,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411202',
            text: '湖滨区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7753,
            gisBd09Lng: 111.194,
            gisGcj02Lat: 34.7696,
            gisGcj02Lng: 111.187,
            stubGroupCnt: null,
            pinYin: 'Hubin',
          },
          {
            id: '411221',
            text: '渑池县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7719,
            gisBd09Lng: 111.77,
            gisGcj02Lat: 34.7662,
            gisGcj02Lng: 111.763,
            stubGroupCnt: null,
            pinYin: 'Mianchi',
          },
          {
            id: '411222',
            text: '陕　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7266,
            gisBd09Lng: 111.113,
            gisGcj02Lat: 34.7204,
            gisGcj02Lng: 111.106,
            stubGroupCnt: null,
            pinYin: 'Shanxian',
          },
          {
            id: '411224',
            text: '卢氏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.059,
            gisBd09Lng: 111.054,
            gisGcj02Lat: 34.0533,
            gisGcj02Lng: 111.048,
            stubGroupCnt: null,
            pinYin: 'Lushi',
          },
          {
            id: '411281',
            text: '义马',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7526,
            gisBd09Lng: 111.882,
            gisGcj02Lat: 34.7467,
            gisGcj02Lng: 111.876,
            stubGroupCnt: null,
            pinYin: 'Yima',
          },
          {
            id: '411282',
            text: '灵宝',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5211,
            gisBd09Lng: 110.901,
            gisGcj02Lat: 34.5148,
            gisGcj02Lng: 110.894,
            stubGroupCnt: null,
            pinYin: 'Lingbao',
          },
        ],
        gisBd09Lng: 111.207,
        text: '三门峡',
        gisBd09Lat: 34.7781,
        gisGcj02Lat: 34.7723,
      },
      {
        pinYin: 'Nanyang',
        id: '411300',
        gisGcj02Lng: 112.53,
        children: [
          {
            id: '411301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9948,
            gisBd09Lng: 112.536,
            gisGcj02Lat: 32.9888,
            gisGcj02Lng: 112.53,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411302',
            text: '宛城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0077,
            gisBd09Lng: 112.545,
            gisGcj02Lat: 33.0016,
            gisGcj02Lng: 112.538,
            stubGroupCnt: null,
            pinYin: 'Wancheng',
          },
          {
            id: '411303',
            text: '卧龙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9922,
            gisBd09Lng: 112.542,
            gisGcj02Lat: 32.9861,
            gisGcj02Lng: 112.535,
            stubGroupCnt: null,
            pinYin: 'Wolong',
          },
          {
            id: '411321',
            text: '南召县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4952,
            gisBd09Lng: 112.435,
            gisGcj02Lat: 33.4889,
            gisGcj02Lng: 112.428,
            stubGroupCnt: null,
            pinYin: 'Nanzhao',
          },
          {
            id: '411322',
            text: '方城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2598,
            gisBd09Lng: 113.022,
            gisGcj02Lat: 33.2537,
            gisGcj02Lng: 113.015,
            stubGroupCnt: null,
            pinYin: 'Fangcheng',
          },
          {
            id: '411323',
            text: '西峡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3095,
            gisBd09Lng: 111.48,
            gisGcj02Lat: 33.3032,
            gisGcj02Lng: 111.474,
            stubGroupCnt: null,
            pinYin: 'Xixia',
          },
          {
            id: '411324',
            text: '镇平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0394,
            gisBd09Lng: 112.242,
            gisGcj02Lat: 33.0334,
            gisGcj02Lng: 112.235,
            stubGroupCnt: null,
            pinYin: 'Zhenping',
          },
          {
            id: '411325',
            text: '内乡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0509,
            gisBd09Lng: 111.857,
            gisGcj02Lat: 33.0447,
            gisGcj02Lng: 111.85,
            stubGroupCnt: null,
            pinYin: 'Neixiang',
          },
          {
            id: '411326',
            text: '淅川县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1444,
            gisBd09Lng: 111.496,
            gisGcj02Lat: 33.1381,
            gisGcj02Lng: 111.49,
            stubGroupCnt: null,
            pinYin: 'Xichuan',
          },
          {
            id: '411327',
            text: '社旗县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0605,
            gisBd09Lng: 112.953,
            gisGcj02Lat: 33.0544,
            gisGcj02Lng: 112.946,
            stubGroupCnt: null,
            pinYin: 'Sheqi',
          },
          {
            id: '411328',
            text: '唐河县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6876,
            gisBd09Lng: 112.813,
            gisGcj02Lat: 32.6813,
            gisGcj02Lng: 112.807,
            stubGroupCnt: null,
            pinYin: 'Tanghe',
          },
          {
            id: '411329',
            text: '新野县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5258,
            gisBd09Lng: 112.366,
            gisGcj02Lat: 32.5199,
            gisGcj02Lng: 112.359,
            stubGroupCnt: null,
            pinYin: 'Xinye',
          },
          {
            id: '411330',
            text: '桐柏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3838,
            gisBd09Lng: 113.435,
            gisGcj02Lat: 32.3778,
            gisGcj02Lng: 113.429,
            stubGroupCnt: null,
            pinYin: 'Tongbai',
          },
          {
            id: '411381',
            text: '邓州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6934,
            gisBd09Lng: 112.093,
            gisGcj02Lat: 32.6871,
            gisGcj02Lng: 112.086,
            stubGroupCnt: null,
            pinYin: 'Dengzhou',
          },
        ],
        gisBd09Lng: 112.536,
        text: '南阳',
        gisBd09Lat: 32.9948,
        gisGcj02Lat: 32.9888,
      },
      {
        pinYin: 'Shangqiu',
        id: '411400',
        gisGcj02Lng: 115.658,
        children: [
          {
            id: '411401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4198,
            gisBd09Lng: 115.664,
            gisGcj02Lat: 34.4137,
            gisGcj02Lng: 115.657,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411402',
            text: '梁园区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4495,
            gisBd09Lng: 115.621,
            gisGcj02Lat: 34.4438,
            gisGcj02Lng: 115.615,
            stubGroupCnt: null,
            pinYin: 'Liangyuan',
          },
          {
            id: '411403',
            text: '睢阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3929,
            gisBd09Lng: 115.66,
            gisGcj02Lat: 34.3869,
            gisGcj02Lng: 115.654,
            stubGroupCnt: null,
            pinYin: 'Suiyang',
          },
          {
            id: '411421',
            text: '民权县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6518,
            gisBd09Lng: 115.156,
            gisGcj02Lat: 34.6461,
            gisGcj02Lng: 115.149,
            stubGroupCnt: null,
            pinYin: 'Minquan',
          },
          {
            id: '411422',
            text: '睢　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4493,
            gisBd09Lng: 115.079,
            gisGcj02Lat: 34.443,
            gisGcj02Lng: 115.073,
            stubGroupCnt: null,
            pinYin: 'Suixian',
          },
          {
            id: '411423',
            text: '宁陵县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4598,
            gisBd09Lng: 115.312,
            gisGcj02Lat: 34.4536,
            gisGcj02Lng: 115.305,
            stubGroupCnt: null,
            pinYin: 'Ningling',
          },
          {
            id: '411424',
            text: '柘城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0968,
            gisBd09Lng: 115.311,
            gisGcj02Lat: 34.0906,
            gisGcj02Lng: 115.305,
            stubGroupCnt: null,
            pinYin: 'Zhecheng',
          },
          {
            id: '411425',
            text: '虞城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4034,
            gisBd09Lng: 115.873,
            gisGcj02Lat: 34.3977,
            gisGcj02Lng: 115.867,
            stubGroupCnt: null,
            pinYin: 'Yucheng',
          },
          {
            id: '411426',
            text: '夏邑县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2454,
            gisBd09Lng: 116.139,
            gisGcj02Lat: 34.2394,
            gisGcj02Lng: 116.132,
            stubGroupCnt: null,
            pinYin: 'Xiayi',
          },
          {
            id: '411481',
            text: '永城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9339,
            gisBd09Lng: 116.457,
            gisGcj02Lat: 33.9282,
            gisGcj02Lng: 116.45,
            stubGroupCnt: null,
            pinYin: 'Yongcheng',
          },
        ],
        gisBd09Lng: 115.664,
        text: '商丘',
        gisBd09Lat: 34.4198,
        gisGcj02Lat: 34.4137,
      },
      {
        pinYin: 'Xinyang',
        id: '411500',
        gisGcj02Lng: 114.093,
        children: [
          {
            id: '411501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.152,
            gisBd09Lng: 114.099,
            gisGcj02Lat: 32.1459,
            gisGcj02Lng: 114.093,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411502',
            text: '师河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1206,
            gisBd09Lng: 114.066,
            gisGcj02Lat: 32.115,
            gisGcj02Lng: 114.06,
            stubGroupCnt: null,
            pinYin: 'Shihe',
          },
          {
            id: '411503',
            text: '平桥区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1069,
            gisBd09Lng: 114.133,
            gisGcj02Lat: 32.1006,
            gisGcj02Lng: 114.127,
            stubGroupCnt: null,
            pinYin: 'Pingqiao',
          },
          {
            id: '411521',
            text: '罗山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2088,
            gisBd09Lng: 114.522,
            gisGcj02Lat: 32.2029,
            gisGcj02Lng: 114.516,
            stubGroupCnt: null,
            pinYin: 'Luoshan',
          },
          {
            id: '411522',
            text: '光山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.016,
            gisBd09Lng: 114.926,
            gisGcj02Lat: 32.0102,
            gisGcj02Lng: 114.92,
            stubGroupCnt: null,
            pinYin: 'Guangshan',
          },
          {
            id: '411523',
            text: '新　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.649,
            gisBd09Lng: 114.888,
            gisGcj02Lat: 31.6432,
            gisGcj02Lng: 114.881,
            stubGroupCnt: null,
            pinYin: 'Xinxian',
          },
          {
            id: '411524',
            text: '商城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8033,
            gisBd09Lng: 115.414,
            gisGcj02Lat: 31.7974,
            gisGcj02Lng: 115.407,
            stubGroupCnt: null,
            pinYin: 'Shangcheng',
          },
          {
            id: '411525',
            text: '固始县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.174,
            gisBd09Lng: 115.663,
            gisGcj02Lat: 32.1679,
            gisGcj02Lng: 115.657,
            stubGroupCnt: null,
            pinYin: 'Gushi',
          },
          {
            id: '411526',
            text: '潢川县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1381,
            gisBd09Lng: 115.061,
            gisGcj02Lat: 32.132,
            gisGcj02Lng: 115.055,
            stubGroupCnt: null,
            pinYin: 'Huangchuan',
          },
          {
            id: '411527',
            text: '淮滨县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4771,
            gisBd09Lng: 115.427,
            gisGcj02Lat: 32.4709,
            gisGcj02Lng: 115.421,
            stubGroupCnt: null,
            pinYin: 'Huaibin',
          },
          {
            id: '411528',
            text: '息　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3468,
            gisBd09Lng: 114.748,
            gisGcj02Lat: 32.3406,
            gisGcj02Lng: 114.742,
            stubGroupCnt: null,
            pinYin: 'Xixian',
          },
        ],
        gisBd09Lng: 114.099,
        text: '信阳',
        gisBd09Lat: 32.152,
        gisGcj02Lat: 32.146,
      },
      {
        pinYin: 'Zhoukou',
        id: '411600',
        gisGcj02Lng: 114.699,
        children: [
          {
            id: '411601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6314,
            gisBd09Lng: 114.703,
            gisGcj02Lat: 33.6253,
            gisGcj02Lng: 114.697,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411602',
            text: '川汇区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6473,
            gisBd09Lng: 114.659,
            gisGcj02Lat: 33.6416,
            gisGcj02Lng: 114.652,
            stubGroupCnt: null,
            pinYin: 'Chuanhui',
          },
          {
            id: '411621',
            text: '扶沟县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0645,
            gisBd09Lng: 114.402,
            gisGcj02Lat: 34.0586,
            gisGcj02Lng: 114.396,
            stubGroupCnt: null,
            pinYin: 'Fugou',
          },
          {
            id: '411622',
            text: '西华县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7723,
            gisBd09Lng: 114.537,
            gisGcj02Lat: 33.7666,
            gisGcj02Lng: 114.53,
            stubGroupCnt: null,
            pinYin: 'Xihua',
          },
          {
            id: '411623',
            text: '商水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5487,
            gisBd09Lng: 114.617,
            gisGcj02Lat: 33.5424,
            gisGcj02Lng: 114.611,
            stubGroupCnt: null,
            pinYin: 'Shangshui',
          },
          {
            id: '411624',
            text: '沈丘县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4149,
            gisBd09Lng: 115.106,
            gisGcj02Lat: 33.4087,
            gisGcj02Lng: 115.099,
            stubGroupCnt: null,
            pinYin: 'Shenqiu',
          },
          {
            id: '411625',
            text: '郸城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6503,
            gisBd09Lng: 115.185,
            gisGcj02Lat: 33.6441,
            gisGcj02Lng: 115.179,
            stubGroupCnt: null,
            pinYin: 'Dancheng',
          },
          {
            id: '411626',
            text: '淮阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7367,
            gisBd09Lng: 114.894,
            gisGcj02Lat: 33.731,
            gisGcj02Lng: 114.887,
            stubGroupCnt: null,
            pinYin: 'Huaiyang',
          },
          {
            id: '411627',
            text: '太康县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0698,
            gisBd09Lng: 114.844,
            gisGcj02Lat: 34.0635,
            gisGcj02Lng: 114.838,
            stubGroupCnt: null,
            pinYin: 'Taikang',
          },
          {
            id: '411628',
            text: '鹿邑县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8633,
            gisBd09Lng: 115.491,
            gisGcj02Lat: 33.8576,
            gisGcj02Lng: 115.485,
            stubGroupCnt: null,
            pinYin: 'Luyi',
          },
          {
            id: '411681',
            text: '项城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4731,
            gisBd09Lng: 114.883,
            gisGcj02Lat: 33.4672,
            gisGcj02Lng: 114.876,
            stubGroupCnt: null,
            pinYin: 'Xiangcheng',
          },
        ],
        gisBd09Lng: 114.705,
        text: '周口',
        gisBd09Lat: 33.6312,
        gisGcj02Lat: 33.625,
      },
      {
        pinYin: 'Zhumadian',
        id: '411700',
        gisGcj02Lng: 114.022,
        children: [
          {
            id: '411701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0183,
            gisBd09Lng: 114.028,
            gisGcj02Lat: 33.0121,
            gisGcj02Lng: 114.022,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '411702',
            text: '驿城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9795,
            gisBd09Lng: 114.0,
            gisGcj02Lat: 32.9732,
            gisGcj02Lng: 113.994,
            stubGroupCnt: null,
            pinYin: 'Yicheng',
          },
          {
            id: '411721',
            text: '西平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3943,
            gisBd09Lng: 114.029,
            gisGcj02Lat: 33.3881,
            gisGcj02Lng: 114.023,
            stubGroupCnt: null,
            pinYin: 'Xiping',
          },
          {
            id: '411722',
            text: '上蔡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2674,
            gisBd09Lng: 114.27,
            gisGcj02Lat: 33.2613,
            gisGcj02Lng: 114.263,
            stubGroupCnt: null,
            pinYin: 'Shangcai',
          },
          {
            id: '411723',
            text: '平舆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9676,
            gisBd09Lng: 114.626,
            gisGcj02Lat: 32.9614,
            gisGcj02Lng: 114.62,
            stubGroupCnt: null,
            pinYin: 'Pingyu',
          },
          {
            id: '411724',
            text: '正阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6106,
            gisBd09Lng: 114.396,
            gisGcj02Lat: 32.6046,
            gisGcj02Lng: 114.39,
            stubGroupCnt: null,
            pinYin: 'Zhengyang',
          },
          {
            id: '411725',
            text: '确山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8058,
            gisBd09Lng: 114.035,
            gisGcj02Lat: 32.7998,
            gisGcj02Lng: 114.028,
            stubGroupCnt: null,
            pinYin: 'Queshan',
          },
          {
            id: '411726',
            text: '泌阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7295,
            gisBd09Lng: 113.335,
            gisGcj02Lat: 32.7237,
            gisGcj02Lng: 113.328,
            stubGroupCnt: null,
            pinYin: 'Biyang',
          },
          {
            id: '411727',
            text: '汝南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0119,
            gisBd09Lng: 114.369,
            gisGcj02Lat: 33.0055,
            gisGcj02Lng: 114.363,
            stubGroupCnt: null,
            pinYin: 'Runan',
          },
          {
            id: '411728',
            text: '遂平县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1514,
            gisBd09Lng: 114.018,
            gisGcj02Lat: 33.1451,
            gisGcj02Lng: 114.012,
            stubGroupCnt: null,
            pinYin: 'Suiping',
          },
          {
            id: '411729',
            text: '新蔡县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7548,
            gisBd09Lng: 114.994,
            gisGcj02Lat: 32.7488,
            gisGcj02Lng: 114.987,
            stubGroupCnt: null,
            pinYin: 'Xincai',
          },
        ],
        gisBd09Lng: 114.028,
        text: '驻马店',
        gisBd09Lat: 33.0183,
        gisGcj02Lat: 33.0121,
      },
    ],
  },
  {
    text: '黑龙江省',
    children: [
      {
        pinYin: 'Harbin',
        id: '230100',
        gisGcj02Lng: 126.536,
        children: [
          {
            id: '230101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.8083,
            gisBd09Lng: 126.542,
            gisGcj02Lat: 45.8026,
            gisGcj02Lng: 126.536,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230102',
            text: '道里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7619,
            gisBd09Lng: 126.623,
            gisGcj02Lat: 45.7556,
            gisGcj02Lng: 126.617,
            stubGroupCnt: null,
            pinYin: 'Daoli',
          },
          {
            id: '230103',
            text: '南岗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7675,
            gisBd09Lng: 126.657,
            gisGcj02Lat: 45.7618,
            gisGcj02Lng: 126.651,
            stubGroupCnt: null,
            pinYin: 'Nangang',
          },
          {
            id: '230104',
            text: '道外区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7967,
            gisBd09Lng: 126.656,
            gisGcj02Lat: 45.791,
            gisGcj02Lng: 126.649,
            stubGroupCnt: null,
            pinYin: 'Daowai',
          },
          {
            id: '230106',
            text: '香坊区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.714,
            gisBd09Lng: 126.669,
            gisGcj02Lat: 45.7084,
            gisGcj02Lng: 126.663,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230107',
            text: '动力区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7275,
            gisBd09Lng: 126.653,
            gisGcj02Lat: 45.7218,
            gisGcj02Lng: 126.646,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230108',
            text: '平房区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.6037,
            gisBd09Lng: 126.644,
            gisGcj02Lat: 45.5979,
            gisGcj02Lng: 126.638,
            stubGroupCnt: null,
            pinYin: 'Pingfang',
          },
          {
            id: '230109',
            text: '松北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.8141,
            gisBd09Lng: 126.569,
            gisGcj02Lat: 45.8083,
            gisGcj02Lng: 126.563,
            stubGroupCnt: null,
            pinYin: 'Songbei',
          },
          {
            id: '230111',
            text: '呼兰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.8127,
            gisBd09Lng: 126.572,
            gisGcj02Lat: 45.8068,
            gisGcj02Lng: 126.565,
            stubGroupCnt: null,
            pinYin: 'Hulan',
          },
          {
            id: '230123',
            text: '依兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.3293,
            gisBd09Lng: 129.575,
            gisGcj02Lat: 46.3233,
            gisGcj02Lng: 129.569,
            stubGroupCnt: null,
            pinYin: 'Yilan',
          },
          {
            id: '230124',
            text: '方正县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.8579,
            gisBd09Lng: 128.835,
            gisGcj02Lat: 45.8522,
            gisGcj02Lng: 128.829,
            stubGroupCnt: null,
            pinYin: 'Fangzheng',
          },
          {
            id: '230125',
            text: '宾　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.763,
            gisBd09Lng: 127.491,
            gisGcj02Lat: 45.7572,
            gisGcj02Lng: 127.485,
            stubGroupCnt: null,
            pinYin: 'Binxian',
          },
          {
            id: '230126',
            text: '巴彦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.0906,
            gisBd09Lng: 127.411,
            gisGcj02Lat: 46.0847,
            gisGcj02Lng: 127.405,
            stubGroupCnt: null,
            pinYin: 'Bayan',
          },
          {
            id: '230127',
            text: '木兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.9573,
            gisBd09Lng: 128.049,
            gisGcj02Lat: 45.9509,
            gisGcj02Lng: 128.043,
            stubGroupCnt: null,
            pinYin: 'Mulan',
          },
          {
            id: '230128',
            text: '通河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.9969,
            gisBd09Lng: 128.754,
            gisGcj02Lat: 45.9906,
            gisGcj02Lng: 128.748,
            stubGroupCnt: null,
            pinYin: 'Tonghe',
          },
          {
            id: '230129',
            text: '延寿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.4569,
            gisBd09Lng: 128.338,
            gisGcj02Lat: 45.4513,
            gisGcj02Lng: 128.331,
            stubGroupCnt: null,
            pinYin: 'Yanshou',
          },
          {
            id: '230181',
            text: '阿城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.5475,
            gisBd09Lng: 126.982,
            gisGcj02Lat: 45.5413,
            gisGcj02Lng: 126.975,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230182',
            text: '双城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.387,
            gisBd09Lng: 126.319,
            gisGcj02Lat: 45.3813,
            gisGcj02Lng: 126.313,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230183',
            text: '尚志',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2165,
            gisBd09Lng: 127.967,
            gisGcj02Lat: 45.2107,
            gisGcj02Lng: 127.96,
            stubGroupCnt: null,
            pinYin: 'Shangzhi',
          },
          {
            id: '230184',
            text: '五常',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.9374,
            gisBd09Lng: 127.175,
            gisGcj02Lat: 44.9314,
            gisGcj02Lng: 127.168,
            stubGroupCnt: null,
            pinYin: 'Wuchang',
          },
        ],
        gisBd09Lng: 126.542,
        text: '哈尔滨',
        gisBd09Lat: 45.8083,
        gisGcj02Lat: 45.8026,
      },
      {
        pinYin: 'Qiqihar',
        id: '230200',
        gisGcj02Lng: 123.918,
        children: [
          {
            id: '230201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3604,
            gisBd09Lng: 123.925,
            gisGcj02Lat: 47.3546,
            gisGcj02Lng: 123.918,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230202',
            text: '龙沙区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.324,
            gisBd09Lng: 123.964,
            gisGcj02Lat: 47.3176,
            gisGcj02Lng: 123.958,
            stubGroupCnt: null,
            pinYin: 'Longsha',
          },
          {
            id: '230203',
            text: '建华区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3602,
            gisBd09Lng: 123.962,
            gisGcj02Lat: 47.3539,
            gisGcj02Lng: 123.955,
            stubGroupCnt: null,
            pinYin: 'Jianhua',
          },
          {
            id: '230204',
            text: '铁锋区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3465,
            gisBd09Lng: 123.984,
            gisGcj02Lat: 47.3403,
            gisGcj02Lng: 123.978,
            stubGroupCnt: null,
            pinYin: 'Tiefeng',
          },
          {
            id: '230205',
            text: '昂昂溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1604,
            gisBd09Lng: 123.827,
            gisGcj02Lat: 47.1542,
            gisGcj02Lng: 123.821,
            stubGroupCnt: null,
            pinYin: 'Angangxi',
          },
          {
            id: '230206',
            text: '富拉尔基区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.2143,
            gisBd09Lng: 123.635,
            gisGcj02Lat: 47.2083,
            gisGcj02Lng: 123.629,
            stubGroupCnt: null,
            pinYin: 'Fulaerji',
          },
          {
            id: '230207',
            text: '碾子山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.5228,
            gisBd09Lng: 122.894,
            gisGcj02Lat: 47.5164,
            gisGcj02Lng: 122.888,
            stubGroupCnt: null,
            pinYin: 'Nianzishan',
          },
          {
            id: '230208',
            text: '梅里斯达斡尔族区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3154,
            gisBd09Lng: 123.759,
            gisGcj02Lat: 47.3094,
            gisGcj02Lng: 123.753,
            stubGroupCnt: null,
            pinYin: 'Meilisi',
          },
          {
            id: '230221',
            text: '龙江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3432,
            gisBd09Lng: 123.211,
            gisGcj02Lat: 47.3373,
            gisGcj02Lng: 123.205,
            stubGroupCnt: null,
            pinYin: 'Longjiang',
          },
          {
            id: '230223',
            text: '依安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.8996,
            gisBd09Lng: 125.312,
            gisGcj02Lat: 47.8935,
            gisGcj02Lng: 125.306,
            stubGroupCnt: null,
            pinYin: "Yi'an",
          },
          {
            id: '230224',
            text: '泰来县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.399,
            gisBd09Lng: 123.423,
            gisGcj02Lat: 46.3933,
            gisGcj02Lng: 123.416,
            stubGroupCnt: null,
            pinYin: 'Tailai',
          },
          {
            id: '230225',
            text: '甘南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.9259,
            gisBd09Lng: 123.512,
            gisGcj02Lat: 47.9198,
            gisGcj02Lng: 123.506,
            stubGroupCnt: null,
            pinYin: 'Gannan',
          },
          {
            id: '230227',
            text: '富裕县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7807,
            gisBd09Lng: 124.481,
            gisGcj02Lat: 47.7748,
            gisGcj02Lng: 124.475,
            stubGroupCnt: null,
            pinYin: 'Fuyu',
          },
          {
            id: '230229',
            text: '克山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.0424,
            gisBd09Lng: 125.882,
            gisGcj02Lat: 48.036,
            gisGcj02Lng: 125.875,
            stubGroupCnt: null,
            pinYin: 'Keshan',
          },
          {
            id: '230230',
            text: '克东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.0483,
            gisBd09Lng: 126.256,
            gisGcj02Lat: 48.042,
            gisGcj02Lng: 126.25,
            stubGroupCnt: null,
            pinYin: 'Kedong',
          },
          {
            id: '230231',
            text: '拜泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.6018,
            gisBd09Lng: 126.107,
            gisGcj02Lat: 47.5956,
            gisGcj02Lng: 126.1,
            stubGroupCnt: null,
            pinYin: 'Baiquan',
          },
          {
            id: '230281',
            text: '讷河',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.6018,
            gisBd09Lng: 126.107,
            gisGcj02Lat: 47.5956,
            gisGcj02Lng: 126.1,
            stubGroupCnt: null,
            pinYin: 'Nehe',
          },
        ],
        gisBd09Lng: 123.925,
        text: '齐齐哈尔',
        gisBd09Lat: 47.3604,
        gisGcj02Lat: 47.3546,
      },
      {
        pinYin: 'Jixi',
        id: '230300',
        gisGcj02Lng: 130.97,
        children: [
          {
            id: '230301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.3011,
            gisBd09Lng: 130.977,
            gisGcj02Lat: 45.2955,
            gisGcj02Lng: 130.97,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230302',
            text: '鸡冠区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.3095,
            gisBd09Lng: 130.988,
            gisGcj02Lat: 45.3039,
            gisGcj02Lng: 130.981,
            stubGroupCnt: null,
            pinYin: 'Jiguan',
          },
          {
            id: '230303',
            text: '恒山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2165,
            gisBd09Lng: 130.912,
            gisGcj02Lat: 45.2102,
            gisGcj02Lng: 130.905,
            stubGroupCnt: null,
            pinYin: 'Hengshan',
          },
          {
            id: '230304',
            text: '滴道区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.3546,
            gisBd09Lng: 130.851,
            gisGcj02Lat: 45.3488,
            gisGcj02Lng: 130.844,
            stubGroupCnt: null,
            pinYin: 'Didao',
          },
          {
            id: '230305',
            text: '梨树区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.0976,
            gisBd09Lng: 130.704,
            gisGcj02Lat: 45.0913,
            gisGcj02Lng: 130.698,
            stubGroupCnt: null,
            pinYin: 'Lishu',
          },
          {
            id: '230306',
            text: '城子河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.3431,
            gisBd09Lng: 131.019,
            gisGcj02Lat: 45.3371,
            gisGcj02Lng: 131.013,
            stubGroupCnt: null,
            pinYin: 'Chengzihe',
          },
          {
            id: '230307',
            text: '麻山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2184,
            gisBd09Lng: 130.486,
            gisGcj02Lat: 45.2126,
            gisGcj02Lng: 130.48,
            stubGroupCnt: null,
            pinYin: 'Mashan',
          },
          {
            id: '230321',
            text: '鸡东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2662,
            gisBd09Lng: 131.134,
            gisGcj02Lat: 45.2603,
            gisGcj02Lng: 131.127,
            stubGroupCnt: null,
            pinYin: 'Jidong',
          },
          {
            id: '230381',
            text: '虎林',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7685,
            gisBd09Lng: 132.944,
            gisGcj02Lat: 45.7623,
            gisGcj02Lng: 132.938,
            stubGroupCnt: null,
            pinYin: 'Hulin',
          },
          {
            id: '230382',
            text: '密山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 45.7623,
            gisGcj02Lng: 132.938,
            stubGroupCnt: null,
            pinYin: 'Mishan',
          },
        ],
        gisBd09Lng: 130.977,
        text: '鸡西',
        gisBd09Lat: 45.3011,
        gisGcj02Lat: 45.2955,
      },
      {
        pinYin: 'Hegang',
        id: '230400',
        gisGcj02Lng: 130.299,
        children: [
          {
            id: '230401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3554,
            gisBd09Lng: 130.305,
            gisGcj02Lat: 47.3492,
            gisGcj02Lng: 130.299,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230402',
            text: '向阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3481,
            gisBd09Lng: 130.301,
            gisGcj02Lat: 47.342,
            gisGcj02Lng: 130.294,
            stubGroupCnt: null,
            pinYin: 'Xiangyang',
          },
          {
            id: '230403',
            text: '工农区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3237,
            gisBd09Lng: 130.281,
            gisGcj02Lat: 47.318,
            gisGcj02Lng: 130.275,
            stubGroupCnt: null,
            pinYin: 'Gongnong',
          },
          {
            id: '230404',
            text: '南山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3206,
            gisBd09Lng: 130.293,
            gisGcj02Lat: 47.3146,
            gisGcj02Lng: 130.286,
            stubGroupCnt: null,
            pinYin: 'Nanshan',
          },
          {
            id: '230405',
            text: '兴安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.258,
            gisBd09Lng: 130.244,
            gisGcj02Lat: 47.2521,
            gisGcj02Lng: 130.238,
            stubGroupCnt: null,
            pinYin: "Xing'an",
          },
          {
            id: '230406',
            text: '东山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3428,
            gisBd09Lng: 130.325,
            gisGcj02Lat: 47.3364,
            gisGcj02Lng: 130.319,
            stubGroupCnt: null,
            pinYin: 'Dongshan',
          },
          {
            id: '230407',
            text: '兴山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3906,
            gisBd09Lng: 130.34,
            gisGcj02Lat: 47.3843,
            gisGcj02Lng: 130.333,
            stubGroupCnt: null,
            pinYin: 'Xingshan',
          },
          {
            id: '230421',
            text: '萝北县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.5832,
            gisBd09Lng: 130.837,
            gisGcj02Lat: 47.5772,
            gisGcj02Lng: 130.83,
            stubGroupCnt: null,
            pinYin: 'Luobei',
          },
          {
            id: '230422',
            text: '绥滨县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.2938,
            gisBd09Lng: 131.861,
            gisGcj02Lat: 47.2877,
            gisGcj02Lng: 131.855,
            stubGroupCnt: null,
            pinYin: 'Suibin',
          },
        ],
        gisBd09Lng: 130.305,
        text: '鹤岗',
        gisBd09Lat: 47.3554,
        gisGcj02Lat: 47.3492,
      },
      {
        pinYin: 'Shuangyashan',
        id: '230500',
        gisGcj02Lng: 134.014,
        children: [
          {
            id: '230501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2042,
            gisBd09Lng: 128.373,
            gisGcj02Lat: 45.1983,
            gisGcj02Lng: 128.367,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230502',
            text: '尖山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.6529,
            gisBd09Lng: 131.166,
            gisGcj02Lat: 46.6465,
            gisGcj02Lng: 131.159,
            stubGroupCnt: null,
            pinYin: 'Jianshan',
          },
          {
            id: '230503',
            text: '岭东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5509,
            gisBd09Lng: 131.158,
            gisGcj02Lat: 46.5446,
            gisGcj02Lng: 131.152,
            stubGroupCnt: null,
            pinYin: 'Lingdong',
          },
          {
            id: '230505',
            text: '四方台区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5999,
            gisBd09Lng: 131.342,
            gisGcj02Lat: 46.5943,
            gisGcj02Lng: 131.336,
            stubGroupCnt: null,
            pinYin: 'Sifangtai',
          },
          {
            id: '230506',
            text: '宝山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5828,
            gisBd09Lng: 131.408,
            gisGcj02Lat: 46.5764,
            gisGcj02Lng: 131.402,
            stubGroupCnt: 0,
            pinYin: 'Baoshan',
          },
          {
            id: '230521',
            text: '集贤县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.7348,
            gisBd09Lng: 131.149,
            gisGcj02Lat: 46.7286,
            gisGcj02Lng: 131.142,
            stubGroupCnt: null,
            pinYin: 'Jixian',
          },
          {
            id: '230522',
            text: '友谊县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.7724,
            gisBd09Lng: 131.815,
            gisGcj02Lat: 46.7667,
            gisGcj02Lng: 131.809,
            stubGroupCnt: null,
            pinYin: 'Youyi',
          },
          {
            id: '230523',
            text: '宝清县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.3319,
            gisBd09Lng: 132.204,
            gisGcj02Lat: 46.3261,
            gisGcj02Lng: 132.197,
            stubGroupCnt: null,
            pinYin: 'Baoqing',
          },
          {
            id: '230524',
            text: '饶河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8039,
            gisBd09Lng: 134.02,
            gisGcj02Lat: 46.7978,
            gisGcj02Lng: 134.014,
            stubGroupCnt: null,
            pinYin: 'Raohe',
          },
        ],
        gisBd09Lng: null,
        text: '双鸭山',
        gisBd09Lat: null,
        gisGcj02Lat: 46.7978,
      },
      {
        pinYin: 'Daqing',
        id: '230600',
        gisGcj02Lng: 125.104,
        children: [
          {
            id: '230601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5929,
            gisBd09Lng: 125.11,
            gisGcj02Lat: 46.5873,
            gisGcj02Lng: 125.104,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230602',
            text: '萨尔图区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.6077,
            gisBd09Lng: 125.06,
            gisGcj02Lat: 46.6014,
            gisGcj02Lng: 125.054,
            stubGroupCnt: null,
            pinYin: 'Saertu',
          },
          {
            id: '230603',
            text: '龙凤区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5671,
            gisBd09Lng: 125.142,
            gisGcj02Lat: 46.561,
            gisGcj02Lng: 125.135,
            stubGroupCnt: null,
            pinYin: 'Longfeng',
          },
          {
            id: '230604',
            text: '让胡路区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.6569,
            gisBd09Lng: 124.878,
            gisGcj02Lat: 46.6513,
            gisGcj02Lng: 124.872,
            stubGroupCnt: null,
            pinYin: 'Ranghulu',
          },
          {
            id: '230605',
            text: '红岗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.4039,
            gisBd09Lng: 124.898,
            gisGcj02Lat: 46.3979,
            gisGcj02Lng: 124.892,
            stubGroupCnt: null,
            pinYin: 'Honggang',
          },
          {
            id: '230606',
            text: '大同区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.0442,
            gisBd09Lng: 124.818,
            gisGcj02Lat: 46.0378,
            gisGcj02Lng: 124.812,
            stubGroupCnt: null,
            pinYin: 'Datong',
          },
          {
            id: '230621',
            text: '肇州县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7044,
            gisBd09Lng: 125.276,
            gisGcj02Lat: 45.6981,
            gisGcj02Lng: 125.27,
            stubGroupCnt: null,
            pinYin: 'Zhaozhou',
          },
          {
            id: '230622',
            text: '肇源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.5226,
            gisBd09Lng: 125.085,
            gisGcj02Lat: 45.5168,
            gisGcj02Lng: 125.078,
            stubGroupCnt: null,
            pinYin: 'Zhaoyuan',
          },
          {
            id: '230623',
            text: '林甸县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1886,
            gisBd09Lng: 124.882,
            gisGcj02Lat: 47.1828,
            gisGcj02Lng: 124.875,
            stubGroupCnt: null,
            pinYin: 'Lindian',
          },
          {
            id: '230624',
            text: '杜尔伯特蒙古族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8687,
            gisBd09Lng: 124.449,
            gisGcj02Lat: 46.8623,
            gisGcj02Lng: 124.443,
            stubGroupCnt: null,
            pinYin: 'Duerbote',
          },
        ],
        gisBd09Lng: 125.11,
        text: '大庆',
        gisBd09Lat: 46.5929,
        gisGcj02Lat: 46.5873,
      },
      {
        pinYin: 'Yichun',
        id: '230700',
        gisGcj02Lng: 128.841,
        children: [
          {
            id: '230701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7331,
            gisBd09Lng: 128.848,
            gisGcj02Lat: 47.7273,
            gisGcj02Lng: 128.841,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230702',
            text: '伊春区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7338,
            gisBd09Lng: 128.914,
            gisGcj02Lat: 47.7278,
            gisGcj02Lng: 128.907,
            stubGroupCnt: null,
            pinYin: 'Yichun',
          },
          {
            id: '230703',
            text: '南岔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1434,
            gisBd09Lng: 129.29,
            gisGcj02Lat: 47.1376,
            gisGcj02Lng: 129.283,
            stubGroupCnt: null,
            pinYin: 'Nancha',
          },
          {
            id: '230704',
            text: '友好区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.8595,
            gisBd09Lng: 128.847,
            gisGcj02Lat: 47.8537,
            gisGcj02Lng: 128.84,
            stubGroupCnt: null,
            pinYin: 'Youhao',
          },
          {
            id: '230705',
            text: '西林区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.4864,
            gisBd09Lng: 129.319,
            gisGcj02Lat: 47.4807,
            gisGcj02Lng: 129.313,
            stubGroupCnt: null,
            pinYin: 'Xilin',
          },
          {
            id: '230706',
            text: '翠峦区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7317,
            gisBd09Lng: 128.675,
            gisGcj02Lat: 47.7257,
            gisGcj02Lng: 128.669,
            stubGroupCnt: null,
            pinYin: 'Cuiluan',
          },
          {
            id: '230707',
            text: '新青区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.2959,
            gisBd09Lng: 129.54,
            gisGcj02Lat: 48.2903,
            gisGcj02Lng: 129.533,
            stubGroupCnt: null,
            pinYin: 'Xinqing',
          },
          {
            id: '230708',
            text: '美溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.6412,
            gisBd09Lng: 129.136,
            gisGcj02Lat: 47.6348,
            gisGcj02Lng: 129.129,
            stubGroupCnt: null,
            pinYin: 'Meixi',
          },
          {
            id: '230709',
            text: '金山屯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.4188,
            gisBd09Lng: 129.435,
            gisGcj02Lat: 47.4131,
            gisGcj02Lng: 129.428,
            stubGroupCnt: null,
            pinYin: 'Jinshantun',
          },
          {
            id: '230710',
            text: '五营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.1145,
            gisBd09Lng: 129.251,
            gisGcj02Lat: 48.1082,
            gisGcj02Lng: 129.245,
            stubGroupCnt: null,
            pinYin: 'Wuying',
          },
          {
            id: '230711',
            text: '乌马河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7324,
            gisBd09Lng: 128.807,
            gisGcj02Lat: 47.7266,
            gisGcj02Lng: 128.8,
            stubGroupCnt: null,
            pinYin: 'Wumahe',
          },
          {
            id: '230712',
            text: '汤旺河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.4605,
            gisBd09Lng: 129.578,
            gisGcj02Lat: 48.4545,
            gisGcj02Lng: 129.571,
            stubGroupCnt: null,
            pinYin: 'Tangwanghe',
          },
          {
            id: '230713',
            text: '带岭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.0334,
            gisBd09Lng: 129.027,
            gisGcj02Lat: 47.0273,
            gisGcj02Lng: 129.021,
            stubGroupCnt: null,
            pinYin: 'Dailing',
          },
          {
            id: '230714',
            text: '乌伊岭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.596,
            gisBd09Lng: 129.445,
            gisGcj02Lat: 48.5902,
            gisGcj02Lng: 129.438,
            stubGroupCnt: null,
            pinYin: 'Wuyiling',
          },
          {
            id: '230715',
            text: '红星区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.2449,
            gisBd09Lng: 129.398,
            gisGcj02Lat: 48.239,
            gisGcj02Lng: 129.392,
            stubGroupCnt: null,
            pinYin: 'Hongxing',
          },
          {
            id: '230716',
            text: '上甘岭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.9804,
            gisBd09Lng: 129.031,
            gisGcj02Lat: 47.9743,
            gisGcj02Lng: 129.025,
            stubGroupCnt: null,
            pinYin: 'Shangganling',
          },
          {
            id: '230722',
            text: '嘉荫县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.895,
            gisBd09Lng: 130.411,
            gisGcj02Lat: 48.8891,
            gisGcj02Lng: 130.404,
            stubGroupCnt: null,
            pinYin: 'Jiayin',
          },
          {
            id: '230781',
            text: '铁力',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.9928,
            gisBd09Lng: 128.038,
            gisGcj02Lat: 46.9865,
            gisGcj02Lng: 128.032,
            stubGroupCnt: null,
            pinYin: 'Tieli',
          },
        ],
        gisBd09Lng: 128.848,
        text: '伊春',
        gisBd09Lat: 47.7331,
        gisGcj02Lat: 47.7273,
      },
      {
        pinYin: 'Jiamusi',
        id: '230800',
        gisGcj02Lng: 130.321,
        children: [
          {
            id: '230801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8055,
            gisBd09Lng: 130.328,
            gisGcj02Lat: 46.7991,
            gisGcj02Lng: 130.321,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230802',
            text: '永红区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8091,
            gisBd09Lng: 130.346,
            gisGcj02Lat: 46.8029,
            gisGcj02Lng: 130.34,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230803',
            text: '向阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.3481,
            gisBd09Lng: 130.302,
            gisGcj02Lat: 47.342,
            gisGcj02Lng: 130.296,
            stubGroupCnt: null,
            pinYin: 'Xiangyang',
          },
          {
            id: '230804',
            text: '前进区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8193,
            gisBd09Lng: 130.382,
            gisGcj02Lat: 46.8137,
            gisGcj02Lng: 130.375,
            stubGroupCnt: null,
            pinYin: 'Qianjin',
          },
          {
            id: '230805',
            text: '东风区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8283,
            gisBd09Lng: 130.41,
            gisGcj02Lat: 46.8224,
            gisGcj02Lng: 130.403,
            stubGroupCnt: null,
            pinYin: 'Dongfeng',
          },
          {
            id: '230811',
            text: '郊　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2296,
            gisBd09Lng: 113.118,
            gisGcj02Lat: 36.2239,
            gisGcj02Lng: 113.112,
            stubGroupCnt: null,
            pinYin: 'Jiaoqu',
          },
          {
            id: '230822',
            text: '桦南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.246,
            gisBd09Lng: 130.561,
            gisGcj02Lat: 46.2396,
            gisGcj02Lng: 130.555,
            stubGroupCnt: null,
            pinYin: 'Huanan',
          },
          {
            id: '230826',
            text: '桦川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.0276,
            gisBd09Lng: 130.727,
            gisGcj02Lat: 47.0219,
            gisGcj02Lng: 130.721,
            stubGroupCnt: null,
            pinYin: 'Huachuan',
          },
          {
            id: '230828',
            text: '汤原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.7374,
            gisBd09Lng: 129.914,
            gisGcj02Lat: 46.7317,
            gisGcj02Lng: 129.908,
            stubGroupCnt: null,
            pinYin: 'Tangyuan',
          },
          {
            id: '230833',
            text: '抚远县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.3705,
            gisBd09Lng: 134.316,
            gisGcj02Lat: 48.3644,
            gisGcj02Lng: 134.309,
            stubGroupCnt: null,
            pinYin: 'Fuyuan',
          },
          {
            id: '230881',
            text: '同江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.6485,
            gisBd09Lng: 132.52,
            gisGcj02Lat: 47.6426,
            gisGcj02Lng: 132.513,
            stubGroupCnt: null,
            pinYin: 'Tongjiang',
          },
          {
            id: '230882',
            text: '富锦',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.256,
            gisBd09Lng: 132.043,
            gisGcj02Lat: 47.2502,
            gisGcj02Lng: 132.037,
            stubGroupCnt: null,
            pinYin: 'Fujin',
          },
        ],
        gisBd09Lng: 130.328,
        text: '佳木斯',
        gisBd09Lat: 46.8055,
        gisGcj02Lat: 46.7991,
      },
      {
        pinYin: 'Qitaihe',
        id: '230900',
        gisGcj02Lng: 131.003,
        children: [
          {
            id: '230901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7763,
            gisBd09Lng: 131.01,
            gisGcj02Lat: 45.7704,
            gisGcj02Lng: 131.003,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '230902',
            text: '新兴区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.8225,
            gisBd09Lng: 130.939,
            gisGcj02Lat: 45.8162,
            gisGcj02Lng: 130.933,
            stubGroupCnt: null,
            pinYin: 'Xinxing',
          },
          {
            id: '230903',
            text: '桃山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7715,
            gisBd09Lng: 131.027,
            gisGcj02Lat: 45.7653,
            gisGcj02Lng: 131.02,
            stubGroupCnt: null,
            pinYin: 'Taoshan',
          },
          {
            id: '230904',
            text: '茄子河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7905,
            gisBd09Lng: 131.073,
            gisGcj02Lat: 45.7845,
            gisGcj02Lng: 131.067,
            stubGroupCnt: null,
            pinYin: 'Qiezihe',
          },
          {
            id: '230921',
            text: '勃利县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.7613,
            gisBd09Lng: 130.598,
            gisGcj02Lat: 45.7553,
            gisGcj02Lng: 130.592,
            stubGroupCnt: null,
            pinYin: 'Boli',
          },
        ],
        gisBd09Lng: 131.01,
        text: '七台河',
        gisBd09Lat: 45.7763,
        gisGcj02Lat: 45.7704,
      },
      {
        pinYin: 'Mudanjiang',
        id: '231000',
        gisGcj02Lng: 129.632,
        children: [
          {
            id: '231001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5581,
            gisBd09Lng: 129.639,
            gisGcj02Lat: 44.5521,
            gisGcj02Lng: 129.632,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '231002',
            text: '东安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.587,
            gisBd09Lng: 129.634,
            gisGcj02Lat: 44.5809,
            gisGcj02Lng: 129.627,
            stubGroupCnt: null,
            pinYin: "Dong'an",
          },
          {
            id: '231003',
            text: '阳明区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.6014,
            gisBd09Lng: 129.643,
            gisGcj02Lat: 44.5955,
            gisGcj02Lng: 129.636,
            stubGroupCnt: null,
            pinYin: 'Yangming',
          },
          {
            id: '231004',
            text: '爱民区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.6002,
            gisBd09Lng: 129.6,
            gisGcj02Lat: 44.5938,
            gisGcj02Lng: 129.593,
            stubGroupCnt: null,
            pinYin: 'Aimin',
          },
          {
            id: '231005',
            text: '西安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5826,
            gisBd09Lng: 129.623,
            gisGcj02Lat: 44.5764,
            gisGcj02Lng: 129.617,
            stubGroupCnt: null,
            pinYin: "Xi'an",
          },
          {
            id: '231024',
            text: '东宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0941,
            gisBd09Lng: 131.133,
            gisGcj02Lat: 44.0881,
            gisGcj02Lng: 131.127,
            stubGroupCnt: null,
            pinYin: 'Dongning',
          },
          {
            id: '231025',
            text: '林口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.2833,
            gisBd09Lng: 130.291,
            gisGcj02Lat: 45.2774,
            gisGcj02Lng: 130.285,
            stubGroupCnt: null,
            pinYin: 'Linkou',
          },
          {
            id: '231081',
            text: '绥芬河',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.4179,
            gisBd09Lng: 131.157,
            gisGcj02Lat: 44.4116,
            gisGcj02Lng: 131.15,
            stubGroupCnt: null,
            pinYin: 'Suifenhe',
          },
          {
            id: '231083',
            text: '海林',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5986,
            gisBd09Lng: 129.388,
            gisGcj02Lat: 44.5925,
            gisGcj02Lng: 129.382,
            stubGroupCnt: null,
            pinYin: 'Hailin',
          },
          {
            id: '231084',
            text: '宁安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3459,
            gisBd09Lng: 129.49,
            gisGcj02Lat: 44.3396,
            gisGcj02Lng: 129.483,
            stubGroupCnt: null,
            pinYin: "Ning'an",
          },
          {
            id: '231085',
            text: '穆棱',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.9236,
            gisBd09Lng: 130.533,
            gisGcj02Lat: 44.9177,
            gisGcj02Lng: 130.527,
            stubGroupCnt: null,
            pinYin: 'Muling',
          },
        ],
        gisBd09Lng: 129.639,
        text: '牡丹江',
        gisBd09Lat: 44.5581,
        gisGcj02Lat: 44.5521,
      },
      {
        pinYin: 'Heihe',
        id: '231100',
        gisGcj02Lng: 127.529,
        children: [
          {
            id: '231101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 50.2505,
            gisBd09Lng: 127.536,
            gisGcj02Lat: 50.2446,
            gisGcj02Lng: 127.529,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '231102',
            text: '爱辉区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 50.2573,
            gisBd09Lng: 127.508,
            gisGcj02Lat: 50.2517,
            gisGcj02Lng: 127.501,
            stubGroupCnt: null,
            pinYin: 'Aihui',
          },
          {
            id: '231121',
            text: '嫩江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.1911,
            gisBd09Lng: 125.229,
            gisGcj02Lat: 49.1855,
            gisGcj02Lng: 125.222,
            stubGroupCnt: null,
            pinYin: 'Nenjiang',
          },
          {
            id: '231123',
            text: '逊克县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.5689,
            gisBd09Lng: 128.486,
            gisGcj02Lat: 49.5631,
            gisGcj02Lng: 128.479,
            stubGroupCnt: null,
            pinYin: 'Xunke',
          },
          {
            id: '231124',
            text: '孙吴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.4314,
            gisBd09Lng: 127.343,
            gisGcj02Lat: 49.4252,
            gisGcj02Lng: 127.336,
            stubGroupCnt: null,
            pinYin: 'Sunwu',
          },
          {
            id: '231181',
            text: '北安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.2476,
            gisBd09Lng: 126.498,
            gisGcj02Lat: 48.2412,
            gisGcj02Lng: 126.491,
            stubGroupCnt: null,
            pinYin: "Bei'an",
          },
          {
            id: '231182',
            text: '五大连池',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.6597,
            gisBd09Lng: 126.161,
            gisGcj02Lat: 48.6538,
            gisGcj02Lng: 126.155,
            stubGroupCnt: null,
            pinYin: 'Wudalianchi',
          },
        ],
        gisBd09Lng: 127.536,
        text: '黑河',
        gisBd09Lat: 50.2505,
        gisGcj02Lat: 50.2446,
      },
      {
        pinYin: 'Suihua',
        id: '231200',
        gisGcj02Lng: 126.968,
        children: [
          {
            id: '231201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.6585,
            gisBd09Lng: 126.974,
            gisGcj02Lat: 46.6521,
            gisGcj02Lng: 126.968,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '231202',
            text: '北林区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 46.6521,
            gisGcj02Lng: 126.968,
            stubGroupCnt: null,
            pinYin: 'Beilin',
          },
          {
            id: '231221',
            text: '望奎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8376,
            gisBd09Lng: 126.493,
            gisGcj02Lat: 46.8312,
            gisGcj02Lng: 126.487,
            stubGroupCnt: null,
            pinYin: 'Wangkui',
          },
          {
            id: '231222',
            text: '兰西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.2578,
            gisBd09Lng: 126.295,
            gisGcj02Lat: 46.2521,
            gisGcj02Lng: 126.289,
            stubGroupCnt: null,
            pinYin: 'Lanxi',
          },
          {
            id: '231223',
            text: '青冈县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.696,
            gisBd09Lng: 126.123,
            gisGcj02Lat: 46.6896,
            gisGcj02Lng: 126.117,
            stubGroupCnt: null,
            pinYin: 'Qinggang',
          },
          {
            id: '231224',
            text: '庆安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.8849,
            gisBd09Lng: 127.512,
            gisGcj02Lat: 46.8792,
            gisGcj02Lng: 127.506,
            stubGroupCnt: null,
            pinYin: "Qing'an",
          },
          {
            id: '231225',
            text: '明水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1789,
            gisBd09Lng: 125.912,
            gisGcj02Lat: 47.1728,
            gisGcj02Lng: 125.906,
            stubGroupCnt: null,
            pinYin: 'Mingshui',
          },
          {
            id: '231226',
            text: '绥棱县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.2412,
            gisBd09Lng: 127.121,
            gisGcj02Lat: 47.2353,
            gisGcj02Lng: 127.114,
            stubGroupCnt: null,
            pinYin: 'Suileng',
          },
          {
            id: '231281',
            text: '安达',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.4227,
            gisBd09Lng: 125.351,
            gisGcj02Lat: 46.4171,
            gisGcj02Lng: 125.345,
            stubGroupCnt: null,
            pinYin: 'Anda',
          },
          {
            id: '231282',
            text: '肇东',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.0562,
            gisBd09Lng: 125.969,
            gisGcj02Lat: 46.0504,
            gisGcj02Lng: 125.962,
            stubGroupCnt: null,
            pinYin: 'Zhaodong',
          },
          {
            id: '231283',
            text: '海伦',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.4685,
            gisBd09Lng: 126.98,
            gisGcj02Lat: 47.4622,
            gisGcj02Lng: 126.974,
            stubGroupCnt: null,
            pinYin: 'Hailun',
          },
        ],
        gisBd09Lng: 126.974,
        text: '绥化',
        gisBd09Lat: 46.6585,
        gisGcj02Lat: 46.6521,
      },
      {
        pinYin: 'DaXingAnLing',
        id: '232700',
        gisGcj02Lng: 124.593,
        children: [
          {
            id: '232721',
            text: '呼玛县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 51.7313,
            gisBd09Lng: 126.661,
            gisGcj02Lat: 51.7257,
            gisGcj02Lng: 126.654,
            stubGroupCnt: null,
            pinYin: 'Huma',
          },
          {
            id: '232722',
            text: '塔河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 52.3393,
            gisBd09Lng: 124.718,
            gisGcj02Lat: 52.3333,
            gisGcj02Lng: 124.711,
            stubGroupCnt: null,
            pinYin: 'Tahe',
          },
          {
            id: '232723',
            text: '漠河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 52.978,
            gisBd09Lng: 122.545,
            gisGcj02Lat: 52.9718,
            gisGcj02Lng: 122.539,
            stubGroupCnt: null,
            pinYin: 'Mohe',
          },
        ],
        gisBd09Lng: 124.599,
        text: '大兴安岭地区',
        gisBd09Lat: 51.9291,
        gisGcj02Lat: 51.9232,
      },
    ],
  },
  {
    text: '湖北省',
    children: [
      {
        pinYin: 'Wuhan',
        id: '420100',
        gisGcj02Lng: 114.306,
        children: [
          {
            id: '420101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5981,
            gisBd09Lng: 114.312,
            gisGcj02Lat: 30.5924,
            gisGcj02Lng: 114.306,
            stubGroupCnt: 2,
            pinYin: null,
          },
          {
            id: '420102',
            text: '江岸区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.606,
            gisBd09Lng: 114.316,
            gisGcj02Lat: 30.6003,
            gisGcj02Lng: 114.31,
            stubGroupCnt: 7,
            pinYin: "Jiang'an",
          },
          {
            id: '420103',
            text: '江汉区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6073,
            gisBd09Lng: 114.278,
            gisGcj02Lat: 30.6014,
            gisGcj02Lng: 114.272,
            stubGroupCnt: 4,
            pinYin: 'Jianghan',
          },
          {
            id: '420104',
            text: '乔口区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5872,
            gisBd09Lng: 114.222,
            gisGcj02Lat: 30.5811,
            gisGcj02Lng: 114.215,
            stubGroupCnt: 0,
            pinYin: 'Qiaokou',
          },
          {
            id: '420105',
            text: '汉阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5614,
            gisBd09Lng: 114.225,
            gisGcj02Lat: 30.5552,
            gisGcj02Lng: 114.218,
            stubGroupCnt: 4,
            pinYin: 'Hanyang',
          },
          {
            id: '420106',
            text: '武昌区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5597,
            gisBd09Lng: 114.323,
            gisGcj02Lat: 30.5539,
            gisGcj02Lng: 114.316,
            stubGroupCnt: 23,
            pinYin: 'Wuchang',
          },
          {
            id: '420107',
            text: '青山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6504,
            gisBd09Lng: 114.392,
            gisGcj02Lat: 30.6443,
            gisGcj02Lng: 114.385,
            stubGroupCnt: 4,
            pinYin: 'Qingshan',
          },
          {
            id: '420111',
            text: '洪山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5062,
            gisBd09Lng: 114.351,
            gisGcj02Lat: 30.4999,
            gisGcj02Lng: 114.344,
            stubGroupCnt: 14,
            pinYin: 'Hongshan',
          },
          {
            id: '420112',
            text: '东西湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6268,
            gisBd09Lng: 114.144,
            gisGcj02Lat: 30.6206,
            gisGcj02Lng: 114.137,
            stubGroupCnt: 0,
            pinYin: 'Dongxihu',
          },
          {
            id: '420113',
            text: '汉南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3142,
            gisBd09Lng: 114.092,
            gisGcj02Lat: 30.3083,
            gisGcj02Lng: 114.085,
            stubGroupCnt: null,
            pinYin: 'Hannan',
          },
          {
            id: '420114',
            text: '蔡甸区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5888,
            gisBd09Lng: 114.036,
            gisGcj02Lat: 30.5828,
            gisGcj02Lng: 114.03,
            stubGroupCnt: 0,
            pinYin: 'Caidian',
          },
          {
            id: '420115',
            text: '江夏区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3828,
            gisBd09Lng: 114.33,
            gisGcj02Lat: 30.3769,
            gisGcj02Lng: 114.323,
            stubGroupCnt: 0,
            pinYin: 'Jiangxia',
          },
          {
            id: '420116',
            text: '黄陂区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8878,
            gisBd09Lng: 114.382,
            gisGcj02Lat: 30.8816,
            gisGcj02Lng: 114.375,
            stubGroupCnt: null,
            pinYin: 'Huangpi',
          },
          {
            id: '420117',
            text: '新洲区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8479,
            gisBd09Lng: 114.808,
            gisGcj02Lat: 30.842,
            gisGcj02Lng: 114.802,
            stubGroupCnt: null,
            pinYin: 'Xinzhou',
          },
        ],
        gisBd09Lng: 114.312,
        text: '武汉',
        gisBd09Lat: 30.5981,
        gisGcj02Lat: 30.5924,
      },
      {
        pinYin: 'Huangshi',
        id: '420200',
        gisGcj02Lng: 115.038,
        children: [
          {
            id: '420201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2055,
            gisBd09Lng: 115.045,
            gisGcj02Lat: 30.1997,
            gisGcj02Lng: 115.038,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420202',
            text: '黄石港区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2282,
            gisBd09Lng: 115.074,
            gisGcj02Lat: 30.222,
            gisGcj02Lng: 115.067,
            stubGroupCnt: null,
            pinYin: 'Huangshigang',
          },
          {
            id: '420203',
            text: '西塞山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2095,
            gisBd09Lng: 115.119,
            gisGcj02Lat: 30.2036,
            gisGcj02Lng: 115.113,
            stubGroupCnt: null,
            pinYin: 'Xisaishan',
          },
          {
            id: '420204',
            text: '下陆区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1782,
            gisBd09Lng: 114.968,
            gisGcj02Lat: 30.1718,
            gisGcj02Lng: 114.962,
            stubGroupCnt: null,
            pinYin: 'Xialu',
          },
          {
            id: '420205',
            text: '铁山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2127,
            gisBd09Lng: 114.909,
            gisGcj02Lat: 30.207,
            gisGcj02Lng: 114.903,
            stubGroupCnt: null,
            pinYin: 'Tieshan',
          },
          {
            id: '420222',
            text: '阳新县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8347,
            gisBd09Lng: 115.221,
            gisGcj02Lat: 29.8285,
            gisGcj02Lng: 115.214,
            stubGroupCnt: null,
            pinYin: 'Yangxin',
          },
          {
            id: '420281',
            text: '大冶',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1004,
            gisBd09Lng: 114.988,
            gisGcj02Lat: 30.0943,
            gisGcj02Lng: 114.982,
            stubGroupCnt: null,
            pinYin: 'Daye',
          },
        ],
        gisBd09Lng: 115.045,
        text: '黄石',
        gisBd09Lat: 30.2055,
        gisGcj02Lat: 30.1997,
      },
      {
        pinYin: 'Shiyan',
        id: '420300',
        gisGcj02Lng: 110.798,
        children: [
          {
            id: '420301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6339,
            gisBd09Lng: 110.805,
            gisGcj02Lat: 32.6281,
            gisGcj02Lng: 110.798,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420302',
            text: '茅箭区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5972,
            gisBd09Lng: 110.82,
            gisGcj02Lat: 32.5915,
            gisGcj02Lng: 110.813,
            stubGroupCnt: null,
            pinYin: 'Maojian',
          },
          {
            id: '420303',
            text: '张湾区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6559,
            gisBd09Lng: 110.776,
            gisGcj02Lat: 32.6496,
            gisGcj02Lng: 110.77,
            stubGroupCnt: null,
            pinYin: 'Zhangwan',
          },
          {
            id: '420321',
            text: '郧　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8645,
            gisBd09Lng: 110.738,
            gisGcj02Lat: 32.8584,
            gisGcj02Lng: 110.732,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420322',
            text: '郧西县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9967,
            gisBd09Lng: 110.431,
            gisGcj02Lat: 32.9906,
            gisGcj02Lng: 110.425,
            stubGroupCnt: null,
            pinYin: 'Yunxi',
          },
          {
            id: '420323',
            text: '竹山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.229,
            gisBd09Lng: 110.235,
            gisGcj02Lat: 32.2233,
            gisGcj02Lng: 110.228,
            stubGroupCnt: null,
            pinYin: 'Zhushan',
          },
          {
            id: '420324',
            text: '竹溪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3234,
            gisBd09Lng: 109.719,
            gisGcj02Lat: 32.3175,
            gisGcj02Lng: 109.713,
            stubGroupCnt: null,
            pinYin: 'Zhuxi',
          },
          {
            id: '420325',
            text: '房　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0612,
            gisBd09Lng: 110.748,
            gisGcj02Lat: 32.055,
            gisGcj02Lng: 110.741,
            stubGroupCnt: null,
            pinYin: 'Fangxian',
          },
          {
            id: '420381',
            text: '丹江口',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5445,
            gisBd09Lng: 111.519,
            gisGcj02Lat: 32.5386,
            gisGcj02Lng: 111.512,
            stubGroupCnt: null,
            pinYin: 'Danjiangkou',
          },
        ],
        gisBd09Lng: 110.805,
        text: '十堰',
        gisBd09Lat: 32.6339,
        gisGcj02Lat: 32.6281,
      },
      {
        pinYin: 'Yichang',
        id: '420500',
        gisGcj02Lng: 111.287,
        children: [
          {
            id: '420501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6967,
            gisBd09Lng: 111.294,
            gisGcj02Lat: 30.691,
            gisGcj02Lng: 111.287,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420502',
            text: '西陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7161,
            gisBd09Lng: 111.293,
            gisGcj02Lat: 30.7103,
            gisGcj02Lng: 111.286,
            stubGroupCnt: null,
            pinYin: 'Xiling',
          },
          {
            id: '420503',
            text: '伍家岗区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6504,
            gisBd09Lng: 111.368,
            gisGcj02Lat: 30.6441,
            gisGcj02Lng: 111.361,
            stubGroupCnt: null,
            pinYin: 'Wujiagang',
          },
          {
            id: '420504',
            text: '点军区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6991,
            gisBd09Lng: 111.275,
            gisGcj02Lat: 30.6931,
            gisGcj02Lng: 111.268,
            stubGroupCnt: null,
            pinYin: 'Dianjun',
          },
          {
            id: '420505',
            text: '猇亭区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5359,
            gisBd09Lng: 111.442,
            gisGcj02Lat: 30.5301,
            gisGcj02Lng: 111.436,
            stubGroupCnt: null,
            pinYin: 'Xiaoting',
          },
          {
            id: '420506',
            text: '夷陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7751,
            gisBd09Lng: 111.332,
            gisGcj02Lat: 30.7691,
            gisGcj02Lng: 111.325,
            stubGroupCnt: null,
            pinYin: 'Yiling',
          },
          {
            id: '420525',
            text: '远安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0659,
            gisBd09Lng: 111.647,
            gisGcj02Lat: 31.0601,
            gisGcj02Lng: 111.64,
            stubGroupCnt: null,
            pinYin: "Yuan'an",
          },
          {
            id: '420526',
            text: '兴山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3538,
            gisBd09Lng: 110.753,
            gisGcj02Lat: 31.3475,
            gisGcj02Lng: 110.747,
            stubGroupCnt: null,
            pinYin: 'Xingshan',
          },
          {
            id: '420527',
            text: '秭归县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8316,
            gisBd09Lng: 110.983,
            gisGcj02Lat: 30.8255,
            gisGcj02Lng: 110.977,
            stubGroupCnt: null,
            pinYin: 'Zigui',
          },
          {
            id: '420528',
            text: '长阳土家族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4781,
            gisBd09Lng: 111.214,
            gisGcj02Lat: 30.4721,
            gisGcj02Lng: 111.207,
            stubGroupCnt: null,
            pinYin: 'Changyang',
          },
          {
            id: '420529',
            text: '五峰土家族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2057,
            gisBd09Lng: 110.681,
            gisGcj02Lat: 30.1998,
            gisGcj02Lng: 110.675,
            stubGroupCnt: null,
            pinYin: 'Wufeng',
          },
          {
            id: '420581',
            text: '宜都',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3838,
            gisBd09Lng: 111.456,
            gisGcj02Lat: 30.3778,
            gisGcj02Lng: 111.45,
            stubGroupCnt: null,
            pinYin: 'Yidu',
          },
          {
            id: '420582',
            text: '当阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8253,
            gisBd09Lng: 111.794,
            gisGcj02Lat: 30.8196,
            gisGcj02Lng: 111.787,
            stubGroupCnt: null,
            pinYin: 'Dangyang',
          },
          {
            id: '420583',
            text: '枝江',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4303,
            gisBd09Lng: 111.767,
            gisGcj02Lat: 30.4245,
            gisGcj02Lng: 111.76,
            stubGroupCnt: null,
            pinYin: 'Zhijiang',
          },
        ],
        gisBd09Lng: 111.294,
        text: '宜昌',
        gisBd09Lat: 30.6967,
        gisGcj02Lat: 30.691,
      },
      {
        pinYin: 'Xiangyang',
        id: '420600',
        gisGcj02Lng: 112.123,
        children: [
          {
            id: '420601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0133,
            gisBd09Lng: 112.129,
            gisGcj02Lat: 32.0075,
            gisGcj02Lng: 112.123,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '420602',
            text: '襄城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0149,
            gisBd09Lng: 112.141,
            gisGcj02Lat: 32.0092,
            gisGcj02Lng: 112.134,
            stubGroupCnt: null,
            pinYin: 'Xiangcheng',
          },
          {
            id: '420606',
            text: '樊城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0494,
            gisBd09Lng: 112.143,
            gisGcj02Lat: 32.0438,
            gisGcj02Lng: 112.136,
            stubGroupCnt: 0,
            pinYin: 'Fancheng',
          },
          {
            id: '420607',
            text: '襄阳区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2283,
            gisBd09Lng: 112.25,
            gisGcj02Lat: 32.2225,
            gisGcj02Lng: 112.244,
            stubGroupCnt: 0,
            pinYin: 'Xiangzhou',
          },
          {
            id: '420624',
            text: '南漳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.779,
            gisBd09Lng: 111.844,
            gisGcj02Lat: 31.7726,
            gisGcj02Lng: 111.838,
            stubGroupCnt: null,
            pinYin: 'Nanzhang',
          },
          {
            id: '420625',
            text: '谷城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2674,
            gisBd09Lng: 111.661,
            gisGcj02Lat: 32.2617,
            gisGcj02Lng: 111.655,
            stubGroupCnt: null,
            pinYin: 'Gucheng',
          },
          {
            id: '420626',
            text: '保康县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.881,
            gisBd09Lng: 111.269,
            gisGcj02Lat: 31.8749,
            gisGcj02Lng: 111.263,
            stubGroupCnt: null,
            pinYin: 'Baokang',
          },
          {
            id: '420682',
            text: '老河口',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.392,
            gisBd09Lng: 111.681,
            gisGcj02Lat: 32.3863,
            gisGcj02Lng: 111.675,
            stubGroupCnt: null,
            pinYin: 'Laohekou',
          },
          {
            id: '420683',
            text: '枣阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1328,
            gisBd09Lng: 112.78,
            gisGcj02Lat: 32.1268,
            gisGcj02Lng: 112.773,
            stubGroupCnt: null,
            pinYin: 'Zaoyang',
          },
          {
            id: '420684',
            text: '宜城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7229,
            gisBd09Lng: 112.266,
            gisGcj02Lat: 31.7172,
            gisGcj02Lng: 112.26,
            stubGroupCnt: null,
            pinYin: 'Yicheng',
          },
        ],
        gisBd09Lng: 112.129,
        text: '襄樊',
        gisBd09Lat: 32.0133,
        gisGcj02Lat: 32.0075,
      },
      {
        pinYin: 'Ezhou',
        id: '420700',
        gisGcj02Lng: 114.895,
        children: [
          {
            id: '420701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3963,
            gisBd09Lng: 114.902,
            gisGcj02Lat: 30.3906,
            gisGcj02Lng: 114.895,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420702',
            text: '梁子湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1056,
            gisBd09Lng: 114.691,
            gisGcj02Lat: 30.0997,
            gisGcj02Lng: 114.685,
            stubGroupCnt: null,
            pinYin: 'Liangzihu',
          },
          {
            id: '420703',
            text: '华容区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5411,
            gisBd09Lng: 114.736,
            gisGcj02Lat: 30.5348,
            gisGcj02Lng: 114.729,
            stubGroupCnt: null,
            pinYin: 'Huarong',
          },
          {
            id: '420704',
            text: '鄂城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4059,
            gisBd09Lng: 114.899,
            gisGcj02Lat: 30.4002,
            gisGcj02Lng: 114.892,
            stubGroupCnt: null,
            pinYin: 'Echeng',
          },
        ],
        gisBd09Lng: 114.902,
        text: '鄂州',
        gisBd09Lat: 30.3963,
        gisGcj02Lat: 30.3906,
      },
      {
        pinYin: 'Jingmen',
        id: '420800',
        gisGcj02Lng: 112.199,
        children: [
          {
            id: '420801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0411,
            gisBd09Lng: 112.206,
            gisGcj02Lat: 31.0348,
            gisGcj02Lng: 112.199,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420802',
            text: '东宝区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0576,
            gisBd09Lng: 112.208,
            gisGcj02Lat: 31.0513,
            gisGcj02Lng: 112.202,
            stubGroupCnt: null,
            pinYin: 'Dongbao',
          },
          {
            id: '420804',
            text: '掇刀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9784,
            gisBd09Lng: 112.214,
            gisGcj02Lat: 30.972,
            gisGcj02Lng: 112.207,
            stubGroupCnt: null,
            pinYin: 'Duodao',
          },
          {
            id: '420821',
            text: '京山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0242,
            gisBd09Lng: 113.126,
            gisGcj02Lat: 31.0184,
            gisGcj02Lng: 113.12,
            stubGroupCnt: null,
            pinYin: 'Jingshan',
          },
          {
            id: '420822',
            text: '沙洋县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7138,
            gisBd09Lng: 112.593,
            gisGcj02Lat: 30.7078,
            gisGcj02Lng: 112.586,
            stubGroupCnt: null,
            pinYin: 'Shayang',
          },
          {
            id: '420881',
            text: '钟祥',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1733,
            gisBd09Lng: 112.595,
            gisGcj02Lat: 31.1672,
            gisGcj02Lng: 112.588,
            stubGroupCnt: null,
            pinYin: 'Zhongxiang',
          },
        ],
        gisBd09Lng: 112.206,
        text: '荆门',
        gisBd09Lat: 31.0411,
        gisGcj02Lat: 31.0348,
      },
      {
        pinYin: 'Xiaogan',
        id: '420900',
        gisGcj02Lng: 113.916,
        children: [
          {
            id: '420901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9307,
            gisBd09Lng: 113.923,
            gisGcj02Lat: 30.9248,
            gisGcj02Lng: 113.916,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '420902',
            text: '孝南区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9224,
            gisBd09Lng: 113.916,
            gisGcj02Lat: 30.9164,
            gisGcj02Lng: 113.91,
            stubGroupCnt: null,
            pinYin: 'Xiaonan',
          },
          {
            id: '420921',
            text: '孝昌县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2634,
            gisBd09Lng: 114.004,
            gisGcj02Lat: 31.257,
            gisGcj02Lng: 113.997,
            stubGroupCnt: null,
            pinYin: 'Xiaochang',
          },
          {
            id: '420922',
            text: '大悟县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5668,
            gisBd09Lng: 114.133,
            gisGcj02Lat: 31.5605,
            gisGcj02Lng: 114.127,
            stubGroupCnt: null,
            pinYin: 'Dawu',
          },
          {
            id: '420923',
            text: '云梦县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0264,
            gisBd09Lng: 113.759,
            gisGcj02Lat: 31.0201,
            gisGcj02Lng: 113.753,
            stubGroupCnt: null,
            pinYin: 'Yunmeng',
          },
          {
            id: '420981',
            text: '应城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9281,
            gisBd09Lng: 113.58,
            gisGcj02Lat: 30.9224,
            gisGcj02Lng: 113.573,
            stubGroupCnt: null,
            pinYin: 'Yingcheng',
          },
          {
            id: '420982',
            text: '安陆',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2607,
            gisBd09Lng: 113.696,
            gisGcj02Lat: 31.255,
            gisGcj02Lng: 113.689,
            stubGroupCnt: null,
            pinYin: 'Anlu',
          },
          {
            id: '420984',
            text: '汉川',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6662,
            gisBd09Lng: 113.845,
            gisGcj02Lat: 30.6604,
            gisGcj02Lng: 113.839,
            stubGroupCnt: null,
            pinYin: 'Hanchuan',
          },
        ],
        gisBd09Lng: 113.923,
        text: '孝感',
        gisBd09Lat: 30.9307,
        gisGcj02Lat: 30.9248,
      },
      {
        pinYin: 'Jingzhou',
        id: '421000',
        gisGcj02Lng: 112.242,
        children: [
          {
            id: '421001',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3376,
            gisBd09Lng: 112.248,
            gisGcj02Lat: 30.3318,
            gisGcj02Lng: 112.242,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '421002',
            text: '沙市区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3163,
            gisBd09Lng: 112.262,
            gisGcj02Lat: 30.3106,
            gisGcj02Lng: 112.255,
            stubGroupCnt: null,
            pinYin: 'Shashi',
          },
          {
            id: '421003',
            text: '荆州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3572,
            gisBd09Lng: 112.195,
            gisGcj02Lat: 30.351,
            gisGcj02Lng: 112.189,
            stubGroupCnt: null,
            pinYin: 'Jingzhou',
          },
          {
            id: '421022',
            text: '公安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0638,
            gisBd09Lng: 112.236,
            gisGcj02Lat: 30.0578,
            gisGcj02Lng: 112.229,
            stubGroupCnt: null,
            pinYin: "Gong'an",
          },
          {
            id: '421023',
            text: '监利县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.843,
            gisBd09Lng: 112.911,
            gisGcj02Lat: 29.8368,
            gisGcj02Lng: 112.905,
            stubGroupCnt: null,
            pinYin: 'Jianli',
          },
          {
            id: '421024',
            text: '江陵县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0467,
            gisBd09Lng: 112.43,
            gisGcj02Lat: 30.0405,
            gisGcj02Lng: 112.424,
            stubGroupCnt: null,
            pinYin: 'Jiangling',
          },
          {
            id: '421081',
            text: '石首',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7269,
            gisBd09Lng: 112.432,
            gisGcj02Lat: 29.7206,
            gisGcj02Lng: 112.426,
            stubGroupCnt: null,
            pinYin: 'Shishou',
          },
          {
            id: '421083',
            text: '洪湖',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8327,
            gisBd09Lng: 113.484,
            gisGcj02Lat: 29.8269,
            gisGcj02Lng: 113.478,
            stubGroupCnt: null,
            pinYin: 'Honghu',
          },
          {
            id: '421087',
            text: '松滋',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.175,
            gisBd09Lng: 111.774,
            gisGcj02Lat: 30.1692,
            gisGcj02Lng: 111.768,
            stubGroupCnt: null,
            pinYin: 'Songzi',
          },
        ],
        gisBd09Lng: 112.248,
        text: '荆州',
        gisBd09Lat: 30.3376,
        gisGcj02Lat: 30.3318,
      },
      {
        pinYin: 'Huanggang',
        id: '421100',
        gisGcj02Lng: 114.872,
        children: [
          {
            id: '421101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4593,
            gisBd09Lng: 114.879,
            gisGcj02Lat: 30.4533,
            gisGcj02Lng: 114.872,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '421102',
            text: '黄州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4402,
            gisBd09Lng: 114.886,
            gisGcj02Lat: 30.4343,
            gisGcj02Lng: 114.88,
            stubGroupCnt: null,
            pinYin: 'Huangzhou',
          },
          {
            id: '421121',
            text: '团风县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6496,
            gisBd09Lng: 114.879,
            gisGcj02Lat: 30.6437,
            gisGcj02Lng: 114.873,
            stubGroupCnt: null,
            pinYin: 'Tuanfeng',
          },
          {
            id: '421122',
            text: '红安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2939,
            gisBd09Lng: 114.625,
            gisGcj02Lat: 31.2877,
            gisGcj02Lng: 114.618,
            stubGroupCnt: null,
            pinYin: "Hong'an",
          },
          {
            id: '421123',
            text: '罗田县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7895,
            gisBd09Lng: 115.406,
            gisGcj02Lat: 30.7837,
            gisGcj02Lng: 115.4,
            stubGroupCnt: null,
            pinYin: 'Luotian',
          },
          {
            id: '421124',
            text: '英山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7409,
            gisBd09Lng: 115.688,
            gisGcj02Lat: 30.7346,
            gisGcj02Lng: 115.681,
            stubGroupCnt: null,
            pinYin: 'Yingshan',
          },
          {
            id: '421125',
            text: '浠水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.457,
            gisBd09Lng: 115.272,
            gisGcj02Lat: 30.4513,
            gisGcj02Lng: 115.266,
            stubGroupCnt: null,
            pinYin: 'Xishui',
          },
          {
            id: '421126',
            text: '蕲春县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2321,
            gisBd09Lng: 115.443,
            gisGcj02Lat: 30.2257,
            gisGcj02Lng: 115.436,
            stubGroupCnt: null,
            pinYin: 'Qichun',
          },
          {
            id: '421127',
            text: '黄梅县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0763,
            gisBd09Lng: 115.951,
            gisGcj02Lat: 30.0702,
            gisGcj02Lng: 115.944,
            stubGroupCnt: null,
            pinYin: 'Huangmei',
          },
          {
            id: '421181',
            text: '麻城',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1787,
            gisBd09Lng: 115.015,
            gisGcj02Lat: 31.173,
            gisGcj02Lng: 115.008,
            stubGroupCnt: null,
            pinYin: 'Macheng',
          },
          {
            id: '421182',
            text: '武穴',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8501,
            gisBd09Lng: 115.568,
            gisGcj02Lat: 29.8438,
            gisGcj02Lng: 115.562,
            stubGroupCnt: null,
            pinYin: 'Wuxue',
          },
        ],
        gisBd09Lng: 114.879,
        text: '黄冈',
        gisBd09Lat: 30.4593,
        gisGcj02Lat: 30.4533,
      },
      {
        pinYin: 'Xianning',
        id: '421200',
        gisGcj02Lng: 114.322,
        children: [
          {
            id: '421201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8462,
            gisBd09Lng: 114.329,
            gisGcj02Lat: 29.8404,
            gisGcj02Lng: 114.322,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '421202',
            text: '咸安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8568,
            gisBd09Lng: 114.307,
            gisGcj02Lat: 29.8511,
            gisGcj02Lng: 114.3,
            stubGroupCnt: null,
            pinYin: "Xian'an",
          },
          {
            id: '421221',
            text: '嘉鱼县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9755,
            gisBd09Lng: 113.946,
            gisGcj02Lat: 29.9699,
            gisGcj02Lng: 113.94,
            stubGroupCnt: null,
            pinYin: 'Jiayu',
          },
          {
            id: '421222',
            text: '通城县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2495,
            gisBd09Lng: 113.824,
            gisGcj02Lat: 29.2438,
            gisGcj02Lng: 113.817,
            stubGroupCnt: null,
            pinYin: 'Tongcheng',
          },
          {
            id: '421223',
            text: '崇阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5609,
            gisBd09Lng: 114.047,
            gisGcj02Lat: 29.5551,
            gisGcj02Lng: 114.04,
            stubGroupCnt: null,
            pinYin: 'Chongyang',
          },
          {
            id: '421224',
            text: '通山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6118,
            gisBd09Lng: 114.49,
            gisGcj02Lat: 29.6054,
            gisGcj02Lng: 114.484,
            stubGroupCnt: null,
            pinYin: 'Tongshan',
          },
          {
            id: '421281',
            text: '赤壁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.728,
            gisBd09Lng: 113.907,
            gisGcj02Lat: 29.7219,
            gisGcj02Lng: 113.901,
            stubGroupCnt: null,
            pinYin: 'Chibi',
          },
        ],
        gisBd09Lng: 114.329,
        text: '咸宁',
        gisBd09Lat: 29.8462,
        gisGcj02Lat: 29.8404,
      },
      {
        pinYin: 'Suizhou',
        id: '421300',
        gisGcj02Lng: 113.382,
        children: [
          {
            id: '421301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6957,
            gisBd09Lng: 113.389,
            gisGcj02Lat: 31.6895,
            gisGcj02Lng: 113.382,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '421302',
            text: '曾都区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7219,
            gisBd09Lng: 113.379,
            gisGcj02Lat: 31.7159,
            gisGcj02Lng: 113.373,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '421381',
            text: '广水',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6214,
            gisBd09Lng: 113.833,
            gisGcj02Lat: 31.6157,
            gisGcj02Lng: 113.826,
            stubGroupCnt: null,
            pinYin: 'Guangshui',
          },
        ],
        gisBd09Lng: 113.389,
        text: '随州',
        gisBd09Lat: 31.6957,
        gisGcj02Lat: 31.6895,
      },
      {
        pinYin: 'Enshi',
        id: '422800',
        gisGcj02Lng: 109.487,
        children: [
          {
            id: '422801',
            text: '恩施',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3003,
            gisBd09Lng: 109.486,
            gisGcj02Lat: 30.2944,
            gisGcj02Lng: 109.479,
            stubGroupCnt: null,
            pinYin: 'Enshi',
          },
          {
            id: '422802',
            text: '利川',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2969,
            gisBd09Lng: 108.942,
            gisGcj02Lat: 30.2908,
            gisGcj02Lng: 108.936,
            stubGroupCnt: null,
            pinYin: 'Lichuan',
          },
          {
            id: '422822',
            text: '建始县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.608,
            gisBd09Lng: 109.728,
            gisGcj02Lat: 30.6022,
            gisGcj02Lng: 109.722,
            stubGroupCnt: null,
            pinYin: 'Jianshi',
          },
          {
            id: '422823',
            text: '巴东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0477,
            gisBd09Lng: 110.346,
            gisGcj02Lat: 31.0421,
            gisGcj02Lng: 110.34,
            stubGroupCnt: null,
            pinYin: 'Badong',
          },
          {
            id: '422825',
            text: '宣恩县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9926,
            gisBd09Lng: 109.499,
            gisGcj02Lat: 29.9869,
            gisGcj02Lng: 109.492,
            stubGroupCnt: null,
            pinYin: 'Xuanen',
          },
          {
            id: '422826',
            text: '咸丰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6704,
            gisBd09Lng: 109.146,
            gisGcj02Lat: 29.6647,
            gisGcj02Lng: 109.14,
            stubGroupCnt: null,
            pinYin: 'Xianfeng',
          },
          {
            id: '422827',
            text: '来凤县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4999,
            gisBd09Lng: 109.414,
            gisGcj02Lat: 29.4939,
            gisGcj02Lng: 109.408,
            stubGroupCnt: null,
            pinYin: 'Laifeng',
          },
          {
            id: '422828',
            text: '鹤峰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8971,
            gisBd09Lng: 110.042,
            gisGcj02Lat: 29.8908,
            gisGcj02Lng: 110.036,
            stubGroupCnt: null,
            pinYin: 'Hefeng',
          },
        ],
        gisBd09Lng: 109.494,
        text: '恩施土家族苗族自治州',
        gisBd09Lat: 30.2777,
        gisGcj02Lat: 30.272,
      },
      {
        pinYin: '',
        id: '429000',
        gisGcj02Lng: 110.74,
        children: [
          {
            id: '429004',
            text: '仙桃',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3682,
            gisBd09Lng: 113.46,
            gisGcj02Lat: 30.3625,
            gisGcj02Lng: 113.453,
            stubGroupCnt: 0,
            pinYin: 'Xiantao',
          },
          {
            id: '429005',
            text: '潜江',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4069,
            gisBd09Lng: 112.908,
            gisGcj02Lat: 30.4007,
            gisGcj02Lng: 112.902,
            stubGroupCnt: null,
            pinYin: 'Qianjiang',
          },
          {
            id: '429006',
            text: '天门',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.647,
            gisBd09Lng: 113.131,
            gisGcj02Lat: 30.641,
            gisGcj02Lng: 113.125,
            stubGroupCnt: null,
            pinYin: 'Tianmen',
          },
          {
            id: '429021',
            text: '神农架林区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6246,
            gisBd09Lng: 110.746,
            gisGcj02Lat: 31.6185,
            gisGcj02Lng: 110.74,
            stubGroupCnt: null,
            pinYin: 'Shennongjia',
          },
        ],
        gisBd09Lng: null,
        text: '省直辖行政单位',
        gisBd09Lat: null,
        gisGcj02Lat: 31.6185,
      },
    ],
  },
  {
    text: '湖南省',
    children: [
      {
        pinYin: 'Changsha',
        id: '430100',
        gisGcj02Lng: 112.939,
        children: [
          {
            id: '430101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.233,
            gisBd09Lng: 112.945,
            gisGcj02Lat: 28.2268,
            gisGcj02Lng: 112.939,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430102',
            text: '芙蓉区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2087,
            gisBd09Lng: 113.042,
            gisGcj02Lat: 28.2023,
            gisGcj02Lng: 113.036,
            stubGroupCnt: null,
            pinYin: 'Furong',
          },
          {
            id: '430103',
            text: '天心区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1182,
            gisBd09Lng: 112.996,
            gisGcj02Lat: 28.1125,
            gisGcj02Lng: 112.989,
            stubGroupCnt: null,
            pinYin: 'Tianxin',
          },
          {
            id: '430104',
            text: '岳麓区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2392,
            gisBd09Lng: 112.939,
            gisGcj02Lat: 28.2329,
            gisGcj02Lng: 112.932,
            stubGroupCnt: null,
            pinYin: 'Yuelu',
          },
          {
            id: '430105',
            text: '开福区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2603,
            gisBd09Lng: 112.995,
            gisGcj02Lat: 28.2546,
            gisGcj02Lng: 112.989,
            stubGroupCnt: null,
            pinYin: 'Kaifu',
          },
          {
            id: '430111',
            text: '雨花区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.142,
            gisBd09Lng: 113.041,
            gisGcj02Lat: 28.1356,
            gisGcj02Lng: 113.035,
            stubGroupCnt: null,
            pinYin: 'Yuhua',
          },
          {
            id: '430121',
            text: '长沙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2496,
            gisBd09Lng: 113.089,
            gisGcj02Lat: 28.2438,
            gisGcj02Lng: 113.083,
            stubGroupCnt: null,
            pinYin: 'Changsha',
          },
          {
            id: '430122',
            text: '望城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2978,
            gisBd09Lng: 112.827,
            gisGcj02Lat: 28.2917,
            gisGcj02Lng: 112.82,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430124',
            text: '宁乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2828,
            gisBd09Lng: 112.56,
            gisGcj02Lat: 28.2765,
            gisGcj02Lng: 112.554,
            stubGroupCnt: null,
            pinYin: 'Ningxiang',
          },
          {
            id: '430181',
            text: '浏阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1688,
            gisBd09Lng: 113.652,
            gisGcj02Lat: 28.1625,
            gisGcj02Lng: 113.645,
            stubGroupCnt: null,
            pinYin: 'Liuyang',
          },
        ],
        gisBd09Lng: 112.945,
        text: '长沙',
        gisBd09Lat: 28.233,
        gisGcj02Lat: 28.2268,
      },
      {
        pinYin: 'Zhuzhou',
        id: '430200',
        gisGcj02Lng: 113.134,
        children: [
          {
            id: '430201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8339,
            gisBd09Lng: 113.141,
            gisGcj02Lat: 27.8278,
            gisGcj02Lng: 113.134,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430202',
            text: '荷塘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8618,
            gisBd09Lng: 113.18,
            gisGcj02Lat: 27.8555,
            gisGcj02Lng: 113.174,
            stubGroupCnt: null,
            pinYin: 'Hetang',
          },
          {
            id: '430203',
            text: '芦淞区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7914,
            gisBd09Lng: 113.158,
            gisGcj02Lat: 27.7851,
            gisGcj02Lng: 113.152,
            stubGroupCnt: null,
            pinYin: 'Lusong',
          },
          {
            id: '430204',
            text: '石峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8791,
            gisBd09Lng: 113.125,
            gisGcj02Lat: 27.8733,
            gisGcj02Lng: 113.118,
            stubGroupCnt: null,
            pinYin: 'Shifeng',
          },
          {
            id: '430211',
            text: '天元区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8324,
            gisBd09Lng: 113.089,
            gisGcj02Lat: 27.8267,
            gisGcj02Lng: 113.083,
            stubGroupCnt: null,
            pinYin: 'Tianyuan',
          },
          {
            id: '430221',
            text: '株洲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7051,
            gisBd09Lng: 113.151,
            gisGcj02Lat: 27.6989,
            gisGcj02Lng: 113.145,
            stubGroupCnt: null,
            pinYin: 'Zhuzhou',
          },
          {
            id: '430223',
            text: '攸　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0053,
            gisBd09Lng: 113.352,
            gisGcj02Lat: 26.9997,
            gisGcj02Lng: 113.345,
            stubGroupCnt: null,
            pinYin: 'Youxian',
          },
          {
            id: '430224',
            text: '茶陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7834,
            gisBd09Lng: 113.546,
            gisGcj02Lat: 26.7772,
            gisGcj02Lng: 113.539,
            stubGroupCnt: null,
            pinYin: 'Chaling',
          },
          {
            id: '430225',
            text: '炎陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4952,
            gisBd09Lng: 113.78,
            gisGcj02Lat: 26.489,
            gisGcj02Lng: 113.773,
            stubGroupCnt: null,
            pinYin: 'Yanling',
          },
          {
            id: '430281',
            text: '醴陵',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6518,
            gisBd09Lng: 113.505,
            gisGcj02Lat: 27.6456,
            gisGcj02Lng: 113.498,
            stubGroupCnt: null,
            pinYin: 'Liling',
          },
        ],
        gisBd09Lng: 113.141,
        text: '株洲',
        gisBd09Lat: 27.8339,
        gisGcj02Lat: 27.8278,
      },
      {
        pinYin: 'Xiangtan',
        id: '430300',
        gisGcj02Lng: 112.944,
        children: [
          {
            id: '430301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8353,
            gisBd09Lng: 112.951,
            gisGcj02Lat: 27.8292,
            gisGcj02Lng: 112.944,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430302',
            text: '雨湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8608,
            gisBd09Lng: 112.914,
            gisGcj02Lat: 27.8545,
            gisGcj02Lng: 112.907,
            stubGroupCnt: null,
            pinYin: 'Yuhu',
          },
          {
            id: '430304',
            text: '岳塘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8768,
            gisBd09Lng: 112.978,
            gisGcj02Lat: 27.8711,
            gisGcj02Lng: 112.971,
            stubGroupCnt: null,
            pinYin: 'Yuetang',
          },
          {
            id: '430321',
            text: '湘潭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7849,
            gisBd09Lng: 112.957,
            gisGcj02Lat: 27.7789,
            gisGcj02Lng: 112.95,
            stubGroupCnt: null,
            pinYin: 'Xiangtan',
          },
          {
            id: '430381',
            text: '湘乡',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7408,
            gisBd09Lng: 112.54,
            gisGcj02Lat: 27.7347,
            gisGcj02Lng: 112.534,
            stubGroupCnt: null,
            pinYin: 'Xiangxiang',
          },
          {
            id: '430382',
            text: '韶山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.92,
            gisBd09Lng: 112.532,
            gisGcj02Lat: 27.9141,
            gisGcj02Lng: 112.526,
            stubGroupCnt: null,
            pinYin: 'Shaoshan',
          },
        ],
        gisBd09Lng: 112.951,
        text: '湘潭',
        gisBd09Lat: 27.8353,
        gisGcj02Lat: 27.8292,
      },
      {
        pinYin: 'Hengyang',
        id: '430400',
        gisGcj02Lng: 112.571,
        children: [
          {
            id: '430401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8991,
            gisBd09Lng: 112.578,
            gisGcj02Lat: 26.8928,
            gisGcj02Lng: 112.571,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430405',
            text: '珠晖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8995,
            gisBd09Lng: 112.628,
            gisGcj02Lat: 26.8939,
            gisGcj02Lng: 112.621,
            stubGroupCnt: null,
            pinYin: 'Zhuhui',
          },
          {
            id: '430406',
            text: '雁峰区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8942,
            gisBd09Lng: 112.623,
            gisGcj02Lat: 26.8885,
            gisGcj02Lng: 112.616,
            stubGroupCnt: null,
            pinYin: 'Yanfeng',
          },
          {
            id: '430407',
            text: '石鼓区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9486,
            gisBd09Lng: 112.604,
            gisGcj02Lat: 26.9427,
            gisGcj02Lng: 112.597,
            stubGroupCnt: null,
            pinYin: 'Shigu',
          },
          {
            id: '430408',
            text: '蒸湘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8984,
            gisBd09Lng: 112.581,
            gisGcj02Lat: 26.8921,
            gisGcj02Lng: 112.574,
            stubGroupCnt: null,
            pinYin: 'Zhengxiang',
          },
          {
            id: '430412',
            text: '南岳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2379,
            gisBd09Lng: 112.744,
            gisGcj02Lat: 27.2322,
            gisGcj02Lng: 112.738,
            stubGroupCnt: null,
            pinYin: 'Nanyue',
          },
          {
            id: '430421',
            text: '衡阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9746,
            gisBd09Lng: 112.38,
            gisGcj02Lat: 26.9689,
            gisGcj02Lng: 112.373,
            stubGroupCnt: null,
            pinYin: 'Hengyang',
          },
          {
            id: '430422',
            text: '衡南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.743,
            gisBd09Lng: 112.684,
            gisGcj02Lat: 26.7366,
            gisGcj02Lng: 112.678,
            stubGroupCnt: null,
            pinYin: 'Hengnan',
          },
          {
            id: '430423',
            text: '衡山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2352,
            gisBd09Lng: 112.875,
            gisGcj02Lat: 27.2295,
            gisGcj02Lng: 112.868,
            stubGroupCnt: null,
            pinYin: 'Hengshan',
          },
          {
            id: '430424',
            text: '衡东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0866,
            gisBd09Lng: 112.961,
            gisGcj02Lat: 27.0807,
            gisGcj02Lng: 112.955,
            stubGroupCnt: null,
            pinYin: 'Hengdong',
          },
          {
            id: '430426',
            text: '祁东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8054,
            gisBd09Lng: 112.098,
            gisGcj02Lat: 26.7991,
            gisGcj02Lng: 112.092,
            stubGroupCnt: null,
            pinYin: 'Qidong',
          },
          {
            id: '430481',
            text: '耒阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4267,
            gisBd09Lng: 112.867,
            gisGcj02Lat: 26.421,
            gisGcj02Lng: 112.86,
            stubGroupCnt: null,
            pinYin: 'Leiyang',
          },
          {
            id: '430482',
            text: '常宁',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.425,
            gisBd09Lng: 112.405,
            gisGcj02Lat: 26.4191,
            gisGcj02Lng: 112.398,
            stubGroupCnt: null,
            pinYin: 'Changning',
          },
        ],
        gisBd09Lng: 112.578,
        text: '衡阳',
        gisBd09Lat: 26.8991,
        gisGcj02Lat: 26.8928,
      },
      {
        pinYin: 'Shaoyang',
        id: '430500',
        gisGcj02Lng: 111.467,
        children: [
          {
            id: '430501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2449,
            gisBd09Lng: 111.473,
            gisGcj02Lat: 27.2387,
            gisGcj02Lng: 111.467,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430502',
            text: '双清区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2376,
            gisBd09Lng: 111.505,
            gisGcj02Lat: 27.2315,
            gisGcj02Lng: 111.498,
            stubGroupCnt: null,
            pinYin: 'Shuangqing',
          },
          {
            id: '430503',
            text: '大祥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2248,
            gisBd09Lng: 111.446,
            gisGcj02Lat: 27.219,
            gisGcj02Lng: 111.439,
            stubGroupCnt: null,
            pinYin: 'Daxiang',
          },
          {
            id: '430511',
            text: '北塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2503,
            gisBd09Lng: 111.457,
            gisGcj02Lat: 27.2443,
            gisGcj02Lng: 111.45,
            stubGroupCnt: null,
            pinYin: 'Beita',
          },
          {
            id: '430521',
            text: '邵东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2642,
            gisBd09Lng: 111.751,
            gisGcj02Lat: 27.2581,
            gisGcj02Lng: 111.744,
            stubGroupCnt: null,
            pinYin: 'Shaodong',
          },
          {
            id: '430522',
            text: '新邵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3272,
            gisBd09Lng: 111.465,
            gisGcj02Lat: 27.321,
            gisGcj02Lng: 111.459,
            stubGroupCnt: null,
            pinYin: 'Xinshao',
          },
          {
            id: '430523',
            text: '邵阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.996,
            gisBd09Lng: 111.28,
            gisGcj02Lat: 26.9901,
            gisGcj02Lng: 111.274,
            stubGroupCnt: null,
            pinYin: 'Shaoyang',
          },
          {
            id: '430524',
            text: '隆回县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1189,
            gisBd09Lng: 111.039,
            gisGcj02Lat: 27.113,
            gisGcj02Lng: 111.033,
            stubGroupCnt: null,
            pinYin: 'Longhui',
          },
          {
            id: '430525',
            text: '洞口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0646,
            gisBd09Lng: 110.585,
            gisGcj02Lat: 27.059,
            gisGcj02Lng: 110.579,
            stubGroupCnt: null,
            pinYin: 'Dongkou',
          },
          {
            id: '430527',
            text: '绥宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.587,
            gisBd09Lng: 110.161,
            gisGcj02Lat: 26.5807,
            gisGcj02Lng: 110.155,
            stubGroupCnt: null,
            pinYin: 'Suining',
          },
          {
            id: '430528',
            text: '新宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4393,
            gisBd09Lng: 110.862,
            gisGcj02Lat: 26.4332,
            gisGcj02Lng: 110.856,
            stubGroupCnt: null,
            pinYin: 'Xinning',
          },
          {
            id: '430529',
            text: '城步苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3957,
            gisBd09Lng: 110.329,
            gisGcj02Lat: 26.3899,
            gisGcj02Lng: 110.322,
            stubGroupCnt: null,
            pinYin: 'Chengbu',
          },
          {
            id: '430581',
            text: '武冈',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7312,
            gisBd09Lng: 110.637,
            gisGcj02Lat: 26.7249,
            gisGcj02Lng: 110.631,
            stubGroupCnt: null,
            pinYin: 'Wugang',
          },
        ],
        gisBd09Lng: 111.473,
        text: '邵阳',
        gisBd09Lat: 27.2449,
        gisGcj02Lat: 27.2387,
      },
      {
        pinYin: 'Yueyang',
        id: '430600',
        gisGcj02Lng: 113.13,
        children: [
          {
            id: '430601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3624,
            gisBd09Lng: 113.137,
            gisGcj02Lat: 29.3564,
            gisGcj02Lng: 113.13,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430602',
            text: '岳阳楼区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3776,
            gisBd09Lng: 113.134,
            gisGcj02Lat: 29.3716,
            gisGcj02Lng: 113.128,
            stubGroupCnt: null,
            pinYin: 'Yueyanglou',
          },
          {
            id: '430603',
            text: '云溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4796,
            gisBd09Lng: 113.278,
            gisGcj02Lat: 29.4733,
            gisGcj02Lng: 113.272,
            stubGroupCnt: null,
            pinYin: 'Yunxi',
          },
          {
            id: '430611',
            text: '君山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4655,
            gisBd09Lng: 113.011,
            gisGcj02Lat: 29.4596,
            gisGcj02Lng: 113.005,
            stubGroupCnt: null,
            pinYin: 'Junshan',
          },
          {
            id: '430621',
            text: '岳阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.15,
            gisBd09Lng: 113.125,
            gisGcj02Lat: 29.1442,
            gisGcj02Lng: 113.118,
            stubGroupCnt: null,
            pinYin: 'Yueyang',
          },
          {
            id: '430623',
            text: '华容县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.536,
            gisBd09Lng: 112.548,
            gisGcj02Lat: 29.5298,
            gisGcj02Lng: 112.542,
            stubGroupCnt: null,
            pinYin: 'Huarong',
          },
          {
            id: '430624',
            text: '湘阴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6941,
            gisBd09Lng: 112.915,
            gisGcj02Lat: 28.6879,
            gisGcj02Lng: 112.908,
            stubGroupCnt: null,
            pinYin: 'Xiangyin',
          },
          {
            id: '430626',
            text: '平江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7062,
            gisBd09Lng: 113.587,
            gisGcj02Lat: 28.7005,
            gisGcj02Lng: 113.58,
            stubGroupCnt: null,
            pinYin: 'Pingjiang',
          },
          {
            id: '430681',
            text: '汨罗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8115,
            gisBd09Lng: 113.074,
            gisGcj02Lat: 28.8054,
            gisGcj02Lng: 113.067,
            stubGroupCnt: null,
            pinYin: 'Miluo',
          },
          {
            id: '430682',
            text: '临湘',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5115,
            gisBd09Lng: 113.46,
            gisGcj02Lat: 29.5058,
            gisGcj02Lng: 113.454,
            stubGroupCnt: null,
            pinYin: 'Linxiang',
          },
        ],
        gisBd09Lng: 113.137,
        text: '岳阳',
        gisBd09Lat: 29.3624,
        gisGcj02Lat: 29.3564,
      },
      {
        pinYin: 'Changde',
        id: '430700',
        gisGcj02Lng: 111.7,
        children: [
          {
            id: '430701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0373,
            gisBd09Lng: 111.706,
            gisGcj02Lat: 29.0312,
            gisGcj02Lng: 111.7,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430702',
            text: '武陵区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0585,
            gisBd09Lng: 111.688,
            gisGcj02Lat: 29.0526,
            gisGcj02Lng: 111.682,
            stubGroupCnt: null,
            pinYin: 'Wuling',
          },
          {
            id: '430703',
            text: '鼎城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0245,
            gisBd09Lng: 111.688,
            gisGcj02Lat: 29.0186,
            gisGcj02Lng: 111.681,
            stubGroupCnt: null,
            pinYin: 'Dingcheng',
          },
          {
            id: '430721',
            text: '安乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4172,
            gisBd09Lng: 112.176,
            gisGcj02Lat: 29.4112,
            gisGcj02Lng: 112.17,
            stubGroupCnt: null,
            pinYin: 'Anxiang',
          },
          {
            id: '430722',
            text: '汉寿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.91,
            gisBd09Lng: 111.979,
            gisGcj02Lat: 28.9037,
            gisGcj02Lng: 111.972,
            stubGroupCnt: null,
            pinYin: 'Hanshou',
          },
          {
            id: '430723',
            text: '澧　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6365,
            gisBd09Lng: 111.766,
            gisGcj02Lat: 29.6307,
            gisGcj02Lng: 111.759,
            stubGroupCnt: null,
            pinYin: 'Lixian',
          },
          {
            id: '430724',
            text: '临澧县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.446,
            gisBd09Lng: 111.655,
            gisGcj02Lat: 29.4402,
            gisGcj02Lng: 111.648,
            stubGroupCnt: null,
            pinYin: 'Linli',
          },
          {
            id: '430725',
            text: '桃源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9086,
            gisBd09Lng: 111.496,
            gisGcj02Lat: 28.9023,
            gisGcj02Lng: 111.49,
            stubGroupCnt: null,
            pinYin: 'Taoyuan',
          },
          {
            id: '430726',
            text: '石门县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5895,
            gisBd09Lng: 111.386,
            gisGcj02Lat: 29.5834,
            gisGcj02Lng: 111.38,
            stubGroupCnt: null,
            pinYin: 'Shimen',
          },
          {
            id: '430781',
            text: '津',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6094,
            gisBd09Lng: 111.884,
            gisGcj02Lat: 29.6035,
            gisGcj02Lng: 111.877,
            stubGroupCnt: null,
            pinYin: 'Jinshi',
          },
        ],
        gisBd09Lng: 111.706,
        text: '常德',
        gisBd09Lat: 29.0373,
        gisGcj02Lat: 29.0312,
      },
      {
        pinYin: 'Zhangjiajie',
        id: '430800',
        gisGcj02Lng: 110.478,
        children: [
          {
            id: '430801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1217,
            gisBd09Lng: 110.485,
            gisGcj02Lat: 29.1159,
            gisGcj02Lng: 110.478,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430802',
            text: '永定区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1243,
            gisBd09Lng: 110.543,
            gisGcj02Lat: 29.118,
            gisGcj02Lng: 110.536,
            stubGroupCnt: null,
            pinYin: 'Yongding',
          },
          {
            id: '430811',
            text: '武陵源区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3506,
            gisBd09Lng: 110.556,
            gisGcj02Lat: 29.3446,
            gisGcj02Lng: 110.549,
            stubGroupCnt: null,
            pinYin: 'Wulingyuan',
          },
          {
            id: '430821',
            text: '慈利县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4356,
            gisBd09Lng: 111.146,
            gisGcj02Lat: 29.4295,
            gisGcj02Lng: 111.14,
            stubGroupCnt: null,
            pinYin: 'Cili',
          },
          {
            id: '430822',
            text: '桑植县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.404,
            gisBd09Lng: 110.17,
            gisGcj02Lat: 29.3977,
            gisGcj02Lng: 110.164,
            stubGroupCnt: null,
            pinYin: 'Sangzhi',
          },
        ],
        gisBd09Lng: 110.485,
        text: '张家界',
        gisBd09Lat: 29.1217,
        gisGcj02Lat: 29.1159,
      },
      {
        pinYin: 'Yiyang',
        id: '430900',
        gisGcj02Lng: 112.355,
        children: [
          {
            id: '430901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5583,
            gisBd09Lng: 112.362,
            gisGcj02Lat: 28.5524,
            gisGcj02Lng: 112.355,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '430902',
            text: '资阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5952,
            gisBd09Lng: 112.332,
            gisGcj02Lat: 28.5889,
            gisGcj02Lng: 112.326,
            stubGroupCnt: null,
            pinYin: 'Ziyang',
          },
          {
            id: '430903',
            text: '赫山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.583,
            gisBd09Lng: 112.382,
            gisGcj02Lat: 28.5773,
            gisGcj02Lng: 112.375,
            stubGroupCnt: null,
            pinYin: 'Heshan',
          },
          {
            id: '430921',
            text: '南　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3671,
            gisBd09Lng: 112.404,
            gisGcj02Lat: 29.3613,
            gisGcj02Lng: 112.397,
            stubGroupCnt: null,
            pinYin: 'Nanxian',
          },
          {
            id: '430922',
            text: '桃江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5224,
            gisBd09Lng: 112.162,
            gisGcj02Lat: 28.5167,
            gisGcj02Lng: 112.155,
            stubGroupCnt: null,
            pinYin: 'Taojiang',
          },
          {
            id: '430923',
            text: '安化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3793,
            gisBd09Lng: 111.22,
            gisGcj02Lat: 28.3732,
            gisGcj02Lng: 111.213,
            stubGroupCnt: null,
            pinYin: 'Anhua',
          },
          {
            id: '430981',
            text: '沅江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8496,
            gisBd09Lng: 112.361,
            gisGcj02Lat: 28.8437,
            gisGcj02Lng: 112.355,
            stubGroupCnt: null,
            pinYin: 'Yuanjiang',
          },
        ],
        gisBd09Lng: 112.362,
        text: '益阳',
        gisBd09Lat: 28.5583,
        gisGcj02Lat: 28.5524,
      },
      {
        pinYin: 'Chenzhou',
        id: '431000',
        gisGcj02Lng: 113.015,
        children: [
          {
            id: '431001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7766,
            gisBd09Lng: 113.021,
            gisGcj02Lat: 25.7705,
            gisGcj02Lng: 113.015,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '431002',
            text: '北湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7899,
            gisBd09Lng: 113.017,
            gisGcj02Lat: 25.7839,
            gisGcj02Lng: 113.01,
            stubGroupCnt: null,
            pinYin: 'Beihu',
          },
          {
            id: '431003',
            text: '苏仙区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.804,
            gisBd09Lng: 113.049,
            gisGcj02Lat: 25.7977,
            gisGcj02Lng: 113.043,
            stubGroupCnt: null,
            pinYin: 'Suxian',
          },
          {
            id: '431021',
            text: '桂阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7591,
            gisBd09Lng: 112.74,
            gisGcj02Lat: 25.7534,
            gisGcj02Lng: 112.733,
            stubGroupCnt: null,
            pinYin: 'Guiyang',
          },
          {
            id: '431022',
            text: '宜章县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4045,
            gisBd09Lng: 112.956,
            gisGcj02Lat: 25.3985,
            gisGcj02Lng: 112.949,
            stubGroupCnt: null,
            pinYin: 'Yizhang',
          },
          {
            id: '431023',
            text: '永兴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1325,
            gisBd09Lng: 113.122,
            gisGcj02Lat: 26.1268,
            gisGcj02Lng: 113.116,
            stubGroupCnt: null,
            pinYin: 'Yongxing',
          },
          {
            id: '431024',
            text: '嘉禾县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5922,
            gisBd09Lng: 112.374,
            gisGcj02Lat: 25.5865,
            gisGcj02Lng: 112.367,
            stubGroupCnt: null,
            pinYin: 'Jiahe',
          },
          {
            id: '431025',
            text: '临武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.282,
            gisBd09Lng: 112.57,
            gisGcj02Lat: 25.2756,
            gisGcj02Lng: 112.563,
            stubGroupCnt: null,
            pinYin: 'Linwu',
          },
          {
            id: '431026',
            text: '汝城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5551,
            gisBd09Lng: 113.691,
            gisGcj02Lat: 25.5493,
            gisGcj02Lng: 113.685,
            stubGroupCnt: null,
            pinYin: 'Rucheng',
          },
          {
            id: '431027',
            text: '桂东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0823,
            gisBd09Lng: 113.951,
            gisGcj02Lat: 26.0767,
            gisGcj02Lng: 113.944,
            stubGroupCnt: null,
            pinYin: 'Guidong',
          },
          {
            id: '431028',
            text: '安仁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7149,
            gisBd09Lng: 113.275,
            gisGcj02Lat: 26.7086,
            gisGcj02Lng: 113.269,
            stubGroupCnt: null,
            pinYin: 'Anren',
          },
          {
            id: '431081',
            text: '资兴',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9804,
            gisBd09Lng: 113.243,
            gisGcj02Lat: 25.9746,
            gisGcj02Lng: 113.237,
            stubGroupCnt: null,
            pinYin: 'Zixing',
          },
        ],
        gisBd09Lng: 113.021,
        text: '郴州',
        gisBd09Lat: 25.7766,
        gisGcj02Lat: 25.7705,
      },
      {
        pinYin: 'Yongzhou',
        id: '431100',
        gisGcj02Lng: 111.613,
        children: [
          {
            id: '431101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4254,
            gisBd09Lng: 111.619,
            gisGcj02Lat: 26.4192,
            gisGcj02Lng: 111.613,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '431102',
            text: '芝山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2318,
            gisBd09Lng: 111.628,
            gisGcj02Lat: 26.2257,
            gisGcj02Lng: 111.621,
            stubGroupCnt: null,
            pinYin: 'Lingling',
          },
          {
            id: '431103',
            text: '冷水滩区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4666,
            gisBd09Lng: 111.603,
            gisGcj02Lat: 26.4603,
            gisGcj02Lng: 111.597,
            stubGroupCnt: null,
            pinYin: 'Lengshuitan',
          },
          {
            id: '431121',
            text: '祁阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5857,
            gisBd09Lng: 111.848,
            gisGcj02Lat: 26.5793,
            gisGcj02Lng: 111.842,
            stubGroupCnt: null,
            pinYin: 'Qiyang',
          },
          {
            id: '431122',
            text: '东安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3978,
            gisBd09Lng: 111.322,
            gisGcj02Lat: 26.392,
            gisGcj02Lng: 111.315,
            stubGroupCnt: null,
            pinYin: "Dong'an",
          },
          {
            id: '431123',
            text: '双牌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9634,
            gisBd09Lng: 111.668,
            gisGcj02Lat: 25.9578,
            gisGcj02Lng: 111.661,
            stubGroupCnt: null,
            pinYin: 'Shuangpai',
          },
          {
            id: '431124',
            text: '道　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5307,
            gisBd09Lng: 111.606,
            gisGcj02Lat: 25.5244,
            gisGcj02Lng: 111.6,
            stubGroupCnt: null,
            pinYin: 'Daoxian',
          },
          {
            id: '431125',
            text: '江永县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2794,
            gisBd09Lng: 111.352,
            gisGcj02Lat: 25.2732,
            gisGcj02Lng: 111.345,
            stubGroupCnt: null,
            pinYin: 'Jiangyong',
          },
          {
            id: '431126',
            text: '宁远县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5744,
            gisBd09Lng: 111.953,
            gisGcj02Lat: 25.5681,
            gisGcj02Lng: 111.947,
            stubGroupCnt: null,
            pinYin: 'Ningyuan',
          },
          {
            id: '431127',
            text: '蓝山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3754,
            gisBd09Lng: 112.207,
            gisGcj02Lat: 25.369,
            gisGcj02Lng: 112.201,
            stubGroupCnt: null,
            pinYin: 'Lanshan',
          },
          {
            id: '431128',
            text: '新田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9097,
            gisBd09Lng: 112.211,
            gisGcj02Lat: 25.9034,
            gisGcj02Lng: 112.205,
            stubGroupCnt: null,
            pinYin: 'Xintian',
          },
          {
            id: '431129',
            text: '江华瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1887,
            gisBd09Lng: 111.586,
            gisGcj02Lat: 25.1825,
            gisGcj02Lng: 111.58,
            stubGroupCnt: null,
            pinYin: 'Jianghua',
          },
        ],
        gisBd09Lng: 111.619,
        text: '永州',
        gisBd09Lat: 26.4252,
        gisGcj02Lat: 26.4189,
      },
      {
        pinYin: 'Huaihua',
        id: '431200',
        gisGcj02Lng: 110.002,
        children: [
          {
            id: '431201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5753,
            gisBd09Lng: 110.008,
            gisGcj02Lat: 27.5694,
            gisGcj02Lng: 110.002,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '431202',
            text: '鹤城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5833,
            gisBd09Lng: 110.045,
            gisGcj02Lat: 27.5769,
            gisGcj02Lng: 110.039,
            stubGroupCnt: null,
            pinYin: 'Hecheng',
          },
          {
            id: '431221',
            text: '中方县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4463,
            gisBd09Lng: 109.952,
            gisGcj02Lat: 27.4403,
            gisGcj02Lng: 109.946,
            stubGroupCnt: null,
            pinYin: 'Zhongfang',
          },
          {
            id: '431222',
            text: '沅陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.459,
            gisBd09Lng: 110.399,
            gisGcj02Lat: 28.4527,
            gisGcj02Lng: 110.393,
            stubGroupCnt: null,
            pinYin: 'Yuanling',
          },
          {
            id: '431223',
            text: '辰溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.0119,
            gisBd09Lng: 110.188,
            gisGcj02Lat: 28.0057,
            gisGcj02Lng: 110.181,
            stubGroupCnt: null,
            pinYin: 'Chenxi',
          },
          {
            id: '431224',
            text: '溆浦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.914,
            gisBd09Lng: 110.604,
            gisGcj02Lat: 27.9082,
            gisGcj02Lng: 110.597,
            stubGroupCnt: null,
            pinYin: 'Xupu',
          },
          {
            id: '431225',
            text: '会同县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8909,
            gisBd09Lng: 109.742,
            gisGcj02Lat: 26.8852,
            gisGcj02Lng: 109.736,
            stubGroupCnt: null,
            pinYin: 'Huitong',
          },
          {
            id: '431226',
            text: '麻阳苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8706,
            gisBd09Lng: 109.808,
            gisGcj02Lat: 27.8643,
            gisGcj02Lng: 109.802,
            stubGroupCnt: null,
            pinYin: 'Mayang',
          },
          {
            id: '431227',
            text: '新晃侗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3564,
            gisBd09Lng: 109.182,
            gisGcj02Lat: 27.3503,
            gisGcj02Lng: 109.176,
            stubGroupCnt: null,
            pinYin: 'Xinhuang',
          },
          {
            id: '431228',
            text: '芷江侗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4497,
            gisBd09Lng: 109.691,
            gisGcj02Lat: 27.4433,
            gisGcj02Lng: 109.685,
            stubGroupCnt: null,
            pinYin: 'Zhijiang',
          },
          {
            id: '431229',
            text: '靖州苗族侗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.578,
            gisBd09Lng: 109.703,
            gisGcj02Lat: 26.5718,
            gisGcj02Lng: 109.696,
            stubGroupCnt: null,
            pinYin: 'Jingzhou',
          },
          {
            id: '431230',
            text: '通道侗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1684,
            gisBd09Lng: 109.792,
            gisGcj02Lat: 26.1622,
            gisGcj02Lng: 109.785,
            stubGroupCnt: null,
            pinYin: 'Tongdao',
          },
          {
            id: '431281',
            text: '洪江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2131,
            gisBd09Lng: 109.844,
            gisGcj02Lat: 27.2072,
            gisGcj02Lng: 109.838,
            stubGroupCnt: null,
            pinYin: 'Hongjiang',
          },
        ],
        gisBd09Lng: 110.008,
        text: '怀化',
        gisBd09Lat: 27.5753,
        gisGcj02Lat: 27.5694,
      },
      {
        pinYin: 'Loudi',
        id: '431300',
        gisGcj02Lng: 111.993,
        children: [
          {
            id: '431301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7033,
            gisBd09Lng: 112.0,
            gisGcj02Lat: 27.6973,
            gisGcj02Lng: 111.993,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '431302',
            text: '娄星区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7315,
            gisBd09Lng: 112.008,
            gisGcj02Lat: 27.7257,
            gisGcj02Lng: 112.002,
            stubGroupCnt: null,
            pinYin: 'Louxing',
          },
          {
            id: '431321',
            text: '双峰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4628,
            gisBd09Lng: 112.201,
            gisGcj02Lat: 27.4565,
            gisGcj02Lng: 112.194,
            stubGroupCnt: null,
            pinYin: 'Shuangfeng',
          },
          {
            id: '431322',
            text: '新化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7326,
            gisBd09Lng: 111.333,
            gisGcj02Lat: 27.7266,
            gisGcj02Lng: 111.327,
            stubGroupCnt: null,
            pinYin: 'Xinhua',
          },
          {
            id: '431381',
            text: '冷水江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6912,
            gisBd09Lng: 111.442,
            gisGcj02Lat: 27.6854,
            gisGcj02Lng: 111.435,
            stubGroupCnt: null,
            pinYin: 'Lengshuijiang',
          },
          {
            id: '431382',
            text: '涟源',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6977,
            gisBd09Lng: 111.671,
            gisGcj02Lat: 27.6921,
            gisGcj02Lng: 111.664,
            stubGroupCnt: null,
            pinYin: 'Lianyuan',
          },
        ],
        gisBd09Lng: 112.0,
        text: '娄底',
        gisBd09Lat: 27.7033,
        gisGcj02Lat: 27.6973,
      },
      {
        pinYin: 'Xiangxi',
        id: '433100',
        gisGcj02Lng: 109.739,
        children: [
          {
            id: '433101',
            text: '吉首',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.268,
            gisBd09Lng: 109.705,
            gisGcj02Lat: 28.2618,
            gisGcj02Lng: 109.699,
            stubGroupCnt: null,
            pinYin: 'Jishou',
          },
          {
            id: '433122',
            text: '泸溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2216,
            gisBd09Lng: 110.227,
            gisGcj02Lat: 28.2159,
            gisGcj02Lng: 110.22,
            stubGroupCnt: null,
            pinYin: 'Luxi',
          },
          {
            id: '433123',
            text: '凤凰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9535,
            gisBd09Lng: 109.605,
            gisGcj02Lat: 27.9476,
            gisGcj02Lng: 109.598,
            stubGroupCnt: null,
            pinYin: 'Fenghuang',
          },
          {
            id: '433124',
            text: '花垣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5776,
            gisBd09Lng: 109.489,
            gisGcj02Lat: 28.5718,
            gisGcj02Lng: 109.482,
            stubGroupCnt: null,
            pinYin: 'Huayuan',
          },
          {
            id: '433125',
            text: '保靖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7058,
            gisBd09Lng: 109.668,
            gisGcj02Lat: 28.6996,
            gisGcj02Lng: 109.661,
            stubGroupCnt: null,
            pinYin: 'Baojing',
          },
          {
            id: '433126',
            text: '古丈县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6223,
            gisBd09Lng: 109.958,
            gisGcj02Lat: 28.6164,
            gisGcj02Lng: 109.951,
            stubGroupCnt: null,
            pinYin: 'Guzhang',
          },
          {
            id: '433127',
            text: '永顺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0104,
            gisBd09Lng: 109.855,
            gisGcj02Lat: 29.0047,
            gisGcj02Lng: 109.848,
            stubGroupCnt: null,
            pinYin: 'Yongshun',
          },
          {
            id: '433130',
            text: '龙山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4635,
            gisBd09Lng: 109.45,
            gisGcj02Lat: 29.4571,
            gisGcj02Lng: 109.443,
            stubGroupCnt: null,
            pinYin: 'Longshan',
          },
        ],
        gisBd09Lng: 109.745,
        text: '湘西土家族苗族自治州',
        gisBd09Lat: 28.3173,
        gisGcj02Lat: 28.3116,
      },
    ],
  },
  {
    text: '吉林省',
    children: [
      {
        pinYin: 'Changchun',
        id: '220100',
        gisGcj02Lng: 125.323,
        children: [
          {
            id: '220101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8222,
            gisBd09Lng: 125.33,
            gisGcj02Lat: 43.8165,
            gisGcj02Lng: 125.323,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220102',
            text: '南关区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8698,
            gisBd09Lng: 125.356,
            gisGcj02Lat: 43.8642,
            gisGcj02Lng: 125.35,
            stubGroupCnt: null,
            pinYin: 'Nanguan',
          },
          {
            id: '220103',
            text: '宽城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.948,
            gisBd09Lng: 125.333,
            gisGcj02Lat: 43.9423,
            gisGcj02Lng: 125.326,
            stubGroupCnt: null,
            pinYin: 'Kuancheng',
          },
          {
            id: '220104',
            text: '朝阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8398,
            gisBd09Lng: 125.294,
            gisGcj02Lat: 43.8335,
            gisGcj02Lng: 125.287,
            stubGroupCnt: null,
            pinYin: 'Chaoyang',
          },
          {
            id: '220105',
            text: '二道区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8703,
            gisBd09Lng: 125.381,
            gisGcj02Lat: 43.8642,
            gisGcj02Lng: 125.374,
            stubGroupCnt: null,
            pinYin: 'Erdao',
          },
          {
            id: '220106',
            text: '绿园区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8864,
            gisBd09Lng: 125.262,
            gisGcj02Lat: 43.8803,
            gisGcj02Lng: 125.256,
            stubGroupCnt: null,
            pinYin: 'Lvyuan',
          },
          {
            id: '220112',
            text: '双阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.5317,
            gisBd09Lng: 125.669,
            gisGcj02Lat: 43.5255,
            gisGcj02Lng: 125.663,
            stubGroupCnt: null,
            pinYin: 'Shuangyang',
          },
          {
            id: '220122',
            text: '农安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.4379,
            gisBd09Lng: 125.192,
            gisGcj02Lat: 44.4318,
            gisGcj02Lng: 125.185,
            stubGroupCnt: null,
            pinYin: "Nong'an",
          },
          {
            id: '220181',
            text: '九台',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.2094,
            gisBd09Lng: 125.985,
            gisGcj02Lat: 44.2032,
            gisGcj02Lng: 125.979,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220182',
            text: '榆树',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.8445,
            gisBd09Lng: 126.539,
            gisGcj02Lat: 44.8389,
            gisGcj02Lng: 126.532,
            stubGroupCnt: null,
            pinYin: 'Yushu',
          },
          {
            id: '220183',
            text: '德惠',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5422,
            gisBd09Lng: 125.712,
            gisGcj02Lat: 44.5365,
            gisGcj02Lng: 125.705,
            stubGroupCnt: null,
            pinYin: 'Dehui',
          },
        ],
        gisBd09Lng: 125.33,
        text: '长春',
        gisBd09Lat: 43.8222,
        gisGcj02Lat: 43.8165,
      },
      {
        pinYin: 'Jilin',
        id: '220200',
        gisGcj02Lng: 126.548,
        children: [
          {
            id: '220201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8426,
            gisBd09Lng: 126.555,
            gisGcj02Lat: 43.8369,
            gisGcj02Lng: 126.548,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220202',
            text: '昌邑区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8874,
            gisBd09Lng: 126.579,
            gisGcj02Lat: 43.8814,
            gisGcj02Lng: 126.573,
            stubGroupCnt: null,
            pinYin: 'Changyi',
          },
          {
            id: '220203',
            text: '龙潭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.916,
            gisBd09Lng: 126.569,
            gisGcj02Lat: 43.9101,
            gisGcj02Lng: 126.563,
            stubGroupCnt: null,
            pinYin: 'Longtan',
          },
          {
            id: '220204',
            text: '船营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8386,
            gisBd09Lng: 126.548,
            gisGcj02Lat: 43.833,
            gisGcj02Lng: 126.541,
            stubGroupCnt: null,
            pinYin: 'Chuanying',
          },
          {
            id: '220211',
            text: '丰满区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8269,
            gisBd09Lng: 126.57,
            gisGcj02Lat: 43.821,
            gisGcj02Lng: 126.563,
            stubGroupCnt: null,
            pinYin: 'Fengman',
          },
          {
            id: '220221',
            text: '永吉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6792,
            gisBd09Lng: 126.503,
            gisGcj02Lat: 43.6729,
            gisGcj02Lng: 126.497,
            stubGroupCnt: null,
            pinYin: 'Yongji',
          },
          {
            id: '220281',
            text: '蛟河',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.7297,
            gisBd09Lng: 127.354,
            gisGcj02Lat: 43.7237,
            gisGcj02Lng: 127.347,
            stubGroupCnt: null,
            pinYin: 'Jiaohe',
          },
          {
            id: '220282',
            text: '桦甸',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9778,
            gisBd09Lng: 126.752,
            gisGcj02Lat: 42.9717,
            gisGcj02Lng: 126.745,
            stubGroupCnt: null,
            pinYin: 'Huadian',
          },
          {
            id: '220283',
            text: '舒兰',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 42.9717,
            gisGcj02Lng: 126.745,
            stubGroupCnt: null,
            pinYin: 'Shulan',
          },
          {
            id: '220284',
            text: '磐石',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9521,
            gisBd09Lng: 126.067,
            gisGcj02Lat: 42.9465,
            gisGcj02Lng: 126.06,
            stubGroupCnt: null,
            pinYin: 'Panshi',
          },
        ],
        gisBd09Lng: 126.555,
        text: '吉林',
        gisBd09Lat: 43.8426,
        gisGcj02Lat: 43.8369,
      },
      {
        pinYin: 'Siping',
        id: '220300',
        gisGcj02Lng: 123.501,
        children: [
          {
            id: '220301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9521,
            gisBd09Lng: 126.067,
            gisGcj02Lat: 42.9465,
            gisGcj02Lng: 126.06,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220302',
            text: '铁西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.1512,
            gisBd09Lng: 124.354,
            gisGcj02Lat: 43.1451,
            gisGcj02Lng: 124.348,
            stubGroupCnt: null,
            pinYin: 'Tiexi',
          },
          {
            id: '220303',
            text: '铁东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.1662,
            gisBd09Lng: 124.418,
            gisGcj02Lat: 43.1602,
            gisGcj02Lng: 124.411,
            stubGroupCnt: null,
            pinYin: 'Tiedong',
          },
          {
            id: '220322',
            text: '梨树县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.3117,
            gisBd09Lng: 124.343,
            gisGcj02Lat: 43.3055,
            gisGcj02Lng: 124.337,
            stubGroupCnt: null,
            pinYin: 'Lishu',
          },
          {
            id: '220323',
            text: '伊通满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.3506,
            gisBd09Lng: 125.312,
            gisGcj02Lat: 43.3445,
            gisGcj02Lng: 125.305,
            stubGroupCnt: null,
            pinYin: 'Yitong',
          },
          {
            id: '220381',
            text: '公主岭',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.5111,
            gisBd09Lng: 124.83,
            gisGcj02Lat: 43.505,
            gisGcj02Lng: 124.824,
            stubGroupCnt: null,
            pinYin: 'Gongzhuling',
          },
          {
            id: '220382',
            text: '双辽',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.5237,
            gisBd09Lng: 123.508,
            gisGcj02Lat: 43.5176,
            gisGcj02Lng: 123.501,
            stubGroupCnt: null,
            pinYin: 'Shuangliao',
          },
        ],
        gisBd09Lng: null,
        text: '四平',
        gisBd09Lat: null,
        gisGcj02Lat: 43.5176,
      },
      {
        pinYin: 'Liaoyuan',
        id: '220400',
        gisGcj02Lng: 125.143,
        children: [
          {
            id: '220401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8941,
            gisBd09Lng: 125.15,
            gisGcj02Lat: 42.8878,
            gisGcj02Lng: 125.143,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220402',
            text: '龙山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9082,
            gisBd09Lng: 125.145,
            gisGcj02Lat: 42.902,
            gisGcj02Lng: 125.139,
            stubGroupCnt: null,
            pinYin: 'Longshan',
          },
          {
            id: '220403',
            text: '西安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.933,
            gisBd09Lng: 125.156,
            gisGcj02Lat: 42.9267,
            gisGcj02Lng: 125.15,
            stubGroupCnt: null,
            pinYin: "Xi'an",
          },
          {
            id: '220421',
            text: '东丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.6823,
            gisBd09Lng: 125.538,
            gisGcj02Lat: 42.676,
            gisGcj02Lng: 125.532,
            stubGroupCnt: null,
            pinYin: 'Dongfeng',
          },
          {
            id: '220422',
            text: '东辽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9317,
            gisBd09Lng: 124.999,
            gisGcj02Lat: 42.926,
            gisGcj02Lng: 124.993,
            stubGroupCnt: null,
            pinYin: 'Dongliao',
          },
        ],
        gisBd09Lng: 125.15,
        text: '辽源',
        gisBd09Lat: 42.8941,
        gisGcj02Lat: 42.8878,
      },
      {
        pinYin: 'Tonghua',
        id: '220500',
        gisGcj02Lng: 125.94,
        children: [
          {
            id: '220501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7334,
            gisBd09Lng: 125.946,
            gisGcj02Lat: 41.7278,
            gisGcj02Lng: 125.94,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220502',
            text: '东昌区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7341,
            gisBd09Lng: 125.962,
            gisGcj02Lat: 41.7284,
            gisGcj02Lng: 125.955,
            stubGroupCnt: null,
            pinYin: 'Dongchang',
          },
          {
            id: '220503',
            text: '二道江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.78,
            gisBd09Lng: 126.049,
            gisGcj02Lat: 41.7742,
            gisGcj02Lng: 126.042,
            stubGroupCnt: null,
            pinYin: 'Erdaojiang',
          },
          {
            id: '220521',
            text: '通化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.6855,
            gisBd09Lng: 125.766,
            gisGcj02Lat: 41.6791,
            gisGcj02Lng: 125.759,
            stubGroupCnt: null,
            pinYin: 'Tonghua',
          },
          {
            id: '220523',
            text: '辉南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.6899,
            gisBd09Lng: 126.054,
            gisGcj02Lat: 42.6842,
            gisGcj02Lng: 126.047,
            stubGroupCnt: null,
            pinYin: 'Huinan',
          },
          {
            id: '220524',
            text: '柳河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2903,
            gisBd09Lng: 125.752,
            gisGcj02Lat: 42.284,
            gisGcj02Lng: 125.745,
            stubGroupCnt: null,
            pinYin: 'Liuhe',
          },
          {
            id: '220581',
            text: '梅河口',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.5445,
            gisBd09Lng: 125.719,
            gisGcj02Lat: 42.5388,
            gisGcj02Lng: 125.713,
            stubGroupCnt: null,
            pinYin: 'Meihekou',
          },
          {
            id: '220582',
            text: '集安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1318,
            gisBd09Lng: 126.2,
            gisGcj02Lat: 41.126,
            gisGcj02Lng: 126.193,
            stubGroupCnt: null,
            pinYin: "Ji'an",
          },
        ],
        gisBd09Lng: 125.946,
        text: '通化',
        gisBd09Lat: 41.7334,
        gisGcj02Lat: 41.7278,
      },
      {
        pinYin: 'Baishan',
        id: '220600',
        gisGcj02Lng: 126.424,
        children: [
          {
            id: '220601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9461,
            gisBd09Lng: 126.431,
            gisGcj02Lat: 41.9404,
            gisGcj02Lng: 126.424,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220602',
            text: '八道江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8891,
            gisBd09Lng: 126.378,
            gisGcj02Lat: 41.8828,
            gisGcj02Lng: 126.371,
            stubGroupCnt: null,
            pinYin: 'Hunjiang',
          },
          {
            id: '220621',
            text: '抚松县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.3477,
            gisBd09Lng: 127.287,
            gisGcj02Lat: 42.3419,
            gisGcj02Lng: 127.28,
            stubGroupCnt: null,
            pinYin: 'Fusong',
          },
          {
            id: '220622',
            text: '靖宇县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.3943,
            gisBd09Lng: 126.82,
            gisGcj02Lat: 42.3883,
            gisGcj02Lng: 126.814,
            stubGroupCnt: null,
            pinYin: 'Jingyu',
          },
          {
            id: '220623',
            text: '长白朝鲜族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.4258,
            gisBd09Lng: 128.207,
            gisGcj02Lat: 41.4201,
            gisGcj02Lng: 128.201,
            stubGroupCnt: null,
            pinYin: 'Changbai',
          },
          {
            id: '220625',
            text: '江源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.095,
            gisBd09Lng: 126.605,
            gisGcj02Lat: 42.0886,
            gisGcj02Lng: 126.599,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220681',
            text: '临江',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8173,
            gisBd09Lng: 126.925,
            gisGcj02Lat: 41.8115,
            gisGcj02Lng: 126.918,
            stubGroupCnt: null,
            pinYin: 'Linjiang',
          },
        ],
        gisBd09Lng: 126.431,
        text: '白山',
        gisBd09Lat: 41.9461,
        gisGcj02Lat: 41.9404,
      },
      {
        pinYin: 'Songyuan',
        id: '220700',
        gisGcj02Lng: 124.825,
        children: [
          {
            id: '220701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.1473,
            gisBd09Lng: 124.832,
            gisGcj02Lat: 45.1412,
            gisGcj02Lng: 124.825,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220702',
            text: '宁江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.1778,
            gisBd09Lng: 124.823,
            gisGcj02Lat: 45.1715,
            gisGcj02Lng: 124.817,
            stubGroupCnt: null,
            pinYin: 'Ningjiang',
          },
          {
            id: '220721',
            text: '前郭尔罗斯蒙古族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.1228,
            gisBd09Lng: 124.83,
            gisGcj02Lat: 45.1167,
            gisGcj02Lng: 124.824,
            stubGroupCnt: null,
            pinYin: 'Qianguoerluosi',
          },
          {
            id: '220722',
            text: '长岭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.2821,
            gisBd09Lng: 123.974,
            gisGcj02Lat: 44.2758,
            gisGcj02Lng: 123.968,
            stubGroupCnt: null,
            pinYin: 'Changling',
          },
          {
            id: '220723',
            text: '乾安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.0102,
            gisBd09Lng: 124.035,
            gisGcj02Lat: 45.0045,
            gisGcj02Lng: 124.028,
            stubGroupCnt: null,
            pinYin: "Qian'an",
          },
          {
            id: '220724',
            text: '扶余县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.1307,
            gisBd09Lng: 125.565,
            gisGcj02Lat: 45.1248,
            gisGcj02Lng: 125.558,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 124.832,
        text: '松原',
        gisBd09Lat: 45.1473,
        gisGcj02Lat: 45.1412,
      },
      {
        pinYin: 'Baicheng',
        id: '220800',
        gisGcj02Lng: 122.839,
        children: [
          {
            id: '220801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.6253,
            gisBd09Lng: 122.845,
            gisGcj02Lat: 45.6195,
            gisGcj02Lng: 122.839,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '220802',
            text: '洮北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.6272,
            gisBd09Lng: 122.857,
            gisGcj02Lat: 45.6212,
            gisGcj02Lng: 122.851,
            stubGroupCnt: null,
            pinYin: 'Taobei',
          },
          {
            id: '220821',
            text: '镇赉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.854,
            gisBd09Lng: 123.206,
            gisGcj02Lat: 45.8482,
            gisGcj02Lng: 123.199,
            stubGroupCnt: null,
            pinYin: 'Zhenlai',
          },
          {
            id: '220822',
            text: '通榆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.8183,
            gisBd09Lng: 123.096,
            gisGcj02Lat: 44.8123,
            gisGcj02Lng: 123.09,
            stubGroupCnt: null,
            pinYin: 'Tongyu',
          },
          {
            id: '220881',
            text: '洮南',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2017.05.02 10:36:52',
            gisBd09Lat: 45.3414,
            gisBd09Lng: 122.793,
            gisGcj02Lat: 45.3354,
            gisGcj02Lng: 122.787,
            stubGroupCnt: null,
            pinYin: 'Taonan',
          },
          {
            id: '220882',
            text: '大安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.5127,
            gisBd09Lng: 124.299,
            gisGcj02Lat: 45.5067,
            gisGcj02Lng: 124.292,
            stubGroupCnt: null,
            pinYin: "Da'an",
          },
        ],
        gisBd09Lng: 122.845,
        text: '白城',
        gisBd09Lat: 45.6253,
        gisGcj02Lat: 45.6195,
      },
      {
        pinYin: 'Yanbian',
        id: '222400',
        gisGcj02Lng: 129.51,
        children: [
          {
            id: '222401',
            text: '延吉',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8974,
            gisBd09Lng: 129.517,
            gisGcj02Lat: 42.8914,
            gisGcj02Lng: 129.51,
            stubGroupCnt: null,
            pinYin: 'Yanji',
          },
          {
            id: '222402',
            text: '图们',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9738,
            gisBd09Lng: 129.851,
            gisGcj02Lat: 42.9674,
            gisGcj02Lng: 129.844,
            stubGroupCnt: null,
            pinYin: 'Tumen',
          },
          {
            id: '222403',
            text: '敦化',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.3778,
            gisBd09Lng: 128.24,
            gisGcj02Lat: 43.3721,
            gisGcj02Lng: 128.233,
            stubGroupCnt: null,
            pinYin: 'Dunhua',
          },
          {
            id: '222404',
            text: '珲春',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8657,
            gisBd09Lng: 130.374,
            gisGcj02Lat: 42.86,
            gisGcj02Lng: 130.367,
            stubGroupCnt: null,
            pinYin: 'Hunchun',
          },
          {
            id: '222405',
            text: '龙井',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7713,
            gisBd09Lng: 129.433,
            gisGcj02Lat: 42.7656,
            gisGcj02Lng: 129.426,
            stubGroupCnt: null,
            pinYin: 'Longjing',
          },
          {
            id: '222406',
            text: '和龙',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.5525,
            gisBd09Lng: 129.017,
            gisGcj02Lat: 42.5462,
            gisGcj02Lng: 129.01,
            stubGroupCnt: null,
            pinYin: 'Helong',
          },
          {
            id: '222424',
            text: '汪清县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.3181,
            gisBd09Lng: 129.778,
            gisGcj02Lat: 43.3124,
            gisGcj02Lng: 129.771,
            stubGroupCnt: null,
            pinYin: 'Wangqing',
          },
          {
            id: '222426',
            text: '安图县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.1175,
            gisBd09Lng: 128.907,
            gisGcj02Lat: 43.1113,
            gisGcj02Lng: 128.9,
            stubGroupCnt: null,
            pinYin: 'Antu',
          },
        ],
        gisBd09Lng: 129.517,
        text: '延边朝鲜族自治州',
        gisBd09Lat: 42.8953,
        gisGcj02Lat: 42.8893,
      },
    ],
  },
  {
    text: '江苏省',
    children: [
      {
        pinYin: 'Nanjing',
        id: '320100',
        gisGcj02Lng: 118.797,
        children: [
          {
            id: '320101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0649,
            gisBd09Lng: 118.804,
            gisGcj02Lat: 32.0585,
            gisGcj02Lng: 118.797,
            stubGroupCnt: 6,
            pinYin: null,
          },
          {
            id: '320102',
            text: '玄武区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0545,
            gisBd09Lng: 118.804,
            gisGcj02Lat: 32.0482,
            gisGcj02Lng: 118.798,
            stubGroupCnt: 2,
            pinYin: 'Xuanwu',
          },
          {
            id: '320103',
            text: '白下区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0302,
            gisBd09Lng: 118.83,
            gisGcj02Lat: 32.0241,
            gisGcj02Lng: 118.824,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320104',
            text: '秦淮区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.045,
            gisBd09Lng: 118.801,
            gisGcj02Lat: 32.0387,
            gisGcj02Lng: 118.795,
            stubGroupCnt: 6,
            pinYin: 'Qinhuai',
          },
          {
            id: '320105',
            text: '建邺区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0098,
            gisBd09Lng: 118.74,
            gisGcj02Lat: 32.0041,
            gisGcj02Lng: 118.734,
            stubGroupCnt: 3,
            pinYin: 'Jianye',
          },
          {
            id: '320106',
            text: '鼓楼区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0719,
            gisBd09Lng: 118.778,
            gisGcj02Lat: 32.0659,
            gisGcj02Lng: 118.771,
            stubGroupCnt: 3,
            pinYin: 'Gulou',
          },
          {
            id: '320107',
            text: '下关区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1091,
            gisBd09Lng: 118.773,
            gisGcj02Lat: 32.1032,
            gisGcj02Lng: 118.767,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320111',
            text: '浦口区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0652,
            gisBd09Lng: 118.635,
            gisGcj02Lat: 32.0595,
            gisGcj02Lng: 118.628,
            stubGroupCnt: 0,
            pinYin: 'Pukou',
          },
          {
            id: '320113',
            text: '栖霞区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1022,
            gisBd09Lng: 118.915,
            gisGcj02Lat: 32.0959,
            gisGcj02Lng: 118.909,
            stubGroupCnt: 3,
            pinYin: 'Qixia',
          },
          {
            id: '320114',
            text: '雨花台区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.997,
            gisBd09Lng: 118.785,
            gisGcj02Lat: 31.9909,
            gisGcj02Lng: 118.779,
            stubGroupCnt: 4,
            pinYin: 'Yuhuatai',
          },
          {
            id: '320115',
            text: '江宁区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9592,
            gisBd09Lng: 118.846,
            gisGcj02Lat: 31.9533,
            gisGcj02Lng: 118.839,
            stubGroupCnt: 5,
            pinYin: 'Jiangning',
          },
          {
            id: '320116',
            text: '六合区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3287,
            gisBd09Lng: 118.828,
            gisGcj02Lat: 32.3225,
            gisGcj02Lng: 118.821,
            stubGroupCnt: null,
            pinYin: 'Luhe',
          },
          {
            id: '320124',
            text: '溧水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5976,
            gisBd09Lng: 119.042,
            gisGcj02Lat: 31.5912,
            gisGcj02Lng: 119.036,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320125',
            text: '高淳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3359,
            gisBd09Lng: 118.972,
            gisGcj02Lat: 31.3301,
            gisGcj02Lng: 118.965,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 118.804,
        text: '南京',
        gisBd09Lat: 32.0649,
        gisGcj02Lat: 32.0585,
      },
      {
        pinYin: 'Wuxi',
        id: '320200',
        gisGcj02Lng: 120.312,
        children: [
          {
            id: '320201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4967,
            gisBd09Lng: 120.319,
            gisGcj02Lat: 31.491,
            gisGcj02Lng: 120.313,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320202',
            text: '崇安区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5716,
            gisBd09Lng: 120.31,
            gisGcj02Lat: 31.5659,
            gisGcj02Lng: 120.303,
            stubGroupCnt: 0,
            pinYin: "Chong'an",
          },
          {
            id: '320203',
            text: '南长区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5692,
            gisBd09Lng: 120.316,
            gisGcj02Lat: 31.5636,
            gisGcj02Lng: 120.309,
            stubGroupCnt: null,
            pinYin: 'Nanchang',
          },
          {
            id: '320204',
            text: '北塘区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6115,
            gisBd09Lng: 120.3,
            gisGcj02Lat: 31.6059,
            gisGcj02Lng: 120.294,
            stubGroupCnt: null,
            pinYin: 'Beitang',
          },
          {
            id: '320205',
            text: '锡山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5941,
            gisBd09Lng: 120.365,
            gisGcj02Lat: 31.5877,
            gisGcj02Lng: 120.358,
            stubGroupCnt: null,
            pinYin: 'Xishan',
          },
          {
            id: '320206',
            text: '惠山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6868,
            gisBd09Lng: 120.305,
            gisGcj02Lat: 31.6812,
            gisGcj02Lng: 120.299,
            stubGroupCnt: null,
            pinYin: 'Huishan',
          },
          {
            id: '320211',
            text: '滨湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5348,
            gisBd09Lng: 120.292,
            gisGcj02Lat: 31.5291,
            gisGcj02Lng: 120.285,
            stubGroupCnt: null,
            pinYin: 'Binhu',
          },
          {
            id: '320281',
            text: '江阴',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9405,
            gisBd09Lng: 120.297,
            gisGcj02Lat: 31.9348,
            gisGcj02Lng: 120.29,
            stubGroupCnt: null,
            pinYin: 'Jiangyin',
          },
          {
            id: '320282',
            text: '宜兴',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3466,
            gisBd09Lng: 119.83,
            gisGcj02Lat: 31.3409,
            gisGcj02Lng: 119.823,
            stubGroupCnt: null,
            pinYin: 'Yixing',
          },
        ],
        gisBd09Lng: 120.319,
        text: '无锡',
        gisBd09Lat: 31.4967,
        gisGcj02Lat: 31.491,
      },
      {
        pinYin: 'Xuzhou',
        id: '320300',
        gisGcj02Lng: 117.284,
        children: [
          {
            id: '320301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2118,
            gisBd09Lng: 117.291,
            gisGcj02Lat: 34.206,
            gisGcj02Lng: 117.284,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320302',
            text: '鼓楼区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2939,
            gisBd09Lng: 117.192,
            gisGcj02Lat: 34.2883,
            gisGcj02Lng: 117.186,
            stubGroupCnt: 0,
            pinYin: 'Gulou',
          },
          {
            id: '320303',
            text: '云龙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2551,
            gisBd09Lng: 117.238,
            gisGcj02Lat: 34.2487,
            gisGcj02Lng: 117.231,
            stubGroupCnt: null,
            pinYin: 'Yunlong',
          },
          {
            id: '320304',
            text: '九里区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3217,
            gisBd09Lng: 117.125,
            gisGcj02Lat: 34.3153,
            gisGcj02Lng: 117.119,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320305',
            text: '贾汪区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4499,
            gisBd09Lng: 117.46,
            gisGcj02Lat: 34.4438,
            gisGcj02Lng: 117.454,
            stubGroupCnt: null,
            pinYin: 'Jiawang',
          },
          {
            id: '320311',
            text: '泉山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.05.26 15:39:53',
            gisBd09Lat: 34.25,
            gisBd09Lng: 117.2,
            gisGcj02Lat: 34.2443,
            gisGcj02Lng: 117.193,
            stubGroupCnt: null,
            pinYin: 'Quanshan',
          },
          {
            id: '320321',
            text: '丰　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6996,
            gisBd09Lng: 116.6,
            gisGcj02Lat: 34.6939,
            gisGcj02Lng: 116.594,
            stubGroupCnt: null,
            pinYin: 'Fengxian',
          },
          {
            id: '320322',
            text: '沛　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7273,
            gisBd09Lng: 116.944,
            gisGcj02Lat: 34.7216,
            gisGcj02Lng: 116.938,
            stubGroupCnt: null,
            pinYin: 'Peixian',
          },
          {
            id: '320323',
            text: '铜山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2889,
            gisBd09Lng: 117.295,
            gisGcj02Lat: 34.2832,
            gisGcj02Lng: 117.288,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320324',
            text: '睢宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9185,
            gisBd09Lng: 117.947,
            gisGcj02Lat: 33.9123,
            gisGcj02Lng: 117.941,
            stubGroupCnt: null,
            pinYin: 'Suining',
          },
          {
            id: '320381',
            text: '新沂',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3751,
            gisBd09Lng: 118.361,
            gisGcj02Lat: 34.3691,
            gisGcj02Lng: 118.355,
            stubGroupCnt: null,
            pinYin: 'Xinyi',
          },
          {
            id: '320382',
            text: '邳州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3402,
            gisBd09Lng: 118.019,
            gisGcj02Lat: 34.3345,
            gisGcj02Lng: 118.012,
            stubGroupCnt: null,
            pinYin: 'Pizhou',
          },
        ],
        gisBd09Lng: 117.291,
        text: '徐州',
        gisBd09Lat: 34.2118,
        gisGcj02Lat: 34.206,
      },
      {
        pinYin: 'Changzhou',
        id: '320400',
        gisGcj02Lng: 119.957,
        children: [
          {
            id: '320401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.787,
            gisBd09Lng: 119.969,
            gisGcj02Lat: 31.7812,
            gisGcj02Lng: 119.962,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '320402',
            text: '天宁区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7858,
            gisBd09Lng: 119.981,
            gisGcj02Lat: 31.7797,
            gisGcj02Lng: 119.975,
            stubGroupCnt: 23,
            pinYin: 'Tianning',
          },
          {
            id: '320404',
            text: '钟楼区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8078,
            gisBd09Lng: 119.908,
            gisGcj02Lat: 31.8016,
            gisGcj02Lng: 119.901,
            stubGroupCnt: 14,
            pinYin: 'Zhonglou',
          },
          {
            id: '320405',
            text: '戚墅堰区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7629,
            gisBd09Lng: 120.055,
            gisGcj02Lat: 31.7572,
            gisGcj02Lng: 120.048,
            stubGroupCnt: 6,
            pinYin: 'Qishuyan',
          },
          {
            id: '320411',
            text: '新北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8395,
            gisBd09Lng: 119.977,
            gisGcj02Lat: 31.8335,
            gisGcj02Lng: 119.97,
            stubGroupCnt: 31,
            pinYin: 'Xinbei',
          },
          {
            id: '320412',
            text: '武进区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7083,
            gisBd09Lng: 119.947,
            gisGcj02Lat: 31.7026,
            gisGcj02Lng: 119.941,
            stubGroupCnt: 117,
            pinYin: 'Wujin',
          },
          {
            id: '320481',
            text: '溧阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4188,
            gisBd09Lng: 119.492,
            gisGcj02Lat: 31.4129,
            gisGcj02Lng: 119.486,
            stubGroupCnt: 0,
            pinYin: 'Liyang',
          },
          {
            id: '320482',
            text: '金坛',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7261,
            gisBd09Lng: 119.603,
            gisGcj02Lat: 31.7203,
            gisGcj02Lng: 119.597,
            stubGroupCnt: 11,
            pinYin: 'Jintan',
          },
        ],
        gisBd09Lng: 119.963,
        text: '常州',
        gisBd09Lat: 31.7244,
        gisGcj02Lat: 31.7186,
      },
      {
        pinYin: 'Suzhou',
        id: '320500',
        gisGcj02Lng: 120.583,
        children: [
          {
            id: '320501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3045,
            gisBd09Lng: 120.59,
            gisGcj02Lat: 31.2983,
            gisGcj02Lng: 120.583,
            stubGroupCnt: 11,
            pinYin: null,
          },
          {
            id: '320502',
            text: '沧浪区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2955,
            gisBd09Lng: 120.617,
            gisGcj02Lat: 31.2892,
            gisGcj02Lng: 120.611,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '320503',
            text: '平江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3319,
            gisBd09Lng: 120.631,
            gisGcj02Lat: 31.3258,
            gisGcj02Lng: 120.625,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '320504',
            text: '金阊区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3443,
            gisBd09Lng: 120.582,
            gisGcj02Lat: 31.3382,
            gisGcj02Lng: 120.576,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320505',
            text: '虎丘区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3031,
            gisBd09Lng: 120.579,
            gisGcj02Lat: 31.297,
            gisGcj02Lng: 120.573,
            stubGroupCnt: 7,
            pinYin: 'Huqiu',
          },
          {
            id: '320506',
            text: '吴中区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2689,
            gisBd09Lng: 120.64,
            gisGcj02Lat: 31.263,
            gisGcj02Lng: 120.634,
            stubGroupCnt: 4,
            pinYin: 'Wuzhong',
          },
          {
            id: '320507',
            text: '相城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3743,
            gisBd09Lng: 120.65,
            gisGcj02Lat: 31.3686,
            gisGcj02Lng: 120.643,
            stubGroupCnt: 1,
            pinYin: 'Xiangcheng',
          },
          {
            id: '320581',
            text: '常熟',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6602,
            gisBd09Lng: 120.76,
            gisGcj02Lat: 31.6543,
            gisGcj02Lng: 120.753,
            stubGroupCnt: null,
            pinYin: 'Changshu',
          },
          {
            id: '320582',
            text: '张家港',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8817,
            gisBd09Lng: 120.563,
            gisGcj02Lat: 31.8759,
            gisGcj02Lng: 120.556,
            stubGroupCnt: null,
            pinYin: 'Zhangjiagang',
          },
          {
            id: '320583',
            text: '昆山',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3913,
            gisBd09Lng: 120.989,
            gisGcj02Lat: 31.3852,
            gisGcj02Lng: 120.983,
            stubGroupCnt: null,
            pinYin: 'Kunshan',
          },
          {
            id: '320584',
            text: '吴江',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.015,
            gisBd09Lng: 120.641,
            gisGcj02Lat: 31.009,
            gisGcj02Lng: 120.634,
            stubGroupCnt: 5,
            pinYin: null,
          },
          {
            id: '320585',
            text: '太仓',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.465,
            gisBd09Lng: 121.135,
            gisGcj02Lat: 31.4593,
            gisGcj02Lng: 121.129,
            stubGroupCnt: 2,
            pinYin: 'Taicang',
          },
          {
            id: '320586',
            text: '吴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.07.11 14:06:08',
            gisBd09Lat: 0.0,
            gisBd09Lng: 0.0,
            gisGcj02Lat: -0.00600417,
            gisGcj02Lng: -0.00650456,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 120.59,
        text: '苏州',
        gisBd09Lat: 31.3045,
        gisGcj02Lat: 31.2983,
      },
      {
        pinYin: 'Nantong',
        id: '320600',
        gisGcj02Lng: 120.893,
        children: [
          {
            id: '320601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9856,
            gisBd09Lng: 120.9,
            gisGcj02Lat: 31.9799,
            gisGcj02Lng: 120.893,
            stubGroupCnt: 0,
            pinYin: null,
          },
          {
            id: '320602',
            text: '崇川区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0169,
            gisBd09Lng: 120.865,
            gisGcj02Lat: 32.0107,
            gisGcj02Lng: 120.858,
            stubGroupCnt: 8,
            pinYin: 'Chongchuan',
          },
          {
            id: '320611',
            text: '港闸区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0378,
            gisBd09Lng: 120.824,
            gisGcj02Lat: 32.0317,
            gisGcj02Lng: 120.818,
            stubGroupCnt: 3,
            pinYin: 'Gangzha',
          },
          {
            id: '320621',
            text: '海安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5423,
            gisBd09Lng: 120.475,
            gisGcj02Lat: 32.536,
            gisGcj02Lng: 120.469,
            stubGroupCnt: null,
            pinYin: "Hai'an",
          },
          {
            id: '320623',
            text: '如东县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3348,
            gisBd09Lng: 121.193,
            gisGcj02Lat: 32.3286,
            gisGcj02Lng: 121.186,
            stubGroupCnt: null,
            pinYin: 'Rudong',
          },
          {
            id: '320681',
            text: '启东',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.814,
            gisBd09Lng: 121.664,
            gisGcj02Lat: 31.8078,
            gisGcj02Lng: 121.658,
            stubGroupCnt: null,
            pinYin: 'Qidong',
          },
          {
            id: '320682',
            text: '如皋',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3775,
            gisBd09Lng: 120.58,
            gisGcj02Lat: 32.3714,
            gisGcj02Lng: 120.574,
            stubGroupCnt: null,
            pinYin: 'Rugao',
          },
          {
            id: '320683',
            text: '通州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0723,
            gisBd09Lng: 121.08,
            gisGcj02Lat: 32.066,
            gisGcj02Lng: 121.074,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '320684',
            text: '海门',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8773,
            gisBd09Lng: 121.19,
            gisGcj02Lat: 31.8711,
            gisGcj02Lng: 121.183,
            stubGroupCnt: null,
            pinYin: 'Haimen',
          },
        ],
        gisBd09Lng: 120.9,
        text: '南通',
        gisBd09Lat: 31.9856,
        gisGcj02Lat: 31.9799,
      },
      {
        pinYin: 'Lianyungang',
        id: '320700',
        gisGcj02Lng: 119.223,
        children: [
          {
            id: '320701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6017,
            gisBd09Lng: 119.23,
            gisGcj02Lat: 34.5961,
            gisGcj02Lng: 119.223,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320703',
            text: '连云区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7638,
            gisBd09Lng: 119.347,
            gisGcj02Lat: 34.7582,
            gisGcj02Lng: 119.34,
            stubGroupCnt: null,
            pinYin: 'Lianyun',
          },
          {
            id: '320705',
            text: '新浦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6565,
            gisBd09Lng: 119.261,
            gisGcj02Lat: 34.6505,
            gisGcj02Lng: 119.254,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320706',
            text: '海州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5778,
            gisBd09Lng: 119.17,
            gisGcj02Lat: 34.5714,
            gisGcj02Lng: 119.164,
            stubGroupCnt: null,
            pinYin: 'Haizhou',
          },
          {
            id: '320721',
            text: '赣榆县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8904,
            gisBd09Lng: 119.042,
            gisGcj02Lat: 34.884,
            gisGcj02Lng: 119.036,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320722',
            text: '东海县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5481,
            gisBd09Lng: 118.778,
            gisGcj02Lat: 34.542,
            gisGcj02Lng: 118.771,
            stubGroupCnt: null,
            pinYin: 'Donghai',
          },
          {
            id: '320723',
            text: '灌云县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2894,
            gisBd09Lng: 119.248,
            gisGcj02Lat: 34.2836,
            gisGcj02Lng: 119.241,
            stubGroupCnt: null,
            pinYin: 'Guanyun',
          },
          {
            id: '320724',
            text: '灌南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.092,
            gisBd09Lng: 119.322,
            gisGcj02Lat: 34.0861,
            gisGcj02Lng: 119.316,
            stubGroupCnt: null,
            pinYin: 'Guannan',
          },
        ],
        gisBd09Lng: 119.23,
        text: '连云港',
        gisBd09Lat: 34.6017,
        gisGcj02Lat: 34.5961,
      },
      {
        pinYin: "Huai'an",
        id: '320800',
        gisGcj02Lng: 119.017,
        children: [
          {
            id: '320801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.616,
            gisBd09Lng: 119.023,
            gisGcj02Lat: 33.6099,
            gisGcj02Lng: 119.016,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320802',
            text: '清河区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6039,
            gisBd09Lng: 119.015,
            gisGcj02Lat: 33.598,
            gisGcj02Lng: 119.008,
            stubGroupCnt: null,
            pinYin: 'Qinghe',
          },
          {
            id: '320803',
            text: '楚州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.504,
            gisBd09Lng: 119.303,
            gisGcj02Lat: 33.4978,
            gisGcj02Lng: 119.296,
            stubGroupCnt: null,
            pinYin: "Huai'an",
          },
          {
            id: '320804',
            text: '淮阴区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6362,
            gisBd09Lng: 119.042,
            gisGcj02Lat: 33.6298,
            gisGcj02Lng: 119.035,
            stubGroupCnt: null,
            pinYin: 'Huaiyin',
          },
          {
            id: '320811',
            text: '清浦区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5575,
            gisBd09Lng: 119.034,
            gisGcj02Lat: 33.5513,
            gisGcj02Lng: 119.027,
            stubGroupCnt: null,
            pinYin: 'Qingpu',
          },
          {
            id: '320826',
            text: '涟水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7863,
            gisBd09Lng: 119.269,
            gisGcj02Lat: 33.7801,
            gisGcj02Lng: 119.262,
            stubGroupCnt: null,
            pinYin: 'Lianshui',
          },
          {
            id: '320829',
            text: '洪泽县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2975,
            gisBd09Lng: 118.881,
            gisGcj02Lat: 33.2918,
            gisGcj02Lng: 118.874,
            stubGroupCnt: null,
            pinYin: 'Hongze',
          },
          {
            id: '320830',
            text: '盱眙县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0171,
            gisBd09Lng: 118.55,
            gisGcj02Lat: 33.0109,
            gisGcj02Lng: 118.543,
            stubGroupCnt: null,
            pinYin: 'Xuyi',
          },
          {
            id: '320831',
            text: '金湖县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0297,
            gisBd09Lng: 119.028,
            gisGcj02Lat: 33.0234,
            gisGcj02Lng: 119.022,
            stubGroupCnt: null,
            pinYin: 'Jinhu',
          },
        ],
        gisBd09Lng: 119.023,
        text: '淮安',
        gisBd09Lat: 33.616,
        gisGcj02Lat: 33.6099,
      },
      {
        pinYin: 'Yancheng',
        id: '320900',
        gisGcj02Lng: 120.162,
        children: [
          {
            id: '320901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3551,
            gisBd09Lng: 120.169,
            gisGcj02Lat: 33.3494,
            gisGcj02Lng: 120.162,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '320902',
            text: '亭湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3944,
            gisBd09Lng: 120.202,
            gisGcj02Lat: 33.3887,
            gisGcj02Lng: 120.195,
            stubGroupCnt: 4,
            pinYin: 'Tinghu',
          },
          {
            id: '320903',
            text: '盐都区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.343,
            gisBd09Lng: 120.161,
            gisGcj02Lat: 33.3371,
            gisGcj02Lng: 120.154,
            stubGroupCnt: 1,
            pinYin: 'Yandu',
          },
          {
            id: '320921',
            text: '响水县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2045,
            gisBd09Lng: 119.586,
            gisGcj02Lat: 34.1988,
            gisGcj02Lng: 119.58,
            stubGroupCnt: null,
            pinYin: 'Xiangshui',
          },
          {
            id: '320922',
            text: '滨海县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.993,
            gisBd09Lng: 119.828,
            gisGcj02Lat: 33.9874,
            gisGcj02Lng: 119.821,
            stubGroupCnt: null,
            pinYin: 'Binhai',
          },
          {
            id: '320923',
            text: '阜宁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7646,
            gisBd09Lng: 119.81,
            gisGcj02Lat: 33.7588,
            gisGcj02Lng: 119.803,
            stubGroupCnt: null,
            pinYin: 'Funing',
          },
          {
            id: '320924',
            text: '射阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7808,
            gisBd09Lng: 120.265,
            gisGcj02Lat: 33.7746,
            gisGcj02Lng: 120.258,
            stubGroupCnt: null,
            pinYin: 'Sheyang',
          },
          {
            id: '320925',
            text: '建湖县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.468,
            gisBd09Lng: 119.804,
            gisGcj02Lat: 33.4621,
            gisGcj02Lng: 119.797,
            stubGroupCnt: null,
            pinYin: 'Jianhu',
          },
          {
            id: '320981',
            text: '东台',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8729,
            gisBd09Lng: 120.326,
            gisGcj02Lat: 32.8671,
            gisGcj02Lng: 120.32,
            stubGroupCnt: null,
            pinYin: 'Dongtai',
          },
          {
            id: '320982',
            text: '大丰',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2048,
            gisBd09Lng: 120.507,
            gisGcj02Lat: 33.1987,
            gisGcj02Lng: 120.501,
            stubGroupCnt: 2,
            pinYin: 'Dafeng',
          },
        ],
        gisBd09Lng: 120.169,
        text: '盐城',
        gisBd09Lat: 33.3551,
        gisGcj02Lat: 33.3494,
      },
      {
        pinYin: 'Yangzhou',
        id: '321000',
        gisGcj02Lng: 119.414,
        children: [
          {
            id: '321001',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3994,
            gisBd09Lng: 119.42,
            gisGcj02Lat: 32.3931,
            gisGcj02Lng: 119.413,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '321002',
            text: '广陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4004,
            gisBd09Lng: 119.439,
            gisGcj02Lat: 32.3945,
            gisGcj02Lng: 119.432,
            stubGroupCnt: null,
            pinYin: 'Guangling',
          },
          {
            id: '321003',
            text: '邗江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3823,
            gisBd09Lng: 119.404,
            gisGcj02Lat: 32.376,
            gisGcj02Lng: 119.397,
            stubGroupCnt: null,
            pinYin: 'Hanjiang',
          },
          {
            id: '321011',
            text: '郊　区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9105,
            gisBd09Lng: 117.808,
            gisGcj02Lat: 30.9047,
            gisGcj02Lng: 117.801,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '321023',
            text: '宝应县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2472,
            gisBd09Lng: 119.365,
            gisGcj02Lat: 33.2414,
            gisGcj02Lng: 119.358,
            stubGroupCnt: null,
            pinYin: 'Baoying',
          },
          {
            id: '321081',
            text: '仪征',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2779,
            gisBd09Lng: 119.191,
            gisGcj02Lat: 32.2718,
            gisGcj02Lng: 119.184,
            stubGroupCnt: null,
            pinYin: 'Yizheng',
          },
          {
            id: '321084',
            text: '高邮',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7866,
            gisBd09Lng: 119.462,
            gisGcj02Lat: 32.781,
            gisGcj02Lng: 119.456,
            stubGroupCnt: null,
            pinYin: 'Gaoyou',
          },
          {
            id: '321088',
            text: '江都',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4401,
            gisBd09Lng: 119.577,
            gisGcj02Lat: 32.4344,
            gisGcj02Lng: 119.57,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 119.42,
        text: '扬州',
        gisBd09Lat: 32.3994,
        gisGcj02Lat: 32.3931,
      },
      {
        pinYin: 'Zhenjiang',
        id: '321100',
        gisGcj02Lng: 119.425,
        children: [
          {
            id: '321101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1956,
            gisBd09Lng: 119.432,
            gisGcj02Lat: 32.1895,
            gisGcj02Lng: 119.425,
            stubGroupCnt: 3,
            pinYin: null,
          },
          {
            id: '321102',
            text: '京口区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2036,
            gisBd09Lng: 119.476,
            gisGcj02Lat: 32.1979,
            gisGcj02Lng: 119.47,
            stubGroupCnt: 3,
            pinYin: 'Jingkou',
          },
          {
            id: '321111',
            text: '润州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2017,
            gisBd09Lng: 119.418,
            gisGcj02Lat: 32.1954,
            gisGcj02Lng: 119.412,
            stubGroupCnt: 3,
            pinYin: 'Runzhou',
          },
          {
            id: '321112',
            text: '丹徒区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1374,
            gisBd09Lng: 119.441,
            gisGcj02Lat: 32.1315,
            gisGcj02Lng: 119.435,
            stubGroupCnt: 4,
            pinYin: 'Dantu',
          },
          {
            id: '321181',
            text: '丹阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0148,
            gisBd09Lng: 119.614,
            gisGcj02Lat: 32.0088,
            gisGcj02Lng: 119.608,
            stubGroupCnt: null,
            pinYin: 'Danyang',
          },
          {
            id: '321182',
            text: '扬中',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2428,
            gisBd09Lng: 119.803,
            gisGcj02Lat: 32.2369,
            gisGcj02Lng: 119.797,
            stubGroupCnt: null,
            pinYin: 'Yangzhong',
          },
          {
            id: '321183',
            text: '句容',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9515,
            gisBd09Lng: 119.176,
            gisGcj02Lat: 31.9452,
            gisGcj02Lng: 119.169,
            stubGroupCnt: null,
            pinYin: 'Jurong',
          },
          {
            id: '321190',
            text: '新区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 31.9452,
            gisGcj02Lng: 119.169,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 119.432,
        text: '镇江',
        gisBd09Lat: 32.1956,
        gisGcj02Lat: 32.1895,
      },
      {
        pinYin: 'Taizhou',
        id: '321200',
        gisGcj02Lng: 119.925,
        children: [
          {
            id: '321201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4622,
            gisBd09Lng: 119.932,
            gisGcj02Lat: 32.4564,
            gisGcj02Lng: 119.926,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '321202',
            text: '海陵区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4967,
            gisBd09Lng: 119.926,
            gisGcj02Lat: 32.4908,
            gisGcj02Lng: 119.919,
            stubGroupCnt: null,
            pinYin: 'Hailing',
          },
          {
            id: '321203',
            text: '高港区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3248,
            gisBd09Lng: 119.888,
            gisGcj02Lat: 32.3184,
            gisGcj02Lng: 119.881,
            stubGroupCnt: null,
            pinYin: 'Gaogang',
          },
          {
            id: '321281',
            text: '兴化',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9164,
            gisBd09Lng: 119.858,
            gisGcj02Lat: 32.9104,
            gisGcj02Lng: 119.852,
            stubGroupCnt: null,
            pinYin: 'Xinghua',
          },
          {
            id: '321282',
            text: '靖江',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0208,
            gisBd09Lng: 120.281,
            gisGcj02Lat: 32.0149,
            gisGcj02Lng: 120.275,
            stubGroupCnt: null,
            pinYin: 'Jingjiang',
          },
          {
            id: '321283',
            text: '泰兴',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '889bc8d8-d36a-4fe5-bbd3-cfd471a5e56b',
            modifyTime: '2016.03.30 13:08:30',
            gisBd09Lat: 32.1775,
            gisBd09Lng: 120.059,
            gisGcj02Lat: 32.1718,
            gisGcj02Lng: 120.052,
            stubGroupCnt: 1,
            pinYin: 'Taixing',
          },
          {
            id: '321284',
            text: '姜堰',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5263,
            gisBd09Lng: 120.091,
            gisGcj02Lat: 32.5204,
            gisGcj02Lng: 120.085,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 119.932,
        text: '泰州',
        gisBd09Lat: 32.4622,
        gisGcj02Lat: 32.4565,
      },
      {
        pinYin: 'Suqian',
        id: '321300',
        gisGcj02Lng: 118.276,
        children: [
          {
            id: '321301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9677,
            gisBd09Lng: 118.283,
            gisGcj02Lat: 33.962,
            gisGcj02Lng: 118.276,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '321302',
            text: '宿城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9684,
            gisBd09Lng: 118.249,
            gisGcj02Lat: 33.9626,
            gisGcj02Lng: 118.242,
            stubGroupCnt: 2,
            pinYin: 'Sucheng',
          },
          {
            id: '321311',
            text: '宿豫区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9534,
            gisBd09Lng: 118.337,
            gisGcj02Lat: 33.9471,
            gisGcj02Lng: 118.33,
            stubGroupCnt: null,
            pinYin: 'Suyu',
          },
          {
            id: '321322',
            text: '沭阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2017.06.07 10:19:15',
            gisBd09Lat: 34.119,
            gisBd09Lng: 118.809,
            gisGcj02Lat: 34.1126,
            gisGcj02Lng: 118.803,
            stubGroupCnt: 1,
            pinYin: 'Shuyang',
          },
          {
            id: '321323',
            text: '泗阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7277,
            gisBd09Lng: 118.711,
            gisGcj02Lat: 33.7216,
            gisGcj02Lng: 118.705,
            stubGroupCnt: 2,
            pinYin: 'Siyang',
          },
          {
            id: '321324',
            text: '泗洪县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4646,
            gisBd09Lng: 118.223,
            gisGcj02Lat: 33.4583,
            gisGcj02Lng: 118.217,
            stubGroupCnt: null,
            pinYin: 'Sihong',
          },
        ],
        gisBd09Lng: 118.283,
        text: '宿迁',
        gisBd09Lat: 33.9677,
        gisGcj02Lat: 33.962,
      },
    ],
  },
  {
    text: '江西省',
    children: [
      {
        pinYin: 'Nanchang',
        id: '360100',
        gisGcj02Lng: 115.857,
        children: [
          {
            id: '360101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6864,
            gisBd09Lng: 115.864,
            gisGcj02Lat: 28.6808,
            gisGcj02Lng: 115.857,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360102',
            text: '东湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6903,
            gisBd09Lng: 115.909,
            gisGcj02Lat: 28.6841,
            gisGcj02Lng: 115.902,
            stubGroupCnt: null,
            pinYin: 'Donghu',
          },
          {
            id: '360103',
            text: '西湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6616,
            gisBd09Lng: 115.885,
            gisGcj02Lat: 28.6558,
            gisGcj02Lng: 115.879,
            stubGroupCnt: null,
            pinYin: 'Xihu',
          },
          {
            id: '360104',
            text: '青云谱区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6259,
            gisBd09Lng: 115.932,
            gisGcj02Lat: 28.6196,
            gisGcj02Lng: 115.926,
            stubGroupCnt: null,
            pinYin: 'Qingyunpu',
          },
          {
            id: '360105',
            text: '湾里区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7188,
            gisBd09Lng: 115.737,
            gisGcj02Lat: 28.7131,
            gisGcj02Lng: 115.731,
            stubGroupCnt: null,
            pinYin: 'Wanli',
          },
          {
            id: '360111',
            text: '青山湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.687,
            gisBd09Lng: 115.969,
            gisGcj02Lat: 28.6812,
            gisGcj02Lng: 115.962,
            stubGroupCnt: null,
            pinYin: 'Qingshanhu',
          },
          {
            id: '360121',
            text: '南昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5494,
            gisBd09Lng: 115.949,
            gisGcj02Lat: 28.5433,
            gisGcj02Lng: 115.943,
            stubGroupCnt: null,
            pinYin: 'Nanchang',
          },
          {
            id: '360122',
            text: '新建县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6976,
            gisBd09Lng: 115.822,
            gisGcj02Lat: 28.6914,
            gisGcj02Lng: 115.815,
            stubGroupCnt: null,
            pinYin: 'Xinjian',
          },
          {
            id: '360123',
            text: '安义县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8505,
            gisBd09Lng: 115.556,
            gisGcj02Lat: 28.8442,
            gisGcj02Lng: 115.55,
            stubGroupCnt: null,
            pinYin: 'Anyi',
          },
          {
            id: '360124',
            text: '进贤县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3826,
            gisBd09Lng: 116.25,
            gisGcj02Lat: 28.3767,
            gisGcj02Lng: 116.243,
            stubGroupCnt: null,
            pinYin: 'Jinxian',
          },
        ],
        gisBd09Lng: 115.864,
        text: '南昌',
        gisBd09Lat: 28.6864,
        gisGcj02Lat: 28.6808,
      },
      {
        pinYin: 'Jingdezhen',
        id: '360200',
        gisGcj02Lng: 117.178,
        children: [
          {
            id: '360201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.273,
            gisBd09Lng: 117.185,
            gisGcj02Lat: 29.2673,
            gisGcj02Lng: 117.178,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360202',
            text: '昌江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2771,
            gisBd09Lng: 117.192,
            gisGcj02Lat: 29.2715,
            gisGcj02Lng: 117.185,
            stubGroupCnt: null,
            pinYin: 'Changjiang',
          },
          {
            id: '360203',
            text: '珠山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3056,
            gisBd09Lng: 117.209,
            gisGcj02Lat: 29.2997,
            gisGcj02Lng: 117.202,
            stubGroupCnt: null,
            pinYin: 'Zhushan',
          },
          {
            id: '360222',
            text: '浮梁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3548,
            gisBd09Lng: 117.224,
            gisGcj02Lat: 29.3487,
            gisGcj02Lng: 117.218,
            stubGroupCnt: null,
            pinYin: 'Fuliang',
          },
          {
            id: '360281',
            text: '乐平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9663,
            gisBd09Lng: 117.136,
            gisGcj02Lat: 28.96,
            gisGcj02Lng: 117.13,
            stubGroupCnt: null,
            pinYin: 'Leping',
          },
        ],
        gisBd09Lng: 117.185,
        text: '景德镇',
        gisBd09Lat: 29.273,
        gisGcj02Lat: 29.2673,
      },
      {
        pinYin: 'Pingxiang',
        id: '360300',
        gisGcj02Lng: 113.855,
        children: [
          {
            id: '360301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6288,
            gisBd09Lng: 113.862,
            gisGcj02Lat: 27.6227,
            gisGcj02Lng: 113.855,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360302',
            text: '安源区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6204,
            gisBd09Lng: 113.88,
            gisGcj02Lat: 27.6141,
            gisGcj02Lng: 113.873,
            stubGroupCnt: null,
            pinYin: 'Anyuan',
          },
          {
            id: '360313',
            text: '湘东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6442,
            gisBd09Lng: 113.742,
            gisGcj02Lat: 27.6381,
            gisGcj02Lng: 113.736,
            stubGroupCnt: null,
            pinYin: 'Xiangdong',
          },
          {
            id: '360321',
            text: '莲花县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1325,
            gisBd09Lng: 113.968,
            gisGcj02Lat: 27.1266,
            gisGcj02Lng: 113.961,
            stubGroupCnt: null,
            pinYin: 'Lianhua',
          },
          {
            id: '360322',
            text: '上栗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8852,
            gisBd09Lng: 113.802,
            gisGcj02Lat: 27.8793,
            gisGcj02Lng: 113.795,
            stubGroupCnt: null,
            pinYin: 'Shangli',
          },
          {
            id: '360323',
            text: '芦溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6367,
            gisBd09Lng: 114.037,
            gisGcj02Lat: 27.6307,
            gisGcj02Lng: 114.03,
            stubGroupCnt: null,
            pinYin: 'Luxi',
          },
        ],
        gisBd09Lng: 113.862,
        text: '萍乡',
        gisBd09Lat: 27.6288,
        gisGcj02Lat: 27.6227,
      },
      {
        pinYin: 'Jiujiang',
        id: '360400',
        gisGcj02Lng: 116.003,
        children: [
          {
            id: '360401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7115,
            gisBd09Lng: 116.009,
            gisGcj02Lat: 29.7056,
            gisGcj02Lng: 116.003,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360402',
            text: '庐山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6775,
            gisBd09Lng: 115.996,
            gisGcj02Lat: 29.6718,
            gisGcj02Lng: 115.989,
            stubGroupCnt: null,
            pinYin: 'Lushan',
          },
          {
            id: '360403',
            text: '浔阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7328,
            gisBd09Lng: 115.996,
            gisGcj02Lat: 29.7271,
            gisGcj02Lng: 115.99,
            stubGroupCnt: null,
            pinYin: 'Xunyang',
          },
          {
            id: '360421',
            text: '九江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6149,
            gisBd09Lng: 115.919,
            gisGcj02Lat: 29.6086,
            gisGcj02Lng: 115.912,
            stubGroupCnt: null,
            pinYin: 'Jiujiang',
          },
          {
            id: '360423',
            text: '武宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2623,
            gisBd09Lng: 115.108,
            gisGcj02Lat: 29.2562,
            gisGcj02Lng: 115.102,
            stubGroupCnt: null,
            pinYin: 'Wuning',
          },
          {
            id: '360424',
            text: '修水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0308,
            gisBd09Lng: 114.554,
            gisGcj02Lat: 29.0252,
            gisGcj02Lng: 114.547,
            stubGroupCnt: null,
            pinYin: 'Xiushui',
          },
          {
            id: '360425',
            text: '永修县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0259,
            gisBd09Lng: 115.815,
            gisGcj02Lat: 29.0196,
            gisGcj02Lng: 115.809,
            stubGroupCnt: null,
            pinYin: 'Yongxiu',
          },
          {
            id: '360426',
            text: '德安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3191,
            gisBd09Lng: 115.763,
            gisGcj02Lat: 29.3133,
            gisGcj02Lng: 115.756,
            stubGroupCnt: null,
            pinYin: "De'an",
          },
          {
            id: '360427',
            text: '星子县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4529,
            gisBd09Lng: 116.052,
            gisGcj02Lat: 29.4465,
            gisGcj02Lng: 116.046,
            stubGroupCnt: null,
            pinYin: 'Xingzi',
          },
          {
            id: '360428',
            text: '都昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2778,
            gisBd09Lng: 116.211,
            gisGcj02Lat: 29.2721,
            gisGcj02Lng: 116.205,
            stubGroupCnt: null,
            pinYin: 'Duchang',
          },
          {
            id: '360429',
            text: '湖口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7444,
            gisBd09Lng: 116.228,
            gisGcj02Lat: 29.7387,
            gisGcj02Lng: 116.221,
            stubGroupCnt: null,
            pinYin: 'Hukou',
          },
          {
            id: '360430',
            text: '彭泽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9022,
            gisBd09Lng: 116.556,
            gisGcj02Lat: 29.8962,
            gisGcj02Lng: 116.549,
            stubGroupCnt: null,
            pinYin: 'Pengze',
          },
          {
            id: '360481',
            text: '瑞昌',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6825,
            gisBd09Lng: 115.688,
            gisGcj02Lat: 29.6761,
            gisGcj02Lng: 115.681,
            stubGroupCnt: null,
            pinYin: 'Ruichang',
          },
        ],
        gisBd09Lng: 116.009,
        text: '九江',
        gisBd09Lat: 29.7115,
        gisGcj02Lat: 29.7056,
      },
      {
        pinYin: 'Xinyu',
        id: '360500',
        gisGcj02Lng: 114.919,
        children: [
          {
            id: '360501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8229,
            gisBd09Lng: 114.926,
            gisGcj02Lat: 27.8171,
            gisGcj02Lng: 114.919,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360502',
            text: '渝水区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8065,
            gisBd09Lng: 114.953,
            gisGcj02Lat: 27.8002,
            gisGcj02Lng: 114.947,
            stubGroupCnt: null,
            pinYin: 'Yushui',
          },
          {
            id: '360521',
            text: '分宜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8203,
            gisBd09Lng: 114.699,
            gisGcj02Lat: 27.8142,
            gisGcj02Lng: 114.693,
            stubGroupCnt: null,
            pinYin: 'Fenyi',
          },
        ],
        gisBd09Lng: 114.926,
        text: '新余',
        gisBd09Lat: 27.8229,
        gisGcj02Lat: 27.8171,
      },
      {
        pinYin: 'Yingtan',
        id: '360600',
        gisGcj02Lng: 117.071,
        children: [
          {
            id: '360601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.265,
            gisBd09Lng: 117.077,
            gisGcj02Lat: 28.2593,
            gisGcj02Lng: 117.071,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360602',
            text: '月湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2453,
            gisBd09Lng: 117.045,
            gisGcj02Lat: 28.2395,
            gisGcj02Lng: 117.038,
            stubGroupCnt: null,
            pinYin: 'Yuehu',
          },
          {
            id: '360622',
            text: '余江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2147,
            gisBd09Lng: 116.825,
            gisGcj02Lat: 28.2091,
            gisGcj02Lng: 116.818,
            stubGroupCnt: null,
            pinYin: 'Yujiang',
          },
          {
            id: '360681',
            text: '贵溪',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2983,
            gisBd09Lng: 117.252,
            gisGcj02Lat: 28.292,
            gisGcj02Lng: 117.246,
            stubGroupCnt: null,
            pinYin: 'Guixi',
          },
        ],
        gisBd09Lng: 117.077,
        text: '鹰潭',
        gisBd09Lat: 28.265,
        gisGcj02Lat: 28.2593,
      },
      {
        pinYin: 'Ganzhou',
        id: '360700',
        gisGcj02Lng: 114.936,
        children: [
          {
            id: '360701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 25.8174,
            gisGcj02Lng: 114.921,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360702',
            text: '章贡区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8232,
            gisBd09Lng: 114.928,
            gisGcj02Lat: 25.8174,
            gisGcj02Lng: 114.921,
            stubGroupCnt: null,
            pinYin: 'Zhanggong',
          },
          {
            id: '360721',
            text: '赣　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4682,
            gisBd09Lng: 116.033,
            gisGcj02Lat: 26.462,
            gisGcj02Lng: 116.027,
            stubGroupCnt: null,
            pinYin: 'Ganxian',
          },
          {
            id: '360722',
            text: '信丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3898,
            gisBd09Lng: 114.93,
            gisGcj02Lat: 25.3839,
            gisGcj02Lng: 114.923,
            stubGroupCnt: null,
            pinYin: 'Xinfeng',
          },
          {
            id: '360723',
            text: '大余县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4071,
            gisBd09Lng: 114.369,
            gisGcj02Lat: 25.4008,
            gisGcj02Lng: 114.362,
            stubGroupCnt: null,
            pinYin: 'Dayu',
          },
          {
            id: '360724',
            text: '上犹县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7963,
            gisBd09Lng: 114.544,
            gisGcj02Lat: 25.7906,
            gisGcj02Lng: 114.538,
            stubGroupCnt: null,
            pinYin: 'Shangyou',
          },
          {
            id: '360725',
            text: '崇义县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6869,
            gisBd09Lng: 114.315,
            gisGcj02Lat: 25.6812,
            gisGcj02Lng: 114.309,
            stubGroupCnt: null,
            pinYin: 'Chongyi',
          },
          {
            id: '360726',
            text: '安远县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.143,
            gisBd09Lng: 115.401,
            gisGcj02Lat: 25.1373,
            gisGcj02Lng: 115.395,
            stubGroupCnt: null,
            pinYin: 'Anyuan',
          },
          {
            id: '360727',
            text: '龙南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9168,
            gisBd09Lng: 114.798,
            gisGcj02Lat: 24.9111,
            gisGcj02Lng: 114.791,
            stubGroupCnt: null,
            pinYin: 'Longnan',
          },
          {
            id: '360728',
            text: '定南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7906,
            gisBd09Lng: 115.033,
            gisGcj02Lat: 24.7849,
            gisGcj02Lng: 115.027,
            stubGroupCnt: null,
            pinYin: 'Dingnan',
          },
          {
            id: '360729',
            text: '全南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7473,
            gisBd09Lng: 114.539,
            gisGcj02Lat: 24.7416,
            gisGcj02Lng: 114.533,
            stubGroupCnt: null,
            pinYin: 'Quannan',
          },
          {
            id: '360730',
            text: '宁都县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4755,
            gisBd09Lng: 116.018,
            gisGcj02Lat: 26.4695,
            gisGcj02Lng: 116.011,
            stubGroupCnt: null,
            pinYin: 'Ningdu',
          },
          {
            id: '360731',
            text: '于都县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.9575,
            gisBd09Lng: 115.423,
            gisGcj02Lat: 25.9514,
            gisGcj02Lng: 115.416,
            stubGroupCnt: null,
            pinYin: 'Yudu',
          },
          {
            id: '360732',
            text: '兴国县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3425,
            gisBd09Lng: 115.37,
            gisGcj02Lat: 26.3368,
            gisGcj02Lng: 115.363,
            stubGroupCnt: null,
            pinYin: 'Xingguo',
          },
          {
            id: '360733',
            text: '会昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6061,
            gisBd09Lng: 115.794,
            gisGcj02Lat: 25.5998,
            gisGcj02Lng: 115.788,
            stubGroupCnt: null,
            pinYin: 'Huichang',
          },
          {
            id: '360734',
            text: '寻乌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9695,
            gisBd09Lng: 115.654,
            gisGcj02Lat: 24.9635,
            gisGcj02Lng: 115.648,
            stubGroupCnt: null,
            pinYin: 'Xunwu',
          },
          {
            id: '360735',
            text: '石城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3327,
            gisBd09Lng: 116.362,
            gisGcj02Lat: 26.3269,
            gisGcj02Lng: 116.355,
            stubGroupCnt: null,
            pinYin: 'Shicheng',
          },
          {
            id: '360781',
            text: '瑞金',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8908,
            gisBd09Lng: 116.034,
            gisGcj02Lat: 25.8846,
            gisGcj02Lng: 116.028,
            stubGroupCnt: null,
            pinYin: 'Ruijin',
          },
          {
            id: '360782',
            text: '南康',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8325,
            gisBd09Lng: 114.712,
            gisGcj02Lat: 25.8262,
            gisGcj02Lng: 114.706,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 114.942,
        text: '赣州',
        gisBd09Lat: 25.8364,
        gisGcj02Lat: 25.8303,
      },
      {
        pinYin: "Ji'an",
        id: '360800',
        gisGcj02Lng: 114.994,
        children: [
          {
            id: '360801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1196,
            gisBd09Lng: 115.001,
            gisGcj02Lat: 27.1137,
            gisGcj02Lng: 114.994,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360802',
            text: '吉州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1498,
            gisBd09Lng: 115.002,
            gisGcj02Lat: 27.1439,
            gisGcj02Lng: 114.996,
            stubGroupCnt: null,
            pinYin: 'Jizhou',
          },
          {
            id: '360803',
            text: '青原区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0862,
            gisBd09Lng: 115.023,
            gisGcj02Lat: 27.0805,
            gisGcj02Lng: 115.017,
            stubGroupCnt: null,
            pinYin: 'Qingyuan',
          },
          {
            id: '360821',
            text: '吉安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0439,
            gisBd09Lng: 114.914,
            gisGcj02Lat: 27.0383,
            gisGcj02Lng: 114.908,
            stubGroupCnt: null,
            pinYin: "Ji'an",
          },
          {
            id: '360822',
            text: '吉水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2348,
            gisBd09Lng: 115.143,
            gisGcj02Lat: 27.2291,
            gisGcj02Lng: 115.136,
            stubGroupCnt: null,
            pinYin: 'Jishui',
          },
          {
            id: '360823',
            text: '峡江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5887,
            gisBd09Lng: 115.323,
            gisGcj02Lat: 27.5824,
            gisGcj02Lng: 115.316,
            stubGroupCnt: null,
            pinYin: 'Xiajiang',
          },
          {
            id: '360824',
            text: '新干县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7451,
            gisBd09Lng: 115.394,
            gisGcj02Lat: 27.7394,
            gisGcj02Lng: 115.387,
            stubGroupCnt: null,
            pinYin: 'Xingan',
          },
          {
            id: '360825',
            text: '永丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3238,
            gisBd09Lng: 115.449,
            gisGcj02Lat: 27.3174,
            gisGcj02Lng: 115.442,
            stubGroupCnt: null,
            pinYin: 'Yongfeng',
          },
          {
            id: '360826',
            text: '泰和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.7954,
            gisBd09Lng: 114.914,
            gisGcj02Lat: 26.7897,
            gisGcj02Lng: 114.908,
            stubGroupCnt: null,
            pinYin: 'Taihe',
          },
          {
            id: '360827',
            text: '遂川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.3187,
            gisBd09Lng: 114.528,
            gisGcj02Lat: 26.3129,
            gisGcj02Lng: 114.521,
            stubGroupCnt: null,
            pinYin: 'Suichuan',
          },
          {
            id: '360828',
            text: '万安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4641,
            gisBd09Lng: 114.792,
            gisGcj02Lat: 26.4584,
            gisGcj02Lng: 114.786,
            stubGroupCnt: null,
            pinYin: "Wan'an",
          },
          {
            id: '360829',
            text: '安福县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3985,
            gisBd09Lng: 114.625,
            gisGcj02Lat: 27.3923,
            gisGcj02Lng: 114.619,
            stubGroupCnt: null,
            pinYin: 'Anfu',
          },
          {
            id: '360830',
            text: '永新县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9503,
            gisBd09Lng: 114.249,
            gisGcj02Lat: 26.9439,
            gisGcj02Lng: 114.243,
            stubGroupCnt: null,
            pinYin: 'Yongxin',
          },
          {
            id: '360881',
            text: '井冈山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5348,
            gisBd09Lng: 114.156,
            gisGcj02Lat: 26.5287,
            gisGcj02Lng: 114.15,
            stubGroupCnt: null,
            pinYin: 'Jinggangshan',
          },
        ],
        gisBd09Lng: 115.001,
        text: '吉安',
        gisBd09Lat: 27.1196,
        gisGcj02Lat: 27.1137,
      },
      {
        pinYin: 'Yichun',
        id: '360900',
        gisGcj02Lng: 115.377,
        children: [
          {
            id: '360901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8196,
            gisBd09Lng: 114.423,
            gisGcj02Lat: 27.8139,
            gisGcj02Lng: 114.417,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '360902',
            text: '袁州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8004,
            gisBd09Lng: 114.434,
            gisGcj02Lat: 27.7947,
            gisGcj02Lng: 114.427,
            stubGroupCnt: null,
            pinYin: 'Yuanzhou',
          },
          {
            id: '360921',
            text: '奉新县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6935,
            gisBd09Lng: 115.407,
            gisGcj02Lat: 28.6877,
            gisGcj02Lng: 115.4,
            stubGroupCnt: null,
            pinYin: 'Fengxin',
          },
          {
            id: '360922',
            text: '万载县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1119,
            gisBd09Lng: 114.452,
            gisGcj02Lat: 28.106,
            gisGcj02Lng: 114.446,
            stubGroupCnt: null,
            pinYin: 'Wanzai',
          },
          {
            id: '360923',
            text: '上高县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.239,
            gisBd09Lng: 114.931,
            gisGcj02Lat: 28.233,
            gisGcj02Lng: 114.925,
            stubGroupCnt: null,
            pinYin: 'Shanggao',
          },
          {
            id: '360924',
            text: '宜丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3995,
            gisBd09Lng: 114.811,
            gisGcj02Lat: 28.3936,
            gisGcj02Lng: 114.805,
            stubGroupCnt: null,
            pinYin: 'Yifeng',
          },
          {
            id: '360925',
            text: '靖安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8668,
            gisBd09Lng: 115.37,
            gisGcj02Lat: 28.861,
            gisGcj02Lng: 115.364,
            stubGroupCnt: null,
            pinYin: "Jing'an",
          },
          {
            id: '360926',
            text: '铜鼓县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5277,
            gisBd09Lng: 114.379,
            gisGcj02Lat: 28.5215,
            gisGcj02Lng: 114.372,
            stubGroupCnt: null,
            pinYin: 'Tonggu',
          },
          {
            id: '360981',
            text: '丰城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1645,
            gisBd09Lng: 115.779,
            gisGcj02Lat: 28.1585,
            gisGcj02Lng: 115.772,
            stubGroupCnt: null,
            pinYin: 'Fengcheng',
          },
          {
            id: '360982',
            text: '樟树',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.061,
            gisBd09Lng: 115.553,
            gisGcj02Lat: 28.0547,
            gisGcj02Lng: 115.547,
            stubGroupCnt: null,
            pinYin: 'Zhangshu',
          },
          {
            id: '360983',
            text: '高安',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4229,
            gisBd09Lng: 115.383,
            gisGcj02Lat: 28.4172,
            gisGcj02Lng: 115.377,
            stubGroupCnt: null,
            pinYin: "Gao'an",
          },
        ],
        gisBd09Lng: null,
        text: '宜春',
        gisBd09Lat: null,
        gisGcj02Lat: 28.4172,
      },
      {
        pinYin: 'Fuzhou',
        id: '361000',
        gisGcj02Lng: 116.358,
        children: [
          {
            id: '361001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9537,
            gisBd09Lng: 116.365,
            gisGcj02Lat: 27.9479,
            gisGcj02Lng: 116.358,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '361002',
            text: '临川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9401,
            gisBd09Lng: 116.317,
            gisGcj02Lat: 27.9341,
            gisGcj02Lng: 116.31,
            stubGroupCnt: null,
            pinYin: 'Linchuan',
          },
          {
            id: '361021',
            text: '南城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5749,
            gisBd09Lng: 116.645,
            gisGcj02Lat: 27.5686,
            gisGcj02Lng: 116.639,
            stubGroupCnt: null,
            pinYin: 'Nancheng',
          },
          {
            id: '361022',
            text: '黎川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2875,
            gisBd09Lng: 116.917,
            gisGcj02Lat: 27.2815,
            gisGcj02Lng: 116.91,
            stubGroupCnt: null,
            pinYin: 'Lichuan',
          },
          {
            id: '361023',
            text: '南丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2232,
            gisBd09Lng: 116.534,
            gisGcj02Lat: 27.2168,
            gisGcj02Lng: 116.527,
            stubGroupCnt: null,
            pinYin: 'Nanfeng',
          },
          {
            id: '361024',
            text: '崇仁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7705,
            gisBd09Lng: 116.069,
            gisGcj02Lat: 27.7643,
            gisGcj02Lng: 116.062,
            stubGroupCnt: null,
            pinYin: 'Chongren',
          },
          {
            id: '361025',
            text: '乐安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.433,
            gisBd09Lng: 115.838,
            gisGcj02Lat: 27.427,
            gisGcj02Lng: 115.831,
            stubGroupCnt: null,
            pinYin: "Le'an",
          },
          {
            id: '361026',
            text: '宜黄县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5594,
            gisBd09Lng: 116.243,
            gisGcj02Lat: 27.5536,
            gisGcj02Lng: 116.236,
            stubGroupCnt: null,
            pinYin: 'Yihuang',
          },
          {
            id: '361027',
            text: '金溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9242,
            gisBd09Lng: 116.762,
            gisGcj02Lat: 27.9179,
            gisGcj02Lng: 116.756,
            stubGroupCnt: null,
            pinYin: 'Jinxi',
          },
          {
            id: '361028',
            text: '资溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7106,
            gisBd09Lng: 117.071,
            gisGcj02Lat: 27.7049,
            gisGcj02Lng: 117.064,
            stubGroupCnt: null,
            pinYin: 'Zixi',
          },
          {
            id: '361029',
            text: '东乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2523,
            gisBd09Lng: 116.611,
            gisGcj02Lat: 28.2464,
            gisGcj02Lng: 116.604,
            stubGroupCnt: null,
            pinYin: 'Dongxiang',
          },
          {
            id: '361030',
            text: '广昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8421,
            gisBd09Lng: 116.334,
            gisGcj02Lat: 26.8364,
            gisGcj02Lng: 116.327,
            stubGroupCnt: null,
            pinYin: 'Guangchang',
          },
        ],
        gisBd09Lng: 116.365,
        text: '抚州',
        gisBd09Lat: 27.9537,
        gisGcj02Lat: 27.9479,
      },
      {
        pinYin: 'Shangrao',
        id: '361100',
        gisGcj02Lng: 117.943,
        children: [
          {
            id: '361101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.46,
            gisBd09Lng: 117.95,
            gisGcj02Lat: 28.4538,
            gisGcj02Lng: 117.943,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '361102',
            text: '信州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4357,
            gisBd09Lng: 117.972,
            gisGcj02Lat: 28.4293,
            gisGcj02Lng: 117.966,
            stubGroupCnt: null,
            pinYin: 'Xinzhou',
          },
          {
            id: '361121',
            text: '上饶县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4546,
            gisBd09Lng: 117.916,
            gisGcj02Lat: 28.4489,
            gisGcj02Lng: 117.91,
            stubGroupCnt: null,
            pinYin: 'Shangrao',
          },
          {
            id: '361122',
            text: '广丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4412,
            gisBd09Lng: 118.199,
            gisGcj02Lat: 28.4349,
            gisGcj02Lng: 118.193,
            stubGroupCnt: null,
            pinYin: 'Guangfeng',
          },
          {
            id: '361123',
            text: '玉山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6875,
            gisBd09Lng: 118.252,
            gisGcj02Lat: 28.6817,
            gisGcj02Lng: 118.245,
            stubGroupCnt: null,
            pinYin: 'Yushan',
          },
          {
            id: '361124',
            text: '铅山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3212,
            gisBd09Lng: 117.716,
            gisGcj02Lat: 28.3149,
            gisGcj02Lng: 117.71,
            stubGroupCnt: null,
            pinYin: 'Yanshan',
          },
          {
            id: '361125',
            text: '横峰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4128,
            gisBd09Lng: 117.604,
            gisGcj02Lat: 28.4065,
            gisGcj02Lng: 117.598,
            stubGroupCnt: null,
            pinYin: 'Hengfeng',
          },
          {
            id: '361126',
            text: '弋阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3829,
            gisBd09Lng: 117.456,
            gisGcj02Lat: 28.3769,
            gisGcj02Lng: 117.449,
            stubGroupCnt: null,
            pinYin: 'Yiyang',
          },
          {
            id: '361127',
            text: '余干县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7071,
            gisBd09Lng: 116.703,
            gisGcj02Lat: 28.7014,
            gisGcj02Lng: 116.696,
            stubGroupCnt: null,
            pinYin: 'Yugan',
          },
          {
            id: '361128',
            text: '鄱阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0105,
            gisBd09Lng: 116.711,
            gisGcj02Lat: 29.0049,
            gisGcj02Lng: 116.705,
            stubGroupCnt: null,
            pinYin: 'Poyang',
          },
          {
            id: '361129',
            text: '万年县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6995,
            gisBd09Lng: 117.066,
            gisGcj02Lat: 28.6938,
            gisGcj02Lng: 117.059,
            stubGroupCnt: null,
            pinYin: 'Wannian',
          },
          {
            id: '361130',
            text: '婺源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2536,
            gisBd09Lng: 117.869,
            gisGcj02Lat: 29.2474,
            gisGcj02Lng: 117.862,
            stubGroupCnt: null,
            pinYin: 'Wuyuan',
          },
          {
            id: '361181',
            text: '德兴',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9515,
            gisBd09Lng: 117.586,
            gisGcj02Lat: 28.9453,
            gisGcj02Lng: 117.579,
            stubGroupCnt: null,
            pinYin: 'Dexing',
          },
        ],
        gisBd09Lng: 117.95,
        text: '上饶',
        gisBd09Lat: 28.46,
        gisGcj02Lat: 28.4538,
      },
    ],
  },
  {
    text: '辽宁省',
    children: [
      {
        pinYin: 'Shenyang',
        id: '210100',
        gisGcj02Lng: 123.432,
        children: [
          {
            id: '210101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8105,
            gisBd09Lng: 123.439,
            gisGcj02Lat: 41.8048,
            gisGcj02Lng: 123.432,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210102',
            text: '和平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7953,
            gisBd09Lng: 123.428,
            gisGcj02Lat: 41.7897,
            gisGcj02Lng: 123.421,
            stubGroupCnt: null,
            pinYin: 'Heping',
          },
          {
            id: '210103',
            text: '沈河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.802,
            gisBd09Lng: 123.465,
            gisGcj02Lat: 41.7958,
            gisGcj02Lng: 123.458,
            stubGroupCnt: null,
            pinYin: 'Shenhe',
          },
          {
            id: '210104',
            text: '大东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8106,
            gisBd09Lng: 123.476,
            gisGcj02Lat: 41.8043,
            gisGcj02Lng: 123.47,
            stubGroupCnt: null,
            pinYin: 'Dadong',
          },
          {
            id: '210105',
            text: '皇姑区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8264,
            gisBd09Lng: 123.431,
            gisGcj02Lat: 41.8207,
            gisGcj02Lng: 123.424,
            stubGroupCnt: null,
            pinYin: 'Huanggu',
          },
          {
            id: '210106',
            text: '铁西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8075,
            gisBd09Lng: 123.384,
            gisGcj02Lat: 41.8013,
            gisGcj02Lng: 123.378,
            stubGroupCnt: null,
            pinYin: 'Tiexi',
          },
          {
            id: '210111',
            text: '苏家屯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.6711,
            gisBd09Lng: 123.352,
            gisGcj02Lat: 41.6648,
            gisGcj02Lng: 123.345,
            stubGroupCnt: null,
            pinYin: 'Sujiatun',
          },
          {
            id: '210112',
            text: '东陵区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7652,
            gisBd09Lng: 123.6,
            gisGcj02Lat: 41.7589,
            gisGcj02Lng: 123.594,
            stubGroupCnt: null,
            pinYin: 'Hunnan',
          },
          {
            id: '210113',
            text: '新城子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0525,
            gisBd09Lng: 123.537,
            gisGcj02Lat: 42.0468,
            gisGcj02Lng: 123.531,
            stubGroupCnt: null,
            pinYin: 'Shenbeixinqu',
          },
          {
            id: '210114',
            text: '于洪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7995,
            gisBd09Lng: 123.315,
            gisGcj02Lat: 41.7939,
            gisGcj02Lng: 123.308,
            stubGroupCnt: null,
            pinYin: 'Yuhong',
          },
          {
            id: '210122',
            text: '辽中县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5178,
            gisBd09Lng: 122.738,
            gisGcj02Lat: 41.5117,
            gisGcj02Lng: 122.732,
            stubGroupCnt: null,
            pinYin: 'Liaozhong',
          },
          {
            id: '210123',
            text: '康平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7561,
            gisBd09Lng: 123.36,
            gisGcj02Lat: 42.7497,
            gisGcj02Lng: 123.354,
            stubGroupCnt: null,
            pinYin: 'Kangping',
          },
          {
            id: '210124',
            text: '法库县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.5097,
            gisBd09Lng: 123.415,
            gisGcj02Lat: 42.504,
            gisGcj02Lng: 123.408,
            stubGroupCnt: null,
            pinYin: 'Faku',
          },
          {
            id: '210181',
            text: '新民',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0034,
            gisBd09Lng: 122.835,
            gisGcj02Lat: 41.9977,
            gisGcj02Lng: 122.828,
            stubGroupCnt: null,
            pinYin: 'Xinmin',
          },
        ],
        gisBd09Lng: 123.439,
        text: '沈阳',
        gisBd09Lat: 41.8105,
        gisGcj02Lat: 41.8048,
      },
      {
        pinYin: 'Dalian',
        id: '210200',
        gisGcj02Lng: 121.614,
        children: [
          {
            id: '210201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.919,
            gisBd09Lng: 121.621,
            gisGcj02Lat: 38.9133,
            gisGcj02Lng: 121.614,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210202',
            text: '中山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9241,
            gisBd09Lng: 121.651,
            gisGcj02Lat: 38.9182,
            gisGcj02Lng: 121.644,
            stubGroupCnt: null,
            pinYin: 'Zhongshan',
          },
          {
            id: '210203',
            text: '西岗区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.92,
            gisBd09Lng: 121.618,
            gisGcj02Lat: 38.9143,
            gisGcj02Lng: 121.612,
            stubGroupCnt: null,
            pinYin: 'Xigang',
          },
          {
            id: '210204',
            text: '沙河口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9103,
            gisBd09Lng: 121.602,
            gisGcj02Lat: 38.9044,
            gisGcj02Lng: 121.595,
            stubGroupCnt: null,
            pinYin: 'Shahekou',
          },
          {
            id: '210211',
            text: '甘井子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9575,
            gisBd09Lng: 121.531,
            gisGcj02Lat: 38.9516,
            gisGcj02Lng: 121.525,
            stubGroupCnt: null,
            pinYin: 'Ganjingzi',
          },
          {
            id: '210212',
            text: '旅顺口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8565,
            gisBd09Lng: 121.271,
            gisGcj02Lat: 38.8508,
            gisGcj02Lng: 121.264,
            stubGroupCnt: null,
            pinYin: 'Lvshunkou',
          },
          {
            id: '210213',
            text: '金州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0563,
            gisBd09Lng: 121.788,
            gisGcj02Lat: 39.05,
            gisGcj02Lng: 121.782,
            stubGroupCnt: null,
            pinYin: 'Jinzhou',
          },
          {
            id: '210224',
            text: '长海县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2779,
            gisBd09Lng: 122.595,
            gisGcj02Lat: 39.2723,
            gisGcj02Lng: 122.588,
            stubGroupCnt: null,
            pinYin: 'Changhai',
          },
          {
            id: '210281',
            text: '瓦房店',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6319,
            gisBd09Lng: 121.986,
            gisGcj02Lat: 39.6263,
            gisGcj02Lng: 121.98,
            stubGroupCnt: null,
            pinYin: 'Wafangdian',
          },
          {
            id: '210282',
            text: '普兰店',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3998,
            gisBd09Lng: 121.97,
            gisGcj02Lat: 39.394,
            gisGcj02Lng: 121.963,
            stubGroupCnt: null,
            pinYin: 'Pulandian',
          },
          {
            id: '210283',
            text: '庄河',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6857,
            gisBd09Lng: 122.973,
            gisGcj02Lat: 39.6798,
            gisGcj02Lng: 122.966,
            stubGroupCnt: null,
            pinYin: 'Zhuanghe',
          },
        ],
        gisBd09Lng: 121.621,
        text: '大连',
        gisBd09Lat: 38.919,
        gisGcj02Lat: 38.9133,
      },
      {
        pinYin: 'Anshan',
        id: '210300',
        gisGcj02Lng: 122.994,
        children: [
          {
            id: '210301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1142,
            gisBd09Lng: 123.001,
            gisGcj02Lat: 41.1079,
            gisGcj02Lng: 122.994,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210302',
            text: '铁东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0963,
            gisBd09Lng: 122.997,
            gisGcj02Lat: 41.09,
            gisGcj02Lng: 122.991,
            stubGroupCnt: null,
            pinYin: 'Tiedong',
          },
          {
            id: '210303',
            text: '铁西区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1257,
            gisBd09Lng: 122.976,
            gisGcj02Lat: 41.1197,
            gisGcj02Lng: 122.969,
            stubGroupCnt: null,
            pinYin: 'Tiexi',
          },
          {
            id: '210304',
            text: '立山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1559,
            gisBd09Lng: 123.037,
            gisGcj02Lat: 41.1499,
            gisGcj02Lng: 123.03,
            stubGroupCnt: null,
            pinYin: 'Lishan',
          },
          {
            id: '210311',
            text: '千山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0803,
            gisBd09Lng: 122.968,
            gisGcj02Lat: 41.0744,
            gisGcj02Lng: 122.961,
            stubGroupCnt: null,
            pinYin: 'Qianshan',
          },
          {
            id: '210321',
            text: '台安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.4185,
            gisBd09Lng: 122.442,
            gisGcj02Lat: 41.4126,
            gisGcj02Lng: 122.436,
            stubGroupCnt: null,
            pinYin: "Tai'an",
          },
          {
            id: '210323',
            text: '岫岩满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2958,
            gisBd09Lng: 123.288,
            gisGcj02Lat: 40.29,
            gisGcj02Lng: 123.282,
            stubGroupCnt: null,
            pinYin: 'Xiuyan',
          },
          {
            id: '210381',
            text: '海城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8868,
            gisBd09Lng: 122.691,
            gisGcj02Lat: 40.881,
            gisGcj02Lng: 122.685,
            stubGroupCnt: null,
            pinYin: 'Haicheng',
          },
        ],
        gisBd09Lng: 123.001,
        text: '鞍山',
        gisBd09Lat: 41.1142,
        gisGcj02Lat: 41.1079,
      },
      {
        pinYin: 'Fushun',
        id: '210400',
        gisGcj02Lng: 123.958,
        children: [
          {
            id: '210401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8858,
            gisBd09Lng: 123.964,
            gisGcj02Lat: 41.8794,
            gisGcj02Lng: 123.958,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210402',
            text: '新抚区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8648,
            gisBd09Lng: 123.88,
            gisGcj02Lat: 41.8588,
            gisGcj02Lng: 123.874,
            stubGroupCnt: null,
            pinYin: 'Xinfu',
          },
          {
            id: '210403',
            text: '东洲区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8583,
            gisBd09Lng: 124.048,
            gisGcj02Lat: 41.8524,
            gisGcj02Lng: 124.041,
            stubGroupCnt: null,
            pinYin: 'Dongzhou',
          },
          {
            id: '210404',
            text: '望花区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8589,
            gisBd09Lng: 123.793,
            gisGcj02Lat: 41.8533,
            gisGcj02Lng: 123.786,
            stubGroupCnt: null,
            pinYin: 'Wanghua',
          },
          {
            id: '210411',
            text: '顺城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8883,
            gisBd09Lng: 123.951,
            gisGcj02Lat: 41.882,
            gisGcj02Lng: 123.945,
            stubGroupCnt: null,
            pinYin: 'Shuncheng',
          },
          {
            id: '210421',
            text: '抚顺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7917,
            gisBd09Lng: 124.202,
            gisGcj02Lat: 41.7853,
            gisGcj02Lng: 124.196,
            stubGroupCnt: null,
            pinYin: 'Fushun',
          },
          {
            id: '210422',
            text: '新宾满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.74,
            gisBd09Lng: 125.046,
            gisGcj02Lat: 41.7336,
            gisGcj02Lng: 125.04,
            stubGroupCnt: null,
            pinYin: 'Xinbin',
          },
          {
            id: '210423',
            text: '清原满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.1067,
            gisBd09Lng: 124.931,
            gisGcj02Lat: 42.1004,
            gisGcj02Lng: 124.924,
            stubGroupCnt: null,
            pinYin: 'Qingyuan',
          },
        ],
        gisBd09Lng: 123.964,
        text: '抚顺',
        gisBd09Lat: 41.8858,
        gisGcj02Lat: 41.8794,
      },
      {
        pinYin: 'Benxi',
        id: '210500',
        gisGcj02Lng: 123.761,
        children: [
          {
            id: '210501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2894,
            gisBd09Lng: 123.768,
            gisGcj02Lat: 41.2836,
            gisGcj02Lng: 123.761,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210502',
            text: '平山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.227,
            gisBd09Lng: 123.69,
            gisGcj02Lat: 41.2211,
            gisGcj02Lng: 123.684,
            stubGroupCnt: null,
            pinYin: 'Pingshan',
          },
          {
            id: '210503',
            text: '溪湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3345,
            gisBd09Lng: 123.773,
            gisGcj02Lat: 41.3287,
            gisGcj02Lng: 123.766,
            stubGroupCnt: null,
            pinYin: 'Xihu',
          },
          {
            id: '210504',
            text: '明山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3145,
            gisBd09Lng: 123.822,
            gisGcj02Lat: 41.3084,
            gisGcj02Lng: 123.815,
            stubGroupCnt: null,
            pinYin: 'Mingshan',
          },
          {
            id: '210505',
            text: '南芬区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1067,
            gisBd09Lng: 123.754,
            gisGcj02Lat: 41.1007,
            gisGcj02Lng: 123.747,
            stubGroupCnt: null,
            pinYin: 'Nanfen',
          },
          {
            id: '210521',
            text: '本溪满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3063,
            gisBd09Lng: 124.126,
            gisGcj02Lat: 41.3005,
            gisGcj02Lng: 124.119,
            stubGroupCnt: null,
            pinYin: 'Benxi',
          },
          {
            id: '210522',
            text: '桓仁满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2725,
            gisBd09Lng: 125.368,
            gisGcj02Lat: 41.2667,
            gisGcj02Lng: 125.361,
            stubGroupCnt: null,
            pinYin: 'Huanren',
          },
        ],
        gisBd09Lng: 123.768,
        text: '本溪',
        gisBd09Lat: 41.2894,
        gisGcj02Lat: 41.2836,
      },
      {
        pinYin: 'Dandong',
        id: '210600',
        gisGcj02Lng: 124.356,
        children: [
          {
            id: '210601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.0058,
            gisBd09Lng: 124.363,
            gisGcj02Lat: 39.9999,
            gisGcj02Lng: 124.356,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210602',
            text: '元宝区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1415,
            gisBd09Lng: 124.402,
            gisGcj02Lat: 40.1357,
            gisGcj02Lng: 124.396,
            stubGroupCnt: null,
            pinYin: 'Yuanbao',
          },
          {
            id: '210603',
            text: '振兴区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.111,
            gisBd09Lng: 124.365,
            gisGcj02Lat: 40.1051,
            gisGcj02Lng: 124.359,
            stubGroupCnt: null,
            pinYin: 'Zhenxing',
          },
          {
            id: '210604',
            text: '振安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.1644,
            gisBd09Lng: 124.434,
            gisGcj02Lat: 40.1581,
            gisGcj02Lng: 124.428,
            stubGroupCnt: null,
            pinYin: "Zhen'an",
          },
          {
            id: '210624',
            text: '宽甸满族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7371,
            gisBd09Lng: 124.79,
            gisGcj02Lat: 40.7309,
            gisGcj02Lng: 124.784,
            stubGroupCnt: null,
            pinYin: 'Kuandian',
          },
          {
            id: '210681',
            text: '东港',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8661,
            gisBd09Lng: 124.16,
            gisGcj02Lat: 39.8604,
            gisGcj02Lng: 124.153,
            stubGroupCnt: null,
            pinYin: 'Donggang',
          },
          {
            id: '210682',
            text: '凤城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4582,
            gisBd09Lng: 124.073,
            gisGcj02Lat: 40.452,
            gisGcj02Lng: 124.067,
            stubGroupCnt: null,
            pinYin: 'Fengcheng',
          },
        ],
        gisBd09Lng: 124.363,
        text: '丹东',
        gisBd09Lat: 40.0058,
        gisGcj02Lat: 39.9999,
      },
      {
        pinYin: 'Jinzhou',
        id: '210700',
        gisGcj02Lng: 121.127,
        children: [
          {
            id: '210701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1007,
            gisBd09Lng: 121.134,
            gisGcj02Lat: 41.095,
            gisGcj02Lng: 121.127,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210702',
            text: '古塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1229,
            gisBd09Lng: 121.135,
            gisGcj02Lat: 41.1172,
            gisGcj02Lng: 121.128,
            stubGroupCnt: null,
            pinYin: 'Guta',
          },
          {
            id: '210703',
            text: '凌河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1203,
            gisBd09Lng: 121.159,
            gisGcj02Lat: 41.1146,
            gisGcj02Lng: 121.152,
            stubGroupCnt: null,
            pinYin: 'Linghe',
          },
          {
            id: '210711',
            text: '太和区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1145,
            gisBd09Lng: 121.11,
            gisGcj02Lat: 41.1084,
            gisGcj02Lng: 121.104,
            stubGroupCnt: null,
            pinYin: 'Taihe',
          },
          {
            id: '210726',
            text: '黑山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.6998,
            gisBd09Lng: 122.128,
            gisGcj02Lat: 41.694,
            gisGcj02Lng: 122.121,
            stubGroupCnt: null,
            pinYin: 'Heishan',
          },
          {
            id: '210727',
            text: '义　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5388,
            gisBd09Lng: 121.246,
            gisGcj02Lat: 41.533,
            gisGcj02Lng: 121.24,
            stubGroupCnt: null,
            pinYin: 'Yixian',
          },
          {
            id: '210781',
            text: '凌海',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1794,
            gisBd09Lng: 121.364,
            gisGcj02Lat: 41.1736,
            gisGcj02Lng: 121.358,
            stubGroupCnt: null,
            pinYin: 'Linghai',
          },
          {
            id: '210782',
            text: '北宁',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.601,
            gisBd09Lng: 121.806,
            gisGcj02Lat: 41.5946,
            gisGcj02Lng: 121.8,
            stubGroupCnt: null,
            pinYin: 'Beizhen',
          },
        ],
        gisBd09Lng: 121.134,
        text: '锦州',
        gisBd09Lat: 41.1007,
        gisGcj02Lat: 41.095,
      },
      {
        pinYin: 'Yingkou',
        id: '210800',
        gisGcj02Lng: 122.236,
        children: [
          {
            id: '210801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6726,
            gisBd09Lng: 122.242,
            gisGcj02Lat: 40.6669,
            gisGcj02Lng: 122.236,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210802',
            text: '站前区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6778,
            gisBd09Lng: 122.266,
            gisGcj02Lat: 40.6717,
            gisGcj02Lng: 122.259,
            stubGroupCnt: null,
            pinYin: 'Zhanqian',
          },
          {
            id: '210803',
            text: '西市区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6713,
            gisBd09Lng: 122.213,
            gisGcj02Lat: 40.6656,
            gisGcj02Lng: 122.207,
            stubGroupCnt: null,
            pinYin: 'Xishi',
          },
          {
            id: '210804',
            text: '鲅鱼圈区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2317,
            gisBd09Lng: 122.128,
            gisGcj02Lat: 40.2259,
            gisGcj02Lng: 122.122,
            stubGroupCnt: null,
            pinYin: 'Bayuquan',
          },
          {
            id: '210811',
            text: '老边区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6863,
            gisBd09Lng: 122.387,
            gisGcj02Lat: 40.6801,
            gisGcj02Lng: 122.38,
            stubGroupCnt: null,
            pinYin: 'Laobian',
          },
          {
            id: '210881',
            text: '盖州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4055,
            gisBd09Lng: 122.356,
            gisGcj02Lat: 40.3999,
            gisGcj02Lng: 122.35,
            stubGroupCnt: null,
            pinYin: 'Gaizhou',
          },
          {
            id: '210882',
            text: '大石桥',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6168,
            gisBd09Lng: 122.507,
            gisGcj02Lat: 40.6106,
            gisGcj02Lng: 122.5,
            stubGroupCnt: null,
            pinYin: 'Dashiqiao',
          },
        ],
        gisBd09Lng: 122.242,
        text: '营口',
        gisBd09Lat: 40.6726,
        gisGcj02Lat: 40.6669,
      },
      {
        pinYin: 'Fuxin',
        id: '210900',
        gisGcj02Lng: 121.67,
        children: [
          {
            id: '210901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0271,
            gisBd09Lng: 121.677,
            gisGcj02Lat: 42.0207,
            gisGcj02Lng: 121.67,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '210902',
            text: '海州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0194,
            gisBd09Lng: 121.663,
            gisGcj02Lat: 42.0132,
            gisGcj02Lng: 121.656,
            stubGroupCnt: null,
            pinYin: 'Haizhou',
          },
          {
            id: '210903',
            text: '新邱区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0935,
            gisBd09Lng: 121.799,
            gisGcj02Lat: 42.0872,
            gisGcj02Lng: 121.792,
            stubGroupCnt: null,
            pinYin: 'Xinqiu',
          },
          {
            id: '210904',
            text: '太平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0167,
            gisBd09Lng: 121.685,
            gisGcj02Lat: 42.0104,
            gisGcj02Lng: 121.679,
            stubGroupCnt: null,
            pinYin: 'Taiping',
          },
          {
            id: '210905',
            text: '清河门区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.789,
            gisBd09Lng: 121.422,
            gisGcj02Lat: 41.7829,
            gisGcj02Lng: 121.416,
            stubGroupCnt: null,
            pinYin: 'Qinghemen',
          },
          {
            id: '210911',
            text: '细河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0308,
            gisBd09Lng: 121.686,
            gisGcj02Lat: 42.0244,
            gisGcj02Lng: 121.68,
            stubGroupCnt: null,
            pinYin: 'Xihe',
          },
          {
            id: '210921',
            text: '阜新蒙古族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0707,
            gisBd09Lng: 121.765,
            gisGcj02Lat: 42.0649,
            gisGcj02Lng: 121.759,
            stubGroupCnt: null,
            pinYin: 'Fuxin',
          },
          {
            id: '210922',
            text: '彰武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.3924,
            gisBd09Lng: 122.545,
            gisGcj02Lat: 42.3862,
            gisGcj02Lng: 122.539,
            stubGroupCnt: null,
            pinYin: 'Zhangwu',
          },
        ],
        gisBd09Lng: 121.677,
        text: '阜新',
        gisBd09Lat: 42.0271,
        gisGcj02Lat: 42.0207,
      },
      {
        pinYin: 'Liaoyang',
        id: '211000',
        gisGcj02Lng: 123.238,
        children: [
          {
            id: '211001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.274,
            gisBd09Lng: 123.245,
            gisGcj02Lat: 41.2677,
            gisGcj02Lng: 123.238,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '211002',
            text: '白塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2752,
            gisBd09Lng: 123.181,
            gisGcj02Lat: 41.2696,
            gisGcj02Lng: 123.174,
            stubGroupCnt: null,
            pinYin: 'Baita',
          },
          {
            id: '211003',
            text: '文圣区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2681,
            gisBd09Lng: 123.191,
            gisGcj02Lat: 41.2624,
            gisGcj02Lng: 123.184,
            stubGroupCnt: null,
            pinYin: 'Wensheng',
          },
          {
            id: '211004',
            text: '宏伟区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2229,
            gisBd09Lng: 123.204,
            gisGcj02Lat: 41.2171,
            gisGcj02Lng: 123.197,
            stubGroupCnt: null,
            pinYin: 'Hongwei',
          },
          {
            id: '211005',
            text: '弓长岭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1569,
            gisBd09Lng: 123.427,
            gisGcj02Lat: 41.1513,
            gisGcj02Lng: 123.421,
            stubGroupCnt: null,
            pinYin: 'Gongchangling',
          },
          {
            id: '211011',
            text: '太子河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2587,
            gisBd09Lng: 123.188,
            gisGcj02Lat: 41.2531,
            gisGcj02Lng: 123.181,
            stubGroupCnt: null,
            pinYin: 'Taizihe',
          },
          {
            id: '211021',
            text: '辽阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2114,
            gisBd09Lng: 123.112,
            gisGcj02Lat: 41.2051,
            gisGcj02Lng: 123.106,
            stubGroupCnt: null,
            pinYin: 'Liaoyang',
          },
          {
            id: '211081',
            text: '灯塔',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.4321,
            gisBd09Lng: 123.346,
            gisGcj02Lat: 41.4259,
            gisGcj02Lng: 123.34,
            stubGroupCnt: null,
            pinYin: 'Dengta',
          },
        ],
        gisBd09Lng: 123.245,
        text: '辽阳',
        gisBd09Lat: 41.274,
        gisGcj02Lat: 41.2677,
      },
      {
        pinYin: 'Panjin',
        id: '211100',
        gisGcj02Lng: 122.072,
        children: [
          {
            id: '211101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1258,
            gisBd09Lng: 122.078,
            gisGcj02Lat: 41.1198,
            gisGcj02Lng: 122.072,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '211102',
            text: '双台子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1969,
            gisBd09Lng: 122.067,
            gisGcj02Lat: 41.1908,
            gisGcj02Lng: 122.061,
            stubGroupCnt: null,
            pinYin: 'Shuangtaizi',
          },
          {
            id: '211103',
            text: '兴隆台区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1443,
            gisBd09Lng: 122.113,
            gisGcj02Lat: 41.1387,
            gisGcj02Lng: 122.106,
            stubGroupCnt: null,
            pinYin: 'Xinglongtai',
          },
          {
            id: '211121',
            text: '大洼县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0085,
            gisBd09Lng: 122.088,
            gisGcj02Lat: 41.0027,
            gisGcj02Lng: 122.082,
            stubGroupCnt: null,
            pinYin: 'Dawa',
          },
          {
            id: '211122',
            text: '盘山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2485,
            gisBd09Lng: 122.002,
            gisGcj02Lat: 41.2427,
            gisGcj02Lng: 121.996,
            stubGroupCnt: null,
            pinYin: 'Panshan',
          },
        ],
        gisBd09Lng: 122.078,
        text: '盘锦',
        gisBd09Lat: 41.1258,
        gisGcj02Lat: 41.1198,
      },
      {
        pinYin: 'Tieling',
        id: '211200',
        gisGcj02Lng: 123.842,
        children: [
          {
            id: '211201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.292,
            gisBd09Lng: 123.848,
            gisGcj02Lat: 42.2857,
            gisGcj02Lng: 123.842,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '211202',
            text: '银州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2923,
            gisBd09Lng: 123.849,
            gisGcj02Lat: 42.286,
            gisGcj02Lng: 123.843,
            stubGroupCnt: null,
            pinYin: 'Yinzhou',
          },
          {
            id: '211204',
            text: '清河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.5528,
            gisBd09Lng: 124.165,
            gisGcj02Lat: 42.547,
            gisGcj02Lng: 124.158,
            stubGroupCnt: null,
            pinYin: 'Qinghe',
          },
          {
            id: '211221',
            text: '铁岭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2292,
            gisBd09Lng: 123.734,
            gisGcj02Lat: 42.2228,
            gisGcj02Lng: 123.727,
            stubGroupCnt: null,
            pinYin: 'Tieling',
          },
          {
            id: '211223',
            text: '西丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7436,
            gisBd09Lng: 124.733,
            gisGcj02Lat: 42.7379,
            gisGcj02Lng: 124.726,
            stubGroupCnt: null,
            pinYin: 'Xifeng',
          },
          {
            id: '211224',
            text: '昌图县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7924,
            gisBd09Lng: 124.116,
            gisGcj02Lat: 42.7864,
            gisGcj02Lng: 124.11,
            stubGroupCnt: null,
            pinYin: 'Changtu',
          },
          {
            id: '211281',
            text: '调兵山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.4654,
            gisBd09Lng: 123.573,
            gisGcj02Lat: 42.4595,
            gisGcj02Lng: 123.566,
            stubGroupCnt: null,
            pinYin: 'Diaobingshan',
          },
          {
            id: '211282',
            text: '开原',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.5522,
            gisBd09Lng: 124.044,
            gisGcj02Lat: 42.5464,
            gisGcj02Lng: 124.037,
            stubGroupCnt: null,
            pinYin: 'Kaiyuan',
          },
        ],
        gisBd09Lng: 123.848,
        text: '铁岭',
        gisBd09Lat: 42.292,
        gisGcj02Lat: 42.2857,
      },
      {
        pinYin: 'Chaoyang',
        id: '211300',
        gisGcj02Lng: 120.45,
        children: [
          {
            id: '211301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5795,
            gisBd09Lng: 120.456,
            gisGcj02Lat: 41.5735,
            gisGcj02Lng: 120.45,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '211302',
            text: '双塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5713,
            gisBd09Lng: 120.461,
            gisGcj02Lat: 41.5652,
            gisGcj02Lng: 120.455,
            stubGroupCnt: null,
            pinYin: 'Shuangta',
          },
          {
            id: '211303',
            text: '龙城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.6052,
            gisBd09Lng: 120.422,
            gisGcj02Lat: 41.5995,
            gisGcj02Lng: 120.415,
            stubGroupCnt: null,
            pinYin: 'Longcheng',
          },
          {
            id: '211321',
            text: '朝阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3734,
            gisBd09Lng: 120.303,
            gisGcj02Lat: 41.3678,
            gisGcj02Lng: 120.296,
            stubGroupCnt: null,
            pinYin: 'Chaoyang',
          },
          {
            id: '211322',
            text: '建平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.4094,
            gisBd09Lng: 119.65,
            gisGcj02Lat: 41.403,
            gisGcj02Lng: 119.644,
            stubGroupCnt: null,
            pinYin: 'Jianping',
          },
          {
            id: '211324',
            text: '喀喇沁左翼蒙古族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1309,
            gisBd09Lng: 119.744,
            gisGcj02Lat: 41.1248,
            gisGcj02Lng: 119.738,
            stubGroupCnt: null,
            pinYin: 'Kalaqinzuoyi',
          },
          {
            id: '211381',
            text: '北票',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8055,
            gisBd09Lng: 120.777,
            gisGcj02Lat: 41.7998,
            gisGcj02Lng: 120.77,
            stubGroupCnt: null,
            pinYin: 'Beipiao',
          },
          {
            id: '211382',
            text: '凌源',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2525,
            gisBd09Lng: 119.406,
            gisGcj02Lat: 41.2461,
            gisGcj02Lng: 119.4,
            stubGroupCnt: null,
            pinYin: 'Lingyuan',
          },
        ],
        gisBd09Lng: 120.456,
        text: '朝阳',
        gisBd09Lat: 41.5795,
        gisGcj02Lat: 41.5735,
      },
      {
        pinYin: 'Huludao',
        id: '211400',
        gisGcj02Lng: 120.835,
        children: [
          {
            id: '211401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.718,
            gisBd09Lng: 120.841,
            gisGcj02Lat: 40.7117,
            gisGcj02Lng: 120.835,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '211402',
            text: '连山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7807,
            gisBd09Lng: 120.876,
            gisGcj02Lat: 40.7747,
            gisGcj02Lng: 120.869,
            stubGroupCnt: null,
            pinYin: 'Lianshan',
          },
          {
            id: '211403',
            text: '龙港区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7423,
            gisBd09Lng: 120.902,
            gisGcj02Lat: 40.7367,
            gisGcj02Lng: 120.896,
            stubGroupCnt: null,
            pinYin: 'Longgang',
          },
          {
            id: '211404',
            text: '南票区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1131,
            gisBd09Lng: 120.756,
            gisGcj02Lat: 41.107,
            gisGcj02Lng: 120.749,
            stubGroupCnt: null,
            pinYin: 'Nanpiao',
          },
          {
            id: '211421',
            text: '绥中县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3321,
            gisBd09Lng: 120.35,
            gisGcj02Lat: 40.3258,
            gisGcj02Lng: 120.343,
            stubGroupCnt: null,
            pinYin: 'Suizhong',
          },
          {
            id: '211422',
            text: '建昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8295,
            gisBd09Lng: 119.843,
            gisGcj02Lat: 40.8237,
            gisGcj02Lng: 119.837,
            stubGroupCnt: null,
            pinYin: 'Jianchang',
          },
          {
            id: '211481',
            text: '兴城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6162,
            gisBd09Lng: 120.762,
            gisGcj02Lat: 40.6103,
            gisGcj02Lng: 120.755,
            stubGroupCnt: null,
            pinYin: 'Xingcheng',
          },
        ],
        gisBd09Lng: 120.841,
        text: '葫芦岛',
        gisBd09Lat: 40.718,
        gisGcj02Lat: 40.7117,
      },
    ],
  },
  {
    text: '内蒙古',
    children: [
      {
        pinYin: 'Hohhot',
        id: '150100',
        gisGcj02Lng: 111.752,
        children: [
          {
            id: '150101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8475,
            gisBd09Lng: 111.758,
            gisGcj02Lat: 40.8415,
            gisGcj02Lng: 111.752,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150102',
            text: '新城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8637,
            gisBd09Lng: 111.671,
            gisGcj02Lat: 40.858,
            gisGcj02Lng: 111.664,
            stubGroupCnt: null,
            pinYin: 'Xincheng',
          },
          {
            id: '150103',
            text: '回民区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8135,
            gisBd09Lng: 111.629,
            gisGcj02Lat: 40.8074,
            gisGcj02Lng: 111.623,
            stubGroupCnt: null,
            pinYin: 'Huimin',
          },
          {
            id: '150104',
            text: '玉泉区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7585,
            gisBd09Lng: 111.682,
            gisGcj02Lat: 40.7527,
            gisGcj02Lng: 111.675,
            stubGroupCnt: null,
            pinYin: 'Yuquan',
          },
          {
            id: '150105',
            text: '赛罕区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7972,
            gisBd09Lng: 111.708,
            gisGcj02Lat: 40.791,
            gisGcj02Lng: 111.702,
            stubGroupCnt: null,
            pinYin: 'Saihan',
          },
          {
            id: '150121',
            text: '土默特左旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7349,
            gisBd09Lng: 111.171,
            gisGcj02Lat: 40.7291,
            gisGcj02Lng: 111.164,
            stubGroupCnt: null,
            pinYin: 'Tumotezuoqi',
          },
          {
            id: '150122',
            text: '托克托县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2825,
            gisBd09Lng: 111.201,
            gisGcj02Lat: 40.2768,
            gisGcj02Lng: 111.195,
            stubGroupCnt: null,
            pinYin: 'Tuoketuo',
          },
          {
            id: '150123',
            text: '和林格尔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3847,
            gisBd09Lng: 111.827,
            gisGcj02Lat: 40.3785,
            gisGcj02Lng: 111.821,
            stubGroupCnt: null,
            pinYin: 'Helingeer',
          },
          {
            id: '150124',
            text: '清水河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9146,
            gisBd09Lng: 111.692,
            gisGcj02Lat: 39.9087,
            gisGcj02Lng: 111.686,
            stubGroupCnt: null,
            pinYin: 'Qingshuihe',
          },
          {
            id: '150125',
            text: '武川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.102,
            gisBd09Lng: 111.457,
            gisGcj02Lat: 41.096,
            gisGcj02Lng: 111.45,
            stubGroupCnt: null,
            pinYin: 'Wuchuan',
          },
        ],
        gisBd09Lng: 111.758,
        text: '呼和浩特',
        gisBd09Lat: 40.8475,
        gisGcj02Lat: 40.8415,
      },
      {
        pinYin: 'Baotou',
        id: '150200',
        gisGcj02Lng: 109.84,
        children: [
          {
            id: '150201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6632,
            gisBd09Lng: 109.847,
            gisGcj02Lat: 40.6574,
            gisGcj02Lng: 109.84,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150202',
            text: '东河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5807,
            gisBd09Lng: 110.051,
            gisGcj02Lat: 40.5744,
            gisGcj02Lng: 110.044,
            stubGroupCnt: null,
            pinYin: 'Donghe',
          },
          {
            id: '150203',
            text: '昆都仑区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6473,
            gisBd09Lng: 109.844,
            gisGcj02Lat: 40.6414,
            gisGcj02Lng: 109.838,
            stubGroupCnt: null,
            pinYin: 'Kundulun',
          },
          {
            id: '150204',
            text: '青山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6513,
            gisBd09Lng: 109.908,
            gisGcj02Lat: 40.6451,
            gisGcj02Lng: 109.902,
            stubGroupCnt: null,
            pinYin: 'Qingshan',
          },
          {
            id: '150205',
            text: '石拐区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6774,
            gisBd09Lng: 110.279,
            gisGcj02Lat: 40.6711,
            gisGcj02Lng: 110.272,
            stubGroupCnt: null,
            pinYin: 'Shiguai',
          },
          {
            id: '150206',
            text: '白云矿区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7741,
            gisBd09Lng: 109.98,
            gisGcj02Lat: 41.7684,
            gisGcj02Lng: 109.974,
            stubGroupCnt: null,
            pinYin: 'Baiyunebokuangqu',
          },
          {
            id: '150207',
            text: '九原区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6159,
            gisBd09Lng: 109.974,
            gisGcj02Lat: 40.6102,
            gisGcj02Lng: 109.968,
            stubGroupCnt: null,
            pinYin: 'Jiuyuan',
          },
          {
            id: '150221',
            text: '土默特右旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.575,
            gisBd09Lng: 110.531,
            gisGcj02Lat: 40.5687,
            gisGcj02Lng: 110.525,
            stubGroupCnt: null,
            pinYin: 'Tumoteyouqi',
          },
          {
            id: '150222',
            text: '固阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0399,
            gisBd09Lng: 110.068,
            gisGcj02Lat: 41.0337,
            gisGcj02Lng: 110.061,
            stubGroupCnt: null,
            pinYin: 'Guyang',
          },
          {
            id: '150223',
            text: '达尔罕茂明安联合旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7043,
            gisBd09Lng: 110.439,
            gisGcj02Lat: 41.6983,
            gisGcj02Lng: 110.433,
            stubGroupCnt: null,
            pinYin: 'Damaoqi',
          },
        ],
        gisBd09Lng: 109.847,
        text: '包头',
        gisBd09Lat: 40.6632,
        gisGcj02Lat: 40.6574,
      },
      {
        pinYin: 'Wuhai',
        id: '150300',
        gisGcj02Lng: 106.796,
        children: [
          {
            id: '150301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.66,
            gisBd09Lng: 106.802,
            gisGcj02Lat: 39.6537,
            gisGcj02Lng: 106.796,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150302',
            text: '海勃湾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.6966,
            gisBd09Lng: 106.829,
            gisGcj02Lat: 39.6905,
            gisGcj02Lng: 106.823,
            stubGroupCnt: null,
            pinYin: 'Haibowan',
          },
          {
            id: '150303',
            text: '海南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4471,
            gisBd09Lng: 106.897,
            gisGcj02Lat: 39.4411,
            gisGcj02Lng: 106.891,
            stubGroupCnt: null,
            pinYin: 'Hainan',
          },
          {
            id: '150304',
            text: '乌达区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5102,
            gisBd09Lng: 106.735,
            gisGcj02Lat: 39.5044,
            gisGcj02Lng: 106.728,
            stubGroupCnt: null,
            pinYin: 'Wuda',
          },
        ],
        gisBd09Lng: 106.802,
        text: '乌海',
        gisBd09Lat: 39.66,
        gisGcj02Lat: 39.6537,
      },
      {
        pinYin: 'Chifeng',
        id: '150400',
        gisGcj02Lng: 118.889,
        children: [
          {
            id: '150401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2638,
            gisBd09Lng: 118.896,
            gisGcj02Lat: 42.2579,
            gisGcj02Lng: 118.889,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150402',
            text: '红山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2708,
            gisBd09Lng: 118.965,
            gisGcj02Lat: 42.265,
            gisGcj02Lng: 118.959,
            stubGroupCnt: null,
            pinYin: 'Hongshan',
          },
          {
            id: '150403',
            text: '元宝山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0445,
            gisBd09Lng: 119.294,
            gisGcj02Lat: 42.0381,
            gisGcj02Lng: 119.288,
            stubGroupCnt: null,
            pinYin: 'Yuanbaoshan',
          },
          {
            id: '150404',
            text: '松山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2924,
            gisBd09Lng: 118.94,
            gisGcj02Lat: 42.2861,
            gisGcj02Lng: 118.934,
            stubGroupCnt: null,
            pinYin: 'Songshan',
          },
          {
            id: '150421',
            text: '阿鲁科尔沁旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8781,
            gisBd09Lng: 120.07,
            gisGcj02Lat: 43.8724,
            gisGcj02Lng: 120.064,
            stubGroupCnt: null,
            pinYin: 'Alukeerqinqi',
          },
          {
            id: '150422',
            text: '巴林左旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.976,
            gisBd09Lng: 119.387,
            gisGcj02Lat: 43.9698,
            gisGcj02Lng: 119.38,
            stubGroupCnt: null,
            pinYin: 'Balinzuoqi',
          },
          {
            id: '150423',
            text: '巴林右旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.5399,
            gisBd09Lng: 118.671,
            gisGcj02Lat: 43.5336,
            gisGcj02Lng: 118.665,
            stubGroupCnt: null,
            pinYin: 'Balinyouqi',
          },
          {
            id: '150424',
            text: '林西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6229,
            gisBd09Lng: 118.062,
            gisGcj02Lat: 43.6168,
            gisGcj02Lng: 118.056,
            stubGroupCnt: null,
            pinYin: 'Linxi',
          },
          {
            id: '150425',
            text: '克什克腾旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.2697,
            gisBd09Lng: 117.551,
            gisGcj02Lat: 43.264,
            gisGcj02Lng: 117.545,
            stubGroupCnt: null,
            pinYin: 'Keshiketengqi',
          },
          {
            id: '150426',
            text: '翁牛特旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.9404,
            gisBd09Lng: 119.013,
            gisGcj02Lat: 42.9345,
            gisGcj02Lng: 119.006,
            stubGroupCnt: null,
            pinYin: 'Wengniuteqi',
          },
          {
            id: '150428',
            text: '喀喇沁旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9341,
            gisBd09Lng: 118.707,
            gisGcj02Lat: 41.9279,
            gisGcj02Lng: 118.7,
            stubGroupCnt: null,
            pinYin: 'Kalaqinqi',
          },
          {
            id: '150429',
            text: '宁城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5997,
            gisBd09Lng: 119.351,
            gisGcj02Lat: 41.594,
            gisGcj02Lng: 119.344,
            stubGroupCnt: null,
            pinYin: 'Ningcheng',
          },
          {
            id: '150430',
            text: '敖汉旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2954,
            gisBd09Lng: 119.928,
            gisGcj02Lat: 42.2896,
            gisGcj02Lng: 119.922,
            stubGroupCnt: null,
            pinYin: 'Aohanqi',
          },
        ],
        gisBd09Lng: 118.896,
        text: '赤峰',
        gisBd09Lat: 42.2638,
        gisGcj02Lat: 42.2579,
      },
      {
        pinYin: 'Tongliao',
        id: '150500',
        gisGcj02Lng: 122.245,
        children: [
          {
            id: '150501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6572,
            gisBd09Lng: 122.252,
            gisGcj02Lat: 43.6513,
            gisGcj02Lng: 122.245,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150502',
            text: '科尔沁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6286,
            gisBd09Lng: 122.263,
            gisGcj02Lat: 43.6224,
            gisGcj02Lng: 122.257,
            stubGroupCnt: null,
            pinYin: 'Keerqin',
          },
          {
            id: '150521',
            text: '科尔沁左翼中旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0568,
            gisBd09Lng: 122.501,
            gisGcj02Lat: 44.0507,
            gisGcj02Lng: 122.495,
            stubGroupCnt: null,
            pinYin: 'Keerqinzuoyizhongqi',
          },
          {
            id: '150522',
            text: '科尔沁左翼后旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.1934,
            gisBd09Lng: 122.704,
            gisGcj02Lat: 43.1878,
            gisGcj02Lng: 122.697,
            stubGroupCnt: null,
            pinYin: 'Keerqinzuoyihouqi',
          },
          {
            id: '150523',
            text: '开鲁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6056,
            gisBd09Lng: 121.327,
            gisGcj02Lat: 43.5992,
            gisGcj02Lng: 121.32,
            stubGroupCnt: null,
            pinYin: 'Kailu',
          },
          {
            id: '150524',
            text: '库伦旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7407,
            gisBd09Lng: 121.817,
            gisGcj02Lat: 42.7344,
            gisGcj02Lng: 121.811,
            stubGroupCnt: null,
            pinYin: 'Kulunqi',
          },
          {
            id: '150525',
            text: '奈曼旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8722,
            gisBd09Lng: 120.664,
            gisGcj02Lat: 42.8666,
            gisGcj02Lng: 120.657,
            stubGroupCnt: null,
            pinYin: 'Naimanqi',
          },
          {
            id: '150526',
            text: '扎鲁特旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5607,
            gisBd09Lng: 120.92,
            gisGcj02Lat: 44.555,
            gisGcj02Lng: 120.913,
            stubGroupCnt: null,
            pinYin: 'Zhaluteqi',
          },
          {
            id: '150581',
            text: '霍林郭勒',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.5367,
            gisBd09Lng: 119.671,
            gisGcj02Lat: 45.5306,
            gisGcj02Lng: 119.664,
            stubGroupCnt: null,
            pinYin: 'Huolinguole',
          },
        ],
        gisBd09Lng: 122.252,
        text: '通辽',
        gisBd09Lat: 43.6572,
        gisGcj02Lat: 43.6513,
      },
      {
        pinYin: 'Ordos',
        id: '150600',
        gisGcj02Lng: 109.782,
        children: [
          {
            id: '150602',
            text: '东胜区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8265,
            gisBd09Lng: 109.971,
            gisGcj02Lat: 39.8208,
            gisGcj02Lng: 109.964,
            stubGroupCnt: null,
            pinYin: 'Dongsheng',
          },
          {
            id: '150621',
            text: '达拉特旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4073,
            gisBd09Lng: 110.041,
            gisGcj02Lat: 40.401,
            gisGcj02Lng: 110.035,
            stubGroupCnt: null,
            pinYin: 'Dalateqi',
          },
          {
            id: '150622',
            text: '准格尔旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8713,
            gisBd09Lng: 111.249,
            gisGcj02Lat: 39.8649,
            gisGcj02Lng: 111.242,
            stubGroupCnt: null,
            pinYin: 'Zhungeerqi',
          },
          {
            id: '150623',
            text: '鄂托克前旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1888,
            gisBd09Lng: 107.485,
            gisGcj02Lat: 38.183,
            gisGcj02Lng: 107.479,
            stubGroupCnt: null,
            pinYin: 'Etuokeqianqi',
          },
          {
            id: '150624',
            text: '鄂托克旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0971,
            gisBd09Lng: 107.987,
            gisGcj02Lat: 39.091,
            gisGcj02Lng: 107.98,
            stubGroupCnt: null,
            pinYin: 'Etuokeqi',
          },
          {
            id: '150625',
            text: '杭锦旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8392,
            gisBd09Lng: 108.741,
            gisGcj02Lat: 39.833,
            gisGcj02Lng: 108.734,
            stubGroupCnt: null,
            pinYin: 'Hangjinqi',
          },
          {
            id: '150626',
            text: '乌审旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.6105,
            gisBd09Lng: 108.826,
            gisGcj02Lat: 38.6043,
            gisGcj02Lng: 108.82,
            stubGroupCnt: null,
            pinYin: 'Wushenqi',
          },
          {
            id: '150627',
            text: '伊金霍洛旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5697,
            gisBd09Lng: 109.755,
            gisGcj02Lat: 39.564,
            gisGcj02Lng: 109.748,
            stubGroupCnt: null,
            pinYin: 'Yijinhuoluoqi',
          },
        ],
        gisBd09Lng: 109.789,
        text: '鄂尔多斯',
        gisBd09Lat: 39.6142,
        gisGcj02Lat: 39.608,
      },
      {
        pinYin: 'Hulunber',
        id: '150700',
        gisGcj02Lng: 119.766,
        children: [
          {
            id: '150701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.2174,
            gisBd09Lng: 119.773,
            gisGcj02Lat: 49.2111,
            gisGcj02Lng: 119.766,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150702',
            text: '海拉尔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.218,
            gisBd09Lng: 119.741,
            gisGcj02Lat: 49.2119,
            gisGcj02Lng: 119.735,
            stubGroupCnt: null,
            pinYin: 'Hailaer',
          },
          {
            id: '150721',
            text: '阿荣旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.1317,
            gisBd09Lng: 123.467,
            gisGcj02Lat: 48.1255,
            gisGcj02Lng: 123.46,
            stubGroupCnt: null,
            pinYin: 'Arongqi',
          },
          {
            id: '150722',
            text: '莫力达瓦达斡尔族自治旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.1038,
            gisBd09Lng: 124.474,
            gisGcj02Lat: 49.0977,
            gisGcj02Lng: 124.467,
            stubGroupCnt: null,
            pinYin: 'Moqi',
          },
          {
            id: '150723',
            text: '鄂伦春自治旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 50.5972,
            gisBd09Lng: 123.733,
            gisGcj02Lat: 50.5909,
            gisGcj02Lng: 123.727,
            stubGroupCnt: null,
            pinYin: 'Elunchun',
          },
          {
            id: '150724',
            text: '鄂温克族自治旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.1528,
            gisBd09Lng: 119.762,
            gisGcj02Lat: 49.1464,
            gisGcj02Lng: 119.756,
            stubGroupCnt: null,
            pinYin: 'Ewen',
          },
          {
            id: '150725',
            text: '陈巴尔虎旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.3338,
            gisBd09Lng: 119.431,
            gisGcj02Lat: 49.3277,
            gisGcj02Lng: 119.425,
            stubGroupCnt: null,
            pinYin: 'Chenbaerhuqi',
          },
          {
            id: '150726',
            text: '新巴尔虎左旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.224,
            gisBd09Lng: 118.275,
            gisGcj02Lat: 48.2183,
            gisGcj02Lng: 118.268,
            stubGroupCnt: null,
            pinYin: 'Xinbaerhuzuoqi',
          },
          {
            id: '150727',
            text: '新巴尔虎右旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.6783,
            gisBd09Lng: 116.828,
            gisGcj02Lat: 48.6727,
            gisGcj02Lng: 116.822,
            stubGroupCnt: null,
            pinYin: 'Xinbaerhuyouqi',
          },
          {
            id: '150781',
            text: '满洲里',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.6034,
            gisBd09Lng: 117.387,
            gisGcj02Lat: 49.5972,
            gisGcj02Lng: 117.38,
            stubGroupCnt: null,
            pinYin: 'Manzhouli',
          },
          {
            id: '150782',
            text: '牙克石',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 49.2916,
            gisBd09Lng: 120.719,
            gisGcj02Lat: 49.2852,
            gisGcj02Lng: 120.713,
            stubGroupCnt: null,
            pinYin: 'Yakeshi',
          },
          {
            id: '150783',
            text: '扎兰屯',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.0191,
            gisBd09Lng: 122.743,
            gisGcj02Lat: 48.013,
            gisGcj02Lng: 122.737,
            stubGroupCnt: null,
            pinYin: 'Zhalantun',
          },
          {
            id: '150784',
            text: '额尔古纳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 50.2481,
            gisBd09Lng: 120.186,
            gisGcj02Lat: 50.2425,
            gisGcj02Lng: 120.18,
            stubGroupCnt: null,
            pinYin: 'Eerguna',
          },
          {
            id: '150785',
            text: '根河',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 50.7853,
            gisBd09Lng: 121.526,
            gisGcj02Lat: 50.7795,
            gisGcj02Lng: 121.519,
            stubGroupCnt: null,
            pinYin: 'Genhe',
          },
        ],
        gisBd09Lng: 119.773,
        text: '呼伦贝尔',
        gisBd09Lat: 49.2174,
        gisGcj02Lat: 49.2111,
      },
      {
        pinYin: 'Bayan Nur',
        id: '150800',
        gisGcj02Lng: 107.389,
        children: [
          {
            id: '150801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7488,
            gisBd09Lng: 107.395,
            gisGcj02Lat: 40.7425,
            gisGcj02Lng: 107.389,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150802',
            text: '临河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.7726,
            gisBd09Lng: 107.393,
            gisGcj02Lat: 40.7663,
            gisGcj02Lng: 107.387,
            stubGroupCnt: null,
            pinYin: 'Linhe',
          },
          {
            id: '150821',
            text: '五原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0939,
            gisBd09Lng: 108.274,
            gisGcj02Lat: 41.0879,
            gisGcj02Lng: 108.268,
            stubGroupCnt: null,
            pinYin: 'Wuyuan',
          },
          {
            id: '150822',
            text: '磴口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3364,
            gisBd09Lng: 107.013,
            gisGcj02Lat: 40.3305,
            gisGcj02Lng: 107.007,
            stubGroupCnt: null,
            pinYin: 'Dengkou',
          },
          {
            id: '150823',
            text: '乌拉特前旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.742,
            gisBd09Lng: 108.658,
            gisGcj02Lat: 40.7363,
            gisGcj02Lng: 108.651,
            stubGroupCnt: null,
            pinYin: 'Wulateqianqi',
          },
          {
            id: '150824',
            text: '乌拉特中旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5729,
            gisBd09Lng: 108.532,
            gisGcj02Lat: 41.5671,
            gisGcj02Lng: 108.525,
            stubGroupCnt: null,
            pinYin: 'Wulatezhongqi',
          },
          {
            id: '150825',
            text: '乌拉特后旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0884,
            gisBd09Lng: 107.082,
            gisGcj02Lat: 41.0825,
            gisGcj02Lng: 107.076,
            stubGroupCnt: null,
            pinYin: 'Wulatehouqi',
          },
          {
            id: '150826',
            text: '杭锦后旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8916,
            gisBd09Lng: 107.157,
            gisGcj02Lat: 40.8853,
            gisGcj02Lng: 107.151,
            stubGroupCnt: null,
            pinYin: 'Hangjinhouqi',
          },
        ],
        gisBd09Lng: 107.395,
        text: '巴彦淖尔',
        gisBd09Lat: 40.7488,
        gisGcj02Lat: 40.7425,
      },
      {
        pinYin: 'Ulanqab',
        id: '150900',
        gisGcj02Lng: 113.133,
        children: [
          {
            id: '150901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9984,
            gisBd09Lng: 113.139,
            gisGcj02Lat: 40.9924,
            gisGcj02Lng: 113.133,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '150902',
            text: '集宁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9964,
            gisBd09Lng: 113.13,
            gisGcj02Lat: 40.9905,
            gisGcj02Lng: 113.124,
            stubGroupCnt: null,
            pinYin: 'Jining',
          },
          {
            id: '150921',
            text: '卓资县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8993,
            gisBd09Lng: 112.585,
            gisGcj02Lat: 40.8931,
            gisGcj02Lng: 112.579,
            stubGroupCnt: null,
            pinYin: 'Zhuozi',
          },
          {
            id: '150922',
            text: '化德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9102,
            gisBd09Lng: 114.018,
            gisGcj02Lat: 41.9039,
            gisGcj02Lng: 114.012,
            stubGroupCnt: null,
            pinYin: 'Huade',
          },
          {
            id: '150923',
            text: '商都县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5671,
            gisBd09Lng: 113.583,
            gisGcj02Lat: 41.5615,
            gisGcj02Lng: 113.577,
            stubGroupCnt: null,
            pinYin: 'Shangdu',
          },
          {
            id: '150924',
            text: '兴和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.8768,
            gisBd09Lng: 113.84,
            gisGcj02Lat: 40.871,
            gisGcj02Lng: 113.834,
            stubGroupCnt: null,
            pinYin: 'Xinghe',
          },
          {
            id: '150925',
            text: '凉城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5375,
            gisBd09Lng: 112.508,
            gisGcj02Lat: 40.5319,
            gisGcj02Lng: 112.502,
            stubGroupCnt: null,
            pinYin: 'Liangcheng',
          },
          {
            id: '150926',
            text: '察哈尔右翼前旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.0259,
            gisBd09Lng: 113.327,
            gisGcj02Lat: 41.0201,
            gisGcj02Lng: 113.32,
            stubGroupCnt: null,
            pinYin: 'Chayouqianqi',
          },
          {
            id: '150927',
            text: '察哈尔右翼中旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2727,
            gisBd09Lng: 112.642,
            gisGcj02Lat: 41.267,
            gisGcj02Lng: 112.636,
            stubGroupCnt: null,
            pinYin: 'Chayouzhongqi',
          },
          {
            id: '150928',
            text: '察哈尔右翼后旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.4404,
            gisBd09Lng: 113.197,
            gisGcj02Lat: 41.4344,
            gisGcj02Lng: 113.19,
            stubGroupCnt: null,
            pinYin: 'Chayouhouqi',
          },
          {
            id: '150929',
            text: '四子王旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5376,
            gisBd09Lng: 111.714,
            gisGcj02Lat: 41.5313,
            gisGcj02Lng: 111.707,
            stubGroupCnt: null,
            pinYin: 'Siziwangqi',
          },
          {
            id: '150981',
            text: '丰镇',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4405,
            gisBd09Lng: 113.118,
            gisGcj02Lat: 40.4347,
            gisGcj02Lng: 113.112,
            stubGroupCnt: null,
            pinYin: 'Fengzhen',
          },
        ],
        gisBd09Lng: 113.139,
        text: '乌兰察布',
        gisBd09Lat: 40.9984,
        gisGcj02Lat: 40.9924,
      },
      {
        pinYin: 'Hinggan',
        id: '152200',
        gisGcj02Lng: 122.039,
        children: [
          {
            id: '152201',
            text: '乌兰浩特',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.0767,
            gisBd09Lng: 122.1,
            gisGcj02Lat: 46.071,
            gisGcj02Lng: 122.094,
            stubGroupCnt: null,
            pinYin: 'Wulanhaote',
          },
          {
            id: '152202',
            text: '阿尔山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1823,
            gisBd09Lng: 119.95,
            gisGcj02Lat: 47.1767,
            gisGcj02Lng: 119.944,
            stubGroupCnt: null,
            pinYin: 'Aershan',
          },
          {
            id: '152221',
            text: '科尔沁右翼前旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.3322,
            gisBd09Lng: 121.225,
            gisGcj02Lat: 46.326,
            gisGcj02Lng: 121.218,
            stubGroupCnt: null,
            pinYin: 'Keyouqianqi',
          },
          {
            id: '152222',
            text: '科尔沁右翼中旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.0662,
            gisBd09Lng: 121.483,
            gisGcj02Lat: 45.0604,
            gisGcj02Lng: 121.477,
            stubGroupCnt: null,
            pinYin: 'Keyouzhongqi',
          },
          {
            id: '152223',
            text: '扎赉特旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.728,
            gisBd09Lng: 122.907,
            gisGcj02Lat: 46.7218,
            gisGcj02Lng: 122.9,
            stubGroupCnt: null,
            pinYin: 'Zhalaiteqi',
          },
          {
            id: '152224',
            text: '突泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.3873,
            gisBd09Lng: 121.603,
            gisGcj02Lat: 45.3814,
            gisGcj02Lng: 121.596,
            stubGroupCnt: null,
            pinYin: 'Tuquan',
          },
        ],
        gisBd09Lng: 122.046,
        text: '兴安盟',
        gisBd09Lat: 46.0881,
        gisGcj02Lat: 46.0818,
      },
      {
        pinYin: 'Xilin Gol',
        id: '152500',
        gisGcj02Lng: 116.047,
        children: [
          {
            id: '152501',
            text: '二连浩特',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6488,
            gisBd09Lng: 111.959,
            gisGcj02Lat: 43.6425,
            gisGcj02Lng: 111.952,
            stubGroupCnt: null,
            pinYin: 'Erlianhaote',
          },
          {
            id: '152502',
            text: '锡林浩特',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.9373,
            gisBd09Lng: 116.094,
            gisGcj02Lat: 43.9315,
            gisGcj02Lng: 116.087,
            stubGroupCnt: null,
            pinYin: 'Xilinhaote',
          },
          {
            id: '152522',
            text: '阿巴嘎旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0283,
            gisBd09Lng: 114.958,
            gisGcj02Lat: 44.022,
            gisGcj02Lng: 114.952,
            stubGroupCnt: null,
            pinYin: 'Abagaqi',
          },
          {
            id: '152523',
            text: '苏尼特左旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8653,
            gisBd09Lng: 113.675,
            gisGcj02Lat: 43.8593,
            gisGcj02Lng: 113.668,
            stubGroupCnt: null,
            pinYin: 'Sunitezuoqi',
          },
          {
            id: '152524',
            text: '苏尼特右旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7531,
            gisBd09Lng: 112.665,
            gisGcj02Lat: 42.7469,
            gisGcj02Lng: 112.658,
            stubGroupCnt: null,
            pinYin: 'Suniteyouqi',
          },
          {
            id: '152525',
            text: '东乌珠穆沁旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.5156,
            gisBd09Lng: 116.979,
            gisGcj02Lat: 45.5096,
            gisGcj02Lng: 116.972,
            stubGroupCnt: null,
            pinYin: 'Dongwuqi',
          },
          {
            id: '152526',
            text: '西乌珠穆沁旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.5936,
            gisBd09Lng: 117.616,
            gisGcj02Lat: 44.5873,
            gisGcj02Lng: 117.609,
            stubGroupCnt: null,
            pinYin: 'Xiwuqi',
          },
          {
            id: '152527',
            text: '太仆寺旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8821,
            gisBd09Lng: 115.29,
            gisGcj02Lat: 41.8762,
            gisGcj02Lng: 115.283,
            stubGroupCnt: null,
            pinYin: 'Taipusiqi',
          },
          {
            id: '152528',
            text: '镶黄旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2375,
            gisBd09Lng: 113.854,
            gisGcj02Lat: 42.2315,
            gisGcj02Lng: 113.848,
            stubGroupCnt: null,
            pinYin: 'Xianghuangqi',
          },
          {
            id: '152529',
            text: '正镶白旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2921,
            gisBd09Lng: 115.039,
            gisGcj02Lat: 42.2864,
            gisGcj02Lng: 115.032,
            stubGroupCnt: null,
            pinYin: 'Zhengxiangbaiqi',
          },
          {
            id: '152530',
            text: '正蓝旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2468,
            gisBd09Lng: 116.0,
            gisGcj02Lat: 42.241,
            gisGcj02Lng: 115.993,
            stubGroupCnt: null,
            pinYin: 'Zhenglanqi',
          },
          {
            id: '152531',
            text: '多伦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2084,
            gisBd09Lng: 116.492,
            gisGcj02Lat: 42.2024,
            gisGcj02Lng: 116.485,
            stubGroupCnt: null,
            pinYin: 'Duolun',
          },
        ],
        gisBd09Lng: 116.053,
        text: '锡林郭勒盟',
        gisBd09Lat: 43.9392,
        gisGcj02Lat: 43.9329,
      },
      {
        pinYin: 'Alxa',
        id: '152900',
        gisGcj02Lng: 105.729,
        children: [
          {
            id: '152921',
            text: '阿拉善左旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.839,
            gisBd09Lng: 105.673,
            gisGcj02Lat: 38.8333,
            gisGcj02Lng: 105.666,
            stubGroupCnt: null,
            pinYin: 'Alashanzuoqi',
          },
          {
            id: '152922',
            text: '阿拉善右旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2212,
            gisBd09Lng: 101.674,
            gisGcj02Lat: 39.2151,
            gisGcj02Lng: 101.667,
            stubGroupCnt: null,
            pinYin: 'Alashanyouqi',
          },
          {
            id: '152923',
            text: '额济纳旗',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9605,
            gisBd09Lng: 101.062,
            gisGcj02Lat: 41.9543,
            gisGcj02Lng: 101.056,
            stubGroupCnt: null,
            pinYin: 'Ejinaqi',
          },
        ],
        gisBd09Lng: 105.735,
        text: '阿拉善盟',
        gisBd09Lat: 38.8577,
        gisGcj02Lat: 38.8514,
      },
    ],
  },
  {
    text: '宁夏省',
    children: [
      {
        pinYin: 'Yinchuan',
        id: '640100',
        gisGcj02Lng: 106.284,
        children: [
          {
            id: '640101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4713,
            gisBd09Lng: 106.294,
            gisGcj02Lat: 38.4654,
            gisGcj02Lng: 106.287,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '640104',
            text: '兴庆区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4706,
            gisBd09Lng: 106.291,
            gisGcj02Lat: 38.4647,
            gisGcj02Lng: 106.284,
            stubGroupCnt: null,
            pinYin: 'Xingqing',
          },
          {
            id: '640105',
            text: '西夏区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4992,
            gisBd09Lng: 106.157,
            gisGcj02Lat: 38.4935,
            gisGcj02Lng: 106.15,
            stubGroupCnt: null,
            pinYin: 'Xixia',
          },
          {
            id: '640106',
            text: '金凤区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4918,
            gisBd09Lng: 106.255,
            gisGcj02Lat: 38.4861,
            gisGcj02Lng: 106.249,
            stubGroupCnt: null,
            pinYin: 'Jinfeng',
          },
          {
            id: '640121',
            text: '永宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2781,
            gisBd09Lng: 106.258,
            gisGcj02Lat: 38.2724,
            gisGcj02Lng: 106.252,
            stubGroupCnt: null,
            pinYin: 'Yongning',
          },
          {
            id: '640122',
            text: '贺兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.5649,
            gisBd09Lng: 106.335,
            gisGcj02Lat: 38.5586,
            gisGcj02Lng: 106.328,
            stubGroupCnt: null,
            pinYin: 'Helan',
          },
          {
            id: '640181',
            text: '灵武',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1082,
            gisBd09Lng: 106.349,
            gisGcj02Lat: 38.102,
            gisGcj02Lng: 106.342,
            stubGroupCnt: null,
            pinYin: 'Lingwu',
          },
        ],
        gisBd09Lng: 106.291,
        text: '银川',
        gisBd09Lat: 38.4706,
        gisGcj02Lat: 38.4647,
      },
      {
        pinYin: 'Shizuishan',
        id: '640200',
        gisGcj02Lng: 106.384,
        children: [
          {
            id: '640201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9897,
            gisBd09Lng: 106.393,
            gisGcj02Lat: 38.984,
            gisGcj02Lng: 106.387,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '640202',
            text: '大武口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0227,
            gisBd09Lng: 106.376,
            gisGcj02Lat: 39.017,
            gisGcj02Lng: 106.369,
            stubGroupCnt: null,
            pinYin: 'Dawukou',
          },
          {
            id: '640205',
            text: '惠农区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2336,
            gisBd09Lng: 106.782,
            gisGcj02Lat: 39.2276,
            gisGcj02Lng: 106.776,
            stubGroupCnt: null,
            pinYin: 'Huinong',
          },
          {
            id: '640221',
            text: '平罗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9189,
            gisBd09Lng: 106.529,
            gisGcj02Lat: 38.9131,
            gisGcj02Lng: 106.522,
            stubGroupCnt: null,
            pinYin: 'Pingluo',
          },
        ],
        gisBd09Lng: 106.39,
        text: '石嘴山',
        gisBd09Lat: 38.9896,
        gisGcj02Lat: 38.9839,
      },
      {
        pinYin: 'Wuzhong',
        id: '640300',
        gisGcj02Lng: 106.2,
        children: [
          {
            id: '640301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.003,
            gisBd09Lng: 106.202,
            gisGcj02Lat: 37.9967,
            gisGcj02Lng: 106.196,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '640302',
            text: '利通区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9905,
            gisBd09Lng: 106.219,
            gisGcj02Lat: 37.9842,
            gisGcj02Lng: 106.212,
            stubGroupCnt: null,
            pinYin: 'Litong',
          },
          {
            id: '640323',
            text: '盐池县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7898,
            gisBd09Lng: 107.415,
            gisGcj02Lat: 37.7835,
            gisGcj02Lng: 107.409,
            stubGroupCnt: null,
            pinYin: 'Yanchi',
          },
          {
            id: '640324',
            text: '同心县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.987,
            gisBd09Lng: 105.92,
            gisGcj02Lat: 36.9812,
            gisGcj02Lng: 105.914,
            stubGroupCnt: null,
            pinYin: 'Tongxin',
          },
          {
            id: '640381',
            text: '青铜峡',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0275,
            gisBd09Lng: 106.085,
            gisGcj02Lat: 38.0212,
            gisGcj02Lng: 106.079,
            stubGroupCnt: null,
            pinYin: 'Qingtongxia',
          },
        ],
        gisBd09Lng: 106.206,
        text: '吴忠',
        gisBd09Lat: 38.0039,
        gisGcj02Lat: 37.9976,
      },
      {
        pinYin: 'Guyuan',
        id: '640400',
        gisGcj02Lng: 106.243,
        children: [
          {
            id: '640401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0216,
            gisBd09Lng: 106.251,
            gisGcj02Lat: 36.0158,
            gisGcj02Lng: 106.244,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '640402',
            text: '原州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0093,
            gisBd09Lng: 106.295,
            gisGcj02Lat: 36.0033,
            gisGcj02Lng: 106.288,
            stubGroupCnt: null,
            pinYin: 'Yuanzhou',
          },
          {
            id: '640422',
            text: '西吉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9702,
            gisBd09Lng: 105.736,
            gisGcj02Lat: 35.9639,
            gisGcj02Lng: 105.73,
            stubGroupCnt: null,
            pinYin: 'Xiji',
          },
          {
            id: '640423',
            text: '隆德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.625,
            gisBd09Lng: 106.131,
            gisGcj02Lat: 35.6192,
            gisGcj02Lng: 106.124,
            stubGroupCnt: null,
            pinYin: 'Longde',
          },
          {
            id: '640424',
            text: '泾源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5031,
            gisBd09Lng: 106.339,
            gisGcj02Lat: 35.4968,
            gisGcj02Lng: 106.333,
            stubGroupCnt: null,
            pinYin: 'Jingyuan',
          },
          {
            id: '640425',
            text: '彭阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8555,
            gisBd09Lng: 106.647,
            gisGcj02Lat: 35.8497,
            gisGcj02Lng: 106.64,
            stubGroupCnt: null,
            pinYin: 'Pengyang',
          },
        ],
        gisBd09Lng: 106.249,
        text: '固原',
        gisBd09Lat: 36.0216,
        gisGcj02Lat: 36.0158,
      },
      {
        pinYin: 'Zhongwei',
        id: '640500',
        gisGcj02Lng: 105.198,
        children: [
          {
            id: '640501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5057,
            gisBd09Lng: 105.204,
            gisGcj02Lat: 37.4999,
            gisGcj02Lng: 105.198,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '640502',
            text: '沙坡头区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5217,
            gisBd09Lng: 105.196,
            gisGcj02Lat: 37.5159,
            gisGcj02Lng: 105.19,
            stubGroupCnt: null,
            pinYin: 'Shapotou',
          },
          {
            id: '640521',
            text: '中宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4976,
            gisBd09Lng: 105.69,
            gisGcj02Lat: 37.4917,
            gisGcj02Lng: 105.683,
            stubGroupCnt: null,
            pinYin: 'Zhongning',
          },
          {
            id: '640522',
            text: '海原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5709,
            gisBd09Lng: 105.651,
            gisGcj02Lat: 36.5651,
            gisGcj02Lng: 105.644,
            stubGroupCnt: null,
            pinYin: 'Haiyuan',
          },
        ],
        gisBd09Lng: 105.204,
        text: '中卫',
        gisBd09Lat: 37.5057,
        gisGcj02Lat: 37.4999,
      },
    ],
  },
  {
    text: '青海省',
    children: [
      {
        pinYin: 'Xining',
        id: '630100',
        gisGcj02Lng: 101.78,
        children: [
          {
            id: '630101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6243,
            gisBd09Lng: 101.783,
            gisGcj02Lat: 36.6181,
            gisGcj02Lng: 101.776,
            stubGroupCnt: 1,
            pinYin: null,
          },
          {
            id: '630102',
            text: '城东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6045,
            gisBd09Lng: 101.811,
            gisGcj02Lat: 36.5987,
            gisGcj02Lng: 101.804,
            stubGroupCnt: null,
            pinYin: 'Chengdong',
          },
          {
            id: '630103',
            text: '城中区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5927,
            gisBd09Lng: 101.777,
            gisGcj02Lat: 36.5865,
            gisGcj02Lng: 101.77,
            stubGroupCnt: null,
            pinYin: 'Chengzhong',
          },
          {
            id: '630104',
            text: '城西区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6341,
            gisBd09Lng: 101.772,
            gisGcj02Lat: 36.6278,
            gisGcj02Lng: 101.766,
            stubGroupCnt: null,
            pinYin: 'Chengxi',
          },
          {
            id: '630105',
            text: '城北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6559,
            gisBd09Lng: 101.773,
            gisGcj02Lat: 36.6496,
            gisGcj02Lng: 101.767,
            stubGroupCnt: null,
            pinYin: 'Chengbei',
          },
          {
            id: '630121',
            text: '大通回族土族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9327,
            gisBd09Lng: 101.692,
            gisGcj02Lat: 36.927,
            gisGcj02Lng: 101.685,
            stubGroupCnt: null,
            pinYin: 'Datong',
          },
          {
            id: '630122',
            text: '湟中县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5067,
            gisBd09Lng: 101.577,
            gisGcj02Lat: 36.5009,
            gisGcj02Lng: 101.571,
            stubGroupCnt: null,
            pinYin: 'Huangzhong',
          },
          {
            id: '630123',
            text: '湟源县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6887,
            gisBd09Lng: 101.261,
            gisGcj02Lat: 36.6826,
            gisGcj02Lng: 101.255,
            stubGroupCnt: null,
            pinYin: 'Huangyuan',
          },
        ],
        gisBd09Lng: 101.786,
        text: '西宁',
        gisBd09Lat: 36.6244,
        gisGcj02Lat: 36.6182,
      },
      {
        pinYin: null,
        id: '632100',
        gisGcj02Lng: 102.079,
        children: [
          {
            id: '632121',
            text: '平安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4064,
            gisBd09Lng: 102.004,
            gisGcj02Lat: 36.4001,
            gisGcj02Lng: 101.998,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632122',
            text: '民和回族土族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3266,
            gisBd09Lng: 102.836,
            gisGcj02Lat: 36.3204,
            gisGcj02Lng: 102.83,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632123',
            text: '乐都县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5078,
            gisBd09Lng: 102.43,
            gisGcj02Lat: 36.5021,
            gisGcj02Lng: 102.424,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632126',
            text: '互助土族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8478,
            gisBd09Lng: 101.964,
            gisGcj02Lat: 36.842,
            gisGcj02Lng: 101.957,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632127',
            text: '化隆回族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1008,
            gisBd09Lng: 102.27,
            gisGcj02Lat: 36.0947,
            gisGcj02Lng: 102.264,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632128',
            text: '循化撒拉族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8554,
            gisBd09Lng: 102.491,
            gisGcj02Lat: 35.8491,
            gisGcj02Lng: 102.485,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 102.085,
        text: '海东地区',
        gisBd09Lat: 36.5176,
        gisGcj02Lat: 36.5118,
      },
      {
        pinYin: 'Haibei',
        id: '632200',
        gisGcj02Lng: 100.901,
        children: [
          {
            id: '632221',
            text: '门源回族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3815,
            gisBd09Lng: 101.624,
            gisGcj02Lat: 37.3754,
            gisGcj02Lng: 101.617,
            stubGroupCnt: null,
            pinYin: 'Menyuan',
          },
          {
            id: '632222',
            text: '祁连县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1824,
            gisBd09Lng: 100.263,
            gisGcj02Lat: 38.1767,
            gisGcj02Lng: 100.256,
            stubGroupCnt: null,
            pinYin: 'Qilian',
          },
          {
            id: '632223',
            text: '海晏县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9026,
            gisBd09Lng: 101.002,
            gisGcj02Lat: 36.8968,
            gisGcj02Lng: 100.995,
            stubGroupCnt: null,
            pinYin: 'Haiyan',
          },
          {
            id: '632224',
            text: '刚察县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3277,
            gisBd09Lng: 100.157,
            gisGcj02Lat: 37.3219,
            gisGcj02Lng: 100.15,
            stubGroupCnt: null,
            pinYin: 'Gangcha',
          },
        ],
        gisBd09Lng: 100.908,
        text: '海北藏族自治州',
        gisBd09Lat: 36.9607,
        gisGcj02Lat: 36.9545,
      },
      {
        pinYin: 'Huangnan',
        id: '632300',
        gisGcj02Lng: 102.015,
        children: [
          {
            id: '632321',
            text: '同仁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5234,
            gisBd09Lng: 102.025,
            gisGcj02Lat: 35.5172,
            gisGcj02Lng: 102.019,
            stubGroupCnt: null,
            pinYin: 'Tongren',
          },
          {
            id: '632322',
            text: '尖扎县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9443,
            gisBd09Lng: 102.038,
            gisGcj02Lat: 35.9384,
            gisGcj02Lng: 102.032,
            stubGroupCnt: null,
            pinYin: 'Jianzha',
          },
          {
            id: '632323',
            text: '泽库县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0421,
            gisBd09Lng: 101.474,
            gisGcj02Lat: 35.0363,
            gisGcj02Lng: 101.468,
            stubGroupCnt: null,
            pinYin: 'Zeku',
          },
          {
            id: '632324',
            text: '河南蒙古族自治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7404,
            gisBd09Lng: 101.622,
            gisGcj02Lat: 34.7344,
            gisGcj02Lng: 101.615,
            stubGroupCnt: null,
            pinYin: 'Henan',
          },
        ],
        gisBd09Lng: 102.022,
        text: '黄南藏族自治州',
        gisBd09Lat: 35.526,
        gisGcj02Lat: 35.5197,
      },
      {
        pinYin: 'Hainan',
        id: '632500',
        gisGcj02Lng: 100.62,
        children: [
          {
            id: '632521',
            text: '共和县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2886,
            gisBd09Lng: 100.627,
            gisGcj02Lat: 36.2829,
            gisGcj02Lng: 100.62,
            stubGroupCnt: null,
            pinYin: 'Gonghe',
          },
          {
            id: '632522',
            text: '同德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2611,
            gisBd09Lng: 100.587,
            gisGcj02Lat: 35.2549,
            gisGcj02Lng: 100.581,
            stubGroupCnt: null,
            pinYin: 'Tongde',
          },
          {
            id: '632523',
            text: '贵德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.046,
            gisBd09Lng: 101.442,
            gisGcj02Lat: 36.0401,
            gisGcj02Lng: 101.435,
            stubGroupCnt: null,
            pinYin: 'Guide',
          },
          {
            id: '632524',
            text: '兴海县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5951,
            gisBd09Lng: 99.9977,
            gisGcj02Lat: 35.5891,
            gisGcj02Lng: 99.9912,
            stubGroupCnt: null,
            pinYin: 'Xinghai',
          },
          {
            id: '632525',
            text: '贵南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5925,
            gisBd09Lng: 100.755,
            gisGcj02Lat: 35.5868,
            gisGcj02Lng: 100.748,
            stubGroupCnt: null,
            pinYin: 'Guinan',
          },
        ],
        gisBd09Lng: 100.627,
        text: '海南藏族自治州',
        gisBd09Lat: 36.292,
        gisGcj02Lat: 36.2863,
      },
      {
        pinYin: 'Golog',
        id: '632600',
        gisGcj02Lng: 100.246,
        children: [
          {
            id: '632621',
            text: '玛沁县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4819,
            gisBd09Lng: 100.247,
            gisGcj02Lat: 34.476,
            gisGcj02Lng: 100.24,
            stubGroupCnt: null,
            pinYin: 'Maqin',
          },
          {
            id: '632622',
            text: '班玛县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9383,
            gisBd09Lng: 100.745,
            gisGcj02Lat: 32.9326,
            gisGcj02Lng: 100.738,
            stubGroupCnt: null,
            pinYin: 'Banma',
          },
          {
            id: '632623',
            text: '甘德县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.9755,
            gisBd09Lng: 99.9084,
            gisGcj02Lat: 33.9698,
            gisGcj02Lng: 99.9018,
            stubGroupCnt: null,
            pinYin: 'Gande',
          },
          {
            id: '632624',
            text: '达日县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7548,
            gisBd09Lng: 99.6589,
            gisGcj02Lat: 33.7491,
            gisGcj02Lng: 99.6523,
            stubGroupCnt: null,
            pinYin: 'Dari',
          },
          {
            id: '632625',
            text: '久治县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4352,
            gisBd09Lng: 101.49,
            gisGcj02Lat: 33.4293,
            gisGcj02Lng: 101.483,
            stubGroupCnt: null,
            pinYin: 'Jiuzhi',
          },
          {
            id: '632626',
            text: '玛多县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9208,
            gisBd09Lng: 98.2181,
            gisGcj02Lat: 34.9151,
            gisGcj02Lng: 98.2115,
            stubGroupCnt: null,
            pinYin: 'Maduo',
          },
        ],
        gisBd09Lng: 100.252,
        text: '果洛藏族自治州',
        gisBd09Lat: 34.4773,
        gisGcj02Lat: 34.4715,
      },
      {
        pinYin: 'Yushu',
        id: '632700',
        gisGcj02Lng: 97.0066,
        children: [
          {
            id: '632721',
            text: '玉树县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9228,
            gisBd09Lng: 96.6723,
            gisGcj02Lat: 32.9171,
            gisGcj02Lng: 96.6657,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '632722',
            text: '杂多县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8991,
            gisBd09Lng: 95.3077,
            gisGcj02Lat: 32.893,
            gisGcj02Lng: 95.3013,
            stubGroupCnt: null,
            pinYin: 'Zaduo',
          },
          {
            id: '632723',
            text: '称多县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3743,
            gisBd09Lng: 97.1153,
            gisGcj02Lat: 33.3682,
            gisGcj02Lng: 97.1088,
            stubGroupCnt: null,
            pinYin: 'Chenduo',
          },
          {
            id: '632724',
            text: '治多县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8581,
            gisBd09Lng: 95.6196,
            gisGcj02Lat: 33.8521,
            gisGcj02Lng: 95.6131,
            stubGroupCnt: null,
            pinYin: 'Zhiduo',
          },
          {
            id: '632725',
            text: '囊谦县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2093,
            gisBd09Lng: 96.4873,
            gisGcj02Lat: 32.203,
            gisGcj02Lng: 96.4809,
            stubGroupCnt: null,
            pinYin: 'Nangqian',
          },
          {
            id: '632726',
            text: '曲麻莱县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1323,
            gisBd09Lng: 95.8039,
            gisGcj02Lat: 34.1264,
            gisGcj02Lng: 95.7973,
            stubGroupCnt: null,
            pinYin: 'Qumalai',
          },
        ],
        gisBd09Lng: 97.0131,
        text: '玉树藏族自治州',
        gisBd09Lat: 33.0111,
        gisGcj02Lat: 33.0053,
      },
      {
        pinYin: 'Haixi',
        id: '632800',
        gisGcj02Lng: 97.3712,
        children: [
          {
            id: '632801',
            text: '格尔木',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4097,
            gisBd09Lng: 94.911,
            gisGcj02Lat: 36.4035,
            gisGcj02Lng: 94.9045,
            stubGroupCnt: null,
            pinYin: 'Geermu',
          },
          {
            id: '632802',
            text: '德令哈',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3753,
            gisBd09Lng: 97.3687,
            gisGcj02Lat: 37.3694,
            gisGcj02Lng: 97.3621,
            stubGroupCnt: null,
            pinYin: 'Delingha',
          },
          {
            id: '632821',
            text: '乌兰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9357,
            gisBd09Lng: 98.487,
            gisGcj02Lat: 36.9298,
            gisGcj02Lng: 98.4805,
            stubGroupCnt: null,
            pinYin: 'Wulan',
          },
          {
            id: '632822',
            text: '都兰县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3082,
            gisBd09Lng: 98.1036,
            gisGcj02Lat: 36.3025,
            gisGcj02Lng: 98.097,
            stubGroupCnt: null,
            pinYin: 'Dulan',
          },
          {
            id: '632823',
            text: '天峻县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.306,
            gisBd09Lng: 99.0296,
            gisGcj02Lat: 37.2999,
            gisGcj02Lng: 99.0231,
            stubGroupCnt: null,
            pinYin: 'Tianjun',
          },
        ],
        gisBd09Lng: 97.3778,
        text: '海西蒙古族藏族自治州',
        gisBd09Lat: 37.3828,
        gisGcj02Lat: 37.3771,
      },
    ],
  },
  {
    text: '山东省',
    children: [
      {
        pinYin: 'Jinan',
        id: '370100',
        gisGcj02Lng: 116.995,
        children: [
          {
            id: '370101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6715,
            gisBd09Lng: 117.001,
            gisGcj02Lat: 36.6651,
            gisGcj02Lng: 116.995,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370102',
            text: '历下区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6721,
            gisBd09Lng: 117.083,
            gisGcj02Lat: 36.6664,
            gisGcj02Lng: 117.076,
            stubGroupCnt: null,
            pinYin: 'Lixia',
          },
          {
            id: '370103',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6571,
            gisBd09Lng: 117.004,
            gisGcj02Lat: 36.6507,
            gisGcj02Lng: 116.998,
            stubGroupCnt: null,
            pinYin: 'Shizhongqu',
          },
          {
            id: '370104',
            text: '槐荫区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.657,
            gisBd09Lng: 116.908,
            gisGcj02Lat: 36.6508,
            gisGcj02Lng: 116.901,
            stubGroupCnt: null,
            pinYin: 'Huaiyin',
          },
          {
            id: '370105',
            text: '天桥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6841,
            gisBd09Lng: 116.995,
            gisGcj02Lat: 36.6778,
            gisGcj02Lng: 116.988,
            stubGroupCnt: null,
            pinYin: 'Tianqiao',
          },
          {
            id: '370112',
            text: '历城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6842,
            gisBd09Lng: 117.074,
            gisGcj02Lat: 36.6785,
            gisGcj02Lng: 117.067,
            stubGroupCnt: null,
            pinYin: 'Licheng',
          },
          {
            id: '370113',
            text: '长清区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5593,
            gisBd09Lng: 116.759,
            gisGcj02Lat: 36.553,
            gisGcj02Lng: 116.752,
            stubGroupCnt: null,
            pinYin: 'Changqing',
          },
          {
            id: '370124',
            text: '平阴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2941,
            gisBd09Lng: 116.462,
            gisGcj02Lat: 36.2884,
            gisGcj02Lng: 116.455,
            stubGroupCnt: null,
            pinYin: 'Pingyin',
          },
          {
            id: '370125',
            text: '济阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9835,
            gisBd09Lng: 117.182,
            gisGcj02Lat: 36.9778,
            gisGcj02Lng: 117.175,
            stubGroupCnt: null,
            pinYin: 'Jiyang',
          },
          {
            id: '370126',
            text: '商河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3126,
            gisBd09Lng: 117.164,
            gisGcj02Lat: 37.3068,
            gisGcj02Lng: 117.158,
            stubGroupCnt: null,
            pinYin: 'Shanghe',
          },
          {
            id: '370181',
            text: '章丘',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.685,
            gisBd09Lng: 117.533,
            gisGcj02Lat: 36.6792,
            gisGcj02Lng: 117.526,
            stubGroupCnt: null,
            pinYin: 'Zhangqiu',
          },
        ],
        gisBd09Lng: 117.001,
        text: '济南',
        gisBd09Lat: 36.6715,
        gisGcj02Lat: 36.6651,
      },
      {
        pinYin: 'Qingdao',
        id: '370200',
        gisGcj02Lng: 120.383,
        children: [
          {
            id: '370201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0729,
            gisBd09Lng: 120.389,
            gisGcj02Lat: 36.0668,
            gisGcj02Lng: 120.383,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370202',
            text: '市南区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0805,
            gisBd09Lng: 120.419,
            gisGcj02Lat: 36.0749,
            gisGcj02Lng: 120.413,
            stubGroupCnt: null,
            pinYin: 'Shinan',
          },
          {
            id: '370203',
            text: '市北区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0922,
            gisBd09Lng: 120.383,
            gisGcj02Lat: 36.0859,
            gisGcj02Lng: 120.376,
            stubGroupCnt: null,
            pinYin: 'Shibei',
          },
          {
            id: '370205',
            text: '四方区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1311,
            gisBd09Lng: 120.377,
            gisGcj02Lat: 36.1247,
            gisGcj02Lng: 120.371,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370211',
            text: '黄岛区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9658,
            gisBd09Lng: 120.205,
            gisGcj02Lat: 35.96,
            gisGcj02Lng: 120.199,
            stubGroupCnt: null,
            pinYin: 'Huangdao',
          },
          {
            id: '370212',
            text: '崂山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1137,
            gisBd09Lng: 120.475,
            gisGcj02Lat: 36.1074,
            gisGcj02Lng: 120.469,
            stubGroupCnt: null,
            pinYin: 'Laoshan',
          },
          {
            id: '370213',
            text: '李沧区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.15,
            gisBd09Lng: 120.439,
            gisGcj02Lat: 36.1443,
            gisGcj02Lng: 120.433,
            stubGroupCnt: null,
            pinYin: 'Licang',
          },
          {
            id: '370214',
            text: '城阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3116,
            gisBd09Lng: 120.404,
            gisGcj02Lat: 36.3057,
            gisGcj02Lng: 120.397,
            stubGroupCnt: null,
            pinYin: 'Chengyang',
          },
          {
            id: '370281',
            text: '胶州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2692,
            gisBd09Lng: 120.042,
            gisGcj02Lat: 36.2633,
            gisGcj02Lng: 120.035,
            stubGroupCnt: null,
            pinYin: 'Jiaozhou',
          },
          {
            id: '370282',
            text: '即墨',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3952,
            gisBd09Lng: 120.453,
            gisGcj02Lat: 36.3893,
            gisGcj02Lng: 120.447,
            stubGroupCnt: null,
            pinYin: 'Jimo',
          },
          {
            id: '370283',
            text: '平度',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.792,
            gisBd09Lng: 119.967,
            gisGcj02Lat: 36.7862,
            gisGcj02Lng: 119.96,
            stubGroupCnt: null,
            pinYin: 'Pingdu',
          },
          {
            id: '370284',
            text: '胶南',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8526,
            gisBd09Lng: 119.857,
            gisGcj02Lat: 35.8466,
            gisGcj02Lng: 119.851,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370285',
            text: '莱西',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8935,
            gisBd09Lng: 120.525,
            gisGcj02Lat: 36.8876,
            gisGcj02Lng: 120.519,
            stubGroupCnt: null,
            pinYin: 'Laixi',
          },
        ],
        gisBd09Lng: 120.389,
        text: '青岛',
        gisBd09Lat: 36.0729,
        gisGcj02Lat: 36.0668,
      },
      {
        pinYin: 'Zibo',
        id: '370300',
        gisGcj02Lng: 118.054,
        children: [
          {
            id: '370301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8185,
            gisBd09Lng: 118.061,
            gisGcj02Lat: 36.8124,
            gisGcj02Lng: 118.054,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370302',
            text: '淄川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6495,
            gisBd09Lng: 117.974,
            gisGcj02Lat: 36.6432,
            gisGcj02Lng: 117.967,
            stubGroupCnt: null,
            pinYin: 'Zichuan',
          },
          {
            id: '370303',
            text: '张店区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8114,
            gisBd09Lng: 118.024,
            gisGcj02Lat: 36.8058,
            gisGcj02Lng: 118.017,
            stubGroupCnt: null,
            pinYin: 'Zhangdian',
          },
          {
            id: '370304',
            text: '博山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5001,
            gisBd09Lng: 117.867,
            gisGcj02Lat: 36.4939,
            gisGcj02Lng: 117.861,
            stubGroupCnt: null,
            pinYin: 'Boshan',
          },
          {
            id: '370305',
            text: '临淄区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8319,
            gisBd09Lng: 118.316,
            gisGcj02Lat: 36.8256,
            gisGcj02Lng: 118.309,
            stubGroupCnt: null,
            pinYin: 'Linzi',
          },
          {
            id: '370306',
            text: '周村区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8096,
            gisBd09Lng: 117.878,
            gisGcj02Lat: 36.8036,
            gisGcj02Lng: 117.872,
            stubGroupCnt: null,
            pinYin: 'Zhoucun',
          },
          {
            id: '370321',
            text: '桓台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9646,
            gisBd09Lng: 118.104,
            gisGcj02Lat: 36.9583,
            gisGcj02Lng: 118.098,
            stubGroupCnt: null,
            pinYin: 'Huantai',
          },
          {
            id: '370322',
            text: '高青县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1769,
            gisBd09Lng: 117.833,
            gisGcj02Lat: 37.1706,
            gisGcj02Lng: 117.827,
            stubGroupCnt: null,
            pinYin: 'Gaoqing',
          },
          {
            id: '370323',
            text: '沂源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1903,
            gisBd09Lng: 118.177,
            gisGcj02Lat: 36.1842,
            gisGcj02Lng: 118.171,
            stubGroupCnt: null,
            pinYin: 'Yiyuan',
          },
        ],
        gisBd09Lng: 118.061,
        text: '淄博',
        gisBd09Lat: 36.8185,
        gisGcj02Lat: 36.8124,
      },
      {
        pinYin: 'Zaozhuang',
        id: '370400',
        gisGcj02Lng: 117.323,
        children: [
          {
            id: '370401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.816,
            gisBd09Lng: 117.33,
            gisGcj02Lat: 34.8101,
            gisGcj02Lng: 117.323,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370402',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8689,
            gisBd09Lng: 117.562,
            gisGcj02Lat: 34.8631,
            gisGcj02Lng: 117.556,
            stubGroupCnt: null,
            pinYin: 'Shizhongqu',
          },
          {
            id: '370403',
            text: '薛城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8008,
            gisBd09Lng: 117.269,
            gisGcj02Lat: 34.7947,
            gisGcj02Lng: 117.263,
            stubGroupCnt: null,
            pinYin: 'Xuecheng',
          },
          {
            id: '370404',
            text: '峄城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7779,
            gisBd09Lng: 117.597,
            gisGcj02Lat: 34.7716,
            gisGcj02Lng: 117.59,
            stubGroupCnt: null,
            pinYin: 'Yicheng',
          },
          {
            id: '370405',
            text: '台儿庄区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5682,
            gisBd09Lng: 117.74,
            gisGcj02Lat: 34.562,
            gisGcj02Lng: 117.734,
            stubGroupCnt: null,
            pinYin: 'Taierzhuang',
          },
          {
            id: '370406',
            text: '山亭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1055,
            gisBd09Lng: 117.468,
            gisGcj02Lat: 35.0993,
            gisGcj02Lng: 117.461,
            stubGroupCnt: null,
            pinYin: 'Shanting',
          },
          {
            id: '370481',
            text: '滕州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1182,
            gisBd09Lng: 117.173,
            gisGcj02Lat: 35.1125,
            gisGcj02Lng: 117.166,
            stubGroupCnt: null,
            pinYin: 'Tengzhou',
          },
        ],
        gisBd09Lng: 117.33,
        text: '枣庄',
        gisBd09Lat: 34.816,
        gisGcj02Lat: 34.8101,
      },
      {
        pinYin: 'Dongying',
        id: '370500',
        gisGcj02Lng: 118.675,
        children: [
          {
            id: '370501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4387,
            gisBd09Lng: 118.682,
            gisGcj02Lat: 37.4324,
            gisGcj02Lng: 118.675,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370502',
            text: '东营区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4541,
            gisBd09Lng: 118.591,
            gisGcj02Lat: 37.448,
            gisGcj02Lng: 118.584,
            stubGroupCnt: null,
            pinYin: 'Dongying',
          },
          {
            id: '370503',
            text: '河口区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8923,
            gisBd09Lng: 118.534,
            gisGcj02Lat: 37.8863,
            gisGcj02Lng: 118.527,
            stubGroupCnt: null,
            pinYin: 'Hekou',
          },
          {
            id: '370521',
            text: '垦利县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5935,
            gisBd09Lng: 118.555,
            gisGcj02Lat: 37.5872,
            gisGcj02Lng: 118.548,
            stubGroupCnt: null,
            pinYin: 'Kenli',
          },
          {
            id: '370522',
            text: '利津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4943,
            gisBd09Lng: 118.262,
            gisGcj02Lat: 37.4887,
            gisGcj02Lng: 118.256,
            stubGroupCnt: null,
            pinYin: 'Lijin',
          },
          {
            id: '370523',
            text: '广饶县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0591,
            gisBd09Lng: 118.415,
            gisGcj02Lat: 37.0531,
            gisGcj02Lng: 118.409,
            stubGroupCnt: null,
            pinYin: 'Guangrao',
          },
        ],
        gisBd09Lng: 118.682,
        text: '东营',
        gisBd09Lat: 37.4387,
        gisGcj02Lat: 37.4324,
      },
      {
        pinYin: 'Yantai',
        id: '370600',
        gisGcj02Lng: 121.448,
        children: [
          {
            id: '370601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4698,
            gisBd09Lng: 121.454,
            gisGcj02Lat: 37.4634,
            gisGcj02Lng: 121.448,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370602',
            text: '芝罘区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5464,
            gisBd09Lng: 121.406,
            gisGcj02Lat: 37.5406,
            gisGcj02Lng: 121.4,
            stubGroupCnt: null,
            pinYin: 'Zhifu',
          },
          {
            id: '370611',
            text: '福山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5032,
            gisBd09Lng: 121.275,
            gisGcj02Lat: 37.4975,
            gisGcj02Lng: 121.268,
            stubGroupCnt: null,
            pinYin: 'Fushan',
          },
          {
            id: '370612',
            text: '牟平区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.392,
            gisBd09Lng: 121.607,
            gisGcj02Lat: 37.3861,
            gisGcj02Lng: 121.601,
            stubGroupCnt: null,
            pinYin: 'Muping',
          },
          {
            id: '370613',
            text: '莱山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5146,
            gisBd09Lng: 121.452,
            gisGcj02Lat: 37.5083,
            gisGcj02Lng: 121.446,
            stubGroupCnt: null,
            pinYin: 'Laishan',
          },
          {
            id: '370634',
            text: '长岛县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9272,
            gisBd09Lng: 120.743,
            gisGcj02Lat: 37.921,
            gisGcj02Lng: 120.737,
            stubGroupCnt: null,
            pinYin: 'Changdao',
          },
          {
            id: '370681',
            text: '龙口',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6491,
            gisBd09Lng: 120.484,
            gisGcj02Lat: 37.6428,
            gisGcj02Lng: 120.478,
            stubGroupCnt: null,
            pinYin: 'Longkou',
          },
          {
            id: '370682',
            text: '莱阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9851,
            gisBd09Lng: 120.717,
            gisGcj02Lat: 36.9788,
            gisGcj02Lng: 120.711,
            stubGroupCnt: null,
            pinYin: 'Laiyang',
          },
          {
            id: '370683',
            text: '莱州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1812,
            gisBd09Lng: 119.95,
            gisGcj02Lat: 37.1755,
            gisGcj02Lng: 119.944,
            stubGroupCnt: null,
            pinYin: 'Laizhou',
          },
          {
            id: '370684',
            text: '蓬莱',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8151,
            gisBd09Lng: 120.766,
            gisGcj02Lat: 37.8093,
            gisGcj02Lng: 120.76,
            stubGroupCnt: null,
            pinYin: 'Penglai',
          },
          {
            id: '370685',
            text: '招远',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.36,
            gisBd09Lng: 120.439,
            gisGcj02Lat: 37.3543,
            gisGcj02Lng: 120.432,
            stubGroupCnt: null,
            pinYin: 'Zhaoyuan',
          },
          {
            id: '370686',
            text: '栖霞',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3409,
            gisBd09Lng: 120.857,
            gisGcj02Lat: 37.3346,
            gisGcj02Lng: 120.851,
            stubGroupCnt: null,
            pinYin: 'Qixia',
          },
          {
            id: '370687',
            text: '海阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.782,
            gisBd09Lng: 121.165,
            gisGcj02Lat: 36.7762,
            gisGcj02Lng: 121.159,
            stubGroupCnt: null,
            pinYin: 'Haiyang',
          },
        ],
        gisBd09Lng: 121.454,
        text: '烟台',
        gisBd09Lat: 37.4698,
        gisGcj02Lat: 37.4634,
      },
      {
        pinYin: 'Weifang',
        id: '370700',
        gisGcj02Lng: 119.161,
        children: [
          {
            id: '370701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7121,
            gisBd09Lng: 119.167,
            gisGcj02Lat: 36.7057,
            gisGcj02Lng: 119.161,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370702',
            text: '潍城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7334,
            gisBd09Lng: 119.032,
            gisGcj02Lat: 36.7272,
            gisGcj02Lng: 119.025,
            stubGroupCnt: null,
            pinYin: 'Weicheng',
          },
          {
            id: '370703',
            text: '寒亭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7802,
            gisBd09Lng: 119.227,
            gisGcj02Lat: 36.7746,
            gisGcj02Lng: 119.221,
            stubGroupCnt: null,
            pinYin: 'Hanting',
          },
          {
            id: '370704',
            text: '坊子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6604,
            gisBd09Lng: 119.173,
            gisGcj02Lat: 36.6541,
            gisGcj02Lng: 119.167,
            stubGroupCnt: null,
            pinYin: 'Fangzi',
          },
          {
            id: '370705',
            text: '奎文区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.714,
            gisBd09Lng: 119.14,
            gisGcj02Lat: 36.7079,
            gisGcj02Lng: 119.133,
            stubGroupCnt: null,
            pinYin: 'Kuiwen',
          },
          {
            id: '370724',
            text: '临朐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5182,
            gisBd09Lng: 118.549,
            gisGcj02Lat: 36.512,
            gisGcj02Lng: 118.543,
            stubGroupCnt: null,
            pinYin: 'Linqu',
          },
          {
            id: '370725',
            text: '昌乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7129,
            gisBd09Lng: 118.838,
            gisGcj02Lat: 36.7069,
            gisGcj02Lng: 118.832,
            stubGroupCnt: null,
            pinYin: 'Changle',
          },
          {
            id: '370781',
            text: '青州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6904,
            gisBd09Lng: 118.484,
            gisGcj02Lat: 36.6845,
            gisGcj02Lng: 118.478,
            stubGroupCnt: null,
            pinYin: 'Qingzhou',
          },
          {
            id: '370782',
            text: '诸城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0011,
            gisBd09Lng: 119.416,
            gisGcj02Lat: 35.9948,
            gisGcj02Lng: 119.41,
            stubGroupCnt: null,
            pinYin: 'Zhucheng',
          },
          {
            id: '370783',
            text: '寿光',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8608,
            gisBd09Lng: 118.797,
            gisGcj02Lat: 36.8545,
            gisGcj02Lng: 118.791,
            stubGroupCnt: null,
            pinYin: 'Shouguang',
          },
          {
            id: '370784',
            text: '安丘',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4823,
            gisBd09Lng: 119.225,
            gisGcj02Lat: 36.4767,
            gisGcj02Lng: 119.219,
            stubGroupCnt: null,
            pinYin: 'Anqiu',
          },
          {
            id: '370785',
            text: '高密',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3885,
            gisBd09Lng: 119.762,
            gisGcj02Lat: 36.3822,
            gisGcj02Lng: 119.756,
            stubGroupCnt: null,
            pinYin: 'Gaomi',
          },
          {
            id: '370786',
            text: '昌邑',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8636,
            gisBd09Lng: 119.406,
            gisGcj02Lat: 36.8572,
            gisGcj02Lng: 119.399,
            stubGroupCnt: null,
            pinYin: 'Changyi',
          },
        ],
        gisBd09Lng: 119.167,
        text: '潍坊',
        gisBd09Lat: 36.7121,
        gisGcj02Lat: 36.7057,
      },
      {
        pinYin: 'Jining',
        id: '370800',
        gisGcj02Lng: 116.587,
        children: [
          {
            id: '370801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4202,
            gisBd09Lng: 116.594,
            gisGcj02Lat: 35.4145,
            gisGcj02Lng: 116.587,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370802',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4106,
            gisBd09Lng: 116.599,
            gisGcj02Lat: 35.4049,
            gisGcj02Lng: 116.592,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370811',
            text: '任城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.413,
            gisBd09Lng: 116.603,
            gisGcj02Lat: 35.4073,
            gisGcj02Lng: 116.596,
            stubGroupCnt: null,
            pinYin: 'Rencheng',
          },
          {
            id: '370826',
            text: '微山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8127,
            gisBd09Lng: 117.135,
            gisGcj02Lat: 34.8064,
            gisGcj02Lng: 117.128,
            stubGroupCnt: null,
            pinYin: 'Weishan',
          },
          {
            id: '370827',
            text: '鱼台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0179,
            gisBd09Lng: 116.656,
            gisGcj02Lat: 35.0116,
            gisGcj02Lng: 116.65,
            stubGroupCnt: null,
            pinYin: 'Yutai',
          },
          {
            id: '370828',
            text: '金乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0725,
            gisBd09Lng: 116.319,
            gisGcj02Lat: 35.0665,
            gisGcj02Lng: 116.312,
            stubGroupCnt: null,
            pinYin: 'Jinxiang',
          },
          {
            id: '370829',
            text: '嘉祥县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4133,
            gisBd09Lng: 116.349,
            gisGcj02Lat: 35.4077,
            gisGcj02Lng: 116.342,
            stubGroupCnt: null,
            pinYin: 'Jiaxiang',
          },
          {
            id: '370830',
            text: '汶上县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7391,
            gisBd09Lng: 116.497,
            gisGcj02Lat: 35.7331,
            gisGcj02Lng: 116.49,
            stubGroupCnt: null,
            pinYin: 'Wenshang',
          },
          {
            id: '370831',
            text: '泗水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6708,
            gisBd09Lng: 117.259,
            gisGcj02Lat: 35.6645,
            gisGcj02Lng: 117.253,
            stubGroupCnt: null,
            pinYin: 'Sishui',
          },
          {
            id: '370832',
            text: '梁山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.807,
            gisBd09Lng: 116.103,
            gisGcj02Lat: 35.8013,
            gisGcj02Lng: 116.096,
            stubGroupCnt: null,
            pinYin: 'Liangshan',
          },
          {
            id: '370881',
            text: '曲阜',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5863,
            gisBd09Lng: 116.993,
            gisGcj02Lat: 35.5801,
            gisGcj02Lng: 116.986,
            stubGroupCnt: null,
            pinYin: 'Qufu',
          },
          {
            id: '370882',
            text: '兖州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5737,
            gisBd09Lng: 116.754,
            gisGcj02Lat: 35.5674,
            gisGcj02Lng: 116.747,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370883',
            text: '邹城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4115,
            gisBd09Lng: 117.01,
            gisGcj02Lat: 35.4052,
            gisGcj02Lng: 117.004,
            stubGroupCnt: null,
            pinYin: 'Zoucheng',
          },
        ],
        gisBd09Lng: 116.594,
        text: '济宁',
        gisBd09Lat: 35.4202,
        gisGcj02Lat: 35.4145,
      },
      {
        pinYin: "Tai'an",
        id: '370900',
        gisGcj02Lng: 117.089,
        children: [
          {
            id: '370901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2053,
            gisBd09Lng: 117.095,
            gisGcj02Lat: 36.1993,
            gisGcj02Lng: 117.089,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370902',
            text: '泰山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1981,
            gisBd09Lng: 117.142,
            gisGcj02Lat: 36.1919,
            gisGcj02Lng: 117.136,
            stubGroupCnt: null,
            pinYin: 'Taishan',
          },
          {
            id: '370903',
            text: '岱岳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1933,
            gisBd09Lng: 117.048,
            gisGcj02Lat: 36.1875,
            gisGcj02Lng: 117.042,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '370921',
            text: '宁阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7649,
            gisBd09Lng: 116.814,
            gisGcj02Lat: 35.7592,
            gisGcj02Lng: 116.807,
            stubGroupCnt: null,
            pinYin: 'Ningyang',
          },
          {
            id: '370923',
            text: '东平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9421,
            gisBd09Lng: 116.478,
            gisGcj02Lat: 35.9364,
            gisGcj02Lng: 116.471,
            stubGroupCnt: null,
            pinYin: 'Dongping',
          },
          {
            id: '370982',
            text: '新泰',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9136,
            gisBd09Lng: 117.775,
            gisGcj02Lat: 35.9079,
            gisGcj02Lng: 117.768,
            stubGroupCnt: null,
            pinYin: 'Xintai',
          },
          {
            id: '370983',
            text: '肥城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1883,
            gisBd09Lng: 116.775,
            gisGcj02Lat: 36.1819,
            gisGcj02Lng: 116.769,
            stubGroupCnt: null,
            pinYin: 'Feicheng',
          },
        ],
        gisBd09Lng: 117.095,
        text: '泰安',
        gisBd09Lat: 36.2053,
        gisGcj02Lat: 36.1993,
      },
      {
        pinYin: 'Weihai',
        id: '371000',
        gisGcj02Lng: 122.123,
        children: [
          {
            id: '371001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5177,
            gisBd09Lng: 122.129,
            gisGcj02Lat: 37.5119,
            gisGcj02Lng: 122.123,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371002',
            text: '环翠区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5079,
            gisBd09Lng: 122.13,
            gisGcj02Lat: 37.502,
            gisGcj02Lng: 122.123,
            stubGroupCnt: null,
            pinYin: 'Huancui',
          },
          {
            id: '371081',
            text: '文登',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1538,
            gisBd09Lng: 122.01,
            gisGcj02Lat: 37.1479,
            gisGcj02Lng: 122.004,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371082',
            text: '荣成',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1708,
            gisBd09Lng: 122.493,
            gisGcj02Lat: 37.1649,
            gisGcj02Lng: 122.486,
            stubGroupCnt: null,
            pinYin: 'Rongcheng',
          },
          {
            id: '371083',
            text: '乳山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9262,
            gisBd09Lng: 121.546,
            gisGcj02Lat: 36.92,
            gisGcj02Lng: 121.54,
            stubGroupCnt: null,
            pinYin: 'Rushan',
          },
        ],
        gisBd09Lng: 122.129,
        text: '威海',
        gisBd09Lat: 37.5177,
        gisGcj02Lat: 37.5119,
      },
      {
        pinYin: 'Rizhao',
        id: '371100',
        gisGcj02Lng: 119.528,
        children: [
          {
            id: '371101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4224,
            gisBd09Lng: 119.534,
            gisGcj02Lat: 35.4161,
            gisGcj02Lng: 119.528,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371102',
            text: '东港区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4285,
            gisBd09Lng: 119.469,
            gisGcj02Lat: 35.4229,
            gisGcj02Lng: 119.463,
            stubGroupCnt: null,
            pinYin: 'Donggang',
          },
          {
            id: '371103',
            text: '岚山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1275,
            gisBd09Lng: 119.326,
            gisGcj02Lat: 35.1217,
            gisGcj02Lng: 119.319,
            stubGroupCnt: null,
            pinYin: 'Lanshan',
          },
          {
            id: '371121',
            text: '五莲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7554,
            gisBd09Lng: 119.215,
            gisGcj02Lat: 35.7497,
            gisGcj02Lng: 119.208,
            stubGroupCnt: null,
            pinYin: 'Wulian',
          },
          {
            id: '371122',
            text: '莒　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5847,
            gisBd09Lng: 118.843,
            gisGcj02Lat: 35.5789,
            gisGcj02Lng: 118.837,
            stubGroupCnt: null,
            pinYin: 'Juxian',
          },
        ],
        gisBd09Lng: 119.534,
        text: '日照',
        gisBd09Lat: 35.4224,
        gisGcj02Lat: 35.4161,
      },
      {
        pinYin: 'Laiwu',
        id: '371200',
        gisGcj02Lng: 117.677,
        children: [
          {
            id: '371201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2185,
            gisBd09Lng: 117.683,
            gisGcj02Lat: 36.2128,
            gisGcj02Lng: 117.677,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371202',
            text: '莱城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2081,
            gisBd09Lng: 117.668,
            gisGcj02Lat: 36.2025,
            gisGcj02Lng: 117.661,
            stubGroupCnt: null,
            pinYin: 'Laicheng',
          },
          {
            id: '371203',
            text: '钢城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0644,
            gisBd09Lng: 117.819,
            gisGcj02Lat: 36.0583,
            gisGcj02Lng: 117.812,
            stubGroupCnt: null,
            pinYin: 'Gangcheng',
          },
        ],
        gisBd09Lng: 117.683,
        text: '莱芜',
        gisBd09Lat: 36.2185,
        gisGcj02Lat: 36.2128,
      },
      {
        pinYin: 'Linyi',
        id: '371300',
        gisGcj02Lng: 118.359,
        children: [
          {
            id: '371301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1097,
            gisBd09Lng: 118.365,
            gisGcj02Lat: 35.1039,
            gisGcj02Lng: 118.359,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371302',
            text: '兰山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0571,
            gisBd09Lng: 118.353,
            gisGcj02Lat: 35.051,
            gisGcj02Lng: 118.346,
            stubGroupCnt: null,
            pinYin: 'Lanshan',
          },
          {
            id: '371311',
            text: '罗庄区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0006,
            gisBd09Lng: 118.291,
            gisGcj02Lat: 34.9947,
            gisGcj02Lng: 118.284,
            stubGroupCnt: null,
            pinYin: 'Luozhuang',
          },
          {
            id: '371312',
            text: '河东区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0908,
            gisBd09Lng: 118.408,
            gisGcj02Lat: 35.0849,
            gisGcj02Lng: 118.402,
            stubGroupCnt: null,
            pinYin: 'Hedong',
          },
          {
            id: '371321',
            text: '沂南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5549,
            gisBd09Lng: 118.473,
            gisGcj02Lat: 35.5488,
            gisGcj02Lng: 118.466,
            stubGroupCnt: null,
            pinYin: 'Yinan',
          },
          {
            id: '371322',
            text: '郯城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6166,
            gisBd09Lng: 118.374,
            gisGcj02Lat: 34.6108,
            gisGcj02Lng: 118.368,
            stubGroupCnt: null,
            pinYin: 'Tancheng',
          },
          {
            id: '371323',
            text: '沂水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7952,
            gisBd09Lng: 118.637,
            gisGcj02Lat: 35.7895,
            gisGcj02Lng: 118.63,
            stubGroupCnt: null,
            pinYin: 'Yishui',
          },
          {
            id: '371324',
            text: '苍山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8648,
            gisBd09Lng: 117.999,
            gisGcj02Lat: 34.8588,
            gisGcj02Lng: 117.992,
            stubGroupCnt: null,
            pinYin: 'Lanling',
          },
          {
            id: '371325',
            text: '费　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2702,
            gisBd09Lng: 117.984,
            gisGcj02Lat: 35.264,
            gisGcj02Lng: 117.977,
            stubGroupCnt: null,
            pinYin: 'Feixian',
          },
          {
            id: '371326',
            text: '平邑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5113,
            gisBd09Lng: 117.648,
            gisGcj02Lat: 35.5055,
            gisGcj02Lng: 117.642,
            stubGroupCnt: null,
            pinYin: 'Pingyi',
          },
          {
            id: '371327',
            text: '莒南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1805,
            gisBd09Lng: 118.843,
            gisGcj02Lat: 35.1746,
            gisGcj02Lng: 118.836,
            stubGroupCnt: null,
            pinYin: 'Junan',
          },
          {
            id: '371328',
            text: '蒙阴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7161,
            gisBd09Lng: 117.951,
            gisGcj02Lat: 35.7098,
            gisGcj02Lng: 117.945,
            stubGroupCnt: null,
            pinYin: 'Mengyin',
          },
          {
            id: '371329',
            text: '临沭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9243,
            gisBd09Lng: 118.659,
            gisGcj02Lat: 34.9183,
            gisGcj02Lng: 118.652,
            stubGroupCnt: null,
            pinYin: 'Linshu',
          },
        ],
        gisBd09Lng: 118.365,
        text: '临沂',
        gisBd09Lat: 35.1097,
        gisGcj02Lat: 35.1039,
      },
      {
        pinYin: 'Dezhou',
        id: '371400',
        gisGcj02Lng: 116.36,
        children: [
          {
            id: '371401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4404,
            gisBd09Lng: 116.366,
            gisGcj02Lat: 37.4346,
            gisGcj02Lng: 116.359,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371402',
            text: '德城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4561,
            gisBd09Lng: 116.306,
            gisGcj02Lat: 37.4499,
            gisGcj02Lng: 116.299,
            stubGroupCnt: null,
            pinYin: 'Decheng',
          },
          {
            id: '371421',
            text: '陵　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4171,
            gisBd09Lng: 116.666,
            gisGcj02Lat: 37.4109,
            gisGcj02Lng: 116.66,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371422',
            text: '宁津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.657,
            gisBd09Lng: 116.806,
            gisGcj02Lat: 37.6512,
            gisGcj02Lng: 116.799,
            stubGroupCnt: null,
            pinYin: 'Ningjin',
          },
          {
            id: '371423',
            text: '庆云县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7797,
            gisBd09Lng: 117.393,
            gisGcj02Lat: 37.7736,
            gisGcj02Lng: 117.386,
            stubGroupCnt: null,
            pinYin: 'Qingyun',
          },
          {
            id: '371424',
            text: '临邑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1937,
            gisBd09Lng: 116.874,
            gisGcj02Lat: 37.1874,
            gisGcj02Lng: 116.868,
            stubGroupCnt: null,
            pinYin: 'Linyi',
          },
          {
            id: '371425',
            text: '齐河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8007,
            gisBd09Lng: 116.767,
            gisGcj02Lat: 36.7944,
            gisGcj02Lng: 116.761,
            stubGroupCnt: null,
            pinYin: 'Qihe',
          },
          {
            id: '371426',
            text: '平原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1704,
            gisBd09Lng: 116.44,
            gisGcj02Lat: 37.1645,
            gisGcj02Lng: 116.434,
            stubGroupCnt: null,
            pinYin: 'Pingyuan',
          },
          {
            id: '371427',
            text: '夏津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9535,
            gisBd09Lng: 116.01,
            gisGcj02Lat: 36.9476,
            gisGcj02Lng: 116.004,
            stubGroupCnt: null,
            pinYin: 'Xiajin',
          },
          {
            id: '371428',
            text: '武城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.218,
            gisBd09Lng: 116.077,
            gisGcj02Lat: 37.212,
            gisGcj02Lng: 116.07,
            stubGroupCnt: null,
            pinYin: 'Wucheng',
          },
          {
            id: '371481',
            text: '乐陵',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7353,
            gisBd09Lng: 117.24,
            gisGcj02Lat: 37.729,
            gisGcj02Lng: 117.233,
            stubGroupCnt: null,
            pinYin: 'Leling',
          },
          {
            id: '371482',
            text: '禹城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9388,
            gisBd09Lng: 116.647,
            gisGcj02Lat: 36.9324,
            gisGcj02Lng: 116.641,
            stubGroupCnt: null,
            pinYin: 'Yucheng',
          },
        ],
        gisBd09Lng: 116.367,
        text: '德州',
        gisBd09Lat: 37.4402,
        gisGcj02Lat: 37.4343,
      },
      {
        pinYin: 'Liaocheng',
        id: '371500',
        gisGcj02Lng: 115.985,
        children: [
          {
            id: '371501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4614,
            gisBd09Lng: 115.992,
            gisGcj02Lat: 36.4557,
            gisGcj02Lng: 115.985,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371502',
            text: '东昌府区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4396,
            gisBd09Lng: 115.996,
            gisGcj02Lat: 36.4339,
            gisGcj02Lng: 115.989,
            stubGroupCnt: null,
            pinYin: 'Dongchangfu',
          },
          {
            id: '371521',
            text: '阳谷县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1197,
            gisBd09Lng: 115.8,
            gisGcj02Lat: 36.1134,
            gisGcj02Lng: 115.794,
            stubGroupCnt: null,
            pinYin: 'Yanggu',
          },
          {
            id: '371522',
            text: '莘　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2392,
            gisBd09Lng: 115.679,
            gisGcj02Lat: 36.2329,
            gisGcj02Lng: 115.672,
            stubGroupCnt: null,
            pinYin: 'Shenxian',
          },
          {
            id: '371523',
            text: '茌平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5863,
            gisBd09Lng: 116.262,
            gisGcj02Lat: 36.5802,
            gisGcj02Lng: 116.256,
            stubGroupCnt: null,
            pinYin: 'Chiping',
          },
          {
            id: '371524',
            text: '东阿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3409,
            gisBd09Lng: 116.257,
            gisGcj02Lat: 36.3349,
            gisGcj02Lng: 116.251,
            stubGroupCnt: null,
            pinYin: "Dong'e",
          },
          {
            id: '371525',
            text: '冠　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4898,
            gisBd09Lng: 115.45,
            gisGcj02Lat: 36.4835,
            gisGcj02Lng: 115.444,
            stubGroupCnt: null,
            pinYin: 'Guanxian',
          },
          {
            id: '371526',
            text: '高唐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.871,
            gisBd09Lng: 116.24,
            gisGcj02Lat: 36.8653,
            gisGcj02Lng: 116.233,
            stubGroupCnt: null,
            pinYin: 'Gaotang',
          },
          {
            id: '371581',
            text: '临清',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8434,
            gisBd09Lng: 115.712,
            gisGcj02Lat: 36.8374,
            gisGcj02Lng: 115.706,
            stubGroupCnt: null,
            pinYin: 'Linqing',
          },
        ],
        gisBd09Lng: 115.992,
        text: '聊城',
        gisBd09Lat: 36.4614,
        gisGcj02Lat: 36.4557,
      },
      {
        pinYin: 'Binzhou',
        id: '371600',
        gisGcj02Lng: 117.974,
        children: [
          {
            id: '371601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3877,
            gisBd09Lng: 117.981,
            gisGcj02Lat: 37.3814,
            gisGcj02Lng: 117.974,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371602',
            text: '滨城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4325,
            gisBd09Lng: 118.03,
            gisGcj02Lat: 37.4269,
            gisGcj02Lng: 118.024,
            stubGroupCnt: null,
            pinYin: 'Bincheng',
          },
          {
            id: '371621',
            text: '惠民县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4945,
            gisBd09Lng: 117.515,
            gisGcj02Lat: 37.4884,
            gisGcj02Lng: 117.509,
            stubGroupCnt: null,
            pinYin: 'Huimin',
          },
          {
            id: '371622',
            text: '阳信县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6468,
            gisBd09Lng: 117.585,
            gisGcj02Lat: 37.6406,
            gisGcj02Lng: 117.579,
            stubGroupCnt: null,
            pinYin: 'Yangxin',
          },
          {
            id: '371623',
            text: '无棣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7744,
            gisBd09Lng: 117.631,
            gisGcj02Lat: 37.7683,
            gisGcj02Lng: 117.625,
            stubGroupCnt: null,
            pinYin: 'Wudi',
          },
          {
            id: '371624',
            text: '沾化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7866,
            gisBd09Lng: 118.091,
            gisGcj02Lat: 37.7802,
            gisGcj02Lng: 118.084,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371625',
            text: '博兴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.159,
            gisBd09Lng: 118.119,
            gisGcj02Lat: 37.153,
            gisGcj02Lng: 118.112,
            stubGroupCnt: null,
            pinYin: 'Boxing',
          },
          {
            id: '371626',
            text: '邹平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8676,
            gisBd09Lng: 117.75,
            gisGcj02Lat: 36.8615,
            gisGcj02Lng: 117.744,
            stubGroupCnt: null,
            pinYin: 'Zouping',
          },
        ],
        gisBd09Lng: 117.981,
        text: '滨州',
        gisBd09Lat: 37.3877,
        gisGcj02Lat: 37.3814,
      },
      {
        pinYin: 'Heze',
        id: '371700',
        gisGcj02Lng: 115.481,
        children: [
          {
            id: '371701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2392,
            gisBd09Lng: 115.487,
            gisGcj02Lat: 35.2334,
            gisGcj02Lng: 115.481,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '371702',
            text: '牡丹区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2574,
            gisBd09Lng: 115.423,
            gisGcj02Lat: 35.2512,
            gisGcj02Lng: 115.417,
            stubGroupCnt: null,
            pinYin: 'Mudan',
          },
          {
            id: '371721',
            text: '曹　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8306,
            gisBd09Lng: 115.55,
            gisGcj02Lat: 34.8243,
            gisGcj02Lng: 115.543,
            stubGroupCnt: null,
            pinYin: 'Caoxian',
          },
          {
            id: '371722',
            text: '单　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8002,
            gisBd09Lng: 116.093,
            gisGcj02Lat: 34.7945,
            gisGcj02Lng: 116.087,
            stubGroupCnt: null,
            pinYin: 'Shanxian',
          },
          {
            id: '371723',
            text: '成武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9581,
            gisBd09Lng: 115.896,
            gisGcj02Lat: 34.9521,
            gisGcj02Lng: 115.889,
            stubGroupCnt: null,
            pinYin: 'Chengwu',
          },
          {
            id: '371724',
            text: '巨野县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4008,
            gisBd09Lng: 116.103,
            gisGcj02Lat: 35.3952,
            gisGcj02Lng: 116.096,
            stubGroupCnt: null,
            pinYin: 'Juye',
          },
          {
            id: '371725',
            text: '郓城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6055,
            gisBd09Lng: 115.951,
            gisGcj02Lat: 35.5994,
            gisGcj02Lng: 115.945,
            stubGroupCnt: null,
            pinYin: 'Yuncheng',
          },
          {
            id: '371726',
            text: '鄄城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5684,
            gisBd09Lng: 115.517,
            gisGcj02Lat: 35.5627,
            gisGcj02Lng: 115.511,
            stubGroupCnt: null,
            pinYin: 'Juancheng',
          },
          {
            id: '371727',
            text: '定陶县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0764,
            gisBd09Lng: 115.58,
            gisGcj02Lat: 35.0701,
            gisGcj02Lng: 115.574,
            stubGroupCnt: null,
            pinYin: 'Dingtao',
          },
          {
            id: '371728',
            text: '东明县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2949,
            gisBd09Lng: 115.097,
            gisGcj02Lat: 35.2886,
            gisGcj02Lng: 115.09,
            stubGroupCnt: null,
            pinYin: 'Dongming',
          },
        ],
        gisBd09Lng: 115.487,
        text: '菏泽',
        gisBd09Lat: 35.2392,
        gisGcj02Lat: 35.2334,
      },
    ],
  },
  {
    text: '山西省',
    children: [
      {
        pinYin: 'Taiyuan',
        id: '140100',
        gisGcj02Lng: 112.551,
        children: [
          {
            id: '140101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8772,
            gisBd09Lng: 112.557,
            gisGcj02Lat: 37.8709,
            gisGcj02Lng: 112.551,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140105',
            text: '小店区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7419,
            gisBd09Lng: 112.573,
            gisGcj02Lat: 37.7356,
            gisGcj02Lng: 112.567,
            stubGroupCnt: null,
            pinYin: 'Xiaodian',
          },
          {
            id: '140106',
            text: '迎泽区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8684,
            gisBd09Lng: 112.57,
            gisGcj02Lat: 37.8621,
            gisGcj02Lng: 112.564,
            stubGroupCnt: null,
            pinYin: 'Yingze',
          },
          {
            id: '140107',
            text: '杏花岭区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9002,
            gisBd09Lng: 112.577,
            gisGcj02Lat: 37.8939,
            gisGcj02Lng: 112.571,
            stubGroupCnt: null,
            pinYin: 'Xinghualing',
          },
          {
            id: '140108',
            text: '尖草坪区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9447,
            gisBd09Lng: 112.493,
            gisGcj02Lat: 37.939,
            gisGcj02Lng: 112.486,
            stubGroupCnt: null,
            pinYin: 'Jiancaoping',
          },
          {
            id: '140109',
            text: '万柏林区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8645,
            gisBd09Lng: 112.522,
            gisGcj02Lat: 37.8587,
            gisGcj02Lng: 112.515,
            stubGroupCnt: null,
            pinYin: 'Wanbailin',
          },
          {
            id: '140110',
            text: '晋源区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7208,
            gisBd09Lng: 112.484,
            gisGcj02Lat: 37.7149,
            gisGcj02Lng: 112.478,
            stubGroupCnt: null,
            pinYin: 'Jinyuan',
          },
          {
            id: '140121',
            text: '清徐县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6123,
            gisBd09Lng: 112.365,
            gisGcj02Lat: 37.6064,
            gisGcj02Lng: 112.358,
            stubGroupCnt: null,
            pinYin: 'Qingxu',
          },
          {
            id: '140122',
            text: '阳曲县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0634,
            gisBd09Lng: 112.679,
            gisGcj02Lat: 38.057,
            gisGcj02Lng: 112.672,
            stubGroupCnt: null,
            pinYin: 'Yangqu',
          },
          {
            id: '140123',
            text: '娄烦县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0725,
            gisBd09Lng: 111.805,
            gisGcj02Lat: 38.0667,
            gisGcj02Lng: 111.798,
            stubGroupCnt: null,
            pinYin: 'Loufan',
          },
          {
            id: '140181',
            text: '古交',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9127,
            gisBd09Lng: 112.182,
            gisGcj02Lat: 37.9066,
            gisGcj02Lng: 112.175,
            stubGroupCnt: null,
            pinYin: 'Gujiao',
          },
        ],
        gisBd09Lng: 112.557,
        text: '太原',
        gisBd09Lat: 37.8772,
        gisGcj02Lat: 37.8709,
      },
      {
        pinYin: 'Datong',
        id: '140200',
        gisGcj02Lng: 113.301,
        children: [
          {
            id: '140201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.0819,
            gisBd09Lng: 113.307,
            gisGcj02Lat: 40.0757,
            gisGcj02Lng: 113.301,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140202',
            text: '城　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8529,
            gisBd09Lng: 113.607,
            gisGcj02Lat: 37.8471,
            gisGcj02Lng: 113.601,
            stubGroupCnt: null,
            pinYin: 'Chengqu',
          },
          {
            id: '140203',
            text: '矿　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8744,
            gisBd09Lng: 113.561,
            gisGcj02Lat: 37.8685,
            gisGcj02Lng: 113.555,
            stubGroupCnt: null,
            pinYin: 'Kuangqu',
          },
          {
            id: '140211',
            text: '南郊区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.0109,
            gisBd09Lng: 113.157,
            gisGcj02Lat: 40.0047,
            gisGcj02Lng: 113.15,
            stubGroupCnt: null,
            pinYin: 'Nanjiao',
          },
          {
            id: '140212',
            text: '新荣区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.2616,
            gisBd09Lng: 113.146,
            gisGcj02Lat: 40.2554,
            gisGcj02Lng: 113.14,
            stubGroupCnt: null,
            pinYin: 'Xinrong',
          },
          {
            id: '140221',
            text: '阳高县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.3724,
            gisBd09Lng: 113.761,
            gisGcj02Lat: 40.3661,
            gisGcj02Lng: 113.754,
            stubGroupCnt: null,
            pinYin: 'Yanggao',
          },
          {
            id: '140222',
            text: '天镇县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.4258,
            gisBd09Lng: 114.099,
            gisGcj02Lat: 40.4197,
            gisGcj02Lng: 114.093,
            stubGroupCnt: null,
            pinYin: 'Tianzhen',
          },
          {
            id: '140223',
            text: '广灵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7656,
            gisBd09Lng: 114.288,
            gisGcj02Lat: 39.7597,
            gisGcj02Lng: 114.281,
            stubGroupCnt: null,
            pinYin: 'Guangling',
          },
          {
            id: '140224',
            text: '灵丘县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4475,
            gisBd09Lng: 114.24,
            gisGcj02Lat: 39.4412,
            gisGcj02Lng: 114.234,
            stubGroupCnt: null,
            pinYin: 'Lingqiu',
          },
          {
            id: '140225',
            text: '浑源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7044,
            gisBd09Lng: 113.697,
            gisGcj02Lat: 39.6987,
            gisGcj02Lng: 113.69,
            stubGroupCnt: null,
            pinYin: 'Hunyuan',
          },
          {
            id: '140226',
            text: '左云县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.019,
            gisBd09Lng: 112.709,
            gisGcj02Lat: 40.0129,
            gisGcj02Lng: 112.703,
            stubGroupCnt: null,
            pinYin: 'Zuoyun',
          },
          {
            id: '140227',
            text: '大同县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.0453,
            gisBd09Lng: 113.619,
            gisGcj02Lat: 40.0393,
            gisGcj02Lng: 113.612,
            stubGroupCnt: null,
            pinYin: 'Datong',
          },
        ],
        gisBd09Lng: 113.307,
        text: '大同',
        gisBd09Lat: 40.0819,
        gisGcj02Lat: 40.0757,
      },
      {
        pinYin: 'Yangquan',
        id: '140300',
        gisGcj02Lng: 113.58,
        children: [
          {
            id: '140301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8621,
            gisBd09Lng: 113.587,
            gisGcj02Lat: 37.8564,
            gisGcj02Lng: 113.58,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140302',
            text: '城　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8535,
            gisBd09Lng: 113.606,
            gisGcj02Lat: 37.8476,
            gisGcj02Lng: 113.599,
            stubGroupCnt: null,
            pinYin: 'Chengqu',
          },
          {
            id: '140303',
            text: '矿　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8743,
            gisBd09Lng: 113.56,
            gisGcj02Lat: 37.8684,
            gisGcj02Lng: 113.554,
            stubGroupCnt: null,
            pinYin: 'Kuangqu',
          },
          {
            id: '140311',
            text: '郊　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9465,
            gisBd09Lng: 113.592,
            gisGcj02Lat: 37.9408,
            gisGcj02Lng: 113.586,
            stubGroupCnt: null,
            pinYin: 'Jiaoqu',
          },
          {
            id: '140321',
            text: '平定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7928,
            gisBd09Lng: 113.665,
            gisGcj02Lat: 37.7866,
            gisGcj02Lng: 113.659,
            stubGroupCnt: null,
            pinYin: 'Pingding',
          },
          {
            id: '140322',
            text: '盂　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0918,
            gisBd09Lng: 113.419,
            gisGcj02Lat: 38.0855,
            gisGcj02Lng: 113.412,
            stubGroupCnt: null,
            pinYin: 'Yuxian',
          },
        ],
        gisBd09Lng: 113.587,
        text: '阳泉',
        gisBd09Lat: 37.8621,
        gisGcj02Lat: 37.8564,
      },
      {
        pinYin: 'Changzhi',
        id: '140400',
        gisGcj02Lng: 113.116,
        children: [
          {
            id: '140401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2012,
            gisBd09Lng: 113.123,
            gisGcj02Lat: 36.1954,
            gisGcj02Lng: 113.116,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140402',
            text: '城　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2089,
            gisBd09Lng: 113.13,
            gisGcj02Lat: 36.203,
            gisGcj02Lng: 113.124,
            stubGroupCnt: null,
            pinYin: 'Chengqu',
          },
          {
            id: '140411',
            text: '郊　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2299,
            gisBd09Lng: 113.118,
            gisGcj02Lat: 36.2242,
            gisGcj02Lng: 113.112,
            stubGroupCnt: null,
            pinYin: 'Jiaoqu',
          },
          {
            id: '140421',
            text: '长治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0586,
            gisBd09Lng: 113.057,
            gisGcj02Lat: 36.0524,
            gisGcj02Lng: 113.05,
            stubGroupCnt: null,
            pinYin: 'Changzhi',
          },
          {
            id: '140423',
            text: '襄垣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5418,
            gisBd09Lng: 113.06,
            gisGcj02Lat: 36.5356,
            gisGcj02Lng: 113.053,
            stubGroupCnt: null,
            pinYin: 'Xiangyuan',
          },
          {
            id: '140424',
            text: '屯留县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3212,
            gisBd09Lng: 112.898,
            gisGcj02Lat: 36.3152,
            gisGcj02Lng: 112.892,
            stubGroupCnt: null,
            pinYin: 'Tunliu',
          },
          {
            id: '140425',
            text: '平顺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2054,
            gisBd09Lng: 113.443,
            gisGcj02Lat: 36.1995,
            gisGcj02Lng: 113.436,
            stubGroupCnt: null,
            pinYin: 'Pingshun',
          },
          {
            id: '140426',
            text: '黎城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.508,
            gisBd09Lng: 113.394,
            gisGcj02Lat: 36.5017,
            gisGcj02Lng: 113.387,
            stubGroupCnt: null,
            pinYin: 'Licheng',
          },
          {
            id: '140427',
            text: '壶关县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1194,
            gisBd09Lng: 113.214,
            gisGcj02Lat: 36.1137,
            gisGcj02Lng: 113.208,
            stubGroupCnt: null,
            pinYin: 'Huguan',
          },
          {
            id: '140428',
            text: '长子县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1272,
            gisBd09Lng: 112.884,
            gisGcj02Lat: 36.1214,
            gisGcj02Lng: 112.878,
            stubGroupCnt: null,
            pinYin: 'Zhangzi',
          },
          {
            id: '140429',
            text: '武乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8421,
            gisBd09Lng: 112.87,
            gisGcj02Lat: 36.8364,
            gisGcj02Lng: 112.864,
            stubGroupCnt: null,
            pinYin: 'Wuxiang',
          },
          {
            id: '140430',
            text: '沁　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7615,
            gisBd09Lng: 112.705,
            gisGcj02Lat: 36.7553,
            gisGcj02Lng: 112.698,
            stubGroupCnt: null,
            pinYin: 'Qinxian',
          },
          {
            id: '140431',
            text: '沁源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5061,
            gisBd09Lng: 112.343,
            gisGcj02Lat: 36.4999,
            gisGcj02Lng: 112.337,
            stubGroupCnt: null,
            pinYin: 'Qinyuan',
          },
          {
            id: '140481',
            text: '潞城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.3392,
            gisBd09Lng: 113.234,
            gisGcj02Lat: 36.3335,
            gisGcj02Lng: 113.228,
            stubGroupCnt: null,
            pinYin: 'Lucheng',
          },
        ],
        gisBd09Lng: 113.123,
        text: '长治',
        gisBd09Lat: 36.2012,
        gisGcj02Lat: 36.1954,
      },
      {
        pinYin: 'Jincheng',
        id: '140500',
        gisGcj02Lng: 112.852,
        children: [
          {
            id: '140501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4959,
            gisBd09Lng: 112.858,
            gisGcj02Lat: 35.4902,
            gisGcj02Lng: 112.852,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140502',
            text: '城　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5084,
            gisBd09Lng: 112.861,
            gisGcj02Lat: 35.5027,
            gisGcj02Lng: 112.855,
            stubGroupCnt: null,
            pinYin: 'Chengqu',
          },
          {
            id: '140521',
            text: '沁水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6959,
            gisBd09Lng: 112.194,
            gisGcj02Lat: 35.6896,
            gisGcj02Lng: 112.187,
            stubGroupCnt: null,
            pinYin: 'Qinshui',
          },
          {
            id: '140522',
            text: '阳城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4915,
            gisBd09Lng: 112.421,
            gisGcj02Lat: 35.4854,
            gisGcj02Lng: 112.415,
            stubGroupCnt: null,
            pinYin: 'Yangcheng',
          },
          {
            id: '140524',
            text: '陵川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7815,
            gisBd09Lng: 113.286,
            gisGcj02Lat: 35.7751,
            gisGcj02Lng: 113.28,
            stubGroupCnt: null,
            pinYin: 'Lingchuan',
          },
          {
            id: '140525',
            text: '泽州县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5064,
            gisBd09Lng: 112.95,
            gisGcj02Lat: 35.5003,
            gisGcj02Lng: 112.944,
            stubGroupCnt: null,
            pinYin: 'Zezhou',
          },
          {
            id: '140581',
            text: '高平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8046,
            gisBd09Lng: 112.929,
            gisGcj02Lat: 35.7982,
            gisGcj02Lng: 112.923,
            stubGroupCnt: null,
            pinYin: 'Gaoping',
          },
        ],
        gisBd09Lng: 112.858,
        text: '晋城',
        gisBd09Lat: 35.4959,
        gisGcj02Lat: 35.4902,
      },
      {
        pinYin: 'Shuozhou',
        id: '140600',
        gisGcj02Lng: 112.432,
        children: [
          {
            id: '140601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3373,
            gisBd09Lng: 112.438,
            gisGcj02Lat: 39.331,
            gisGcj02Lng: 112.432,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140602',
            text: '朔城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3252,
            gisBd09Lng: 112.44,
            gisGcj02Lat: 39.3188,
            gisGcj02Lng: 112.433,
            stubGroupCnt: null,
            pinYin: 'Shuocheng',
          },
          {
            id: '140603',
            text: '平鲁区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5181,
            gisBd09Lng: 112.295,
            gisGcj02Lat: 39.5121,
            gisGcj02Lng: 112.288,
            stubGroupCnt: null,
            pinYin: 'Pinglu',
          },
          {
            id: '140621',
            text: '山阴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5322,
            gisBd09Lng: 112.821,
            gisGcj02Lat: 39.5259,
            gisGcj02Lng: 112.815,
            stubGroupCnt: null,
            pinYin: 'Shanyin',
          },
          {
            id: '140622',
            text: '应　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.5597,
            gisBd09Lng: 113.198,
            gisGcj02Lat: 39.5537,
            gisGcj02Lng: 113.191,
            stubGroupCnt: null,
            pinYin: 'Yingxian',
          },
          {
            id: '140623',
            text: '右玉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.9944,
            gisBd09Lng: 112.474,
            gisGcj02Lat: 39.9884,
            gisGcj02Lng: 112.468,
            stubGroupCnt: null,
            pinYin: 'Youyu',
          },
          {
            id: '140624',
            text: '怀仁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8336,
            gisBd09Lng: 113.106,
            gisGcj02Lat: 39.8279,
            gisGcj02Lng: 113.1,
            stubGroupCnt: null,
            pinYin: 'Huairen',
          },
        ],
        gisBd09Lng: 112.438,
        text: '朔州',
        gisBd09Lat: 39.3373,
        gisGcj02Lat: 39.331,
      },
      {
        pinYin: 'Jinzhong',
        id: '140700',
        gisGcj02Lng: 112.753,
        children: [
          {
            id: '140701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6926,
            gisBd09Lng: 112.76,
            gisGcj02Lat: 37.6868,
            gisGcj02Lng: 112.753,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140702',
            text: '榆次区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7043,
            gisBd09Lng: 112.714,
            gisGcj02Lat: 37.6982,
            gisGcj02Lng: 112.707,
            stubGroupCnt: null,
            pinYin: 'Yuci',
          },
          {
            id: '140721',
            text: '榆社县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0766,
            gisBd09Lng: 112.982,
            gisGcj02Lat: 37.071,
            gisGcj02Lng: 112.976,
            stubGroupCnt: null,
            pinYin: 'Yushe',
          },
          {
            id: '140722',
            text: '左权县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0882,
            gisBd09Lng: 113.386,
            gisGcj02Lat: 37.0821,
            gisGcj02Lng: 113.379,
            stubGroupCnt: null,
            pinYin: 'Zuoquan',
          },
          {
            id: '140723',
            text: '和顺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3345,
            gisBd09Lng: 113.576,
            gisGcj02Lat: 37.3288,
            gisGcj02Lng: 113.57,
            stubGroupCnt: null,
            pinYin: 'Heshun',
          },
          {
            id: '140724',
            text: '昔阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6175,
            gisBd09Lng: 113.714,
            gisGcj02Lat: 37.6118,
            gisGcj02Lng: 113.707,
            stubGroupCnt: null,
            pinYin: 'Xiyang',
          },
          {
            id: '140725',
            text: '寿阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9001,
            gisBd09Lng: 113.183,
            gisGcj02Lat: 37.8939,
            gisGcj02Lng: 113.176,
            stubGroupCnt: null,
            pinYin: 'Shouyang',
          },
          {
            id: '140726',
            text: '太谷县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4273,
            gisBd09Lng: 112.557,
            gisGcj02Lat: 37.421,
            gisGcj02Lng: 112.551,
            stubGroupCnt: null,
            pinYin: 'Taigu',
          },
          {
            id: '140727',
            text: '祁　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3635,
            gisBd09Lng: 112.342,
            gisGcj02Lat: 37.3573,
            gisGcj02Lng: 112.335,
            stubGroupCnt: null,
            pinYin: 'Qixian',
          },
          {
            id: '140728',
            text: '平遥县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1951,
            gisBd09Lng: 112.182,
            gisGcj02Lat: 37.189,
            gisGcj02Lng: 112.175,
            stubGroupCnt: null,
            pinYin: 'Pingyao',
          },
          {
            id: '140729',
            text: '灵石县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8531,
            gisBd09Lng: 111.785,
            gisGcj02Lat: 36.8474,
            gisGcj02Lng: 111.778,
            stubGroupCnt: null,
            pinYin: 'Lingshi',
          },
          {
            id: '140781',
            text: '介休',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0309,
            gisBd09Lng: 111.923,
            gisGcj02Lat: 37.0251,
            gisGcj02Lng: 111.917,
            stubGroupCnt: null,
            pinYin: 'Jiexiu',
          },
        ],
        gisBd09Lng: 112.76,
        text: '晋中',
        gisBd09Lat: 37.6926,
        gisGcj02Lat: 37.6868,
      },
      {
        pinYin: 'Yuncheng',
        id: '140800',
        gisGcj02Lng: 111.008,
        children: [
          {
            id: '140801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0311,
            gisBd09Lng: 111.015,
            gisGcj02Lat: 35.0248,
            gisGcj02Lng: 111.008,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140802',
            text: '盐湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.021,
            gisBd09Lng: 111.004,
            gisGcj02Lat: 35.0147,
            gisGcj02Lng: 110.998,
            stubGroupCnt: null,
            pinYin: 'Yanhu',
          },
          {
            id: '140821',
            text: '临猗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1498,
            gisBd09Lng: 110.78,
            gisGcj02Lat: 35.1435,
            gisGcj02Lng: 110.774,
            stubGroupCnt: null,
            pinYin: 'Linyi',
          },
          {
            id: '140822',
            text: '万荣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4193,
            gisBd09Lng: 110.844,
            gisGcj02Lat: 35.4135,
            gisGcj02Lng: 110.837,
            stubGroupCnt: null,
            pinYin: 'Wanrong',
          },
          {
            id: '140823',
            text: '闻喜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3624,
            gisBd09Lng: 111.232,
            gisGcj02Lat: 35.3562,
            gisGcj02Lng: 111.225,
            stubGroupCnt: null,
            pinYin: 'Wenxi',
          },
          {
            id: '140824',
            text: '稷山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6096,
            gisBd09Lng: 110.99,
            gisGcj02Lat: 35.6034,
            gisGcj02Lng: 110.983,
            stubGroupCnt: null,
            pinYin: 'Jishan',
          },
          {
            id: '140825',
            text: '新绛县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6214,
            gisBd09Lng: 111.231,
            gisGcj02Lat: 35.6152,
            gisGcj02Lng: 111.224,
            stubGroupCnt: null,
            pinYin: 'Xinjiang',
          },
          {
            id: '140826',
            text: '绛　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4963,
            gisBd09Lng: 111.576,
            gisGcj02Lat: 35.4903,
            gisGcj02Lng: 111.569,
            stubGroupCnt: null,
            pinYin: 'Jiangxian',
          },
          {
            id: '140827',
            text: '垣曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.3023,
            gisBd09Lng: 111.677,
            gisGcj02Lat: 35.2966,
            gisGcj02Lng: 111.67,
            stubGroupCnt: null,
            pinYin: 'Yuanqu',
          },
          {
            id: '140828',
            text: '夏　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1466,
            gisBd09Lng: 111.227,
            gisGcj02Lat: 35.1404,
            gisGcj02Lng: 111.221,
            stubGroupCnt: null,
            pinYin: 'Xiaxian',
          },
          {
            id: '140829',
            text: '平陆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8408,
            gisBd09Lng: 111.2,
            gisGcj02Lat: 34.835,
            gisGcj02Lng: 111.193,
            stubGroupCnt: null,
            pinYin: 'Pinglu',
          },
          {
            id: '140830',
            text: '芮城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6983,
            gisBd09Lng: 110.701,
            gisGcj02Lat: 34.6926,
            gisGcj02Lng: 110.695,
            stubGroupCnt: null,
            pinYin: 'Ruicheng',
          },
          {
            id: '140881',
            text: '永济',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8726,
            gisBd09Lng: 110.455,
            gisGcj02Lat: 34.8669,
            gisGcj02Lng: 110.448,
            stubGroupCnt: null,
            pinYin: 'Yongji',
          },
          {
            id: '140882',
            text: '河津',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.602,
            gisBd09Lng: 110.718,
            gisGcj02Lat: 35.5963,
            gisGcj02Lng: 110.711,
            stubGroupCnt: null,
            pinYin: 'Hejin',
          },
        ],
        gisBd09Lng: 111.015,
        text: '运城',
        gisBd09Lat: 35.0311,
        gisGcj02Lat: 35.0248,
      },
      {
        pinYin: 'Xinzhou',
        id: '140900',
        gisGcj02Lng: 112.734,
        children: [
          {
            id: '140901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4208,
            gisBd09Lng: 112.74,
            gisGcj02Lat: 38.4151,
            gisGcj02Lng: 112.734,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '140902',
            text: '忻府区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4087,
            gisBd09Lng: 112.753,
            gisGcj02Lat: 38.403,
            gisGcj02Lng: 112.746,
            stubGroupCnt: null,
            pinYin: 'Xinfu',
          },
          {
            id: '140921',
            text: '定襄县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4773,
            gisBd09Lng: 112.964,
            gisGcj02Lat: 38.4715,
            gisGcj02Lng: 112.958,
            stubGroupCnt: null,
            pinYin: 'Dingxiang',
          },
          {
            id: '140922',
            text: '五台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7321,
            gisBd09Lng: 113.263,
            gisGcj02Lat: 38.726,
            gisGcj02Lng: 113.257,
            stubGroupCnt: null,
            pinYin: 'Wutai',
          },
          {
            id: '140923',
            text: '代　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.071,
            gisBd09Lng: 112.966,
            gisGcj02Lat: 39.0652,
            gisGcj02Lng: 112.96,
            stubGroupCnt: null,
            pinYin: 'Daixian',
          },
          {
            id: '140924',
            text: '繁峙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1945,
            gisBd09Lng: 113.273,
            gisGcj02Lat: 39.1882,
            gisGcj02Lng: 113.267,
            stubGroupCnt: null,
            pinYin: 'Fanshi',
          },
          {
            id: '140925',
            text: '宁武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0065,
            gisBd09Lng: 112.31,
            gisGcj02Lat: 39.0003,
            gisGcj02Lng: 112.303,
            stubGroupCnt: null,
            pinYin: 'Ningwu',
          },
          {
            id: '140926',
            text: '静乐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.3645,
            gisBd09Lng: 111.947,
            gisGcj02Lat: 38.3583,
            gisGcj02Lng: 111.94,
            stubGroupCnt: null,
            pinYin: 'Jingle',
          },
          {
            id: '140927',
            text: '神池县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0967,
            gisBd09Lng: 112.217,
            gisGcj02Lat: 39.0905,
            gisGcj02Lng: 112.211,
            stubGroupCnt: null,
            pinYin: 'Shenchi',
          },
          {
            id: '140928',
            text: '五寨县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9162,
            gisBd09Lng: 111.854,
            gisGcj02Lat: 38.9099,
            gisGcj02Lng: 111.847,
            stubGroupCnt: null,
            pinYin: 'Wuzhai',
          },
          {
            id: '140929',
            text: '岢岚县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7058,
            gisBd09Lng: 111.582,
            gisGcj02Lat: 38.6997,
            gisGcj02Lng: 111.575,
            stubGroupCnt: null,
            pinYin: 'Kelan',
          },
          {
            id: '140930',
            text: '河曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.389,
            gisBd09Lng: 111.145,
            gisGcj02Lat: 39.3828,
            gisGcj02Lng: 111.139,
            stubGroupCnt: null,
            pinYin: 'Hequ',
          },
          {
            id: '140931',
            text: '保德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0267,
            gisBd09Lng: 111.092,
            gisGcj02Lat: 39.0208,
            gisGcj02Lng: 111.086,
            stubGroupCnt: null,
            pinYin: 'Baode',
          },
          {
            id: '140932',
            text: '偏关县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4425,
            gisBd09Lng: 111.517,
            gisGcj02Lat: 39.4365,
            gisGcj02Lng: 111.511,
            stubGroupCnt: null,
            pinYin: 'Pianguan',
          },
          {
            id: '140981',
            text: '原平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.7358,
            gisBd09Lng: 112.717,
            gisGcj02Lat: 38.7298,
            gisGcj02Lng: 112.711,
            stubGroupCnt: null,
            pinYin: 'Yuanping',
          },
        ],
        gisBd09Lng: 112.74,
        text: '忻州',
        gisBd09Lat: 38.4208,
        gisGcj02Lat: 38.4151,
      },
      {
        pinYin: 'Linfen',
        id: '141000',
        gisGcj02Lng: 111.52,
        children: [
          {
            id: '141001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0931,
            gisBd09Lng: 111.526,
            gisGcj02Lat: 36.0873,
            gisGcj02Lng: 111.52,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '141002',
            text: '尧都区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0878,
            gisBd09Lng: 111.584,
            gisGcj02Lat: 36.0817,
            gisGcj02Lng: 111.578,
            stubGroupCnt: null,
            pinYin: 'Yaodu',
          },
          {
            id: '141021',
            text: '曲沃县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6473,
            gisBd09Lng: 111.482,
            gisGcj02Lat: 35.6409,
            gisGcj02Lng: 111.476,
            stubGroupCnt: null,
            pinYin: 'Quwo',
          },
          {
            id: '141022',
            text: '翼城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7444,
            gisBd09Lng: 111.725,
            gisGcj02Lat: 35.7381,
            gisGcj02Lng: 111.719,
            stubGroupCnt: null,
            pinYin: 'Yicheng',
          },
          {
            id: '141023',
            text: '襄汾县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.8815,
            gisBd09Lng: 111.449,
            gisGcj02Lat: 35.8757,
            gisGcj02Lng: 111.442,
            stubGroupCnt: null,
            pinYin: 'Xiangfen',
          },
          {
            id: '141024',
            text: '洪洞县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2583,
            gisBd09Lng: 111.682,
            gisGcj02Lat: 36.2525,
            gisGcj02Lng: 111.676,
            stubGroupCnt: null,
            pinYin: 'Hongtong',
          },
          {
            id: '141025',
            text: '古　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2718,
            gisBd09Lng: 111.927,
            gisGcj02Lat: 36.266,
            gisGcj02Lng: 111.921,
            stubGroupCnt: null,
            pinYin: 'Guxian',
          },
          {
            id: '141026',
            text: '安泽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1526,
            gisBd09Lng: 112.257,
            gisGcj02Lat: 36.1469,
            gisGcj02Lng: 112.251,
            stubGroupCnt: null,
            pinYin: 'Anze',
          },
          {
            id: '141027',
            text: '浮山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9739,
            gisBd09Lng: 111.856,
            gisGcj02Lat: 35.9676,
            gisGcj02Lng: 111.85,
            stubGroupCnt: null,
            pinYin: 'Fushan',
          },
          {
            id: '141028',
            text: '吉　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.1032,
            gisBd09Lng: 110.689,
            gisGcj02Lat: 36.0974,
            gisGcj02Lng: 110.682,
            stubGroupCnt: null,
            pinYin: 'Jixian',
          },
          {
            id: '141029',
            text: '乡宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9755,
            gisBd09Lng: 110.854,
            gisGcj02Lat: 35.9695,
            gisGcj02Lng: 110.847,
            stubGroupCnt: null,
            pinYin: 'Xiangning',
          },
          {
            id: '141030',
            text: '大宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4711,
            gisBd09Lng: 110.759,
            gisGcj02Lat: 36.4648,
            gisGcj02Lng: 110.753,
            stubGroupCnt: null,
            pinYin: 'Daning',
          },
          {
            id: '141031',
            text: '隰　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6987,
            gisBd09Lng: 110.947,
            gisGcj02Lat: 36.693,
            gisGcj02Lng: 110.94,
            stubGroupCnt: null,
            pinYin: 'Xixian',
          },
          {
            id: '141032',
            text: '永和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.7655,
            gisBd09Lng: 110.638,
            gisGcj02Lat: 36.7592,
            gisGcj02Lng: 110.632,
            stubGroupCnt: null,
            pinYin: 'Yonghe',
          },
          {
            id: '141033',
            text: '蒲　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.4163,
            gisBd09Lng: 111.104,
            gisGcj02Lat: 36.4102,
            gisGcj02Lng: 111.097,
            stubGroupCnt: null,
            pinYin: 'Puxian',
          },
          {
            id: '141034',
            text: '汾西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6589,
            gisBd09Lng: 111.57,
            gisGcj02Lat: 36.653,
            gisGcj02Lng: 111.563,
            stubGroupCnt: null,
            pinYin: 'Fenxi',
          },
          {
            id: '141081',
            text: '侯马',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.6238,
            gisBd09Lng: 111.379,
            gisGcj02Lat: 35.6176,
            gisGcj02Lng: 111.372,
            stubGroupCnt: null,
            pinYin: 'Houma',
          },
          {
            id: '141082',
            text: '霍州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5741,
            gisBd09Lng: 111.761,
            gisGcj02Lat: 36.5681,
            gisGcj02Lng: 111.755,
            stubGroupCnt: null,
            pinYin: 'Huozhou',
          },
        ],
        gisBd09Lng: 111.526,
        text: '临汾',
        gisBd09Lat: 36.0931,
        gisGcj02Lat: 36.0873,
      },
      {
        pinYin: 'Lvliang',
        id: '141100',
        gisGcj02Lng: 111.142,
        children: [
          {
            id: '141101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5253,
            gisBd09Lng: 111.148,
            gisGcj02Lat: 37.5192,
            gisGcj02Lng: 111.142,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '141102',
            text: '离石区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5233,
            gisBd09Lng: 111.157,
            gisGcj02Lat: 37.5173,
            gisGcj02Lng: 111.15,
            stubGroupCnt: null,
            pinYin: 'Lishi',
          },
          {
            id: '141121',
            text: '文水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.444,
            gisBd09Lng: 112.035,
            gisGcj02Lat: 37.4383,
            gisGcj02Lng: 112.029,
            stubGroupCnt: null,
            pinYin: 'Wenshui',
          },
          {
            id: '141122',
            text: '交城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5571,
            gisBd09Lng: 112.162,
            gisGcj02Lat: 37.5513,
            gisGcj02Lng: 112.155,
            stubGroupCnt: null,
            pinYin: 'Jiaocheng',
          },
          {
            id: '141123',
            text: '兴　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.468,
            gisBd09Lng: 111.133,
            gisGcj02Lat: 38.4617,
            gisGcj02Lng: 111.127,
            stubGroupCnt: null,
            pinYin: 'Xingxian',
          },
          {
            id: '141124',
            text: '临　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9572,
            gisBd09Lng: 110.999,
            gisGcj02Lat: 37.9509,
            gisGcj02Lng: 110.993,
            stubGroupCnt: null,
            pinYin: 'Linxian',
          },
          {
            id: '141125',
            text: '柳林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4352,
            gisBd09Lng: 110.896,
            gisGcj02Lat: 37.4289,
            gisGcj02Lng: 110.89,
            stubGroupCnt: null,
            pinYin: 'Liulin',
          },
          {
            id: '141126',
            text: '石楼县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0032,
            gisBd09Lng: 110.842,
            gisGcj02Lat: 36.9974,
            gisGcj02Lng: 110.835,
            stubGroupCnt: null,
            pinYin: 'Shilou',
          },
          {
            id: '141127',
            text: '岚　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2848,
            gisBd09Lng: 111.679,
            gisGcj02Lat: 38.2791,
            gisGcj02Lng: 111.672,
            stubGroupCnt: null,
            pinYin: 'Lanxian',
          },
          {
            id: '141128',
            text: '方山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9008,
            gisBd09Lng: 111.252,
            gisGcj02Lat: 37.8945,
            gisGcj02Lng: 111.245,
            stubGroupCnt: null,
            pinYin: 'Fangshan',
          },
          {
            id: '141129',
            text: '中阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.3618,
            gisBd09Lng: 111.187,
            gisGcj02Lat: 37.3561,
            gisGcj02Lng: 111.18,
            stubGroupCnt: null,
            pinYin: 'Zhongyang',
          },
          {
            id: '141130',
            text: '交口县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9884,
            gisBd09Lng: 111.188,
            gisGcj02Lat: 36.9827,
            gisGcj02Lng: 111.182,
            stubGroupCnt: null,
            pinYin: 'Jiaokou',
          },
          {
            id: '141181',
            text: '孝义',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1509,
            gisBd09Lng: 111.786,
            gisGcj02Lat: 37.1453,
            gisGcj02Lng: 111.779,
            stubGroupCnt: null,
            pinYin: 'Xiaoyi',
          },
          {
            id: '141182',
            text: '汾阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.2711,
            gisBd09Lng: 111.794,
            gisGcj02Lat: 37.2654,
            gisGcj02Lng: 111.788,
            stubGroupCnt: null,
            pinYin: 'Fenyang',
          },
        ],
        gisBd09Lng: 111.148,
        text: '吕梁',
        gisBd09Lat: 37.5253,
        gisGcj02Lat: 37.5192,
      },
    ],
  },
  {
    text: '陕西省',
    children: [
      {
        pinYin: "Xi'an",
        id: '610100',
        gisGcj02Lng: 108.941,
        children: [
          {
            id: '610101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3477,
            gisBd09Lng: 108.947,
            gisGcj02Lat: 34.3416,
            gisGcj02Lng: 108.941,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610102',
            text: '新城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2727,
            gisBd09Lng: 108.968,
            gisGcj02Lat: 34.2664,
            gisGcj02Lng: 108.962,
            stubGroupCnt: null,
            pinYin: 'Xincheng',
          },
          {
            id: '610103',
            text: '碑林区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2373,
            gisBd09Lng: 108.942,
            gisGcj02Lat: 34.2312,
            gisGcj02Lng: 108.936,
            stubGroupCnt: null,
            pinYin: 'Beilin',
          },
          {
            id: '610104',
            text: '莲湖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2734,
            gisBd09Lng: 108.947,
            gisGcj02Lat: 34.2673,
            gisGcj02Lng: 108.941,
            stubGroupCnt: null,
            pinYin: 'Lianhu',
          },
          {
            id: '610111',
            text: '灞桥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.279,
            gisBd09Lng: 109.073,
            gisGcj02Lat: 34.2728,
            gisGcj02Lng: 109.066,
            stubGroupCnt: null,
            pinYin: 'Baqiao',
          },
          {
            id: '610112',
            text: '未央区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.05.26 15:21:29',
            gisBd09Lat: 34.2998,
            gisBd09Lng: 108.953,
            gisGcj02Lat: 34.2936,
            gisGcj02Lng: 108.947,
            stubGroupCnt: null,
            pinYin: 'Weiyang',
          },
          {
            id: '610113',
            text: '雁塔区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2016.04.27 10:51:30',
            gisBd09Lat: 34.229,
            gisBd09Lng: 108.955,
            gisGcj02Lat: 34.2227,
            gisGcj02Lng: 108.949,
            stubGroupCnt: null,
            pinYin: 'Yanta',
          },
          {
            id: '610114',
            text: '阎良区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6683,
            gisBd09Lng: 109.233,
            gisGcj02Lat: 34.6623,
            gisGcj02Lng: 109.227,
            stubGroupCnt: null,
            pinYin: 'Yanliang',
          },
          {
            id: '610115',
            text: '临潼区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.373,
            gisBd09Lng: 109.221,
            gisGcj02Lat: 34.3668,
            gisGcj02Lng: 109.215,
            stubGroupCnt: null,
            pinYin: 'Lintong',
          },
          {
            id: '610116',
            text: '长安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1646,
            gisBd09Lng: 108.914,
            gisGcj02Lat: 34.1589,
            gisGcj02Lng: 108.908,
            stubGroupCnt: null,
            pinYin: "Chang'an",
          },
          {
            id: '610122',
            text: '蓝田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1578,
            gisBd09Lng: 109.33,
            gisGcj02Lat: 34.1514,
            gisGcj02Lng: 109.324,
            stubGroupCnt: null,
            pinYin: 'Lantian',
          },
          {
            id: '610124',
            text: '周至县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1694,
            gisBd09Lng: 108.229,
            gisGcj02Lat: 34.1632,
            gisGcj02Lng: 108.223,
            stubGroupCnt: null,
            pinYin: 'Zhouzhi',
          },
          {
            id: '610125',
            text: '户　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.1147,
            gisBd09Lng: 108.609,
            gisGcj02Lat: 34.1083,
            gisGcj02Lng: 108.603,
            stubGroupCnt: null,
            pinYin: 'Huxian',
          },
          {
            id: '610126',
            text: '高陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5159,
            gisBd09Lng: 109.075,
            gisGcj02Lat: 34.5096,
            gisGcj02Lng: 109.069,
            stubGroupCnt: null,
            pinYin: 'Gaoling',
          },
        ],
        gisBd09Lng: 108.947,
        text: '西安',
        gisBd09Lat: 34.3477,
        gisGcj02Lat: 34.3415,
      },
      {
        pinYin: 'Tongchuan',
        id: '610200',
        gisGcj02Lng: 108.946,
        children: [
          {
            id: '610201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.903,
            gisBd09Lng: 108.952,
            gisGcj02Lat: 34.8968,
            gisGcj02Lng: 108.946,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610202',
            text: '王益区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0757,
            gisBd09Lng: 109.083,
            gisGcj02Lat: 35.0694,
            gisGcj02Lng: 109.077,
            stubGroupCnt: null,
            pinYin: 'Wangyi',
          },
          {
            id: '610203',
            text: '印台区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1217,
            gisBd09Lng: 109.108,
            gisGcj02Lat: 35.1156,
            gisGcj02Lng: 109.102,
            stubGroupCnt: null,
            pinYin: 'Yintai',
          },
          {
            id: '610204',
            text: '耀州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.916,
            gisBd09Lng: 108.986,
            gisGcj02Lat: 34.9098,
            gisGcj02Lng: 108.979,
            stubGroupCnt: null,
            pinYin: 'Yaozhou',
          },
          {
            id: '610222',
            text: '宜君县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4046,
            gisBd09Lng: 109.123,
            gisGcj02Lat: 35.3987,
            gisGcj02Lng: 109.116,
            stubGroupCnt: null,
            pinYin: 'Yijun',
          },
        ],
        gisBd09Lng: 108.952,
        text: '铜川',
        gisBd09Lat: 34.903,
        gisGcj02Lat: 34.8968,
      },
      {
        pinYin: 'Baoji',
        id: '610300',
        gisGcj02Lng: 107.24,
        children: [
          {
            id: '610301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3687,
            gisBd09Lng: 107.247,
            gisGcj02Lat: 34.3629,
            gisGcj02Lng: 107.24,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610302',
            text: '渭滨区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3782,
            gisBd09Lng: 107.158,
            gisGcj02Lat: 34.372,
            gisGcj02Lng: 107.151,
            stubGroupCnt: null,
            pinYin: 'Weibin',
          },
          {
            id: '610303',
            text: '金台区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3963,
            gisBd09Lng: 107.138,
            gisGcj02Lat: 34.3903,
            gisGcj02Lng: 107.131,
            stubGroupCnt: null,
            pinYin: 'Jintai',
          },
          {
            id: '610304',
            text: '陈仓区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3613,
            gisBd09Lng: 107.395,
            gisGcj02Lat: 34.3551,
            gisGcj02Lng: 107.388,
            stubGroupCnt: null,
            pinYin: 'Chencang',
          },
          {
            id: '610322',
            text: '凤翔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5278,
            gisBd09Lng: 107.408,
            gisGcj02Lat: 34.5215,
            gisGcj02Lng: 107.401,
            stubGroupCnt: null,
            pinYin: 'Fengxiang',
          },
          {
            id: '610323',
            text: '岐山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4496,
            gisBd09Lng: 107.628,
            gisGcj02Lat: 34.4435,
            gisGcj02Lng: 107.622,
            stubGroupCnt: null,
            pinYin: 'Qishan',
          },
          {
            id: '610324',
            text: '扶风县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3815,
            gisBd09Lng: 107.907,
            gisGcj02Lat: 34.3753,
            gisGcj02Lng: 107.901,
            stubGroupCnt: null,
            pinYin: 'Fufeng',
          },
          {
            id: '610326',
            text: '眉　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2805,
            gisBd09Lng: 107.757,
            gisGcj02Lat: 34.2742,
            gisGcj02Lng: 107.75,
            stubGroupCnt: null,
            pinYin: 'Meixian',
          },
          {
            id: '610327',
            text: '陇　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8988,
            gisBd09Lng: 106.871,
            gisGcj02Lat: 34.8931,
            gisGcj02Lng: 106.865,
            stubGroupCnt: null,
            pinYin: 'Longxian',
          },
          {
            id: '610328',
            text: '千阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6488,
            gisBd09Lng: 107.139,
            gisGcj02Lat: 34.6427,
            gisGcj02Lng: 107.133,
            stubGroupCnt: null,
            pinYin: 'Qianyang',
          },
          {
            id: '610329',
            text: '麟游县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6841,
            gisBd09Lng: 107.801,
            gisGcj02Lat: 34.6781,
            gisGcj02Lng: 107.794,
            stubGroupCnt: null,
            pinYin: 'Linyou',
          },
          {
            id: '610330',
            text: '凤　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.918,
            gisBd09Lng: 106.529,
            gisGcj02Lat: 33.9121,
            gisGcj02Lng: 106.522,
            stubGroupCnt: null,
            pinYin: 'Fengxian',
          },
          {
            id: '610331',
            text: '太白县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0642,
            gisBd09Lng: 107.327,
            gisGcj02Lat: 34.0584,
            gisGcj02Lng: 107.32,
            stubGroupCnt: null,
            pinYin: 'Taibai',
          },
        ],
        gisBd09Lng: 107.247,
        text: '宝鸡',
        gisBd09Lat: 34.3687,
        gisGcj02Lat: 34.3629,
      },
      {
        pinYin: 'Xianyang',
        id: '610400',
        gisGcj02Lng: 108.709,
        children: [
          {
            id: '610401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3356,
            gisBd09Lng: 108.716,
            gisGcj02Lat: 34.3293,
            gisGcj02Lng: 108.709,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610402',
            text: '秦都区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3355,
            gisBd09Lng: 108.713,
            gisGcj02Lat: 34.3292,
            gisGcj02Lng: 108.706,
            stubGroupCnt: null,
            pinYin: 'Qindu',
          },
          {
            id: '610403',
            text: '杨凌区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2771,
            gisBd09Lng: 108.093,
            gisGcj02Lat: 34.2711,
            gisGcj02Lng: 108.086,
            stubGroupCnt: null,
            pinYin: 'Yangling',
          },
          {
            id: '610404',
            text: '渭城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3683,
            gisBd09Lng: 108.743,
            gisGcj02Lat: 34.3621,
            gisGcj02Lng: 108.737,
            stubGroupCnt: null,
            pinYin: 'Weicheng',
          },
          {
            id: '610422',
            text: '三原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6232,
            gisBd09Lng: 108.948,
            gisGcj02Lat: 34.617,
            gisGcj02Lng: 108.941,
            stubGroupCnt: null,
            pinYin: 'Sanyuan',
          },
          {
            id: '610423',
            text: '泾阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5333,
            gisBd09Lng: 108.85,
            gisGcj02Lat: 34.527,
            gisGcj02Lng: 108.843,
            stubGroupCnt: null,
            pinYin: 'Jingyang',
          },
          {
            id: '610424',
            text: '乾　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5339,
            gisBd09Lng: 108.246,
            gisGcj02Lat: 34.5275,
            gisGcj02Lng: 108.24,
            stubGroupCnt: null,
            pinYin: 'Qianxian',
          },
          {
            id: '610425',
            text: '礼泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.4874,
            gisBd09Lng: 108.433,
            gisGcj02Lat: 34.4817,
            gisGcj02Lng: 108.426,
            stubGroupCnt: null,
            pinYin: 'Liquan',
          },
          {
            id: '610426',
            text: '永寿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.6982,
            gisBd09Lng: 108.147,
            gisGcj02Lat: 34.692,
            gisGcj02Lng: 108.141,
            stubGroupCnt: null,
            pinYin: 'Yongshou',
          },
          {
            id: '610427',
            text: '彬　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.0414,
            gisBd09Lng: 108.088,
            gisGcj02Lat: 35.0356,
            gisGcj02Lng: 108.082,
            stubGroupCnt: null,
            pinYin: 'Binxian',
          },
          {
            id: '610428',
            text: '长武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2115,
            gisBd09Lng: 107.806,
            gisGcj02Lat: 35.2057,
            gisGcj02Lng: 107.799,
            stubGroupCnt: null,
            pinYin: 'Changwu',
          },
          {
            id: '610429',
            text: '旬邑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1178,
            gisBd09Lng: 108.341,
            gisGcj02Lat: 35.1118,
            gisGcj02Lng: 108.335,
            stubGroupCnt: null,
            pinYin: 'Xunyi',
          },
          {
            id: '610430',
            text: '淳化县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8051,
            gisBd09Lng: 108.586,
            gisGcj02Lat: 34.7989,
            gisGcj02Lng: 108.58,
            stubGroupCnt: null,
            pinYin: 'Chunhua',
          },
          {
            id: '610431',
            text: '武功县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.2662,
            gisBd09Lng: 108.207,
            gisGcj02Lat: 34.2604,
            gisGcj02Lng: 108.201,
            stubGroupCnt: null,
            pinYin: 'Wugong',
          },
          {
            id: '610481',
            text: '兴平',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.3056,
            gisBd09Lng: 108.498,
            gisGcj02Lat: 34.2993,
            gisGcj02Lng: 108.491,
            stubGroupCnt: null,
            pinYin: 'Xingping',
          },
        ],
        gisBd09Lng: 108.716,
        text: '咸阳',
        gisBd09Lat: 34.3356,
        gisGcj02Lat: 34.3293,
      },
      {
        pinYin: 'Weinan',
        id: '610500',
        gisGcj02Lng: 109.51,
        children: [
          {
            id: '610501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5057,
            gisBd09Lng: 109.517,
            gisGcj02Lat: 34.5,
            gisGcj02Lng: 109.51,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610502',
            text: '临渭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5038,
            gisBd09Lng: 109.498,
            gisGcj02Lat: 34.4981,
            gisGcj02Lng: 109.491,
            stubGroupCnt: null,
            pinYin: 'Linwei',
          },
          {
            id: '610521',
            text: '华　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5186,
            gisBd09Lng: 109.779,
            gisGcj02Lat: 34.5125,
            gisGcj02Lng: 109.772,
            stubGroupCnt: null,
            pinYin: 'Huaxian',
          },
          {
            id: '610522',
            text: '潼关县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5504,
            gisBd09Lng: 110.252,
            gisGcj02Lat: 34.5445,
            gisGcj02Lng: 110.246,
            stubGroupCnt: null,
            pinYin: 'Tongguan',
          },
          {
            id: '610523',
            text: '大荔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.8016,
            gisBd09Lng: 109.949,
            gisGcj02Lat: 34.7955,
            gisGcj02Lng: 109.942,
            stubGroupCnt: null,
            pinYin: 'Dali',
          },
          {
            id: '610524',
            text: '合阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.2439,
            gisBd09Lng: 110.156,
            gisGcj02Lat: 35.2376,
            gisGcj02Lng: 110.15,
            stubGroupCnt: null,
            pinYin: 'Heyang',
          },
          {
            id: '610525',
            text: '澄城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1964,
            gisBd09Lng: 109.939,
            gisGcj02Lat: 35.1902,
            gisGcj02Lng: 109.933,
            stubGroupCnt: null,
            pinYin: 'Chengcheng',
          },
          {
            id: '610526',
            text: '蒲城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.9615,
            gisBd09Lng: 109.594,
            gisGcj02Lat: 34.9555,
            gisGcj02Lng: 109.587,
            stubGroupCnt: null,
            pinYin: 'Pucheng',
          },
          {
            id: '610527',
            text: '白水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.1827,
            gisBd09Lng: 109.598,
            gisGcj02Lat: 35.1767,
            gisGcj02Lng: 109.592,
            stubGroupCnt: null,
            pinYin: 'Baishui',
          },
          {
            id: '610528',
            text: '富平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.7575,
            gisBd09Lng: 109.187,
            gisGcj02Lat: 34.7513,
            gisGcj02Lng: 109.181,
            stubGroupCnt: null,
            pinYin: 'Fuping',
          },
          {
            id: '610581',
            text: '韩城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.4825,
            gisBd09Lng: 110.45,
            gisGcj02Lat: 35.4767,
            gisGcj02Lng: 110.443,
            stubGroupCnt: null,
            pinYin: 'Hancheng',
          },
          {
            id: '610582',
            text: '华阴',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.5719,
            gisBd09Lng: 110.099,
            gisGcj02Lat: 34.5662,
            gisGcj02Lng: 110.092,
            stubGroupCnt: null,
            pinYin: 'Huayin',
          },
        ],
        gisBd09Lng: 109.517,
        text: '渭南',
        gisBd09Lat: 34.5057,
        gisGcj02Lat: 34.5,
      },
      {
        pinYin: "Yan'an",
        id: '610600',
        gisGcj02Lng: 109.525,
        children: [
          {
            id: '610601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.6249,
            gisBd09Lng: 109.531,
            gisGcj02Lat: 36.6189,
            gisGcj02Lng: 109.525,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610602',
            text: '宝塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5971,
            gisBd09Lng: 109.5,
            gisGcj02Lat: 36.5914,
            gisGcj02Lng: 109.493,
            stubGroupCnt: null,
            pinYin: 'Baota',
          },
          {
            id: '610621',
            text: '延长县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5855,
            gisBd09Lng: 110.02,
            gisGcj02Lat: 36.5794,
            gisGcj02Lng: 110.013,
            stubGroupCnt: null,
            pinYin: 'Yanchang',
          },
          {
            id: '610622',
            text: '延川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8842,
            gisBd09Lng: 110.199,
            gisGcj02Lat: 36.8782,
            gisGcj02Lng: 110.192,
            stubGroupCnt: null,
            pinYin: 'Yanchuan',
          },
          {
            id: '610623',
            text: '子长县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.1488,
            gisBd09Lng: 109.682,
            gisGcj02Lat: 37.1425,
            gisGcj02Lng: 109.676,
            stubGroupCnt: null,
            pinYin: 'Zichang',
          },
          {
            id: '610624',
            text: '安塞县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8699,
            gisBd09Lng: 109.335,
            gisGcj02Lat: 36.8636,
            gisGcj02Lng: 109.329,
            stubGroupCnt: null,
            pinYin: 'Ansai',
          },
          {
            id: '610625',
            text: '志丹县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8283,
            gisBd09Lng: 108.776,
            gisGcj02Lat: 36.8225,
            gisGcj02Lng: 108.769,
            stubGroupCnt: null,
            pinYin: 'Zhidan',
          },
          {
            id: '610626',
            text: '吴旗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.9303,
            gisBd09Lng: 108.183,
            gisGcj02Lat: 36.9247,
            gisGcj02Lng: 108.176,
            stubGroupCnt: null,
            pinYin: 'Wuqi',
          },
          {
            id: '610627',
            text: '甘泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.2828,
            gisBd09Lng: 109.358,
            gisGcj02Lat: 36.2768,
            gisGcj02Lng: 109.352,
            stubGroupCnt: null,
            pinYin: 'Ganquan',
          },
          {
            id: '610628',
            text: '富　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.9938,
            gisBd09Lng: 109.386,
            gisGcj02Lat: 35.9881,
            gisGcj02Lng: 109.379,
            stubGroupCnt: null,
            pinYin: 'Fuxian',
          },
          {
            id: '610629',
            text: '洛川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.7684,
            gisBd09Lng: 109.44,
            gisGcj02Lat: 35.7621,
            gisGcj02Lng: 109.433,
            stubGroupCnt: null,
            pinYin: 'Luochuan',
          },
          {
            id: '610630',
            text: '宜川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.0567,
            gisBd09Lng: 110.176,
            gisGcj02Lat: 36.0504,
            gisGcj02Lng: 110.17,
            stubGroupCnt: null,
            pinYin: 'Yichuan',
          },
          {
            id: '610631',
            text: '黄龙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5904,
            gisBd09Lng: 109.847,
            gisGcj02Lat: 35.5846,
            gisGcj02Lng: 109.841,
            stubGroupCnt: null,
            pinYin: 'Huanglong',
          },
          {
            id: '610632',
            text: '黄陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 35.5847,
            gisBd09Lng: 109.27,
            gisGcj02Lat: 35.579,
            gisGcj02Lng: 109.263,
            stubGroupCnt: null,
            pinYin: 'Huangling',
          },
        ],
        gisBd09Lng: 109.531,
        text: '延安',
        gisBd09Lat: 36.6249,
        gisGcj02Lat: 36.6189,
      },
      {
        pinYin: 'Hanzhong',
        id: '610700',
        gisGcj02Lng: 107.024,
        children: [
          {
            id: '610701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0731,
            gisBd09Lng: 107.03,
            gisGcj02Lat: 33.0669,
            gisGcj02Lng: 107.024,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610702',
            text: '汉台区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.074,
            gisBd09Lng: 107.039,
            gisGcj02Lat: 33.0677,
            gisGcj02Lng: 107.032,
            stubGroupCnt: null,
            pinYin: 'Hantai',
          },
          {
            id: '610721',
            text: '南郑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0057,
            gisBd09Lng: 106.944,
            gisGcj02Lat: 32.9995,
            gisGcj02Lng: 106.938,
            stubGroupCnt: null,
            pinYin: 'Nanzheng',
          },
          {
            id: '610722',
            text: '城固县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1625,
            gisBd09Lng: 107.341,
            gisGcj02Lat: 33.1568,
            gisGcj02Lng: 107.334,
            stubGroupCnt: null,
            pinYin: 'Chenggu',
          },
          {
            id: '610723',
            text: '洋　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2291,
            gisBd09Lng: 107.553,
            gisGcj02Lat: 33.2231,
            gisGcj02Lng: 107.547,
            stubGroupCnt: null,
            pinYin: 'Yangxian',
          },
          {
            id: '610724',
            text: '西乡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9894,
            gisBd09Lng: 107.775,
            gisGcj02Lat: 32.9831,
            gisGcj02Lng: 107.768,
            stubGroupCnt: null,
            pinYin: 'Xixiang',
          },
          {
            id: '610725',
            text: '勉　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.1597,
            gisBd09Lng: 106.679,
            gisGcj02Lat: 33.1534,
            gisGcj02Lng: 106.672,
            stubGroupCnt: null,
            pinYin: 'Mianxian',
          },
          {
            id: '610726',
            text: '宁强县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8351,
            gisBd09Lng: 106.264,
            gisGcj02Lat: 32.8294,
            gisGcj02Lng: 106.258,
            stubGroupCnt: null,
            pinYin: 'Ningqiang',
          },
          {
            id: '610727',
            text: '略阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3335,
            gisBd09Lng: 106.164,
            gisGcj02Lat: 33.3277,
            gisGcj02Lng: 106.157,
            stubGroupCnt: null,
            pinYin: 'Lueyang',
          },
          {
            id: '610728',
            text: '镇巴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.543,
            gisBd09Lng: 107.902,
            gisGcj02Lat: 32.5368,
            gisGcj02Lng: 107.896,
            stubGroupCnt: null,
            pinYin: 'Zhenba',
          },
          {
            id: '610729',
            text: '留坝县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6237,
            gisBd09Lng: 106.928,
            gisGcj02Lat: 33.6174,
            gisGcj02Lng: 106.921,
            stubGroupCnt: null,
            pinYin: 'Liuba',
          },
          {
            id: '610730',
            text: '佛坪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5308,
            gisBd09Lng: 107.999,
            gisGcj02Lat: 33.5245,
            gisGcj02Lng: 107.992,
            stubGroupCnt: null,
            pinYin: 'Foping',
          },
        ],
        gisBd09Lng: 107.03,
        text: '汉中',
        gisBd09Lat: 33.0731,
        gisGcj02Lat: 33.0669,
      },
      {
        pinYin: 'Yulin',
        id: '610800',
        gisGcj02Lng: 109.735,
        children: [
          {
            id: '610801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2909,
            gisBd09Lng: 109.742,
            gisGcj02Lat: 38.2853,
            gisGcj02Lng: 109.735,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610802',
            text: '榆阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.2825,
            gisBd09Lng: 109.728,
            gisGcj02Lat: 38.2767,
            gisGcj02Lng: 109.721,
            stubGroupCnt: null,
            pinYin: 'Yuyang',
          },
          {
            id: '610821',
            text: '神木县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.8485,
            gisBd09Lng: 110.506,
            gisGcj02Lat: 38.8423,
            gisGcj02Lng: 110.5,
            stubGroupCnt: null,
            pinYin: 'Shenmu',
          },
          {
            id: '610822',
            text: '府谷县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0342,
            gisBd09Lng: 111.075,
            gisGcj02Lat: 39.0284,
            gisGcj02Lng: 111.068,
            stubGroupCnt: null,
            pinYin: 'Fugu',
          },
          {
            id: '610823',
            text: '横山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.9651,
            gisBd09Lng: 109.301,
            gisGcj02Lat: 37.959,
            gisGcj02Lng: 109.294,
            stubGroupCnt: null,
            pinYin: 'Hengshan',
          },
          {
            id: '610824',
            text: '靖边县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6049,
            gisBd09Lng: 108.803,
            gisGcj02Lat: 37.5991,
            gisGcj02Lng: 108.797,
            stubGroupCnt: null,
            pinYin: 'Jingbian',
          },
          {
            id: '610825',
            text: '定边县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6005,
            gisBd09Lng: 107.609,
            gisGcj02Lat: 37.5946,
            gisGcj02Lng: 107.602,
            stubGroupCnt: null,
            pinYin: 'Dingbian',
          },
          {
            id: '610826',
            text: '绥德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.5092,
            gisBd09Lng: 110.271,
            gisGcj02Lat: 37.503,
            gisGcj02Lng: 110.265,
            stubGroupCnt: null,
            pinYin: 'Suide',
          },
          {
            id: '610827',
            text: '米脂县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.7612,
            gisBd09Lng: 110.192,
            gisGcj02Lat: 37.7551,
            gisGcj02Lng: 110.186,
            stubGroupCnt: null,
            pinYin: 'Mizhi',
          },
          {
            id: '610828',
            text: '佳　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.0258,
            gisBd09Lng: 110.499,
            gisGcj02Lat: 38.0197,
            gisGcj02Lng: 110.492,
            stubGroupCnt: null,
            pinYin: 'Jiaxian',
          },
          {
            id: '610829',
            text: '吴堡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.4583,
            gisBd09Lng: 110.748,
            gisGcj02Lat: 37.4521,
            gisGcj02Lng: 110.742,
            stubGroupCnt: null,
            pinYin: 'Wubu',
          },
          {
            id: '610830',
            text: '清涧县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0949,
            gisBd09Lng: 110.129,
            gisGcj02Lat: 37.089,
            gisGcj02Lng: 110.123,
            stubGroupCnt: null,
            pinYin: 'Qingjian',
          },
          {
            id: '610831',
            text: '子洲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6172,
            gisBd09Lng: 110.041,
            gisGcj02Lat: 37.6109,
            gisGcj02Lng: 110.034,
            stubGroupCnt: null,
            pinYin: 'Zizhou',
          },
        ],
        gisBd09Lng: 109.742,
        text: '榆林',
        gisBd09Lat: 38.2909,
        gisGcj02Lat: 38.2853,
      },
      {
        pinYin: 'Ankang',
        id: '610900',
        gisGcj02Lng: 109.03,
        children: [
          {
            id: '610901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6907,
            gisBd09Lng: 109.037,
            gisGcj02Lat: 32.685,
            gisGcj02Lng: 109.03,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '610902',
            text: '汉滨区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7013,
            gisBd09Lng: 109.035,
            gisGcj02Lat: 32.6956,
            gisGcj02Lng: 109.028,
            stubGroupCnt: null,
            pinYin: 'Hanbin',
          },
          {
            id: '610921',
            text: '汉阴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.8997,
            gisBd09Lng: 108.516,
            gisGcj02Lat: 32.8936,
            gisGcj02Lng: 108.509,
            stubGroupCnt: null,
            pinYin: 'Hanyin',
          },
          {
            id: '610922',
            text: '石泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.0448,
            gisBd09Lng: 108.256,
            gisGcj02Lat: 33.0385,
            gisGcj02Lng: 108.25,
            stubGroupCnt: null,
            pinYin: 'Shiquan',
          },
          {
            id: '610923',
            text: '宁陕县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3163,
            gisBd09Lng: 108.321,
            gisGcj02Lat: 33.3106,
            gisGcj02Lng: 108.314,
            stubGroupCnt: null,
            pinYin: 'Ningshan',
          },
          {
            id: '610924',
            text: '紫阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5261,
            gisBd09Lng: 108.542,
            gisGcj02Lat: 32.5204,
            gisGcj02Lng: 108.535,
            stubGroupCnt: null,
            pinYin: 'Ziyang',
          },
          {
            id: '610925',
            text: '岚皋县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3129,
            gisBd09Lng: 108.908,
            gisGcj02Lat: 32.3072,
            gisGcj02Lng: 108.901,
            stubGroupCnt: null,
            pinYin: 'Langao',
          },
          {
            id: '610926',
            text: '平利县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.395,
            gisBd09Lng: 109.369,
            gisGcj02Lat: 32.3892,
            gisGcj02Lng: 109.363,
            stubGroupCnt: null,
            pinYin: 'Pingli',
          },
          {
            id: '610927',
            text: '镇坪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8899,
            gisBd09Lng: 109.534,
            gisGcj02Lat: 31.884,
            gisGcj02Lng: 109.528,
            stubGroupCnt: null,
            pinYin: 'Zhenping',
          },
          {
            id: '610928',
            text: '旬阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.84,
            gisBd09Lng: 109.372,
            gisGcj02Lat: 32.8342,
            gisGcj02Lng: 109.366,
            stubGroupCnt: null,
            pinYin: 'Xunyang',
          },
          {
            id: '610929',
            text: '白河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.815,
            gisBd09Lng: 110.118,
            gisGcj02Lat: 32.8093,
            gisGcj02Lng: 110.111,
            stubGroupCnt: null,
            pinYin: 'Baihe',
          },
        ],
        gisBd09Lng: 109.037,
        text: '安康',
        gisBd09Lat: 32.6907,
        gisGcj02Lat: 32.685,
      },
      {
        pinYin: 'Shangluo',
        id: '611000',
        gisGcj02Lng: 109.941,
        children: [
          {
            id: '611001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8766,
            gisBd09Lng: 109.947,
            gisGcj02Lat: 33.8704,
            gisGcj02Lng: 109.941,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '611002',
            text: '商州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.8692,
            gisBd09Lng: 109.948,
            gisGcj02Lat: 33.863,
            gisGcj02Lng: 109.942,
            stubGroupCnt: null,
            pinYin: 'Shangzhou',
          },
          {
            id: '611021',
            text: '洛南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 34.0972,
            gisBd09Lng: 110.156,
            gisGcj02Lat: 34.0909,
            gisGcj02Lng: 110.149,
            stubGroupCnt: null,
            pinYin: 'Luonan',
          },
          {
            id: '611022',
            text: '丹凤县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.7009,
            gisBd09Lng: 110.334,
            gisGcj02Lat: 33.6951,
            gisGcj02Lng: 110.328,
            stubGroupCnt: null,
            pinYin: 'Danfeng',
          },
          {
            id: '611023',
            text: '商南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5371,
            gisBd09Lng: 110.89,
            gisGcj02Lat: 33.5308,
            gisGcj02Lng: 110.883,
            stubGroupCnt: null,
            pinYin: 'Shangnan',
          },
          {
            id: '611024',
            text: '山阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5383,
            gisBd09Lng: 109.89,
            gisGcj02Lat: 33.5324,
            gisGcj02Lng: 109.884,
            stubGroupCnt: null,
            pinYin: 'Shanyang',
          },
          {
            id: '611025',
            text: '镇安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.4291,
            gisBd09Lng: 109.16,
            gisGcj02Lat: 33.4234,
            gisGcj02Lng: 109.153,
            stubGroupCnt: null,
            pinYin: "Zhen'an",
          },
          {
            id: '611026',
            text: '柞水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.6921,
            gisBd09Lng: 109.12,
            gisGcj02Lat: 33.6862,
            gisGcj02Lng: 109.114,
            stubGroupCnt: null,
            pinYin: 'Zhashui',
          },
        ],
        gisBd09Lng: 109.947,
        text: '商洛',
        gisBd09Lat: 33.8766,
        gisGcj02Lat: 33.8704,
      },
    ],
  },
  {
    text: '新疆',
    children: [
      {
        pinYin: 'Urumqi',
        id: '650100',
        gisGcj02Lng: 87.6182,
        children: [
          {
            id: '650101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8419,
            gisBd09Lng: 87.5964,
            gisGcj02Lat: 43.8357,
            gisGcj02Lng: 87.59,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '650102',
            text: '天山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.7959,
            gisBd09Lng: 87.6388,
            gisGcj02Lat: 43.79,
            gisGcj02Lng: 87.6323,
            stubGroupCnt: null,
            pinYin: 'Tianshan',
          },
          {
            id: '650103',
            text: '沙依巴克区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8072,
            gisBd09Lng: 87.6079,
            gisGcj02Lat: 43.8009,
            gisGcj02Lng: 87.6015,
            stubGroupCnt: null,
            pinYin: 'Shayibake',
          },
          {
            id: '650104',
            text: '新市区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8499,
            gisBd09Lng: 87.5835,
            gisGcj02Lat: 43.8438,
            gisGcj02Lng: 87.577,
            stubGroupCnt: null,
            pinYin: 'Xinshi',
          },
          {
            id: '650105',
            text: '水磨沟区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8389,
            gisBd09Lng: 87.6482,
            gisGcj02Lat: 43.833,
            gisGcj02Lng: 87.6416,
            stubGroupCnt: null,
            pinYin: 'Shuimogou',
          },
          {
            id: '650106',
            text: '头屯河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8831,
            gisBd09Lng: 87.4361,
            gisGcj02Lat: 43.8774,
            gisGcj02Lng: 87.4295,
            stubGroupCnt: null,
            pinYin: 'Toutunhe',
          },
          {
            id: '650107',
            text: '达坂城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.37,
            gisBd09Lng: 88.3184,
            gisGcj02Lat: 43.3638,
            gisGcj02Lng: 88.312,
            stubGroupCnt: null,
            pinYin: 'Dabancheng',
          },
          {
            id: '650108',
            text: '东山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.9786,
            gisBd09Lng: 87.6627,
            gisGcj02Lat: 43.9729,
            gisGcj02Lng: 87.6561,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '650121',
            text: '乌鲁木齐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.4771,
            gisBd09Lng: 87.4167,
            gisGcj02Lat: 43.4713,
            gisGcj02Lng: 87.41,
            stubGroupCnt: null,
            pinYin: 'Wulumuqi',
          },
        ],
        gisBd09Lng: 87.6246,
        text: '乌鲁木齐',
        gisBd09Lat: 43.8318,
        gisGcj02Lat: 43.8257,
      },
      {
        pinYin: 'Karamay',
        id: '650200',
        gisGcj02Lng: 84.8917,
        children: [
          {
            id: '650201',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.606,
            gisBd09Lng: 84.8792,
            gisGcj02Lat: 45.6,
            gisGcj02Lng: 84.8727,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '650202',
            text: '独山子区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3335,
            gisBd09Lng: 84.8945,
            gisGcj02Lat: 44.3277,
            gisGcj02Lng: 84.8879,
            stubGroupCnt: null,
            pinYin: 'Dushanzi',
          },
          {
            id: '650203',
            text: '克拉玛依区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.6086,
            gisBd09Lng: 84.8753,
            gisGcj02Lat: 45.6026,
            gisGcj02Lng: 84.8688,
            stubGroupCnt: null,
            pinYin: 'Kelamayi',
          },
          {
            id: '650204',
            text: '白碱滩区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.6933,
            gisBd09Lng: 85.1406,
            gisGcj02Lat: 45.6875,
            gisGcj02Lng: 85.134,
            stubGroupCnt: null,
            pinYin: 'Baijiantan',
          },
          {
            id: '650205',
            text: '乌尔禾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.0956,
            gisBd09Lng: 85.7023,
            gisGcj02Lat: 46.0894,
            gisGcj02Lng: 85.6959,
            stubGroupCnt: null,
            pinYin: 'Wuerhe',
          },
        ],
        gisBd09Lng: 84.8983,
        text: '克拉玛依',
        gisBd09Lat: 45.586,
        gisGcj02Lat: 45.5802,
      },
      {
        pinYin: 'Turpan',
        id: '652100',
        gisGcj02Lng: 89.1869,
        children: [
          {
            id: '652101',
            text: '吐鲁番',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.949,
            gisBd09Lng: 89.1946,
            gisGcj02Lat: 42.943,
            gisGcj02Lng: 89.1881,
            stubGroupCnt: null,
            pinYin: 'Tulufan',
          },
          {
            id: '652122',
            text: '鄯善县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8755,
            gisBd09Lng: 90.2228,
            gisGcj02Lat: 42.8694,
            gisGcj02Lng: 90.2163,
            stubGroupCnt: null,
            pinYin: 'Shanshan',
          },
          {
            id: '652123',
            text: '托克逊县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.7976,
            gisBd09Lng: 88.6626,
            gisGcj02Lat: 42.7916,
            gisGcj02Lng: 88.6562,
            stubGroupCnt: null,
            pinYin: 'Tuokexun',
          },
        ],
        gisBd09Lng: 89.1934,
        text: '吐鲁番地区',
        gisBd09Lat: 42.9487,
        gisGcj02Lat: 42.9427,
      },
      {
        pinYin: 'Hami',
        id: '652200',
        gisGcj02Lng: 93.5181,
        children: [
          {
            id: '652201',
            text: '哈密',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.8304,
            gisBd09Lng: 93.5261,
            gisGcj02Lat: 42.8245,
            gisGcj02Lng: 93.5195,
            stubGroupCnt: null,
            pinYin: 'Hami',
          },
          {
            id: '652222',
            text: '巴里坤哈萨克自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.6056,
            gisBd09Lng: 93.0201,
            gisGcj02Lat: 43.5994,
            gisGcj02Lng: 93.0137,
            stubGroupCnt: null,
            pinYin: 'Balikun',
          },
          {
            id: '652223',
            text: '伊吾县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.2602,
            gisBd09Lng: 94.7038,
            gisGcj02Lat: 43.254,
            gisGcj02Lng: 94.6974,
            stubGroupCnt: null,
            pinYin: 'Yiwu',
          },
        ],
        gisBd09Lng: 93.5246,
        text: '哈密地区',
        gisBd09Lat: 42.8331,
        gisGcj02Lat: 42.8273,
      },
      {
        pinYin: 'Changji',
        id: '652300',
        gisGcj02Lng: 87.3111,
        children: [
          {
            id: '652301',
            text: '昌吉',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0168,
            gisBd09Lng: 87.316,
            gisGcj02Lat: 44.011,
            gisGcj02Lng: 87.3094,
            stubGroupCnt: null,
            pinYin: 'Changji',
          },
          {
            id: '652302',
            text: '阜康',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.1629,
            gisBd09Lng: 87.9963,
            gisGcj02Lat: 44.1569,
            gisGcj02Lng: 87.9898,
            stubGroupCnt: null,
            pinYin: 'Fukang',
          },
          {
            id: '652303',
            text: '米泉',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.9688,
            gisBd09Lng: 87.6603,
            gisGcj02Lat: 43.963,
            gisGcj02Lng: 87.6537,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '652323',
            text: '呼图壁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.198,
            gisBd09Lng: 86.9095,
            gisGcj02Lat: 44.1919,
            gisGcj02Lng: 86.903,
            stubGroupCnt: null,
            pinYin: 'Hutubi',
          },
          {
            id: '652324',
            text: '玛纳斯县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3104,
            gisBd09Lng: 86.2214,
            gisGcj02Lat: 44.3046,
            gisGcj02Lng: 86.2147,
            stubGroupCnt: null,
            pinYin: 'Manasi',
          },
          {
            id: '652325',
            text: '奇台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0279,
            gisBd09Lng: 89.6022,
            gisGcj02Lat: 44.0221,
            gisGcj02Lng: 89.5956,
            stubGroupCnt: null,
            pinYin: 'Qitai',
          },
          {
            id: '652327',
            text: '吉木萨尔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0063,
            gisBd09Lng: 89.1827,
            gisGcj02Lat: 44.0001,
            gisGcj02Lng: 89.1763,
            stubGroupCnt: null,
            pinYin: 'Jimusaer',
          },
          {
            id: '652328',
            text: '木垒哈萨克自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8404,
            gisBd09Lng: 90.2886,
            gisGcj02Lat: 43.8346,
            gisGcj02Lng: 90.282,
            stubGroupCnt: null,
            pinYin: 'Mulei',
          },
        ],
        gisBd09Lng: 87.3177,
        text: '昌吉回族自治州',
        gisBd09Lat: 44.0178,
        gisGcj02Lat: 44.012,
      },
      {
        pinYin: 'Bortala',
        id: '652700',
        gisGcj02Lng: 82.069,
        children: [
          {
            id: '652701',
            text: '博乐',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.8605,
            gisBd09Lng: 82.0602,
            gisGcj02Lat: 44.8545,
            gisGcj02Lng: 82.0537,
            stubGroupCnt: null,
            pinYin: 'Bole',
          },
          {
            id: '652722',
            text: '精河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.6065,
            gisBd09Lng: 82.9021,
            gisGcj02Lat: 44.6004,
            gisGcj02Lng: 82.8956,
            stubGroupCnt: null,
            pinYin: 'Jinghe',
          },
          {
            id: '652723',
            text: '温泉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.9749,
            gisBd09Lng: 81.0313,
            gisGcj02Lat: 44.9688,
            gisGcj02Lng: 81.0248,
            stubGroupCnt: null,
            pinYin: 'Wenquan',
          },
        ],
        gisBd09Lng: 82.0754,
        text: '博尔塔拉蒙古自治州',
        gisBd09Lat: 44.9123,
        gisGcj02Lat: 44.9061,
      },
      {
        pinYin: 'Bayingol',
        id: '652800',
        gisGcj02Lng: 86.147,
        children: [
          {
            id: '652801',
            text: '库尔勒',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7322,
            gisBd09Lng: 86.1838,
            gisGcj02Lat: 41.726,
            gisGcj02Lng: 86.1774,
            stubGroupCnt: null,
            pinYin: 'Kuerle',
          },
          {
            id: '652822',
            text: '轮台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7838,
            gisBd09Lng: 84.2574,
            gisGcj02Lat: 41.7776,
            gisGcj02Lng: 84.251,
            stubGroupCnt: null,
            pinYin: 'Luntai',
          },
          {
            id: '652823',
            text: '尉犁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.3499,
            gisBd09Lng: 86.2702,
            gisGcj02Lat: 41.3438,
            gisGcj02Lng: 86.2638,
            stubGroupCnt: null,
            pinYin: 'Yuli',
          },
          {
            id: '652824',
            text: '若羌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.0292,
            gisBd09Lng: 88.1727,
            gisGcj02Lat: 39.0233,
            gisGcj02Lng: 88.1662,
            stubGroupCnt: null,
            pinYin: 'Ruoqiang',
          },
          {
            id: '652825',
            text: '且末县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1513,
            gisBd09Lng: 85.5368,
            gisGcj02Lat: 38.1453,
            gisGcj02Lng: 85.5303,
            stubGroupCnt: null,
            pinYin: 'Qiemo',
          },
          {
            id: '652826',
            text: '焉耆回族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.0692,
            gisBd09Lng: 86.5782,
            gisGcj02Lat: 42.0635,
            gisGcj02Lng: 86.5716,
            stubGroupCnt: null,
            pinYin: 'Yanqi',
          },
          {
            id: '652827',
            text: '和静县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.3296,
            gisBd09Lng: 86.3925,
            gisGcj02Lat: 42.3234,
            gisGcj02Lng: 86.3861,
            stubGroupCnt: null,
            pinYin: 'Hejing',
          },
          {
            id: '652828',
            text: '和硕县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 42.2741,
            gisBd09Lng: 86.8707,
            gisGcj02Lat: 42.2679,
            gisGcj02Lng: 86.8642,
            stubGroupCnt: null,
            pinYin: 'Heshuo',
          },
          {
            id: '652829',
            text: '博湖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.9864,
            gisBd09Lng: 86.6395,
            gisGcj02Lat: 41.9801,
            gisGcj02Lng: 86.6331,
            stubGroupCnt: null,
            pinYin: 'Bohu',
          },
        ],
        gisBd09Lng: 86.1534,
        text: '巴音郭楞蒙古自治州',
        gisBd09Lat: 41.7705,
        gisGcj02Lat: 41.7643,
      },
      {
        pinYin: 'Aksu',
        id: '652900',
        gisGcj02Lng: 80.2599,
        children: [
          {
            id: '652901',
            text: '阿克苏',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.1742,
            gisBd09Lng: 80.2722,
            gisGcj02Lat: 41.168,
            gisGcj02Lng: 80.2658,
            stubGroupCnt: null,
            pinYin: 'Akesu',
          },
          {
            id: '652922',
            text: '温宿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2828,
            gisBd09Lng: 80.2468,
            gisGcj02Lat: 41.2769,
            gisGcj02Lng: 80.2402,
            stubGroupCnt: null,
            pinYin: 'Wensu',
          },
          {
            id: '652923',
            text: '库车县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.7233,
            gisBd09Lng: 82.9671,
            gisGcj02Lat: 41.7174,
            gisGcj02Lng: 82.9605,
            stubGroupCnt: null,
            pinYin: 'Kuche',
          },
          {
            id: '652924',
            text: '沙雅县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.2277,
            gisBd09Lng: 82.7891,
            gisGcj02Lat: 41.2215,
            gisGcj02Lng: 82.7827,
            stubGroupCnt: null,
            pinYin: 'Shaya',
          },
          {
            id: '652925',
            text: '新和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.5535,
            gisBd09Lng: 82.6162,
            gisGcj02Lat: 41.5477,
            gisGcj02Lng: 82.6096,
            stubGroupCnt: null,
            pinYin: 'Xinhe',
          },
          {
            id: '652926',
            text: '拜城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.8026,
            gisBd09Lng: 81.8792,
            gisGcj02Lat: 41.7966,
            gisGcj02Lng: 81.8726,
            stubGroupCnt: null,
            pinYin: 'Baicheng',
          },
          {
            id: '652927',
            text: '乌什县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 41.221,
            gisBd09Lng: 79.2322,
            gisGcj02Lat: 41.2149,
            gisGcj02Lng: 79.2257,
            stubGroupCnt: null,
            pinYin: 'Wushi',
          },
          {
            id: '652928',
            text: '阿瓦提县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.6505,
            gisBd09Lng: 80.3795,
            gisGcj02Lat: 40.6445,
            gisGcj02Lng: 80.373,
            stubGroupCnt: null,
            pinYin: 'Awati',
          },
          {
            id: '652929',
            text: '柯坪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5142,
            gisBd09Lng: 79.0547,
            gisGcj02Lat: 40.5082,
            gisGcj02Lng: 79.0482,
            stubGroupCnt: null,
            pinYin: 'Keping',
          },
        ],
        gisBd09Lng: 80.2664,
        text: '阿克苏地区',
        gisBd09Lat: 41.1746,
        gisGcj02Lat: 41.1685,
      },
      {
        pinYin: 'Kizilsu',
        id: '653000',
        gisGcj02Lng: 76.1672,
        children: [
          {
            id: '653001',
            text: '阿图什',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7221,
            gisBd09Lng: 76.1755,
            gisGcj02Lat: 39.7161,
            gisGcj02Lng: 76.169,
            stubGroupCnt: null,
            pinYin: 'Atushi',
          },
          {
            id: '653022',
            text: '阿克陶县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.1541,
            gisBd09Lng: 75.9549,
            gisGcj02Lat: 39.1479,
            gisGcj02Lng: 75.9485,
            stubGroupCnt: null,
            pinYin: 'Aketao',
          },
          {
            id: '653023',
            text: '阿合奇县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.9431,
            gisBd09Lng: 78.4539,
            gisGcj02Lat: 40.9371,
            gisGcj02Lng: 78.4473,
            stubGroupCnt: null,
            pinYin: 'Aheqi',
          },
          {
            id: '653024',
            text: '乌恰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7252,
            gisBd09Lng: 75.2674,
            gisGcj02Lat: 39.7191,
            gisGcj02Lng: 75.261,
            stubGroupCnt: null,
            pinYin: 'Wuqia',
          },
        ],
        gisBd09Lng: 76.1737,
        text: '克孜勒苏柯尔克孜自治州',
        gisBd09Lat: 39.721,
        gisGcj02Lat: 39.715,
      },
      {
        pinYin: 'Kashgar',
        id: '653100',
        gisGcj02Lng: 75.9902,
        children: [
          {
            id: '653101',
            text: '喀什',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4735,
            gisBd09Lng: 76.002,
            gisGcj02Lat: 39.4676,
            gisGcj02Lng: 75.9955,
            stubGroupCnt: null,
            pinYin: 'Kashi',
          },
          {
            id: '653121',
            text: '疏附县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.3815,
            gisBd09Lng: 75.8717,
            gisGcj02Lat: 39.3754,
            gisGcj02Lng: 75.8652,
            stubGroupCnt: null,
            pinYin: 'Shufu',
          },
          {
            id: '653122',
            text: '疏勒县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4075,
            gisBd09Lng: 76.0554,
            gisGcj02Lat: 39.4015,
            gisGcj02Lng: 76.0489,
            stubGroupCnt: null,
            pinYin: 'Shule',
          },
          {
            id: '653123',
            text: '英吉沙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.9358,
            gisBd09Lng: 76.1825,
            gisGcj02Lat: 38.9298,
            gisGcj02Lng: 76.1761,
            stubGroupCnt: null,
            pinYin: 'Yingjisha',
          },
          {
            id: '653124',
            text: '泽普县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.1985,
            gisBd09Lng: 77.2787,
            gisGcj02Lat: 38.1922,
            gisGcj02Lng: 77.2722,
            stubGroupCnt: null,
            pinYin: 'Zepu',
          },
          {
            id: '653125',
            text: '莎车县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.4205,
            gisBd09Lng: 77.2543,
            gisGcj02Lat: 38.4145,
            gisGcj02Lng: 77.2477,
            stubGroupCnt: null,
            pinYin: 'Shache',
          },
          {
            id: '653126',
            text: '叶城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.8891,
            gisBd09Lng: 77.4205,
            gisGcj02Lat: 37.883,
            gisGcj02Lng: 77.4141,
            stubGroupCnt: null,
            pinYin: 'Yecheng',
          },
          {
            id: '653127',
            text: '麦盖提县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 38.91,
            gisBd09Lng: 77.6582,
            gisGcj02Lat: 38.9038,
            gisGcj02Lng: 77.6518,
            stubGroupCnt: null,
            pinYin: 'Maigaiti',
          },
          {
            id: '653128',
            text: '岳普湖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.2397,
            gisBd09Lng: 76.7841,
            gisGcj02Lat: 39.2336,
            gisGcj02Lng: 76.7776,
            stubGroupCnt: null,
            pinYin: 'Yuepuhu',
          },
          {
            id: '653129',
            text: '伽师县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.4939,
            gisBd09Lng: 76.7302,
            gisGcj02Lat: 39.4881,
            gisGcj02Lng: 76.7236,
            stubGroupCnt: null,
            pinYin: 'Jiashi',
          },
          {
            id: '653130',
            text: '巴楚县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.7908,
            gisBd09Lng: 78.5566,
            gisGcj02Lat: 39.785,
            gisGcj02Lng: 78.55,
            stubGroupCnt: null,
            pinYin: 'Bachu',
          },
          {
            id: '653131',
            text: '塔什库尔干塔吉克自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.779,
            gisBd09Lng: 75.2312,
            gisGcj02Lat: 37.7729,
            gisGcj02Lng: 75.2248,
            stubGroupCnt: null,
            pinYin: 'Tashikuergantajike',
          },
        ],
        gisBd09Lng: 75.9967,
        text: '喀什地区',
        gisBd09Lat: 39.4763,
        gisGcj02Lat: 39.4703,
      },
      {
        pinYin: 'Hotan',
        id: '653200',
        gisGcj02Lng: 79.9248,
        children: [
          {
            id: '653201',
            text: '和田',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.5786,
            gisBd09Lng: 79.9078,
            gisGcj02Lat: 36.5725,
            gisGcj02Lng: 79.9014,
            stubGroupCnt: null,
            pinYin: 'Hetianshi',
          },
          {
            id: '653221',
            text: '和田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.573,
            gisBd09Lng: 79.917,
            gisGcj02Lat: 36.5668,
            gisGcj02Lng: 79.9106,
            stubGroupCnt: null,
            pinYin: 'Hetianxian',
          },
          {
            id: '653222',
            text: '墨玉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.2832,
            gisBd09Lng: 79.7369,
            gisGcj02Lat: 37.2774,
            gisGcj02Lng: 79.7303,
            stubGroupCnt: null,
            pinYin: 'Moyu',
          },
          {
            id: '653223',
            text: '皮山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.6274,
            gisBd09Lng: 78.2913,
            gisGcj02Lat: 37.6215,
            gisGcj02Lng: 78.2847,
            stubGroupCnt: null,
            pinYin: 'Pishan',
          },
          {
            id: '653224',
            text: '洛浦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0799,
            gisBd09Lng: 80.1962,
            gisGcj02Lat: 37.0739,
            gisGcj02Lng: 80.1897,
            stubGroupCnt: null,
            pinYin: 'Luopu',
          },
          {
            id: '653225',
            text: '策勒县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0034,
            gisBd09Lng: 80.8155,
            gisGcj02Lat: 36.9976,
            gisGcj02Lng: 80.8089,
            stubGroupCnt: null,
            pinYin: 'Cele',
          },
          {
            id: '653226',
            text: '于田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 36.8629,
            gisBd09Lng: 81.6859,
            gisGcj02Lat: 36.857,
            gisGcj02Lng: 81.6793,
            stubGroupCnt: null,
            pinYin: 'Yutian',
          },
          {
            id: '653227',
            text: '民丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 37.0706,
            gisBd09Lng: 82.7044,
            gisGcj02Lat: 37.0644,
            gisGcj02Lng: 82.698,
            stubGroupCnt: null,
            pinYin: 'Minfeng',
          },
        ],
        gisBd09Lng: 79.9313,
        text: '和田地区',
        gisBd09Lat: 37.1219,
        gisGcj02Lat: 37.1156,
      },
      {
        pinYin: 'Ili',
        id: '654000',
        gisGcj02Lng: 81.3253,
        children: [
          {
            id: '654002',
            text: '伊宁',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.9831,
            gisBd09Lng: 81.5315,
            gisGcj02Lat: 43.9772,
            gisGcj02Lng: 81.5249,
            stubGroupCnt: null,
            pinYin: 'Yining',
          },
          {
            id: '654003',
            text: '奎屯',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.4327,
            gisBd09Lng: 84.908,
            gisGcj02Lat: 44.4269,
            gisGcj02Lng: 84.9014,
            stubGroupCnt: null,
            pinYin: 'Kuitun',
          },
          {
            id: '654021',
            text: '伊宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.983,
            gisBd09Lng: 81.5343,
            gisGcj02Lat: 43.9772,
            gisGcj02Lng: 81.5277,
            stubGroupCnt: null,
            pinYin: 'Yining',
          },
          {
            id: '654022',
            text: '察布查尔锡伯自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.8467,
            gisBd09Lng: 81.1579,
            gisGcj02Lat: 43.8407,
            gisGcj02Lng: 81.1514,
            stubGroupCnt: null,
            pinYin: 'Chabuchaerxibo',
          },
          {
            id: '654023',
            text: '霍城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.0623,
            gisBd09Lng: 80.8863,
            gisGcj02Lat: 44.0561,
            gisGcj02Lng: 80.8799,
            stubGroupCnt: null,
            pinYin: 'Huocheng',
          },
          {
            id: '654024',
            text: '巩留县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.4892,
            gisBd09Lng: 82.2394,
            gisGcj02Lat: 43.4832,
            gisGcj02Lng: 82.2329,
            stubGroupCnt: null,
            pinYin: 'Gongliu',
          },
          {
            id: '654025',
            text: '新源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.4363,
            gisBd09Lng: 83.2665,
            gisGcj02Lat: 43.4302,
            gisGcj02Lng: 83.2601,
            stubGroupCnt: null,
            pinYin: 'Xinyuan',
          },
          {
            id: '654026',
            text: '昭苏县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.1649,
            gisBd09Lng: 81.137,
            gisGcj02Lat: 43.1587,
            gisGcj02Lng: 81.1307,
            stubGroupCnt: null,
            pinYin: 'Zhaosu',
          },
          {
            id: '654027',
            text: '特克斯县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.2238,
            gisBd09Lng: 81.8432,
            gisGcj02Lat: 43.2176,
            gisGcj02Lng: 81.8368,
            stubGroupCnt: null,
            pinYin: 'Tekesi',
          },
          {
            id: '654028',
            text: '尼勒克县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 43.805,
            gisBd09Lng: 82.5191,
            gisGcj02Lat: 43.7992,
            gisGcj02Lng: 82.5126,
            stubGroupCnt: null,
            pinYin: 'Nileke',
          },
        ],
        gisBd09Lng: 81.3318,
        text: '伊犁哈萨克自治州',
        gisBd09Lat: 43.9229,
        gisGcj02Lat: 43.917,
      },
      {
        pinYin: 'Qoqek',
        id: '654200',
        gisGcj02Lng: 82.9804,
        children: [
          {
            id: '654201',
            text: '塔城',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.7574,
            gisBd09Lng: 82.9957,
            gisGcj02Lat: 46.7516,
            gisGcj02Lng: 82.9891,
            stubGroupCnt: null,
            pinYin: 'Tacheng',
          },
          {
            id: '654202',
            text: '乌苏',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.4411,
            gisBd09Lng: 84.6834,
            gisGcj02Lat: 44.4353,
            gisGcj02Lng: 84.6768,
            stubGroupCnt: null,
            pinYin: 'Wusu',
          },
          {
            id: '654221',
            text: '额敏县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.5307,
            gisBd09Lng: 83.6359,
            gisGcj02Lat: 46.5245,
            gisGcj02Lng: 83.6295,
            stubGroupCnt: null,
            pinYin: 'Emin',
          },
          {
            id: '654223',
            text: '沙湾县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3321,
            gisBd09Lng: 85.6269,
            gisGcj02Lat: 44.3263,
            gisGcj02Lng: 85.6202,
            stubGroupCnt: null,
            pinYin: 'Shawan',
          },
          {
            id: '654224',
            text: '托里县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 45.9427,
            gisBd09Lng: 83.6125,
            gisGcj02Lat: 45.9368,
            gisGcj02Lng: 83.606,
            stubGroupCnt: null,
            pinYin: 'Tuoli',
          },
          {
            id: '654225',
            text: '裕民县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.2063,
            gisBd09Lng: 82.9868,
            gisGcj02Lat: 46.2006,
            gisGcj02Lng: 82.9802,
            stubGroupCnt: null,
            pinYin: 'Yumin',
          },
          {
            id: '654226',
            text: '和布克赛尔蒙古自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.7996,
            gisBd09Lng: 85.7342,
            gisGcj02Lat: 46.7938,
            gisGcj02Lng: 85.7276,
            stubGroupCnt: null,
            pinYin: 'Hebukesaier',
          },
        ],
        gisBd09Lng: 82.987,
        text: '塔城地区',
        gisBd09Lat: 46.7508,
        gisGcj02Lat: 46.745,
      },
      {
        pinYin: 'Altay',
        id: '654300',
        gisGcj02Lng: 88.1432,
        children: [
          {
            id: '654301',
            text: '阿勒泰',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.8328,
            gisBd09Lng: 88.1382,
            gisGcj02Lat: 47.827,
            gisGcj02Lng: 88.1316,
            stubGroupCnt: null,
            pinYin: 'Aletai',
          },
          {
            id: '654321',
            text: '布尔津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.7064,
            gisBd09Lng: 86.8683,
            gisGcj02Lat: 47.7003,
            gisGcj02Lng: 86.8619,
            stubGroupCnt: null,
            pinYin: 'Buerjin',
          },
          {
            id: '654322',
            text: '富蕴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.0001,
            gisBd09Lng: 89.5325,
            gisGcj02Lat: 46.9939,
            gisGcj02Lng: 89.5262,
            stubGroupCnt: null,
            pinYin: 'Fuyun',
          },
          {
            id: '654323',
            text: '福海县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.1186,
            gisBd09Lng: 87.4936,
            gisGcj02Lat: 47.1124,
            gisGcj02Lng: 87.4872,
            stubGroupCnt: null,
            pinYin: 'Fuhai',
          },
          {
            id: '654324',
            text: '哈巴河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 48.0668,
            gisBd09Lng: 86.4259,
            gisGcj02Lat: 48.0607,
            gisGcj02Lng: 86.4195,
            stubGroupCnt: null,
            pinYin: 'Habahe',
          },
          {
            id: '654325',
            text: '青河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 46.6853,
            gisBd09Lng: 90.3821,
            gisGcj02Lat: 46.6791,
            gisGcj02Lng: 90.3757,
            stubGroupCnt: null,
            pinYin: 'Qinghe',
          },
          {
            id: '654326',
            text: '吉木乃县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 47.4477,
            gisBd09Lng: 85.8824,
            gisGcj02Lat: 47.4418,
            gisGcj02Lng: 85.8758,
            stubGroupCnt: null,
            pinYin: 'Jimunai',
          },
        ],
        gisBd09Lng: 88.1499,
        text: '阿勒泰地区',
        gisBd09Lat: 47.852,
        gisGcj02Lat: 47.8463,
      },
      {
        pinYin: '',
        id: '659000',
        gisGcj02Lng: 87.5606,
        children: [
          {
            id: '659001',
            text: '石河子',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3135,
            gisBd09Lng: 86.0739,
            gisGcj02Lat: 44.3074,
            gisGcj02Lng: 86.0674,
            stubGroupCnt: null,
            pinYin: 'Shihezi',
          },
          {
            id: '659002',
            text: '阿拉尔',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 40.5545,
            gisBd09Lng: 81.3074,
            gisGcj02Lat: 40.5487,
            gisGcj02Lng: 81.3008,
            stubGroupCnt: null,
            pinYin: 'Aral',
          },
          {
            id: '659003',
            text: '图木舒克',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 39.8712,
            gisBd09Lng: 79.075,
            gisGcj02Lat: 39.865,
            gisGcj02Lng: 79.0686,
            stubGroupCnt: null,
            pinYin: 'Tumxuk',
          },
          {
            id: '659004',
            text: '五家渠',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 44.3686,
            gisBd09Lng: 87.5672,
            gisGcj02Lat: 44.3627,
            gisGcj02Lng: 87.5606,
            stubGroupCnt: null,
            pinYin: 'Wujiaqu',
          },
        ],
        gisBd09Lng: null,
        text: '省直辖行政单位',
        gisBd09Lat: null,
        gisGcj02Lat: 44.3627,
      },
    ],
  },
  {
    text: '西藏',
    children: [
      {
        pinYin: 'Lhasa',
        id: '540100',
        gisGcj02Lng: 91.1144,
        children: [
          {
            id: '540101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6501,
            gisBd09Lng: 91.1214,
            gisGcj02Lat: 29.6442,
            gisGcj02Lng: 91.1149,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '540102',
            text: '城关区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6585,
            gisBd09Lng: 91.1449,
            gisGcj02Lat: 29.6527,
            gisGcj02Lng: 91.1383,
            stubGroupCnt: null,
            pinYin: 'Chengguan',
          },
          {
            id: '540121',
            text: '林周县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9009,
            gisBd09Lng: 91.2677,
            gisGcj02Lat: 29.8951,
            gisGcj02Lng: 91.2611,
            stubGroupCnt: null,
            pinYin: 'Linzhou',
          },
          {
            id: '540122',
            text: '当雄县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4777,
            gisBd09Lng: 91.107,
            gisGcj02Lat: 30.4716,
            gisGcj02Lng: 91.1006,
            stubGroupCnt: null,
            pinYin: 'Dangxiong',
          },
          {
            id: '540123',
            text: '尼木县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4381,
            gisBd09Lng: 90.172,
            gisGcj02Lat: 29.4323,
            gisGcj02Lng: 90.1654,
            stubGroupCnt: null,
            pinYin: 'Nimu',
          },
          {
            id: '540124',
            text: '曲水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3581,
            gisBd09Lng: 90.7517,
            gisGcj02Lat: 29.352,
            gisGcj02Lng: 90.7452,
            stubGroupCnt: null,
            pinYin: 'Qushui',
          },
          {
            id: '540125',
            text: '堆龙德庆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6523,
            gisBd09Lng: 91.0117,
            gisGcj02Lat: 29.6465,
            gisGcj02Lng: 91.0051,
            stubGroupCnt: null,
            pinYin: 'Duilongdeqing',
          },
          {
            id: '540126',
            text: '达孜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6758,
            gisBd09Lng: 91.3555,
            gisGcj02Lat: 29.6698,
            gisGcj02Lng: 91.349,
            stubGroupCnt: null,
            pinYin: 'Dazi',
          },
          {
            id: '540127',
            text: '墨竹工卡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8403,
            gisBd09Lng: 91.7364,
            gisGcj02Lat: 29.8345,
            gisGcj02Lng: 91.7298,
            stubGroupCnt: null,
            pinYin: 'Mozhugongka',
          },
        ],
        gisBd09Lng: 91.121,
        text: '拉萨',
        gisBd09Lat: 29.6502,
        gisGcj02Lat: 29.6442,
      },
      {
        pinYin: null,
        id: '542100',
        gisGcj02Lng: 97.179,
        children: [
          {
            id: '542121',
            text: '昌都县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4486,
            gisBd09Lng: 97.2442,
            gisGcj02Lat: 31.4427,
            gisGcj02Lng: 97.2377,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542122',
            text: '江达县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8136,
            gisBd09Lng: 98.0367,
            gisGcj02Lat: 31.8074,
            gisGcj02Lng: 98.0302,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542123',
            text: '贡觉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7465,
            gisBd09Lng: 98.4964,
            gisGcj02Lat: 30.7405,
            gisGcj02Lng: 98.4899,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542124',
            text: '类乌齐县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4405,
            gisBd09Lng: 96.4051,
            gisGcj02Lat: 31.4346,
            gisGcj02Lng: 96.3986,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542125',
            text: '丁青县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6819,
            gisBd09Lng: 95.3119,
            gisGcj02Lat: 31.6758,
            gisGcj02Lng: 95.3054,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542126',
            text: '察雅县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5892,
            gisBd09Lng: 97.8441,
            gisGcj02Lat: 30.5833,
            gisGcj02Lng: 97.8376,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542127',
            text: '八宿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1623,
            gisBd09Lng: 96.8854,
            gisGcj02Lat: 30.1564,
            gisGcj02Lng: 96.8789,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542128',
            text: '左贡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5627,
            gisBd09Lng: 97.8084,
            gisGcj02Lat: 29.5564,
            gisGcj02Lng: 97.802,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542129',
            text: '芒康县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6205,
            gisBd09Lng: 98.6207,
            gisGcj02Lat: 29.6145,
            gisGcj02Lng: 98.6142,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542132',
            text: '洛隆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7354,
            gisBd09Lng: 95.9172,
            gisGcj02Lat: 30.7294,
            gisGcj02Lng: 95.9107,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542133',
            text: '边坝县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8918,
            gisBd09Lng: 94.5998,
            gisGcj02Lat: 30.8858,
            gisGcj02Lng: 94.5933,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 97.1855,
        text: '昌都地区',
        gisBd09Lat: 31.1405,
        gisGcj02Lat: 31.1343,
      },
      {
        pinYin: 'Shannan',
        id: '542200',
        gisGcj02Lng: 91.7747,
        children: [
          {
            id: '542221',
            text: '乃东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.231,
            gisBd09Lng: 91.7681,
            gisGcj02Lat: 29.2251,
            gisGcj02Lng: 91.7616,
            stubGroupCnt: null,
            pinYin: 'Naidong',
          },
          {
            id: '542222',
            text: '扎囊县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2512,
            gisBd09Lng: 91.3445,
            gisGcj02Lat: 29.2451,
            gisGcj02Lng: 91.3381,
            stubGroupCnt: null,
            pinYin: 'Zhanang',
          },
          {
            id: '542223',
            text: '贡嘎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2955,
            gisBd09Lng: 90.9911,
            gisGcj02Lat: 29.2894,
            gisGcj02Lng: 90.9846,
            stubGroupCnt: null,
            pinYin: 'Gongga',
          },
          {
            id: '542224',
            text: '桑日县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.265,
            gisBd09Lng: 92.0237,
            gisGcj02Lat: 29.2589,
            gisGcj02Lng: 92.0172,
            stubGroupCnt: null,
            pinYin: 'Sangri',
          },
          {
            id: '542225',
            text: '琼结县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0313,
            gisBd09Lng: 91.6918,
            gisGcj02Lat: 29.025,
            gisGcj02Lng: 91.6854,
            stubGroupCnt: null,
            pinYin: 'Qiongjie',
          },
          {
            id: '542226',
            text: '曲松县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0687,
            gisBd09Lng: 92.2095,
            gisGcj02Lat: 29.0629,
            gisGcj02Lng: 92.2029,
            stubGroupCnt: null,
            pinYin: 'Qusong',
          },
          {
            id: '542227',
            text: '措美县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4441,
            gisBd09Lng: 91.4413,
            gisGcj02Lat: 28.4378,
            gisGcj02Lng: 91.4349,
            stubGroupCnt: null,
            pinYin: 'Cuomei',
          },
          {
            id: '542228',
            text: '洛扎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3921,
            gisBd09Lng: 90.8671,
            gisGcj02Lat: 28.386,
            gisGcj02Lng: 90.8607,
            stubGroupCnt: null,
            pinYin: 'Luozha',
          },
          {
            id: '542229',
            text: '加查县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1456,
            gisBd09Lng: 92.6016,
            gisGcj02Lat: 29.1398,
            gisGcj02Lng: 92.595,
            stubGroupCnt: null,
            pinYin: 'Jiacha',
          },
          {
            id: '542231',
            text: '隆子县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4127,
            gisBd09Lng: 92.4696,
            gisGcj02Lat: 28.4069,
            gisGcj02Lng: 92.4631,
            stubGroupCnt: null,
            pinYin: 'Longzi',
          },
          {
            id: '542232',
            text: '错那县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9978,
            gisBd09Lng: 91.9648,
            gisGcj02Lat: 27.9919,
            gisGcj02Lng: 91.9582,
            stubGroupCnt: null,
            pinYin: 'Cuona',
          },
          {
            id: '542233',
            text: '浪卡子县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9738,
            gisBd09Lng: 90.4054,
            gisGcj02Lat: 28.9679,
            gisGcj02Lng: 90.3989,
            stubGroupCnt: null,
            pinYin: 'Langkazi',
          },
        ],
        gisBd09Lng: 91.7812,
        text: '山南地区',
        gisBd09Lat: 29.2437,
        gisGcj02Lat: 29.2377,
      },
      {
        pinYin: null,
        id: '542300',
        gisGcj02Lng: 88.9496,
        children: [
          {
            id: '542301',
            text: '日喀则',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.268,
            gisBd09Lng: 88.9562,
            gisGcj02Lat: 29.262,
            gisGcj02Lng: 88.9497,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542322',
            text: '南木林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8147,
            gisBd09Lng: 89.3801,
            gisGcj02Lat: 29.8087,
            gisGcj02Lng: 89.3736,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542323',
            text: '江孜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9107,
            gisBd09Lng: 89.6695,
            gisGcj02Lat: 28.9046,
            gisGcj02Lng: 89.663,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542324',
            text: '定日县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4777,
            gisBd09Lng: 87.0,
            gisGcj02Lat: 28.4715,
            gisGcj02Lng: 86.9935,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542325',
            text: '萨迦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.855,
            gisBd09Lng: 88.3479,
            gisGcj02Lat: 28.8488,
            gisGcj02Lng: 88.3415,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542326',
            text: '拉孜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1802,
            gisBd09Lng: 87.859,
            gisGcj02Lat: 29.174,
            gisGcj02Lng: 87.8526,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542327',
            text: '昂仁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9566,
            gisBd09Lng: 86.6294,
            gisGcj02Lat: 29.9504,
            gisGcj02Lng: 86.623,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542328',
            text: '谢通门县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9149,
            gisBd09Lng: 88.0823,
            gisGcj02Lat: 29.9086,
            gisGcj02Lng: 88.0759,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542329',
            text: '白朗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.82,
            gisBd09Lng: 89.1048,
            gisGcj02Lat: 28.8143,
            gisGcj02Lng: 89.0982,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542330',
            text: '仁布县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2242,
            gisBd09Lng: 89.9762,
            gisGcj02Lat: 29.2182,
            gisGcj02Lng: 89.9697,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542331',
            text: '康马县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4434,
            gisBd09Lng: 89.6559,
            gisGcj02Lat: 28.4371,
            gisGcj02Lng: 89.6495,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542332',
            text: '定结县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2371,
            gisBd09Lng: 87.9144,
            gisGcj02Lat: 28.2314,
            gisGcj02Lng: 87.9078,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542333',
            text: '仲巴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4845,
            gisBd09Lng: 83.6626,
            gisGcj02Lat: 30.4783,
            gisGcj02Lng: 83.6562,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542334',
            text: '亚东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8146,
            gisBd09Lng: 89.0833,
            gisGcj02Lat: 27.8087,
            gisGcj02Lng: 89.0767,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542335',
            text: '吉隆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8603,
            gisBd09Lng: 85.3052,
            gisGcj02Lat: 28.8542,
            gisGcj02Lng: 85.2987,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542336',
            text: '聂拉木县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5997,
            gisBd09Lng: 86.068,
            gisGcj02Lat: 28.5936,
            gisGcj02Lng: 86.0616,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542337',
            text: '萨嘎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4014,
            gisBd09Lng: 85.0856,
            gisGcj02Lat: 29.3951,
            gisGcj02Lng: 85.0792,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '542338',
            text: '岗巴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2839,
            gisBd09Lng: 88.5308,
            gisGcj02Lat: 28.278,
            gisGcj02Lng: 88.5243,
            stubGroupCnt: null,
            pinYin: null,
          },
        ],
        gisBd09Lng: 88.9561,
        text: '日喀则地区',
        gisBd09Lat: 29.2681,
        gisGcj02Lat: 29.2621,
      },
      {
        pinYin: 'Nagqu',
        id: '542400',
        gisGcj02Lng: 92.0522,
        children: [
          {
            id: '542421',
            text: '那曲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4758,
            gisBd09Lng: 92.06,
            gisGcj02Lat: 31.4696,
            gisGcj02Lng: 92.0536,
            stubGroupCnt: null,
            pinYin: 'Naqu',
          },
          {
            id: '542422',
            text: '嘉黎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6473,
            gisBd09Lng: 93.2395,
            gisGcj02Lat: 30.641,
            gisGcj02Lng: 93.233,
            stubGroupCnt: null,
            pinYin: 'Jiali',
          },
          {
            id: '542423',
            text: '比如县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4839,
            gisBd09Lng: 93.6884,
            gisGcj02Lat: 31.478,
            gisGcj02Lng: 93.6819,
            stubGroupCnt: null,
            pinYin: 'Biru',
          },
          {
            id: '542424',
            text: '聂荣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1142,
            gisBd09Lng: 92.3102,
            gisGcj02Lat: 32.1081,
            gisGcj02Lng: 92.3037,
            stubGroupCnt: null,
            pinYin: 'Nierong',
          },
          {
            id: '542425',
            text: '安多县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2711,
            gisBd09Lng: 91.689,
            gisGcj02Lat: 32.2649,
            gisGcj02Lng: 91.6826,
            stubGroupCnt: null,
            pinYin: 'Anduo',
          },
          {
            id: '542426',
            text: '申扎县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9364,
            gisBd09Lng: 88.7167,
            gisGcj02Lat: 30.9304,
            gisGcj02Lng: 88.7102,
            stubGroupCnt: null,
            pinYin: 'Shenzha',
          },
          {
            id: '542427',
            text: '索　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8926,
            gisBd09Lng: 93.7912,
            gisGcj02Lat: 31.8869,
            gisGcj02Lng: 93.7847,
            stubGroupCnt: null,
            pinYin: 'Suoxian',
          },
          {
            id: '542428',
            text: '班戈县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3983,
            gisBd09Lng: 90.0169,
            gisGcj02Lat: 31.392,
            gisGcj02Lng: 90.0105,
            stubGroupCnt: null,
            pinYin: 'Bange',
          },
          {
            id: '542429',
            text: '巴青县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9246,
            gisBd09Lng: 94.0602,
            gisGcj02Lat: 31.9186,
            gisGcj02Lng: 94.0537,
            stubGroupCnt: null,
            pinYin: 'Baqing',
          },
          {
            id: '542430',
            text: '尼玛县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7907,
            gisBd09Lng: 87.2435,
            gisGcj02Lat: 31.7845,
            gisGcj02Lng: 87.2371,
            stubGroupCnt: null,
            pinYin: 'Nima',
          },
        ],
        gisBd09Lng: 92.0587,
        text: '那曲地区',
        gisBd09Lat: 31.4824,
        gisGcj02Lat: 31.4762,
      },
      {
        pinYin: 'Ngari',
        id: '542500',
        gisGcj02Lng: 81.1444,
        children: [
          {
            id: '542521',
            text: '普兰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2991,
            gisBd09Lng: 81.1854,
            gisGcj02Lat: 30.2934,
            gisGcj02Lng: 81.1788,
            stubGroupCnt: null,
            pinYin: 'Pulan',
          },
          {
            id: '542522',
            text: '札达县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4849,
            gisBd09Lng: 79.8107,
            gisGcj02Lat: 31.4787,
            gisGcj02Lng: 79.8043,
            stubGroupCnt: null,
            pinYin: 'Zhada',
          },
          {
            id: '542523',
            text: '噶尔县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5063,
            gisBd09Lng: 80.1035,
            gisGcj02Lat: 32.5005,
            gisGcj02Lng: 80.0969,
            stubGroupCnt: null,
            pinYin: 'Gaer',
          },
          {
            id: '542524',
            text: '日土县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.3936,
            gisBd09Lng: 79.7204,
            gisGcj02Lat: 33.3876,
            gisGcj02Lng: 79.7138,
            stubGroupCnt: null,
            pinYin: 'Ritu',
          },
          {
            id: '542525',
            text: '革吉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3941,
            gisBd09Lng: 81.1544,
            gisGcj02Lat: 32.3881,
            gisGcj02Lng: 81.1479,
            stubGroupCnt: null,
            pinYin: 'Geji',
          },
          {
            id: '542526',
            text: '改则县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3091,
            gisBd09Lng: 84.0695,
            gisGcj02Lat: 32.3034,
            gisGcj02Lng: 84.0629,
            stubGroupCnt: null,
            pinYin: 'Gaize',
          },
          {
            id: '542527',
            text: '措勤县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0223,
            gisBd09Lng: 85.1601,
            gisGcj02Lat: 31.0165,
            gisGcj02Lng: 85.1535,
            stubGroupCnt: null,
            pinYin: 'Cuoqin',
          },
        ],
        gisBd09Lng: 81.1509,
        text: '阿里地区',
        gisBd09Lat: 30.4068,
        gisGcj02Lat: 30.4007,
      },
      {
        pinYin: 'Nyingchi',
        id: '542600',
        gisGcj02Lng: 94.3634,
        children: [
          {
            id: '542621',
            text: '林芝县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6429,
            gisBd09Lng: 94.3692,
            gisGcj02Lat: 29.6371,
            gisGcj02Lng: 94.3626,
            stubGroupCnt: null,
            pinYin: 'Linzhi',
          },
          {
            id: '542622',
            text: '工布江达县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8921,
            gisBd09Lng: 93.2544,
            gisGcj02Lat: 29.8858,
            gisGcj02Lng: 93.248,
            stubGroupCnt: null,
            pinYin: 'Gongbujiangda',
          },
          {
            id: '542623',
            text: '米林县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.222,
            gisBd09Lng: 94.2187,
            gisGcj02Lat: 29.2157,
            gisGcj02Lng: 94.2122,
            stubGroupCnt: null,
            pinYin: 'Milin',
          },
          {
            id: '542624',
            text: '墨脱县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3302,
            gisBd09Lng: 95.3393,
            gisGcj02Lat: 29.3245,
            gisGcj02Lng: 95.3328,
            stubGroupCnt: null,
            pinYin: 'Motuo',
          },
          {
            id: '542625',
            text: '波密县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8647,
            gisBd09Lng: 95.7747,
            gisGcj02Lat: 29.8584,
            gisGcj02Lng: 95.7683,
            stubGroupCnt: null,
            pinYin: 'Bomi',
          },
          {
            id: '542626',
            text: '察隅县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6668,
            gisBd09Lng: 97.4719,
            gisGcj02Lat: 28.6608,
            gisGcj02Lng: 97.4654,
            stubGroupCnt: null,
            pinYin: 'Chayu',
          },
          {
            id: '542627',
            text: '朗　县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: '1',
            modifyTime: '2017.02.10 10:03:35',
            gisBd09Lat: 29.0521,
            gisBd09Lng: 93.0831,
            gisGcj02Lat: 29.0463,
            gisGcj02Lng: 93.0766,
            stubGroupCnt: null,
            pinYin: 'Langxian',
          },
        ],
        gisBd09Lng: 94.37,
        text: '林芝地区',
        gisBd09Lat: 29.6548,
        gisGcj02Lat: 29.649,
      },
    ],
  },
  {
    text: '四川省',
    children: [
      {
        pinYin: 'Chengdu',
        id: '510100',
        gisGcj02Lng: 104.069,
        children: [
          {
            id: '510101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6635,
            gisBd09Lng: 104.075,
            gisGcj02Lat: 30.6575,
            gisGcj02Lng: 104.068,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510104',
            text: '锦江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6623,
            gisBd09Lng: 104.09,
            gisGcj02Lat: 30.6565,
            gisGcj02Lng: 104.084,
            stubGroupCnt: null,
            pinYin: 'Jinjiang',
          },
          {
            id: '510105',
            text: '青羊区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6811,
            gisBd09Lng: 104.067,
            gisGcj02Lat: 30.6749,
            gisGcj02Lng: 104.061,
            stubGroupCnt: null,
            pinYin: 'Qingyang',
          },
          {
            id: '510106',
            text: '金牛区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6971,
            gisBd09Lng: 104.061,
            gisGcj02Lat: 30.6908,
            gisGcj02Lng: 104.055,
            stubGroupCnt: null,
            pinYin: 'Jinniu',
          },
          {
            id: '510107',
            text: '武侯区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6477,
            gisBd09Lng: 104.05,
            gisGcj02Lat: 30.6414,
            gisGcj02Lng: 104.043,
            stubGroupCnt: null,
            pinYin: 'Wuhou',
          },
          {
            id: '510108',
            text: '成华区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6657,
            gisBd09Lng: 104.109,
            gisGcj02Lat: 30.66,
            gisGcj02Lng: 104.103,
            stubGroupCnt: null,
            pinYin: 'Chenghua',
          },
          {
            id: '510112',
            text: '龙泉驿区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5627,
            gisBd09Lng: 104.282,
            gisGcj02Lat: 30.5564,
            gisGcj02Lng: 104.276,
            stubGroupCnt: null,
            pinYin: 'Longquanyi',
          },
          {
            id: '510113',
            text: '青白江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8854,
            gisBd09Lng: 104.257,
            gisGcj02Lat: 30.8794,
            gisGcj02Lng: 104.251,
            stubGroupCnt: null,
            pinYin: 'Qingbaijiang',
          },
          {
            id: '510114',
            text: '新都区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8304,
            gisBd09Lng: 104.165,
            gisGcj02Lat: 30.8241,
            gisGcj02Lng: 104.158,
            stubGroupCnt: null,
            pinYin: 'Xindu',
          },
          {
            id: '510115',
            text: '温江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6869,
            gisBd09Lng: 103.862,
            gisGcj02Lat: 30.6812,
            gisGcj02Lng: 103.856,
            stubGroupCnt: null,
            pinYin: 'Wenjiang',
          },
          {
            id: '510121',
            text: '金堂县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8681,
            gisBd09Lng: 104.419,
            gisGcj02Lat: 30.8618,
            gisGcj02Lng: 104.413,
            stubGroupCnt: null,
            pinYin: 'Jintang',
          },
          {
            id: '510122',
            text: '双流县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5805,
            gisBd09Lng: 103.931,
            gisGcj02Lat: 30.5742,
            gisGcj02Lng: 103.925,
            stubGroupCnt: null,
            pinYin: 'Shuangliu',
          },
          {
            id: '510124',
            text: '郫　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8018,
            gisBd09Lng: 103.91,
            gisGcj02Lat: 30.7956,
            gisGcj02Lng: 103.903,
            stubGroupCnt: null,
            pinYin: 'Pixian',
          },
          {
            id: '510129',
            text: '大邑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5937,
            gisBd09Lng: 103.528,
            gisGcj02Lat: 30.5878,
            gisGcj02Lng: 103.521,
            stubGroupCnt: null,
            pinYin: 'Dayi',
          },
          {
            id: '510131',
            text: '蒲江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2023,
            gisBd09Lng: 103.513,
            gisGcj02Lat: 30.1966,
            gisGcj02Lng: 103.507,
            stubGroupCnt: null,
            pinYin: 'Pujiang',
          },
          {
            id: '510132',
            text: '新津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4163,
            gisBd09Lng: 103.819,
            gisGcj02Lat: 30.4101,
            gisGcj02Lng: 103.812,
            stubGroupCnt: null,
            pinYin: 'Xinjin',
          },
          {
            id: '510181',
            text: '都江堰',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9946,
            gisBd09Lng: 103.655,
            gisGcj02Lat: 30.9886,
            gisGcj02Lng: 103.649,
            stubGroupCnt: null,
            pinYin: 'Dujiangyan',
          },
          {
            id: '510182',
            text: '彭州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9958,
            gisBd09Lng: 103.966,
            gisGcj02Lat: 30.99,
            gisGcj02Lng: 103.96,
            stubGroupCnt: null,
            pinYin: 'Pengzhou',
          },
          {
            id: '510183',
            text: '邛崃',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4166,
            gisBd09Lng: 103.471,
            gisGcj02Lat: 30.4105,
            gisGcj02Lng: 103.465,
            stubGroupCnt: null,
            pinYin: 'Qionglai',
          },
          {
            id: '510184',
            text: '崇州',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6363,
            gisBd09Lng: 103.68,
            gisGcj02Lat: 30.63,
            gisGcj02Lng: 103.674,
            stubGroupCnt: null,
            pinYin: 'Chongzhou',
          },
        ],
        gisBd09Lng: 104.075,
        text: '成都',
        gisBd09Lat: 30.6635,
        gisGcj02Lat: 30.6575,
      },
      {
        pinYin: 'Zigong',
        id: '510300',
        gisGcj02Lng: 104.779,
        children: [
          {
            id: '510301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3451,
            gisBd09Lng: 104.786,
            gisGcj02Lat: 29.339,
            gisGcj02Lng: 104.779,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510302',
            text: '自流井区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3435,
            gisBd09Lng: 104.785,
            gisGcj02Lat: 29.3373,
            gisGcj02Lng: 104.779,
            stubGroupCnt: null,
            pinYin: 'Ziliujing',
          },
          {
            id: '510303',
            text: '贡井区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3512,
            gisBd09Lng: 104.722,
            gisGcj02Lat: 29.3454,
            gisGcj02Lng: 104.716,
            stubGroupCnt: null,
            pinYin: 'Gongjing',
          },
          {
            id: '510304',
            text: '大安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3703,
            gisBd09Lng: 104.781,
            gisGcj02Lat: 29.364,
            gisGcj02Lng: 104.774,
            stubGroupCnt: null,
            pinYin: "Da'an",
          },
          {
            id: '510311',
            text: '沿滩区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2788,
            gisBd09Lng: 104.882,
            gisGcj02Lat: 29.2725,
            gisGcj02Lng: 104.876,
            stubGroupCnt: null,
            pinYin: 'Yantan',
          },
          {
            id: '510321',
            text: '荣　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4509,
            gisBd09Lng: 104.425,
            gisGcj02Lat: 29.4448,
            gisGcj02Lng: 104.419,
            stubGroupCnt: null,
            pinYin: 'Rongxian',
          },
          {
            id: '510322',
            text: '富顺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1904,
            gisBd09Lng: 104.988,
            gisGcj02Lat: 29.1842,
            gisGcj02Lng: 104.982,
            stubGroupCnt: null,
            pinYin: 'Fushun',
          },
        ],
        gisBd09Lng: 104.786,
        text: '自贡',
        gisBd09Lat: 29.3451,
        gisGcj02Lat: 29.339,
      },
      {
        pinYin: 'Panzhihua',
        id: '510400',
        gisGcj02Lng: 101.721,
        children: [
          {
            id: '510401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5885,
            gisBd09Lng: 101.727,
            gisGcj02Lat: 26.5826,
            gisGcj02Lng: 101.721,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510402',
            text: '东　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5522,
            gisBd09Lng: 101.713,
            gisGcj02Lat: 26.5464,
            gisGcj02Lng: 101.706,
            stubGroupCnt: null,
            pinYin: 'Dongqu',
          },
          {
            id: '510403',
            text: '西　区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6029,
            gisBd09Lng: 101.638,
            gisGcj02Lat: 26.5966,
            gisGcj02Lng: 101.632,
            stubGroupCnt: null,
            pinYin: 'Xiqu',
          },
          {
            id: '510411',
            text: '仁和区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5043,
            gisBd09Lng: 101.746,
            gisGcj02Lat: 26.4982,
            gisGcj02Lng: 101.739,
            stubGroupCnt: null,
            pinYin: 'Renhe',
          },
          {
            id: '510421',
            text: '米易县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.897,
            gisBd09Lng: 102.116,
            gisGcj02Lat: 26.8907,
            gisGcj02Lng: 102.11,
            stubGroupCnt: null,
            pinYin: 'Miyi',
          },
          {
            id: '510422',
            text: '盐边县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6883,
            gisBd09Lng: 101.861,
            gisGcj02Lat: 26.6822,
            gisGcj02Lng: 101.855,
            stubGroupCnt: null,
            pinYin: 'Yanbian',
          },
        ],
        gisBd09Lng: 101.727,
        text: '攀枝花',
        gisBd09Lat: 26.5885,
        gisGcj02Lat: 26.5826,
      },
      {
        pinYin: 'Luzhou',
        id: '510500',
        gisGcj02Lng: 105.442,
        children: [
          {
            id: '510501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8777,
            gisBd09Lng: 105.448,
            gisGcj02Lat: 28.8718,
            gisGcj02Lng: 105.442,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510502',
            text: '江阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8844,
            gisBd09Lng: 105.444,
            gisGcj02Lat: 28.8786,
            gisGcj02Lng: 105.438,
            stubGroupCnt: null,
            pinYin: 'Jiangyang',
          },
          {
            id: '510503',
            text: '纳溪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7795,
            gisBd09Lng: 105.378,
            gisGcj02Lat: 28.7733,
            gisGcj02Lng: 105.372,
            stubGroupCnt: null,
            pinYin: 'Naxi',
          },
          {
            id: '510504',
            text: '龙马潭区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9189,
            gisBd09Lng: 105.445,
            gisGcj02Lat: 28.9131,
            gisGcj02Lng: 105.438,
            stubGroupCnt: null,
            pinYin: 'Longmatan',
          },
          {
            id: '510521',
            text: '泸　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1576,
            gisBd09Lng: 105.389,
            gisGcj02Lat: 29.1515,
            gisGcj02Lng: 105.383,
            stubGroupCnt: null,
            pinYin: 'Luxian',
          },
          {
            id: '510522',
            text: '合江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8178,
            gisBd09Lng: 105.836,
            gisGcj02Lat: 28.8115,
            gisGcj02Lng: 105.83,
            stubGroupCnt: null,
            pinYin: 'Hejiang',
          },
          {
            id: '510524',
            text: '叙永县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1626,
            gisBd09Lng: 105.452,
            gisGcj02Lat: 28.1567,
            gisGcj02Lng: 105.445,
            stubGroupCnt: null,
            pinYin: 'Xuyong',
          },
          {
            id: '510525',
            text: '古蔺县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.0451,
            gisBd09Lng: 105.818,
            gisGcj02Lat: 28.0391,
            gisGcj02Lng: 105.811,
            stubGroupCnt: null,
            pinYin: 'Gulin',
          },
        ],
        gisBd09Lng: 105.448,
        text: '泸州',
        gisBd09Lat: 28.8777,
        gisGcj02Lat: 28.8718,
      },
      {
        pinYin: 'Deyang',
        id: '510600',
        gisGcj02Lng: 104.224,
        children: [
          {
            id: '510601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1337,
            gisBd09Lng: 104.405,
            gisGcj02Lat: 31.1274,
            gisGcj02Lng: 104.399,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510603',
            text: '旌阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1486,
            gisBd09Lng: 104.426,
            gisGcj02Lat: 31.1424,
            gisGcj02Lng: 104.419,
            stubGroupCnt: null,
            pinYin: 'Jingyang',
          },
          {
            id: '510623',
            text: '中江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0391,
            gisBd09Lng: 104.687,
            gisGcj02Lat: 31.0333,
            gisGcj02Lng: 104.681,
            stubGroupCnt: null,
            pinYin: 'Zhongjiang',
          },
          {
            id: '510626',
            text: '罗江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3231,
            gisBd09Lng: 104.518,
            gisGcj02Lat: 31.3168,
            gisGcj02Lng: 104.511,
            stubGroupCnt: null,
            pinYin: 'Luojiang',
          },
          {
            id: '510681',
            text: '广汉',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9846,
            gisBd09Lng: 104.29,
            gisGcj02Lat: 30.9783,
            gisGcj02Lng: 104.283,
            stubGroupCnt: null,
            pinYin: 'Guanghan',
          },
          {
            id: '510682',
            text: '什邡',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1339,
            gisBd09Lng: 104.175,
            gisGcj02Lat: 31.1276,
            gisGcj02Lng: 104.169,
            stubGroupCnt: null,
            pinYin: 'Shifang',
          },
          {
            id: '510683',
            text: '绵竹',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3431,
            gisBd09Lng: 104.23,
            gisGcj02Lat: 31.3374,
            gisGcj02Lng: 104.224,
            stubGroupCnt: null,
            pinYin: 'Mianzhu',
          },
        ],
        gisBd09Lng: null,
        text: '德阳',
        gisBd09Lat: null,
        gisGcj02Lat: 31.3374,
      },
      {
        pinYin: 'Mianyang',
        id: '510700',
        gisGcj02Lng: 104.68,
        children: [
          {
            id: '510701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4729,
            gisBd09Lng: 104.686,
            gisGcj02Lat: 31.467,
            gisGcj02Lng: 104.68,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510703',
            text: '涪城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4615,
            gisBd09Lng: 104.764,
            gisGcj02Lat: 31.4552,
            gisGcj02Lng: 104.758,
            stubGroupCnt: null,
            pinYin: 'Fucheng',
          },
          {
            id: '510704',
            text: '游仙区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4803,
            gisBd09Lng: 104.774,
            gisGcj02Lat: 31.474,
            gisGcj02Lng: 104.768,
            stubGroupCnt: null,
            pinYin: 'Youxian',
          },
          {
            id: '510722',
            text: '三台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1017,
            gisBd09Lng: 105.103,
            gisGcj02Lat: 31.0956,
            gisGcj02Lng: 105.096,
            stubGroupCnt: null,
            pinYin: 'Santai',
          },
          {
            id: '510723',
            text: '盐亭县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2137,
            gisBd09Lng: 105.397,
            gisGcj02Lat: 31.2077,
            gisGcj02Lng: 105.39,
            stubGroupCnt: null,
            pinYin: 'Yanting',
          },
          {
            id: '510724',
            text: '安　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5391,
            gisBd09Lng: 104.573,
            gisGcj02Lat: 31.5334,
            gisGcj02Lng: 104.567,
            stubGroupCnt: null,
            pinYin: 'Anxian',
          },
          {
            id: '510725',
            text: '梓潼县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6411,
            gisBd09Lng: 105.168,
            gisGcj02Lat: 31.6353,
            gisGcj02Lng: 105.161,
            stubGroupCnt: null,
            pinYin: 'Zitong',
          },
          {
            id: '510726',
            text: '北川羌族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8388,
            gisBd09Lng: 104.472,
            gisGcj02Lat: 31.8331,
            gisGcj02Lng: 104.465,
            stubGroupCnt: null,
            pinYin: 'Beichuan',
          },
          {
            id: '510727',
            text: '平武县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4146,
            gisBd09Lng: 104.536,
            gisGcj02Lat: 32.4083,
            gisGcj02Lng: 104.529,
            stubGroupCnt: null,
            pinYin: 'Pingwu',
          },
          {
            id: '510781',
            text: '江油',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7648,
            gisBd09Lng: 104.735,
            gisGcj02Lat: 31.7588,
            gisGcj02Lng: 104.729,
            stubGroupCnt: null,
            pinYin: 'Jiangyou',
          },
        ],
        gisBd09Lng: 104.686,
        text: '绵阳',
        gisBd09Lat: 31.4729,
        gisGcj02Lat: 31.467,
      },
      {
        pinYin: 'Guangyuan',
        id: '510800',
        gisGcj02Lng: 105.842,
        children: [
          {
            id: '510801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.4419,
            gisBd09Lng: 105.849,
            gisGcj02Lat: 32.4355,
            gisGcj02Lng: 105.842,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510802',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5613,
            gisBd09Lng: 103.769,
            gisGcj02Lat: 29.5554,
            gisGcj02Lng: 103.763,
            stubGroupCnt: null,
            pinYin: 'Lizhou',
          },
          {
            id: '510811',
            text: '元坝区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.1498,
            gisBd09Lng: 105.883,
            gisGcj02Lat: 32.1439,
            gisGcj02Lng: 105.877,
            stubGroupCnt: null,
            pinYin: 'Zhaohua',
          },
          {
            id: '510812',
            text: '朝天区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6497,
            gisBd09Lng: 105.897,
            gisGcj02Lat: 32.6439,
            gisGcj02Lng: 105.891,
            stubGroupCnt: null,
            pinYin: 'Chaotian',
          },
          {
            id: '510821',
            text: '旺苍县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2352,
            gisBd09Lng: 106.298,
            gisGcj02Lat: 32.2292,
            gisGcj02Lng: 106.291,
            stubGroupCnt: null,
            pinYin: 'Wangcang',
          },
          {
            id: '510822',
            text: '青川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.5922,
            gisBd09Lng: 105.246,
            gisGcj02Lat: 32.5859,
            gisGcj02Lng: 105.24,
            stubGroupCnt: null,
            pinYin: 'Qingchuan',
          },
          {
            id: '510823',
            text: '剑阁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.294,
            gisBd09Lng: 105.533,
            gisGcj02Lat: 32.2883,
            gisGcj02Lng: 105.526,
            stubGroupCnt: null,
            pinYin: 'Jiange',
          },
          {
            id: '510824',
            text: '苍溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.7383,
            gisBd09Lng: 105.943,
            gisGcj02Lat: 31.7322,
            gisGcj02Lng: 105.937,
            stubGroupCnt: null,
            pinYin: 'Cangxi',
          },
        ],
        gisBd09Lng: 105.849,
        text: '广元',
        gisBd09Lat: 32.4419,
        gisGcj02Lat: 32.4355,
      },
      {
        pinYin: 'Suining',
        id: '510900',
        gisGcj02Lng: 105.593,
        children: [
          {
            id: '510901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5379,
            gisBd09Lng: 105.6,
            gisGcj02Lat: 30.5316,
            gisGcj02Lng: 105.593,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '510903',
            text: '船山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5319,
            gisBd09Lng: 105.574,
            gisGcj02Lat: 30.5259,
            gisGcj02Lng: 105.568,
            stubGroupCnt: null,
            pinYin: 'Chuanshan',
          },
          {
            id: '510904',
            text: '安居区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3606,
            gisBd09Lng: 105.464,
            gisGcj02Lat: 30.3545,
            gisGcj02Lng: 105.457,
            stubGroupCnt: null,
            pinYin: 'Anju',
          },
          {
            id: '510921',
            text: '蓬溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7637,
            gisBd09Lng: 105.713,
            gisGcj02Lat: 30.7574,
            gisGcj02Lng: 105.707,
            stubGroupCnt: null,
            pinYin: 'Pengxi',
          },
          {
            id: '510922',
            text: '射洪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8768,
            gisBd09Lng: 105.395,
            gisGcj02Lat: 30.8708,
            gisGcj02Lng: 105.388,
            stubGroupCnt: null,
            pinYin: 'Shehong',
          },
          {
            id: '510923',
            text: '大英县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6007,
            gisBd09Lng: 105.242,
            gisGcj02Lat: 30.5944,
            gisGcj02Lng: 105.236,
            stubGroupCnt: null,
            pinYin: 'Daying',
          },
        ],
        gisBd09Lng: 105.6,
        text: '遂宁',
        gisBd09Lat: 30.5379,
        gisGcj02Lat: 30.5316,
      },
      {
        pinYin: 'Neijiang',
        id: '511000',
        gisGcj02Lng: 105.059,
        children: [
          {
            id: '511001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5852,
            gisBd09Lng: 105.065,
            gisGcj02Lat: 29.5795,
            gisGcj02Lng: 105.059,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511002',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.593,
            gisBd09Lng: 105.075,
            gisGcj02Lat: 29.5873,
            gisGcj02Lng: 105.069,
            stubGroupCnt: null,
            pinYin: 'Shizhongqu',
          },
          {
            id: '511011',
            text: '东兴区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5997,
            gisBd09Lng: 105.08,
            gisGcj02Lat: 29.5939,
            gisGcj02Lng: 105.074,
            stubGroupCnt: null,
            pinYin: 'Dongxing',
          },
          {
            id: '511024',
            text: '威远县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5334,
            gisBd09Lng: 104.676,
            gisGcj02Lat: 29.5274,
            gisGcj02Lng: 104.67,
            stubGroupCnt: null,
            pinYin: 'Weiyuan',
          },
          {
            id: '511025',
            text: '资中县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7695,
            gisBd09Lng: 104.859,
            gisGcj02Lat: 29.7635,
            gisGcj02Lng: 104.853,
            stubGroupCnt: null,
            pinYin: 'Zizhong',
          },
          {
            id: '511028',
            text: '隆昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3455,
            gisBd09Lng: 105.296,
            gisGcj02Lat: 29.3397,
            gisGcj02Lng: 105.289,
            stubGroupCnt: null,
            pinYin: 'Longchang',
          },
        ],
        gisBd09Lng: 105.065,
        text: '内江',
        gisBd09Lat: 29.5852,
        gisGcj02Lat: 29.5795,
      },
      {
        pinYin: 'Leshan',
        id: '511100',
        gisGcj02Lng: 103.767,
        children: [
          {
            id: '511101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5579,
            gisBd09Lng: 103.774,
            gisGcj02Lat: 29.5519,
            gisGcj02Lng: 103.767,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511102',
            text: '市中区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5614,
            gisBd09Lng: 103.769,
            gisGcj02Lat: 29.5555,
            gisGcj02Lng: 103.762,
            stubGroupCnt: null,
            pinYin: 'Shizhongqu',
          },
          {
            id: '511111',
            text: '沙湾区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.42,
            gisBd09Lng: 103.557,
            gisGcj02Lat: 29.4137,
            gisGcj02Lng: 103.55,
            stubGroupCnt: null,
            pinYin: 'Shawan',
          },
          {
            id: '511112',
            text: '五通桥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4136,
            gisBd09Lng: 103.823,
            gisGcj02Lat: 29.4074,
            gisGcj02Lng: 103.817,
            stubGroupCnt: null,
            pinYin: 'Wutongqiao',
          },
          {
            id: '511113',
            text: '金口河区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2512,
            gisBd09Lng: 103.087,
            gisGcj02Lat: 29.2448,
            gisGcj02Lng: 103.081,
            stubGroupCnt: null,
            pinYin: 'Jinkouhe',
          },
          {
            id: '511123',
            text: '犍为县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2147,
            gisBd09Lng: 103.958,
            gisGcj02Lat: 29.2087,
            gisGcj02Lng: 103.951,
            stubGroupCnt: null,
            pinYin: 'Qianwei',
          },
          {
            id: '511124',
            text: '井研县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6581,
            gisBd09Lng: 104.076,
            gisGcj02Lat: 29.6521,
            gisGcj02Lng: 104.069,
            stubGroupCnt: null,
            pinYin: 'Jingyan',
          },
          {
            id: '511126',
            text: '夹江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7442,
            gisBd09Lng: 103.579,
            gisGcj02Lat: 29.7379,
            gisGcj02Lng: 103.573,
            stubGroupCnt: null,
            pinYin: 'Jiajiang',
          },
          {
            id: '511129',
            text: '沐川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9627,
            gisBd09Lng: 103.91,
            gisGcj02Lat: 28.9565,
            gisGcj02Lng: 103.904,
            stubGroupCnt: null,
            pinYin: 'Muchuan',
          },
          {
            id: '511132',
            text: '峨边彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2366,
            gisBd09Lng: 103.269,
            gisGcj02Lat: 29.2309,
            gisGcj02Lng: 103.263,
            stubGroupCnt: null,
            pinYin: 'Ebian',
          },
          {
            id: '511133',
            text: '马边彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8415,
            gisBd09Lng: 103.552,
            gisGcj02Lat: 28.8353,
            gisGcj02Lng: 103.546,
            stubGroupCnt: null,
            pinYin: 'Mabian',
          },
          {
            id: '511181',
            text: '峨眉山',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5733,
            gisBd09Lng: 103.451,
            gisGcj02Lat: 29.567,
            gisGcj02Lng: 103.445,
            stubGroupCnt: null,
            pinYin: 'Emeishan',
          },
        ],
        gisBd09Lng: 103.774,
        text: '乐山',
        gisBd09Lat: 29.5579,
        gisGcj02Lat: 29.5519,
      },
      {
        pinYin: 'Nanchong',
        id: '511300',
        gisGcj02Lng: 106.112,
        children: [
          {
            id: '511301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8439,
            gisBd09Lng: 106.118,
            gisGcj02Lat: 30.8379,
            gisGcj02Lng: 106.112,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511302',
            text: '顺庆区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8029,
            gisBd09Lng: 106.098,
            gisGcj02Lat: 30.7966,
            gisGcj02Lng: 106.092,
            stubGroupCnt: null,
            pinYin: 'Shunqing',
          },
          {
            id: '511303',
            text: '高坪区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7868,
            gisBd09Lng: 106.126,
            gisGcj02Lat: 30.7809,
            gisGcj02Lng: 106.119,
            stubGroupCnt: null,
            pinYin: 'Gaoping',
          },
          {
            id: '511304',
            text: '嘉陵区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.764,
            gisBd09Lng: 106.08,
            gisGcj02Lat: 30.7577,
            gisGcj02Lng: 106.073,
            stubGroupCnt: null,
            pinYin: 'Jialing',
          },
          {
            id: '511321',
            text: '南部县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3595,
            gisBd09Lng: 106.073,
            gisGcj02Lat: 31.3533,
            gisGcj02Lng: 106.067,
            stubGroupCnt: null,
            pinYin: 'Nanbu',
          },
          {
            id: '511322',
            text: '营山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0815,
            gisBd09Lng: 106.573,
            gisGcj02Lat: 31.0752,
            gisGcj02Lng: 106.567,
            stubGroupCnt: null,
            pinYin: 'Yingshan',
          },
          {
            id: '511323',
            text: '蓬安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0336,
            gisBd09Lng: 106.42,
            gisGcj02Lat: 31.0276,
            gisGcj02Lng: 106.414,
            stubGroupCnt: null,
            pinYin: "Peng'an",
          },
          {
            id: '511324',
            text: '仪陇县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2752,
            gisBd09Lng: 106.311,
            gisGcj02Lat: 31.269,
            gisGcj02Lng: 106.304,
            stubGroupCnt: null,
            pinYin: 'Yilong',
          },
          {
            id: '511325',
            text: '西充县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.999,
            gisBd09Lng: 105.909,
            gisGcj02Lat: 30.9933,
            gisGcj02Lng: 105.902,
            stubGroupCnt: null,
            pinYin: 'Xichong',
          },
          {
            id: '511381',
            text: '阆中',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5627,
            gisBd09Lng: 106.013,
            gisGcj02Lat: 31.5569,
            gisGcj02Lng: 106.006,
            stubGroupCnt: null,
            pinYin: 'Langzhong',
          },
        ],
        gisBd09Lng: 106.118,
        text: '南充',
        gisBd09Lat: 30.8439,
        gisGcj02Lat: 30.8379,
      },
      {
        pinYin: 'Meishan',
        id: '511400',
        gisGcj02Lng: 103.849,
        children: [
          {
            id: '511401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0799,
            gisBd09Lng: 103.855,
            gisGcj02Lat: 30.0742,
            gisGcj02Lng: 103.849,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511402',
            text: '东坡区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0473,
            gisBd09Lng: 103.839,
            gisGcj02Lat: 30.0414,
            gisGcj02Lng: 103.833,
            stubGroupCnt: null,
            pinYin: 'Dongpo',
          },
          {
            id: '511421',
            text: '仁寿县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0005,
            gisBd09Lng: 104.141,
            gisGcj02Lat: 29.9945,
            gisGcj02Lng: 104.135,
            stubGroupCnt: null,
            pinYin: 'Renshou',
          },
          {
            id: '511422',
            text: '彭山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1979,
            gisBd09Lng: 103.88,
            gisGcj02Lat: 30.1921,
            gisGcj02Lng: 103.873,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511423',
            text: '洪雅县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9104,
            gisBd09Lng: 103.38,
            gisGcj02Lat: 29.9047,
            gisGcj02Lng: 103.373,
            stubGroupCnt: null,
            pinYin: 'Hongya',
          },
          {
            id: '511424',
            text: '丹棱县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0196,
            gisBd09Lng: 103.519,
            gisGcj02Lat: 30.0138,
            gisGcj02Lng: 103.513,
            stubGroupCnt: null,
            pinYin: 'Danling',
          },
          {
            id: '511425',
            text: '青神县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8359,
            gisBd09Lng: 103.854,
            gisGcj02Lat: 29.8301,
            gisGcj02Lng: 103.848,
            stubGroupCnt: null,
            pinYin: 'Qingshen',
          },
        ],
        gisBd09Lng: 103.855,
        text: '眉山',
        gisBd09Lat: 30.0799,
        gisGcj02Lat: 30.0742,
      },
      {
        pinYin: 'Yibin',
        id: '511500',
        gisGcj02Lng: 104.641,
        children: [
          {
            id: '511501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7578,
            gisBd09Lng: 104.648,
            gisGcj02Lat: 28.7515,
            gisGcj02Lng: 104.641,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511502',
            text: '翠屏区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7713,
            gisBd09Lng: 104.627,
            gisGcj02Lat: 28.7651,
            gisGcj02Lng: 104.62,
            stubGroupCnt: null,
            pinYin: 'Cuiping',
          },
          {
            id: '511521',
            text: '宜宾县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.696,
            gisBd09Lng: 104.54,
            gisGcj02Lat: 28.6897,
            gisGcj02Lng: 104.534,
            stubGroupCnt: null,
            pinYin: 'Yibin',
          },
          {
            id: '511522',
            text: '南溪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8515,
            gisBd09Lng: 104.976,
            gisGcj02Lat: 28.8455,
            gisGcj02Lng: 104.969,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511523',
            text: '江安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7292,
            gisBd09Lng: 105.076,
            gisGcj02Lat: 28.7235,
            gisGcj02Lng: 105.069,
            stubGroupCnt: null,
            pinYin: "Jiang'an",
          },
          {
            id: '511524',
            text: '长宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5873,
            gisBd09Lng: 104.929,
            gisGcj02Lat: 28.5815,
            gisGcj02Lng: 104.922,
            stubGroupCnt: null,
            pinYin: 'Changning',
          },
          {
            id: '511525',
            text: '高　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4411,
            gisBd09Lng: 104.525,
            gisGcj02Lat: 28.4348,
            gisGcj02Lng: 104.518,
            stubGroupCnt: null,
            pinYin: 'Gaoxian',
          },
          {
            id: '511526',
            text: '珙　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4443,
            gisBd09Lng: 104.717,
            gisGcj02Lat: 28.4386,
            gisGcj02Lng: 104.71,
            stubGroupCnt: null,
            pinYin: 'Gongxian',
          },
          {
            id: '511527',
            text: '筠连县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1709,
            gisBd09Lng: 104.518,
            gisGcj02Lat: 28.1646,
            gisGcj02Lng: 104.511,
            stubGroupCnt: null,
            pinYin: 'Junlian',
          },
          {
            id: '511528',
            text: '兴文县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.31,
            gisBd09Lng: 105.244,
            gisGcj02Lat: 28.3037,
            gisGcj02Lng: 105.238,
            stubGroupCnt: null,
            pinYin: 'Xingwen',
          },
          {
            id: '511529',
            text: '屏山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6592,
            gisBd09Lng: 104.158,
            gisGcj02Lat: 28.6529,
            gisGcj02Lng: 104.151,
            stubGroupCnt: null,
            pinYin: 'Pingshan',
          },
        ],
        gisBd09Lng: 104.648,
        text: '宜宾',
        gisBd09Lat: 28.7578,
        gisGcj02Lat: 28.7515,
      },
      {
        pinYin: "Guang'an",
        id: '511600',
        gisGcj02Lng: 104.151,
        children: [
          {
            id: '511601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6592,
            gisBd09Lng: 104.158,
            gisGcj02Lat: 28.6529,
            gisGcj02Lng: 104.151,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511602',
            text: '广安区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4803,
            gisBd09Lng: 106.65,
            gisGcj02Lat: 30.4744,
            gisGcj02Lng: 106.643,
            stubGroupCnt: null,
            pinYin: "Guang'an",
          },
          {
            id: '511621',
            text: '岳池县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5446,
            gisBd09Lng: 106.447,
            gisGcj02Lat: 30.5383,
            gisGcj02Lng: 106.441,
            stubGroupCnt: null,
            pinYin: 'Yuechi',
          },
          {
            id: '511622',
            text: '武胜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3551,
            gisBd09Lng: 106.302,
            gisGcj02Lat: 30.349,
            gisGcj02Lng: 106.295,
            stubGroupCnt: null,
            pinYin: 'Wusheng',
          },
          {
            id: '511623',
            text: '邻水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3402,
            gisBd09Lng: 106.939,
            gisGcj02Lat: 30.334,
            gisGcj02Lng: 106.932,
            stubGroupCnt: null,
            pinYin: 'Linshui',
          },
          {
            id: '511681',
            text: '华莹',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3945,
            gisBd09Lng: 106.79,
            gisGcj02Lat: 30.3882,
            gisGcj02Lng: 106.783,
            stubGroupCnt: null,
            pinYin: 'Huaying',
          },
        ],
        gisBd09Lng: 104.158,
        text: '广安',
        gisBd09Lat: 28.6592,
        gisGcj02Lat: 28.6529,
      },
      {
        pinYin: 'Dazhou',
        id: '511700',
        gisGcj02Lng: 107.469,
        children: [
          {
            id: '511701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.215,
            gisBd09Lng: 107.476,
            gisGcj02Lat: 31.2092,
            gisGcj02Lng: 107.469,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511702',
            text: '通川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2215,
            gisBd09Lng: 107.512,
            gisGcj02Lat: 31.2152,
            gisGcj02Lng: 107.505,
            stubGroupCnt: null,
            pinYin: 'Tongchuan',
          },
          {
            id: '511703',
            text: '达川区',
            status: 0,
            orderId: '2016.03.28 13:38:56',
            createAccount: '',
            createTime: '2016.03.28 13:38:59',
            modifyAccount: '',
            modifyTime: '2016.03.28 13:39:02',
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 31.1754,
            gisGcj02Lng: 107.419,
            stubGroupCnt: null,
            pinYin: 'Dachuan',
          },
          {
            id: '511721',
            text: '达　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.1816,
            gisBd09Lng: 107.425,
            gisGcj02Lat: 31.1754,
            gisGcj02Lng: 107.419,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511722',
            text: '宣汉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3596,
            gisBd09Lng: 107.734,
            gisGcj02Lat: 31.3536,
            gisGcj02Lng: 107.728,
            stubGroupCnt: null,
            pinYin: 'Xuanhan',
          },
          {
            id: '511723',
            text: '开江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0896,
            gisBd09Lng: 107.876,
            gisGcj02Lat: 31.0833,
            gisGcj02Lng: 107.87,
            stubGroupCnt: null,
            pinYin: 'Kaijiang',
          },
          {
            id: '511724',
            text: '大竹县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7419,
            gisBd09Lng: 107.211,
            gisGcj02Lat: 30.7361,
            gisGcj02Lng: 107.205,
            stubGroupCnt: null,
            pinYin: 'Dazhu',
          },
          {
            id: '511725',
            text: '渠　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8436,
            gisBd09Lng: 106.98,
            gisGcj02Lat: 30.8379,
            gisGcj02Lng: 106.973,
            stubGroupCnt: null,
            pinYin: 'Quxian',
          },
          {
            id: '511781',
            text: '万源',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.0872,
            gisBd09Lng: 108.039,
            gisGcj02Lat: 32.0812,
            gisGcj02Lng: 108.032,
            stubGroupCnt: null,
            pinYin: 'Wanyuan',
          },
        ],
        gisBd09Lng: 107.476,
        text: '达州',
        gisBd09Lat: 31.215,
        gisGcj02Lat: 31.2092,
      },
      {
        pinYin: "Ya'an",
        id: '511800',
        gisGcj02Lng: 103.045,
        children: [
          {
            id: '511801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.016,
            gisBd09Lng: 103.052,
            gisGcj02Lat: 30.0101,
            gisGcj02Lng: 103.045,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511802',
            text: '雨城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0107,
            gisBd09Lng: 103.04,
            gisGcj02Lat: 30.0049,
            gisGcj02Lng: 103.033,
            stubGroupCnt: null,
            pinYin: 'Yucheng',
          },
          {
            id: '511821',
            text: '名山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1321,
            gisBd09Lng: 103.227,
            gisGcj02Lat: 30.126,
            gisGcj02Lng: 103.221,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511822',
            text: '荥经县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7992,
            gisBd09Lng: 102.854,
            gisGcj02Lat: 29.7929,
            gisGcj02Lng: 102.848,
            stubGroupCnt: null,
            pinYin: 'Yingjing',
          },
          {
            id: '511823',
            text: '汉源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3507,
            gisBd09Lng: 102.657,
            gisGcj02Lat: 29.3449,
            gisGcj02Lng: 102.651,
            stubGroupCnt: null,
            pinYin: 'Hanyuan',
          },
          {
            id: '511824',
            text: '石棉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2342,
            gisBd09Lng: 102.368,
            gisGcj02Lat: 29.2279,
            gisGcj02Lng: 102.362,
            stubGroupCnt: null,
            pinYin: 'Shimian',
          },
          {
            id: '511825',
            text: '天全县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0718,
            gisBd09Lng: 102.765,
            gisGcj02Lat: 30.066,
            gisGcj02Lng: 102.759,
            stubGroupCnt: null,
            pinYin: 'Tianquan',
          },
          {
            id: '511826',
            text: '芦山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1502,
            gisBd09Lng: 102.935,
            gisGcj02Lat: 30.1442,
            gisGcj02Lng: 102.929,
            stubGroupCnt: null,
            pinYin: 'Lushan',
          },
          {
            id: '511827',
            text: '宝兴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.375,
            gisBd09Lng: 102.824,
            gisGcj02Lat: 30.3689,
            gisGcj02Lng: 102.817,
            stubGroupCnt: null,
            pinYin: 'Baoxing',
          },
        ],
        gisBd09Lng: 103.052,
        text: '雅安',
        gisBd09Lat: 30.016,
        gisGcj02Lat: 30.0101,
      },
      {
        pinYin: 'Bazhong',
        id: '511900',
        gisGcj02Lng: 106.747,
        children: [
          {
            id: '511901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8726,
            gisBd09Lng: 106.754,
            gisGcj02Lat: 31.8669,
            gisGcj02Lng: 106.747,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '511902',
            text: '巴州区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8567,
            gisBd09Lng: 106.776,
            gisGcj02Lat: 31.8507,
            gisGcj02Lng: 106.77,
            stubGroupCnt: null,
            pinYin: 'Bazhou',
          },
          {
            id: '511921',
            text: '通江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9176,
            gisBd09Lng: 107.252,
            gisGcj02Lat: 31.9116,
            gisGcj02Lng: 107.245,
            stubGroupCnt: null,
            pinYin: 'Tongjiang',
          },
          {
            id: '511922',
            text: '南江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.3526,
            gisBd09Lng: 106.836,
            gisGcj02Lat: 32.3466,
            gisGcj02Lng: 106.83,
            stubGroupCnt: null,
            pinYin: 'Nanjiang',
          },
          {
            id: '511923',
            text: '平昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.5665,
            gisBd09Lng: 107.111,
            gisGcj02Lat: 31.5608,
            gisGcj02Lng: 107.104,
            stubGroupCnt: null,
            pinYin: 'Pingchang',
          },
        ],
        gisBd09Lng: 106.754,
        text: '巴中',
        gisBd09Lat: 31.8726,
        gisGcj02Lat: 31.8669,
      },
      {
        pinYin: 'Ziyang',
        id: '512000',
        gisGcj02Lng: 104.626,
        children: [
          {
            id: '512001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1343,
            gisBd09Lng: 104.633,
            gisGcj02Lat: 30.1281,
            gisGcj02Lng: 104.626,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '512002',
            text: '雁江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.124,
            gisBd09Lng: 104.66,
            gisGcj02Lat: 30.1177,
            gisGcj02Lng: 104.653,
            stubGroupCnt: null,
            pinYin: 'Yanjiang',
          },
          {
            id: '512021',
            text: '安岳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1037,
            gisBd09Lng: 105.343,
            gisGcj02Lat: 30.0976,
            gisGcj02Lng: 105.337,
            stubGroupCnt: null,
            pinYin: 'Anyue',
          },
          {
            id: '512022',
            text: '乐至县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.282,
            gisBd09Lng: 105.03,
            gisGcj02Lat: 30.2759,
            gisGcj02Lng: 105.024,
            stubGroupCnt: null,
            pinYin: 'Lezhi',
          },
          {
            id: '512081',
            text: '简阳',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.4177,
            gisBd09Lng: 104.556,
            gisGcj02Lat: 30.4117,
            gisGcj02Lng: 104.549,
            stubGroupCnt: null,
            pinYin: 'Jianyang',
          },
        ],
        gisBd09Lng: 104.633,
        text: '资阳',
        gisBd09Lat: 30.1343,
        gisGcj02Lat: 30.1281,
      },
      {
        pinYin: 'Aba',
        id: '513200',
        gisGcj02Lng: 102.225,
        children: [
          {
            id: '513221',
            text: '汶川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4822,
            gisBd09Lng: 103.594,
            gisGcj02Lat: 31.4762,
            gisGcj02Lng: 103.588,
            stubGroupCnt: null,
            pinYin: 'Wenchuan',
          },
          {
            id: '513222',
            text: '理　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4407,
            gisBd09Lng: 103.173,
            gisGcj02Lat: 31.4347,
            gisGcj02Lng: 103.167,
            stubGroupCnt: null,
            pinYin: 'Lixian',
          },
          {
            id: '513223',
            text: '茂　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6882,
            gisBd09Lng: 103.862,
            gisGcj02Lat: 31.6825,
            gisGcj02Lng: 103.856,
            stubGroupCnt: null,
            pinYin: 'Maoxian',
          },
          {
            id: '513224',
            text: '松潘县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.6441,
            gisBd09Lng: 103.604,
            gisGcj02Lat: 32.6382,
            gisGcj02Lng: 103.597,
            stubGroupCnt: null,
            pinYin: 'Songpan',
          },
          {
            id: '513225',
            text: '九寨沟县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.2675,
            gisBd09Lng: 104.244,
            gisGcj02Lat: 33.2617,
            gisGcj02Lng: 104.237,
            stubGroupCnt: null,
            pinYin: 'Jiuzhaigou',
          },
          {
            id: '513226',
            text: '金川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.4827,
            gisBd09Lng: 102.071,
            gisGcj02Lat: 31.477,
            gisGcj02Lng: 102.064,
            stubGroupCnt: null,
            pinYin: 'Jinchuan',
          },
          {
            id: '513227',
            text: '小金县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0046,
            gisBd09Lng: 102.371,
            gisGcj02Lat: 30.9983,
            gisGcj02Lng: 102.364,
            stubGroupCnt: null,
            pinYin: 'Xiaojin',
          },
          {
            id: '513228',
            text: '黑水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.068,
            gisBd09Lng: 102.998,
            gisGcj02Lat: 32.0621,
            gisGcj02Lng: 102.992,
            stubGroupCnt: null,
            pinYin: 'Heishui',
          },
          {
            id: '513229',
            text: '马尔康县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.9119,
            gisBd09Lng: 102.215,
            gisGcj02Lat: 31.9059,
            gisGcj02Lng: 102.209,
            stubGroupCnt: null,
            pinYin: 'Maerkang',
          },
          {
            id: '513230',
            text: '壤塘县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2708,
            gisBd09Lng: 100.987,
            gisGcj02Lat: 32.2651,
            gisGcj02Lng: 100.981,
            stubGroupCnt: null,
            pinYin: 'Rangtang',
          },
          {
            id: '513231',
            text: '阿坝县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.908,
            gisBd09Lng: 101.714,
            gisGcj02Lat: 32.9023,
            gisGcj02Lng: 101.708,
            stubGroupCnt: null,
            pinYin: 'Aba',
          },
          {
            id: '513232',
            text: '若尔盖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 33.5819,
            gisBd09Lng: 102.97,
            gisGcj02Lat: 33.5756,
            gisGcj02Lng: 102.963,
            stubGroupCnt: null,
            pinYin: 'Ruoergai',
          },
          {
            id: '513233',
            text: '红原县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.7967,
            gisBd09Lng: 102.553,
            gisGcj02Lat: 32.791,
            gisGcj02Lng: 102.546,
            stubGroupCnt: null,
            pinYin: 'Hongyuan',
          },
        ],
        gisBd09Lng: 102.232,
        text: '阿坝藏族羌族自治州',
        gisBd09Lat: 31.9056,
        gisGcj02Lat: 31.8994,
      },
      {
        pinYin: 'Garze',
        id: '513300',
        gisGcj02Lng: 101.963,
        children: [
          {
            id: '513321',
            text: '康定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0043,
            gisBd09Lng: 101.965,
            gisGcj02Lat: 29.9984,
            gisGcj02Lng: 101.959,
            stubGroupCnt: null,
            pinYin: 'Kangding',
          },
          {
            id: '513322',
            text: '泸定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9209,
            gisBd09Lng: 102.243,
            gisGcj02Lat: 29.9146,
            gisGcj02Lng: 102.237,
            stubGroupCnt: null,
            pinYin: 'Luding',
          },
          {
            id: '513323',
            text: '丹巴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.884,
            gisBd09Lng: 101.899,
            gisGcj02Lat: 30.8777,
            gisGcj02Lng: 101.893,
            stubGroupCnt: null,
            pinYin: 'Danba',
          },
          {
            id: '513324',
            text: '九龙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.006,
            gisBd09Lng: 101.516,
            gisGcj02Lat: 28.9998,
            gisGcj02Lng: 101.509,
            stubGroupCnt: null,
            pinYin: 'Jiulong',
          },
          {
            id: '513325',
            text: '雅江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0379,
            gisBd09Lng: 101.023,
            gisGcj02Lat: 30.0318,
            gisGcj02Lng: 101.016,
            stubGroupCnt: null,
            pinYin: 'Yajiang',
          },
          {
            id: '513326',
            text: '道孚县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9854,
            gisBd09Lng: 101.134,
            gisGcj02Lat: 30.9794,
            gisGcj02Lng: 101.128,
            stubGroupCnt: null,
            pinYin: 'Daofu',
          },
          {
            id: '513327',
            text: '炉霍县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.3967,
            gisBd09Lng: 100.685,
            gisGcj02Lat: 31.3904,
            gisGcj02Lng: 100.678,
            stubGroupCnt: null,
            pinYin: 'Luhuo',
          },
          {
            id: '513328',
            text: '甘孜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.6287,
            gisBd09Lng: 100.001,
            gisGcj02Lat: 31.6228,
            gisGcj02Lng: 99.9947,
            stubGroupCnt: null,
            pinYin: 'Ganzi',
          },
          {
            id: '513329',
            text: '新龙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.9447,
            gisBd09Lng: 100.317,
            gisGcj02Lat: 30.9385,
            gisGcj02Lng: 100.311,
            stubGroupCnt: null,
            pinYin: 'Xinlong',
          },
          {
            id: '513330',
            text: '德格县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.8121,
            gisBd09Lng: 98.5895,
            gisGcj02Lat: 31.8064,
            gisGcj02Lng: 98.5829,
            stubGroupCnt: null,
            pinYin: 'Dege',
          },
          {
            id: '513331',
            text: '白玉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.2155,
            gisBd09Lng: 98.8334,
            gisGcj02Lat: 31.2098,
            gisGcj02Lng: 98.8268,
            stubGroupCnt: null,
            pinYin: 'Baiyu',
          },
          {
            id: '513332',
            text: '石渠县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.9843,
            gisBd09Lng: 98.1117,
            gisGcj02Lat: 32.9786,
            gisGcj02Lng: 98.1051,
            stubGroupCnt: null,
            pinYin: 'Shiqu',
          },
          {
            id: '513333',
            text: '色达县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 32.2747,
            gisBd09Lng: 100.342,
            gisGcj02Lat: 32.2685,
            gisGcj02Lng: 100.336,
            stubGroupCnt: null,
            pinYin: 'Seda',
          },
          {
            id: '513334',
            text: '理塘县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0015,
            gisBd09Lng: 100.277,
            gisGcj02Lat: 29.9958,
            gisGcj02Lng: 100.271,
            stubGroupCnt: null,
            pinYin: 'Litang',
          },
          {
            id: '513335',
            text: '巴塘县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0109,
            gisBd09Lng: 99.1188,
            gisGcj02Lat: 30.0046,
            gisGcj02Lng: 99.1124,
            stubGroupCnt: null,
            pinYin: 'Batang',
          },
          {
            id: '513336',
            text: '乡城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9373,
            gisBd09Lng: 99.8053,
            gisGcj02Lat: 28.9314,
            gisGcj02Lng: 99.7987,
            stubGroupCnt: null,
            pinYin: 'Xiangcheng',
          },
          {
            id: '513337',
            text: '稻城县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0429,
            gisBd09Lng: 100.305,
            gisGcj02Lat: 29.0368,
            gisGcj02Lng: 100.299,
            stubGroupCnt: null,
            pinYin: 'Daocheng',
          },
          {
            id: '513338',
            text: '得荣县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7186,
            gisBd09Lng: 99.2967,
            gisGcj02Lat: 28.7129,
            gisGcj02Lng: 99.2901,
            stubGroupCnt: null,
            pinYin: 'Derong',
          },
        ],
        gisBd09Lng: 101.97,
        text: '甘孜藏族自治州',
        gisBd09Lat: 30.0533,
        gisGcj02Lat: 30.0474,
      },
      {
        pinYin: 'Liangshan',
        id: '513400',
        gisGcj02Lng: 102.269,
        children: [
          {
            id: '513401',
            text: '西昌',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9002,
            gisBd09Lng: 102.27,
            gisGcj02Lat: 27.8941,
            gisGcj02Lng: 102.264,
            stubGroupCnt: null,
            pinYin: 'Xichang',
          },
          {
            id: '513422',
            text: '木里藏族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9348,
            gisBd09Lng: 101.288,
            gisGcj02Lat: 27.9285,
            gisGcj02Lng: 101.281,
            stubGroupCnt: null,
            pinYin: 'Muli',
          },
          {
            id: '513423',
            text: '盐源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4293,
            gisBd09Lng: 101.516,
            gisGcj02Lat: 27.423,
            gisGcj02Lng: 101.51,
            stubGroupCnt: null,
            pinYin: 'Yanyuan',
          },
          {
            id: '513424',
            text: '德昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4083,
            gisBd09Lng: 102.183,
            gisGcj02Lat: 27.4026,
            gisGcj02Lng: 102.177,
            stubGroupCnt: null,
            pinYin: 'Dechang',
          },
          {
            id: '513425',
            text: '会理县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6615,
            gisBd09Lng: 102.251,
            gisGcj02Lat: 26.6552,
            gisGcj02Lng: 102.245,
            stubGroupCnt: null,
            pinYin: 'Huili',
          },
          {
            id: '513426',
            text: '会东县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6409,
            gisBd09Lng: 102.586,
            gisGcj02Lat: 26.6348,
            gisGcj02Lng: 102.579,
            stubGroupCnt: null,
            pinYin: 'Huidong',
          },
          {
            id: '513427',
            text: '宁南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.0735,
            gisBd09Lng: 102.765,
            gisGcj02Lat: 27.0677,
            gisGcj02Lng: 102.759,
            stubGroupCnt: null,
            pinYin: 'Ningnan',
          },
          {
            id: '513428',
            text: '普格县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3822,
            gisBd09Lng: 102.546,
            gisGcj02Lat: 27.3765,
            gisGcj02Lng: 102.539,
            stubGroupCnt: null,
            pinYin: 'Puge',
          },
          {
            id: '513429',
            text: '布拖县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7122,
            gisBd09Lng: 102.819,
            gisGcj02Lat: 27.7062,
            gisGcj02Lng: 102.812,
            stubGroupCnt: null,
            pinYin: 'Butuo',
          },
          {
            id: '513430',
            text: '金阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 28.0143,
            gisGcj02Lng: 102.844,
            stubGroupCnt: null,
            pinYin: 'Jinyang',
          },
          {
            id: '513431',
            text: '昭觉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.0206,
            gisBd09Lng: 102.851,
            gisGcj02Lat: 28.0143,
            gisGcj02Lng: 102.844,
            stubGroupCnt: null,
            pinYin: 'Zhaojue',
          },
          {
            id: '513432',
            text: '喜德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3129,
            gisBd09Lng: 102.421,
            gisGcj02Lat: 28.3072,
            gisGcj02Lng: 102.414,
            stubGroupCnt: null,
            pinYin: 'Xide',
          },
          {
            id: '513433',
            text: '冕宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5559,
            gisBd09Lng: 102.183,
            gisGcj02Lat: 28.5502,
            gisGcj02Lng: 102.176,
            stubGroupCnt: null,
            pinYin: 'Mianning',
          },
          {
            id: '513434',
            text: '越西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6456,
            gisBd09Lng: 102.514,
            gisGcj02Lat: 28.6396,
            gisGcj02Lng: 102.507,
            stubGroupCnt: null,
            pinYin: 'Yuexi',
          },
          {
            id: '513435',
            text: '甘洛县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9643,
            gisBd09Lng: 102.778,
            gisGcj02Lat: 28.9585,
            gisGcj02Lng: 102.771,
            stubGroupCnt: null,
            pinYin: 'Ganluo',
          },
          {
            id: '513436',
            text: '美姑县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3341,
            gisBd09Lng: 103.139,
            gisGcj02Lat: 28.3284,
            gisGcj02Lng: 103.133,
            stubGroupCnt: null,
            pinYin: 'Meigu',
          },
          {
            id: '513437',
            text: '雷波县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2691,
            gisBd09Lng: 103.579,
            gisGcj02Lat: 28.2629,
            gisGcj02Lng: 103.572,
            stubGroupCnt: null,
            pinYin: 'Leibo',
          },
        ],
        gisBd09Lng: 102.276,
        text: '凉山彝族自治州',
        gisBd09Lat: 27.8884,
        gisGcj02Lat: 27.8824,
      },
    ],
  },

  {
    text: '云南省',
    children: [
      {
        pinYin: 'Kunming',
        id: '530100',
        gisGcj02Lng: 102.836,
        children: [
          {
            id: '530101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8854,
            gisBd09Lng: 102.842,
            gisGcj02Lat: 24.8791,
            gisGcj02Lng: 102.836,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530102',
            text: '五华区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0505,
            gisBd09Lng: 102.715,
            gisGcj02Lat: 25.0443,
            gisGcj02Lng: 102.708,
            stubGroupCnt: null,
            pinYin: 'Wuhua',
          },
          {
            id: '530103',
            text: '盘龙区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1225,
            gisBd09Lng: 102.759,
            gisGcj02Lat: 25.1166,
            gisGcj02Lng: 102.753,
            stubGroupCnt: null,
            pinYin: 'Panlong',
          },
          {
            id: '530111',
            text: '官渡区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.021,
            gisBd09Lng: 102.75,
            gisGcj02Lat: 25.0149,
            gisGcj02Lng: 102.744,
            stubGroupCnt: null,
            pinYin: 'Guandu',
          },
          {
            id: '530112',
            text: '西山区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0441,
            gisBd09Lng: 102.671,
            gisGcj02Lat: 25.0385,
            gisGcj02Lng: 102.665,
            stubGroupCnt: null,
            pinYin: 'Xishan',
          },
          {
            id: '530113',
            text: '东川区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.088,
            gisBd09Lng: 103.193,
            gisGcj02Lat: 26.0818,
            gisGcj02Lng: 103.187,
            stubGroupCnt: null,
            pinYin: 'Dongchuan',
          },
          {
            id: '530121',
            text: '呈贡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8717,
            gisBd09Lng: 102.872,
            gisGcj02Lat: 24.8656,
            gisGcj02Lng: 102.866,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530122',
            text: '晋宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.676,
            gisBd09Lng: 102.602,
            gisGcj02Lat: 24.6697,
            gisGcj02Lng: 102.596,
            stubGroupCnt: null,
            pinYin: 'Jinning',
          },
          {
            id: '530124',
            text: '富民县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2281,
            gisBd09Lng: 102.505,
            gisGcj02Lat: 25.2219,
            gisGcj02Lng: 102.499,
            stubGroupCnt: null,
            pinYin: 'Fumin',
          },
          {
            id: '530125',
            text: '宜良县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9262,
            gisBd09Lng: 103.148,
            gisGcj02Lat: 24.9205,
            gisGcj02Lng: 103.142,
            stubGroupCnt: null,
            pinYin: 'Yiliang',
          },
          {
            id: '530126',
            text: '石林彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7766,
            gisBd09Lng: 103.301,
            gisGcj02Lat: 24.7705,
            gisGcj02Lng: 103.294,
            stubGroupCnt: null,
            pinYin: 'Shilin',
          },
          {
            id: '530127',
            text: '嵩明县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3447,
            gisBd09Lng: 103.042,
            gisGcj02Lat: 25.3389,
            gisGcj02Lng: 103.036,
            stubGroupCnt: null,
            pinYin: 'Songming',
          },
          {
            id: '530128',
            text: '禄劝彝族苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5577,
            gisBd09Lng: 102.478,
            gisGcj02Lat: 25.5514,
            gisGcj02Lng: 102.472,
            stubGroupCnt: null,
            pinYin: 'Luquan',
          },
          {
            id: '530129',
            text: '寻甸回族彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5636,
            gisBd09Lng: 103.263,
            gisGcj02Lat: 25.5579,
            gisGcj02Lng: 103.257,
            stubGroupCnt: null,
            pinYin: 'Xundian',
          },
          {
            id: '530181',
            text: '安宁',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.9255,
            gisBd09Lng: 102.485,
            gisGcj02Lat: 24.9192,
            gisGcj02Lng: 102.479,
            stubGroupCnt: null,
            pinYin: 'Anning',
          },
        ],
        gisBd09Lng: 102.842,
        text: '昆明',
        gisBd09Lat: 24.8854,
        gisGcj02Lat: 24.8791,
      },
      {
        pinYin: 'Qujing',
        id: '530300',
        gisGcj02Lng: 103.798,
        children: [
          {
            id: '530301',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4943,
            gisBd09Lng: 103.804,
            gisGcj02Lat: 25.488,
            gisGcj02Lng: 103.798,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530302',
            text: '麒麟区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5019,
            gisBd09Lng: 103.812,
            gisGcj02Lat: 25.4956,
            gisGcj02Lng: 103.806,
            stubGroupCnt: null,
            pinYin: 'Qilin',
          },
          {
            id: '530321',
            text: '马龙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4349,
            gisBd09Lng: 103.587,
            gisGcj02Lat: 25.4287,
            gisGcj02Lng: 103.58,
            stubGroupCnt: null,
            pinYin: 'Malong',
          },
          {
            id: '530322',
            text: '陆良县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0355,
            gisBd09Lng: 103.674,
            gisGcj02Lat: 25.0293,
            gisGcj02Lng: 103.668,
            stubGroupCnt: null,
            pinYin: 'Luliang',
          },
          {
            id: '530323',
            text: '师宗县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8342,
            gisBd09Lng: 103.998,
            gisGcj02Lat: 24.8285,
            gisGcj02Lng: 103.992,
            stubGroupCnt: null,
            pinYin: 'Shizong',
          },
          {
            id: '530324',
            text: '罗平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8907,
            gisBd09Lng: 104.317,
            gisGcj02Lat: 24.8847,
            gisGcj02Lng: 104.31,
            stubGroupCnt: null,
            pinYin: 'Luoping',
          },
          {
            id: '530325',
            text: '富源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6805,
            gisBd09Lng: 104.262,
            gisGcj02Lat: 25.6744,
            gisGcj02Lng: 104.256,
            stubGroupCnt: null,
            pinYin: 'Fuyuan',
          },
          {
            id: '530326',
            text: '会泽县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4242,
            gisBd09Lng: 103.305,
            gisGcj02Lat: 26.4181,
            gisGcj02Lng: 103.299,
            stubGroupCnt: null,
            pinYin: 'Huize',
          },
          {
            id: '530328',
            text: '沾益县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6063,
            gisBd09Lng: 103.83,
            gisGcj02Lat: 25.6002,
            gisGcj02Lng: 103.823,
            stubGroupCnt: null,
            pinYin: 'Zhanyi',
          },
          {
            id: '530381',
            text: '宣威',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.2256,
            gisBd09Lng: 104.112,
            gisGcj02Lat: 26.2199,
            gisGcj02Lng: 104.105,
            stubGroupCnt: null,
            pinYin: 'Xuanwei',
          },
        ],
        gisBd09Lng: 103.804,
        text: '曲靖',
        gisBd09Lat: 25.4943,
        gisGcj02Lat: 25.488,
      },
      {
        pinYin: 'Yuxi',
        id: '530400',
        gisGcj02Lng: 102.547,
        children: [
          {
            id: '530401',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3572,
            gisBd09Lng: 102.554,
            gisGcj02Lat: 24.3515,
            gisGcj02Lng: 102.547,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530402',
            text: '红塔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3599,
            gisBd09Lng: 102.552,
            gisGcj02Lat: 24.3542,
            gisGcj02Lng: 102.545,
            stubGroupCnt: null,
            pinYin: 'Hongta',
          },
          {
            id: '530421',
            text: '江川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.2934,
            gisBd09Lng: 102.761,
            gisGcj02Lat: 24.2875,
            gisGcj02Lng: 102.754,
            stubGroupCnt: null,
            pinYin: 'Jiangchuan',
          },
          {
            id: '530422',
            text: '澄江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6796,
            gisBd09Lng: 102.915,
            gisGcj02Lat: 24.6739,
            gisGcj02Lng: 102.909,
            stubGroupCnt: null,
            pinYin: 'Chengjiang',
          },
          {
            id: '530423',
            text: '通海县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1195,
            gisBd09Lng: 102.773,
            gisGcj02Lat: 24.1137,
            gisGcj02Lng: 102.767,
            stubGroupCnt: null,
            pinYin: 'Tonghai',
          },
          {
            id: '530424',
            text: '华宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.199,
            gisBd09Lng: 102.936,
            gisGcj02Lat: 24.193,
            gisGcj02Lng: 102.929,
            stubGroupCnt: null,
            pinYin: 'Huaning',
          },
          {
            id: '530425',
            text: '易门县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6764,
            gisBd09Lng: 102.17,
            gisGcj02Lat: 24.6706,
            gisGcj02Lng: 102.163,
            stubGroupCnt: null,
            pinYin: 'Yimen',
          },
          {
            id: '530426',
            text: '峨山彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.1754,
            gisBd09Lng: 102.413,
            gisGcj02Lat: 24.1696,
            gisGcj02Lng: 102.407,
            stubGroupCnt: null,
            pinYin: 'Eshan',
          },
          {
            id: '530427',
            text: '新平彝族傣族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0758,
            gisBd09Lng: 101.997,
            gisGcj02Lat: 24.0695,
            gisGcj02Lng: 101.99,
            stubGroupCnt: null,
            pinYin: 'Xinping',
          },
          {
            id: '530428',
            text: '元江哈尼族彝族傣族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6017,
            gisBd09Lng: 102.011,
            gisGcj02Lat: 23.5954,
            gisGcj02Lng: 102.005,
            stubGroupCnt: null,
            pinYin: 'Yuanjiang',
          },
        ],
        gisBd09Lng: 102.554,
        text: '玉溪',
        gisBd09Lat: 24.3572,
        gisGcj02Lat: 24.3515,
      },
      {
        pinYin: 'Baoshan',
        id: '530500',
        gisGcj02Lng: 99.1623,
        children: [
          {
            id: '530501',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.118,
            gisBd09Lng: 99.1689,
            gisGcj02Lat: 25.1122,
            gisGcj02Lng: 99.1623,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530502',
            text: '隆阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1269,
            gisBd09Lng: 99.1723,
            gisGcj02Lat: 25.1212,
            gisGcj02Lng: 99.1657,
            stubGroupCnt: null,
            pinYin: 'Longyang',
          },
          {
            id: '530521',
            text: '施甸县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7289,
            gisBd09Lng: 99.1949,
            gisGcj02Lat: 24.7232,
            gisGcj02Lng: 99.1884,
            stubGroupCnt: null,
            pinYin: 'Shidian',
          },
          {
            id: '530522',
            text: '腾冲县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.026,
            gisBd09Lng: 98.4969,
            gisGcj02Lat: 25.02,
            gisGcj02Lng: 98.4904,
            stubGroupCnt: null,
            pinYin: 'Tengchong',
          },
          {
            id: '530523',
            text: '龙陵县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5934,
            gisBd09Lng: 98.6973,
            gisGcj02Lat: 24.5876,
            gisGcj02Lng: 98.6907,
            stubGroupCnt: null,
            pinYin: 'Longling',
          },
          {
            id: '530524',
            text: '昌宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8342,
            gisBd09Lng: 99.6127,
            gisGcj02Lat: 24.8279,
            gisGcj02Lng: 99.6062,
            stubGroupCnt: null,
            pinYin: 'Changning',
          },
        ],
        gisBd09Lng: 99.1689,
        text: '保山',
        gisBd09Lat: 25.118,
        gisGcj02Lat: 25.1122,
      },
      {
        pinYin: 'Zhaotong',
        id: '530600',
        gisGcj02Lng: 103.717,
        children: [
          {
            id: '530601',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.3441,
            gisBd09Lng: 103.724,
            gisGcj02Lat: 27.3382,
            gisGcj02Lng: 103.717,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530602',
            text: '昭阳区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.326,
            gisBd09Lng: 103.713,
            gisGcj02Lat: 27.32,
            gisGcj02Lng: 103.706,
            stubGroupCnt: null,
            pinYin: 'Zhaoyang',
          },
          {
            id: '530621',
            text: '鲁甸县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1931,
            gisBd09Lng: 103.565,
            gisGcj02Lat: 27.1868,
            gisGcj02Lng: 103.559,
            stubGroupCnt: null,
            pinYin: 'Ludian',
          },
          {
            id: '530622',
            text: '巧家县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9146,
            gisBd09Lng: 102.937,
            gisGcj02Lat: 26.9086,
            gisGcj02Lng: 102.931,
            stubGroupCnt: null,
            pinYin: 'Qiaojia',
          },
          {
            id: '530623',
            text: '盐津县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1145,
            gisBd09Lng: 104.242,
            gisGcj02Lat: 28.1087,
            gisGcj02Lng: 104.235,
            stubGroupCnt: null,
            pinYin: 'Yanjin',
          },
          {
            id: '530624',
            text: '大关县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7543,
            gisBd09Lng: 103.898,
            gisGcj02Lat: 27.7483,
            gisGcj02Lng: 103.892,
            stubGroupCnt: null,
            pinYin: 'Daguan',
          },
          {
            id: '530625',
            text: '永善县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.2347,
            gisBd09Lng: 103.645,
            gisGcj02Lat: 28.2289,
            gisGcj02Lng: 103.639,
            stubGroupCnt: null,
            pinYin: 'Yongshan',
          },
          {
            id: '530626',
            text: '绥江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6042,
            gisBd09Lng: 103.965,
            gisGcj02Lat: 28.5983,
            gisGcj02Lng: 103.959,
            stubGroupCnt: null,
            pinYin: 'Suijiang',
          },
          {
            id: '530627',
            text: '镇雄县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.4475,
            gisBd09Lng: 104.881,
            gisGcj02Lat: 27.4412,
            gisGcj02Lng: 104.875,
            stubGroupCnt: null,
            pinYin: 'Zhenxiong',
          },
          {
            id: '530628',
            text: '彝良县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6312,
            gisBd09Lng: 104.055,
            gisGcj02Lat: 27.6249,
            gisGcj02Lng: 104.048,
            stubGroupCnt: null,
            pinYin: 'Yiliang',
          },
          {
            id: '530629',
            text: '威信县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8525,
            gisBd09Lng: 105.056,
            gisGcj02Lat: 27.8467,
            gisGcj02Lng: 105.049,
            stubGroupCnt: null,
            pinYin: 'Weixin',
          },
          {
            id: '530630',
            text: '水富县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.636,
            gisBd09Lng: 104.423,
            gisGcj02Lat: 28.6298,
            gisGcj02Lng: 104.417,
            stubGroupCnt: null,
            pinYin: 'Shuifu',
          },
        ],
        gisBd09Lng: 103.724,
        text: '昭通',
        gisBd09Lat: 27.3441,
        gisGcj02Lat: 27.3382,
      },
      {
        pinYin: 'Lijiang',
        id: '530700',
        gisGcj02Lng: 100.227,
        children: [
          {
            id: '530701',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8626,
            gisBd09Lng: 100.233,
            gisGcj02Lat: 26.8565,
            gisGcj02Lng: 100.227,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530702',
            text: '古城区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8831,
            gisBd09Lng: 100.232,
            gisGcj02Lat: 26.877,
            gisGcj02Lng: 100.226,
            stubGroupCnt: null,
            pinYin: 'Gucheng',
          },
          {
            id: '530721',
            text: '玉龙纳西族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.8274,
            gisBd09Lng: 100.243,
            gisGcj02Lat: 26.8215,
            gisGcj02Lng: 100.237,
            stubGroupCnt: null,
            pinYin: 'Yulong',
          },
          {
            id: '530722',
            text: '永胜县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.69,
            gisBd09Lng: 100.758,
            gisGcj02Lat: 26.6842,
            gisGcj02Lng: 100.751,
            stubGroupCnt: null,
            pinYin: 'Yongsheng',
          },
          {
            id: '530723',
            text: '华坪县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.6356,
            gisBd09Lng: 101.273,
            gisGcj02Lat: 26.6293,
            gisGcj02Lng: 101.267,
            stubGroupCnt: null,
            pinYin: 'Huaping',
          },
          {
            id: '530724',
            text: '宁蒗彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.2876,
            gisBd09Lng: 100.86,
            gisGcj02Lat: 27.2818,
            gisGcj02Lng: 100.853,
            stubGroupCnt: null,
            pinYin: 'Ninglang',
          },
        ],
        gisBd09Lng: 100.233,
        text: '丽江',
        gisBd09Lat: 26.8626,
        gisGcj02Lat: 26.8565,
      },
      {
        pinYin: "Pu'er",
        id: '530800',
        gisGcj02Lng: 100.976,
        children: [
          {
            id: '530801',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7924,
            gisBd09Lng: 100.983,
            gisGcj02Lat: 22.7867,
            gisGcj02Lng: 100.976,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530802',
            text: '翠云区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 23.0604,
            gisGcj02Lng: 101.049,
            stubGroupCnt: null,
            pinYin: 'Simao',
          },
          {
            id: '530821',
            text: '普洱哈尼族彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0667,
            gisBd09Lng: 101.055,
            gisGcj02Lat: 23.0604,
            gisGcj02Lng: 101.049,
            stubGroupCnt: null,
            pinYin: 'Ninger',
          },
          {
            id: '530822',
            text: '墨江哈尼族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.437,
            gisBd09Lng: 101.7,
            gisGcj02Lat: 23.4313,
            gisGcj02Lng: 101.693,
            stubGroupCnt: null,
            pinYin: 'Mojiang',
          },
          {
            id: '530823',
            text: '景东彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.453,
            gisBd09Lng: 100.841,
            gisGcj02Lat: 24.4471,
            gisGcj02Lng: 100.835,
            stubGroupCnt: null,
            pinYin: 'Jingdong',
          },
          {
            id: '530824',
            text: '景谷傣族彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.5036,
            gisBd09Lng: 100.711,
            gisGcj02Lat: 23.4975,
            gisGcj02Lng: 100.704,
            stubGroupCnt: null,
            pinYin: 'Jinggu',
          },
          {
            id: '530825',
            text: '镇沅彝族哈尼族拉祜族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.015,
            gisBd09Lng: 101.111,
            gisGcj02Lat: 24.0093,
            gisGcj02Lng: 101.105,
            stubGroupCnt: null,
            pinYin: 'Zhenyuan',
          },
          {
            id: '530826',
            text: '江城哈尼族彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.59,
            gisBd09Lng: 101.869,
            gisGcj02Lat: 22.5838,
            gisGcj02Lng: 101.863,
            stubGroupCnt: null,
            pinYin: 'Jiangcheng',
          },
          {
            id: '530827',
            text: '孟连傣族拉祜族佤族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.3365,
            gisBd09Lng: 99.5958,
            gisGcj02Lat: 22.3302,
            gisGcj02Lng: 99.5893,
            stubGroupCnt: null,
            pinYin: 'Menglian',
          },
          {
            id: '530828',
            text: '澜沧拉祜族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.562,
            gisBd09Lng: 99.939,
            gisGcj02Lat: 22.5559,
            gisGcj02Lng: 99.9325,
            stubGroupCnt: null,
            pinYin: 'Lancang',
          },
          {
            id: '530829',
            text: '西盟佤族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.6511,
            gisBd09Lng: 99.5984,
            gisGcj02Lat: 22.6448,
            gisGcj02Lng: 99.592,
            stubGroupCnt: null,
            pinYin: 'Ximeng',
          },
        ],
        gisBd09Lng: 100.983,
        text: '思茅',
        gisBd09Lat: 22.7924,
        gisGcj02Lat: 22.7867,
      },
      {
        pinYin: 'Lincang',
        id: '530900',
        gisGcj02Lng: 100.089,
        children: [
          {
            id: '530901',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.8928,
            gisBd09Lng: 100.096,
            gisGcj02Lat: 23.8865,
            gisGcj02Lng: 100.089,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '530902',
            text: '临翔区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.9015,
            gisBd09Lng: 100.089,
            gisGcj02Lat: 23.8952,
            gisGcj02Lng: 100.083,
            stubGroupCnt: null,
            pinYin: 'Linxiang',
          },
          {
            id: '530921',
            text: '凤庆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5868,
            gisBd09Lng: 99.9361,
            gisGcj02Lat: 24.5808,
            gisGcj02Lng: 99.9296,
            stubGroupCnt: null,
            pinYin: 'Fengqing',
          },
          {
            id: '530922',
            text: '云　县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.4505,
            gisBd09Lng: 100.138,
            gisGcj02Lat: 24.4447,
            gisGcj02Lng: 100.132,
            stubGroupCnt: null,
            pinYin: 'Yunxian',
          },
          {
            id: '530923',
            text: '永德县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0248,
            gisBd09Lng: 99.2652,
            gisGcj02Lat: 24.0186,
            gisGcj02Lng: 99.2587,
            stubGroupCnt: null,
            pinYin: 'Yongde',
          },
          {
            id: '530924',
            text: '镇康县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7687,
            gisBd09Lng: 98.8327,
            gisGcj02Lat: 23.763,
            gisGcj02Lng: 98.8261,
            stubGroupCnt: null,
            pinYin: 'Zhenkang',
          },
          {
            id: '530925',
            text: '双江拉祜族佤族布朗族傣族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.474,
            gisBd09Lng: 99.8351,
            gisGcj02Lat: 23.4677,
            gisGcj02Lng: 99.8287,
            stubGroupCnt: null,
            pinYin: 'Shuangjiang',
          },
          {
            id: '530926',
            text: '耿马傣族佤族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.541,
            gisBd09Lng: 99.4073,
            gisGcj02Lat: 23.5351,
            gisGcj02Lng: 99.4007,
            stubGroupCnt: null,
            pinYin: 'Gengma',
          },
          {
            id: '530927',
            text: '沧源佤族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1533,
            gisBd09Lng: 99.2528,
            gisGcj02Lat: 23.147,
            gisGcj02Lng: 99.2464,
            stubGroupCnt: null,
            pinYin: 'Cangyuan',
          },
        ],
        gisBd09Lng: 100.096,
        text: '临沧',
        gisBd09Lat: 23.8928,
        gisGcj02Lat: 23.8865,
      },
      {
        pinYin: 'Chuxiong',
        id: '532300',
        gisGcj02Lng: 101.528,
        children: [
          {
            id: '532301',
            text: '楚雄',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0388,
            gisBd09Lng: 101.553,
            gisGcj02Lat: 25.0328,
            gisGcj02Lng: 101.547,
            stubGroupCnt: null,
            pinYin: 'Chuxiong',
          },
          {
            id: '532322',
            text: '双柏县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.6949,
            gisBd09Lng: 101.648,
            gisGcj02Lat: 24.6886,
            gisGcj02Lng: 101.642,
            stubGroupCnt: null,
            pinYin: 'Shuangbai',
          },
          {
            id: '532323',
            text: '牟定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3185,
            gisBd09Lng: 101.554,
            gisGcj02Lat: 25.3125,
            gisGcj02Lng: 101.548,
            stubGroupCnt: null,
            pinYin: 'Mouding',
          },
          {
            id: '532324',
            text: '南华县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1981,
            gisBd09Lng: 101.281,
            gisGcj02Lat: 25.1918,
            gisGcj02Lng: 101.274,
            stubGroupCnt: null,
            pinYin: 'Nanhua',
          },
          {
            id: '532325',
            text: '姚安县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5098,
            gisBd09Lng: 101.249,
            gisGcj02Lat: 25.504,
            gisGcj02Lng: 101.242,
            stubGroupCnt: null,
            pinYin: "Yao'an",
          },
          {
            id: '532326',
            text: '大姚县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7235,
            gisBd09Lng: 101.329,
            gisGcj02Lat: 25.7177,
            gisGcj02Lng: 101.322,
            stubGroupCnt: null,
            pinYin: 'Dayao',
          },
          {
            id: '532327',
            text: '永仁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.0601,
            gisBd09Lng: 101.678,
            gisGcj02Lat: 26.0542,
            gisGcj02Lng: 101.671,
            stubGroupCnt: null,
            pinYin: 'Yongren',
          },
          {
            id: '532328',
            text: '元谋县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.7091,
            gisBd09Lng: 101.881,
            gisGcj02Lat: 25.7028,
            gisGcj02Lng: 101.875,
            stubGroupCnt: null,
            pinYin: 'Yuanmou',
          },
          {
            id: '532329',
            text: '武定县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5346,
            gisBd09Lng: 102.412,
            gisGcj02Lat: 25.5288,
            gisGcj02Lng: 102.406,
            stubGroupCnt: null,
            pinYin: 'Wuding',
          },
          {
            id: '532331',
            text: '禄丰县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.1559,
            gisBd09Lng: 102.086,
            gisGcj02Lat: 25.15,
            gisGcj02Lng: 102.08,
            stubGroupCnt: null,
            pinYin: 'Lufeng',
          },
        ],
        gisBd09Lng: 101.534,
        text: '楚雄彝族自治州',
        gisBd09Lat: 25.0514,
        gisGcj02Lat: 25.0451,
      },
      {
        pinYin: 'Honghe',
        id: '532500',
        gisGcj02Lng: 103.376,
        children: [
          {
            id: '532501',
            text: '个旧',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3585,
            gisBd09Lng: 103.166,
            gisGcj02Lat: 23.3527,
            gisGcj02Lng: 103.159,
            stubGroupCnt: null,
            pinYin: 'Gejiu',
          },
          {
            id: '532502',
            text: '开远',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7206,
            gisBd09Lng: 103.276,
            gisGcj02Lat: 23.7148,
            gisGcj02Lng: 103.269,
            stubGroupCnt: null,
            pinYin: 'Kaiyuan',
          },
          {
            id: '532522',
            text: '蒙自县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3465,
            gisBd09Lng: 103.5,
            gisGcj02Lat: 23.3408,
            gisGcj02Lng: 103.493,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '532523',
            text: '屏边苗族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.99,
            gisBd09Lng: 103.694,
            gisGcj02Lat: 22.9837,
            gisGcj02Lng: 103.688,
            stubGroupCnt: null,
            pinYin: 'Pingbian',
          },
          {
            id: '532524',
            text: '建水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 23.7054,
            gisGcj02Lng: 102.497,
            stubGroupCnt: null,
            pinYin: 'Jianshui',
          },
          {
            id: '532525',
            text: '石屏县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.7116,
            gisBd09Lng: 102.504,
            gisGcj02Lat: 23.7054,
            gisGcj02Lng: 102.497,
            stubGroupCnt: null,
            pinYin: 'Shiping',
          },
          {
            id: '532526',
            text: '弥勒县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.2259,
            gisBd09Lng: 103.39,
            gisGcj02Lat: 24.2202,
            gisGcj02Lng: 103.384,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '532527',
            text: '泸西县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.5378,
            gisBd09Lng: 103.774,
            gisGcj02Lat: 24.5318,
            gisGcj02Lng: 103.767,
            stubGroupCnt: null,
            pinYin: 'Luxi',
          },
          {
            id: '532528',
            text: '元阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.2259,
            gisBd09Lng: 102.842,
            gisGcj02Lat: 23.2196,
            gisGcj02Lng: 102.835,
            stubGroupCnt: null,
            pinYin: 'Yuanyang',
          },
          {
            id: '532529',
            text: '红河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.3749,
            gisBd09Lng: 102.425,
            gisGcj02Lat: 23.3692,
            gisGcj02Lng: 102.418,
            stubGroupCnt: null,
            pinYin: 'Honghexian',
          },
          {
            id: '532530',
            text: '金平苗族瑶族傣族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.7828,
            gisBd09Lng: 103.236,
            gisGcj02Lat: 22.7768,
            gisGcj02Lng: 103.23,
            stubGroupCnt: null,
            pinYin: 'Jinping',
          },
          {
            id: '532531',
            text: '绿春县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.9996,
            gisBd09Lng: 102.4,
            gisGcj02Lat: 22.9936,
            gisGcj02Lng: 102.393,
            stubGroupCnt: null,
            pinYin: 'Lvchun',
          },
          {
            id: '532532',
            text: '河口瑶族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.5346,
            gisBd09Lng: 103.948,
            gisGcj02Lat: 22.5285,
            gisGcj02Lng: 103.941,
            stubGroupCnt: null,
            pinYin: 'Hekou',
          },
        ],
        gisBd09Lng: 103.382,
        text: '红河哈尼族彝族自治州',
        gisBd09Lat: 23.3697,
        gisGcj02Lat: 23.364,
      },
      {
        pinYin: 'Wenshan',
        id: '532600',
        gisGcj02Lng: 104.215,
        children: [
          {
            id: '532621',
            text: '文山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4019,
            gisBd09Lng: 104.089,
            gisGcj02Lat: 23.3961,
            gisGcj02Lng: 104.083,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '532622',
            text: '砚山县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6111,
            gisBd09Lng: 104.344,
            gisGcj02Lat: 23.6054,
            gisGcj02Lng: 104.337,
            stubGroupCnt: null,
            pinYin: 'Yanshan',
          },
          {
            id: '532623',
            text: '西畴县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.4429,
            gisBd09Lng: 104.68,
            gisGcj02Lat: 23.4369,
            gisGcj02Lng: 104.673,
            stubGroupCnt: null,
            pinYin: 'Xichou',
          },
          {
            id: '532624',
            text: '麻栗坡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.1311,
            gisBd09Lng: 104.71,
            gisGcj02Lat: 23.1254,
            gisGcj02Lng: 104.703,
            stubGroupCnt: null,
            pinYin: 'Malipo',
          },
          {
            id: '532625',
            text: '马关县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.0188,
            gisBd09Lng: 104.401,
            gisGcj02Lat: 23.0125,
            gisGcj02Lng: 104.395,
            stubGroupCnt: null,
            pinYin: 'Maguan',
          },
          {
            id: '532626',
            text: '丘北县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0473,
            gisBd09Lng: 104.202,
            gisGcj02Lat: 24.0414,
            gisGcj02Lng: 104.196,
            stubGroupCnt: null,
            pinYin: 'Qiubei',
          },
          {
            id: '532627',
            text: '广南县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0521,
            gisBd09Lng: 105.062,
            gisGcj02Lat: 24.0464,
            gisGcj02Lng: 105.056,
            stubGroupCnt: null,
            pinYin: 'Guangnan',
          },
          {
            id: '532628',
            text: '富宁县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 23.6317,
            gisBd09Lng: 105.64,
            gisGcj02Lat: 23.6258,
            gisGcj02Lng: 105.633,
            stubGroupCnt: null,
            pinYin: 'Funing',
          },
        ],
        gisBd09Lng: 104.222,
        text: '文山壮族苗族自治州',
        gisBd09Lat: 23.4041,
        gisGcj02Lat: 23.3984,
      },
      {
        pinYin: 'Xishuangbanna',
        id: '532800',
        gisGcj02Lng: 100.797,
        children: [
          {
            id: '532801',
            text: '景洪',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 22.0062,
            gisBd09Lng: 100.779,
            gisGcj02Lat: 22.0001,
            gisGcj02Lng: 100.773,
            stubGroupCnt: null,
            pinYin: 'Jinghong',
          },
          {
            id: '532822',
            text: '勐海县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.9633,
            gisBd09Lng: 100.46,
            gisGcj02Lat: 21.9571,
            gisGcj02Lng: 100.453,
            stubGroupCnt: null,
            pinYin: 'Menghai',
          },
          {
            id: '532823',
            text: '勐腊县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 21.4599,
            gisBd09Lng: 101.572,
            gisGcj02Lat: 21.4541,
            gisGcj02Lng: 101.565,
            stubGroupCnt: null,
            pinYin: 'Mengla',
          },
        ],
        gisBd09Lng: 100.804,
        text: '西双版纳傣族自治州',
        gisBd09Lat: 22.0138,
        gisGcj02Lat: 22.0075,
      },
      {
        pinYin: 'Dali',
        id: '532900',
        gisGcj02Lng: 100.268,
        children: [
          {
            id: '532901',
            text: '大理',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.5979,
            gisBd09Lng: 100.237,
            gisGcj02Lat: 25.5919,
            gisGcj02Lng: 100.23,
            stubGroupCnt: null,
            pinYin: 'Dali',
          },
          {
            id: '532922',
            text: '漾濞彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.6764,
            gisBd09Lng: 99.9649,
            gisGcj02Lat: 25.6701,
            gisGcj02Lng: 99.9585,
            stubGroupCnt: null,
            pinYin: 'Yangbi',
          },
          {
            id: '532923',
            text: '祥云县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4899,
            gisBd09Lng: 100.557,
            gisGcj02Lat: 25.4837,
            gisGcj02Lng: 100.551,
            stubGroupCnt: null,
            pinYin: 'Xiangyun',
          },
          {
            id: '532924',
            text: '宾川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8331,
            gisBd09Lng: 100.579,
            gisGcj02Lat: 25.8269,
            gisGcj02Lng: 100.573,
            stubGroupCnt: null,
            pinYin: 'Binchuan',
          },
          {
            id: '532925',
            text: '弥渡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.3493,
            gisBd09Lng: 100.499,
            gisGcj02Lat: 25.3435,
            gisGcj02Lng: 100.493,
            stubGroupCnt: null,
            pinYin: 'Midu',
          },
          {
            id: '532926',
            text: '南涧彝族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.0489,
            gisBd09Lng: 100.521,
            gisGcj02Lat: 25.0431,
            gisGcj02Lng: 100.515,
            stubGroupCnt: null,
            pinYin: 'Nanjian',
          },
          {
            id: '532927',
            text: '巍山彝族回族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.2336,
            gisBd09Lng: 100.314,
            gisGcj02Lat: 25.2273,
            gisGcj02Lng: 100.308,
            stubGroupCnt: null,
            pinYin: 'Weishan',
          },
          {
            id: '532928',
            text: '永平县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.4704,
            gisBd09Lng: 99.5482,
            gisGcj02Lat: 25.4647,
            gisGcj02Lng: 99.5416,
            stubGroupCnt: null,
            pinYin: 'Yongping',
          },
          {
            id: '532929',
            text: '云龙县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8915,
            gisBd09Lng: 99.3772,
            gisGcj02Lat: 25.8853,
            gisGcj02Lng: 99.3707,
            stubGroupCnt: null,
            pinYin: 'Yunlong',
          },
          {
            id: '532930',
            text: '洱源县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.1177,
            gisBd09Lng: 99.957,
            gisGcj02Lat: 26.1114,
            gisGcj02Lng: 99.9506,
            stubGroupCnt: null,
            pinYin: 'Eryuan',
          },
          {
            id: '532931',
            text: '剑川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5431,
            gisBd09Lng: 99.9107,
            gisGcj02Lat: 26.5374,
            gisGcj02Lng: 99.9041,
            stubGroupCnt: null,
            pinYin: 'Jianchuan',
          },
          {
            id: '532932',
            text: '鹤庆县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.5649,
            gisBd09Lng: 100.183,
            gisGcj02Lat: 26.5588,
            gisGcj02Lng: 100.177,
            stubGroupCnt: null,
            pinYin: 'Heqing',
          },
        ],
        gisBd09Lng: 100.275,
        text: '大理白族自治州',
        gisBd09Lat: 25.6122,
        gisGcj02Lat: 25.6065,
      },
      {
        pinYin: 'Dehong',
        id: '533100',
        gisGcj02Lng: 98.5854,
        children: [
          {
            id: '533102',
            text: '瑞丽',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.0187,
            gisBd09Lng: 97.859,
            gisGcj02Lat: 24.013,
            gisGcj02Lng: 97.8525,
            stubGroupCnt: null,
            pinYin: 'Ruili',
          },
          {
            id: '533103',
            text: '潞西',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.3294,
            gisBd09Lng: 98.4276,
            gisGcj02Lat: 24.3233,
            gisGcj02Lng: 98.4211,
            stubGroupCnt: null,
            pinYin: 'Mangshi',
          },
          {
            id: '533122',
            text: '梁河县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.8105,
            gisBd09Lng: 98.3035,
            gisGcj02Lat: 24.8043,
            gisGcj02Lng: 98.2971,
            stubGroupCnt: null,
            pinYin: 'Lianghe',
          },
          {
            id: '533123',
            text: '盈江县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.7112,
            gisBd09Lng: 97.9391,
            gisGcj02Lat: 24.7049,
            gisGcj02Lng: 97.9326,
            stubGroupCnt: null,
            pinYin: 'Yingjiang',
          },
          {
            id: '533124',
            text: '陇川县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 24.189,
            gisBd09Lng: 97.7988,
            gisGcj02Lat: 24.1827,
            gisGcj02Lng: 97.7924,
            stubGroupCnt: null,
            pinYin: 'Longchuan',
          },
        ],
        gisBd09Lng: 98.592,
        text: '德宏傣族景颇族自治州',
        gisBd09Lat: 24.4383,
        gisGcj02Lat: 24.4325,
      },
      {
        pinYin: 'Nujiang',
        id: '533300',
        gisGcj02Lng: 98.9105,
        children: [
          {
            id: '533321',
            text: '泸水县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 25.8291,
            gisBd09Lng: 98.8657,
            gisGcj02Lat: 25.8229,
            gisGcj02Lng: 98.8592,
            stubGroupCnt: null,
            pinYin: 'Lushui',
          },
          {
            id: '533323',
            text: '福贡县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.9081,
            gisBd09Lng: 98.8762,
            gisGcj02Lat: 26.9018,
            gisGcj02Lng: 98.8698,
            stubGroupCnt: null,
            pinYin: 'Fugong',
          },
          {
            id: '533324',
            text: '贡山独龙族怒族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7444,
            gisBd09Lng: 98.6729,
            gisGcj02Lat: 27.7384,
            gisGcj02Lng: 98.6664,
            stubGroupCnt: null,
            pinYin: 'Gongshan',
          },
          {
            id: '533325',
            text: '兰坪白族普米族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 26.4601,
            gisBd09Lng: 99.4246,
            gisGcj02Lat: 26.4544,
            gisGcj02Lng: 99.418,
            stubGroupCnt: null,
            pinYin: 'Lanping',
          },
        ],
        gisBd09Lng: 98.917,
        text: '怒江傈僳族自治州',
        gisBd09Lat: 25.9231,
        gisGcj02Lat: 25.9171,
      },
      {
        pinYin: 'Deqen',
        id: '533400',
        gisGcj02Lng: 99.704,
        children: [
          {
            id: '533421',
            text: '香格里拉县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9079,
            gisBd09Lng: 99.8316,
            gisGcj02Lat: 27.9017,
            gisGcj02Lng: 99.8252,
            stubGroupCnt: null,
            pinYin: 'Xianggelila',
          },
          {
            id: '533422',
            text: '德钦县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4698,
            gisBd09Lng: 98.924,
            gisGcj02Lat: 28.4639,
            gisGcj02Lng: 98.9175,
            stubGroupCnt: null,
            pinYin: 'Deqin',
          },
          {
            id: '533423',
            text: '维西傈僳族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.1832,
            gisBd09Lng: 99.2937,
            gisGcj02Lat: 27.1775,
            gisGcj02Lng: 99.2872,
            stubGroupCnt: null,
            pinYin: 'Weixi',
          },
        ],
        gisBd09Lng: 99.7105,
        text: '迪庆藏族自治州',
        gisBd09Lat: 27.8258,
        gisGcj02Lat: 27.8196,
      },
    ],
  },
  {
    text: '浙江省',
    children: [
      {
        pinYin: 'Hangzhou',
        id: '330100',
        gisGcj02Lng: 120.155,
        children: [
          {
            id: '330101',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2801,
            gisBd09Lng: 120.162,
            gisGcj02Lat: 30.2742,
            gisGcj02Lng: 120.156,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330102',
            text: '上城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.248,
            gisBd09Lng: 120.177,
            gisGcj02Lat: 30.2423,
            gisGcj02Lng: 120.17,
            stubGroupCnt: 0,
            pinYin: 'Shangcheng',
          },
          {
            id: '330103',
            text: '下城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2871,
            gisBd09Lng: 120.188,
            gisGcj02Lat: 30.2814,
            gisGcj02Lng: 120.182,
            stubGroupCnt: 0,
            pinYin: 'Xiacheng',
          },
          {
            id: '330104',
            text: '江干区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2634,
            gisBd09Lng: 120.213,
            gisGcj02Lat: 30.2575,
            gisGcj02Lng: 120.206,
            stubGroupCnt: 1,
            pinYin: 'Jianggan',
          },
          {
            id: '330105',
            text: '拱墅区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.3246,
            gisBd09Lng: 120.149,
            gisGcj02Lat: 30.3185,
            gisGcj02Lng: 120.142,
            stubGroupCnt: 0,
            pinYin: 'Gongshu',
          },
          {
            id: '330106',
            text: '西湖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2647,
            gisBd09Lng: 120.138,
            gisGcj02Lat: 30.2584,
            gisGcj02Lng: 120.132,
            stubGroupCnt: 0,
            pinYin: 'Xihu',
          },
          {
            id: '330108',
            text: '滨江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2154,
            gisBd09Lng: 120.22,
            gisGcj02Lat: 30.2094,
            gisGcj02Lng: 120.213,
            stubGroupCnt: 0,
            pinYin: 'Binjiang',
          },
          {
            id: '330109',
            text: '萧山区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1926,
            gisBd09Lng: 120.273,
            gisGcj02Lat: 30.1865,
            gisGcj02Lng: 120.266,
            stubGroupCnt: null,
            pinYin: 'Xiaoshan',
          },
          {
            id: '330110',
            text: '余杭区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.424,
            gisBd09Lng: 120.304,
            gisGcj02Lat: 30.4184,
            gisGcj02Lng: 120.297,
            stubGroupCnt: 0,
            pinYin: 'Yuhang',
          },
          {
            id: '330122',
            text: '桐庐县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7984,
            gisBd09Lng: 119.698,
            gisGcj02Lat: 29.7927,
            gisGcj02Lng: 119.691,
            stubGroupCnt: null,
            pinYin: 'Tonglu',
          },
          {
            id: '330127',
            text: '淳安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.6149,
            gisBd09Lng: 119.049,
            gisGcj02Lat: 29.6086,
            gisGcj02Lng: 119.043,
            stubGroupCnt: null,
            pinYin: "Chun'an",
          },
          {
            id: '330182',
            text: '建德',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.481,
            gisBd09Lng: 119.288,
            gisGcj02Lat: 29.4746,
            gisGcj02Lng: 119.281,
            stubGroupCnt: null,
            pinYin: 'Jiande',
          },
          {
            id: '330183',
            text: '富阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0005,
            gisBd09Lng: 119.847,
            gisGcj02Lat: 29.9947,
            gisGcj02Lng: 119.841,
            stubGroupCnt: 0,
            pinYin: 'Fuyang',
          },
          {
            id: '330185',
            text: '临安',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2396,
            gisBd09Lng: 119.731,
            gisGcj02Lat: 30.2337,
            gisGcj02Lng: 119.725,
            stubGroupCnt: null,
            pinYin: "Lin'an",
          },
        ],
        gisBd09Lng: 120.162,
        text: '杭州',
        gisBd09Lat: 30.2801,
        gisGcj02Lat: 30.2742,
      },
      {
        pinYin: 'Ningbo',
        id: '330200',
        gisGcj02Lng: 121.551,
        children: [
          {
            id: '330201',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.88,
            gisBd09Lng: 121.557,
            gisGcj02Lat: 29.8737,
            gisGcj02Lng: 121.55,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330203',
            text: '海曙区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8658,
            gisBd09Lng: 121.557,
            gisGcj02Lat: 29.8595,
            gisGcj02Lng: 121.551,
            stubGroupCnt: null,
            pinYin: 'Haishu',
          },
          {
            id: '330204',
            text: '江东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8733,
            gisBd09Lng: 121.576,
            gisGcj02Lat: 29.867,
            gisGcj02Lng: 121.57,
            stubGroupCnt: null,
            pinYin: 'Jiangdong',
          },
          {
            id: '330205',
            text: '江北区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8924,
            gisBd09Lng: 121.561,
            gisGcj02Lat: 29.886,
            gisGcj02Lng: 121.555,
            stubGroupCnt: null,
            pinYin: 'Jiangbei',
          },
          {
            id: '330206',
            text: '北仑区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9046,
            gisBd09Lng: 121.851,
            gisGcj02Lat: 29.8989,
            gisGcj02Lng: 121.844,
            stubGroupCnt: null,
            pinYin: 'Beilun',
          },
          {
            id: '330211',
            text: '镇海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9549,
            gisBd09Lng: 121.723,
            gisGcj02Lat: 29.949,
            gisGcj02Lng: 121.716,
            stubGroupCnt: null,
            pinYin: 'Zhenhai',
          },
          {
            id: '330212',
            text: '鄞州区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.8234,
            gisBd09Lng: 121.553,
            gisGcj02Lat: 29.8171,
            gisGcj02Lng: 121.547,
            stubGroupCnt: null,
            pinYin: 'Yinzhou',
          },
          {
            id: '330225',
            text: '象山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 29.8171,
            gisGcj02Lng: 121.547,
            stubGroupCnt: null,
            pinYin: 'Xiangshan',
          },
          {
            id: '330226',
            text: '宁海县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2943,
            gisBd09Lng: 121.436,
            gisGcj02Lat: 29.288,
            gisGcj02Lng: 121.43,
            stubGroupCnt: null,
            pinYin: 'Ninghai',
          },
          {
            id: '330281',
            text: '余姚',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0427,
            gisBd09Lng: 121.161,
            gisGcj02Lat: 30.037,
            gisGcj02Lng: 121.154,
            stubGroupCnt: null,
            pinYin: 'Yuyao',
          },
          {
            id: '330282',
            text: '慈溪',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.1748,
            gisBd09Lng: 121.273,
            gisGcj02Lat: 30.1691,
            gisGcj02Lng: 121.267,
            stubGroupCnt: null,
            pinYin: 'Cixi',
          },
          {
            id: '330283',
            text: '奉化',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.661,
            gisBd09Lng: 121.413,
            gisGcj02Lat: 29.6551,
            gisGcj02Lng: 121.407,
            stubGroupCnt: null,
            pinYin: 'Fenghua',
          },
        ],
        gisBd09Lng: 121.557,
        text: '宁波',
        gisBd09Lat: 29.88,
        gisGcj02Lat: 29.8737,
      },
      {
        pinYin: 'Wenzhou',
        id: '330300',
        gisGcj02Lng: 28.0,
        children: [
          {
            id: '330301',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 28.0072,
            gisGcj02Lng: 120.638,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330302',
            text: '鹿城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 28.0072,
            gisGcj02Lng: 120.638,
            stubGroupCnt: null,
            pinYin: 'Lucheng',
          },
          {
            id: '330303',
            text: '龙湾区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 28.0072,
            gisGcj02Lng: 120.638,
            stubGroupCnt: null,
            pinYin: 'Longwan',
          },
          {
            id: '330304',
            text: '瓯海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.013,
            gisBd09Lng: 120.645,
            gisGcj02Lat: 28.0072,
            gisGcj02Lng: 120.638,
            stubGroupCnt: null,
            pinYin: 'Ouhai',
          },
          {
            id: '330322',
            text: '洞头县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.8413,
            gisBd09Lng: 121.163,
            gisGcj02Lat: 27.8355,
            gisGcj02Lng: 121.157,
            stubGroupCnt: null,
            pinYin: 'Dongtou',
          },
          {
            id: '330324',
            text: '永嘉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1589,
            gisBd09Lng: 120.698,
            gisGcj02Lat: 28.1529,
            gisGcj02Lng: 120.691,
            stubGroupCnt: null,
            pinYin: 'Yongjia',
          },
          {
            id: '330326',
            text: '平阳县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6682,
            gisBd09Lng: 120.572,
            gisGcj02Lat: 27.6622,
            gisGcj02Lng: 120.566,
            stubGroupCnt: null,
            pinYin: 'Pingyang',
          },
          {
            id: '330327',
            text: '苍南县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5227,
            gisBd09Lng: 120.433,
            gisGcj02Lat: 27.517,
            gisGcj02Lng: 120.427,
            stubGroupCnt: null,
            pinYin: 'Cangnan',
          },
          {
            id: '330328',
            text: '文成县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7932,
            gisBd09Lng: 120.097,
            gisGcj02Lat: 27.7872,
            gisGcj02Lng: 120.091,
            stubGroupCnt: null,
            pinYin: 'Wencheng',
          },
          {
            id: '330329',
            text: '泰顺县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.5618,
            gisBd09Lng: 119.724,
            gisGcj02Lat: 27.5561,
            gisGcj02Lng: 119.717,
            stubGroupCnt: null,
            pinYin: 'Taishun',
          },
          {
            id: '330381',
            text: '瑞安',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.7829,
            gisBd09Lng: 120.663,
            gisGcj02Lat: 27.7772,
            gisGcj02Lng: 120.656,
            stubGroupCnt: null,
            pinYin: "Rui'an",
          },
          {
            id: '330382',
            text: '乐清',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1183,
            gisBd09Lng: 120.991,
            gisGcj02Lat: 28.1122,
            gisGcj02Lng: 120.984,
            stubGroupCnt: null,
            pinYin: 'Yueqing',
          },
        ],
        gisBd09Lng: 120.7,
        text: '温州',
        gisBd09Lat: 0.0,
        gisGcj02Lat: 0.0,
      },
      {
        pinYin: 'Jiaxing',
        id: '330400',
        gisGcj02Lng: 120.755,
        children: [
          {
            id: '330401',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7516,
            gisBd09Lng: 120.762,
            gisGcj02Lat: 30.7457,
            gisGcj02Lng: 120.756,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330402',
            text: '秀城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7148,
            gisBd09Lng: 120.932,
            gisGcj02Lat: 30.7088,
            gisGcj02Lng: 120.926,
            stubGroupCnt: null,
            pinYin: 'Nanhu',
          },
          {
            id: '330411',
            text: '秀洲区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7711,
            gisBd09Lng: 120.715,
            gisGcj02Lat: 30.7648,
            gisGcj02Lng: 120.709,
            stubGroupCnt: 0,
            pinYin: 'Xiuzhou',
          },
          {
            id: '330421',
            text: '嘉善县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8356,
            gisBd09Lng: 120.933,
            gisGcj02Lat: 30.8297,
            gisGcj02Lng: 120.927,
            stubGroupCnt: null,
            pinYin: 'Jiashan',
          },
          {
            id: '330424',
            text: '海盐县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5312,
            gisBd09Lng: 120.954,
            gisGcj02Lat: 30.525,
            gisGcj02Lng: 120.947,
            stubGroupCnt: null,
            pinYin: 'Haiyan',
          },
          {
            id: '330481',
            text: '海宁',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.5153,
            gisBd09Lng: 120.688,
            gisGcj02Lat: 30.5095,
            gisGcj02Lng: 120.681,
            stubGroupCnt: null,
            pinYin: 'Haining',
          },
          {
            id: '330482',
            text: '平湖',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6821,
            gisBd09Lng: 121.022,
            gisGcj02Lat: 30.6765,
            gisGcj02Lng: 121.016,
            stubGroupCnt: null,
            pinYin: 'Pinghu',
          },
          {
            id: '330483',
            text: '桐乡',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6363,
            gisBd09Lng: 120.571,
            gisGcj02Lat: 30.6304,
            gisGcj02Lng: 120.564,
            stubGroupCnt: null,
            pinYin: 'Tongxiang',
          },
        ],
        gisBd09Lng: 120.762,
        text: '嘉兴',
        gisBd09Lat: 30.751,
        gisGcj02Lat: 30.7451,
      },
      {
        pinYin: 'Huzhou',
        id: '330500',
        gisGcj02Lng: 120.086,
        children: [
          {
            id: '330501',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8985,
            gisBd09Lng: 120.093,
            gisGcj02Lat: 30.8926,
            gisGcj02Lng: 120.087,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330502',
            text: '吴兴区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8613,
            gisBd09Lng: 120.192,
            gisGcj02Lat: 30.8557,
            gisGcj02Lng: 120.186,
            stubGroupCnt: null,
            pinYin: 'Wuxing',
          },
          {
            id: '330503',
            text: '南浔区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.8558,
            gisBd09Lng: 120.424,
            gisGcj02Lat: 30.8502,
            gisGcj02Lng: 120.418,
            stubGroupCnt: null,
            pinYin: 'Nanxun',
          },
          {
            id: '330521',
            text: '德清县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: null,
            gisBd09Lng: null,
            gisGcj02Lat: 30.8502,
            gisGcj02Lng: 120.418,
            stubGroupCnt: null,
            pinYin: 'Deqing',
          },
          {
            id: '330522',
            text: '长兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 31.0329,
            gisBd09Lng: 119.917,
            gisGcj02Lat: 31.0269,
            gisGcj02Lng: 119.911,
            stubGroupCnt: null,
            pinYin: 'Changxing',
          },
          {
            id: '330523',
            text: '安吉县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.6428,
            gisBd09Lng: 119.688,
            gisGcj02Lat: 30.637,
            gisGcj02Lng: 119.681,
            stubGroupCnt: null,
            pinYin: 'Anji',
          },
        ],
        gisBd09Lng: 120.093,
        text: '湖州',
        gisBd09Lat: 30.8985,
        gisGcj02Lat: 30.8926,
      },
      {
        pinYin: 'Shaoxing',
        id: '330600',
        gisGcj02Lng: 120.581,
        children: [
          {
            id: '330601',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0361,
            gisBd09Lng: 120.587,
            gisGcj02Lat: 30.0299,
            gisGcj02Lng: 120.58,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330602',
            text: '越城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9946,
            gisBd09Lng: 120.589,
            gisGcj02Lat: 29.9884,
            gisGcj02Lng: 120.582,
            stubGroupCnt: null,
            pinYin: 'Yuecheng',
          },
          {
            id: '330621',
            text: '绍兴县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9686,
            gisBd09Lng: 120.573,
            gisGcj02Lat: 29.9627,
            gisGcj02Lng: 120.566,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330624',
            text: '新昌县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5048,
            gisBd09Lng: 120.912,
            gisGcj02Lat: 29.4992,
            gisGcj02Lng: 120.905,
            stubGroupCnt: null,
            pinYin: 'Xinchang',
          },
          {
            id: '330681',
            text: '诸暨',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.7197,
            gisBd09Lng: 120.243,
            gisGcj02Lat: 29.7133,
            gisGcj02Lng: 120.237,
            stubGroupCnt: null,
            pinYin: 'Zhuji',
          },
          {
            id: '330682',
            text: '上虞',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9782,
            gisBd09Lng: 120.89,
            gisGcj02Lat: 29.9725,
            gisGcj02Lng: 120.883,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330683',
            text: '嵊州',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.5944,
            gisBd09Lng: 120.829,
            gisGcj02Lat: 29.5882,
            gisGcj02Lng: 120.823,
            stubGroupCnt: null,
            pinYin: 'Shengzhou',
          },
        ],
        gisBd09Lng: 120.587,
        text: '绍兴',
        gisBd09Lat: 30.0361,
        gisGcj02Lat: 30.0299,
      },
      {
        pinYin: 'Jinhua',
        id: '330700',
        gisGcj02Lng: 119.648,
        children: [
          {
            id: '330701',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0852,
            gisBd09Lng: 119.654,
            gisGcj02Lat: 29.0789,
            gisGcj02Lng: 119.648,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330702',
            text: '婺城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0912,
            gisBd09Lng: 119.58,
            gisGcj02Lat: 29.0855,
            gisGcj02Lng: 119.574,
            stubGroupCnt: null,
            pinYin: 'Wucheng',
          },
          {
            id: '330703',
            text: '金东区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1046,
            gisBd09Lng: 119.699,
            gisGcj02Lat: 29.0989,
            gisGcj02Lng: 119.693,
            stubGroupCnt: null,
            pinYin: 'Jindong',
          },
          {
            id: '330723',
            text: '武义县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8975,
            gisBd09Lng: 119.825,
            gisGcj02Lat: 28.8918,
            gisGcj02Lng: 119.819,
            stubGroupCnt: null,
            pinYin: 'Wuyi',
          },
          {
            id: '330726',
            text: '浦江县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.4578,
            gisBd09Lng: 119.899,
            gisGcj02Lat: 29.4515,
            gisGcj02Lng: 119.892,
            stubGroupCnt: null,
            pinYin: 'Pujiang',
          },
          {
            id: '330727',
            text: '磐安县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0598,
            gisBd09Lng: 120.455,
            gisGcj02Lat: 29.0538,
            gisGcj02Lng: 120.449,
            stubGroupCnt: null,
            pinYin: "Pan'an",
          },
          {
            id: '330781',
            text: '兰溪',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2137,
            gisBd09Lng: 119.468,
            gisGcj02Lat: 29.2081,
            gisGcj02Lng: 119.462,
            stubGroupCnt: null,
            pinYin: 'Lanxi',
          },
          {
            id: '330782',
            text: '义乌',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.3111,
            gisBd09Lng: 120.081,
            gisGcj02Lat: 29.3053,
            gisGcj02Lng: 120.075,
            stubGroupCnt: null,
            pinYin: 'Yiwu',
          },
          {
            id: '330783',
            text: '东阳',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.2941,
            gisBd09Lng: 120.251,
            gisGcj02Lat: 29.2877,
            gisGcj02Lng: 120.244,
            stubGroupCnt: null,
            pinYin: 'Dongyang',
          },
          {
            id: '330784',
            text: '永康',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8938,
            gisBd09Lng: 120.055,
            gisGcj02Lat: 28.8881,
            gisGcj02Lng: 120.049,
            stubGroupCnt: null,
            pinYin: 'Yongkang',
          },
        ],
        gisBd09Lng: 119.654,
        text: '金华',
        gisBd09Lat: 29.0852,
        gisGcj02Lat: 29.0789,
      },
      {
        pinYin: 'Quzhou',
        id: '330800',
        gisGcj02Lng: 118.874,
        children: [
          {
            id: '330801',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9413,
            gisBd09Lng: 118.881,
            gisGcj02Lat: 28.9356,
            gisGcj02Lng: 118.875,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330802',
            text: '柯城区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9735,
            gisBd09Lng: 118.878,
            gisGcj02Lat: 28.9678,
            gisGcj02Lng: 118.872,
            stubGroupCnt: null,
            pinYin: 'Kecheng',
          },
          {
            id: '330803',
            text: '衢江区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9856,
            gisBd09Lng: 118.967,
            gisGcj02Lat: 28.9798,
            gisGcj02Lng: 118.96,
            stubGroupCnt: null,
            pinYin: 'Qujiang',
          },
          {
            id: '330822',
            text: '常山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.9068,
            gisBd09Lng: 118.518,
            gisGcj02Lat: 28.9011,
            gisGcj02Lng: 118.512,
            stubGroupCnt: null,
            pinYin: 'Changshan',
          },
          {
            id: '330824',
            text: '开化县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1418,
            gisBd09Lng: 118.421,
            gisGcj02Lat: 29.1357,
            gisGcj02Lng: 118.414,
            stubGroupCnt: null,
            pinYin: 'Kaihua',
          },
          {
            id: '330825',
            text: '龙游县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.0343,
            gisBd09Lng: 119.179,
            gisGcj02Lat: 29.028,
            gisGcj02Lng: 119.172,
            stubGroupCnt: null,
            pinYin: 'Longyou',
          },
          {
            id: '330881',
            text: '江山',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.7426,
            gisBd09Lng: 118.634,
            gisGcj02Lat: 28.7369,
            gisGcj02Lng: 118.627,
            stubGroupCnt: null,
            pinYin: 'Jiangshan',
          },
        ],
        gisBd09Lng: 118.881,
        text: '衢州',
        gisBd09Lat: 28.9413,
        gisGcj02Lat: 28.9356,
      },
      {
        pinYin: 'Zhoushan',
        id: '330900',
        gisGcj02Lng: 122.207,
        children: [
          {
            id: '330901',
            text: '市辖区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.9905,
            gisBd09Lng: 122.214,
            gisGcj02Lat: 29.9848,
            gisGcj02Lng: 122.208,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '330902',
            text: '定海区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.0245,
            gisBd09Lng: 122.113,
            gisGcj02Lat: 30.0189,
            gisGcj02Lng: 122.107,
            stubGroupCnt: null,
            pinYin: 'Dinghai',
          },
          {
            id: '330903',
            text: '普陀区',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.955,
            gisBd09Lng: 122.309,
            gisGcj02Lat: 29.9489,
            gisGcj02Lng: 122.303,
            stubGroupCnt: null,
            pinYin: 'Putuo',
          },
          {
            id: '330921',
            text: '岱山县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.2486,
            gisBd09Lng: 122.213,
            gisGcj02Lat: 30.2428,
            gisGcj02Lng: 122.206,
            stubGroupCnt: null,
            pinYin: 'Daishan',
          },
          {
            id: '330922',
            text: '嵊泗县',
            status: 1,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 30.7311,
            gisBd09Lng: 122.459,
            gisGcj02Lat: 30.7254,
            gisGcj02Lng: 122.453,
            stubGroupCnt: null,
            pinYin: 'Shengsi',
          },
        ],
        gisBd09Lng: 122.214,
        text: '舟山',
        gisBd09Lat: 29.9905,
        gisGcj02Lat: 29.9848,
      },
      {
        pinYin: 'Taizhou',
        id: '331000',
        gisGcj02Lng: 121.421,
        children: [
          {
            id: '331001',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6621,
            gisBd09Lng: 121.427,
            gisGcj02Lat: 28.656,
            gisGcj02Lng: 121.421,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '331002',
            text: '椒江区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6791,
            gisBd09Lng: 121.449,
            gisGcj02Lat: 28.6728,
            gisGcj02Lng: 121.443,
            stubGroupCnt: null,
            pinYin: 'Jiaojiang',
          },
          {
            id: '331003',
            text: '黄岩区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6553,
            gisBd09Lng: 121.27,
            gisGcj02Lat: 28.6497,
            gisGcj02Lng: 121.263,
            stubGroupCnt: null,
            pinYin: 'Huangyan',
          },
          {
            id: '331004',
            text: '路桥区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5883,
            gisBd09Lng: 121.373,
            gisGcj02Lat: 28.5825,
            gisGcj02Lng: 121.366,
            stubGroupCnt: null,
            pinYin: 'Luqiao',
          },
          {
            id: '331021',
            text: '玉环县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1416,
            gisBd09Lng: 121.239,
            gisGcj02Lat: 28.1357,
            gisGcj02Lng: 121.232,
            stubGroupCnt: null,
            pinYin: 'Yuhuan',
          },
          {
            id: '331022',
            text: '三门县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1092,
            gisBd09Lng: 121.403,
            gisGcj02Lat: 29.1035,
            gisGcj02Lng: 121.396,
            stubGroupCnt: null,
            pinYin: 'Sanmen',
          },
          {
            id: '331023',
            text: '天台县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 29.1494,
            gisBd09Lng: 121.013,
            gisGcj02Lat: 29.1437,
            gisGcj02Lng: 121.006,
            stubGroupCnt: null,
            pinYin: 'Tiantai',
          },
          {
            id: '331024',
            text: '仙居县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8535,
            gisBd09Lng: 120.736,
            gisGcj02Lat: 28.8472,
            gisGcj02Lng: 120.729,
            stubGroupCnt: null,
            pinYin: 'Xianju',
          },
          {
            id: '331081',
            text: '温岭',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.3772,
            gisBd09Lng: 121.393,
            gisGcj02Lat: 28.3715,
            gisGcj02Lng: 121.387,
            stubGroupCnt: null,
            pinYin: 'Wenling',
          },
          {
            id: '331082',
            text: '临海',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.8637,
            gisBd09Lng: 121.153,
            gisGcj02Lat: 28.858,
            gisGcj02Lng: 121.146,
            stubGroupCnt: null,
            pinYin: 'Linhai',
          },
        ],
        gisBd09Lng: 121.427,
        text: '台州',
        gisBd09Lat: 28.6621,
        gisGcj02Lat: 28.6559,
      },
      {
        pinYin: 'Lishui',
        id: '331100',
        gisGcj02Lng: 119.923,
        children: [
          {
            id: '331101',
            text: '市辖区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4729,
            gisBd09Lng: 119.93,
            gisGcj02Lat: 28.4671,
            gisGcj02Lng: 119.923,
            stubGroupCnt: null,
            pinYin: null,
          },
          {
            id: '331102',
            text: '莲都区',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4509,
            gisBd09Lng: 119.92,
            gisGcj02Lat: 28.445,
            gisGcj02Lng: 119.913,
            stubGroupCnt: null,
            pinYin: 'Liandu',
          },
          {
            id: '331121',
            text: '青田县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.1458,
            gisBd09Lng: 120.296,
            gisGcj02Lat: 28.1401,
            gisGcj02Lng: 120.289,
            stubGroupCnt: null,
            pinYin: 'Qingtian',
          },
          {
            id: '331122',
            text: '缙云县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.6648,
            gisBd09Lng: 120.099,
            gisGcj02Lat: 28.6588,
            gisGcj02Lng: 120.092,
            stubGroupCnt: null,
            pinYin: 'Jinyun',
          },
          {
            id: '331123',
            text: '遂昌县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.5975,
            gisBd09Lng: 119.283,
            gisGcj02Lat: 28.5912,
            gisGcj02Lng: 119.276,
            stubGroupCnt: null,
            pinYin: 'Suichang',
          },
          {
            id: '331124',
            text: '松阳县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.4546,
            gisBd09Lng: 119.489,
            gisGcj02Lat: 28.4487,
            gisGcj02Lng: 119.483,
            stubGroupCnt: null,
            pinYin: 'Songyang',
          },
          {
            id: '331125',
            text: '云和县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.121,
            gisBd09Lng: 119.581,
            gisGcj02Lat: 28.1153,
            gisGcj02Lng: 119.574,
            stubGroupCnt: null,
            pinYin: 'Yunhe',
          },
          {
            id: '331126',
            text: '庆元县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.6246,
            gisBd09Lng: 119.07,
            gisGcj02Lat: 27.6184,
            gisGcj02Lng: 119.063,
            stubGroupCnt: null,
            pinYin: 'Qingyuan',
          },
          {
            id: '331127',
            text: '景宁畲族自治县',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 27.9791,
            gisBd09Lng: 119.642,
            gisGcj02Lat: 27.9728,
            gisGcj02Lng: 119.636,
            stubGroupCnt: null,
            pinYin: 'Jingning',
          },
          {
            id: '331181',
            text: '龙泉',
            status: 0,
            orderId: null,
            createAccount: null,
            createTime: null,
            modifyAccount: null,
            modifyTime: null,
            gisBd09Lat: 28.0806,
            gisBd09Lng: 119.149,
            gisGcj02Lat: 28.0744,
            gisGcj02Lng: 119.142,
            stubGroupCnt: null,
            pinYin: 'Longquan',
          },
        ],
        gisBd09Lng: 119.93,
        text: '丽水',
        gisBd09Lat: 28.4729,
        gisGcj02Lat: 28.4671,
      },
    ],
  },
]

export const lunarInfo = [
  0x6b50,
  0x2ba0,
  0xab62,
  0x9370,
  0x52e6,
  0xd160,
  0xe4b0,
  0x6d25,
  0xda90,
  0x5b50, // 1801 ~ 1810
  0x36d3,
  0x2ae0,
  0xa2e0,
  0xe2d2,
  0xc950,
  0xd556,
  0xb520,
  0xb690,
  0x5da4,
  0x55d0, // 1811 ~ 1820
  0x25d0,
  0xa5b3,
  0xa2b0,
  0xa8b7,
  0xa95f,
  0xb4a0,
  0xb2a5,
  0xad5f,
  0x55b0,
  0x2b74, // 1821 ~ 1830
  0x2570,
  0x52f9,
  0x52b0,
  0x6950,
  0x6d56,
  0x5aa0,
  0xab50,
  0x56d4,
  0x4ae0,
  0xa570, // 1831 ~ 1840
  0x4553,
  0xd2af,
  0xe8a7,
  0xd55f,
  0x5aa0,
  0xada5,
  0x95d0,
  0x4ae0,
  0xaab4,
  0xa4d0, // 1841 ~ 1850
  0xd2b8,
  0xb290,
  0xb550,
  0x5757,
  0x2da0,
  0x95d0,
  0x4d75,
  0x49b0,
  0xa4b0,
  0xa4b3, // 1851 ~ 1860
  0x6a9f,
  0xad98,
  0x6b50,
  0x2b60,
  0x9365,
  0x937f,
  0x4970,
  0x6964,
  0xe4a0,
  0xea6a, // 1861 ~ 1870
  0xda90,
  0x5ad0,
  0x2ad6,
  0x2aef,
  0x92e0,
  0xcad5,
  0xc950,
  0xd4a0,
  0xd4a3,
  0xb69f, // 1871 ~ 1880
  0x57a7,
  0x55b0,
  0x25d0,
  0x95b5,
  0x92b0,
  0xa950,
  0xd954,
  0xb4a0,
  0xb550,
  0x6d52, // 1881 ~ 1890
  0x55b0,
  0x2776,
  0x2570,
  0x52b0,
  0xaaa5,
  0xe950,
  0x6aa0,
  0xbaa3,
  0xab50,
  0x4bd8, // 1891 ~ 1900

  0x4ae0,
  0xa570,
  0x54d5,
  0xd260,
  0xd950,
  0x16554 /*0x5554*/,
  0x56af,
  0x9ad0,
  0x55d2,
  0x4ae0, // 1901 ~ 1910
  0xa5b6,
  0xa4d0,
  0xd250,
  0xd255,
  0xb54f,
  0xd6a0,
  0xada2,
  0x95b0,
  0x4977,
  0x497f, // 1911 ~ 1920
  0xa4b0,
  0xb4b5,
  0x6a50,
  0x6d40,
  0xab54,
  0x2b6f,
  0x9570,
  0x52f2,
  0x4970,
  0x6566, // 1921 ~ 1930
  0xd4a0,
  0xea50,
  /* 0x6a95, 0x5adf*/ 0x06e95,
  0x05ad0,
  0x2b60,
  0x86e3,
  0x92ef,
  0xc8d7,
  0xc95f,
  0xd4a0, // 1931 ~ 1940
  0xd8a6,
  0xb55f,
  0x56a0,
  0xa5b4,
  0x25df,
  0x92d0,
  0xd2b2,
  0xa950,
  0xb557,
  0x6ca0, // 1941 ~ 1950
  0xb550,
  0x5355,
  0x4daf,
  0xa5b0,
  0x4573,
  0x52bf,
  0xa9a8,
  0xe950,
  0x6aa0,
  0xaea6, // 1951 ~ 1960
  0xab50,
  0x4b60,
  0xaae4,
  0xa570,
  0x5260,
  0xf263,
  0xd950,
  0x5b57,
  0x56a0,
  0x96d0, // 1961 ~ 1970
  0x4dd5,
  0x4ad0,
  0xa4d0,
  0xd4d4,
  0xd250,
  0xd558,
  0xb540,
  0xb6a0,
  0x95a6,
  0x95bf, // 1971 ~ 1980
  0x49b0,
  0xa974,
  0xa4b0,
  0xb27a,
  0x6a50,
  0x6d40,
  0xaf46,
  0xab60,
  0x9570,
  0x4af5, // 1981 ~ 1990
  0x4970,
  0x64b0,
  0x74a3,
  0xea50,
  0x6b58,
  0x5ac0,
  0xab60,
  0x96d5,
  0x92e0,
  0xc960, // 1991 ~ 2000

  0xd954,
  0xd4a0,
  0xda50,
  0x7552,
  0x56a0,
  0xabb7,
  0x25d0,
  0x92d0,
  0xcab5,
  0xa950, // 2001 ~ 2010
  0xb4a0,
  0xbaa4,
  0xad50,
  0x55d9,
  0x4ba0,
  0xa5b0,
  0x5176,
  0x52bf,
  0xa930,
  0x7954, // 2011 ~ 2020
  0x6aa0,
  0xad50,
  0x5b52,
  0x4b60,
  0xa6e6,
  0xa4e0,
  0xd260,
  0xea65,
  0xd530,
  0x5aa0, // 2021 ~ 2030
  0x76a3,
  0x96d0,
  0x4afb,
  0x4ad0,
  0xa4d0,
  0xd0b6,
  0xd25f,
  0xd520,
  0xdd45,
  0xb5a0, // 2031 ~ 2040
  0x56d0,
  0x55b2,
  0x49b0,
  0xa577,
  0xa4b0,
  0xaa50,
  0xb255,
  0x6d2f,
  0xada0,
  0x4b63, // 2041 ~ 2050
  0x937f,
  0x49f8,
  0x4970,
  0x64b0,
  0x68a6,
  0xea5f,
  0x6b20,
  0xa6c4,
  0xaaef,
  0x92e0, // 2051 ~ 2060
  0xd2e3,
  0xc960,
  0xd557,
  0xd4a0,
  0xda50,
  0x5d55,
  0x56a0,
  0xa6d0,
  0x55d4,
  0x52d0, // 2061 ~ 2070
  0xa9b8,
  0xa950,
  0xb4a0,
  0xb6a6,
  0xad50,
  0x55a0,
  0xaba4,
  0xa5b0,
  0x52b0,
  0xb273, // 2071 ~ 2080
  0x6930,
  0x7337,
  0x6aa0,
  0xad50,
  0x4b55,
  0x4b6f,
  0xa570,
  0x54e4,
  0xd260,
  0xe968, // 2081 ~ 2090
  0xd520,
  0xdaa0,
  0x6aa6,
  0x56df,
  0x4ae0,
  0xa9d4,
  0xa4d0,
  0xd150,
  0xf252,
  0xd520, // 2091 ~ 2100
]

// 农历正月到腊月
export const monthArray = [
  '正月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '冬月',
  '腊月',
]

// 农历初一到卅十
export const dayArray = [
  '初一',
  '初二',
  '初三',
  '初四',
  '初五',
  '初六',
  '初七',
  '初八',
  '初九',
  '初十',
  '十一',
  '十二',
  '十三',
  '十四',
  '十五',
  '十六',
  '十七',
  '十八',
  '十九',
  '廿十',
  '廿一',
  '廿二',
  '廿三',
  '廿四',
  '廿五',
  '廿六',
  '廿七',
  '廿八',
  '廿九',
  '卅十',
]

// 阳历闰年的每月天数
export const solarNumberDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

// 时差修改
export const ShiChaXiuZheng = [
  ['1月1日', '0:03:09', '-1'],
  ['1月2日', '0:03:38', '-1'],
  ['1月3日', '0:04:06', '-1'],
  ['1月4日', '0:04:33', '-1'],
  ['1月5日', '0:05:01', '-1'],
  ['1月6日', '0:05:27', '-1'],
  ['1月7日', '0:05:54', '-1'],
  ['1月8日', '0:06:20', '-1'],
  ['1月9日', '0:06:45', '-1'],
  ['1月10日', '0:07:10', '-1'],
  ['1月11日', '0:07:35', '-1'],
  ['1月12日', '0:07:59', '-1'],
  ['1月13日', '0:08:22', '-1'],
  ['1月14日', '0:08:45', '-1'],
  ['1月15日', '0:09:07', '-1'],
  ['1月16日', '0:09:28', '-1'],
  ['1月17日', '0:09:49', '-1'],
  ['1月18日', '0:10:09', '-1'],
  ['1月19日', '0:10:28', '-1'],
  ['1月20日', '0:10:47', '-1'],
  ['1月21日', '0:11:05', '-1'],
  ['1月22日', '0:11:22', '-1'],
  ['1月23日', '0:11:38', '-1'],
  ['1月24日', '0:11:54', '-1'],
  ['1月25日', '0:12:08', '-1'],
  ['1月26日', '0:12:22', '-1'],
  ['1月27日', '0:12:35', '-1'],
  ['1月28日', '0:12:59', '-1'],
  ['1月29日', '0:13:10', '-1'],
  ['1月30日', '0:13:19', '-1'],
  ['1月31日', '0:13:37', '-1'],

  ['2月1日', '0:13:44', '-1'],
  ['2月2日', '0:13:50', '-1'],
  ['2月3日', '0:13:56', '-1'],
  ['2月4日', '0:14:01', '-1'],
  ['2月5日', '0:14:05', '-1'],
  ['2月6日', '0:14:09', '-1'],
  ['2月7日', '0:14:11', '-1'],
  ['2月8日', '0:14:13', '-1'],
  ['2月9日', '0:14:14', '-1'],
  ['2月10日', '0:14:15', '-1'],
  ['2月11日', '0:14:14', '-1'],
  ['2月12日', '0:14:13', '-1'],
  ['2月13日', '0:14:11', '-1'],
  ['2月14日', '0:14:08', '-1'],
  ['2月15日', '0:14:05', '-1'],
  ['2月16日', '0:14:01', '-1'],
  ['2月17日', '0:13:56', '-1'],
  ['2月18日', '0:13:51', '-1'],
  ['2月19日', '0:13:44', '-1'],
  ['2月20日', '0:13:38', '-1'],
  ['2月21日', '0:13:30', '-1'],
  ['2月22日', '0:13:22', '-1'],
  ['2月23日', '0:13:13', '-1'],
  ['2月24日', '0:11:04', '-1'],
  ['2月25日', '0:12:54', '-1'],
  ['2月26日', '0:12:43', '-1'],
  ['2月27日', '0:12:32', '-1'],
  ['2月28日', '0:12:21', '-1'],
  ['2月29日', '0:12:08', '-1'],

  ['3月1日', '0:11:56', '-1'],
  ['3月2日', '0:11:43', '-1'],
  ['3月3日', '0:11:29', '-1'],
  ['3月4日', '0:11:15', '-1'],
  ['3月5日', '0:11:01', '-1'],
  ['3月6日', '0:10:47', '-1'],
  ['3月7日', '0:10:32', '-1'],
  ['3月8日', '0:10:16', '-1'],
  ['3月9日', '0:10:01', '-1'],
  ['3月10日', '0:09:45', '-1'],
  ['3月11日', '0:09:28', '-1'],
  ['3月12日', '0:09:12', '-1'],
  ['3月13日', '0:08:55', '-1'],
  ['3月14日', '0:08:38', '-1'],
  ['3月15日', '0:08:21', '-1'],
  ['3月16日', '0:08:04', '-1'],
  ['3月17日', '0:07:46', '-1'],
  ['3月18日', '0:07:29', '-1'],
  ['3月19日', '0:07:11', '-1'],
  ['3月20日', '0:06:53', '-1'],
  ['3月21日', '0:06:35', '-1'],
  ['3月22日', '0:06:17', '-1'],
  ['3月23日', '0:05:58', '-1'],
  ['3月24日', '0:05:40', '-1'],
  ['3月25日', '0:05:22', '-1'],
  ['3月26日', '0:05:04', '-1'],
  ['3月27日', '0:04:45', '-1'],
  ['3月28日', '0:04:27', '-1'],
  ['3月29日', '0:04:09', '-1'],
  ['3月30日', '0:03:51', '-1'],
  ['3月31日', '0:03:33', '-1'],

  ['4月1日', '0:03:16', '-1'],
  ['4月2日', '0:02:58', '-1'],
  ['4月3日', '0:02:41', '-1'],
  ['4月4日', '0:02:24', '-1'],
  ['4月5日', '0:02:07', '-1'],
  ['4月6日', '0:01:50', '-1'],
  ['4月7日', '0:01:33', '-1'],
  ['4月8日', '0:01:17', '-1'],
  ['4月9日', '0:01:01', '-1'],
  ['4月10日', '0:00:46', '1'],
  ['4月11日', '0:00:30', '1'],
  ['4月12日', '0:00:16', '1'],
  ['4月13日', '0:00:01', '1'],
  ['4月14日', '0:00:13', '1'],
  ['4月15日', '0:00:27', '1'],
  ['4月16日', '0:00:41', '1'],
  ['4月17日', '0:00:54', '1'],
  ['4月18日', '0:01:06', '1'],
  ['4月19日', '0:01:19', '1'],
  ['4月20日', '0:01:31', '1'],
  ['4月21日', '0:01:42', '1'],
  ['4月22日', '0:01:53', '1'],
  ['4月23日', '0:02:04', '1'],
  ['4月24日', '0:02:14', '1'],
  ['4月25日', '0:02:23', '1'],
  ['4月26日', '0:02:33', '1'],
  ['4月27日', '0:02:41', '1'],
  ['4月28日', '0:02:49', '1'],
  ['4月29日', '0:02:57', '1'],
  ['4月30日', '0:03:04', '1'],

  ['5月1日', '0:01:10', '1'],
  ['5月2日', '0:03:16', '1'],
  ['5月3日', '0:03:21', '1'],
  ['5月4日', '0:03:26', '1'],
  ['5月5日', '0:03:30', '1'],
  ['5月6日', '0:03:37', '1'],
  ['5月7日', '0:03:36', '1'],
  ['5月8日', '0:03:39', '1'],
  ['5月9日', '0:03:40', '1'],
  ['5月10日', '0:03:42', '1'],
  ['5月11日', '0:03:42', '1'],
  ['5月12日', '0:03:42', '1'],
  ['5月13日', '0:03:42', '1'],
  ['5月14日', '0:03:41', '1'],
  ['5月15日', '0:03:39', '1'],
  ['5月16日', '0:03:37', '1'],
  ['5月17日', '0:03:34', '1'],
  ['5月18日', '0:03:31', '1'],
  ['5月19日', '0:03:27', '1'],
  ['5月20日', '0:03:23', '1'],
  ['5月21日', '0:03:18', '1'],
  ['5月22日', '0:03:13', '1'],
  ['5月23日', '0:03:07', '1'],
  ['5月24日', '0:03:01', '1'],
  ['5月25日', '0:02:54', '1'],
  ['5月26日', '0:02:47', '1'],
  ['5月27日', '0:02:39', '1'],
  ['5月28日', '0:02:31', '1'],
  ['5月29日', '0:02:22', '1'],
  ['5月30日', '0:02:13', '1'],
  ['5月31日', '0:02:04', '1'],

  ['6月1日', '0:01:54', '1'],
  ['6月2日', '0:01:44', '1'],
  ['6月3日', '0:01:34', '1'],
  ['6月4日', '0:01:23', '1'],
  ['6月5日', '0:01:12', '1'],
  ['6月6日', '0:01:00', '1'],
  ['6月7日', '0:00:48', '1'],
  ['6月8日', '0:00:36', '1'],
  ['6月9日', '0:00:24', '1'],
  ['6月10日', '0:00:12', '1'],
  ['6月11日', '0:00:01', '1'],
  ['6月12日', '0:00:14', '1'],
  ['6月13日', '0:00:39', '1'],
  ['6月14日', '0:00:52', '1'],
  ['6月15日', '0:01:05', '-1'],
  ['6月16日', '0:01:18', '-1'],
  ['6月17日', '0:01:31', '-1'],
  ['6月18日', '0:01:45', '-1'],
  ['6月19日', '0:01:57', '-1'],
  ['6月20日', '0:02:10', '-1'],
  ['6月21日', '0:02:23', '-1'],
  ['6月22日', '0:02:36', '-1'],
  ['6月23日', '0:02:48', '-1'],
  ['6月24日', '0:03:01', '-1'],
  ['6月25日', '0:03:13', '-1'],
  ['6月26日', '0:03:25', '-1'],
  ['6月27日', '0:03:37', '-1'],
  ['6月28日', '0:03:49', '-1'],
  ['6月29日', '0:04:00', '-1'],
  ['6月30日', '0:04:11', '-1'],

  ['7月1日', '0:04:22', '-1'],
  ['7月2日', '0:04:33', '-1'],
  ['7月3日', '0:04:43', '-1'],
  ['7月4日', '0:04:53', '-1'],
  ['7月5日', '0:05:02', '-1'],
  ['7月6日', '0:05:11', '-1'],
  ['7月7日', '0:05:20', '-1'],
  ['7月8日', '0:05:28', '-1'],
  ['7月9日', '0:05:36', '-1'],
  ['7月10日', '0:05:43', '-1'],
  ['7月11日', '0:05:50', '-1'],
  ['7月12日', '0:05:56', '-1'],
  ['7月13日', '0:06:02', '-1'],
  ['7月14日', '0:06:08', '-1'],
  ['7月15日', '0:06:12', '-1'],
  ['7月16日', '0:06:16', '-1'],
  ['7月17日', '0:06:20', '-1'],
  ['7月18日', '0:06:23', '-1'],
  ['7月19日', '0:06:25', '-1'],
  ['7月20日', '0:06:27', '-1'],
  ['7月21日', '0:06:29', '-1'],
  ['7月22日', '0:06:29', '-1'],
  ['7月23日', '0:06:29', '-1'],
  ['7月24日', '0:06:29', '-1'],
  ['7月25日', '0:06:28', '-1'],
  ['7月26日', '0:06:26', '-1'],
  ['7月27日', '0:06:24', '-1'],
  ['7月28日', '0:06:21', '-1'],
  ['7月29日', '0:06:17', '-1'],
  ['7月30日', '0:06:13', '-1'],
  ['7月31日', '0:06:08', '-1'],

  ['8月1日', '0:06:03', '-1'],
  ['8月2日', '0:05:57', '-1'],
  ['8月3日', '0:05:51', '-1'],
  ['8月4日', '0:05:44', '-1'],
  ['8月5日', '0:05:36', '-1'],
  ['8月6日', '0:05:28', '-1'],
  ['8月7日', '0:05:19', '-1'],
  ['8月8日', '0:05:10', '-1'],
  ['8月9日', '0:05:00', '-1'],
  ['8月10日', '0:04:50', '-1'],
  ['8月11日', '0:04:39', '-1'],
  ['8月12日', '0:04:27', '-1'],
  ['8月13日', '0:04:15', '-1'],
  ['8月14日', '0:04:02', '-1'],
  ['8月15日', '0:03:49', '-1'],
  ['8月16日', '0:03:36', '-1'],
  ['8月17日', '0:03:21', '-1'],
  ['8月18日', '0:03:07', '-1'],
  ['8月19日', '0:02:51', '-1'],
  ['8月20日', '0:02:36', '-1'],
  ['8月21日', '0:02:20', '-1'],
  ['8月22日', '0:02:03', '-1'],
  ['8月23日', '0:01:47', '-1'],
  ['8月24日', '0:01:29', '-1'],
  ['8月25日', '0:01:12', '-1'],
  ['8月26日', '0:00:54', '1'],
  ['8月27日', '0:00:35', '1'],
  ['8月28日', '0:00:17', '1'],
  ['8月29日', '0:00:02', '1'],
  ['8月30日', '0:00:21', '1'],
  ['8月31日', '0:00:41', '1'],

  ['9月1日', '0:01:00', '1'],
  ['9月2日', '0:01:20', '1'],
  ['9月3日', '0:01:40', '1'],
  ['9月4日', '0:02:01', '1'],
  ['9月5日', '0:02:21', '1'],
  ['9月6日', '0:02:42', '1'],
  ['9月7日', '0:03:03', '1'],
  ['9月8日', '0:03:03', '1'],
  ['9月9日', '0:03:24', '1'],
  ['9月10日', '0:03:45', '1'],
  ['9月11日', '0:04:06', '1'],
  ['9月12日', '0:04:27', '1'],
  ['9月13日', '0:04:48', '1'],
  ['9月14日', '0:05:10', '1'],
  ['9月15日', '0:05:31', '1'],
  ['9月16日', '0:05:53', '1'],
  ['9月17日', '0:06:14', '1'],
  ['9月18日', '0:06:35', '1'],
  ['9月19日', '0:06:57', '1'],
  ['9月20日', '0:07:18', '1'],
  ['9月21日', '0:07:39', '1'],
  ['9月22日', '0:08:00', '1'],
  ['9月23日', '0:08:21', '1'],
  ['9月24日', '0:08:42', '1'],
  ['9月25日', '0:09:02', '1'],
  ['9月26日', '0:09:22', '1'],
  ['9月27日', '0:09:42', '1'],
  ['9月28日', '0:10:02', '1'],
  ['9月29日', '0:10:21', '1'],
  ['9月30日', '0:10:40', '1'],

  ['10月1日', '0:10:59', '1'],
  ['10月2日', '0:11:18', '1'],
  ['10月3日', '0:11:36', '1'],
  ['10月4日', '0:11:36', '1'],
  ['10月5日', '0:11:53', '1'],
  ['10月6日', '0:12:11', '1'],
  ['10月7日', '0:12:28', '1'],
  ['10月8日', '0:12:44', '1'],
  ['10月9日', '0:13:00', '1'],
  ['10月10日', '0:13:16', '1'],
  ['10月11日', '0:13:16', '1'],
  ['10月12日', '0:13:31', '1'],
  ['10月13日', '0:13:45', '1'],
  ['10月14日', '0:13:59', '1'],
  ['10月15日', '0:14:13', '1'],
  ['10月16日', '0:14:26', '1'],
  ['10月17日', '0:14:38', '1'],
  ['10月18日', '0:14:50', '1'],
  ['10月19日', '0:15:01', '1'],
  ['10月20日', '0:15:12', '1'],
  ['10月21日', '0:15:21', '1'],
  ['10月22日', '0:15:31', '1'],
  ['10月23日', '0:15:40', '1'],
  ['10月24日', '0:15:48', '1'],
  ['10月25日', '0:15:55', '1'],
  ['10月26日', '0:16:01', '1'],
  ['10月27日', '0:16:07', '1'],
  ['10月28日', '0:16:12', '1'],
  ['10月29日', '0:16:16', '1'],
  ['10月30日', '0:16:20', '1'],
  ['10月31日', '0:16:22', '1'],

  ['11月1日', '0:16:24', '1'],
  ['11月2日', '0:16:25', '1'],
  ['11月3日', '0:16:25', '1'],
  ['11月4日', '0:16:24', '1'],
  ['11月5日', '0:16:23', '1'],
  ['11月6日', '0:16:21', '1'],
  ['11月7日', '0:16:17', '1'],
  ['11月8日', '0:16:13', '1'],
  ['11月9日', '0:16:09', '1'],
  ['11月10日', '0:16:03', '1'],
  ['11月11日', '0:15:56', '1'],
  ['11月12日', '0:15:49', '1'],
  ['11月13日', '0:15:41', '1'],
  ['11月14日', '0:15:32', '1'],
  ['11月15日', '0:15:22', '1'],
  ['11月16日', '0:15:11', '1'],
  ['11月17日', '0:15:00', '1'],
  ['11月18日', '0:14:47', '1'],
  ['11月19日', '0:14:34', '1'],
  ['11月20日', '0:14:20', '1'],
  ['11月21日', '0:14:06', '1'],
  ['11月22日', '0:13:50', '1'],
  ['11月23日', '0:13:34', '1'],
  ['11月24日', '0:13:17', '1'],
  ['11月25日', '0:12:59', '1'],
  ['11月26日', '0:12:40', '1'],
  ['11月27日', '0:12:21', '1'],
  ['11月28日', '0:12:01', '1'],
  ['11月29日', '0:11:40', '1'],
  ['11月30日', '0:11:18', '1'],

  ['12月1日', '0:10:56', '1'],
  ['12月2日', '0:10:33', '1'],
  ['12月3日', '0:10:09', '1'],
  ['12月4日', '0:09:45', '1'],
  ['12月5日', '0:09:21', '1'],
  ['12月6日', '0:08:55', '1'],
  ['12月7日', '0:08:29', '1'],
  ['12月8日', '0:08:03', '1'],
  ['12月9日', '0:07:36', '1'],
  ['12月10日', '0:07:09', '1'],
  ['12月11日', '0:06:42', '1'],
  ['12月12日', '0:06:14', '1'],
  ['12月13日', '0:05:46', '1'],
  ['12月14日', '0:05:17', '1'],
  ['12月15日', '0:04:48', '1'],
  ['12月16日', '0:04:19', '1'],
  ['12月17日', '0:03:50', '1'],
  ['12月18日', '0:03:21', '1'],
  ['12月19日', '0:02:51', '1'],
  ['12月20日', '0:02:22', '1'],
  ['12月21日', '0:01:52', '1'],
  ['12月22日', '0:01:22', '1'],
  ['12月23日', '0:00:52', '1'],
  ['12月24日', '0:00:23', '1'],
  ['12月25日', '0:00:07', '1'],
  ['12月26日', '0:00:37', '1'],
  ['12月27日', '0:01:06', '-1'],
  ['12月28日', '0:01:36', '-1'],
  ['12月29日', '0:02:05', '-1'],
  ['12月30日', '0:02:34', '-1'],
  ['12月31日', '0:03:03', '-1'],
]

// 食神
export const shisheng = [
  //			{"日/干", "甲",   "乙",  "丙",  "丁",  "戊",   "己",  "庚",   "辛",  "壬",  "癸"},
  /*"甲"*/
  [
    '比肩',
    '劫财',
    '食神',
    '伤官',
    '偏财',
    '正财',
    '七杀',
    '正官',
    '偏印',
    '正印',
  ],
  /*"乙"*/
  [
    '劫财',
    '比肩',
    '伤官',
    '食神',
    '正财',
    '偏财',
    '正官',
    '七杀',
    '正印',
    '偏印',
  ],
  /*"丙"*/
  [
    '偏印',
    '正印',
    '比肩',
    '劫财',
    '食神',
    '伤官',
    '偏财',
    '正财',
    '七杀',
    '正官',
  ],
  /*"丁"*/
  [
    '正印',
    '偏印',
    '劫财',
    '比肩',
    '伤官',
    '食神',
    '正财',
    '偏财',
    '正官',
    '七杀',
  ],
  /*"戊"*/
  [
    '七杀',
    '正官',
    '偏印',
    '正印',
    '比肩',
    '劫财',
    '食神',
    '伤官',
    '偏财',
    '正财',
  ],
  /*"己"*/
  [
    '正官',
    '七杀',
    '正印',
    '偏印',
    '劫财',
    '比肩',
    '伤官',
    '食神',
    '正财',
    '偏财',
  ],
  /*"庚"*/
  [
    '偏财',
    '正财',
    '七杀',
    '正官',
    '偏印',
    '正印',
    '比肩',
    '劫财',
    '食神',
    '伤官',
  ],
  /*"辛"*/
  [
    '正财',
    '偏财',
    '正官',
    '七杀',
    '正印',
    '偏印',
    '劫财',
    '比肩',
    '伤官',
    '食神',
  ],
  /*"壬"*/
  [
    '食神',
    '伤官',
    '偏财',
    '正财',
    '七杀',
    '正官',
    '偏印',
    '正印',
    '比肩',
    '劫财',
  ],
  /*"癸"*/
  [
    '伤官',
    '食神',
    '正财',
    '偏财',
    '正官',
    '七杀',
    '正印',
    '偏印',
    '劫财',
    '比肩',
  ],
]

// 天干
export const TianGan = [
  '甲',
  '乙',
  '丙',
  '丁',
  '戊',
  '己',
  '庚',
  '辛',
  '壬',
  '癸',
]
// 地支
export const DiZhi = [
  '子',
  '丑',
  '寅',
  '卯',
  '辰',
  '巳',
  '午',
  '未',
  '申',
  '酉',
  '戌',
  '亥',
]

export const ly_zhi = [
  '寅',
  '卯',
  '辰',
  '巳',
  '午',
  '未',
  '申',
  '酉',
  '戌',
  '亥',
  '子',
  '丑',
]

// vip等级名称
export const vipLevelName = ['黄金', '黄金', '钻石', '钻石']

// 默认替换符
export const defaultText = '﹡'

// 敏感词
export const  blackwordList = {
    "习大大": 1,
    "习小小": 1,
    "习中中": 1,
    "小小平": 1,
    "近大大": 1,
    "平大大": 1,
    "x大大": 1,
    "近大大": 1,
    "xidada": 1,
    "xizhuxi": 1,
    "xizong": 1,
    "xjp": 1,
    "大大": 1,
    "jp大大": 1,
    "jping": 1,
    "习总": 1,
    "近平总": 1,
    "习近": 1,
    "习jj": 1,
    "习pp": 1,
    "习sir": 1,
    "jinping": 1,
    "某习": 1,
    "某平": 1,
    "某近": 1,
    "某大大": 1,
    "习某": 1,
    "习z": 1,
    "习帝": 1,
    "习武帝": 1,
    "习jp": 1,
    "习太子": 1,
    "近平": 1,
    "习远平": 1,
    "习x": 1,
    "近平习": 1,
    "平近习": 1,
    "平近": 1,
    "习紧平": 1,
    "习紧": 1,
    "紧平": 1,
    "紧平大大": 1,
    "紧平boss": 1,
    "近平boss": 1,
    "近boss": 1,
    "习boss": 1,
    "习近": 1,
    "习平": 1,
    "习核心": 1,
    "习core": 1,
    "习主席": 1,
    "习书记": 1,
    "习boss": 1,
    "习贼": 1,
    "习猪": 1,
    "习熊": 1,
    "习明泽": 1,
    "老习": 1,
    "平总": 1,
    "近平习": 1,
    "近习": 1,
    "近平·习": 1,
    "平·习": 1,
    "习维尼": 1,
    "习维尼熊": 1,
    "习兔子": 1,
    "习兔": 1,
    "习大": 1,
    "习x": 1,
    "习xx": 1,
    "习彭": 1,
    "习姐夫": 1,
    "习相": 1,
    "彭姐夫": 1,
    "习近平": 1,
    "习近p": 1,
    "习近ping": 1,
    "习j平": 1,
    "习jp": 1,
    "习jping": 1,
    "习jin平": 1,
    "习jinp": 1,
    "习jinping": 1,
    "x近平": 1,
    "x近p": 1,
    "x近ping": 1,
    "xj平": 1,
    "xjp": 1,
    "xjping": 1,
    "xjin平": 1,
    "xjinp": 1,
    "xjinping": 1,
    "xi近平": 1,
    "xi近p": 1,
    "xi近ping": 1,
    "xij平": 1,
    "xijp": 1,
    "xijping": 1,
    "xijin平": 1,
    "xijinp": 1,
    "xijinping": 1,
    "温boss": 1,
    "温加宝": 1,
    "温x": 1,
    "温总理": 1,
    "温首相": 1,
    "温宝宝": 1,
    "温加饱": 1,
    "温加保": 1,
    "温云松": 1,
    "温如春": 1,
    "温家宝": 1,
    "温家b": 1,
    "温家bao": 1,
    "温j宝": 1,
    "温jb": 1,
    "温jbao": 1,
    "温jia宝": 1,
    "温jiab": 1,
    "温jiabao": 1,
    "w家宝": 1,
    "w家b": 1,
    "w家bao": 1,
    "wj宝": 1,
    "wjb": 1,
    "wjbao": 1,
    "wjia宝": 1,
    "wjiab": 1,
    "wjiabao": 1,
    "wen家宝": 1,
    "wen家b": 1,
    "wen家bao": 1,
    "wenj宝": 1,
    "wenjb": 1,
    "wenjbao": 1,
    "wenjia宝": 1,
    "wenjiab": 1,
    "wenjiabao": 1,
    "胡x": 1,
    "胡boss": 1,
    "胡总": 1,
    "胡王八": 1,
    "胡boss": 1,
    "胡惊涛": 1,
    "胡景涛": 1,
    "胡紧掏": 1,
    "湖紧掏": 1,
    "胡紧套": 1,
    "锦涛": 1,
    "胡派": 1,
    "胡主席": 1,
    "胡书记": 1,
    "胡锦涛": 1,
    "胡锦t": 1,
    "胡锦tao": 1,
    "胡j涛": 1,
    "胡jt": 1,
    "胡jtao": 1,
    "胡jin涛": 1,
    "胡jint": 1,
    "胡jintao": 1,
    "h锦涛": 1,
    "h锦t": 1,
    "h锦tao": 1,
    "hj涛": 1,
    "hjt": 1,
    "hjtao": 1,
    "hjin涛": 1,
    "hjint": 1,
    "hjintao": 1,
    "hu锦涛": 1,
    "hu锦t": 1,
    "hu锦tao": 1,
    "huj涛": 1,
    "hujt": 1,
    "hujtao": 1,
    "hujin涛": 1,
    "hujint": 1,
    "hujintao": 1,
    "民泽江": 1,
    "江胡": 1,
    "江主席": 1,
    "江书记": 1,
    "江书记": 1,
    "江浙闽": 1,
    "江沢民": 1,
    "江浙民": 1,
    "择民": 1,
    "则民": 1,
    "茳泽民": 1,
    "zemin": 1,
    "ze民": 1,
    "江core": 1,
    "江x": 1,
    "江派": 1,
    "江戏子": 1,
    "江蛤蟆": 1,
    "江某某": 1,
    "江泽慧": 1,
    "江贼": 1,
    "江猪": 1,
    "江氏集团": 1,
    "江泽民": 1,
    "江泽m": 1,
    "江泽min": 1,
    "江z民": 1,
    "江zm": 1,
    "江zmin": 1,
    "江ze民": 1,
    "江zem": 1,
    "江zemin": 1,
    "j泽民": 1,
    "j泽m": 1,
    "j泽min": 1,
    "jz民": 1,
    "jzm": 1,
    "jzmin": 1,
    "jze民": 1,
    "Jzem": 1,
    "jzemin": 1,
    "jiang泽民": 1,
    "jiang泽m": 1,
    "jiang泽min": 1,
    "jiangz民": 1,
    "jiangzm": 1,
    "jiangzmin": 1,
    "jiangze民": 1,
    "jiangzem": 1,
    "jiangzemin": 1,
    "小平": 1,
    "邓矮子": 1,
    "平小邓": 1,
    "xiao平": 1,
    "邓xp": 1,
    "邓晓平": 1,
    "邓南巡": 1,
    "邓改革": 1,
    "邓开放": 1,
    "邓朴方": 1,
    "邓主席": 1,
    "邓书记": 1,
    "邓小平": 1,
    "邓小p": 1,
    "邓小ping": 1,
    "邓x平": 1,
    "邓xp": 1,
    "邓xping": 1,
    "邓xiao平": 1,
    "邓xiaop": 1,
    "邓xiaoping": 1,
    "d小平": 1,
    "d小p": 1,
    "d小ping": 1,
    "dx平": 1,
    "dxp": 1,
    "dxping": 1,
    "dxiao平": 1,
    "dxiaop": 1,
    "dxiaoping": 1,
    "deng小平": 1,
    "deng小p": 1,
    "deng小ping": 1,
    "dengx平": 1,
    "dengxp": 1,
    "dengxping": 1,
    "dengxiao平": 1,
    "dengxiaop": 1,
    "dengxiaoping": 1,
    "毛泽东": 1,
    "猫泽东": 1,
    "猫则东": 1,
    "猫贼洞": 1,
    "毛zd": 1,
    "毛zx": 1,
    "z东": 1,
    "ze东": 1,
    "泽d": 1,
    "zedong": 1,
    "毛太祖": 1,
    "太祖": 1,
    "毛相": 1,
    "毛新宇": 1,
    "主席画像": 1,
    "毛爷爷": 1,
    "毛泽东": 1,
    "毛泽d": 1,
    "毛泽dong": 1,
    "毛z东": 1,
    "毛zd": 1,
    "毛zdong": 1,
    "毛ze东": 1,
    "毛zed": 1,
    "毛zedong": 1,
    "m泽东": 1,
    "m泽d": 1,
    "m泽dong": 1,
    "mz东": 1,
    "mzd": 1,
    "mzdong": 1,
    "mze东": 1,
    "mzed": 1,
    "mzedong": 1,
    "mao泽东": 1,
    "maozd": 1,
    "maozdong": 1,
    "maoze东": 1,
    "maozed": 1,
    "maozedong": 1,
    "朱镕基": 1,
    "朱容基": 1,
    "朱镕鸡": 1,
    "朱容鸡": 1,
    "李鹏": 1,
    "李peng": 1,
    "里鹏": 1,
    "李月月鸟": 1,
    "华主席": 1,
    "华国": 1,
    "华国锋": 1,
    "国锋": 1,
    "彭丽媛": 1,
    "丽媛": 1,
    "彭麻麻": 1,
    "彭妈妈": 1,
    "胡耀邦": 1,
    "耀邦": 1,
    "赵紫阳": 1,
    "紫阳": 1,
    "周恩来": 1,
    "恩来": 1,
    "杨尚昆": 1,
    "尚昆": 1,
    "李先念": 1,
    "朱德": 1,
    "刘少奇": 1,
    "少奇": 1,
    "毛岸英": 1,
    "岸英": 1,
    "蛋炒饭": 1,
    "江青": 1,
    "王洪文": 1,
    "张春桥": 1,
    "江青": 1,
    "姚文元": 1,
    "李克强": 1,
    "克强": 1,
    "朱镕基": 1,
    "榕基": 1,
    "李鹏": 1,
    "彭李艳": 1,
    "栗战书": 1,
    "战书": 1,
    "汪洋": 1,
    "王沪宁": 1,
    "沪宁": 1,
    "赵乐际": 1,
    "乐际": 1,
    "韩正": 1,
    "吴邦国": 1,
    "贾庆林": 1,
    "李长春": 1,
    "贺国强": 1,
    "周永康": 1,
    "张德江": 1,
    "俞正声": 1,
    "刘云山": 1,
    "王岐山": 1,
    "张高丽": 1,
    "第一代领导人": 1,
    "第二代领导人": 1,
    "第三代领导人": 1,
    "第四代领导人": 1,
    "达赖": 1,
    "喇嘛": 1,
    "活佛": 1,
    "李洪志": 1,
    "法轮功": 1,
    "藏独": 1,
    "疆独": 1,
    "台独": 1,
    "港独": 1,
    "废青": 1,
    "蒋介石": 1,
    "蒋校长": 1,
    "李登辉": 1,
    "蒋经国": 1,
    "陈水扁": 1,
    "阿扁": 1,
    "陈独秀": 2,
    "项英": 2,
    "朱德": 2,
    "任弼时": 2,
    "彭真": 2,
    "张闻天": 2,
    "彭德怀": 2,
    "罗荣桓": 2,
    "陈毅": 2,
    "李富春": 2,
    "刘伯承": 2,
    "叶剑英": 2,
    "陈伯达": 2,
    "张春桥": 2,
    "王洪文": 2,
    "韦国清": 2,
    "纪登奎": 2,
    "汪东兴": 2,
    "姚文元": 2,
    "乌兰夫": 2,
    "徐向前": 2,
    "习仲勋": 2,
    "王震": 2,
    "邓颖超": 2,
    "李德生": 2,
    "田纪云": 2,
    "李铁映": 2,
    "李瑞环": 2,
    "李锡铭": 2,
    "宋平": 2,
    "胡启立": 2,
    "姚依林": 2,
    "刘华清": 2,
    "李岚清": 2,
    "吴邦国": 2,
    "邹家华": 2,
    "陈希同": 2,
    "姜春云": 2,
    "钱其琛": 2,
    "尉健行": 2,
    "李长春": 2,
    "吴官正": 2,
    "罗干": 2,
    "黄菊": 2,
    "王兆国": 2,
    "回良玉": 2,
    "刘淇": 2,
    "刘云山": 2,
    "吴仪": 2,
    "张德江": 2,
    "陈良宇": 2,
    "周永康": 2,
    "俞正声": 2,
    "贺国强": 2,
    "郭伯雄": 2,
    "曹刚川": 2,
    "曾庆红": 2,
    "曾培炎": 2,
    "王乐泉": 2,
    "王岐山": 2,
    "回良玉": 2,
    "刘淇": 2,
    "刘延东": 2,
    "李源潮": 2,
    "汪洋": 2,
    "张高丽": 2,
    "徐才厚": 2,
    "薄熙来": 2,
    "王沪宁": 2,
    "刘奇葆": 2,
    "许其亮": 2,
    "孙春兰": 2,
    "孙政才": 2,
    "李建国": 2,
    "张春贤": 2,
    "范长龙": 2,
    "孟建柱": 2,
    "赵乐际": 2,
    "胡春华": 2,
    "栗战书": 2,
    "郭金龙": 2,
    "韩正": 2,
    "丁薛祥": 2,
    "刘鹤": 2,
    "李希": 2,
    "李强": 2,
    "李鸿忠": 2,
    "杨晓渡": 2,
    "张又侠": 2,
    "陈全国": 2,
    "陈敏尔": 2,
    "郭声琨": 2,
    "黄坤明": 2,
    "蔡奇": 2,
    "薄一波": 2,
    "杜青林": 2,
    "赵洪祝": 2,
    "陈希同": 2,
    "郭声琨": 2,
    "王忠禹": 2,
    "陈至立": 2,
    "梁光烈": 2,
    "戴秉国": 2,
    "常万全": 2,
    "魏凤和": 2,
    "肖捷": 2,
    "赵克志": 2,
    "主席": 2,
    "总理": 2,
    "常委": 2,
    "检察院": 2,
    "市长": 2,
    "中共中央": 2,
    "外交部": 2,
    "国防部": 2,
    "教育部": 2,
    "财政部": 2,
    "住建部": 2,
    "军委": 2,
    "孙中山": 2,
    "蒋介石": 2,
    "蒋经国": 2,
    "国民党蒋介石": 2,
    "李登辉": 2,
    "陈水扁": 2,
    "马英九": 2,
    "蔡英文": 2,
    "董建华": 2,
    "梁振英": 2,
    "林郑月娥": 2,
    "何厚铧": 2,
    "达赖": 2,
    "李洪志": 2,
    "藏独": 2,
    "台独": 2,
    "89": 2,
    "疆独": 2,
    "金日成": 2,
    "金正日": 2,
    "金正恩": 2,
    "金三胖": 2,
    "港独": 2,
    "新疆棉": 2,
    "陈红军": 2,
    "陈祥榕": 2,
    "肖思远": 2,
    "王焯冉": 2,
    "温云松": 2,
    "张培莉": 2,
    "温如春": 2,
    "刘永清": 2,
    "江绵恒": 2,
    "江绵康": 2,
    "王冶坪 ": 2,
    "江泽慧": 2,
    "邓朴方": 2,
    "邓榕": 2,
    "邓质方": 2,
    "朱云来": 2,
    "胡海峰": 2,
    "胡海清": 2,
    "李小琳": 2,
    "普京": 2,
    "弗拉基米尔·泽连斯基": 2,
    "乌克兰总统": 2,
    "梅德韦杰夫": 2,
    "奥列格·西涅古诺夫": 2,
    "德米特里·佩斯科夫": 2,
    "法国总理": 2,
    "德国总统": 2,
    "土耳其总理": 2,
    "中国教徒": 2,
    "统一教": 2,
    "李洪志": 2,
    "志洪李": 2,
    "法轮": 2,
    "轮法功": 2,
    "三去车仑": 2,
    "氵去车仑": 2,
    "发论工": 2,
    "法x功": 2,
    "法0功": 2,
    "轮子功": 2,
    "车仑工力": 2,
    "法lun": 2,
    "fa轮": 2,
    "法lg": 2,
    "flg": 2,
    "fl功": 2,
    "大法弟子": 2,
    "大纪元": 2,
    "明慧网": 2,
    "明慧周报": 2,
    "伪火": 2,
    "超越红墙": 2,
    "真善忍": 2,
    "藏字石": 2,
    "人类灭亡进程表": 2,
    "按照马雅历法": 2,
    "促红细胞生成素": 2,
    "胰岛素样生长因子": 2,
    "testosterone": 2,
    "strychnine": 2,
    "新型毒品": 2,
    "海洛因": 2,
    "海luo因": 2,
    "cocain": 2,
    "麻古": 2,
    "冰毒": 2,
    "苯丙胺": 2,
    "大麻": 2,
    "安非他命": 2,
    "腐败中国": 2,
    "打倒中国": 2,
    "打倒共产党": 2,
    "打倒共产主义": 2,
    "打倒胡锦涛": 2,
    "打倒江泽民": 2,
    "打倒江主席": 2,
    "打倒李鹏": 2,
    "打倒温家宝": 2,
    "打倒中共": 2,
    "抵制共产党": 2,
    "抵制共产主义": 2,
    "抵制胡锦涛": 2,
    "抵制江泽民": 2,
    "抵制江主席": 2,
    "抵制李鹏": 2,
    "抵制罗干": 2,
    "抵制温家宝": 2,
    "抵制中共": 2,
    "抵制朱镕基": 2,
    "灭亡中国": 2,
    "亡党亡国": 2,
    "粉碎四人帮": 2,
    "激流中国": 2,
    "贪污腐败": 2,
    "强制拆除": 2,
    "政治风波": 2,
    "太子党": 2,
    "上海帮": 2,
    "北京帮": 2,
    "清华帮": 2,
    "红色贵族": 2,
    "官匪": 2,
    "独夫民贼": 2,
    "官商勾结": 2,
    "城管暴力执法": 2,
    "一党执政": 2,
    "宪法法院": 2,
    "胡平": 2,
    "贺卫方": 2,
    "一党专制": 2,
    "一党专政": 2,
    "宪法法院": 2,
    "张志新": 2,
    "王炳章": 2,
    "魏京生": 2,
    "寻找林昭的灵魂": 2,
    "谁是新中国": 2,
    "讨伐中宣部": 2,
    "异议人士": 2,
    "民运人士": 2,
    "chinesedemocracy": 2,
    "选国家主席": 2,
    "民一主": 2,
    "宪政": 2,
    "平反": 2,
    "党章": 2,
    "人木又": 2,
    "中国人权": 2,
    "中国新民党": 2,
    "群体事件": 2,
    "群体性事件": 2,
    "上中央": 2,
    "去中央": 2,
    "联名上书": 2,
    "截访": 2,
    "上访": 2,
    "访民": 2,
    "游行": 2,
    "you行": 2,
    "官逼民反": 2,
    "反共": 2,
    "抗议": 2,
    "焚烧中国国旗": 2,
    "流血冲突": 2,
    "灭共": 2,
    "罢考": 2,
    "罢工": 2,
    "罢课": 2,
    "罢运": 2,
    "jie严": 2,
    "五毛党": 2,
    "8的平方事件": 2,
    "知道64": 2,
    "八九年": 2,
    "六四": 2,
    "陆四": 2,
    "198964": 2,
    "89年春夏之交": 2,
    "64惨案": 2,
    "4事件": 2,
    "64运动": 2,
    "64时期": 2,
    "学潮": 2,
    "xuechao": 2,
    "坦克压大学生": 2,
    "血洗京城": 2,
    "王丹": 2,
    "柴玲": 2,
    "王超华": 2,
    "侯德健": 2,
    "丁子霖": 2,
    "党的喉舌": 2,
    "中华局域网": 2,
    "新闻封锁": 2,
    "封锁消息": 2,
    "中国的陷阱": 2,
    "境外媒体": 2,
    "东森新闻网": 2,
    "东森电视": 2,
    "自由亚洲": 2,
    "自由时报": 2,
    "中国时报": 2,
    "钓鱼岛": 2,
    "8341部队": 2,
    "七大军区": 2,
    "沈阳军区": 2,
    "南京军区": 2,
    "兰州军区": 2,
    "印尼屠华": 2,
    "印尼事件命": 2,
    "蒋公纪念歌": 2,
    "马英九": 2,
    "苏贞昌": 2,
    "台湾": 2,
    "台湾共和国": 2,
    "台独": 2,
    "一中一台": 2,
    "打台湾": 2,
    "两岸战争": 2,
    "攻占台湾": 2,
    "支持台湾": 2,
    "进攻台湾": 2,
    "占领台湾": 2,
    "统一台湾": 2,
    "收复台湾": 2,
    "登陆台湾": 2,
    "解放台湾": 2,
    "台湾独立": 2,
    "解决台湾": 2,
    "台湾问题": 2,
    "台海问题": 2,
    "台海危机": 2,
    "台海统一": 2,
    "台海战争": 2,
    "台海局势": 2,
    "中华联邦": 2,
    "国民党": 2,
    "x民党": 2,
    "民进党": 2,
    "闹独立": 2,
    "热比娅": 2,
    "伊力哈木": 2,
    "疆独": 2,
    "达赖": 2,
    "阿旺晋美": 2,
    "哒赖": 2,
    "藏独": 2,
    "支持zd": 2,
    "藏暴乱": 2,
    "藏青会": 2,
    "雪山狮子旗": 2,
    "阴b": 2,
    "幼交": 2,
    "幼女": 2,
    "玉乳": 2,
    "玉穴": 2,
    "抓胸": 2,
    "自慰": 2,
    "作爱": 2,
    "欠干": 2,
    "我日你": 2,
    "爆你菊": 2,
    "cao你": 2,
    "贱比": 2,
    "全家死绝": 2,
    "精子": 2,
    "射精": 2,
    "强奸": 2,
    "内裤": 2,
    "高潮": 2,
    "干死": 2,
    "乱伦小": 2,
    "美艳少妇": 2,
    "迷奸粉": 2,
    "迷奸药": 2,
    "迷药": 2,
    "骚货": 2,
    "色b": 2,
    "射爽": 2,
    "射颜": 2,
    "兽奸": 2,
    "兽交": 2,
    "手淫": 2,
    "熟女": 2,
    "体奸": 2,
    "舔阴": 2,
    "小逼": 2,
    "小穴": 2,
    "小xue": 2,
    "胸推": 2,
    "穴口": 2,
    "颜射": 2,
    "阳具": 2,
    "要射了": 2,
    "阴部": 2,
    "阴唇": 2,
    "阴道": 2,
    "阴核": 2,
    "阴户": 2,
    "阿共": 2,
    "共产主义幽灵": 2,
    "恶党": 2,
    "邪党": 2,
    "共产专制": 2,
    "共产王朝": 2,
    "裆中央": 2,
    "土共": 2,
    "共匪": 2,
    "共产党腐败": 2,
    "仇共": 2,
    "共产党专制": 2,
    "共产党报应": 2,
    "共产党末日": 2,
    "communistparty": 2,
    "zhengfu": 2,
    "挡中央": 2,
    "档中央": 2,
    "中国zf": 2,
    "中央zf": 2,
    "中华帝国": 2,
    "性交视频": 2,
    "性交图片": 2,
    "阴唇": 2,
    "阴道": 2,
    "阴户": 2,
    "阴茎增大": 2,
    "阴茎助勃": 2,
    "陰道": 2,
    "淫穴": 2,
    "摇头丸": 2,
    "迷药": 2,
    "暴奸": 2,
    "暴乳": 2,
    "爆乳": 2,
    "屄": 2,
    "被操": 2,
    "逼奸": 2,
    "插暴": 2,
    "操逼": 2,
    "操黑": 2,
    "操烂": 2,
    "肏你": 2,
    "操我": 2,
    "插比": 2,
    "插b": 2,
    "插逼": 2,
    "插你": 2,
    "插我": 2,
    "插阴": 2,
    "潮吹": 2,
    "潮喷": 2,
    "吃精": 2,
    "抽插": 2,
    "扌由插": 2,
    "抽一插": 2,
    "大力抽送": 2,
    "大乳": 2,
    "肥逼": 2,
    "粉穴": 2,
    "干穴": 2,
    "肛交": 2,
    "肛门": 2,
    "龟头": 2,
    "豪乳": 2,
    "黑逼": 2,
    "后庭": 2,
    "后穴": 2,
    "鸡吧": 2,
    "鸡巴": 2,
    "脚交": 2,
    "精液": 2,
    "就去日": 2,
    "巨屌": 2,
    "巨奶": 2,
    "巨乳": 2,
    "菊穴": 2,
    "口爆": 2,
    "口交": 2,
    "口射": 2,
    "狂操": 2,
    "狂插": 2,
    "浪逼": 2,
    "漏乳": 2,
    "露b": 2,
    "乱交": 2,
    "轮操": 2,
    "轮奸": 2,
    "美逼": 2,
    "美少妇": 2,
    "美乳": 2,
    "美腿": 2,
    "美穴": 2,
    "迷奸": 2,
    "密穴": 2,
    "蜜穴": 2,
    "摸奶": 2,
    "母奸": 2,
    "奶子": 2,
    "内射": 2,
    "嫩逼": 2,
    "嫩穴": 2,
    "强jian": 2,
    "强暴": 2,
    "强奸处女": 2,
    "拳交": 2,
    "群交": 2,
    "人妻": 2,
    "人兽": 2,
    "日逼": 2,
    "日烂": 2,
    "肉逼": 2,
    "肉唇": 2,
    "肉棍": 2,
    "肉茎": 2,
    "揉乳": 2,
    "肉穴": 2,
    "乳房": 2,
    "乳沟": 2,
    "乳交": 2,
    "乳头": 2,
    "骚逼": 2,
    "骚比": 2,
    "藏独": 2,
    "藏独立": 2,
    "藏妇会": 2,
    "藏青会": 2,
    "藏字石": 2,
    "穆斯林": 2,
    "穆罕穆德": 2,
    "江丑闻": 2,
    "江嫡系": 2,
    "江独裁": 2,
    "江蛤蟆": 2,
    "江核心": 2,
    "江黑心": 2,
    "江胡内斗": 2,
    "江祸心": 2,
    "江家帮": 2,
    "江绵恒": 2,
    "江派": 2,
    "和胡派": 2,
    "江派人马": 2,
    "江氏集团": 2,
    "江氏家族": 2,
    "江氏政治局": 2,
    "江氏政治委员": 2,
    "江太上": 2,
    "江戏子": 2,
    "江系人": 2,
    "江系人马": 2,
    "江宰民": 2,
    "江贼": 2,
    "江贼民": 2,
    "江主席": 2,
    "麻果丸": 2,
    "台独": 2,
    "台湾独立": 2,
    "台湾国": 2,
    "台湾应该独立": 2,
    "台湾有权独立": 2,
    "天灭中共": 2,
    "中共帮凶": 2,
    "中共保命": 2,
    "中共裁": 2,
    "中共党文化": 2,
    "中共腐败": 2,
    "中共的血旗": 2,
    "中共的罪恶": 2,
    "中共帝国": 2,
    "中共独裁": 2,
    "中共封锁": 2,
    "中共封网": 2,
    "中共黑": 2,
    "中共黑帮": 2,
    "中共解体": 2,
    "中共近期权力斗争": 2,
    "中共恐惧": 2,
    "中共权力斗争": 2,
    "中共任用": 2,
    "中共退党": 2,
    "中共洗脑": 2,
    "中共邪教": 2,
    "中共邪毒素": 2,
    "中共政治游戏": 2,
    "肛交": 2,
    "干穴": 2,
    "粉穴": 2,
    "肥逼": 2,
    "荡妇": 2,
    "大乳": 2,
    "大力抽送": 2,
    "抽一插": 2,
    "扌由插": 2,
    "吃精": 2,
    "抽插": 2,
    "潮喷": 2,
    "潮吹": 2,
    "插阴": 2,
    "插我": 2,
    "插你": 2,
    "北京政权": 2,
    "刘志军": 2,
    "许宗衡": 2,
    "李启红": 2,
    "贪腐财富": 2,
    "落马": 2,
    "高官名单": 2,
    "陈希同": 2,
    "陈良宇": 2,
    "滥用职权": 2,
    "开除党籍": 2,
    "刘方仁": 2,
    "以权谋私": 2,
    "李嘉廷": 2,
    "宋平顺": 2,
    "黄瑶": 2,
    "剥夺政治权利终身": 2,
    "王昭耀": 2,
    "剥夺政治权利": 2,
    "杜世成": 2,
    "沈图": 2,
    "罗云光": 2,
    "张辛泰": 2,
    "王乐毅": 2,
    "李纪周": 2,
    "郑光迪": 2,
    "郑筱萸": 2,
    "留党察看": 2,
    "官僚主义": 2,
    "徐炳松": 2,
    "王宝森": 2,
    "孟庆平": 2,
    "胡长清": 2,
    "王怀忠": 2,
    "刘维明": 2,
    "刘志华": 2,
    "周文吉": 2,
    "王庆录广西": 2,
    "广西政协": 2,
    "中国交通": 2,
    "高严国家电力": 2,
    "刘金宝": 2,
    "陈同海": 2,
    "慕绥新": 2,
    "吴振汉": 2,
    "李宝金": 2,
    "陈少勇": 2,
    "黄松有": 2,
    "王华元": 2,
    "刘连昆": 2,
    "骚穴": 2,
    "爱液": 2,
    "爆草": 2,
    "暴乳": 2,
    "爆乳": 2,
    "屄": 2,
    "被操": 2,
    "逼奸": 2,
    "插暴": 2,
    "操逼": 2,
    "操烂": 2,
    "肏你": 2,
    "肏死": 2,
    "操死": 2,
    "操我": 2,
    "插比": 2,
    "插b": 2,
    "插逼": 2,
    "肉棒": 2,
    "乱奸": 2,
}
